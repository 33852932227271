<template>
    <Teleport to="#sports">
        <div
            v-gtm:page="gtmPage"
            v-gtm:type="gtmType"
            class="popupModalWrapper"
        >
            <div class="popupModal">
                <div class="popupModal_head">
                    <slot name="header" />
                    <span class="popupModalTitle">
                        {{ title }}
                    </span>
                    <VButton
                        v-gtm:label="'CloseBtn'"
                        v-gtm:@click
                        padding="0"
                    >
                        <SboIcon
                            name="system:cross"
                            size="20"
                            pointer
                            v-on:click="closePopup"
                        />
                    </VButton>
                </div>
                <div class="popupModal_content">
                    <slot name="content" />
                </div>
            </div>
        </div>
    </Teleport>
</template>

<script lang="ts" setup>
    import { onUnmounted } from 'vue';
    import VButton from '../common/button/VButton.vue';
    import SboIcon from '@/components/common/SboIcon.vue';
    import { useScrollableStore } from '@/store/scrollableStore';

    defineProps<{
        title: string;
        gtmPage?: string;
        gtmType?: string;
    }>();

    const emit = defineEmits(['close']);

    function closePopup() {
        emit('close');
    }

    const { setIsScrollable } = useScrollableStore();
    setIsScrollable(false);
    onUnmounted(() => setIsScrollable(true));
</script>

<style lang="scss" scoped>
@import "@/components/exchange/PopupModal";
</style>
