<template>
    <div class="myBetRowTitle">
        <strong>
            {{ i18n.t(`market_type_name_${MarketType.MixParlay}`) }}
            -
            {{ selectionCountDisplay }}
        </strong>
    </div>

    <Selection
        v-for="subBet in bet.subBets"
        v-bind:key="subBet.transactionId"
        v-bind:bet="subBet"
    />
</template>

<script lang="ts" setup>

    import { computed, toRefs } from 'vue';
    import Selection from '@/components/myBets/Selection.vue';
    import { mixParlaySelectionsDisplay } from '@/components/myBets/useBetDisplay';
    import { i18n } from '@/core/lib/i18n';
    import type { IBet } from '@/interface/IBet';
    import { MarketType } from '@/interface/enum';

    const props = defineProps<{
        bet: IBet;
    }>();

    const { bet } = toRefs(props);
    const selectionCountDisplay = computed(() => mixParlaySelectionsDisplay(bet.value.subBets.length));
</script>

<style lang="scss" scoped>
@import "@/components/myBets/MyBetRow";
</style>
