import type { MaybeRef } from '@vueuse/core';
import { get } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { getDisplayMarketTypeInfos, toBetBuilderMarketTypeInfos } from '@/components/oddsDisplay/marketTypeDisplayRule';
import { useShowTimeDisplay } from '@/composable/useShowTimeDisplay';
import { isFastMarket, isMainMarket, isNextGoal } from '@/core/lib/oddsHelper';
import { getLiveTimeDisplay } from '@/core/oddsApi/helpers/liveScore';
import type { IEvent, IEventEuro } from '@/interface/IEvent';
import type { IEventLike } from '@/interface/IEventLike';
import type { MarketTypeInfo } from '@/interface/IMarketTypeInfo';
import type { IEventResult, IOdds } from '@/interface/IOdds';
import type { IBetBuilderMarket } from '@/interface/betBuilder';
import type { OddsDisplayMode } from '@/interface/enum';
import { BetTypeGroup, HandicapType, MarketShowingType, MarketType, SportType, TeamOption } from '@/interface/enum';
import { useCustomerStore } from '@/store/customerStore';
import { useOddsDisplaySettingsStore } from '@/store/oddsDisplaySettingsStore';

export const mainMarketMoreOrder = [
    MarketType.LiveScore,
    MarketType.FH_LiveScore,
    MarketType.MoneyLine,
    MarketType.Handicap,
    MarketType.FH_Handicap,
    MarketType.OverUnder,
    MarketType.FH_OverUnder,
    MarketType._1X2,
    MarketType.FH_1X2,
    MarketType.OddEven,
    MarketType.FH_OddEven,
    MarketType.TotalGoal,
    MarketType.DoubleChance,
    MarketType.CorrectScore,
    MarketType.FH_CorrectScore,
    MarketType.HTFT,
    MarketType.FGLG,
];

function isShowOnMainMarket(_odds: MaybeRef<IOdds>, marketTypeInfos: MarketTypeInfo[], displayMarketGroup: BetTypeGroup) {
    const odds = get(_odds);
    if (isMainMarket(displayMarketGroup)) {
        if (isNextGoal(odds)) return marketTypeInfos.some(x => isNextGoal(x.marketGroup));
        return marketTypeInfos.some(x => x.marketGroup === odds.eventResult.marketGroup.id && x.marketType === odds.marketType);
    }
    return marketTypeInfos.filter(x => isMainMarket(x.marketGroup)).some(x => x.marketType === odds.marketType);
}

export function getMarketOdds(oddsDisplayMode: OddsDisplayMode, _event: MaybeRef<IEvent>, displayMarketGroup: BetTypeGroup, _oddsList: MaybeRef<IOdds[]> = []) {
    const event = get(_event);
    const oddsList = get(_oddsList);
    const mainMarketTypeInfos = getDisplayMarketTypeInfos(event.sportType, event.isLive, oddsDisplayMode);
    return {
        main: oddsList.filter(odds => isShowOnMainMarket(odds, mainMarketTypeInfos, displayMarketGroup)),
        more: oddsList.filter(odds => !isShowOnMainMarket(odds, mainMarketTypeInfos, displayMarketGroup)),
    };
}

export function getBetBuilderDisplayMarkets(oddsDisplayMode: OddsDisplayMode, _match: MaybeRef<IEvent>, _markets: MaybeRef<IBetBuilderMarket[]>) {
    const match = get(_match);
    const markets = get(_markets);
    const mainMarketTypeInfos = getDisplayMarketTypeInfos(match.sportType, match.isLive, oddsDisplayMode);
    const betBuilderDisplayMarketTypeInfos = toBetBuilderMarketTypeInfos(mainMarketTypeInfos);

    const isShowOnMainMarket = (market: IBetBuilderMarket) => betBuilderDisplayMarketTypeInfos.some(x => x && x.betBuilderMarketType === market.marketType.id && x.statType === market.matchStatType.id);

    return {
        main: markets.filter(market => isShowOnMainMarket(market)),
        more: markets.filter(market => !isShowOnMainMarket(market)),
    };
}

export function useMoreMarketOddsDisplay(oddsDisplayMode: OddsDisplayMode, event: MaybeRef<IEvent>, oddsList: MaybeRef<IOdds[]> = []) {
    const displayMainMarketTypeInfos = getDisplayMarketTypeInfos(get(event).sportType, get(event).isLive, oddsDisplayMode);

    // more market will show those odds which is *not* in displayMarketTypes
    const mainMarketOddsList = computed(() => {
        const filteredOdds = get(oddsList)
            .filter(odds => isMainMarket(odds))
            .filter(odds => !displayMainMarketTypeInfos.some(x => x.marketGroup === odds.eventResult.marketGroup.id && x.marketType === odds.marketType));

        return filteredOdds;
    });

    const fastMarketOddsList = computed(() => get(oddsList).filter(odds => isFastMarket(odds)));

    const newBetTypeOddsList = computed(() => {
        const filteredOdds = get(oddsList)
            .filter(odds => !isMainMarket(odds))
            .filter(odds => !isFastMarket(odds));
        return filteredOdds;
    });

    return {
        mainMarketOddsList,
        newBetTypeOddsList,
        fastMarketOddsList,
    };
}

export function getMoreMarketCount(oddsDisplayMode: OddsDisplayMode, event: IEvent, displayMarketGroup: BetTypeGroup) {
    const mainMarketTypeInfos = getDisplayMarketTypeInfos(event.sportType, event.isLive, oddsDisplayMode);

    return Object.entries(event.marketTypeCount).reduce((marketGroupsSum, [marketGroupString, value]) => {
        const marketGroup = parseInt(marketGroupString) as BetTypeGroup;
        return Object.entries(value).reduce((marketTypesSum, [marketTypeString, count]) => {
            const marketType = parseInt(marketTypeString) as MarketType;
            count = count ?? 0;

            if (isMainMarket(displayMarketGroup)) {
                const isShowOnMainMarket = mainMarketTypeInfos.some((x) => {
                    if (isNextGoal(marketGroup)) return isNextGoal(x.marketGroup);
                    return x.marketType === marketType && x.marketGroup === marketGroup;
                });

                if (isShowOnMainMarket) {
                    return marketTypesSum;
                }
                if (isShowNewBetTypeOnMainPage(event.sportType, marketGroup, event.isLive, oddsDisplayMode)) {
                    return marketTypesSum;
                }
                return marketTypesSum + count;
            }

            if (marketGroup === displayMarketGroup) {
                if (mainMarketTypeInfos.some(x => x.marketGroup === BetTypeGroup.Main && x.marketType === marketType)) {
                    return marketTypesSum;
                }
                return marketTypesSum + count;
            }

            return marketTypesSum;
        }, marketGroupsSum);
    }, 0);
}

export function isShowNewBetTypeOnMainPage(sportType: SportType, marketGroupId: BetTypeGroup, isLive: boolean, oddsDisplayMode: OddsDisplayMode): boolean {
    const { marketShowing } = useOddsDisplaySettingsStore();
    if (marketShowing === MarketShowingType.Main) return false;

    if (!isLive) return false;
    if (isMainMarket(marketGroupId)) return false;
    if (isFastMarket(marketGroupId)) return false;
    if (isNextGoal(marketGroupId)) return false;
    if (sportType === SportType.Cricket) return false;

    const isShowOnMainEvent = getDisplayMarketTypeInfos(sportType, isLive, oddsDisplayMode).some(x => x.marketGroup === marketGroupId);
    return !isShowOnMainEvent;
}

export function isHomeFavorite(eventResult: IEventResult) {
    return eventResult?.handicapType === HandicapType.Home;
}

export function isAwayFavorite(eventResult: IEventResult) {
    return eventResult?.handicapType === HandicapType.Away;
}

export function getTeamName(teamOption: TeamOption, event: IEvent | IEventEuro, oddsList: IOdds[]) {
    return translateNewBetTypeTemplate(teamOption, event, oddsList);
}

function translateNewBetTypeTemplate(teamOption: TeamOption, event: IEvent | IEventEuro, oddsList: IOdds[]) {
    if (oddsList.length === 0) {
        switch (teamOption) {
            case TeamOption.Home:
                return event.homeTeam.name ?? '';
            case TeamOption.Away:
                return event.awayTeam.name ?? '';
            default:
                return '';
        }
    }

    return replaceTemplate(teamOption, event, oddsList[0]);
}

function replaceTemplate(teamOption: TeamOption, event: IEvent | IEventEuro, odds: IOdds) {
    const { marketGroup } = odds.eventResult;
    const template = teamOption === TeamOption.Home
        ? marketGroup.homeTemplate
        : marketGroup.awayTemplate;
    return template
        .replace(/_/g, '')
        .replace(/{home}/ig, event.homeTeam.name ?? '')
        .replace(/{homeext}/ig, marketGroup.homeExtension)
        .replace(/{away}/ig, event.awayTeam.name ?? '')
        .replace(/{awayext}/ig, marketGroup.awayExtension);
}

export function isDisplayEventDate(event: IEvent) {
    return !event.isSboLive && !event.isTvLive;
}

export function getNonLiveTimeDisplay(event: IEvent) {
    const { date, time } = useShowTimeDisplay(event.showTime, event.showTimeType);
    return {
        date: event.isSboLive ? '' : date,
        time,
        showLiveTag: event.isSboLive,
    };
}

export function getMatchLiveTimeDisplay(event: IEventLike) {
    if (!event.isLive) return '';
    const period = event.extraInfo?.period ?? 0;
    const periodStartTime = event.extraInfo?.periodStartTime ?? '';
    const { siteStyle } = storeToRefs(useCustomerStore());

    return getLiveTimeDisplay(period, periodStartTime, siteStyle.value);
}

export function isShowInjuryTime(event: IEventLike) {
    return (event.extraInfo?.injuryTime ?? 0) > 0;
}
