<template>
    <Navbar v-gtm:type="'SubHeader'" class="sticky">
        <template v-if="!isShowSportList" #navbarTitleIcon>
            <SvgIcon
                v-if="titleIcon"
                v-bind:name="titleIcon.name"
                v-bind:size="titleIcon.size"
            />
        </template>
        <template #navbarTitle>
            <VButton
                v-if="isShowSportList"
                padding="4px 8px"
                class="navbarSportList"
            >
                <SportIcon
                    v-bind:sportType="sportType"
                    colorful
                    size="lg"
                    class="navbarSportList_icon"
                />
                {{ navbarTitle }}
                <SvgIcon
                    name="icon-system-arrow"
                    v-bind:rotated="!isSportListHovered"
                    right
                />
                <Dropdown
                    v-bind:openOnHover="!IS_MOBILE"
                    v-bind:openOnClick="IS_MOBILE"
                    v-on:onOpen="isSportListHovered = true"
                    v-on:onClose="isSportListHovered = false"
                >
                    <div
                        v-gtm:type="'SubHeader_SportList'"
                        class="dropdownList"
                    >
                        <SportList v-bind:items="sportList" />
                    </div>
                </Dropdown>
            </VButton>
            <template v-else>
                {{ navbarTitle }}
            </template>
        </template>
        <template #navbarDivider>
            <NavbarDivider v-if="isShowNavbarDivider" />
        </template>
        <template #topRow>
            <NavbarMain>
                <template
                    v-for="item in navbarItems"
                    v-bind:key="item.key"
                >
                    <NavbarDivider v-if="item.showDividing" />
                    <NavbarItem
                        v-gtm:label="item.gtmLabel"
                        v-gtm:@click
                        isShowCount
                        v-bind:name="item.name"
                        v-bind:count="item.count"
                        v-bind:isActive="item.isActive"
                        v-bind:onclick="item.onClick"
                        countTextColor="white"
                        v-bind:countBgColor="item.isLive ? 'live' : 'nonLive'"
                        v-bind:countBorderColor="item.isLive ? 'live' : 'nonLive'"
                    />
                </template>
            </NavbarMain>
            <NavbarRowItem>
                <Toolbar />
            </NavbarRowItem>
        </template>
        <template #bottomRow>
            <NavbarRowItem fluid>
                <SlideTab v-bind:items="timeSelections" />
            </NavbarRowItem>
            <NavbarRowItem>
                <VButton
                    v-if="isShowSelectLeague"
                    v-gtm:@click
                    v-gtm:label="'SelectLeague'"
                    v-bind:active="hasSelectedLeague"
                    activeColor="brandColor-200"
                    activeTextColor="primary"
                    flat
                    v-on:click="showSelectLeague"
                >
                    <template #icon>
                        <SvgIcon
                            left
                            class="selectLeague_icon"
                            name="icon-system-filter"
                        />
                    </template>
                    {{ i18n.t('select_league') }}
                    <Count
                        v-if="hasSelectedLeague"
                        right
                        v-bind:number="selectedLeagueCount"
                        bgColor="primary"
                        textColor="white"
                    />
                </VButton>
                <VButton
                    v-gtm:@click
                    v-gtm:label="`${MarketPage[marketPage]}Refresh`"
                    flat
                    v-on:click="refresh(true)"
                >
                    <template #icon>
                        <SvgIcon
                            name="icon-system-refresh"
                            size="16"
                            left
                            v-bind:rotating="isRefreshing"
                        />
                    </template>
                    <span class="refresher_title">
                        {{ countdown }}
                    </span>
                </VButton>
            </NavbarRowItem>
        </template>
    </Navbar>
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import { computed, ref } from 'vue';
    import Count from '@/components/common/Count.vue';
    import SportIcon from '@/components/common/SportIcon.vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import VButton from '@/components/common/button/VButton.vue';
    import Dropdown from '@/components/common/dropdown/Dropdown.vue';
    import Navbar from '@/components/common/navbar/Navbar.vue';
    import NavbarDivider from '@/components/common/navbar/NavbarDivider.vue';
    import NavbarItem from '@/components/common/navbar/NavbarItem.vue';
    import NavbarMain from '@/components/common/navbar/NavbarMain.vue';
    import NavbarRowItem from '@/components/common/navbar/NavbarRowItem.vue';
    import SlideTab from '@/components/common/slideTab/SlideTab.vue';
    import Toolbar from '@/components/navbarBlock/asia/Toolbar.vue';
    import { useRefresher } from '@/components/navbarBlock/asia/useRefresher';
    import { useSportMarketPage } from '@/components/navbarBlock/asia/useSportMarketPage';
    import { useTimeSelection } from '@/components/navbarBlock/asia/useTimeSelection';
    import SportList from '@/components/sportList/SportList.vue';
    import { useEventEmitter } from '@/composable/useEventEmitter';
    import { i18n } from '@/core/lib/i18n';
    import { isMobile } from '@/core/lib/utils';
    import { MarketPage } from '@/interface/enum';
    import { useAsiaSportMarketInfoStore } from '@/store/asiaSportMarketInfoStore';
    import { useSelectedLeagueStore } from '@/store/selectedLeagueStore';
    import { useSideBarStore } from '@/store/sideBarStore';

    const { sportList } = storeToRefs(useSideBarStore());
    const { selectedLeagueCount } = storeToRefs(useSelectedLeagueStore());
    const {
        sportType,
        isMixParlay,
        isPromotionZone,
        isMultipleSportsZone,
        multipleSportsList,
        currentPromotionZoneConfig,
        isMyFavorite,
        favoriteCount,
        marketPage,
    } = storeToRefs(useAsiaSportMarketInfoStore());

    const hasSelectedLeague = computed(() => selectedLeagueCount.value !== 0);

    const navbarTitle = computed(() => {
        if (isMixParlay.value) return i18n.t(`market_page_name_${MarketPage.MixParlay}`);
        if (isMyFavorite.value) return i18n.t('tab_title_favorites');
        if (currentPromotionZoneConfig.value) return i18n.t(currentPromotionZoneConfig.value.name);
        return i18n.t(`sport_type_name_${sportType.value}`);
    });

    const { navbarItems } = useSportMarketPage();

    const { timeSelections } = useTimeSelection();

    const showSelectLeague = () => {
        const eventEmitter = useEventEmitter();
        eventEmitter.emit('selectLeague:show');
    };
    const IS_MOBILE = isMobile();
    const { refresh, isRefreshing, countdown } = useRefresher();

    const isShowSportList = computed(() => {
        if (isMyFavorite.value) return false;
        if (isMixParlay.value) return false;
        if (isPromotionZone.value) return false;
        return true;
    });
    const isSportListHovered = ref(false);

    const isShowNavbarDivider = computed(() => {
        if (isMyFavorite.value && favoriteCount.value === 0) {
            return false;
        }
        if (isMultipleSportsZone.value && multipleSportsList.value.length === 0) {
            return false;
        }
        return true;
    });

    const isShowSelectLeague = computed(() => {
        if (isMyFavorite.value) return false;
        if (isPromotionZone.value) return false;
        return true;
    });

    const titleIcon = computed(() => {
        if (isMyFavorite.value) return { name: 'icon-system-star-full-color', size: 'lg' };
        if (isMixParlay.value) return { name: 'icon-system-ticketMultiple', size: 'lg' };
        if (currentPromotionZoneConfig.value) return currentPromotionZoneConfig.value.icon;

        return null;
    });
</script>

<style lang="scss" scoped>
    @import "@/components/navbarBlock/NavbarBlock.Asia";
    @import "@/components/common/navbar/NavbarDropdown";
</style>
