<template>
    <div
        v-gtm:page="isAsiaSite ? 'K_Exchange' : 'G_Exchange'"
        v-gtm:type="'Page'"
        v-gtm:@view
    >
        <div id="exchangeNavbar">
            <Navbar v-if="isAsiaSite" v-bind:isShowBottomRow="false">
                <template #navbarTitleIcon>
                    <SboIcon name="sports:exchange" size="lg" />
                </template>
                <template #navbarTitle>
                    {{ i18n.t('exchange') }}
                </template>
                <template #topRow>
                    <NavbarRowItem
                        v-if="!hasDepositBonus"
                        fluid
                        right
                    >
                        <VButton
                            v-gtm:type="'Wallet'"
                            v-gtm:@click
                            v-on:click="setIsShowPopup(true)"
                        >
                            <SboIcon
                                name="system:transfer"
                                size="lg"
                                left
                            />
                            <span class="refresher_title">
                                {{ i18n.t('exchange_wallet') }}
                            </span>
                        </VButton>
                    </NavbarRowItem>
                </template>
            </Navbar>
            <Breadcrumbs v-else borderRadius>
                <VButton
                    v-if="!hasDepositBonus"
                    v-gtm:type="'Wallet'"
                    v-gtm:@click
                    v-on:click="setIsShowPopup(true)"
                >
                    <SboIcon name="system:transfer" size="lg" />
                    <span class="refresher_title">
                        {{ i18n.t('exchange_wallet') }}
                    </span>
                </VButton>
            </Breadcrumbs>
        </div>
        <div v-if="!isLoaded" class="previewLoading">
            <StaticSpinner />
        </div>
        <ErrorPage v-else-if="errorMessage" v-bind:errorMessage="errorMessage" />
        <div v-else class="iframeWrapper">
            <div v-if="!isIframeLoaded" class="previewLoading">
                <StaticSpinner />
            </div>

            <iframe
                title="Exchange"
                class="iframe"
                v-bind:src="iframeUrl"
                v-bind:style="{ height: isIframeLoaded ? iframeHeight + 'px' : 0 }"
                v-on:load="isIframeLoaded = true"
            />
        </div>
        <Transfer
            v-if="isShowPopup"
            v-bind:isShowPopup="isShowPopup"
            v-on:close="setIsShowPopup(false)"
        />
    </div>
</template>

<script setup lang="ts">
    import { debounceAnimationFrame } from '@sports-utils/timer';
    import { useWindowSize } from '@vueuse/core';
    import { storeToRefs } from 'pinia';
    import { computed, onMounted, watch } from 'vue';
    import ErrorPage from '@/components/common/ErrorPage.vue';
    import SboIcon from '@/components/common/SboIcon.vue';
    import StaticSpinner from '@/components/common/StaticSpinner.vue';
    import VButton from '@/components/common/button/VButton.vue';
    import Navbar from '@/components/common/navbar/Navbar.vue';
    import NavbarRowItem from '@/components/common/navbar/NavbarRowItem.vue';
    import Transfer from '@/components/exchange/Transfer.vue';
    import Breadcrumbs from '@/components/navbarBlock/euro/Breadcrumbs.vue';
    import { useEventEmitter } from '@/composable/useEventEmitter';
    import { useState } from '@/composable/useState';
    import { Api } from '@/core/lib/api';
    import { getHeight } from '@/core/lib/dom';
    import { i18n } from '@/core/lib/i18n';
    import type { IErrorMessage } from '@/interface/IErrorMessage';
    import { ExchangeStatus } from '@/interface/enum';
    import { useCustomerStore } from '@/store/customerStore';
    import { useExchangeCustomerStore } from '@/store/exchangeCustomerStore';

    const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
    const [iframeHeight, setIframeHeight] = useState(0);

    const { isAsiaSite } = storeToRefs(useCustomerStore());

    const {
        exchangeStatus,
        hasDepositBonus,
        hasExceedBettingBudget,
    } = storeToRefs(useExchangeCustomerStore());

    const { setCustomerInfo, setExchangeStatus } = useExchangeCustomerStore();

    const [iframeUrl, setIframeUrl] = useState<string>('');
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [isIframeLoaded] = useState<boolean>(false);

    async function getBettingInfo() {
        try {
            const { status, iframeUrl, customerInfo } = await Api.getExchangeBettingInfo();

            setIframeUrl(iframeUrl);
            setCustomerInfo(customerInfo);
            setExchangeStatus(status);
        } catch (e) {
            setExchangeStatus(ExchangeStatus.Error);
            console.error(e);
        } finally {
            setIsLoaded(true);
        }
    }

    getBettingInfo();

    const errorMessage = computed<IErrorMessage | null>(() => {
        if (exchangeStatus.value !== ExchangeStatus.Success) {
            return {
                title: i18n.t('something_went_wrong'),
                message: i18n.t('exchange_error_general_error'),
            };
        }

        if (hasDepositBonus.value) {
            return {
                title: i18n.t('exchange_error_deposit_limit_title'),
                message: i18n.t('exchange_error_deposit_limit_subtitle'),
            };
        }

        if (hasExceedBettingBudget.value) {
            return {
                title: i18n.t('exchange_error_betting_limit_exceeded_title'),
                message: i18n.t('exchange_error_betting_limit_exceeded_subtitle'),
            };
        }

        return null;
    });

    const headerGap = 8;
    const headerHeight = 70;
    const bannerGap = 8;
    const navbarGap = 8;
    const footerGap = 8;
    const calculateIframeHeight = debounceAnimationFrame(() => {
        const bannerHeight = getHeight('#topBanner');
        const navbarHeight = getHeight('#exchangeNavbar');
        setIframeHeight(window.innerHeight - headerHeight - headerGap - bannerHeight - bannerGap - navbarHeight - navbarGap - footerGap);
    });

    const eventEmitter = useEventEmitter();
    eventEmitter.on('columnCenter:resize', () => {
        calculateIframeHeight();
    });

    onMounted(() => {
        calculateIframeHeight();
    });

    const { height } = useWindowSize();
    watch([height], calculateIframeHeight);
</script>

<style lang="scss" scoped>
    @import "@/views/Exchange";
</style>
