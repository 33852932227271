<template>
    <CubeBoxContainer>
        <CubeBoxItem
            v-for="hotLink in hotLinks"
            v-bind:key="hotLink.name"
            v-tooltip="hotLink.name"
            v-bind:iconName="getIconName(hotLink)"
            v-bind:isActive="hotLink.isActive"
            v-on:click="hotLink.onClick"
        />
    </CubeBoxContainer>
    <CubeBoxContainer isFluid>
        <CubeBoxItem
            v-for="sport in flattenSportList"
            v-bind:key="sport.name"
            v-tooltip="sport.name"
            v-bind:iconName="getIconName(sport)"
            v-bind:isActive="sport.isActive"
            v-on:click="sport.onClick"
        >
            <SportIcon
                v-if="sport.sportType"
                size="xxl"
                v-bind:sportType="sport.sportType"
            />
        </CubeBoxItem>
    </CubeBoxContainer>
    <CubeBoxContainer>
        <CubeBoxItem
            v-tooltip="i18n.t('tab_title_ticket')"
            iconName="icon-system-ticket"
            v-bind:isActive="isBetSlipExpanded"
            v-on:click="onBetSlipClicked"
        />
        <CubeBoxItem
            v-tooltip="i18n.t('tab_title_mybets')"
            iconName="icon-system-mini-my-bets"
            v-bind:isActive="isMyBetsExpanded"
            v-on:click="onMyBetsClicked"
        />
    </CubeBoxContainer>
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import { computed } from 'vue';
    import SportIcon from '@/components/common/SportIcon.vue';
    import CubeBoxContainer from '@/components/sideBar/CubeBoxContainer.vue';
    import CubeBoxItem from '@/components/sideBar/CubeBoxItem.vue';
    import { i18n } from '@/core/lib/i18n';
    import type { ISportListItem } from '@/interface/ISport';
    import { useLayoutStore } from '@/store/layoutStore';
    import { useSideBarStore } from '@/store/sideBarStore';

    const { hotLinks, sportList } = storeToRefs(useSideBarStore());
    const flattenSportList = computed<ISportListItem[]>(() => sportList.value.flatMap(sport => sport.children ?? sport));

    const { isBetSlipExpanded, isMyBetsExpanded } = storeToRefs(useLayoutStore());
    const { toggleBetSlipExpanded, toggleMyBetsExpanded } = useLayoutStore();

    const onBetSlipClicked = () => {
        toggleBetSlipExpanded();
        toggleMyBetsExpanded(false);
    };

    const onMyBetsClicked = () => {
        toggleBetSlipExpanded(false);
        toggleMyBetsExpanded();
    };

    function getIconName(sport: ISportListItem) {
        return typeof sport.icon === 'string' ? sport.icon : sport.icon?.name ?? '';
    }
</script>
