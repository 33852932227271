<template>
    <div v-gtm:page="'Results'" class="results notranslate">
        <Navbar class="sticky">
            <template #navbarTitleIcon>
                <SvgIcon name="icon-system-result-outline" />
            </template>
            <template #navbarTitle>
                {{ isOutright ? i18n.t('results_outright_results') : i18n.t('results') }}
            </template>
            <template #navbarDivider>
                <NavbarDivider />
            </template>
            <template #topRow>
                <NavbarMain v-gtm:type="'SelectDate'">
                    <NavbarItem
                        v-for="filter in dateFilters"
                        v-bind:key="filter.key"
                        v-gtm:label="filter.gtmLabel"
                        v-gtm:@click
                        v-bind:name="filter.name"
                        v-bind:isActive="filter.isActive"
                        v-bind:onclick="filter.onClick"
                    />
                </NavbarMain>
            </template>
            <template #bottomRow>
                <NavbarRowItem fluid>
                    <div class="formGroup">
                        <select
                            class="select"
                            v-bind:value="sportType"
                            v-on:change="(e: Event) => setSportType(parseInt((e.target as HTMLSelectElement).value) as unknown as SportType)"
                        >
                            <option
                                v-for="sport in sports"
                                v-bind:key="sport"
                                v-bind:value="sport"
                            >
                                {{ i18n.t(`sport_type_name_${sport}`) }}
                            </option>
                        </select>
                        <select
                            v-bind:value="selectedLeague"
                            class="select"
                            v-bind:disabled="isLoading || isEmpty"
                            v-on:change="(e: Event) => setSelectedLeague(parseInt((e.target as HTMLSelectElement).value))"
                        >
                            <option v-bind:value="0">
                                {{ i18n.t('all_leagues') }}
                            </option>
                            <option
                                v-for="league in leagues"
                                v-bind:key="league.id"
                                v-bind:value="league.id"
                            >
                                {{ league.name }}
                            </option>
                        </select>
                    </div>
                </NavbarRowItem>
                <NavbarRowItem>
                    <div class="item">
                        <ToggleSwitch
                            v-gtm:label="isOutright ? 'Results_NormalResult' : 'Results_OutrightResult'"
                            v-gtm:@click
                            v-bind:modelValue="isOutright"
                            v-bind:text="i18n.t('results_outright_results')"
                            v-on:update:modelValue="setIsOutright(!isOutright)"
                        />
                    </div>
                </NavbarRowItem>
            </template>
        </Navbar>
        <ResultTable />
    </div>
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import { computed } from 'vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import Navbar from '@/components/common/navbar/Navbar.vue';
    import NavbarDivider from '@/components/common/navbar/NavbarDivider.vue';
    import NavbarItem from '@/components/common/navbar/NavbarItem.vue';
    import NavbarMain from '@/components/common/navbar/NavbarMain.vue';
    import NavbarRowItem from '@/components/common/navbar/NavbarRowItem.vue';
    import ToggleSwitch from '@/components/common/toggleSwitch/ToggleSwitch.vue';
    import ResultTable from '@/components/results/ResultTable.vue';
    import { formatDate } from '@/core/lib/date';
    import { i18n } from '@/core/lib/i18n';
    import { getLocalesSortingFn } from '@/core/lib/string';
    import { ResultsDateFilter, SportType } from '@/interface/enum';
    import type { IDateRange } from '@/store/resultsStore';
    import { useResultsStore } from '@/store/resultsStore';

    const { sportType, dateFilter, dateOptions, leagues, selectedLeague, isLoading, isEmpty, isOutright } = storeToRefs(useResultsStore());
    const { dateFilterMapping, setSportType, setDateFilter, setSelectedLeague, setIsOutright } = useResultsStore();

    const getDateFilterDisplay = (dateFilter: ResultsDateFilter, dateRange: IDateRange) => {
        switch (dateFilter) {
            case ResultsDateFilter.Today:
            case ResultsDateFilter.Minus1:
                return i18n.t(`results_date_filter_${dateFilter}`);
            case ResultsDateFilter.OneWeek:
                return `${i18n.t('results_recent_one_week')} (${formatDate(dateRange.startDate, 'MM/dd')}-${formatDate(dateRange.endDate, 'MM/dd')})`;
            case ResultsDateFilter.TwoWeek:
                return `${i18n.t('results_recent_two_weeks')} (${formatDate(dateRange.startDate, 'MM/dd')}-${formatDate(dateRange.endDate, 'MM/dd')})`;
            case ResultsDateFilter.OneMonth:
                return `${i18n.t('results_recent_one_month')} (${formatDate(dateRange.startDate, 'MM/dd')}-${formatDate(dateRange.endDate, 'MM/dd')})`;
            default:
                return formatDate(dateRange.startDate, 'MM/dd');
        }
    };

    const dateFilterGtmMapping: Partial<Record<ResultsDateFilter, string>> = {
        [ResultsDateFilter.Today]: 'Today',
        [ResultsDateFilter.Minus1]: 'Yesterday',
        [ResultsDateFilter.OneWeek]: 'Results_OutrightResult_Week',
        [ResultsDateFilter.TwoWeek]: 'Results_OutrightResult_TwoWeek',
        [ResultsDateFilter.OneMonth]: 'Results_OutrightResult_Month',
    };

    const dateFilters = computed(() => dateOptions.value.map(option => ({
        key: option,
        name: getDateFilterDisplay(option, dateFilterMapping[option]),
        isActive: dateFilter.value === option,
        onClick: () => {
            if (option === dateFilter.value) return;
            setDateFilter(option);
        },
        gtmLabel: dateFilterGtmMapping[option] ?? '',
    })));

    const excludedSportType = [
        SportType.Unknown,
        SportType.Financial,
        SportType.VirtualFootball,
        SportType.VirtualBasketball,
        SportType.VirtualRacing,
        SportType.EsportsIm,
        SportType.EsportsForDisplay,
        SportType.Racing,
    ];

    const sortFn = getLocalesSortingFn();
    const sports = (Object.values(SportType)
        .filter(x => typeof x === 'number') as unknown as SportType[])
        .filter(x => !excludedSportType.includes(x))
        .sort((a, b) => sortFn(i18n.t(`sport_type_name_${a}`), i18n.t(`sport_type_name_${b}`)));
</script>

<style lang="scss" scoped>
    @import "@/components/results/ResultsBlock";
</style>
