<template>
    <div>
        <OutrightOddsDisplayHeader
            v-bind:isLive="isLive"
            v-bind:isSticky="isSticky"
        />
        <VirtualScroll
            v-if="leagues.length > 0"
            class="oddsDisplay_content"
            v-bind:list="dataList"
            keyAttrName="id"
            v-bind:defaultItemHeight="item => item.height"
            v-bind:hysteresis="0"
        >
            <template #default="{ item }">
                <OutrightOddsDisplayLeague
                    v-bind:isLive="isLive"
                    v-bind:league="item.league"
                />
            </template>
            <template #placeholder="{ item }">
                <OutrightOddsDisplayPlaceholder
                    v-bind:league="item.league"
                    v-bind:isLive="isLive"
                />
            </template>
        </VirtualScroll>
        <div
            v-if="leagues.length === 0"
            class="oddsDisplay_empty"
            v-bind:class="{ live: isLive }"
        >
            <span v-if="loaded">{{ i18n.t('no_events_available_for_this_market') }}</span>
            <StaticSpinner v-else />
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { computed } from 'vue';
    import StaticSpinner from '@/components/common/StaticSpinner.vue';
    import OutrightOddsDisplayPlaceholder from '@/components/oddsDisplay/OutrightOddsDisplayPlaceholder.vue';
    import { getOutrightLeaguePlaceHolderHeight } from '@/components/oddsDisplay/oddsTableCalculation';
    import { OutrightOddsDisplayBlockContext } from '@/components/oddsDisplay/outright/OutrightOddsDisplayBlockContext';
    import { OutrightOddsDisplayContext } from '@/components/oddsDisplay/outright/OutrightOddsDisplayContext';
    import OutrightOddsDisplayHeader from '@/components/oddsDisplay/outright/OutrightOddsDisplayHeader.vue';
    import OutrightOddsDisplayLeague from '@/components/oddsDisplay/outright/OutrightOddsDisplayLeague.vue';
    import VirtualScroll from '@/components/virtualScroll/VirtualScroll.vue';
    import { i18n } from '@/core/lib/i18n';

    const props = defineProps({
        isLive: {
            type: Boolean,
            required: true,
        },
        isSticky: {
            type: Boolean,
            required: true,
        },
    });

    const { loaded } = OutrightOddsDisplayBlockContext.inject();
    const { leagues } = OutrightOddsDisplayContext.provide(props.isLive);

    const dataList = computed(() => leagues.value.map(league => ({
        id: league.id,
        height: getOutrightLeaguePlaceHolderHeight(league),
        league,
    })));
</script>

<style lang="scss" scoped>
    @import "@/components/oddsDisplay/OddsDisplay";
</style>
