<template>
    <Breadcrumbs borderRadius />
    <MatchDetailHeader
        v-gtm:page="'G_MatchDetail'"
        v-gtm:type="'Header'"
        v-bind:event="event"
        class="sticky"
    />

    <MatchLoading v-if="!loaded" />
    <NoData
        v-else-if="!event"
        v-bind:dataType="NoDataType.Event"
        borderRadius
    />
    <MatchDetailOddsDisplay
        v-else
        v-gtm:page="'G_MatchDetail'"
        v-gtm:type="'MatchCard'"
        v-bind:event="event"
    />
</template>

<script lang="ts" setup>
    import { ref, watchEffect } from 'vue';
    import { EuroDisplayContext } from '@/components/euroDisplay/EuroDisplayContext';
    import MatchDetailHeader from '@/components/euroDisplay/MatchDetailHeader.vue';
    import MatchDetailOddsDisplay from '@/components/euroDisplay/MatchDetailOddsDisplay.vue';
    import MatchLoading from '@/components/euroDisplay/MatchLoading.vue';
    import NoData from '@/components/euroDisplay/NoData.vue';
    import Breadcrumbs from '@/components/navbarBlock/euro/Breadcrumbs.vue';
    import { useEuroRoute } from '@/composable/useEuroRoute';
    import { useEventEuro } from '@/core/oddsApi/composable/useEvent.euro';
    import { EnumOddsCategoryType } from '@/core/oddsApi/oddsApiType';
    import { EuroDate, NoDataType } from '@/interface/enum';

    const { sportType, eventId, regionName, leagueName, eventName, isMultiplesPage, updateNameParams } = useEuroRoute();

    const { isMultiples } = EuroDisplayContext.provide(sportType, isMultiplesPage, ref(EuroDate.Unknown));

    const { event, loaded } = useEventEuro(eventId.value, isMultiples.value ? EnumOddsCategoryType.MixParlay : EnumOddsCategoryType.All);

    watchEffect(() => {
        if (!event.value) return;
        if (event.value.league.region.name === regionName.value
            && event.value.league.name === leagueName.value
            && `${event.value.homeTeam.name} v ${event.value.awayTeam.name}` === eventName.value) return;

        updateNameParams({
            regionName: event.value.league.region.name,
            leagueName: event.value.league.name,
            eventName: `${event.value.homeTeam.name} v ${event.value.awayTeam.name}`,
        });
    });
</script>
