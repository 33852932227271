import { isEqual } from 'lodash';
import { computed, nextTick, ref, watch } from 'vue';
import { deepCompareComputed } from '@/composable/deepCompareComputed';
import { useState } from '@/composable/useState';
import { i18n } from '@/core/lib/i18n';
import { deepFreeze } from '@/core/lib/utils';
import { formatEvent } from '@/core/oddsApi/helpers/formatEvent';
import type { EnumOddsCategoryType } from '@/core/oddsApi/oddsApiType';
import {
    useTicketEventQuery,
} from '@/core/oddsApi/oddsApiType';
import type { IEvent } from '@/interface/IEvent';
import { EnumLanguage } from '@/interface/enum';

export function useTicketEvent(
    eventId: number,
    oddsCategory: EnumOddsCategoryType,
    isActive = ref(true),
) {
    const variables = computed(() => ({
        query: {
            id: eventId,
            oddsCategory,
            lang: EnumLanguage.toOddsApi(i18n.locale.value),
        },
    }));

    const [isEnabled, setIsEnabled] = useState(true);
    const refetch = async () => {
        if (isEnabled.value) {
            setIsEnabled(false);
            nextTick(() => setIsEnabled(true));
        }
    };

    const { loaded, loading, onResult } = useTicketEventQuery(
        variables,
        deepCompareComputed(() => ({
            enabled: isActive.value && isEnabled.value,
        })),
    );

    const event = ref<IEvent | undefined>(undefined);

    onResult((result) => {
        if (result) {
            const newEvent = deepFreeze(formatEvent(result, result.eventResults));
            if (!isEqual(event.value, newEvent)) {
                event.value = newEvent;
            }
        }
    });

    watch(variables, () => {
        loaded.value = false;
        event.value = undefined;
    });

    return {
        loaded,
        loading,
        refetch,
        event,
    };
}
