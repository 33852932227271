<template>
    <div
        class="statesWrapper"
        v-bind:class="{ 'statesWrapper-show': show }"
        v-bind:style="wrapperStyle"
    >
        <div ref="innerRef">
            <div
                class="states"
                v-bind:class="classes"
                v-bind:style="innerStyle"
            >
                <div class="states_content">
                    <SvgIcon
                        v-if="!noIcon"
                        v-bind:name="iconName"
                        v-bind:size="iconSize"
                        left
                    />
                    <span>
                        <DynamicI18n v-bind:config="message" />
                    </span>
                </div>
                <div v-if="animationTimeout !== undefined" class="statesProgress">
                    <div
                        v-bind:key="animationTimeout"
                        class="statesProgress_bar"
                        v-bind:style="`animation-duration: ${animationTimeout}ms;`"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import type { CSSProperties } from 'vue';
    import { computed, nextTick, ref, watch } from 'vue';
    import DynamicI18n from '../common/DynamicI18n.vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import { parsePadding } from '@/core/lib/style';
    import type { IDynamicI18nConfig } from '@/interface/IDynamicI18nConfig';
    import { SiteStyle } from '@/interface/enum';
    import { useCustomerStore } from '@/store/customerStore';

    const props = withDefaults(defineProps<{
        position: 'top' | 'bottom';
        status?: 'success' | 'info' | 'warning' | 'danger';
        rounded?: boolean;
        noIcon?: boolean;
        message: string | IDynamicI18nConfig;
        show: boolean;
        padding?: string | number;
        progressDuration?: number;
        textCenter?: boolean;
        icon?: string;
        iconSize?: string;
        bgColor?: string;
        color?: string;
        lineHeight?: number;
    }>(), {
        status: undefined,
        padding: '',
        progressDuration: undefined,
        icon: '',
        iconSize: 'default',
        bgColor: '',
        color: '',
        lineHeight: undefined,
    });

    const animationTimeout = computed(() => {
        const { show, progressDuration } = props;
        return show ? progressDuration : 0;
    });

    const classes = computed(() => {
        const { position, status } = props;
        const classes = [
            `states-${position}`,
            props.rounded ? 'states-rounded' : '',
            props.textCenter ? 'states-textCenter' : '',
        ];
        if (status) {
            classes.push(`states-${status}`);
        }
        return classes;
    });

    const iconName = computed(() => {
        if (props.icon) return props.icon;
        return props.status === 'success' ? 'icon-system-checkCircle' : 'icon-system-warningCircle';
    });

    const innerRef = ref<HTMLDivElement | null>(null);
    const wrapperHeight = ref(0);
    watch(() => [props.show, props.message], () => {
        if (props.show) {
            nextTick().then(() => {
                wrapperHeight.value = innerRef.value?.getBoundingClientRect().height ?? 0;
            });
        } else {
            wrapperHeight.value = 0;
        }
    }, { immediate: true });

    const wrapperStyle = computed(() => ({
        minHeight: props.show ? `${wrapperHeight.value}px` : 0,
        height: props.show ? `${wrapperHeight.value}px` : 0,
    }));

    const innerStyle = computed(() => {
        const result: CSSProperties = {};

        const padding = parsePadding(props.padding ?? '');
        if (padding) result.padding = padding;
        if (props.bgColor) result.backgroundColor = props.bgColor;
        if (props.color) result.color = props.color;
        if (props.lineHeight) result.lineHeight = `${props.lineHeight}px`;

        return result;
    });

    const { siteStyle } = storeToRefs(useCustomerStore());
    if (siteStyle.value === SiteStyle.Euro) {
        import('@/components/betSlip/Message.Euro.scss');
    } else {
        import('@/components/betSlip/Message.scss');
    }
</script>
