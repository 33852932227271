<template>
    <div class="acceptAnyOdds">
        <CheckBox
            id="acceptAnyOdds"
            v-bind:gtmLabel="isAcceptAnyOdds ? 'AcceptAnyOdds_UnCheck' : 'AcceptAnyOdds_Check'"
            v-bind:checked="isAcceptAnyOdds"
            v-bind:onclick="toggleIsAcceptAnyOdds"
        >
            <template #label>
                <span class="labelText">
                    {{ i18n.t('bet_slip_accept_any_odds') }}
                </span>
            </template>
        </CheckBox>
    </div>
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import type { PropType } from 'vue';
    import CheckBox from '@/components/common/CheckBox.vue';
    import { i18n } from '@/core/lib/i18n';
    import { useCustomerStore } from '@/store/customerStore';

    const props = defineProps({
        setMessage: {
            type: Function as PropType<(message: string) => void>,
            default: () => false,
        },
    });

    const { isAcceptAnyOdds } = storeToRefs(useCustomerStore());
    const { updateIsAcceptAnyOdds } = useCustomerStore();

    const toggleIsAcceptAnyOdds = (async (checked: boolean) => {
        try {
            await updateIsAcceptAnyOdds(checked);
        } catch (error) {
            props.setMessage(i18n.t('bet_slip_error_message_general_failure'));
        }
    });

</script>
