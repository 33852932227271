<template>
    <div class="myBetRowTitle">
        <strong>
            {{ i18n.t(`market_type_name_${MarketType.BetBuilder}`) }}
            -
            {{ i18n.t(`sport_type_name_${bet.sportType}`) }}
            -
            {{ legsCountDisplay }}
        </strong>
    </div>

    <BetBuilderSelection
        v-for="leg in bet.betBuilderLegs"
        v-bind:key="leg.transactionId"
        v-bind:leg="leg"
        v-bind:isInvalidStatus="bet.isInvalidStatus"
    />
    <component
        v-bind:is="bet.isInvalidStatus ? 'del' : 'div'"
    >
        <ul style="margin-bottom: 4px;">
            <li>
                {{ bet.homeTeamName }} -{{ i18n.t('vs') }}- {{ bet.awayTeamName }}
                <span v-if="!bet.isLive">
                    @ {{ showTimeDisplay }}
                </span>
            </li>
            <li>
                {{ bet.leagueName }}
            </li>
        </ul>
    </component>
    <Result v-bind:bet="bet" />
</template>

<script lang="ts" setup>
    import { computed, toRefs } from 'vue';
    import BetBuilderSelection from '@/components/myBets/BetBuilderSelection.vue';
    import Result from '@/components/myBets/Result.vue';
    import { betBuilderSelectionsDisplay, useBetDisplay } from '@/components/myBets/useBetDisplay';
    import { i18n } from '@/core/lib/i18n';
    import type { IBet } from '@/interface/IBet';
    import { MarketType } from '@/interface/enum';

    const props = defineProps<{
        bet: IBet;
    }>();

    const { bet } = toRefs(props);

    const legsCountDisplay = computed(() => betBuilderSelectionsDisplay(bet.value.betBuilderLegs?.length ?? 0));
    const { showTimeDisplay } = useBetDisplay(bet);

</script>

<style lang="scss" scoped>
    @import "@/components/myBets/MyBetRow";
</style>
