import { cloneDeep } from 'lodash';
import { defineStore, storeToRefs } from 'pinia';
import { computed, ref, watchEffect } from 'vue';
import { useEventEmitter } from '@/composable/useEventEmitter';
import { useLocalStorage } from '@/composable/useLocalStorage';
import { useSessionStorage } from '@/composable/useSessionStorage';
import { Api } from '@/core/lib/api';
import { convertToEuroPrice } from '@/core/lib/oddsHelper';
import { pick } from '@/core/lib/utils';
import { EnumHandicapType, EnumOddsStatus } from '@/core/oddsApi/oddsApiType';

import type { IBetSlip, ITicket } from '@/interface/IBetSlip';
import { BetSlipType } from '@/interface/IBetSlip';
import type { IEvent } from '@/interface/IEvent';
import type { IOdds, IPrice } from '@/interface/IOdds';
import type { IOutrightLeague } from '@/interface/IOutrightLeague';
import type { IOutrightOdds } from '@/interface/IOutrightOdds';
import type { IBetBuilderBetSlip, IBetBuilderMarket, IBetBuilderTicket, ISelection } from '@/interface/betBuilder';
import {
    HandicapType,
    LeagueSize,
    MarketType,
    OddsOption,
    OpenTicketStatus,
    PriceStyle,
    SiteStyle,
    SportType,
} from '@/interface/enum';
import type { AsiaBetPageType } from '@/interface/enum';
import { useCustomerStore } from '@/store/customerStore';
import { useToggleStore } from '@/store/toggleStore';

const pickSingleKeys = ['event', 'odds', 'priceOption', 'betPage'] as const;
type SessionSingleTicket = Pick<ITicket, typeof pickSingleKeys[number]>;
const pickBetBuilderKeys = ['match', 'market', 'selection', 'betPage'] as const;
type SessionBetBuilderTicket = Pick<IBetBuilderTicket, typeof pickBetBuilderKeys[number]>;

const getDefaultBetSlip = (type: BetSlipType.Single | BetSlipType.Parlay | BetSlipType.Outright): IBetSlip => ({
    type,
    uid: '',
    minBet: 0,
    maxBet: 0,
    balance: 0,
    message: OpenTicketStatus.Success,
    tickets: [],
});

const getDefaultEvent = (): IEvent => (
    {
        id: 0,
        eventCode: '',
        eventDate: new Date(),
        hasStatistics: false,
        liveCourtMatchId: 0,
        showTime: new Date(),
        showTimeType: 0,
        marketTypeCount: {},
        isLive: false,
        isTvLive: false,
        isSboLive: false,
        tvChannels: [],
        kickOffTime: new Date(),
        eventResults: [],
        handicapType: EnumHandicapType.None,
        isMixParlay: false,
        extraInfo: { injuryTime: 0, period: 0, round: 0, statisticsUrl: '', periodStartTime: '' },
        awayTeam: { name: '', redCard: 0, liveScore: 0, isFavorite: false },
        homeTeam: { name: '', redCard: 0, liveScore: 0, isFavorite: false },
        league: { id: 0, name: '', info: '', size: LeagueSize.Small },
        sportType: SportType.Unknown,
        stream: { videoUrl: null, betRadar: null, gLive: null, betConstruct: null },
        outrightIds: [],
    }
);

const getDefaultOdds = (): IOdds => (
    {
        id: 0,
        isLive: false,
        hasMixParlay: false,
        point: 0,
        handicapType: HandicapType.Unknown,
        status: EnumOddsStatus.Unknown,
        betCondition: '',
        sortOrder: 0,
        sportType: SportType.Unknown,
        marketType: MarketType.Unknown,
        prices: [],
        eventResult: {
            id: 0,
            eventId: 0,
            handicapType: HandicapType.Unknown,
            marketGroup: {
                id: 0,
                name: '',
                awayExtension: '',
                awayTemplate: '',
                displayPriority: 0,
                homeExtension: '',
                homeTemplate: '',
                isSingle: false,
                isYesNo: false,
            },
            liveHomeScore: 0,
            liveAwayScore: 0,
        },
    }
);

export const useBetSlipStore = defineStore('BetSlip', () => {
    const eventEmitter = useEventEmitter();
    const { accountId } = storeToRefs(useCustomerStore());
    const [sessionSingleTicket, setSessionSingleTicket] = useSessionStorage<SessionSingleTicket | null>(
        `singleTicket:${accountId.value}`,
        null,
        {
            decode: v => JSON.parse(v),
            encode: v => JSON.stringify(v),
        },
    );

    const [sessionParlayTicket, setSessionParlayTicket] = useSessionStorage<SessionSingleTicket[] | null>(
        `parlayTicket:${accountId.value}`,
        null,
        {
            decode: v => JSON.parse(v),
            encode: v => JSON.stringify(v),
        },
    );

    const [sessionBetBuilderTicket, setSessionBetBuilderTicket] = useSessionStorage<SessionBetBuilderTicket[] | null>(
        `betBuilderTicket:${accountId.value}`,
        null,
        {
            decode: v => JSON.parse(v),
            encode: v => JSON.stringify(v),
        },
    );

    const [isShowLastStake, setIsShowLastStake] = useLocalStorage<boolean>(`lastStake:isShowLastStake:${accountId.value}`, false);
    const [singleLastStake, setSingleLastStake] = useLocalStorage<number>(`lastStake:single:${accountId.value}`, 0);
    const [parlayLastStake, setParlayLastStake] = useLocalStorage<number>(`lastStake:parlay:${accountId.value}`, 0);

    const singleBetSlip = ref<IBetSlip | null>(null);
    const singleTicket = computed<ITicket | null>(() => {
        if (!singleBetSlip.value) return null;
        return singleBetSlip.value.tickets[0];
    });
    const parlayBetSlip = ref<IBetSlip | null>(null);
    const betBuilderBetSlip = ref<IBetBuilderBetSlip | null>(null);

    const removeBetBuilderBetSlip = () => {
        betBuilderBetSlip.value = null;
    };

    const addToSingleBetSlip = (event: IEvent, odds: IOdds, priceOption: IPrice, betPage: AsiaBetPageType, type = BetSlipType.Single | BetSlipType.Outright) => {
        removeBetBuilderBetSlip();

        singleBetSlip.value = {
            ...getDefaultBetSlip(type),
            tickets: [{
                key: `${odds.id}-${priceOption.option}`,
                betPage,
                priceStyle: PriceStyle.Unknown,
                event: cloneDeep(event),
                odds: cloneDeep(odds),
                priceOption: cloneDeep(priceOption),
            }],
        };
    };

    function getParlayTicketKey(odds: IOdds, priceOption: IPrice) {
        return `${odds.id}-${priceOption.option}`;
    }

    const addToParlayBetSlip = (event: IEvent, odds: IOdds, priceOption: IPrice, betPage: AsiaBetPageType) => {
        if (parlayBetSlip.value === null) {
            parlayBetSlip.value = {
                ...getDefaultBetSlip(BetSlipType.Parlay),
            };
        }
        const key = getParlayTicketKey(odds, priceOption);
        const newSubBet: ITicket = {
            key,
            betPage,
            priceStyle: PriceStyle.Unknown,
            event: cloneDeep(event),
            odds: cloneDeep(odds),
            priceOption: cloneDeep(priceOption),
        };

        parlayBetSlip.value = {
            ...parlayBetSlip.value,
            tickets: [
                // not allowed for same event
                ...parlayBetSlip.value.tickets.filter(t => t.event.id !== event.id),
                newSubBet,
            ],
        };

        eventEmitter.emit('betSlip:addToParlayBetSlip');
    };

    const addSingleToParlayBetSlip = (event: IEvent, odds: IOdds, priceOption: IPrice, betPage: AsiaBetPageType, priceStyle: PriceStyle) => {
        const newPriceOption = convertToEuroPrice(priceOption.price, priceStyle);
        addToParlayBetSlip(event, odds, { ...priceOption, price: newPriceOption }, betPage);
    };

    const addToOutrightBetSlip = (league: IOutrightLeague, odds: IOutrightOdds, betPage: AsiaBetPageType) => {
        betBuilderBetSlip.value = null;
        const defaultEvent = getDefaultEvent();
        singleBetSlip.value = {
            ...getDefaultBetSlip(BetSlipType.Outright),
            tickets: [{
                key: `${odds.id}-${OddsOption.OR}`,
                betPage,
                priceStyle: PriceStyle.Unknown,
                event: {
                    ...defaultEvent,
                    id: league.id,
                    isLive: league.isLive,
                    sportType: league.sportType,
                    league: {
                        ...defaultEvent.league,
                        id: league.id,
                        name: league.name,
                        info: league.info,
                    },
                    homeTeam: { ...defaultEvent.homeTeam, name: odds.teamName ?? '' },
                    awayTeam: { ...defaultEvent.awayTeam, name: odds.teamName ?? '' },
                },
                odds: {
                    ...getDefaultOdds(),
                    id: odds.id,
                    isLive: odds.isLive,
                    status: EnumOddsStatus.Running,
                    sportType: league.sportType,
                    marketType: MarketType.OutRight,
                    prices: [],
                },
                priceOption: {
                    price: odds.price,
                    option: OddsOption.OR,
                },
            }],
        };
    };

    const updateSingleBetSlip = (updateFn: (betSlip: IBetSlip) => IBetSlip) => {
        if (!singleBetSlip.value) return;
        singleBetSlip.value = updateFn({ ...singleBetSlip.value });
    };

    const removeSingleBetSlip = () => {
        if (singleBetSlip.value) {
            Api.clearTicket();
            singleBetSlip.value = null;
        }
    };

    const updateParlayBetSlip = (updateFn: (parlayBetSlip: IBetSlip) => IBetSlip) => {
        if (!parlayBetSlip.value) return;
        parlayBetSlip.value = updateFn({ ...parlayBetSlip.value });
    };

    const removeParlayBetSlip = () => {
        parlayBetSlip.value = null;
    };

    const removeParlayTicket = (key: string) => {
        if (!parlayBetSlip.value) return;

        parlayBetSlip.value = {
            ...parlayBetSlip.value,
            tickets: parlayBetSlip.value.tickets.filter(t => t.key !== key),
        };

        if (parlayBetSlip.value.tickets.length === 0) {
            removeParlayBetSlip();
        }
    };

    const addToBetBuilderBetSlip = (match: IEvent, market: IBetBuilderMarket, selection: ISelection, _betPage: AsiaBetPageType) => {
        removeSingleBetSlip();

        const newTicket: IBetBuilderTicket = {
            key: selection.id.toString(),
            match,
            selection,
            sportType: match.sportType,
            market,
            betPage: _betPage,
        };

        const isInSameMatch = newTicket.match.id === betBuilderBetSlip.value?.tickets[0].match.id;
        if (betBuilderBetSlip.value && isInSameMatch) {
            betBuilderBetSlip.value = {
                ...betBuilderBetSlip.value,
                tickets: [
                    ...betBuilderBetSlip.value.tickets,
                    newTicket,
                ],
            };
        } else {
            betBuilderBetSlip.value = {
                type: BetSlipType.BetBuilder,
                uid: '',
                minBet: 0,
                maxBet: 0,
                balance: 0,
                tickets: [newTicket],
                price: { value: 0, style: PriceStyle.Euro },
                markets: [],
                message: OpenTicketStatus.Success,
                updatingStatus: 'none',
            };
        }
    };

    const updateBetBuilderBetSlip = (updateFn: (betSlip: IBetBuilderBetSlip) => IBetBuilderBetSlip) => {
        if (!betBuilderBetSlip.value) return;
        betBuilderBetSlip.value = updateFn({ ...betBuilderBetSlip.value });
    };

    const removeBetBuilderTicket = (key: string) => {
        if (!betBuilderBetSlip.value) return;

        betBuilderBetSlip.value = {
            ...betBuilderBetSlip.value,
            tickets: betBuilderBetSlip.value.tickets.filter(ticket => ticket.key !== key),
        };

        if (betBuilderBetSlip.value.tickets.length === 0) {
            betBuilderBetSlip.value = null;
        }
    };

    if (sessionParlayTicket.value) {
        sessionParlayTicket.value.forEach(ticket => addToParlayBetSlip(
            ticket.event,
            ticket.odds,
            ticket.priceOption,
            ticket.betPage,
        ));
    }

    if (sessionSingleTicket.value) {
        addToSingleBetSlip(
            sessionSingleTicket.value.event,
            sessionSingleTicket.value.odds,
            sessionSingleTicket.value.priceOption,
            sessionSingleTicket.value.betPage,
            sessionSingleTicket.value.odds.marketType === MarketType.OutRight ? BetSlipType.Outright : BetSlipType.Single,
        );
    }

    if (sessionBetBuilderTicket.value) {
        sessionBetBuilderTicket.value.forEach(ticket => addToBetBuilderBetSlip(
            ticket.match,
            ticket.market,
            ticket.selection,
            ticket.betPage,
        ));
    }

    watchEffect(() => {
        if (singleTicket.value) {
            setSessionSingleTicket(pick<ITicket>(singleTicket.value, pickSingleKeys));
        } else {
            setSessionSingleTicket(null);
        }
    });

    watchEffect(() => {
        if (parlayBetSlip.value) {
            setSessionParlayTicket(parlayBetSlip.value.tickets.map(ticket => pick<ITicket>(ticket, pickSingleKeys)));
        } else {
            setSessionParlayTicket(null);
        }
    });

    const { isBetBuilderEnabled } = storeToRefs(useToggleStore(SiteStyle.Asia));
    watchEffect(() => {
        if (!isBetBuilderEnabled.value) {
            setSessionBetBuilderTicket(null);
        } else if (betBuilderBetSlip.value) {
            setSessionBetBuilderTicket(betBuilderBetSlip.value.tickets.map(ticket => pick<IBetBuilderTicket>(ticket, pickBetBuilderKeys)));
        } else {
            setSessionBetBuilderTicket(null);
        }
    });

    return {
        singleBetSlip,
        singleTicket,
        addToSingleBetSlip,
        updateSingleBetSlip,
        removeSingleBetSlip,

        getParlayTicketKey,
        parlayBetSlip,
        addToParlayBetSlip,
        addSingleToParlayBetSlip,
        updateParlayBetSlip,
        removeParlayTicket,
        removeParlayBetSlip,

        addToOutrightBetSlip,

        betBuilderBetSlip,
        addToBetBuilderBetSlip,
        updateBetBuilderBetSlip,
        removeBetBuilderBetSlip,
        removeBetBuilderTicket,

        isShowLastStake,
        setIsShowLastStake,
        singleLastStake,
        setSingleLastStake,
        parlayLastStake,
        setParlayLastStake,
    };
});
