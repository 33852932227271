<template>
    <div
        class="tab_header_item hover_overlay"
        v-bind:class="{ active: isActive }"
        v-on:click="selectTab(name)"
    >
        <div class="tab_title">
            <div class="tab_title_icon">
                <SvgIcon
                    v-if="isSmall"
                    name="icon-system-collapse-arrow"
                />
                <slot v-else name="icon">
                    <SvgIcon
                        v-bind:name="icon"
                        size="lg"
                    />
                </slot>
            </div>
            <span>&nbsp;{{ label }}</span>
        </div>
        <SvgIcon
            v-if="enableCollapse && !isSmall && isActive"
            name="icon-system-arrow"
            v-bind:rotated="!isExpand"
        />
    </div>
</template>

<script lang="ts" setup>
    import { computed, onMounted, onUnmounted } from 'vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import { TabsContext } from '@/components/tabs/TabsContext';

    const props = defineProps({
        name: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
        isSmall: {
            type: Boolean,
            default: false,
        },
    });

    const { currentTab, registerTab, unregisterTab, selectTab, isExpand, enableCollapse } = TabsContext.inject();
    const isActive = computed(() => props.name === currentTab.value);

    onMounted(() => {
        registerTab(props.name);
    });

    onUnmounted(() => {
        unregisterTab(props.name);
    });
</script>

<style lang="scss" scoped>
    @import "@/components/tabs/Tab";
</style>
