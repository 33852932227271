import type { FormatCurrencyOptions, VMoneyOptions } from '@sports-utils/money';
import { CurrencyType, getDefaultMoneyOptions, getSeparators, toSboCurrencyFormat, unformatSboCurrency } from '@sports-utils/money';
import { get, useBroadcastChannel } from '@vueuse/core';
import { defineStore } from 'pinia';
import type { MaybeRef } from 'vue';
import { computed, watch } from 'vue';
import { useState } from '@/composable/useState';
import { Api } from '@/core/lib/api';
import { hashString } from '@/core/lib/hashHelper';
import { setI18nSiteStyle } from '@/core/lib/i18n';
import type { AcceptAnyCashOutBroadcastMessage, PriceStyleBroadcastMessage, SiteStyleBroadcastMessage } from '@/interface/broadcastchannel';
import type { SportType } from '@/interface/enum';
import {
    BrandType,
    BroadcastChannelName,
    DisplayTime,
    HashType,
    LicenseType,
    OddsDisplayMode,
    OddsSorting,
    PlayerType,
    PriceStyle,
    SboCurrency,
    SiteStyle,
    Sound,
} from '@/interface/enum';

function convertToSportEnums(sports?: string): SportType[] | null {
    if (!sports) return null;
    return sports.split(',').map(parseInt) as unknown as SportType[];
}

export const useCustomerStore = defineStore('CustomerInfo', () => {
    const [playerType, setPlayerType] = useState(PlayerType.B2C);
    const [licenseType, setLicense] = useState(LicenseType.IOM);
    const [brandType, setBrandType] = useState(BrandType.Sbobet);
    const [isMainDomain, setIsMainDomain] = useState(false);
    const [ipCountryCode, setIpCountryCode] = useState('--');
    const [sboCurrency, setSboCurrency] = useState(SboCurrency.TB);
    const [isAcceptAnyOdds, setIsAcceptAnyOdds] = useState(false);
    const [isAcceptAnyCashOutValue, setIsAcceptAnyCashOutValue] = useState(false);
    const [priceStyle, setPriceStyle] = useState(PriceStyle.Euro);
    const [priceStyleOptions, setPriceStyleOptions] = useState<PriceStyle[]>([]);
    const [defaultStake, setDefaultStake] = useState(0);
    const [isQ, setIsQ] = useState(false);
    const [isEFootballEnabled, setIsEFootballEnabled] = useState(false);
    const [accountId, setAccountId] = useState('');
    const [loginName, setLoginName] = useState('');
    const [sportSorting, setSportSorting] = useState<SportType[] | null>([]);
    const [isOpenScoreAlert, setIsOpenScoreAlert] = useState(false);
    const [oddsSorting, setOddsSorting] = useState(OddsSorting.Normal);
    /* Please don't read directly. Use `oddsDisplaySettingsStore` instead. */
    const [_oddsDisplayMode, setOddsDisplayMode] = useState(OddsDisplayMode.Double);
    const [displayTime, setDisplayTime] = useState(DisplayTime.SystemTime);
    const [trackingId, setTrackingId] = useState('');

    const [siteStyle, _setSiteStyle] = useState(SiteStyle.Asia);
    const setSiteStyle = (siteStyle: SiteStyle) => {
        if (siteStyle === SiteStyle.Unknown) {
            _setSiteStyle(SiteStyle.Asia);
        } else {
            _setSiteStyle(siteStyle);
        }
    };
    watch(siteStyle, () => setI18nSiteStyle(siteStyle.value), { immediate: true });

    const defaultMoneyConfig = computed<VMoneyOptions>(() => ({
        ...getDefaultMoneyOptions({ currencyType: CurrencyType.Sbo, currency: sboCurrency.value }),
        disableNegative: false,
        allowBlank: false,
    }));

    async function initCustomerInfo() {
        const [{ data: response }, { data: location }] = await Promise.all([
            Api.getCustomerInfo(),
            Api.getLocation(),
        ]);
        setPlayerType(PlayerType.fromPollux(response.playerType));
        setLicense(LicenseType.fromPollux(response.license));
        setPriceStyle(PriceStyle.fromPollux(response.priceStyle));
        setPriceStyleOptions(response.priceStyleOptions?.map(style => PriceStyle.fromPollux(style)) ?? []);
        setDefaultStake(response.defaultStake);
        setSboCurrency(response.currency as SboCurrency);
        setIsAcceptAnyOdds(response.isAcceptAnyOdds);
        setIsAcceptAnyCashOutValue(response.isAcceptAnyCashOutValue);
        setIpCountryCode(location.countryCode ?? '--');
        setIsQ(response.isQ);
        setIsEFootballEnabled(response.isEFootballEnabled);
        setAccountId(response.accountId ?? '');
        setLoginName(response.loginName ?? '');
        setSportSorting(convertToSportEnums(response.sportSorting));
        setIsOpenScoreAlert(isUserOpenScoreAlert(response.playSound));
        setOddsSorting(OddsSorting.fromPollux(response.oddsSorting));
        setOddsDisplayMode(OddsDisplayMode.fromPollux(response.oddsDisplayMode));
        setBrandType(BrandType.fromPollux(location.brand));
        setIsMainDomain(location.isMainDomain);
        setDisplayTime(response.displayTime);
        setTrackingId(response.trackingId ?? '');
        setSiteStyle(SiteStyle.fromPollux(response.siteStyle));
    }

    const updateIsAcceptAnyOdds = (async (isAcceptAnyOdds: boolean) => {
        try {
            setIsAcceptAnyOdds(isAcceptAnyOdds);
            await Api.updateIsAcceptAnyOdds(isAcceptAnyOdds);
        } catch (error) {
            setIsAcceptAnyOdds(!isAcceptAnyOdds);
            throw error;
        }
    });

    const { post } = useBroadcastChannel<AcceptAnyCashOutBroadcastMessage, AcceptAnyCashOutBroadcastMessage>({ name: BroadcastChannelName.AcceptAnyCashOutValue });
    const updateIsAcceptAnyCashOutValue = (async (isAcceptAnyCashOutValue: boolean) => {
        try {
            setIsAcceptAnyCashOutValue(isAcceptAnyCashOutValue);
            await Api.updateIsAcceptAnyCashOutValue(isAcceptAnyCashOutValue);
            post({ isAcceptAnyCashOutValue });
        } catch (error) {
            setIsAcceptAnyCashOutValue(!isAcceptAnyCashOutValue);
            throw error;
        }
    });

    const { post: postPriceStyle, data: priceStyleMessage } = useBroadcastChannel<PriceStyleBroadcastMessage, PriceStyleBroadcastMessage>({ name: BroadcastChannelName.PriceStyle });
    const updatePriceStyle = (async (newPriceStyle: PriceStyle) => {
        const oldPriceStyle = priceStyle.value;
        try {
            setPriceStyle(newPriceStyle);
            await Api.updatePriceStyle(newPriceStyle);
            postPriceStyle({ priceStyle: newPriceStyle });
        } catch (error) {
            setPriceStyle(oldPriceStyle);
            throw error;
        }
    });

    watch(priceStyleMessage, () => {
        setPriceStyle(priceStyleMessage.value.priceStyle);
    });

    const updateOddsSorting = (async (newOddsSorting: OddsSorting) => {
        const oldOddsSorting = oddsSorting.value;
        try {
            setOddsSorting(newOddsSorting);
            await Api.updateOddsSorting(newOddsSorting);
        } catch (error) {
            setOddsSorting(oldOddsSorting);
            throw error;
        }
    });

    const updateOddsDisplayMode = (async (newOddsDisplayMode: OddsDisplayMode) => {
        const oldOddsDisplayMode = _oddsDisplayMode.value;
        try {
            setOddsDisplayMode(newOddsDisplayMode);
            await Api.updateOddsDisplayMode(newOddsDisplayMode);
        } catch (error) {
            setOddsDisplayMode(oldOddsDisplayMode);
            throw error;
        }
    });

    const updateDisplayTime = (async (newDisplayTime: DisplayTime) => {
        const oldDisplayTime = displayTime.value;
        try {
            setDisplayTime(newDisplayTime);
            await Api.updateDisplayTime(newDisplayTime);
        } catch (error) {
            setDisplayTime(oldDisplayTime);
            throw error;
        }
    });

    const updatePlaySound = (async () => {
        setIsOpenScoreAlert(!isOpenScoreAlert.value);
        try {
            await Api.updatePlaySound(isOpenScoreAlert.value ? Sound.Whistle : Sound.Unknown);
        } catch (error) {
            setIsOpenScoreAlert(!isOpenScoreAlert.value);
            throw error;
        }
    });

    const { post: postSiteStyle, data: siteStyleMessage } = useBroadcastChannel<SiteStyleBroadcastMessage, SiteStyleBroadcastMessage>({ name: BroadcastChannelName.SiteStyle });
    const updateSiteStyle = (async (newSiteStyle: SiteStyle) => {
        const oldSiteStyle = siteStyle.value;
        if (oldSiteStyle === newSiteStyle) return;
        try {
            setSiteStyle(newSiteStyle);
            await Api.updateSiteStyle(newSiteStyle);
            postSiteStyle({ siteStyle: newSiteStyle });
        } catch (error) {
            setSiteStyle(oldSiteStyle);
            throw error;
        }
    });
    watch(siteStyleMessage, () => {
        if (siteStyleMessage.value.siteStyle !== siteStyle.value) {
            window.location.reload();
        }
    });

    const isMnlB2C = computed(() => [PlayerType.B2C, PlayerType.B2B2C].includes(playerType.value) && licenseType.value === LicenseType.MNL);
    const isAsiaSite = computed(() => siteStyle.value === SiteStyle.Asia);
    const isIom = computed(() => licenseType.value === LicenseType.IOM);

    const isIndoUserByCountryCode = computed(() => ipCountryCode.value === 'ID');

    const hashedAccountId = computed(() => hashString(accountId.value ?? '', HashType.MD5));

    const brandName = computed(() => BrandType[brandType.value].toUpperCase());

    const formatMoney = (value: MaybeRef<number>) => toSboCurrencyFormat(get(value), sboCurrency.value, { minPrecision: 0, maxPrecision: 0 });
    const formatMoneyWithDecimal = (value: MaybeRef<number>, option?: FormatCurrencyOptions) => toSboCurrencyFormat(get(value), sboCurrency.value, option);
    const unformatMoney = (value: string) => unformatSboCurrency(value, sboCurrency.value);
    const moneySeparators = computed(() => getSeparators({
        currencyType: CurrencyType.Sbo,
        currency: sboCurrency.value,
    }));

    return {
        siteStyle,
        updateSiteStyle,
        defaultStake,
        priceStyle,
        updatePriceStyle,
        priceStyleOptions,
        playerType,
        isMnlB2C,
        licenseType,
        brandType,
        isMainDomain,
        ipCountryCode,
        isIndoUserByCountryCode,
        sboCurrency,
        isAcceptAnyOdds,
        updateIsAcceptAnyOdds,
        isAcceptAnyCashOutValue,
        updateIsAcceptAnyCashOutValue,
        setIsAcceptAnyCashOutValue,
        isQ,
        isEFootballEnabled,
        accountId,
        hashedAccountId,
        loginName,
        defaultMoneyConfig,
        initCustomerInfo,
        isIom,
        isAsiaSite,
        sportSorting,
        oddsSorting,
        updateOddsSorting,
        _oddsDisplayMode,
        updateOddsDisplayMode,
        displayTime,
        setDisplayTime,
        updateDisplayTime,
        isOpenScoreAlert,
        setIsOpenScoreAlert,
        trackingId,
        updatePlaySound,
        formatMoney,
        formatMoneyWithDecimal,
        unformatMoney,
        moneySeparators,
        brandName,
    };
});

function isUserOpenScoreAlert(playSound: string | undefined): boolean {
    return playSound?.startsWith('sound_') ?? false;
}
