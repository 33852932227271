<template>
    <Tabs v-model:isExpand="isMyBetsExpanded" v-gtm:type="'MyBet'">
        <Tab
            v-gtm:label="isMyBetsExpanded ? 'Collapse' : 'Expand'"
            v-gtm:@click
            name="myBets"
            icon="icon-system-mini-my-bets"
            v-bind:label="i18n.t('tab_title_mybets')"
            v-bind:isSmall="!isLayoutExpanded"
        />
        <template #myBets>
            <div class="myBetContainer">
                <div class="blockTopOperate">
                    <div class="blockTopOperate_item">
                        <CheckBox
                            v-if="isCashOutEnabled"
                            id="cashOut"
                            v-bind:gtmLabel="isDisplayCashOut ? 'Switch_CashOut_Off' : 'Switch_CashOut_On'"
                            v-bind:checked="isDisplayCashOut"
                            v-bind:onclick="setIsDisplayCashOut"
                        >
                            <template #label>
                                <span class="labelText">
                                    {{ i18n.t('cash_out') }}
                                </span>
                            </template>
                        </CheckBox>
                        <VTooltip v-if="isCashOutEnabled">
                            <SvgIcon
                                name="icon-system-cycle-info"
                                size="lg"
                                class="cashOutInfo_icon"
                            />
                            <template #content>
                                <ul class="cashOutInfo_list">
                                    <li
                                        v-gtm:@view
                                        v-gtm:label="'Tooltip'"
                                        class="cashOutInfo_list_item"
                                    >
                                        {{ i18n.t('cash_out_tooltip') }}
                                        <a
                                            v-gtm:label="'Tooltip_LearnMore'"
                                            v-gtm:@click
                                            v-bind:href="cashOutLearnMoreUrl"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {{ i18n.t('learn_more') }}
                                        </a>
                                    </li>
                                </ul>
                            </template>
                        </VTooltip>
                    </div>
                    <div class="blockTopOperate_item">
                        <VButton
                            v-gtm:label="'Refresh'"
                            v-gtm:@click
                            flat
                            tile
                            class="myBets_refresh"
                            v-on:click="refresh(true, true)"
                        >
                            <template #icon>
                                <SvgIcon
                                    name="icon-system-refresh"
                                    size="16"
                                    left
                                    v-bind:rotating="isRefreshing"
                                />
                            </template>
                            <span class="refresher_title">
                                {{ countdown }}
                            </span>
                        </VButton>
                    </div>
                </div>
                <div v-if="isDisplayCashOut" class="blockTopOperate">
                    <div class="blockTopOperate_item">
                        <AcceptAnyCashOutValue />
                        <VTooltip>
                            <SvgIcon
                                name="icon-system-cycle-info"
                                size="lg"
                                class="cashOutInfo_icon"
                            />
                            <template #content>
                                <ul class="cashOutInfo_list">
                                    <li
                                        v-gtm:@view
                                        v-gtm:label="'AcceptAnyCashOut_Tooltip'"
                                        class="cashOutInfo_list_item"
                                    >
                                        {{ i18n.t('accept_any_cash_out_value_tooltip') }}
                                    </li>
                                </ul>
                            </template>
                        </VTooltip>
                    </div>
                </div>
                <Message
                    v-bind:show="!!betSlipMessage"
                    v-bind:message="betSlipMessage"
                    position="top"
                    padding="4px 9px"
                    status="success"
                    v-bind:progressDuration="messageDuration"
                />
                <div v-if="miniMyBets.length === 0" class="myBets myBets-empty">
                    <NoBet />
                </div>
                <div v-else class="myBets">
                    <template
                        v-for="bet in miniMyBets"
                        v-bind:key="bet.transactionId"
                    >
                        <MultipleBet v-if="bet.isMixParlay || bet.isBetBuilder" v-bind:bet="bet" />
                        <OutrightBet v-else-if="bet.isOutright" v-bind:bet="bet" />
                        <SingleBet v-else v-bind:bet="bet" />
                    </template>
                </div>
            </div>
        </template>
        <template #footer>
            <VButton
                v-gtm:label="'ViewAll'"
                v-gtm:@click
                block
                tile
                class="viewAll"
                color="primary"
                textColor="white"
                hoverTextColor="white"
                v-on:click="openMyBets"
            >
                {{ i18n.t('mini_my_bets_view_all') }}
            </VButton>
        </template>
    </Tabs>
</template>

<script lang="ts" setup>
    import { useBroadcastChannel, useTimeoutFn } from '@vueuse/core';
    import { storeToRefs } from 'pinia';
    import { computed, watch } from 'vue';
    import Message from '@/components/betSlip/Message.vue';
    import AcceptAnyCashOutValue from '@/components/cashOut/AcceptAnyCashOutValue.vue';
    import { useCashOutRefresher } from '@/components/cashOut/useCashOutRefresher';
    import CheckBox from '@/components/common/CheckBox.vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import VButton from '@/components/common/button/VButton.vue';
    import { accountDbReplicationDelay, cashOutedDelay } from '@/components/myBets/betHelper';
    import MultipleBet from '@/components/myBets/miniMyBets/MultipleBet.vue';
    import NoBet from '@/components/myBets/miniMyBets/NoBet.vue';
    import OutrightBet from '@/components/myBets/miniMyBets/OutrightBet.vue';
    import SingleBet from '@/components/myBets/miniMyBets/SingleBet.vue';
    import { useRefresher } from '@/components/myBets/miniMyBets/useRefresher';
    import { useDisplayCashOut } from '@/components/myBets/useDisplayCashOut';
    import Tab from '@/components/tabs/Tab.vue';
    import Tabs from '@/components/tabs/Tabs.vue';
    import { useAutoResetState } from '@/composable/useAutoResetState';
    import { useIdle } from '@/composable/useIdle';
    import { useMyBets } from '@/composable/useMyBets';
    import { GetCashOutLearnMoreUrl } from '@/core/lib/helpCenter';
    import { i18n } from '@/core/lib/i18n';
    import { VTooltip } from '@/directives/tooltip';
    import type { AcceptAnyCashOutBroadcastMessage, BetSlipBroadcastMessage } from '@/interface/broadcastchannel';
    import { BroadcastChannelName, SiteStyle } from '@/interface/enum';
    import { useCustomerStore } from '@/store/customerStore';
    import { useLayoutStore } from '@/store/layoutStore';
    import { useMiniMyBetsStore } from '@/store/miniMyBetsStore';
    import { useToggleStore } from '@/store/toggleStore';

    const messageDuration = 3000;
    const [betSlipMessage, setBetSlipMessage] = useAutoResetState('', messageDuration);
    const { miniMyBets } = storeToRefs(useMiniMyBetsStore());
    const { isLayoutExpanded, isMyBetsExpanded } = storeToRefs(useLayoutStore());
    const { refresh, forceRefresh, isRefreshing, countdown } = useRefresher();
    const { refreshCashOutValues } = useMiniMyBetsStore();
    const { refresh: cashOutRefresh } = useCashOutRefresher(refreshCashOutValues);
    const { toggleMyBetsExpanded } = useLayoutStore();
    const { isCashOutEnabled } = storeToRefs(useToggleStore(SiteStyle.Asia));

    const { brandType } = storeToRefs(useCustomerStore());

    const { isDisplayCashOut, setIsDisplayCashOut } = useDisplayCashOut();

    const { data } = useBroadcastChannel<BetSlipBroadcastMessage, BetSlipBroadcastMessage>({ name: BroadcastChannelName.BetSlip });
    watch(data, () => {
        toggleMyBetsExpanded(true);
        const message = data.value.isLive ? i18n.t('bet_slip_live_success_message') : i18n.t('bet_slip_non_live_success_message');
        setBetSlipMessage(message);
        useTimeoutFn(() => forceRefresh(false, false), accountDbReplicationDelay);
    });

    const { data: cashOuted } = useBroadcastChannel({ name: BroadcastChannelName.CashOut });
    watch(cashOuted, () => {
        useTimeoutFn(() => forceRefresh(false, false), cashOutedDelay);
    });

    const { setIsAcceptAnyCashOutValue } = useCustomerStore();
    const { data: acceptAnyCashOutValue } = useBroadcastChannel<AcceptAnyCashOutBroadcastMessage, AcceptAnyCashOutBroadcastMessage>({ name: BroadcastChannelName.AcceptAnyCashOutValue });
    watch(acceptAnyCashOutValue, () => {
        setIsAcceptAnyCashOutValue(acceptAnyCashOutValue.value.isAcceptAnyCashOutValue);
    });

    const cashOutLearnMoreUrl = computed(() => GetCashOutLearnMoreUrl(brandType.value, i18n.locale.value));

    const { openMyBets } = useMyBets();

    // Automatically recover from idle state with a force refresh
    // This is to prevent the user from losing data when they are idle
    const { onActive } = useIdle(60 * 1000);
    onActive(() => {
        // eslint-disable-next-line no-console
        console.log('MiniMyBets:Recovering from idle state...');
        refresh(true, true);
        cashOutRefresh();
    });
</script>

<style lang="scss" scoped>
    @import "@/components/myBets/miniMyBets/MiniMyBetsBlock";
</style>
