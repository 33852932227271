import { unformat } from '@sports-utils/money';
import type { Ref } from 'vue';
import { computed, ref } from 'vue';
import { useStakeConfig } from '@/composable/useStakeDisplay';
import { useCustomerStore } from '@/store/customerStore';

export function useStakeInput(stake: Ref<number> = ref(0)) {
    const setStake = (value: number) => {
        stake.value = value;
    };
    const {
        formatStakeConfig,

        hasMultiplier,
        multiplier,
        stakeInputMaxLength,
    } = useStakeConfig();

    const { formatMoney } = useCustomerStore();
    const stakeDisplay = computed(() => {
        const value = formatMoney(Math.floor(stake.value / multiplier.value));
        return value === '0' ? '' : value;
    });

    const setDisplayToStake = (value: string) => {
        const newStake = unformat(value, formatStakeConfig.value) as number;
        setStake(newStake * multiplier.value);
    };

    return {
        stake,
        setStake,
        stakeDisplay,
        hasMultiplier,
        setDisplayToStake,
        formatStakeConfig,
        stakeInputMaxLength,
    };
}
