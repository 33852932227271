<template>
    <Tabs
        v-model:isExpand="isBetSlipExpanded"
        v-gtm:type="'BetSlip'"
    >
        <Tab
            v-gtm:label="isBetSlipExpanded ? 'Collapse' : 'Expand'"
            v-gtm:@click
            name="ticket"
            icon="icon-system-ticket"
            v-bind:label="i18n.t('tab_title_ticket')"
            v-bind:isSmall="!isLayoutExpanded"
            class="notranslate"
        />
        <template #ticket>
            <SubTabs
                v-model="betSlipActiveTab"
                style="height: 100%;"
            >
                <SubTab
                    v-bind:name="TabName.Single"
                    v-bind:label="i18n.t('single')"
                    showIndicator
                    class="notranslate"
                />
                <SubTab
                    v-bind:name="TabName.Parlay"
                    v-bind:label="i18n.t('parlay')"
                    v-bind:count="parlayBetSlipCount"
                    showIndicator
                    class="notranslate"
                />
                <template #Single>
                    <BetSlip
                        v-if="singleBetSlip"
                        v-bind:key="singleBetSlip.type === BetSlipType.Single ? 'single' : 'outright'"
                        v-bind:betSlip="singleBetSlip"
                    />
                    <BetBuilderBetSlip
                        v-else-if="betBuilderBetSlip"
                        v-bind:key="'betBuilder' + betBuilderBetSlip.tickets[0]?.match.id"
                        v-bind:betSlip="betBuilderBetSlip"
                    />
                    <NoBet v-else>
                        <VButton
                            v-if="isMixParlay"
                            class="noBetButton"
                            block
                            color="accent"
                            textColor="primary"
                            v-on:click="setSportType(sportType)"
                        >
                            {{ i18n.t('single') }}
                            <Count
                                right
                                v-bind:number="singleCount"
                                bgColor="primary"
                                textColor="white"
                            />
                        </VButton>
                    </NoBet>
                </template>
                <template #Parlay>
                    <BetSlip
                        v-if="parlayBetSlip"
                        v-bind:betSlip="parlayBetSlip"
                    />
                    <NoBet v-else>
                        <VButton
                            v-if="!isMixParlay"
                            class="noBetButton"
                            block
                            color="accent"
                            textColor="primary"
                            v-on:click="setMarketPage(MarketPage.MixParlay)"
                        >
                            {{ i18n.t('market_page_name_4') }}
                            <Count
                                right
                                v-bind:number="mixParlayCount"
                                bgColor="primary"
                                textColor="white"
                            />
                        </VButton>
                    </NoBet>
                </template>
            </SubTabs>
        </template>
    </Tabs>
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import { computed } from 'vue';
    import BetBuilderBetSlip from './BetBuilderBetSlip.vue';
    import BetSlip from '@/components/betSlip/BetSlip.vue';
    import NoBet from '@/components/betSlip/NoBet.vue';
    import Count from '@/components/common/Count.vue';
    import VButton from '@/components/common/button/VButton.vue';
    import SubTab from '@/components/subTabs/SubTab.vue';
    import SubTabs from '@/components/subTabs/SubTabs.vue';
    import Tab from '@/components/tabs/Tab.vue';
    import Tabs from '@/components/tabs/Tabs.vue';
    import { i18n } from '@/core/lib/i18n';
    import { BetSlipType } from '@/interface/IBetSlip';
    import { MarketPage, TabName } from '@/interface/enum';
    import { useAsiaSportMarketInfoStore } from '@/store/asiaSportMarketInfoStore';
    import { useBetSlipStore } from '@/store/betSlipStore';
    import { useLayoutStore } from '@/store/layoutStore';

    const { sportType, isMixParlay } = storeToRefs(useAsiaSportMarketInfoStore());
    const { setMarketPage, setSportType } = useAsiaSportMarketInfoStore();
    const { isLayoutExpanded, isBetSlipExpanded, betSlipActiveTab } = storeToRefs(useLayoutStore());

    const { singleBetSlip, parlayBetSlip, betBuilderBetSlip } = storeToRefs(useBetSlipStore());
    const { singleCount, mixParlayCount } = storeToRefs(useAsiaSportMarketInfoStore());
    const parlayBetSlipCount = computed(() => parlayBetSlip.value?.tickets.length ?? 0);
</script>

<style lang="scss" scoped>
    .noBetButton {
        margin-bottom: map-get($spacers, md);
    }
</style>
