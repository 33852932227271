/**
 * @param ttl - expiration time in milliseconds (ms)
 */
export class ExpiryCache<K, T> {
    constructor(private readonly ttl: number) {
        if (ttl <= 0) {
            throw new Error('ttl needs to be greater than 0');
        }
    }

    private cache = new Map<K, {
        value: T;
        timeoutId: number;
    }>();

    public has(key: K) {
        return this.cache.has(key);
    }

    public get(key: K): T | null {
        return this.cache.get(key)?.value ?? null;
    }

    public set(key: K, value: T) {
        window.clearTimeout(this.cache.get(key)?.timeoutId);

        this.cache.set(key, {
            value,
            timeoutId: window.setTimeout(() => this.cache.delete(key), this.ttl),
        });
    }

    public delete(key: K) {
        window.clearTimeout(this.cache.get(key)?.timeoutId);

        this.cache.delete(key);
    }

    public clear() {
        this.cache.clear();
    }

    public get size() {
        return this.cache.size;
    }
}
