import { useTimeoutFn } from '@vueuse/core';
import { computed } from 'vue';
import { useState } from '@/composable/useState';
import type { IBetSlipMessage } from '@/interface/IBetSlipMessage';
import { BetSlipMessagePriority } from '@/interface/enum';

export const useBetSlipMessage = () => {
    const defaultMessage: IBetSlipMessage = {
        message: '',
        priority: BetSlipMessagePriority.Unknown,
    };

    const [_message, _setMessage] = useState<IBetSlipMessage>(defaultMessage);
    const message = computed(() => _message.value.message);
    const resetMessage = () => {
        _setMessage(defaultMessage);
    };
    const { start: startAutoResetMessage, stop: stopAutoResetMessage } = useTimeoutFn(() => {
        resetMessage();
    }, 5000, { immediate: false });

    const setMessage = (message: string, priority: BetSlipMessagePriority = BetSlipMessagePriority.Error) => {
        if (priority < _message.value.priority) return;
        stopAutoResetMessage();
        _setMessage({
            message, priority,
        });
    };

    return {
        message,
        setMessage,
        resetMessage,

        startAutoResetMessage,
        stopAutoResetMessage,
    };
};
