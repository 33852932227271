<template>
    <div class="selection">
        <div
            class="selection_line"
            v-bind:class="bet.isLive ? 'live' : ''"
        />
        <div
            class="selection_content"
            v-bind:class="{ del: bet.isInvalidStatus }"
        >
            <ul>
                <li>
                    <strong>{{ title }}</strong>
                </li>
                <li class="betOption">
                    <div class="contentGroup" v-bind:class="{ 'optionTeam-favorite': betOption.isFavorite }">
                        {{ betOption.name }}
                    </div>
                    <div v-if="pointDisplay" class="contentGroup">
                        @ {{ pointDisplay }}
                    </div>
                    <div v-if="scoreDisplay" class="contentGroup">
                        @ {{ scoreDisplay }}
                    </div>
                    <div v-if="bet.isMixParlay" class="contentGroup">
                        @ {{ priceDisplay }} ({{ priceStyleDisplay }})
                    </div>
                </li>
                <li v-if="correctScoreExcludingScores">
                    {{ i18n.t('odds_option_cs_excluding') }}
                    {{ correctScoreExcludingScores }}
                </li>
                <li v-if="!bet.isOutright" class="teamName">
                    {{ bet.homeTeamName }} -{{ i18n.t('vs') }}- {{ bet.awayTeamName }}
                </li>
                <li>
                    {{ bet.leagueName }}
                    <div v-if="!bet.isOutright && !bet.isLive" class="contentGroup">
                        @ {{ showTimeDisplay }}
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <Result v-bind:bet="bet" />
</template>

<script lang="ts" setup>
    import { toRefs } from 'vue';
    import Result from '@/components/myBets/Result.vue';
    import { useBetDisplay } from '@/components/myBets/useBetDisplay';
    import { i18n } from '@/core/lib/i18n';
    import type { IBaseBet } from '@/interface/IBet';

    const props = defineProps<{
        bet: IBaseBet;
    }>();

    const { bet } = toRefs(props);
    const {
        betOption, title, pointDisplay, priceDisplay, scoreDisplay,
        priceStyleDisplay, correctScoreExcludingScores,
        showTimeDisplay,
    } = useBetDisplay(bet);

</script>

<style lang="scss" scoped>
    @import "@/components/myBets/Selection";
</style>
