<template>
    <div class="single">
        <div v-if="haveBetSlip" class="subHeader">
            <div class="description">
                {{ `${ i18n.t('bet_slip_selection_count', { count: singleBetSlips.length }, singleBetSlips.length)}` }}
            </div>
            <div
                v-gtm:@click
                v-gtm:label="'RemoveAll'"
                class="action"
                v-on:click="removeAll"
            >
                {{ i18n.t('remove_all') }}
            </div>
        </div>
        <BetSlip
            v-for="betSlip in singleBetSlips"
            v-bind:key="betSlip.key"
            v-bind:betSlip="betSlip"
        />
        <div v-if="singleBetSlips.length" class="stakePerBet">
            <span class="stakePerBetTitle">{{ i18n.t('stake_per_bet') }}</span>
            <span
                v-gtm:@click
                v-gtm:label="'StakePerBet'"
            >
                <StakeInput
                    v-bind:stakeDisplay="stakeDisplay"
                    v-bind:setDisplayToStake="setDisplayToStake"
                />
            </span>
        </div>
        <div class="placeBetBlock">
            <template v-if="singleBetSlips.length > 0">
                <AcceptAnyOdds class="placeBetBlockItem" />

                <div v-if="isVoucherEnabled" class="redeemVoucher">
                    <CheckBox
                        id="redeemVoucher"
                        v-bind:checked="voucherEnabled"
                        v-bind:gtmLabel="voucherEnabled ? 'Voucher_UnCheck' : 'Voucher_Check'"
                        v-bind:onclick="setVoucherEnabled"
                    >
                        <template #label>
                            <span class="labelText">
                                {{ i18n.t('redeem_voucher') }}
                            </span>
                        </template>
                    </CheckBox>
                    <SvgIcon
                        v-tooltip="i18n.t('voucher_tooltip_1') + ' ' + i18n.t('voucher_tooltip_2')"
                        class="icon"
                        name="icon-system-cycle-info"
                    />
                </div>

                <div v-if="voucherEnabled" class="voucherStakeBlock">
                    <div class="detailText_item placeBetBlockItem">
                        <!-- need translation for other language -->
                        <span>{{ i18n.t('voucher_stake') }}</span>
                        <span class="amount">
                            <CurrencyIcon />
                            {{ toSboCurrencyFormat(voucherTotalStake, sboCurrency) }}
                        </span>
                    </div>
                    <div class="detailText_item placeBetBlockItem">
                        <strong>{{ i18n.t('stake') }}</strong>
                        <strong class="amount">
                            <CurrencyIcon />
                            {{ toSboCurrencyFormat(normalTotalStake, sboCurrency) }}
                        </strong>
                    </div>
                </div>

                <div class="detailText_item placeBetBlockItem">
                    <span>{{ i18n.t('total_stake') }}</span>
                    <span class="amount">
                        <CurrencyIcon />
                        {{ singleTotalStakeDisplay }}
                    </span>
                </div>
                <div class="detailText_item placeBetBlockItem">
                    <strong>{{ i18n.t('bet_slip_total_potential_returns') }}</strong>
                    <strong class="amount">
                        <CurrencyIcon />
                        {{ singleMaxPayout }}
                    </strong>
                </div>
                <template v-for="message in displayMessages" v-bind:key="message">
                    <Message
                        class="placeBetBlockItem"
                        v-bind:show="!!message"
                        position="top"
                        iconSize="lg"
                        status="danger"
                        rounded
                        v-bind:message="message"
                        v-bind:icon="`icon-system-warningCircle`"
                    />
                </template>
                <PlaceBetBlockEuro
                    class="placeBetBlockItem"
                    v-bind:betSlipType="BetSlipType.Single"
                />
            </template>
            <NoBetSlip
                v-else
                v-bind:buttonGtmLabel="'GoBetting'"
                v-bind:title="i18n.t('go_betting')"
                v-bind:onClick="goToBettingSingle"
            >
                <template #message>
                    <template v-for="message in displayMessages" v-bind:key="message">
                        <Message
                            class="bottomMessage"
                            v-bind:show="!!message"
                            position="top"
                            iconSize="lg"
                            status="danger"
                            rounded
                            v-bind:message="message"
                            v-bind:icon="`icon-system-warningCircle`"
                        />
                    </template>
                </template>
            </NoBetSlip>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { toSboCurrencyFormat } from '@sports-utils/money';
    import { storeToRefs } from 'pinia';
    import { computed } from 'vue';
    import AcceptAnyOdds from '@/components/betSlip/AcceptAnyOdds.vue';
    import BetSlip from '@/components/betSlip/BetSlip.Euro.vue';
    import Message from '@/components/betSlip/Message.vue';
    import NoBetSlip from '@/components/betSlip/NoBetSlip.vue';
    import PlaceBetBlockEuro from '@/components/betSlip/PlaceBetBlockEuro.vue';
    import StakeInput from '@/components/betSlip/StakeInput.vue';
    import CheckBox from '@/components/common/CheckBox.vue';
    import CurrencyIcon from '@/components/common/CurrencyIcon.vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import { useEuroRouter } from '@/composable/useEuroRouter';
    import { useStakeInput } from '@/composable/useStakeInput';
    import { i18n } from '@/core/lib/i18n';
    import { getMaxPayout, getVoucherMaxPayout } from '@/core/lib/ticket';
    import { BetSlipType } from '@/interface/IBetSlip';
    import { useEuroBetSlipStore } from '@/store/betSlipStore.euro';
    import { useCustomerStore } from '@/store/customerStore';
    import { useToggleStore } from '@/store/toggleStore';

    const { singleBetSlips, voucherEnabled } = storeToRefs(useEuroBetSlipStore());
    const { setVoucherEnabled, useBetSlipsStoreByType } = useEuroBetSlipStore();

    const { betSlipMessage, removeAll: _removeAll, placedErrorMessages } = useBetSlipsStoreByType(BetSlipType.Single);
    const displayMessages = computed(() => [...placedErrorMessages.value, betSlipMessage.value]);

    const haveBetSlip = computed(() => singleBetSlips.value.length > 0);
    const removeAll = () => {
        if (haveBetSlip.value) {
            _removeAll();
        }
    };

    const { sboCurrency } = storeToRefs(useCustomerStore());
    const { formatMoneyWithDecimal } = useCustomerStore();
    const voucherTotalStake = computed(() => (singleBetSlips.value.filter(x => x.voucherId).reduce((acc, curr) => acc + curr.stake, 0)));
    const normalTotalStake = computed(() => (singleBetSlips.value.filter(x => !x.voucherId).reduce((acc, curr) => acc + curr.stake, 0)));
    const singleTotalStakeDisplay = computed(() => formatMoneyWithDecimal((voucherTotalStake.value + normalTotalStake.value)));
    const singleMaxPayout = computed(() => {
        const total = singleBetSlips.value.reduce((acc, curr) => {
            const maxPayout = curr.voucherId
                ? getVoucherMaxPayout(curr.stake, curr.tickets[0].priceOption.price, curr.tickets[0].priceStyle)
                : getMaxPayout(curr.stake, curr.tickets[0].priceOption.price, curr.tickets[0].priceStyle);
            return acc + maxPayout;
        }, 0);
        return toSboCurrencyFormat(total, sboCurrency.value);
    });

    const betSlipStake = computed({
        get: () => {
            const singleBetSlipsWithoutVoucher = singleBetSlips.value.filter(x => !voucherEnabled.value || !x.voucherId);
            if (singleBetSlipsWithoutVoucher.length === 0) {
                return 0;
            }

            // stake per bet will auto follow the value when
            // all bet slips have the same stake
            return singleBetSlipsWithoutVoucher.every(x => x.stake === singleBetSlipsWithoutVoucher[0].stake)
                ? singleBetSlipsWithoutVoucher[0].stake
                : 0;
        },
        set: (value: number) => {
            singleBetSlips.value.forEach((x) => {
                if (voucherEnabled.value && x.voucherId) return;
                x.stake = value;
            });
        },
    });
    const { stakeDisplay, setDisplayToStake } = useStakeInput(betSlipStake);

    const { isVoucherEnabled } = storeToRefs(useToggleStore());

    const { goToHomePage: goToBettingSingle } = useEuroRouter();
</script>

<style lang="scss" scoped>
    @import "@/components/betSlip/BetSlipBlock.Euro";

    .form-input {
        width: 120px;
    }
</style>
