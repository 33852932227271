import { get } from '@vueuse/core';
import type { MaybeRef } from 'vue';
import { computed, ref } from 'vue';
import { deepCompareComputed } from '@/composable/deepCompareComputed';
import { i18n } from '@/core/lib/i18n';
import type { EnumOddsCategoryType } from '@/core/oddsApi/oddsApiType';
import { useEventEuroSubscription } from '@/core/oddsApi/oddsApiType';
import { EnumLanguage } from '@/interface/enum';

export function useEventSubscription(
    matchId: MaybeRef<number>,
    oddsCategory: EnumOddsCategoryType,
    isActive = ref(true),
) {
    const variables = computed(() => ({
        query: {
            id: get(matchId),
            oddsCategory,
            lang: EnumLanguage.toOddsApi(i18n.locale.value),
        },
    }));

    const { onSubscription } = useEventEuroSubscription(
        variables,
        deepCompareComputed(() => ({
            enabled: isActive.value,
        })),
    );

    return {
        onSubscription,
    };
}
