const MyBetsWindow = {
    name: 'MyBets',
    config: 'width=830,height=600',
};

export function useMyBets() {
    function openMyBets() {
        window.open('/my-bets', MyBetsWindow.name, MyBetsWindow.config);
    }

    return {
        openMyBets,
    };
}
