import { defineStore } from 'pinia';
import { watch } from 'vue';
import { useState } from '@/composable/useState';

export const useScrollableStore = defineStore('scrollable', () => {
    const [scrollLockCount, setScrollLockCount] = useState(0);

    watch(scrollLockCount, () => {
        if (scrollLockCount.value > 0) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.removeProperty('overflow');
        }
    });

    function setIsScrollable(scrollable: boolean) {
        if (scrollable) {
            const count = Math.max(scrollLockCount.value - 1, 0);
            setScrollLockCount(count);
        } else {
            setScrollLockCount(scrollLockCount.value + 1);
        }
    }

    return {
        setIsScrollable,
    };
});
