import { defineStore } from 'pinia';
import { watch } from 'vue';
import { formatBet } from '@/components/myBets/betHelper';
import { useState } from '@/composable/useState';
import { Api } from '@/core/lib/api';
import { i18n } from '@/core/lib/i18n';
import type { IBet } from '@/interface/IBet';

export const useSettledBetsStore = defineStore('SettledBets', () => {
    const [date, setDate] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [settledBets, setSettledBets] = useState<IBet[]>([]);

    const refreshSettledBets = async () => {
        try {
            const { data } = await Api.getSettledBetList(date.value);
            setSettledBets(data.map(bet => formatBet(bet)) ?? []);
            setIsLoaded(true);
        } catch (error) {
            console.error(error);
        }
    };

    watch(i18n.platformLanguageCookie, () => {
        refreshSettledBets();
    });

    watch(date, () => {
        refreshSettledBets();
    });

    return {
        date,
        setDate,
        isLoaded,
        settledBets,
    };
});
