import type { Ref } from 'vue';
import { computed, watch } from 'vue';
import { getMarketRule } from '@/components/euroDisplay/oddsDisplay/marketRules';
import { useAutoResetState } from '@/composable/useAutoResetState';
import { useState } from '@/composable/useState';
import {
    isShowPoint as _isShowPoint,
    formatPointEuro,
    formatPrice,
    getMarketGroupId,
    isCS,
} from '@/core/lib/oddsHelper';
import { formatLiveScore, getScore, isShowLiveScore } from '@/core/oddsApi/helpers/liveScore';
import type { ITicketEuro } from '@/interface/IBetSlip';
import { OddsOption } from '@/interface/enum';

export const useTicketDisplay = (ticket: Ref<ITicketEuro>) => {
    const price = computed(() => ticket.value.priceOption.price);

    const { bigBetOptionFormatter, marketTypeDisplay } = getMarketRule(ticket.value.odds.marketType);

    const marketGroupName = computed(() => marketTypeDisplay(ticket.value.odds.eventResult.marketGroup, ticket.value.odds.marketType));

    const optionDisplay = computed(() => bigBetOptionFormatter(ticket.value.event, ticket.value.odds, ticket.value.priceOption));

    const priceDisplay = computed(() => formatPrice(price.value, ticket.value.odds.marketType));

    const isShowPoint = computed(() => _isShowPoint(ticket.value.odds.marketType, getMarketGroupId(ticket.value.odds)));
    const pointDisplay = computed(() => formatPointEuro(ticket.value.odds.point, ticket.value.odds.marketType, ticket.value.priceOption.option).join(', '));
    const [oldPoint, setOldPoint] = useState(ticket.value.odds.point);
    const oldPointDisplay = computed(() => formatPointEuro(oldPoint.value, ticket.value.odds.marketType, ticket.value.priceOption.option).join(', '));
    const [isPointChange, setPointChange] = useAutoResetState(false, 3000);
    watch(() => ticket.value.odds.point, (_, oldVal) => {
        setPointChange(true);
        setOldPoint(oldVal);
    });

    const liveScoreDisplay = computed(() => {
        if (!isShowLiveScore(ticket.value.event.sportType, ticket.value.odds.isLive, ticket.value.odds.marketType)) return null;

        const score = getScore([], getMarketGroupId(ticket.value.odds), ticket.value.event);
        return score ? formatLiveScore(score.home, score.away, '-') : null;
    });
    const [isScoreChange, setScoreChange] = useAutoResetState(false, 3000);
    watch(liveScoreDisplay, () => setScoreChange(true));

    const isLiveCorrectScoreAos = computed(() => ticket.value.odds.isLive && isCS(ticket.value.odds) && ticket.value.priceOption.option === OddsOption.CS_Others);
    const correctScoreExcludingScores = computed(() => {
        if (!isLiveCorrectScoreAos.value) return null;
        return ticket.value.odds.betCondition.split(',').join(', ');
    });

    return {
        marketGroupName,
        optionDisplay,

        isShowPoint,
        pointDisplay,
        isPointChange,
        oldPointDisplay,

        price,
        priceDisplay,

        liveScoreDisplay,
        isScoreChange,
        correctScoreExcludingScores,
    };
};
