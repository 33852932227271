<template>
    <div
        ref="sportBody"
        v-resize="handleLayoutResize"
        v-gtm:page="'K_Lobby'"
        v-gtm:identity="PlayerType[playerType]"
        v-gtm:license="LicenseType[licenseType]"
        v-gtm:currency="sboCurrency"
        v-gtm:uuid="trackingId"
        class="sportBody"
        v-bind:class="locale"
    >
        <div
            v-if="isCasaHeaderEnabled"
            id="shared-component"
            v-resize="handleHeaderResize"
            data-product="Sports"
        >
            <!-- will be replaced by real header when casa is mounted -->
            <div class="placeholderHeader" />
        </div>
        <FakeHeader v-else />

        <section class="container">
            <div class="columns" v-bind:class="{ sideBarCollapsed: !isLayoutExpanded }">
                <LeftColumn />
                <div
                    v-resize="columnCenterResize"
                    class="column column-center"
                >
                    <TopBanner class="component" />
                    <RouterView />
                </div>
                <div class="column column-right">
                    <div v-if="isCasinoWidgetEnabled" id="casino-entry" />
                    <div
                        v-if="isMiniGameWidgetEnabled"
                        id="mini-game-entry"
                        v-bind:data-site-style="SiteStyle[siteStyle]"
                    />
                </div>
            </div>
        </section>
        <div v-if="isCasaHeaderEnabled" id="shared-footer" />

        <ScoreAlert v-if="isOpenScoreAlert && isScoreAlertEnabled" />

        <div id="games-end-match" />
        <JackpotWinnerPopup />
        <PopupBlock />
    </div>
</template>

<script lang="ts" setup>
    import { loadScript } from '@sports-utils/dom';
    import { useCssVar, useEventListener } from '@vueuse/core';
    import { storeToRefs } from 'pinia';
    import { ref } from 'vue';
    import PopupBlock from '@/components/PopupBlock.vue';
    import TopBanner from '@/components/banner/TopBanner.vue';
    import FakeHeader from '@/components/header/FakeHeader.vue';
    import JackpotWinnerPopup from '@/components/jackpot/JackpotWinnerPopup.vue';
    import LeftColumn from '@/components/layout/LeftColumn.vue';
    import { updateOddsDisplayWidth } from '@/components/oddsDisplay/oddsTableCalculation';
    import ScoreAlert from '@/components/scoreAlert/ScoreAlert.vue';
    import { useBackToClassicDesktop } from '@/composable/useBackToClassicDesktop';
    import { useEventEmitter } from '@/composable/useEventEmitter';
    import { useMyBets } from '@/composable/useMyBets';
    import { usePlatform } from '@/composable/usePlatform';
    import { useTitle } from '@/composable/useTitle';
    import { i18n } from '@/core/lib/i18n';
    import { getCasaUrl, getCasinoWidgetUrl, getMiniGameUrl } from '@/core/lib/url';
    import type { SizeInfo } from '@/directives/resize';
    import { BreakPoint, LicenseType, PageType, PlayerType, SiteStyle } from '@/interface/enum';
    import { useCustomerStore } from '@/store/customerStore';
    import { useLayoutStore } from '@/store/layoutStore';
    import { useScoreAlertStore } from '@/store/scoreAlertStore';
    import { useToggleStore } from '@/store/toggleStore';

    document.body.classList.add('sports-background');

    const { locale } = i18n;
    const { playerType, licenseType, sboCurrency, trackingId, isOpenScoreAlert, siteStyle } = storeToRefs(useCustomerStore());

    const isCasaHeaderEnabled = __ENABLE_CASA_HEADER__;
    if (isCasaHeaderEnabled) {
        loadScript(`${getCasaUrl()}/shared-component.js?v=${Date.now()}`);
    }

    const { isCasinoWidgetEnabled, isScoreAlertEnabled, isMiniGameWidgetEnabled } = useToggleStore(SiteStyle.Asia);
    if (isCasinoWidgetEnabled) {
        loadScript(getCasinoWidgetUrl());
    }

    if (isMiniGameWidgetEnabled) {
        loadScript(getMiniGameUrl());
    }

    const { isLayoutExpanded } = storeToRefs(useLayoutStore());
    const { toggleLayoutExpanded } = useLayoutStore();

    const handleLayoutResize = ({ width }: SizeInfo) => {
        if (width > BreakPoint.XL && !isLayoutExpanded.value) {
            toggleLayoutExpanded(true);
        }
    };

    const sportBody = ref<HTMLElement | null>(null);
    const accountHeaderSize = useCssVar('--size-account-header', sportBody, { initialValue: '70px' });
    const handleHeaderResize = ({ height }: SizeInfo) => {
        accountHeaderSize.value = `${height >= 70 ? 70 : 35}px`;
    };

    useTitle(PageType.Home);

    const { onOpenMyBets, safariBackPageCallBack, onBackToClassicDesktop } = usePlatform();
    safariBackPageCallBack();

    const { openMyBets } = useMyBets();
    onOpenMyBets(openMyBets);
    const { backToClassicDesktop } = useBackToClassicDesktop();
    onBackToClassicDesktop(backToClassicDesktop);

    const { initAudioAutoPlay } = useScoreAlertStore();
    const cleanup = useEventListener(window, 'click', () => {
        initAudioAutoPlay();
        cleanup();
    });

    const eventEmitter = useEventEmitter();
    function columnCenterResize(sizeInfo: SizeInfo) {
        updateOddsDisplayWidth(sizeInfo);
        eventEmitter.emit('columnCenter:resize');
    }
</script>

<style lang="scss">
    @import "@/scss/Home";
</style>
