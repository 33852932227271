<template>
    <div class="placeholder">
        <div class="league-name">
            {{ leagueName }}
        </div>
        <div
            v-for="event in eventList"
            v-if="!isCollapsed"
            v-bind:key="event.id"
            v-bind:style="{
                height: getPlaceHolderHeight(event),
            }"
        >
            {{ event.homeTeam.name }}
            <br>
            {{ event.awayTeam.name }}
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { toRefs } from '@vueuse/core';
    import { storeToRefs } from 'pinia';
    import { type PropType } from 'vue';
    import { OddsDisplayContext } from '@/components/oddsDisplay/OddsDisplayContext';
    import { getMatchPlaceHolderHeight, getMoreMarketPlaceHolderHeight } from '@/components/oddsDisplay/oddsTableCalculation';
    import type { IEvent } from '@/interface/IEvent';
    import { BetTypeGroup } from '@/interface/enum';
    import { useOddsDisplaySettingsStore } from '@/store/oddsDisplaySettingsStore';

    const props = defineProps({
        eventList: {
            type: Object as PropType<IEvent[]>,
            required: true,
        },
    });

    const { eventList } = toRefs(props);

    const { oddsDisplayMode } = storeToRefs(useOddsDisplaySettingsStore());

    const { isGroupCollapsed } = OddsDisplayContext.inject();
    const isCollapsed = isGroupCollapsed(props.eventList[0].id);

    const leagueName = props.eventList[0].league.name;

    const getPlaceHolderHeight = (event: IEvent) => {
        const matchPlaceHolderHeight = getMatchPlaceHolderHeight(oddsDisplayMode.value, event.isLive, event);
        const moreMarketPlaceHolderHeight = getMoreMarketPlaceHolderHeight(oddsDisplayMode.value, event, BetTypeGroup.Main);

        return `${matchPlaceHolderHeight + moreMarketPlaceHolderHeight}px`;
    };

</script>

<style lang="scss" scoped>
.placeholder {
    opacity: 0;
}
</style>
