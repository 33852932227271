<template>
    <div class="oddsBoxWrapper">
        <StaticSpinner
            v-if="!loaded"
            margin="0 auto"
            class="loading"
            v-bind:style="{ 'min-height': `${placeHolderHeight}px` }"
        />
        <template v-else>
            <div
                v-for="oddsItem in sortedOutrightOdds"
                v-bind:key="oddsItem.id"
                class="oddsBoxPack flex-row"
            >
                <OutrightOdds
                    v-bind:league="league"
                    v-bind:odds="oddsItem"
                    align="right"
                />
            </div>
        </template>
    </div>
</template>

<script lang="ts" setup>
    import orderBy from 'lodash/orderBy';
    import type { PropType } from 'vue';
    import { computed, toRefs } from 'vue';
    import StaticSpinner from '@/components/common/StaticSpinner.vue';
    import OutrightOdds from '@/components/euroDisplay/OutrightOdds.vue';
    import { useOutrightOddsEuro } from '@/core/oddsApi/composable/useOutrightOdds.euro';
    import type { IOutrightLeagueEuro } from '@/interface/IOutrightLeague';

    const props = defineProps({
        league: {
            type: Object as PropType<IOutrightLeagueEuro>,
            required: true,
        },
    });

    const { league } = toRefs(props);

    const { outrightOdds, loaded } = useOutrightOddsEuro(league.value.sportType, league.value.id, league.value.isLive);

    const sortedOutrightOdds = computed(() => orderBy(outrightOdds.value, [x => x.price, x => x.teamName.toUpperCase()]));

    const placeHolderHeight = computed(() => Math.ceil(league.value.oddsCount / 2) * (40 + 8));
</script>

<style lang="scss" scoped>
@import "@/components/euroDisplay/oddsDisplay/outright/OutrightMatch";
</style>
