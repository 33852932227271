import NP from 'number-precision';
import { storeToRefs } from 'pinia';
import { i18n } from '@/core/lib/i18n';
import type { ITicket, ITicketEuro } from '@/interface/IBetSlip';
import { OpenTicketStatus, PlaceOrderStatus, PlayerType, PriceStyle } from '@/interface/enum';
import { useCustomerStore } from '@/store/customerStore';

NP.enableBoundaryChecking(false);

export const getActualStake = (stake: number, oddsPrice: number) => {
    if (oddsPrice < 0) {
        return Math.abs(oddsPrice) * stake;
    }
    return stake;
};

export function getMaxPayout(stake: number, price: number, priceStyle: PriceStyle) {
    if (stake === 0) {
        return 0;
    }

    if (priceStyle === PriceStyle.Euro) {
        return stake * price;
    }
    return Math.abs(stake * price) + stake;
}

export function getVoucherMaxPayout(voucherStake: number, price: number, priceStyle: PriceStyle) {
    const voucherActualStake = getActualStake(voucherStake, price);
    return getMaxPayout(voucherStake, price, priceStyle) - voucherActualStake;
}

export function getOpenTicketErrorMessage(errorCode: OpenTicketStatus) {
    switch (errorCode) {
        case OpenTicketStatus.InvalidInput:
        case OpenTicketStatus.InvalidPrice:
            return i18n.t('bet_slip_error_message_general_failure');
        case OpenTicketStatus.EventClosed:
        case OpenTicketStatus.EventCompleted:
        case OpenTicketStatus.EventInternal:
            return i18n.t('bet_slip_error_message_event_closed');
        case OpenTicketStatus.OddsClosed:
        case OpenTicketStatus.OddsCompleted:
        case OpenTicketStatus.OddsInternal:
            return i18n.t('bet_slip_error_message_odds_closed');
        case OpenTicketStatus.MaxOddsExceed:
            return i18n.t('bet_slip_error_message_over_max_odds');
        default:
            return '';
    }
}

type SuccessPlaceOrderStatus =
PlaceOrderStatus.Success
| PlaceOrderStatus.LiveSuccess
| PlaceOrderStatus.EventCheckSuccess
| PlaceOrderStatus.CreditCheckSuccess
| PlaceOrderStatus.MaxWinLoseLimitExceed
;

type SpecialHandlePlaceOrderStatus =
PlaceOrderStatus.HdpPointChange // bet_slip_error_message_hdp_point_change
| PlaceOrderStatus.ScoreChanged// bet_slip_error_message_score_changed
| PlaceOrderStatus.PriceChange // bet_slip_error_message_price_suspend
| PlaceOrderStatus.HierarchyClosed
| PlaceOrderStatus.MemberClosed
;

export type DisplayPlaceOrderStatus = Exclude<PlaceOrderStatus,
| SuccessPlaceOrderStatus
| SpecialHandlePlaceOrderStatus
| PlaceOrderStatus.LiveSuccess
| PlaceOrderStatus.EventCheckSuccess
| PlaceOrderStatus.CreditCheckSuccess
| PlaceOrderStatus.BlackListCheckSuccess
| PlaceOrderStatus.StakeProblem
| PlaceOrderStatus.InvalidCookieIdentity
| PlaceOrderStatus.MinBetUnMet
| PlaceOrderStatus.InvalidPriceStyle
| PlaceOrderStatus.InvalidPriceId
| PlaceOrderStatus.IsNotSingleBet
| PlaceOrderStatus.DeductBalanceFail
| PlaceOrderStatus.InvalidHttpReferer
| PlaceOrderStatus.InvalidPriceStyleForVoucher
| PlaceOrderStatus.InvalidAccountForVoucher
| PlaceOrderStatus.PlayerNotLogin
| PlaceOrderStatus.FailToFulfillBetCountRestrictionForSplitPoint
| PlaceOrderStatus.MPSubBetCountExceed
>;

const PlaceBetErrorMessageMap: Partial<Record<DisplayPlaceOrderStatus, string>> = {
    [PlaceOrderStatus.EventClosed]: 'bet_slip_error_message_event_closed',
    [PlaceOrderStatus.MemberSuspend]: 'bet_slip_error_message_member_suspend',
    [PlaceOrderStatus.HierarchySuspend]: 'bet_slip_error_message_hierarchy_suspend',
    [PlaceOrderStatus.InsufficientCredit]: 'bet_slip_error_message_insufficient_credit',
    [PlaceOrderStatus.InBlackList]: 'bet_slip_error_message_general_failure',
    [PlaceOrderStatus.InvalidPrice]: 'bet_slip_error_message_invalid_odds',
    [PlaceOrderStatus.PriceSuspend]: 'bet_slip_error_message_price_suspend',
    [PlaceOrderStatus.AccountProblem]: 'bet_slip_error_message_account_problem',
    [PlaceOrderStatus.MaxBetExceed]: 'bet_slip_error_message_max_bet_exceed',
    [PlaceOrderStatus.MaxPerMatchExceed]: 'bet_slip_error_message_max_per_match_exceed',
    [PlaceOrderStatus.SportExcluded]: 'bet_slip_error_message_sport_excluded',
    [PlaceOrderStatus.BettingBudgetExceed]: 'bet_slip_error_message_betting_budget_exceed',
    [PlaceOrderStatus.MPMaxPayoutExceed]: 'bet_slip_error_message_mp_max_payout_exceed',
    [PlaceOrderStatus.MPMaxAccumulatedStakeForSubEventExceed]: 'bet_slip_error_message_mp_max_accumulated_stake_for_sub_event_exceed',
    [PlaceOrderStatus.InvalidVoucher]: 'bet_slip_error_message_invalid_voucher',
    [PlaceOrderStatus.InvalidPriceForVoucher]: 'bet_slip_error_message_invalid_price_for_voucher',
    [PlaceOrderStatus.InvalidIp]: 'bet_slip_error_message_invalid_ip',
    [PlaceOrderStatus.CountryIsBlock]: 'bet_slip_error_message_invalid_ip',
    [PlaceOrderStatus.PhoneBetting]: 'bet_slip_error_message_phone_betting_only',
    [PlaceOrderStatus.ThirdPartyTimeout]: 'bet_slip_error_message_third_party_timeout',
    [PlaceOrderStatus.LimitExceedTryLater]: 'bet_slip_error_message_limit_exceed_try_later',
    [PlaceOrderStatus.LimitExceed]: 'bet_slip_error_message_limit_exceed',
    [PlaceOrderStatus.GeneralFailure]: 'bet_slip_error_message_general_failure',
    [PlaceOrderStatus.RetryOpenTicket]: 'bet_slip_error_message_general_failure',
    [PlaceOrderStatus.PTProblem]: 'bet_slip_error_message_account_problem',
    [PlaceOrderStatus.InvalidRefNo]: 'bet_slip_error_message_general_failure',
    [PlaceOrderStatus.IOMWalkinOnly]: 'bet_slip_error_message_iom_walk_in_only',
    [PlaceOrderStatus.InvalidCurrencyForVietnam]: 'bet_slip_error_message_account_problem',
    [PlaceOrderStatus.LessEvent]: 'bet_slip_error_message_less_than_three_bets',
    [PlaceOrderStatus.ExistBetOnSameEvent]: 'bet_slip_error_message_exist_bet_on_same_event',
    [PlaceOrderStatus.NotValidMixParlayCombination]: 'bet_slip_error_message_not_valid_mix_parlay_combination',
    [PlaceOrderStatus.BetBuilderMaxPayoutExceed]: 'betSlip_errorMessage_betBuilderMaxPayoutExceed',
    [PlaceOrderStatus.BetBuilderMaxAccumulatedStakeExceed]: 'betSlip_errorMessage_betBuilderMaxAccumulatedStakeExceed',
    [PlaceOrderStatus.ExistBetWithSameRefNo]: 'bet_slip_error_message_general_failure',
};

export function getErrorMessage(errorCode: PlaceOrderStatus) {
    return PlaceBetErrorMessageMap[errorCode as DisplayPlaceOrderStatus]
        ?? 'bet_slip_error_message_general_failure';
}

export function getPlaceBetErrorMessage(errorCode: PlaceOrderStatus) {
    const errorMessage = getErrorMessage(errorCode);
    return i18n.t(errorMessage);
}

/**
 * @example
 * value = 1.2345
 * decimalPlaces = 2
 *
 * 1. 1.2345e+2 = 123.45
 * 2. Math.floor(123.45) = 123
 * 3. 123e-2 = 1.23
 */
export function roundDown(value: number, decimalPlaces = 0) {
    // Shift the decimal point to the right
    const shiftedValue = `${value}e+${decimalPlaces}`;

    // Round down the shifted value
    const flooredValue = Math.floor(Number(shiftedValue));

    // Shift the decimal point back to the left
    const finalValue = `${flooredValue}e-${decimalPlaces}`;

    // Convert the result back to a number and return
    return Number(finalValue);
}

export function getTotalOddsPrice(tickets: (ITicket | ITicketEuro)[]) {
    if (tickets.length === 0) {
        return 0;
    }

    return roundDown(tickets.reduce((totalOdds, ticket) => NP.times(totalOdds, ticket.priceOption.price), 1), 3);
}

type EuroDisplayPlaceOrderStatus =
    PlaceOrderStatus.PhoneBetting |
    PlaceOrderStatus.InvalidIp |
    PlaceOrderStatus.CountryIsBlock |
    PlaceOrderStatus.BettingBudgetExceed |
    PlaceOrderStatus.MaxBetExceed |
    PlaceOrderStatus.LimitExceed |
    PlaceOrderStatus.LimitExceedTryLater |
    PlaceOrderStatus.PriceChange |
    PlaceOrderStatus.SportExcluded |
    PlaceOrderStatus.MaxPerMatchExceed |
    PlaceOrderStatus.InsufficientCredit |
    PlaceOrderStatus.PriceSuspend |
    PlaceOrderStatus.InvalidPrice |
    PlaceOrderStatus.EventClosed |
    PlaceOrderStatus.ThirdPartyTimeout |
    PlaceOrderStatus.ScoreChanged |
    PlaceOrderStatus.HdpPointChange |
    PlaceOrderStatus.InvalidCurrencyForVietnam |
    PlaceOrderStatus.PTProblem |
    PlaceOrderStatus.InBlackList |
    PlaceOrderStatus.GeneralFailure |
    PlaceOrderStatus.AccountProblem |
    PlaceOrderStatus.HierarchySuspend |
    PlaceOrderStatus.MemberSuspend;

const euroErrorMessageMap: Record<EuroDisplayPlaceOrderStatus, string> = {
    [PlaceOrderStatus.PhoneBetting]: 'bet_slip_error_message_phone_betting_only',
    [PlaceOrderStatus.InvalidIp]: 'bet_slip_error_message_invalid_ip',
    [PlaceOrderStatus.CountryIsBlock]: 'bet_slip_error_message_invalid_ip',
    [PlaceOrderStatus.BettingBudgetExceed]: 'bet_slip_error_message_betting_budget_exceed',
    [PlaceOrderStatus.MaxBetExceed]: 'bet_slip_error_message_stake_update_stake',
    [PlaceOrderStatus.LimitExceed]: 'bet_slip_error_message_stake_update_stake',
    [PlaceOrderStatus.LimitExceedTryLater]: 'bet_slip_error_message_stake_update_stake',
    [PlaceOrderStatus.PriceChange]: 'bet_slip_error_message_stake_accept_price_change',
    [PlaceOrderStatus.SportExcluded]: 'bet_slip_error_message_sport_excluded',
    [PlaceOrderStatus.MaxPerMatchExceed]: 'bet_slip_error_message_max_per_match_exceed',
    [PlaceOrderStatus.InsufficientCredit]: 'bet_slip_error_message_insufficient_credit',
    [PlaceOrderStatus.PriceSuspend]: 'bet_slip_error_message_price_suspend',
    [PlaceOrderStatus.InvalidPrice]: 'bet_slip_error_message_invalid_odds',
    [PlaceOrderStatus.EventClosed]: 'bet_slip_error_message_event_closed',
    [PlaceOrderStatus.ThirdPartyTimeout]: 'bet_slip_error_message_third_party_timeout',
    [PlaceOrderStatus.ScoreChanged]: 'bet_slip_error_message_score_changed',
    [PlaceOrderStatus.HdpPointChange]: 'bet_slip_error_message_point_changed',
    [PlaceOrderStatus.InvalidCurrencyForVietnam]: 'bet_slip_error_message_account_problem',
    [PlaceOrderStatus.PTProblem]: 'bet_slip_error_message_account_problem',
    [PlaceOrderStatus.InBlackList]: 'bet_slip_error_message_general_failure',
    [PlaceOrderStatus.GeneralFailure]: 'bet_slip_error_message_general_failure',
    [PlaceOrderStatus.AccountProblem]: 'bet_slip_error_message_account_problem',
    [PlaceOrderStatus.HierarchySuspend]: 'bet_slip_error_message_hierarchy_suspend',
    [PlaceOrderStatus.MemberSuspend]: 'bet_slip_error_message_member_suspend',
};

export const getPlacedErrorMessage = (status: PlaceOrderStatus, alternateStake: string) => {
    const { brandName, playerType } = storeToRefs(useCustomerStore());

    if (status === PlaceOrderStatus.MaxWinLoseLimitExceed) {
        if (playerType.value === PlayerType.B2B) return i18n.t('bet_slip_error_message_max_win_loss_limit_contact_upper_line');
        return (i18n.t('bet_slip_error_message_max_win_loss_limit_contact_cs'));
    }

    const adjustStake = parseInt(alternateStake);
    if (!Number.isNaN(adjustStake)) {
        return i18n.t('bet_slip_error_message_limit_exceed', { alternateStake });
    }

    const resourceKey = euroErrorMessageMap[status as EuroDisplayPlaceOrderStatus] ?? 'bet_slip_error_message_general_failure';
    return i18n.t(resourceKey, { brandName: brandName.value });
};
