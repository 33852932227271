<template>
    <Breadcrumbs />
    <Navbar v-bind:isShowBottomRow="hasBottomRow" class="sticky euroNavbar">
        <template #navbarTitle>
            <div class="navbarSportList navbarSportList_title">
                <slot name="title" />
            </div>
        </template>
        <template #navbarDivider>
            <NavbarDivider />
        </template>
        <template #topRow>
            <NavbarMain class="navbarMain">
                <NavbarItem
                    v-for="item in items"
                    v-bind:key="item.title"
                    v-gtm:@click
                    v-gtm:label="item.gtmLabel"
                    v-gtm:disabled="!item.gtmLabel"
                    v-gtm:type="'MatchCard'"
                    v-bind:name="item.title"
                    isEuroSite
                    v-bind:hasBottomRow="hasBottomRow"
                    v-bind:isActive="item.isActive === undefined ? false : item.isActive()"
                    v-bind:onclick="item.onClick"
                    countTextColor="white"
                    v-bind:countBgColor="item.isLive ? 'live' : 'nonLive'"
                    v-bind:countBorderColor="item.isLive ? 'live' : 'nonLive'"
                />
            </NavbarMain>
            <NavbarRowItem v-if="marketDropdownPosition === EnumEuroMarketDropdownPosition.Middle">
                <div class="formGroup">
                    <select
                        v-model="market"
                        v-gtm:@click
                        v-gtm:type="'MatchCard'"
                        v-gtm:label="market.gtmLabel"
                        class="select euroSelect"
                    >
                        <option
                            v-for="m in markets"
                            v-bind:key="m.market"
                            v-bind:value="m"
                        >
                            {{ m.name }}
                        </option>
                    </select>
                </div>
            </NavbarRowItem>
        </template>
        <template #bottomRow>
            <NavbarRowItem fluid>
                <SlideTab v-bind:items="subItems" />
            </NavbarRowItem>
            <NavbarRowItem v-if="marketDropdownPosition === EnumEuroMarketDropdownPosition.Bottom">
                <div class="formGroup">
                    <select
                        ref="selectMarket"
                        v-model="market"
                        v-gtm:type="'MatchCard'"
                        v-gtm:label="market.gtmLabel"
                        class="select euroSelect"
                        v-on:change="gtmSelectMarket"
                    >
                        <option
                            v-for="m in markets"
                            v-bind:key="m.market"
                            v-bind:value="m"
                        >
                            {{ m.name }}
                        </option>
                    </select>
                </div>
            </NavbarRowItem>
        </template>
    </Navbar>
</template>

<script lang="ts" setup>
    import { computed, ref } from 'vue';
    import Navbar from '@/components/common/navbar/Navbar.vue';
    import NavbarDivider from '@/components/common/navbar/NavbarDivider.vue';
    import NavbarItem from '@/components/common/navbar/NavbarItem.vue';
    import NavbarMain from '@/components/common/navbar/NavbarMain.vue';
    import NavbarRowItem from '@/components/common/navbar/NavbarRowItem.vue';
    import SlideTab from '@/components/common/slideTab/SlideTab.vue';
    import { EuroDisplayContext } from '@/components/euroDisplay/EuroDisplayContext';
    import Breadcrumbs from '@/components/navbarBlock/euro/Breadcrumbs.vue';
    import { sportsGtm } from '@/core/lib/gtm';
    import type { ISlideTabItem } from '@/interface/ISlideTabItem';
    import { EnumEuroMarketDropdownPosition } from '@/interface/enum';

    export interface INavBarItem {
        title: string;
        isLive?: boolean;
        isActive?: () => boolean;
        onClick: () => void | Promise<void>;
        subItems?: {
            title: string;
            isActive?: () => boolean;
            onClick: () => void | Promise<void>;
        }[];
        gtmLabel?: string;
    }

    const props = withDefaults(defineProps<{
        items: INavBarItem[];
        marketDropdownPosition?: EnumEuroMarketDropdownPosition;
    }>(), {
        marketDropdownPosition: EnumEuroMarketDropdownPosition.None,
    });

    const activeItem = computed(() => props.items.find(item => item.isActive?.()));
    const subItems = computed<ISlideTabItem[]>(() => activeItem.value?.subItems?.map<ISlideTabItem>(item => ({
        key: item.title,
        name: item.title,
        isActive: item.isActive === undefined ? false : item.isActive(),
        onClick: item.onClick,
    })) ?? []);

    const hasBottomRow = computed(() => !!subItems.value.length || props.marketDropdownPosition === EnumEuroMarketDropdownPosition.Bottom);

    const { markets, market } = EuroDisplayContext.inject();

    const selectMarket = ref<HTMLInputElement | null>(null);
    const gtmSelectMarket = () => {
        if (!selectMarket.value) {
            return;
        }
        const event = sportsGtm.getGtmEventFromEl(selectMarket.value);
        sportsGtm.pushToDataLayer('click', event);
    };
</script>

<style lang="scss" scoped>
    @import "@/components/common/navbar/NavbarDropdown";

    .navbarMain {
        margin-left: 8px;
    }
</style>
