import { useIntervalFn } from '@vueuse/core';
import type { ComputedGetter, Ref } from 'vue';
import { readonly, shallowRef, watchEffect } from 'vue';

/**
 * Computed value which updates at regular intervals
 */
export function computedInterval<T>(getter: ComputedGetter<T>, interval: number): Readonly<Ref<T>> {
    const current = shallowRef();

    watchEffect(() => {
        current.value = getter();
    });

    useIntervalFn(() => {
        const newVal = getter();
        if (current.value !== newVal) {
            current.value = newVal;
        }
    }, interval);

    return readonly(current);
}
