import { storeToRefs } from 'pinia';
import { useDisplayCashOut } from '@/components/myBets/useDisplayCashOut';
import { useCountdown } from '@/composable/useCountdown';
import { useToggleStore } from '@/store/toggleStore';

export function useCashOutRefresher(refreshFn: () => Promise<void>) {
    const { cashOutValueRefreshSeconds } = storeToRefs(useToggleStore());
    const { isDisplayCashOut } = useDisplayCashOut();

    const _refresh = async () => {
        await refreshFn();
    };

    const { isLoading: isRefreshing, trigger: refresh, forceTrigger: forceRefresh } = useCountdown(
        cashOutValueRefreshSeconds,
        _refresh,
        { enabled: isDisplayCashOut, immediate: true, minLoadingTime: 1000 },
    );

    return {
        refresh,
        forceRefresh,
        isRefreshing,
    };
}
