import { defineStore } from 'pinia';
import { useEventsQueryBehavior } from '@/composable/useEventsQueryBehavior';
import { useOddsQueryPerEventBehavior } from '@/composable/useOddsQueryPerEventBehavior';

export const useBehaviorStore = defineStore('behavior', () => {
    const { addCount: addEventsQueryCount } = useEventsQueryBehavior();

    const { addCount: addOddsQueryPerEventCount } = useOddsQueryPerEventBehavior();

    return {
        addEventsQueryCount,
        addOddsQueryPerEventCount,
    };
});
