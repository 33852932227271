import { BrandType, EnumLanguage } from '@/interface/enum';

const LangUrlMapping: Partial<Record<BrandType, Record<EnumLanguage, string>>> = {
    [BrandType.Sbobet]: {
        [EnumLanguage.EN]: 'https://help.sbobet.com/article/what-is-cash-out-7298.html',
        [EnumLanguage.ZH_CN]: 'https://help.sbobet.com/article/%e4%bb%80%e4%b9%88%e6%98%af%e6%92%a4%e5%8d%95%e5%a5%97%e7%8e%b0%ef%bc%9f-9155.html',
        [EnumLanguage.ID_ID]: 'https://help.sbobet.com/article/apa-itu-fitur-bayar-sekarang-7326.html',
        [EnumLanguage.TH_TH]: 'https://help.sbobet.com/article/%e0%b8%88%e0%b9%88%e0%b8%b2%e0%b8%a2%e0%b8%ad%e0%b8%ad%e0%b8%81%e0%b8%84%e0%b8%b7%e0%b8%ad%e0%b8%ad%e0%b8%b0%e0%b9%84%e0%b8%a3-7328.html',
        [EnumLanguage.VI_VN]: 'https://help.sbobet.com/article/r%c3%bat-s%e1%bb%9bm-l%c3%a0-g%c3%ac-7350.html',
        [EnumLanguage.JA_JP]: 'https://help.sbobet.com/article/%e3%82%ad%e3%83%a3%e3%83%83%e3%82%b7%e3%83%a5%e3%82%a2%e3%82%a6%e3%83%88%e3%81%a8%e3%81%af%ef%bc%9f-10528.html',
        [EnumLanguage.KO_KR]: 'https://help.sbobet.com/article/%ec%ba%90%ec%8b%9c-%ec%95%84%ec%9b%83%ec%9d%b4-%eb%ac%b4%ec%97%87%ec%9d%b8%ea%b0%80%ec%9a%94-7706.html',
        [EnumLanguage.MY_MM]: 'https://help.sbobet.com/article/what-is-cash-out-7298.html',
        [EnumLanguage.PT_BR]: 'https://help.sbobet.com/article/what-is-cash-out-7298.html',
        [EnumLanguage.KM_KH]: 'https://help.sbobet.com/article/what-is-cash-out-7298.html',
        [EnumLanguage.BN_BD]: 'https://help.sbobet.com/article/what-is-cash-out-7298.html',
    },
    [BrandType.Sbotop]: {
        [EnumLanguage.EN]: 'https://help.sbotop.com/article/what-is-cash-out-11153.html',
        [EnumLanguage.ZH_CN]: 'https://help.sbotop.com/article/%e4%bb%80%e4%b9%88%e6%98%af%e6%92%a4%e5%8d%95%e5%a5%97%e7%8e%b0%ef%bc%9f-11154.html',
        [EnumLanguage.ID_ID]: 'https://help.sbotop.com/article/apa-itu-fitur-bayar-sekarang-11155.html',
        [EnumLanguage.TH_TH]: 'https://help.sbotop.com/article/%e0%b8%88%e0%b9%88%e0%b8%b2%e0%b8%a2%e0%b8%ad%e0%b8%ad%e0%b8%81%e0%b8%84%e0%b8%b7%e0%b8%ad%e0%b8%ad%e0%b8%b0%e0%b9%84%e0%b8%a3-11156.html',
        [EnumLanguage.VI_VN]: 'https://help.sbotop.com/article/r%c3%bat-s%e1%bb%9bm-l%c3%a0-g%c3%ac-8347.html',
        [EnumLanguage.JA_JP]: 'https://help.sbotop.com/article/%e3%82%ad%e3%83%a3%e3%83%83%e3%82%b7%e3%83%a5%e3%82%a2%e3%82%a6%e3%83%88%e3%81%a8%e3%81%af%ef%bc%9f-11157.html',
        [EnumLanguage.KO_KR]: 'https://help.sbotop.com/article/%ec%ba%90%ec%8b%9c-%ec%95%84%ec%9b%83%ec%9d%b4-%eb%ac%b4%ec%97%87%ec%9d%b8%ea%b0%80%ec%9a%94-11158.html',
        [EnumLanguage.MY_MM]: 'https://help.sbotop.com//article/%e1%80%84%e1%80%bd%e1%80%b1%e1%80%80%e1%80%bc%e1%80%ad%e1%80%af%e1%80%9a%e1%80%b0%e1%80%81%e1%80%bc%e1%80%84%e1%80%ba%e1%80%b8%e1%80%86%e1%80%ad%e1%80%af%e1%80%90%e1%80%ac-%e1%80%98%e1%80%ac%e1%80%9c%e1%80%b2%e1%81%8b-11159.html',
        [EnumLanguage.PT_BR]: 'https://help.sbotop.com/article/what-is-cash-out-11153.html',
        [EnumLanguage.KM_KH]: 'https://help.sbotop.com/article/what-is-cash-out-11153.html',
        [EnumLanguage.BN_BD]: 'https://help.sbotop.com/article/what-is-cash-out-11153.html',
    },
};

export function GetCashOutLearnMoreUrl(brandType: BrandType, languageType: EnumLanguage): string {
    return LangUrlMapping[brandType]?.[languageType] ?? LangUrlMapping[brandType]?.[EnumLanguage.EN] ?? '';
}
