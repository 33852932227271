import type { MaybeRef } from '@vueuse/core';
import { get } from '@vueuse/core';
import type { Ref } from 'vue';
import { computed, onUnmounted, ref } from 'vue';
import { deepCompareComputed } from '@/composable/deepCompareComputed';
import { i18n } from '@/core/lib/i18n';
import { isInvalidLiveMp1X2Odds, isValidOdds } from '@/core/lib/oddsHelper';
import { deepFreeze } from '@/core/lib/utils';
import { formatOdds } from '@/core/oddsApi/helpers/formatOdds';
import type {
    EnumPresetFilterType,
} from '@/core/oddsApi/oddsApiType';
import {
    EnumOddsCategoryType,
    EnumOddsMarketFilterType,

    useOddsSubscription,
} from '@/core/oddsApi/oddsApiType';
import type { IOdds } from '@/interface/IOdds';
import { EnumLanguage, PriceStyle } from '@/interface/enum';

export function useOddsSubscriptionCallback({
    eventId,
    oddsId,
    presetFilter,
    oddsCategory,
    priceStyle,
    marketFilter = EnumOddsMarketFilterType.All,
    isActive = ref(true),
}: {
    eventId: number;
    oddsId?: number;
    presetFilter: EnumPresetFilterType.Live | EnumPresetFilterType.NonLive;
    oddsCategory: EnumOddsCategoryType;
    priceStyle: MaybeRef<PriceStyle>;
    marketFilter?: EnumOddsMarketFilterType;
    isActive?: Ref<boolean>;
}) {
    const variables = computed(() => ({
        query: {
            id: eventId,
            oddsId,
            filter: presetFilter,
            marketFilter,
            oddsCategory,
            priceStyle: PriceStyle.toOddsApi(get(priceStyle)),
            lang: EnumLanguage.toOddsApi(i18n.locale.value),
        },
    }));

    const { onSubscription } = useOddsSubscription(
        variables,
        deepCompareComputed(() => ({
            enabled: isActive.value,
        })),
    );

    type UpdatedFn = (odds: IOdds) => void;
    let updatedFn: UpdatedFn[] = [];
    const onUpdated = (callback: UpdatedFn) => {
        updatedFn.push(callback);
        onUnmounted(() => {
            updatedFn = updatedFn.filter(fn => fn !== callback);
        });
    };

    type DeletedFn = (oddsId: number) => void;
    let deletedFn: DeletedFn[] = [];
    const onDeleted = (callback: DeletedFn) => {
        deletedFn.push(callback);
        onUnmounted(() => {
            deletedFn = deletedFn.filter(fn => fn !== callback);
        });
    };

    onSubscription((subscription) => {
        subscription?.updated.forEach((toUpdate) => {
            if (toUpdate.id === oddsId) {
                const updatedOdds = deepFreeze(formatOdds(toUpdate));
                const isMixParlayMode = oddsCategory === EnumOddsCategoryType.MixParlay;
                if (isValidOdds(updatedOdds) && !isInvalidLiveMp1X2Odds(updatedOdds, isMixParlayMode)) {
                    updatedFn.forEach(fn => fn(updatedOdds));
                } else {
                    deletedFn.forEach(fn => fn(updatedOdds.id));
                }
            }
        });

        subscription?.deleted.forEach((toDeleteId) => {
            if (toDeleteId === oddsId) {
                deletedFn.forEach(fn => fn(toDeleteId));
            }
        });
    });

    return {
        onUpdated,
        onDeleted,
    };
}
