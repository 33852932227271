<template>
    <table
        class="vTable"
        v-bind:class="classes"
    >
        <slot />
    </table>
</template>

<script lang="ts" setup>
    import { computed } from 'vue';

    const props = defineProps({
        rounded: {
            type: Boolean,
            default: false,
        },
        striped: {
            type: Boolean,
            default: true,
        },
        bordered: {
            type: Boolean,
            default: false,
        },
    });

    const classes = computed(() => {
        const list = [];
        if (props.rounded) list.push('vTable-rounded');
        if (props.striped) list.push('vTable-striped');
        if (props.bordered) list.push('vTable-bordered');
        return list;
    });
</script>

<style lang="scss">
    @import "@/components/common/table/VTable";
</style>
