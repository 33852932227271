<template>
    <LiveStream />
</template>

<script lang="ts" setup>
    import LiveStream from '@/components/liveStream/LiveStream.vue';
    import { useTitle } from '@/composable/useTitle';
    import { PageType } from '@/interface/enum';
    import { useToggleStore } from '@/store/toggleStore';

    useTitle(PageType.LiveStream);

    document.body.classList.add('sports-background');

    const { registerAutoRefresh } = useToggleStore();
    registerAutoRefresh();
</script>
