import type { Ref } from 'vue';
import { ref } from 'vue';

/**
 * @example
 * const [isOpen, toggleIsOpen] = useToggle(true)
 * toggleIsOpen()
 * // or
 * toggleIsOpen(false)
 */
export function useToggle(initialValue: boolean): [Ref<boolean>, (newVal?: boolean) => void] {
    const bool = ref(initialValue);
    const toggleBool = (newValue?: boolean) => {
        if (typeof newValue === 'boolean') {
            bool.value = newValue;
        } else {
            bool.value = !bool.value;
        }
    };

    return [bool, toggleBool];
}
