import { type Ref, computed, ref } from 'vue';
import { useMultipleSportsEventCount } from '@/composable/useMultipleSportsEventCount';
import type { IPromotionConfig } from '@/composable/usePromotionZone';
import { useSingleSportsEventCount } from '@/composable/useSingleSportsEventCount';
import type { IMarketInfo } from '@/interface/IMarketInfo';
import type { IZoneEventCount } from '@/interface/ISportMarketCount';
import type { SportType } from '@/interface/enum';
import { PromotionZone } from '@/interface/enum';
import type { SportInfo } from '@/models/SportInfo';

export function usePromotionZoneEventCount({
    sportType,
    promotionZone,
    promotionZoneConfig,
    filteredLeagueIds,
    filteredLeagueNames,
}: {
    sportType: Ref<SportType>;
    promotionZone: Ref<PromotionZone>;
    promotionZoneConfig: Ref<IPromotionConfig | null>;
    filteredLeagueIds: Ref<number[]>;
    filteredLeagueNames: Ref<string[]>;
}) {
    const isMultipleSportsZone = computed(() => promotionZone.value === PromotionZone.MultipleSports);
    const isSingleSportZone = computed(() => promotionZone.value !== PromotionZone.None && !isMultipleSportsZone.value);

    const singleCount = useSingleSportsEventCount({
        sportType,
        filteredLeagueIds,
        filteredLeagueNames,
        promotionZone,
        isEnabled: isSingleSportZone,
    });

    const multipleCount = useMultipleSportsEventCount({
        filteredLeagueIds,
        filteredLeagueNames,
        isEnabled: isMultipleSportsZone,
    });

    const eventCount = computed<IZoneEventCount>(() => {
        if (isMultipleSportsZone.value) {
            return multipleCount;
        }

        if (isSingleSportZone.value) {
            return singleCount;
        }

        return {
            sportList: ref<SportInfo[]>([]),
            marketList: ref(new Map<SportType, IMarketInfo[]>()),
            loaded: ref(false),
            loading: ref(true),
        };
    });

    const marketList = computed<Map<SportType, IMarketInfo[]>>(() => {
        const { marketList } = eventCount.value;

        return new Map<SportType, IMarketInfo[]>(
            Array.from(marketList.value.entries()).map(([key, value]) => [
                key,
                value.filter(x => !promotionZoneConfig.value?.excludeMarketPages?.includes(x.marketPage)),
            ]),
        );
    });

    const sportList = computed(() => eventCount.value.sportList.value);
    const loaded = computed(() => eventCount.value.loaded.value);
    const loading = computed(() => eventCount.value.loading.value);

    return {
        marketList,
        sportList,
        loaded,
        loading,
    };
}
