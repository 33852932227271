import type { Directive, DirectiveBinding } from 'vue';

function showElement(el: HTMLElement, binding: DirectiveBinding<string>) {
    el.style.visibility = binding.value ? 'visible' : 'hidden';
}

export const VisibleDirective: Directive<HTMLElement, string> = {
    created(el, binding) {
        showElement(el, binding);
    },
    updated(el, binding) {
        showElement(el, binding);
    },
};
