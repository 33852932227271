import { groupBy } from 'lodash';
import { parseISOString } from '@/core/lib/date';
import type { IBet, IPartialCashOutBet, ISubBet } from '@/interface/IBet';
import type { IBetBuilderLeg } from '@/interface/betBuilder';
import { HandicapType, MainBetStatus, MarketType, OddsOption, PriceStyle, SportType, SubBetStatus } from '@/interface/enum';
import type { Bet, BetBuilderLeg, PartiallyCashedOutBet, SubBet } from '@/interface/pollux';
import { BetStatus } from '@/interface/pollux';

export const accountDbReplicationDelay = 1000;

export const cashOutedDelay = 3000;

export const runningStatusList = [MainBetStatus.Running, MainBetStatus.Waiting];

export const invalidMainBetStatusList = [
    MainBetStatus.WaitingRejected,
    MainBetStatus.Refunded,
    MainBetStatus.Void,
    MainBetStatus.SpecialVoid,
    MainBetStatus.Reversed,
    MainBetStatus.Amended,
];

export const invalidSubBetStatusList = [
    SubBetStatus.WaitingRejected,
    SubBetStatus.Void,
];

export function formatBet(bet: Bet): IBet {
    const marketType = MarketType.fromPollux(bet.marketType);
    const mainBetStatus = MainBetStatus.fromPollux(bet.mainBetStatus);
    return {
        ...bet,
        sportType: SportType.fromPollux(bet.sportType),
        marketType,
        oddsOptionType: OddsOption.fromPollux(bet.oddsOptionType),
        betTeam: bet.betTeam ?? '',
        favoriteTeam: HandicapType.fromPollux(bet.favoriteTeam),
        priceStyle: PriceStyle.fromPollux(bet.oddsStyle),
        showTimeDisplayType: bet.showTimeDisplayType,
        showTime: parseISOString(bet.showTime),
        transactionDate: parseISOString(bet.transactionDate),
        mainBetStatus,
        isResettled: [MainBetStatus.Amended, MainBetStatus.Reversed].includes(mainBetStatus),
        isInvalidStatus: invalidMainBetStatusList.includes(mainBetStatus),
        isSettled: !runningStatusList.includes(mainBetStatus),
        isMixParlay: marketType === MarketType.MixParlay,
        isOutright: marketType === MarketType.OutRight,
        isBetBuilder: marketType === MarketType.BetBuilder,
        isWaitingReject: mainBetStatus === MainBetStatus.WaitingRejected,
        subBets: bet.subBets?.map(subBet => formatSubBet(subBet, bet)) ?? [],
        betTypeGroup: {
            id: bet.betTypeGroupId,
            name: bet.betTypeGroup ?? '',
        },
        homeTeamName: bet.homeTeamName ?? '',
        awayTeamName: bet.awayTeamName ?? '',
        betCondition: bet.betCondition ?? '',
        leagueName: bet.leagueName ?? '',
        liveScore: '',
        liveTime: '',
        betLiveScore: bet.betLiveScore ?? { home: 0, away: 0 },
        fullTimeScore: bet.fullTimeScore ?? { home: 0, away: 0 },
        halfTimeScore: bet.halfTimeScore ?? { home: 0, away: 0 },
        result: bet.result ?? '',
        playerWinLoss: bet.playerWinLoss,
        playerCommission: bet.playerCommission,
        partiallyCashedOutBets: bet.partiallyCashedOutBets ? formatPartialCashOutMap(bet.partiallyCashedOutBets) : undefined,
        originalTransId: bet.originalTransactionId,
        originalStake: bet.originalStake,
        originalActualStake: bet.originalActualStake,
        totalCashedOutValue: bet.totalCashedOutValue,
        isPartialCashOutBet: bet.isPartialCashOutBet,
        partialCashedOutStake: bet.partialCashedOutStake,
        partialCashedOutValue: bet.partialCashedOutValue,
        partialCashedOutActualStake: bet.partialCashedOutActualStake,
        betBuilderLegs: bet.betBuilderLegs?.map(leg => formatBetBuilderLeg(leg)) ?? [],
    };
}

export function formatSubBet(bet: SubBet, mainBet: Bet): ISubBet {
    const mainBetStatus = MainBetStatus.fromPollux(mainBet.mainBetStatus);
    const subBetStatus = SubBetStatus.fromPollux(bet.subBetStatus);
    return {
        ...bet,
        sportType: SportType.fromPollux(bet.sportType),
        marketType: MarketType.fromPollux(bet.marketType),
        oddsOptionType: OddsOption.fromPollux(bet.oddsOptionType),
        betTeam: bet.betTeam ?? '',
        favoriteTeam: HandicapType.fromPollux(bet.favoriteTeam),
        subBetStatus: SubBetStatus.fromPollux(bet.subBetStatus),
        priceStyle: PriceStyle.fromPollux(mainBet.oddsStyle),
        showTimeDisplayType: bet.showTimeDisplayType,
        showTime: parseISOString(bet.showTime),
        isInvalidStatus: invalidMainBetStatusList.includes(mainBetStatus) || invalidSubBetStatusList.includes(subBetStatus),
        isSettled: !runningStatusList.includes(mainBetStatus),
        isMixParlay: true,
        isOutright: false,
        isBetBuilder: false,
        isWaitingReject: mainBetStatus === MainBetStatus.WaitingRejected,
        isVoucher: mainBet.isVoucher,
        betTypeGroup: {
            id: bet.betTypeGroupId,
            name: bet.betTypeGroup ?? '',
        },
        homeTeamName: bet.homeTeamName ?? '',
        awayTeamName: bet.awayTeamName ?? '',
        betCondition: bet.betCondition ?? '',
        leagueName: bet.leagueName ?? '',
        liveScore: '',
        liveTime: '',
        stake: mainBet.stake,
        betLiveScore: bet.betLiveScore ?? { home: 0, away: 0 },
        fullTimeScore: bet.fullTimeScore ?? { home: 0, away: 0 },
        halfTimeScore: bet.halfTimeScore ?? { home: 0, away: 0 },
    };
}

function formatPartialCashOutMap(bets: PartiallyCashedOutBet[]): Map<number, IPartialCashOutBet[]> {
    const groupedBets = groupBy(bets, x => x.transId);
    return new Map(
        Object.entries(groupedBets).map(
            ([transId, bets]) => [parseInt(transId), bets.map(formatPartialCashOutBet)],
        ),
    );
}

function formatPartialCashOutBet(bet: PartiallyCashedOutBet): IPartialCashOutBet {
    return {
        transId: bet.transId,
        status: bet.partialCashOutBetStatus,
        transDate: parseISOString(bet.transDate),
        stake: bet.stake,
        actualStake: bet.actualStake,
        result: bet.result,
        isResettled: (bet.partialCashOutBetStatus & BetStatus.Resettled) === BetStatus.Resettled,
        isVoid: [BetStatus.Void, BetStatus.Special_Void, BetStatus.System_Void].some(status => (bet.partialCashOutBetStatus & status) === status),
        isCashedOut: (bet.partialCashOutBetStatus & BetStatus.Cashed_Out) === BetStatus.Cashed_Out,
        commission: bet.commission,
    };
}

function formatBetBuilderLeg(leg: BetBuilderLeg): IBetBuilderLeg {
    return {
        ...leg,
        status: SubBetStatus.fromPollux(leg.status),
        marketType: {
            id: leg.marketType?.key ?? 0,
            name: leg.marketType?.value ?? '',
        },
        selectionType: {
            id: leg.selectionType?.key ?? 0,
            name: leg.selectionType?.value ?? '',
        },
        betTeam: leg.betTeam ?? '',
    };
}
