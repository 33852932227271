<template>
    <div
        class="cubeBox_item"
        v-bind:class="{
            active: isActive,
        }"
        v-on:click="emit('click')"
    >
        <slot>
            <SvgIcon
                v-if="iconName"
                size="xxl"
                v-bind:name="iconName"
            />
        </slot>
    </div>
</template>

<script lang="ts" setup>
    import SvgIcon from '@/components/common/SvgIcon.vue';

    defineProps({
        iconName: {
            type: String,
            default: undefined,
        },
        isActive: {
            type: Boolean,
            required: true,
        },
    });

    const emit = defineEmits(['click']);
</script>
