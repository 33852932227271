<template>
    <div class="breadcrumbs_item">
        <RouterLink
            v-if="breadcrumb.route"
            v-bind:to="{
                name: breadcrumb.route.name,
                params: breadcrumb.route.params,
            }"
        >
            {{ breadcrumb.title }}
        </RouterLink>
        <strong v-else>
            {{ breadcrumb.title }}
        </strong>
    </div>
    <SvgIcon
        v-if="breadcrumb.route"
        class="arrow"
        name="icon-system-arrow"
        v-bind:size="8"
    />
</template>

<script setup lang="ts">
    import { toRefs } from 'vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import type { IBreadcrumb } from '@/interface/IBreadcrumb';

    const props = defineProps<{ breadcrumb: IBreadcrumb }>();
    const { breadcrumb } = toRefs(props);
</script>

<style lang="scss" scoped>
    @import "@/components/navbarBlock/euro/BreadcrumbsItem";
</style>
