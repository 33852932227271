import { defineStore, storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useLocalStorage } from '@/composable/useLocalStorage';
import { MarketShowingType, OddsDisplayMode, SportType } from '@/interface/enum';
import { useAsiaSportMarketInfoStore } from '@/store/asiaSportMarketInfoStore';
import { useCustomerStore } from '@/store/customerStore';

export const useOddsDisplaySettingsStore = defineStore('OddsDisplaySettings', () => {
    const { accountId, _oddsDisplayMode } = storeToRefs(useCustomerStore());
    const { sportType, isMixParlay } = storeToRefs(useAsiaSportMarketInfoStore());

    // Only Football support SingleLine
    const oddsDisplayMode = computed(() => (sportType.value === SportType.Soccer
        ? _oddsDisplayMode.value
        : OddsDisplayMode.Double));

    const [_marketShowing, setMarketShowing] = useLocalStorage<MarketShowingType>(
        `marketShowing:${accountId.value}`,
        MarketShowingType.All,
    );

    const marketShowing = computed(() => (isMixParlay.value ? MarketShowingType.Main : _marketShowing.value));

    return {
        oddsDisplayMode,
        marketShowing,
        setMarketShowing,
    };
});
