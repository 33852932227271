import { storeToRefs } from 'pinia';
import type { Ref } from 'vue';
import { computed } from 'vue';
import { i18n } from '@/core/lib/i18n';
import { getKratosLiveCourtUrl } from '@/core/lib/url';
import type { IEvent, IEventEuro } from '@/interface/IEvent';
import { BrandType, EnumLanguage } from '@/interface/enum';
import { useCustomerStore } from '@/store/customerStore';

const LiveCourtWindow = {
    name: 'LiveCourt',
    config: 'scrollbars=no,resizable=no,width=478,height=725',
};

export function useLiveCourt(event: Ref<IEvent | IEventEuro | null>) {
    const { brandType } = storeToRefs(useCustomerStore());

    const openLiveCourt = () => {
        const lang = EnumLanguage.toLiveCourt(i18n.locale.value) ?? 'en';
        const brandName = BrandType[brandType.value].toLowerCase();
        const url = `${getKratosLiveCourtUrl()}/live-court.html?liveCourtMatchId=${event.value?.liveCourtMatchId}&lang=${lang}&brandType=${brandName}`;
        window.open(url, LiveCourtWindow.name, LiveCourtWindow.config);
    };

    const isLiveCourtAvailable = computed(() => event.value?.isLive
     && event.value.liveCourtMatchId > 0);

    return {
        isLiveCourtAvailable,
        openLiveCourt,
    };
}
