import { defineStore, storeToRefs } from 'pinia';
import { computed, ref, watch, watchEffect } from 'vue';
import { useEventEmitter } from '@/composable/useEventEmitter';
import { useLocalStorage } from '@/composable/useLocalStorage';
import { useSessionStorage } from '@/composable/useSessionStorage';
import { useState } from '@/composable/useState';
import { getDefaultParlayBetSlip } from '@/core/lib/betSlipHelper.euro';
import { pick } from '@/core/lib/utils';
import type { IBetSlipEuro, ITicketEuro } from '@/interface/IBetSlip';
import { BetSlipType } from '@/interface/IBetSlip';
import type { IBetSlipStoreEuro } from '@/interface/IBetSlipStoreEuro';
import type { IEventEuro } from '@/interface/IEvent';
import type { IOdds, IPrice } from '@/interface/IOdds';
import { SiteStyle, TabName } from '@/interface/enum';
import type { EuroBetPageType } from '@/interface/enum';
import { useCustomerStore } from '@/store/customerStore';
import { useToggleStore } from '@/store/toggleStore';
import { useMixParlayBetSlip } from '@/store/useMixParlayBetSlip.euro';
import { useSingleBetSlips } from '@/store/useSingleBetSlips.euro';

export type ISingleBetSlipEuro = IBetSlipEuro
& { type: BetSlipType.Single | BetSlipType.Outright };

export type IMixParlayBetSlipEuro = IBetSlipEuro & { type: BetSlipType.Parlay };

const pickSingleKeys = ['event', 'odds', 'priceOption', 'betPage'] as const;
type SessionSingleTicket = Pick<ITicketEuro, typeof pickSingleKeys[number]>;

export const useEuroBetSlipStore = defineStore('EuroBetSlip', () => {
    const [balance, setBalance] = useState<number|null>(0);
    const [betSlipTab, setBetSlipTab] = useState(TabName.Single);
    const eventEmitter = useEventEmitter();

    const { accountId } = storeToRefs(useCustomerStore());
    const [_voucherEnabled, setVoucherEnabled] = useLocalStorage<boolean>(`voucherEnabled:${accountId.value}`, false);

    const singleBetSlips = ref<ISingleBetSlipEuro[]>([]);
    const mixParlayBetSlip = ref<IMixParlayBetSlipEuro>(getDefaultParlayBetSlip());
    const useSingle = useSingleBetSlips(singleBetSlips, balance);
    const useMixParlay = useMixParlayBetSlip(mixParlayBetSlip, balance);
    const { isVoucherEnabled } = storeToRefs(useToggleStore(SiteStyle.Euro));

    const voucherEnabled = computed(() => _voucherEnabled.value && isVoucherEnabled.value);

    const addSingleBet = (event: IEventEuro, odds: IOdds, priceOption: IPrice, betPage: EuroBetPageType, type: BetSlipType.Single | BetSlipType.Outright = BetSlipType.Single) => {
        setBetSlipTab(TabName.Single);
        useSingle.addBet(event, odds, priceOption, betPage, type);
        eventEmitter.emit('betSlip:addToSingleBetSlip');
    };

    const addToParlayBetSlip = (event: IEventEuro, odds: IOdds, priceOption: IPrice, betPage: EuroBetPageType) => {
        setBetSlipTab(TabName.Parlay);
        useMixParlay.addBet(event, odds, priceOption, betPage);
        eventEmitter.emit('betSlip:addToMultipleBetSlip');
    };
    const useBetSlipsStoreByType = (type: BetSlipType): IBetSlipStoreEuro => {
        switch (type) {
            case BetSlipType.Parlay:
                return {
                    ...useMixParlay,
                    addBet: addToParlayBetSlip,
                };
            case BetSlipType.Single:
            case BetSlipType.Outright:
                return {
                    ...useSingle,
                    addBet: addSingleBet,
                };
            default:
                throw new Error('default case not implement');
        }
    };

    const [sessionSingleTicket, setSessionSingleTicket] = useSessionStorage<SessionSingleTicket[] | null>(
        `singleTicket:euro:${accountId.value}`,
        null,
        {
            decode: v => JSON.parse(v),
            encode: v => JSON.stringify(v),
        },
    );

    const [sessionParlayTicket, setSessionParlayTicket] = useSessionStorage<SessionSingleTicket[] | null>(
        `parlayTicket:euro:${accountId.value}`,
        null,
        {
            decode: v => JSON.parse(v),
            encode: v => JSON.stringify(v),
        },
    );

    if (sessionSingleTicket.value) {
        sessionSingleTicket.value.forEach((ticket) => {
            useSingle.addBet(
                ticket.event,
                ticket.odds,
                ticket.priceOption,
                ticket.betPage,
            );
        });
    }

    if (sessionParlayTicket.value) {
        sessionParlayTicket.value.forEach((ticket) => {
            useMixParlay.addBet(
                ticket.event,
                ticket.odds,
                ticket.priceOption,
                ticket.betPage,
            );
        });
    }

    watch(singleBetSlips, () => {
        if (singleBetSlips.value) {
            setSessionSingleTicket(singleBetSlips.value.map(ticket => pick<ITicketEuro>(ticket.tickets[0], pickSingleKeys)));
        } else {
            setSessionSingleTicket(null);
        }
    }, { deep: true });

    watchEffect(() => {
        if (mixParlayBetSlip.value) {
            setSessionParlayTicket(mixParlayBetSlip.value.tickets.map(ticket => pick<ITicketEuro>(ticket, pickSingleKeys)));
        } else {
            setSessionParlayTicket(null);
        }
    });

    return {
        /**
         * Indicates whether user choose to enable voucher or not
         */
        voucherEnabled,
        setVoucherEnabled,

        betSlipTab,
        balance,
        setBalance,

        singleBetSlips,
        mixParlayBetSlip,
        useBetSlipsStoreByType,
    };
});
