<template>
    <div class="betSlipContainer">
        <Message
            v-bind:show="!!betSlipMessage"
            position="top"
            padding="4px 13px"
            status="danger"
            v-bind:message="betSlipMessage"
        />
        <div class="betSlip">
            <div ref="ticketContainerRef" class="ticketContainer">
                <div
                    class="ticket"
                    v-bind:class="{
                        live: match.isLive,
                    }"
                >
                    <div class="ticket_header">
                        <div class="ticket_header_title">
                            <span v-tooltip="i18n.t('wait_tooltip')" class="oddsDataStates">
                                <span class="oddsDataStates_light" />
                            </span>
                            <strong class="optionTitle">
                                <span>{{ betBuilderTitle }}</span>
                            </strong>
                        </div>
                    </div>
                    <ExpandTransitionGroup v-if="isEnableAnimation" v-on:after-enter="scrollToBottom()">
                        <BetBuilderTicket
                            v-for="ticket in betSlip.tickets"
                            v-bind:key="ticket.selection.id"
                            v-bind:ticket="ticket"
                            v-bind:match="match"
                            class="ticket-animated"
                        />
                    </ExpandTransitionGroup>
                    <template v-else>
                        <BetBuilderTicket
                            v-for="ticket in betSlip.tickets"
                            v-bind:key="ticket.selection.id"
                            v-bind:ticket="ticket"
                            v-bind:match="match"
                        />
                    </template>
                    <div class="ticket_detail">
                        <div
                            class="detailText"
                            style="color: var(--primary);"
                        >
                            <span>
                                {{ match.homeTeam.name }}
                                -{{ i18n.t('vs') }}-
                                {{ match.awayTeam.name }}
                            </span>
                        </div>
                        <div class="detailText" style="color: var(--primary);">
                            <span>{{ match.league.name }}</span>
                        </div>
                    </div>
                </div>
                <Notification
                    v-if="invalidSelectionMessage"
                    iconAlign="top"
                >
                    <span>
                        <DynamicI18n v-bind:config="invalidSelectionMessage" />
                    </span>
                </Notification>
            </div>
            <PlaceBetBlock />
        </div>
    </div>
</template>

<script lang="ts" setup>
    import type { PropType } from 'vue';
    import { computed, nextTick, ref, toRefs } from 'vue';
    import BetBuilderTicket from '@/components/betSlip/BetBuilderTicket.vue';
    import Message from '@/components/betSlip/Message.vue';
    import PlaceBetBlock from '@/components/betSlip/PlaceBetBlock.vue';
    import { BetSlipContext } from '@/components/betSlip/betSlipContext';
    import DynamicI18n from '@/components/common/DynamicI18n.vue';
    import Notification from '@/components/common/Notification.vue';
    import ExpandTransitionGroup from '@/components/transitions/ExpandTransitionGroup.vue';
    import { useBetBuilderInvalidMessageDisplay } from '@/composable/useBetBuilderInvalidMessageDisplay';
    import { useEventEmitter } from '@/composable/useEventEmitter';
    import { i18n } from '@/core/lib/i18n';
    import { getBrowserInfo } from '@/core/lib/utils';
    import type { IBetBuilderBetSlip } from '@/interface/betBuilder';

    const props = defineProps({
        betSlip: {
            type: Object as PropType<IBetBuilderBetSlip>,
            required: true,
        },
    });

    const browserInfo = getBrowserInfo();
    const isEnableAnimation = !(browserInfo.name?.toLowerCase().includes('safari') && parseFloat(browserInfo.version ?? '0') < 15);

    const { betSlip } = toRefs(props);
    const { betSlipMessage } = BetSlipContext.provide(betSlip);

    const eventEmitter = useEventEmitter();
    const ticketContainerRef = ref<HTMLElement | null>(null);
    eventEmitter.on('betSlip:addToParlayBetSlip', () => {
        scrollToBottom();
    });

    scrollToBottom();

    function scrollToBottom() {
        nextTick(() => {
            if (ticketContainerRef.value) {
                const scrollHeight = ticketContainerRef.value.scrollHeight;
                ticketContainerRef.value.scroll({ top: scrollHeight, behavior: 'smooth' });
            }
        });
    }

    const tickets = computed(() => betSlip.value.tickets);
    const match = computed(() => betSlip.value.tickets[0].match);
    const { invalidSelectionMessage } = useBetBuilderInvalidMessageDisplay(tickets);

    const betBuilderTitle = computed(() => {
        const sportName = i18n.t(`sport_type_name_${(match.value.sportType)}`);
        return `${i18n.t('market_type_name_41')} - ${sportName}`;
    });
</script>

<style lang="scss" scoped>
    @import "@/components/betSlip/BetSlip";
    @import "@/components/betSlip/Ticket";
</style>
