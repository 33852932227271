import orderBy from 'lodash/orderBy';
import { getTeamName } from '@/components/oddsDisplay/matchDisplay/useMatchDisplay';
import { getBetOptionDisplay } from '@/core/lib/betOptionDisplay';
import { i18n } from '@/core/lib/i18n';
import { isMainMarket, isNextGoal } from '@/core/lib/oddsHelper';
import type { IEvent } from '@/interface/IEvent';
import type { IOdds, IPrice } from '@/interface/IOdds';
import { MarketType, OddsOption, TeamOption } from '@/interface/enum';

type TitleFormatter = (event: IEvent, odds: IOdds | IOdds[]) => string;

type SinglePricesFormatter = (prices: IPrice[]) => (IPrice[][]);
type MultiplePriceFormatter = (prices: IPrice[]) => (IPrice[]);

type WidthType = 'width-21' | 'width-31' | 'width-32' | 'width-full';
type WidthFormatter = (odds: IOdds | IOdds[], formattedPrices: IPrice[][]) => WidthType;

type OptionFormatter = (event: IEvent, odds: IOdds, price: IPrice) => string;

type TooltipFormatter = (event: IEvent, odds: IOdds, price: IPrice) => string;

interface MarketRule {
    titleFormatter: TitleFormatter;
    singlePricesFormatter: SinglePricesFormatter;
    multiplePricesFormatter: MultiplePriceFormatter;
    widthFormatter: WidthFormatter;
    singleOptionFormatter: OptionFormatter;
    singleTooltipFormatter: TooltipFormatter;
    minDecimalPlace: number;
}

export const defaultTitleFormatter: TitleFormatter = (event, odds) => {
    const firstOdds = Array.isArray(odds) ? odds[0] : odds;
    const marketTitle = i18n.t(`market_type_name_${firstOdds.marketType}`);
    const marketGroupName = firstOdds.eventResult.marketGroup.name;

    if (isMainMarket(firstOdds)) return marketTitle;
    if (isNextGoal(firstOdds)) return i18n.t('next_goal');
    return firstOdds.eventResult.marketGroup.isSingle && !firstOdds.eventResult.marketGroup.isYesNo
        ? `${marketGroupName} - ${marketTitle}`
        : marketGroupName;
};

const defaultSinglePriceFormatter: SinglePricesFormatter = prices => [prices];

const defaultMultiplePricesFormatter: MultiplePriceFormatter = prices => prices;

const defaultWidthFormatter: WidthFormatter = (odds, formattedPrices) => {
    const prices = formattedPrices.flat();
    if (prices.length <= 4) return 'width-31';
    if (prices.length <= 6) return 'width-21';

    return 'width-full';
};

const defaultOptionFormatter: OptionFormatter = (event, odds, price) => price.option;

const defaultTooltipFormatter: TooltipFormatter = (event, odds, price) => price.option;

const defaultMinDecimalPlace = 2;

const _1X2Rule: Partial<MarketRule> = {
    singlePricesFormatter: prices => [orderBy(prices, x => [OddsOption._1X2_1, OddsOption._1X2_X, OddsOption._1X2_2].indexOf(x.option))],
    singleOptionFormatter: (event, odds, price) => price.option.toUpperCase(),
    singleTooltipFormatter: (event, odds, price) => {
        if (odds.marketType === MarketType._1X2) {
            if (price.option === OddsOption._1X2_1) return i18n.t('odds_option_ft_1_tooltip');
            if (price.option === OddsOption._1X2_2) return i18n.t('odds_option_ft_2_tooltip');
            if (price.option === OddsOption._1X2_X) return i18n.t('odds_option_ft_x_tooltip');
        }

        if (odds.marketType === MarketType.FH_1X2) {
            if (price.option === OddsOption._1X2_1) return i18n.t('odds_option_fh_1_tooltip');
            if (price.option === OddsOption._1X2_2) return i18n.t('odds_option_fh_2_tooltip');
            if (price.option === OddsOption._1X2_X) return i18n.t('odds_option_fh_x_tooltip');
        }

        return '';
    },
    multiplePricesFormatter: prices => orderBy(prices, x => [OddsOption._1X2_1, OddsOption._1X2_2, OddsOption._1X2_X].indexOf(x.option)),
};

const correctScoreRule: Partial<MarketRule> = {
    singlePricesFormatter: (prices) => {
        const validOdds = prices.filter(price => price.price > 1);

        const numberOfRows = Math.ceil(validOdds.length / 13);
        const numberOfColumns = Math.ceil(validOdds.length / numberOfRows);

        const sorted = orderBy(validOdds, x => x.option);
        const priceRows = new Array<Array<IPrice | null>>(numberOfRows)
            .fill(new Array(numberOfColumns).fill(null))
            .map((row, rowIndex) => row
                .map((_, columnIndex) => sorted[rowIndex * row.length + columnIndex] ?? null));

        return priceRows;
    },
    singleOptionFormatter: (event, odds, price) => getBetOptionDisplay.displayCS(price.option, { home: event.homeTeam.liveScore ?? 0, away: event.awayTeam.liveScore ?? 0 }, odds.marketType, ':').name,
    singleTooltipFormatter: (event, odds, price) => getBetOptionDisplay.displayCS(price.option, { home: event.homeTeam.liveScore ?? 0, away: event.awayTeam.liveScore ?? 0 }, odds.marketType, ':').tooltip,
    minDecimalPlace: 0,
};

const doubleChanceRule: Partial<MarketRule> = {
    singlePricesFormatter: prices => [orderBy(prices, x => [OddsOption.DC_1X, OddsOption.DC_12, OddsOption.DC_2X].indexOf(x.option))],
    singleOptionFormatter: (event, odds, price) => getBetOptionDisplay.displayDoubleChance(price.option).name,
    singleTooltipFormatter: (event, odds, price) => getBetOptionDisplay.displayDoubleChance(price.option).tooltip,
};

const fglgRule: Partial<MarketRule> = {
    singlePricesFormatter: prices => [orderBy(prices, x => [OddsOption.FGLG_HF, OddsOption.FGLG_HL, OddsOption.FGLG_AF, OddsOption.FGLG_AL, OddsOption.FGLG_NG].indexOf(x.option))],
    singleOptionFormatter: (event, odds, price) => getBetOptionDisplay.displayFGLG(price.option).name,
    singleTooltipFormatter: (event, odds, price) => getBetOptionDisplay.displayFGLG(price.option).tooltip,
};

const hdpRule: Partial<MarketRule> = {
    singlePricesFormatter: prices => [orderBy(prices, x => [OddsOption.Home, OddsOption.Away].indexOf(x.option))],
    multiplePricesFormatter: prices => orderBy(prices, x => [OddsOption.Home, OddsOption.Away].indexOf(x.option)),
    singleOptionFormatter: (event, odds, price) => getTeamName(price.option === OddsOption.Home ? TeamOption.Home : TeamOption.Away, event, [odds]),
    singleTooltipFormatter: (event, odds, price) => {
        if (!isMainMarket(odds) && odds.eventResult.marketGroup.isYesNo) {
            return getTeamName(price.option === OddsOption.Home ? TeamOption.Home : TeamOption.Away, event, [odds]);
        }

        if (price.option === OddsOption.Home) return i18n.t('odds_option_home');
        if (price.option === OddsOption.Away) return i18n.t('odds_option_away');
        return '';
    },
};

const htftRule: Partial<MarketRule> = {
    singlePricesFormatter: prices => [orderBy(prices, x => [OddsOption.HTFT_HH, OddsOption.HTFT_HD, OddsOption.HTFT_HA, OddsOption.HTFT_DH, OddsOption.HTFT_DD, OddsOption.HTFT_DA, OddsOption.HTFT_AH, OddsOption.HTFT_AD, OddsOption.HTFT_AA].indexOf(x.option))],
    singleOptionFormatter: (event, odds, price) => getBetOptionDisplay.displayHTFT(price.option).name,
    singleTooltipFormatter: (event, odds, price) => getBetOptionDisplay.displayHTFT(price.option).tooltip,
};

const liveScoreRule: Partial<MarketRule> = {
    singlePricesFormatter: prices => [orderBy(prices, x => [OddsOption.LS_1, OddsOption.LS_X, OddsOption.LS_2].indexOf(x.option))],
    singleOptionFormatter: (event, odds, price) => price.option.toUpperCase(),
    singleTooltipFormatter: (event, odds, price) => {
        if (odds.marketType === MarketType.LiveScore) {
            if (price.option === OddsOption.LS_1) return i18n.t('odds_option_ft_a1x2_1_tooltip');
            if (price.option === OddsOption.LS_2) return i18n.t('odds_option_ft_a1x2_2_tooltip');
            if (price.option === OddsOption.LS_X) return i18n.t('odds_option_ft_a1x2_x_tooltip');
        }

        if (odds.marketType === MarketType.FH_LiveScore) {
            if (price.option === OddsOption.LS_1) return i18n.t('odds_option_fh_a1x2_1_tooltip');
            if (price.option === OddsOption.LS_2) return i18n.t('odds_option_fh_a1x2_2_tooltip');
            if (price.option === OddsOption.LS_X) return i18n.t('odds_option_fh_a1x2_x_tooltip');
        }

        return '';
    },
};

const moneyLineRule: Partial<MarketRule> = {
    singlePricesFormatter: prices => [orderBy(prices, x => [OddsOption.ML_1, OddsOption.ML_2].indexOf(x.option))],
    multiplePricesFormatter: prices => orderBy(prices, x => [OddsOption.ML_1, OddsOption.ML_2].indexOf(x.option)),
    singleOptionFormatter: (event, odds, price) => {
        if (!isMainMarket(odds) && odds.eventResult.marketGroup.isYesNo) {
            return getTeamName(price.option === OddsOption.Home ? TeamOption.Home : TeamOption.Away, event, [odds]);
        }

        if (price.option === OddsOption.ML_1) return i18n.t('odds_option_home');
        if (price.option === OddsOption.ML_2) return i18n.t('odds_option_away');
        return '';
    },
    singleTooltipFormatter: (event, odds, price) => {
        if (price.option === OddsOption.ML_1) return i18n.t('odds_option_home');
        if (price.option === OddsOption.ML_2) return i18n.t('odds_option_away');
        return '';
    },
};

const oddEvenRule: Partial<MarketRule> = {
    singlePricesFormatter: prices => [orderBy(prices, x => [OddsOption.Odd, OddsOption.Even].indexOf(x.option))],
    multiplePricesFormatter: prices => orderBy(prices, x => [OddsOption.Odd, OddsOption.Even].indexOf(x.option)),
    singleOptionFormatter: (event, odds, price) => getBetOptionDisplay.displayOE(price.option).name,
    singleTooltipFormatter: (event, odds, price) => getBetOptionDisplay.displayOE(price.option).tooltip,
};

const overUnderRule: Partial<MarketRule> = {
    singlePricesFormatter: prices => [orderBy(prices, x => [OddsOption.Over, OddsOption.Under].indexOf(x.option))],
    multiplePricesFormatter: prices => orderBy(prices, x => [OddsOption.Over, OddsOption.Under].indexOf(x.option)),
    singleOptionFormatter: (event, odds, price) => {
        if (price.option === OddsOption.Over) return i18n.t('odds_option_over');
        if (price.option === OddsOption.Under) return i18n.t('odds_option_under');
        return '';
    },
};

const totalGoalRule: Partial<MarketRule> = {
    singlePricesFormatter: prices => [orderBy(prices, x => [OddsOption.TG_0_1, OddsOption.TG_2_3, OddsOption.TG_4_6, OddsOption.TG_7_Up].indexOf(x.option))],
    singleOptionFormatter: (event, odds, price) => getBetOptionDisplay.displayTotalGoal(price.option, '~').name,
    singleTooltipFormatter: (event, odds, price) => getBetOptionDisplay.displayTotalGoal(price.option, '~').tooltip,
};

export function getMarketRule(marketType: MarketType): MarketRule {
    const defaultRule: MarketRule = {
        titleFormatter: defaultTitleFormatter,
        singlePricesFormatter: defaultSinglePriceFormatter,
        multiplePricesFormatter: defaultMultiplePricesFormatter,
        widthFormatter: defaultWidthFormatter,
        singleOptionFormatter: defaultOptionFormatter,
        singleTooltipFormatter: defaultTooltipFormatter,
        minDecimalPlace: defaultMinDecimalPlace,
    };

    const withDefault = (rule: Partial<MarketRule>) => ({ ...defaultRule, ...rule });

    switch (marketType) {
        case MarketType.Handicap: return withDefault(hdpRule);
        case MarketType.OddEven: return withDefault(oddEvenRule);
        case MarketType.OverUnder: return withDefault(overUnderRule);
        case MarketType.CorrectScore: return withDefault(correctScoreRule);
        case MarketType._1X2: return withDefault(_1X2Rule);
        case MarketType.TotalGoal: return withDefault(totalGoalRule);
        case MarketType.FH_Handicap: return withDefault(hdpRule);
        case MarketType.FH_1X2: return withDefault(_1X2Rule);
        case MarketType.FH_OverUnder: return withDefault(overUnderRule);
        case MarketType.HTFT: return withDefault(htftRule);
        case MarketType.MoneyLine: return withDefault(moneyLineRule);
        case MarketType.FH_OddEven: return withDefault(oddEvenRule);
        case MarketType.FGLG: return withDefault(fglgRule);
        case MarketType.FH_CorrectScore: return withDefault(correctScoreRule);
        case MarketType.DoubleChance: return withDefault(doubleChanceRule);
        case MarketType.LiveScore: return withDefault(liveScoreRule);
        case MarketType.FH_LiveScore: return withDefault(liveScoreRule);
        default: return defaultRule;
    }
}
