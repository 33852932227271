import type { RouteLocationNamedRaw, Router } from 'vue-router';
import { useRouter } from 'vue-router';
import type { IEventEuro } from '@/interface/IEvent';
import type { ILeague } from '@/interface/ILeague';
import type { MatchDetailPreview } from '@/interface/enum';
import { EuroRoutePage, SportType } from '@/interface/enum';

export const useEuroRouter = () => {
    const router = useRouter();

    const goToHomePage = () => {
        router.push({
            name: EuroRoutePage.Home,
        });
    };

    const goToSportPage = (sportType = SportType.Soccer) => {
        router.push({
            name: EuroRoutePage.Sport_Matches,
            params: {
                sport: SportType.toRoute(sportType),
            },
        });
    };

    type IRegionRoute = Pick<ILeague['region'], 'id'> & Partial<Pick<ILeague['region'], 'name'>>;

    const getRegionRoute = (sportType: SportType, region: IRegionRoute): RouteLocationNamedRaw => ({
        name: EuroRoutePage.Region_Matches,
        params: {
            sport: SportType.toRoute(sportType),
            region: region.id,
            regionName: region.name,
        },
    });

    const goToRegion = (sportType: SportType, region: IRegionRoute) => {
        router.push(getRegionRoute(sportType, region));
    };

    type ILeagueRoute = Pick<ILeague, 'id'> & Partial<Pick<ILeague, 'name'>> & { region: IRegionRoute };

    const getLeagueRoute = (sportType: SportType, league: ILeagueRoute): RouteLocationNamedRaw => ({
        name: EuroRoutePage.League_Matches,
        params: {
            sport: SportType.toRoute(sportType),
            region: league.region.id,
            regionName: league.region.name,
            league: league.id,
            leagueName: league.name,
        },
    });

    const goToLeague = (sportType: SportType, league: ILeagueRoute) => {
        router.push(getLeagueRoute(sportType, league));
    };

    const goToMultiple = (sport: SportType | null = null, mode: keyof Pick<Router, 'push' | 'replace'> = 'push') => {
        router[mode]({
            name: EuroRoutePage.Multiples,
            params: sport
                ? { sport: SportType.toRoute(sport) }
                : undefined,
        });
    };

    const goToInPlay = (sport: SportType | null = null, mode: keyof Pick<Router, 'push' | 'replace'> = 'push') => {
        router[mode]({
            name: EuroRoutePage.InPlay,
            params: sport
                ? { sport: SportType.toRoute(sport) }
                : undefined,
        });
    };

    const goToMatchDetail = (event: IEventEuro, isMultiples: boolean, watch?: MatchDetailPreview.LiveStream | MatchDetailPreview.LiveCourt) => {
        router.push({
            name: isMultiples ? EuroRoutePage.Multiples_MatchDetail : EuroRoutePage.MatchDetail,
            params: {
                sport: SportType.toRoute(event.sportType),
                region: event.league.region.id,
                regionName: event.league.region.name,
                league: event.league.id,
                leagueName: event.league.name,
                eventId: event.id,
                eventName: `${event.homeTeam.name} v ${event.awayTeam.name}`,
            },
            query: watch
                ? { watch }
                : undefined,
        });
    };

    return {
        goToHomePage,
        goToSportPage,
        getRegionRoute,
        goToRegion,
        getLeagueRoute,
        goToLeague,
        goToMultiple,
        goToInPlay,
        goToMatchDetail,
    };
};
