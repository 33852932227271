<template>
    <div class="tabs">
        <div class="tabs_header">
            <slot />
        </div>

        <div
            v-show="isExpand || animated"
            v-bind:style="style"
            class="tabs_content"
        >
            <template v-for="tab in tabs" v-bind:key="tab.name">
                <template v-if="animated">
                    <ExpandTransition>
                        <div v-if="isExpand">
                            <slot
                                v-if="tab.name === currentTab"
                                v-bind:name="tab.name"
                            />
                        </div>
                    </ExpandTransition>
                </template>
                <template v-else>
                    <slot
                        v-if="tab.name === currentTab"
                        v-bind:name="tab.name"
                    />
                </template>
            </template>
        </div>
        <div
            v-if="isShowFooter"
            ref="footerElement"
            class="tabs_footer"
        >
            <slot name="footer" />
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { computed, ref, useSlots, watch } from 'vue';
    import { TabsContext } from '@/components/tabs/TabsContext';
    import ExpandTransition from '@/components/transitions/ExpandTransition.vue';
    import type { CSSPropertiesWithVars } from '@/core/lib/style';

    const emit = defineEmits([
        'update:modelValue',
        'update:isExpand',
    ]);

    const footerElement = ref<HTMLInputElement>();
    const props = defineProps({
        modelValue: {
            type: [String, Number],
            default: null,
        },
        enableCollapse: {
            type: Boolean,
            default: true,
        },
        isExpand: {
            type: Boolean,
            default: true,
        },
        animated: {
            type: Boolean,
            default: false,
        },
    });

    const { currentTab, tabs, selectTab, isExpand, updateExpand } = TabsContext.provide({
        initialTab: props.modelValue,
        initialExpand: props.isExpand,
        enableCollapse: props.enableCollapse,
    });

    watch(() => props.modelValue, (newValue) => {
        if (currentTab.value !== newValue) selectTab(newValue);
    });

    watch(currentTab, (newValue) => {
        emit('update:modelValue', newValue);
    });

    watch(() => props.isExpand, (newValue) => {
        if (isExpand.value !== newValue) updateExpand(newValue);
    });

    watch(isExpand, (newValue) => {
        emit('update:isExpand', newValue);
    });

    const slots = useSlots();
    const isShowFooter = computed(() => !!slots.footer);
    const footerHeight = computed(() => footerElement?.value?.clientHeight ?? 0);

    const style = computed(() => {
        const result: CSSPropertiesWithVars = {};

        result['--v-tabs-footer-item-height'] = `${footerHeight.value}px`;
        return result;
    });
</script>

<style lang="scss" scoped>
    @import "@/components/tabs/Tabs";
</style>
