import { formatPoint, isBetBuilderHandicap, isBetBuilderOU } from '@/core/lib/oddsHelper';
import type { IBetBuilderMarket } from '@/interface/betBuilder';
import { BetBuilderSelectionType } from '@/interface/enum';

const positivePointSelectionTypes = [
    BetBuilderSelectionType.Home,
    BetBuilderSelectionType.Over,
];

export function getSelectionPoint(market: IBetBuilderMarket, selectionType: BetBuilderSelectionType) {
    if (isBetBuilderHandicap(market.displayType) || isBetBuilderOU(market.displayType)) {
        const formattedPoint = formatPoint(market.point);
        const isPositiveSide = positivePointSelectionTypes.includes(selectionType);

        if (isPositiveSide && market.point >= 0) return formattedPoint;
        if (!isPositiveSide && market.point < 0) return formattedPoint;

        return '';
    }

    return '';
}
