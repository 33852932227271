import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';
import 'broadcastchannel-polyfill';
import '@/polyfill-numberFormat';

const isClient = typeof window !== 'undefined';
const isSupportResizeObserver = 'ResizeObserver' in window;

if (isClient && !isSupportResizeObserver) {
    if (!isSupportResizeObserver) {
        window.ResizeObserver = ResizeObserverPolyfill;
    }
}
