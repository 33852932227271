import { parseDate, parseISOString } from '@/core/lib/date';
import { isMainMarket } from '@/core/lib/oddsHelper';
import { formatJersey } from '@/core/oddsApi/composable/useStreamEvents';
import type { EventResultTypeFragment, EventTypeEuroFragment, EventTypeFragment } from '@/core/oddsApi/oddsApiType';
import { EnumHandicapType, EnumSportType, EnumTournamentSizeType } from '@/core/oddsApi/oddsApiType';
import type { IEvent, IEventEuro, IEventExtraInfo } from '@/interface/IEvent';
import type { ISimpleEventResult } from '@/interface/ISimpleEventResult';
import type { BetRadarStreamStatus, BetTypeGroup, MarketType } from '@/interface/enum';
import { LeagueSize, ShowTimeDisplayType, SportType } from '@/interface/enum';

function formatExtraInfo(event: EventTypeFragment): IEventExtraInfo {
    return event.mainMarketEventResult.extraInfo
        ? {
            injuryTime: event.mainMarketEventResult.extraInfo.injuryTime,
            period: event.mainMarketEventResult.extraInfo.period,
            round: event.mainMarketEventResult.extraInfo.round,
            statisticsUrl: event.mainMarketEventResult.extraInfo.statisticsUrl ?? '',
            periodStartTime: event.mainMarketEventResult.extraInfo.periodStartTime,
        }
        : {
            injuryTime: 0,
            period: 0,
            round: 0,
            statisticsUrl: null,
            periodStartTime: null,
        };
}

function getHandicapType(event: EventTypeFragment) {
    return event.isLive
        ? event.mainMarketEventResult.liveHandicapType
        : event.mainMarketEventResult.nonLiveHandicapType;
}

export function formatEvent(event: EventTypeFragment, rawEventResults: EventResultTypeFragment[]): IEvent {
    const handicapType = getHandicapType(event);

    const eventResults = rawEventResults.map<ISimpleEventResult>(eventResult => ({
        id: eventResult.id,
        homeTeam: {
            liveScore: eventResult.liveHomeScore,
            redCard: eventResult.extraInfo?.homeRedCards ?? null,
        },
        awayTeam: {
            liveScore: eventResult.liveAwayScore,
            redCard: eventResult.extraInfo?.awayRedCards ?? null,
        },
        marketGroup: {
            id: eventResult.marketGroup?.id ?? -1,
            marketGroupName: eventResult.marketGroup?.marketGroupName ?? '',
            displayPriority: eventResult.marketGroup?.displayPriority ?? 0,
        },
    }));

    const mainMarketEventResult = eventResults.find(eventResult => isMainMarket(eventResult.marketGroup.id));

    return ({
        id: event.id,
        eventCode: event.eventCode,
        eventDate: parseISOString(event.eventDate),
        hasStatistics: event.hasStatistics,
        liveCourtMatchId: event.liveCourtMatchId,
        showTime: parseDate(event.showTime, 'MM/dd/yyyy HH:mm', 8),
        showTimeType: event.showTimeType,
        marketTypeCount: getMarketTypeCount(event.marketTypeCount),
        isLive: event.isLive,
        isTvLive: ShowTimeDisplayType.isTvLive(event.showTimeType),
        isSboLive: ShowTimeDisplayType.isSboLive(event.showTimeType),
        tvChannels: event.tvChannels,
        kickOffTime: parseISOString(event.realKickoffTime),
        eventResults,
        handicapType,
        isMixParlay: event.mainMarketEventResult.isMixParlay,
        extraInfo: formatExtraInfo(event),
        awayTeam: {
            name: event.awayTeam?.teamName ?? '',
            redCard: mainMarketEventResult?.awayTeam.redCard ?? null,
            liveScore: mainMarketEventResult?.awayTeam.liveScore ?? null,
            isFavorite: handicapType === EnumHandicapType.Away,
        },
        homeTeam: {
            name: event.homeTeam?.teamName ?? '',
            redCard: mainMarketEventResult?.homeTeam.redCard ?? null,
            liveScore: mainMarketEventResult?.homeTeam.liveScore ?? null,
            isFavorite: handicapType === EnumHandicapType.Home,
        },
        league: {
            id: event.tournament?.id ?? 0,
            name: event.tournament?.tournamentName ?? '',
            info: event.tournament?.tournamentInfo ?? '',
            size: LeagueSize.fromOddsApi(event.tournament?.tournamentSize ?? EnumTournamentSizeType.Small),
        },
        sportType: SportType.fromOddsApi(event.tournament?.sport?.sportType ?? EnumSportType.Soccer),
        stream: formatStream(event),
        outrightIds: event.isLive ? [] : event.nonLiveOutrightIds,
    });
}

function getMarketTypeCount(marketTypeCount: string[]): Partial<Record<BetTypeGroup, Partial<Record<MarketType, number>>>> {
    const result: Partial<Record<BetTypeGroup, Partial<Record<MarketType, number>>>> = {};
    marketTypeCount.forEach((marketTypeStr) => {
        const [marketGroup, marketType, count] = marketTypeStr.split(':').map(x => parseInt(x)) as [BetTypeGroup, MarketType, number];
        if (!result[marketGroup]) result[marketGroup] = {};
        result[marketGroup]![marketType] = count;
    });
    return result;
}

export function formatEventEuro(event: EventTypeEuroFragment): IEventEuro {
    return ({
        id: event.id,
        eventCode: event.eventCode,
        hasStatistics: event.hasStatistics,
        liveCourtMatchId: event.liveCourtMatchId,
        showTime: parseDate(event.showTime, 'MM/dd/yyyy HH:mm', 8),
        showTimeType: event.showTimeType,
        marketTypeCount: getMarketTypeCount(event.marketTypeCount),
        isLive: event.isLive,
        kickOffTime: parseISOString(event.realKickoffTime),
        extraInfo: event.mainMarketEventResult.extraInfo ? {
            injuryTime: event.mainMarketEventResult.extraInfo.injuryTime,
            period: event.mainMarketEventResult.extraInfo.period,
            periodStartTime: event.mainMarketEventResult.extraInfo.periodStartTime,
        } : null,
        awayTeam: {
            name: event.awayTeam?.teamName ?? '',
            jersey: formatJersey(event.awayJersey),
            redCard: event.mainMarketEventResult.extraInfo?.awayRedCards ?? null,
            liveScore: event.mainMarketEventResult.liveAwayScore,
        },
        homeTeam: {
            name: event.homeTeam?.teamName ?? '',
            jersey: formatJersey(event.homeJersey),
            redCard: event.mainMarketEventResult.extraInfo?.homeRedCards ?? null,
            liveScore: event.mainMarketEventResult.liveHomeScore,
        },
        league: {
            id: event.tournament?.id ?? 0,
            name: event.tournament?.tournamentName ?? '',
            info: event.tournament?.tournamentInfo ?? '',
            size: LeagueSize.fromOddsApi(event.tournament?.tournamentSize ?? EnumTournamentSizeType.Small),
            region: {
                id: event.tournament?.region?.id ?? 0,
                name: event.tournament?.region?.regionName ?? '',
            },
        },
        sportType: SportType.fromOddsApi(event.tournament?.sport?.sportType ?? EnumSportType.Soccer),
        stream: formatStream(event),
    });
}

type EventWithStream = Pick<EventTypeFragment, 'id' | 'stream'>

export function formatStream(event: EventWithStream) {
    return event.stream ? {
        videoUrl: event.stream.videoUrl ?? null,
        betRadar: event.stream.betRadar ? {
            eventId: event.id,
            streamId: event.stream.betRadar.streamId,
            streamStatus: event.stream.betRadar.streamStatus as BetRadarStreamStatus,
            allowedCountries: event.stream.betRadar.allowedCountries,
        } : null,
        gLive: event.stream.gLive ? {
            eventId: event.id,
            isPlaying: event.stream.gLive.isPlaying,
        } : null,
        betConstruct: event.stream.betConstruct ? {
            eventId: event.stream.betConstruct.eventId,
            allowedCountries: event.stream.betConstruct.allowedCountries,
            isPlaying: event.stream.betConstruct.isPlaying,
        } : null,
    } : null;
}
