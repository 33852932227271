/* eslint-disable function-paren-newline */
import type { MaybeRef } from '@vueuse/core';
import { get } from '@vueuse/core';
import type { Ref } from 'vue';
import { computed, onUnmounted, watch } from 'vue';
import { useState } from '@/composable/useState';

interface CountdownOption {
    enabled?: Ref<boolean>;
    immediate?: boolean;
    minLoadingTime?: number;
}

// eslint-disable-next-line space-before-function-paren
export function useCountdown<R, F extends (...params: any[]) => Promise<R>>(
    refreshDuration: MaybeRef<number>,
    callback: F,
    option?: CountdownOption,
) {
    const refreshDurationRef = computed(() => get(refreshDuration));
    const [countdown, setCountdown] = useState(refreshDurationRef.value);
    const [isLoading, setIsLoading] = useState(false);
    const isEnabled = computed(() => option?.enabled?.value ?? true);
    let callbackQueue: Promise<R>[] = [];

    let intervalId: number;
    const stopCountdown = () => clearInterval(intervalId);
    const startCountdown = () => {
        stopCountdown();
        setCountdown(refreshDurationRef.value);

        intervalId = window.setInterval(() => {
            setCountdown(countdown.value - 1);
        }, 1000);
    };
    const resetCountdown = () => {
        startCountdown();
    };

    let prevLoadingAt = new Date().getTime();
    let loadingTimeoutId = 0;

    const _execCallback = async (force: boolean, ...params: Parameters<F>) => {
        if (!force && isLoading.value) return;
        if (!isEnabled.value) return;

        stopCountdown();
        if (!isLoading.value) {
            setIsLoading(true);
            prevLoadingAt = new Date().getTime();
        }

        const promise = callback(...params);
        callbackQueue.push(promise);
        try {
            await promise;
        } catch (error) {
            console.error(error);
        }
        callbackQueue = callbackQueue.filter(p => p !== promise);
        if (callbackQueue.length === 0) {
            const now = new Date().getTime();
            const diff = now - prevLoadingAt;
            if (!option?.minLoadingTime || diff >= option.minLoadingTime) {
                setIsLoading(false);
                startCountdown();
            } else {
                window.clearTimeout(loadingTimeoutId);
                loadingTimeoutId = window.setTimeout(() => {
                    setIsLoading(false);
                    startCountdown();
                }, option.minLoadingTime - diff);
            }
        }
    };

    onUnmounted(() => stopCountdown());

    watch([refreshDurationRef, isEnabled], () => {
        if (isEnabled.value) {
            if (option?.immediate === true) {
                // @ts-expect-error I don't know how to type this
                _execCallback(false);
            } else {
                startCountdown();
            }
        } else {
            stopCountdown();
        }
    }, { immediate: true });

    watch(countdown, (sec) => {
        if (sec <= 0) {
        // @ts-expect-error I don't know how to type this
            _execCallback(false);
        }
    });

    return {
        countdown,
        isLoading,
        resetCountdown,
        /** Trigger the callback immediately */
        trigger: (...params: Parameters<F>) => _execCallback(false, ...params),
        /** Trigger the callback immediately without checking isLoading */
        forceTrigger: (...params: Parameters<F>) => _execCallback(true, ...params),
    };
}
