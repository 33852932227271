import { sleep } from '@sports-utils/timer';
import { useBroadcastChannel, whenever } from '@vueuse/core';
import { cloneDeep } from 'lodash';
import { storeToRefs } from 'pinia';
import type { Ref } from 'vue';
import { computed, watch } from 'vue';
import { useAutoResetState } from '@/composable/useAutoResetState';
import { useBetSlipMessage } from '@/composable/useBetSlipMessage';
import { useEventEmitter } from '@/composable/useEventEmitter';
import { usePlatform } from '@/composable/usePlatform';
import { createReceipt, useReceiptCollection } from '@/composable/useReceiptCollection';
import { useState } from '@/composable/useState';
import { Api } from '@/core/lib/api';
import { getTicketKey } from '@/core/lib/betSlipHelper.euro';
import { i18n } from '@/core/lib/i18n';
import { getActualStake, getPlacedErrorMessage, getTotalOddsPrice } from '@/core/lib/ticket';
import type { IBetSlipEuro, ITicketEuro } from '@/interface/IBetSlip';
import type { IBetSlipStoreEuro } from '@/interface/IBetSlipStoreEuro';
import type { IEventEuro } from '@/interface/IEvent';
import type { IOdds, IPrice } from '@/interface/IOdds';
import { BetSlipsEuroStatus } from '@/interface/IPlaceBetButton';
import type { BetSlipBroadcastMessage } from '@/interface/broadcastchannel';
import type { EuroBetPageType } from '@/interface/enum';
import {
    BetSlipMessagePriority,
    BroadcastChannelName,
    OpenTicketStatus,
    PlaceOrderStatus,
    PriceStyle,
    SiteStyle,
} from '@/interface/enum';
import type { IMixParlayBetSlipEuro } from '@/store/betSlipStore.euro';
import { useCustomerStore } from '@/store/customerStore';
import { useToggleStore } from '@/store/toggleStore';

export const useMixParlayBetSlip = (betSlip: Ref<IMixParlayBetSlipEuro>, balance: Ref<number | null>): IBetSlipStoreEuro => {
    const { receipts, resetReceipts, setReceiptSuccessMessage } = useReceiptCollection();

    const { isAcceptAnyOdds } = storeToRefs(useCustomerStore());
    const isAutoAcceptChange = computed(() => isAcceptAnyOdds.value || betSlip.value.stake === 0);

    const { refreshBalanceAfterAction } = usePlatform();
    const [isPlacingBet, setIsPlacingBet] = useState(false);
    const { message, setMessage, resetMessage, startAutoResetMessage } = useBetSlipMessage();
    const [placedErrorMessages, setPlacedErrorMessages] = useAutoResetState<string[]>([], 5000);

    whenever(() => betSlip.value.tickets.length === 0, () => {
        startAutoResetMessage();
    });

    const addBet = (event: IEventEuro, odds: IOdds, priceOption: IPrice, betPage: EuroBetPageType) => {
        /**
         * Check if the bet is already existed.
         * Normally, this should not happen. Just in case.
         */
        const betSlipKey = getTicketKey(odds, priceOption);
        const isExisted = betSlip.value.tickets.some(x => x.key === betSlipKey);
        if (isExisted) return;

        /**
         * Clear all receipts when a new bet is added
         */
        resetReceipts();

        /**
         * Clear all error message when a new bet is added
         */
        resetMessage();

        const newSubBet: ITicketEuro = {
            key: betSlipKey,
            betPage,
            priceStyle: PriceStyle.Unknown,
            event: cloneDeep(event),
            odds: cloneDeep(odds),
            priceOption: cloneDeep(priceOption),
            liveHomeScore: 0,
            liveAwayScore: 0,
        };

        betSlip.value = {
            ...betSlip.value,
            tickets: [
                // MP in same event is not allowed
                ...betSlip.value.tickets.filter(t => t.event.id !== event.id),
                newSubBet,
            ],
        };
    };

    const removeBet = (key: string) => {
        betSlip.value = {
            ...betSlip.value,
            tickets: betSlip.value.tickets.filter(t => t.key !== key),
        };
    };

    const removeAll = () => {
        Api.clearTicket();
        betSlip.value.tickets = [];
        betSlip.value.stake = 0;
    };

    const updateBet = (uid: string, updateFn: (parlayBetSlip: IBetSlipEuro) => void) => {
        if (!betSlip.value) return;
        updateFn(betSlip.value);
    };

    const actualStake = (betSlip: IBetSlipEuro) => getActualStake(betSlip.stake, getTotalOddsPrice(betSlip.tickets));
    const { placeBetDelayTime } = storeToRefs(useToggleStore(SiteStyle.Euro));
    const placeBet = async () => {
        if (balance.value !== null && betSlip.value.stake > balance.value) {
            setMessage(i18n.t('bet_slip_error_message_not_enough_balance'));
            return;
        }

        try {
            setIsPlacingBet(true);
            const [{ data: response }] = await Promise.all([
                Api.placeEuroMixParlayBet(betSlip.value, betSlip.value.stake),
                sleep(placeBetDelayTime.value * 1000),
            ]);
            if (response.isPlaceBetSuccess) {
                emitPlaceBetSuccess();
                refreshBalanceAfterAction();

                receipts.value.list = [
                    createReceipt(betSlip.value, response),
                ];
                setReceiptSuccessMessage();

                removeAll();
            } else {
                const placeOrderStatus = PlaceOrderStatus.fromPollux(response.placeOrderStatus);
                betSlip.value.placedOrderError = {
                    status: placeOrderStatus,
                    message: '',
                };
                const message = getPlacedErrorMessage(placeOrderStatus, response.extraInfo);
                setPlacedErrorMessages([message]);
            }
        } catch (error) {
            console.error('Failed to place bet', error);
            const message = getPlacedErrorMessage(PlaceOrderStatus.GeneralFailure, '');
            setPlacedErrorMessages([message]);
        } finally {
            setIsPlacingBet(false);
        }
    };

    const { post } = useBroadcastChannel<BetSlipBroadcastMessage, BetSlipBroadcastMessage>({ name: BroadcastChannelName.BetSlip });
    function emitPlaceBetSuccess() {
        const isLive = betSlip.value.tickets.some(ticket => ticket.odds.isLive);
        post({ name: 'placeBetSuccess', isLive });
    }

    const [hasPriceChange, setHasPriceChange] = useState(false);
    const eventEmitter = useEventEmitter();
    eventEmitter.on('ticket:price:change', () => {
        setHasPriceChange(true);
    });
    eventEmitter.on('ticket:accept:price:change', () => {
        setHasPriceChange(false);
    });

    const betSlipStatusList = computed(() => {
        const statusList: BetSlipsEuroStatus[] = [];

        if (isPlacingBet.value) {
            statusList.push(BetSlipsEuroStatus.Loading);
        }

        const { uid, stake, minBet, maxBet, tickets } = betSlip.value;

        const isTicketOpened = uid !== '';
        if (isTicketOpened) {
            if (stake === 0) {
                statusList.push(BetSlipsEuroStatus.Unavailable);
            } else if (stake < minBet) {
                statusList.push(BetSlipsEuroStatus.LessThanMinBet);
            } else if (stake > maxBet) {
                statusList.push(BetSlipsEuroStatus.GreaterThanMaxBet);
            }
        } else {
            // Means that Ticket not opened yet
            statusList.push(BetSlipsEuroStatus.Unavailable);
        }

        if (!isAutoAcceptChange.value && hasPriceChange.value) {
            statusList.push(BetSlipsEuroStatus.PriceChange);
        }

        if (tickets.length === 0 || statusList.length === 0) {
            statusList.push(BetSlipsEuroStatus.Valid);
        }

        return statusList;
    });

    const placeBetStatus = computed(() => Math.max(...betSlipStatusList.value));

    watch(betSlipStatusList, () => {
        const isInvalidStake = BetSlipsEuroStatus.isInvalidStake(betSlipStatusList.value);
        const displayPriceChange = betSlipStatusList.value.includes(BetSlipsEuroStatus.PriceChange) && !isAutoAcceptChange.value;

        if (isInvalidStake && displayPriceChange) {
            setMessage(i18n.t('bet_slip_error_message_stake_update_stake_and_accept_price_change'), BetSlipMessagePriority.OddsChange);
        } else if (isInvalidStake) {
            setMessage(i18n.t('bet_slip_error_message_stake_update_stake'), BetSlipMessagePriority.InvalidStake);
        } else if (displayPriceChange) {
            setMessage(i18n.t('bet_slip_error_message_stake_accept_price_change'), BetSlipMessagePriority.OddsChange);
        } else if (betSlipStatusList.value.includes(BetSlipsEuroStatus.Valid)) {
            resetMessage();
        }
    });

    watch(() => betSlip.value.openTicketError, (openTicketError) => {
        if (openTicketError === OpenTicketStatus.MaxOddsExceed) {
            setMessage(i18n.t('bet_slip_error_message_over_max_odds'));
        } else {
            resetMessage();
        }
    });

    return {
        betSlipStatusList,
        placeBetStatus,

        receipts,
        resetReceipts,

        actualStake,

        isAutoAcceptChange,
        isPlacingBet,
        setIsPlacingBet,

        betSlipMessage: message,
        setBetSlipMessage: setMessage,
        placedErrorMessages,

        addBet,
        removeBet,
        updateBet,
        removeAll,
        placeBet,
    };
};
