<template>
    <div
        class="multipleBetType notranslate"
        v-bind:class="[styleWidth, { live: event.isLive }]"
    >
        <strong v-tooltip:top="title" class="multipleBetType_title">
            <div
                v-if="isShowScore && liveScore"
                class="score"
            >
                [ {{ liveScore.home }}<span>:</span>{{ liveScore.away }} ]
            </div>
            {{ title }}
            <VButton
                v-if="false"
                padding="0 2px"
                v-on:click="toggleIsExpand"
            >
                <SvgIcon
                    name="icon-system-arrow"
                    v-bind:rotated="!isExpand"
                />
            </VButton>
        </strong>
        <ExpandTransition>
            <div v-if="isExpand" class="multipleBetType_content">
                <div class="multipleBetType_content_col ">
                    <template v-for="(row, rowIdx) in oddsRow" v-bind:key="rowIdx">
                        <strong v-if="rowIdx === 0" class="titleCell titleCell-breakLine">
                            {{ i18n.t('odds_option_event') }}
                        </strong>
                        <strong
                            v-tooltip="homeText"
                            class="titleCell"
                            v-bind:class="{
                                'team-favorite': isHomeFavorite(odds?.eventResult),
                            }"
                        >
                            <div class="titleCell_contents">
                                {{ homeText }}
                            </div>
                        </strong>
                        <strong
                            v-tooltip="awayText"
                            class="titleCell"
                            v-bind:class="{
                                'team-favorite': isAwayFavorite(odds?.eventResult),
                            }"
                        >
                            <div class="titleCell_contents">
                                {{ awayText }}
                            </div>
                        </strong>
                        <strong
                            v-if="has1X2(row)"
                            v-tooltip="i18n.t('odds_option_draw')"
                            class="titleCell team-draw"
                        >
                            {{ i18n.t('odds_option_draw') }}
                        </strong>
                    </template>
                </div>

                <template v-for="(titleOdds, colIdx) in oddsRow[0]" v-bind:key="colIdx">
                    <div class="multipleBetType_content_col ">
                        <strong
                            v-if="titleOdds"
                            v-tooltip:top="getMarketTooltip(titleOdds.marketType)"
                            class="titleCell titleCell-breakLine"
                            v-bind:class="{ 'cursor-help': !!getMarketTooltip(titleOdds.marketType) }"
                        >
                            <div v-bind:data-market="MarketType[titleOdds.marketType]">
                                {{ getMarketTitle(titleOdds.marketType) }}
                            </div>
                        </strong>
                        <template v-for="(row, rowIdx) in oddsRow" v-bind:key="rowIdx">
                            <template v-if="row[colIdx]">
                                <template v-for="oddsItem in [row[colIdx] as IOdds]">
                                    <strong
                                        v-for="price in (oddsItem.prices)"
                                        v-bind:key="`${oddsItem.id}-${price.option}`"
                                        class="oddsItem"
                                    >
                                        <div v-if="isShowPoint(oddsItem.marketType, getMarketGroupId(oddsItem))" class="oddsPoint">
                                            {{ getOddsPoint(oddsItem, price) }}
                                        </div>
                                        <div v-if="getTag(oddsItem, price)" class="oddsTag">
                                            {{ getTag(oddsItem, price) }}
                                        </div>
                                        <Odds
                                            v-bind:eventId="event.id"
                                            v-bind:oddsId="oddsItem.id"
                                            v-bind:oddsPrice="price"
                                            v-bind:oddsStatus="oddsItem.status"
                                            v-bind:align="(isShowPoint(oddsItem.marketType, getMarketGroupId(oddsItem)) || getTag(oddsItem, price)) ? 'right' : 'center'"
                                            class="oddsPrice"
                                        />
                                    </strong>
                                    <div
                                        v-for="index in Math.max(0, (has1X2(row) ? 3 : 2) - oddsItem.prices.length)"
                                        v-bind:key="index"
                                        class="oddsItem"
                                    />
                                </template>
                            </template>
                            <div
                                v-for="index in Math.max(0, (has1X2(row) ? 3 : 2))"
                                v-else
                                v-bind:key="index"
                                class="oddsItem"
                            />
                        </template>
                    </div>
                </template>
            </div>
        </ExpandTransition>
    </div>
</template>

<script lang="ts" setup>
    import { computed, toRefs } from 'vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import VButton from '@/components/common/button/VButton.vue';
    import Odds from '@/components/odds/Odds.vue';
    import { MoreMarketTooltipMapping } from '@/components/oddsDisplay/marketTypeDisplayRule';
    import { getTeamName, isAwayFavorite, isHomeFavorite } from '@/components/oddsDisplay/matchDisplay/useMatchDisplay';
    import { defaultTitleFormatter, getMarketRule } from '@/components/oddsDisplay/moreMarket/marketRules';
    import ExpandTransition from '@/components/transitions/ExpandTransition.vue';
    import { useToggle } from '@/composable/useToggle';
    import { groupByWithOrder } from '@/core/lib/array';
    import { i18n } from '@/core/lib/i18n';
    import { getMarketGroupId, getOddsPoint, getTag, has1X2, isOE, isOU, isShowPoint } from '@/core/lib/oddsHelper';
    import { isMobile } from '@/core/lib/utils';
    import { getScore, isShowScoreInMoreMarket } from '@/core/oddsApi/helpers/liveScore';
    import type { IEvent } from '@/interface/IEvent';
    import type { IOdds } from '@/interface/IOdds';
    import { MarketType, TeamOption } from '@/interface/enum';

    const props = defineProps<{
        event: IEvent;
        odds: IOdds;
        oddsList: IOdds[];
    }>();

    const [isExpand, toggleIsExpand] = useToggle(true);

    const { event } = toRefs(props);

    const getMarketTitle = (marketType: MarketType) => i18n.t(`market_type_short_name_${marketType}`);
    const getMarketTooltip = (marketType: MarketType) => {
        if (isMobile()) return '';

        const key = MoreMarketTooltipMapping[marketType] ?? MarketType[marketType];
        return i18n.t(key);
    };

    const isShowScore = computed(() => isShowScoreInMoreMarket(props.event, getMarketGroupId(props.odds)));

    const liveScore = computed(() => getScore(event.value.eventResults, getMarketGroupId(props.odds)));
    const title = computed(() => defaultTitleFormatter(props.event, props.oddsList));

    const homeText = computed(() => {
        if (props.odds.eventResult.marketGroup.isYesNo) {
            return getTeamName(TeamOption.Home, props.event, props.oddsList);
        }
        if (props.oddsList.every(odds => isOU(odds.marketType))) return i18n.t('odds_option_over');
        if (props.oddsList.every(odds => isOE(odds.marketType))) return i18n.t('odds_option_odd');

        return i18n.t('home');
    });

    const awayText = computed(() => {
        if (props.odds.eventResult.marketGroup.isYesNo) {
            return getTeamName(TeamOption.Away, props.event, props.oddsList);
        }
        if (props.oddsList.every(odds => isOU(odds.marketType))) return i18n.t('odds_option_under');
        if (props.oddsList.every(odds => isOE(odds.marketType))) return i18n.t('odds_option_even');

        return i18n.t('away');
    });

    const oddsList = computed(() => props.oddsList.map(odds => ({
        ...odds,
        prices: getMarketRule(odds.marketType).multiplePricesFormatter(odds.prices),
    })));
    const groupedOddsList = computed(() => groupByWithOrder(oddsList.value, odds => odds.marketType));
    const maxRowLength = computed(() => Math.max(1, ...groupedOddsList.value.map(x => x.length)));
    const oddsRow = computed(() => new Array<null[]>(maxRowLength.value)
        .fill(new Array<null>(groupedOddsList.value.length).fill(null))
        .map<(IOdds | null)[]>((row, rowIndex) => row
            .map<IOdds | null>((_, columnIndex) => groupedOddsList.value[columnIndex][rowIndex] ?? null)));

    const styleWidth = computed(() => {
        if (groupedOddsList.value.length === 1) return 'width-31';
        if (groupedOddsList.value.length <= 3) return 'width-21';
        if (groupedOddsList.value.length <= 5) return 'width-32';
        return 'width-full';
    });
</script>

<style lang="scss" scoped>
    @import "@/components/oddsDisplay/moreMarket/marketType/MultipleBetType";
</style>
