import type { MaybeRef } from '@vueuse/core';
import { get } from '@vueuse/core';
import type { Maybe } from 'graphql/jsutils/Maybe';
import { isEqual } from 'lodash';
import { computed, ref } from 'vue';
import { deepFreeze } from '@/core/lib/utils';
import { isWsConnected } from '@/core/oddsApi/apollo';
import type { BetRadarLiveScoreTypeFragment, CricketLiveScoreTypeFragment, SboLiveScoreTypeFragment, SdtLiveScoreTypeFragment, ThirdPartyLiveScoreTypeFragment } from '@/core/oddsApi/oddsApiType';
import { useLiveScoreQuery, useLiveScoreSubscription } from '@/core/oddsApi/oddsApiType';
import type { IBetConstructLiveScore, IBetRadarLiveScore, ICricketLiveScore, IProviderLiveScore, ISboLiveScore, ISdtLiveScore } from '@/interface/ILiveScore';
import { BetConstructLiveScoreStatus, BetRadarLiveScoreStatus, MatchStatus } from '@/interface/enum';

export function useLiveScore(eventId: MaybeRef<number>, isEnabled: MaybeRef<boolean> = true) {
    const variables = computed(() => ({
        query: {
            id: get(eventId),
        },
    }));

    const isFirstLoaded = ref(false);
    const isValidEventId = computed(() => get(eventId) > 0);
    const options = computed(() => ({
        pollInterval: 30000,
        enabled: get(isEnabled) && isValidEventId.value && (!isFirstLoaded.value || !isWsConnected.value),
    }));

    const { onResult } = useLiveScoreQuery(
        variables,
        options,
    );

    const liveScore = ref<IProviderLiveScore>();
    onResult((result) => {
        isFirstLoaded.value = true;

        if (!result) return;

        const newScore = deepFreeze(formatLiveScore(result));
        if (!isEqual(liveScore.value, newScore)) {
            liveScore.value = newScore;
        }
    });

    const { onSubscription } = useLiveScoreSubscription(
        variables,
        computed(() => ({
            enabled: get(isEnabled) && isValidEventId.value,
        })),
    );

    onSubscription((result) => {
        if (!result) return;

        const newScore = deepFreeze(formatLiveScore(result));
        if (!isEqual(liveScore.value, newScore)) {
            liveScore.value = newScore;
        }
    });

    return {
        liveScore,
    };
}

function formatLiveScore(result: {
    sbo?: Maybe<SboLiveScoreTypeFragment>;
    sdt?: Maybe<SdtLiveScoreTypeFragment>;
    betRadar?: Maybe<BetRadarLiveScoreTypeFragment>;
    betConstruct?: Maybe<ThirdPartyLiveScoreTypeFragment>;
    cricket?: Maybe<CricketLiveScoreTypeFragment>;
}): IProviderLiveScore {
    const { sbo, sdt, betConstruct, betRadar, cricket } = result;

    return {
        sbo: formatSboLiveScore(sbo),
        sdt: formatSdtLiveScore(sdt),
        betConstruct: formatBetConstructLiveScore(betConstruct),
        betRadar: formatBetRadarLiveScore(betRadar),
        cricket: formatCricketLiveScore(cricket),
    };
}

function formatBetRadarLiveScore(betRadar: Maybe<BetRadarLiveScoreTypeFragment>): IBetRadarLiveScore | null {
    if (!betRadar) return null;
    return {
        gameScore: {
            home: betRadar.gameHomeScore ?? 0,
            away: betRadar.gameAwayScore ?? 0,
        },
        setScore: {
            home: betRadar.setHomeScore ?? 0,
            away: betRadar.setAwayScore ?? 0,
        },
        totalScore: {
            home: betRadar.totalHomeScore ?? 0,
            away: betRadar.totalAwayScore ?? 0,
        },
        inPlayPeriod: betRadar.inPlayPeriod ?? '',
        inPlayInning: betRadar.inPlayInning ?? '',
        matchStatus: betRadar.matchStatus ? MatchStatus.fromOddsApi(betRadar.matchStatus) : MatchStatus.Unknown,
        status: betRadar.status ? BetRadarLiveScoreStatus.fromOddsApi(betRadar.status) : BetRadarLiveScoreStatus.Unknown,
    };
}

function formatBetConstructLiveScore(betConstruct: Maybe<ThirdPartyLiveScoreTypeFragment>): IBetConstructLiveScore | null {
    if (!betConstruct) return null;
    return {
        gameScore: {
            home: betConstruct.gameHomeScore ?? 0,
            away: betConstruct.gameAwayScore ?? 0,
        },
        setScore: {
            home: betConstruct.setHomeScore ?? 0,
            away: betConstruct.setAwayScore ?? 0,
        },
        totalScore: {
            home: betConstruct.totalHomeScore ?? 0,
            away: betConstruct.totalAwayScore ?? 0,
        },
        inPlayPeriod: betConstruct.inPlayPeriod ?? '',
        inPlayInning: betConstruct.inPlayPeriod ?? '',
        matchStatus: betConstruct.matchStatus ? MatchStatus.fromOddsApi(betConstruct.matchStatus) : MatchStatus.Unknown,
        status: betConstruct.status ? BetConstructLiveScoreStatus.fromOddsApi(betConstruct.status) : BetConstructLiveScoreStatus.Unknown,
    };
}

function formatSdtLiveScore(sdt: Maybe<SdtLiveScoreTypeFragment>): ISdtLiveScore | null {
    if (!sdt) return null;
    return {
        score: {
            home: sdt.homeScore ?? 0,
            away: sdt.awayScore ?? 0,
        },
        status: sdt?.status ?? 0,
    };
}

function formatSboLiveScore(sbo: Maybe<SboLiveScoreTypeFragment>): ISboLiveScore | null {
    if (!sbo) return null;
    return {
        id: sbo.eventId ?? 0,
        score: {
            home: sbo.homeScore ?? 0,
            away: sbo.awayScore ?? 0,
        },
    };
}

function formatCricketLiveScore(cricket: Maybe<CricketLiveScoreTypeFragment>): ICricketLiveScore | null {
    if (!cricket) return null;
    return {
        score: {
            home: cricket.homeScore,
            away: cricket.awayScore,
        },
        homeDismissals: cricket.homeDismissals,
        awayDismissals: cricket.awayDismissals,
        innings: cricket.innings,
        over: Math.max(cricket.over - 1, 0),
        delivery: cricket.delivery,
    };
}
