import { until, watchOnce } from '@vueuse/core';
import { isAfter } from 'date-fns';
import { defineStore, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useBannerAsia, useBannerEuro } from '@/composable/useBanners';
import { useLocalStorage } from '@/composable/useLocalStorage';
import { addHour, parseISOString } from '@/core/lib/date';
import { BannerPosition, SiteStyle } from '@/interface/enum';
import { useCustomerStore } from '@/store/customerStore';

export const usePopupBannerStore = defineStore('popupBanner', () => {
    const { siteStyle } = storeToRefs(useCustomerStore());
    const { executeBannerAction, banners, isLoaded } = siteStyle.value === SiteStyle.Euro
        ? useBannerEuro(BannerPosition.EuroPopup)
        : useBannerAsia(BannerPosition.AsiaPopup);

    const banner = computed(() => (banners.value ? banners.value[0] : null));

    const isShowing = ref(false);

    const { accountId } = storeToRefs(useCustomerStore());

    const [popupExpiration, setPopupExpiration] = useLocalStorage<Date>(
        `popupBanner:${accountId.value}`,
        new Date(0),
        {
            decode: value => parseISOString(value),
            encode: value => value.toISOString(),
        },
    );

    const frequencyHour = computed(() => banner.value?.remark?.frequencyHour ?? 1);

    const closePopup = () => {
        isShowing.value = false;

        const expiration = addHour(new Date(), frequencyHour.value);
        setPopupExpiration(expiration);
    };

    const onClickBanner = () => {
        executeBannerAction(banner.value);
        closePopup();
    };

    function checkAndShowBanner() {
        if (!banner.value) return;

        const isBannerExpired = isAfter(new Date(), popupExpiration.value);
        if (isBannerExpired) {
            isShowing.value = true;
        }
    }

    function show() {
        return new Promise<void>((resolve) => {
            watchOnce(isLoaded, async () => {
                if (!isLoaded.value) return;

                checkAndShowBanner();

                await until(isShowing).toBe(false);
                resolve();
            });
        });
    }

    return {
        show,
        banner,
        closePopup,
        onClickBanner,
        isShowing,
    };
});
