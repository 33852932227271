import type { ISelectedLeagueInfo } from '@/interface/ISelectedLeagueInfo';
import type { EventDateType, MarketPage, OddsDaylightType } from '@/interface/enum';
import { SportType } from '@/interface/enum';
import type { SelectedLeagueInfoRequest, SelectedLeagueInfoResponse } from '@/interface/pollux';

export function formatSelectedLeagueInfo(info: SelectedLeagueInfoResponse): ISelectedLeagueInfo {
    return {
        sportType: SportType.fromPollux(info.sportType),
        marketPage: info.marketPage as MarketPage,
        eventDate: info.eventDate as EventDateType,
        daylightType: info.daylightType as OddsDaylightType,
        leagueIds: info.leagueIds ?? [],
        isSelectAllBigLeagues: info.isSelectAllBigLeagues,
    };
}

export function formatSelectedLeagueInfoRequest(info: ISelectedLeagueInfo): SelectedLeagueInfoRequest {
    return {
        sportType: SportType.toPollux(info.sportType),
        marketPage: info.marketPage,
        eventDate: info.eventDate,
        daylightType: info.daylightType,
        leagueIds: info.leagueIds ?? [],
        isSelectAllBigLeagues: info.isSelectAllBigLeagues,
    };
}
