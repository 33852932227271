import { DebugLogPlugin, DisablePlugin, createGtm, installGtm } from '@sports-utils/gtm';
import type { App } from 'vue';
import { isSupportedDeviceForGtm } from '@/core/lib/utils';

const plugins = [
    DisablePlugin(),
];
if (__ENABLE_GTM_DEBUG_LOG__) {
    plugins.push(DebugLogPlugin());
}

export const sportsGtm = createGtm({
    // decide will event being sent to GTM container (default: true)
    isEnabled: __ENABLE_GTM__ && isSupportedDeviceForGtm(),
    // GTM container ID
    gtmId: 'GTM-PWKF364',
    // Optional, specific the dataLayer name
    // Hint: you should always do this to prevent conflict with other departments
    dataLayerName: 'sportsDesktopLayer',
    plugins,
});

export function installSportsGtm(app: App) {
    installGtm(app, sportsGtm);
}
