import type { Ref } from 'vue';
import { computed, defineAsyncComponent } from 'vue';
import BetConstructStream from '@/components/liveStream/betConstructStream/BetConstructStream.vue';
import BetRadarStream from '@/components/liveStream/betRadarStream/BetRadarStream.vue';
import GLiveStream from '@/components/liveStream/gLiveStream/GLiveStream.vue';
import TwitchLiveStream from '@/components/liveStream/twitchLiveStream/TwitchLiveStream.vue';
import type { UseLiveStreamEvent } from '@/composable/useLiveStream';
import { useLiveStream } from '@/composable/useLiveStream';

const VideoJsStream = defineAsyncComponent(() => import('@/components/liveStream/videoJsStream/VideoJsStream.vue'));

// TODO: refactor with open-close principle to separate provider implementations
export function useLiveStreamComponent(event: Ref<UseLiveStreamEvent | null>) {
    const { isLiveStreamPlayable, isTwitchPlayable, isMuayThaiPlayable, isBetRadarPlayable, isGLivePlayable, isBetConstructPlayable } = useLiveStream(event);

    const streamDisplay = computed(() => {
        if (!isLiveStreamPlayable.value || !event.value?.stream) return null;

        const { videoUrl, betRadar, gLive, betConstruct } = event.value.stream;

        if (isTwitchPlayable.value && videoUrl) {
            return {
                component: TwitchLiveStream,
                key: videoUrl,
                videoUrl,
            };
        }

        if (isMuayThaiPlayable.value && videoUrl) {
            return {
                component: VideoJsStream,
                key: videoUrl,
                videoUrl,
            };
        }

        if (isBetRadarPlayable.value && betRadar) {
            return {
                component: BetRadarStream,
                key: betRadar.eventId,
                betRadar,
            };
        }

        if (isGLivePlayable.value && gLive) {
            return {
                component: GLiveStream,
                key: gLive.eventId,
                gLive,
            };
        }

        if (isBetConstructPlayable.value && betConstruct) {
            return {
                component: BetConstructStream,
                key: betConstruct.eventId,
                betConstruct,
            };
        }

        return null;
    });

    return {
        streamDisplay,
    };
}
