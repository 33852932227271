<template>
    <div v-debug:leagueId="league.id" class="league">
        <div
            class="leagueTitle"
            v-bind:class="{ live: isLive }"
        >
            <div
                class="leagueTitle_collapseBtn"
                v-bind:style="{ width: `${timeColumnWidth}px` }"
            >
                <VButton
                    v-gtm:label="collapseGtmLabel"
                    v-gtm:@click
                    flat
                    block
                    padding="0"
                    v-on:click="isCollapsed = !isCollapsed"
                >
                    <SvgIcon
                        name="icon-system-arrow"
                        v-bind:rotated="isCollapsed"
                    />
                </VButton>
            </div>
            <span class="leagueTitle_name">
                <Favorite
                    v-if="isShowFavorite"
                    type="LEAGUE"
                    v-bind:sport="sportType"
                    v-bind:leagueId="league.id"
                    class="leagueTitle_name_favorite"
                />
                {{ league.name }}
            </span>
            <SvgIcon
                v-if="league.info"
                v-tooltip="league.info"
                name="icon-system-cycle-info"
                right
            />
            <div
                class="leagueTitle_refresher"
                v-bind:style="{ width: `${moreColumWidth}px` }"
            >
                <VButton
                    v-gtm:label="'Refresh'"
                    v-gtm:@click
                    flat
                    block
                    padding="0"
                    v-on:click="refresh"
                >
                    <SvgIcon
                        name="icon-system-refresh"
                        v-bind:rotating="isRefreshing"
                    />
                </VButton>
            </div>
        </div>
        <template v-if="!isCollapsed">
            <OddsDisplayMatch
                v-for="event in eventList"
                v-bind:key="event.id"
                v-bind:event="event"
                v-bind:newBetTypeTeleportTargets="newBetTypeTeleportTargets"
            />
            <template
                v-for="[marketGroupId, eventResults] in moreMarketEventResultsByMarketGroup"
                v-bind:key="marketGroupId"
            >
                <div
                    class="leaguesNewBetType"
                    v-bind:class="{ live: isLive }"
                >
                    {{ eventResults[0].marketGroup.marketGroupName }}
                </div>
                <div
                    v-for="(eventResult, index) in eventResults"
                    v-bind:key="eventResult.id"
                    v-bind:ref="(el) => el
                        ? newBetTypeTeleportTargets.set(eventResult.id, { element: el as HTMLDivElement, eventResultIndex: index })
                        : newBetTypeTeleportTargets.delete(eventResult.id)"
                />
            </template>
        </template>
    </div>
</template>

<script lang="ts" setup>
    import entries from 'lodash/fp/entries';
    import flatMap from 'lodash/fp/flatMap';
    import flow from 'lodash/fp/flow';
    import groupBy from 'lodash/fp/groupBy';
    import sortBy from 'lodash/fp/sortBy';
    import { storeToRefs } from 'pinia';
    import type { PropType } from 'vue';
    import { computed, ref } from 'vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import VButton from '@/components/common/button/VButton.vue';
    import Favorite from '@/components/favorite/Favorite.vue';
    import { OddsDisplayContext } from '@/components/oddsDisplay/OddsDisplayContext';
    import OddsDisplayMatch from '@/components/oddsDisplay/OddsDisplayMatch.vue';
    import { isShowNewBetTypeOnMainPage } from '@/components/oddsDisplay/matchDisplay/useMatchDisplay';
    import { getOddsTableColumns } from '@/components/oddsDisplay/oddsTableCalculation';
    import { useAutoResetState } from '@/composable/useAutoResetState';
    import { useEventEmitter } from '@/composable/useEventEmitter';
    import type { IEvent } from '@/interface/IEvent';
    import type { ISimpleEventResult } from '@/interface/ISimpleEventResult';
    import { MarketPage, OddsDisplayBlockType, SiteStyle } from '@/interface/enum';
    import { useAsiaSportMarketInfoStore } from '@/store/asiaSportMarketInfoStore';
    import { useToggleStore } from '@/store/toggleStore';

    const props = defineProps({
        isLive: {
            type: Boolean,
            required: true,
        },
        eventList: {
            type: Object as PropType<IEvent[]>,
            required: true,
        },
    });

    const { isMyFavoriteEnabled } = storeToRefs(useToggleStore(SiteStyle.Asia));
    const { marketPage } = storeToRefs(useAsiaSportMarketInfoStore());
    const isShowFavorite = computed(() => isMyFavoriteEnabled.value && marketPage.value !== MarketPage.MixParlay);

    const eventEmitter = useEventEmitter();
    const { sportType, oddsDisplayMode, matchListInBetBuilderMode } = OddsDisplayContext.inject();
    const columns = computed(() => getOddsTableColumns(oddsDisplayMode, sportType, props.isLive));
    const timeColumnWidth = computed(() => columns.value.find(({ key }) => key === OddsDisplayBlockType.Time)!.width);
    const moreColumWidth = computed(() => columns.value.find(({ key }) => key === OddsDisplayBlockType.More)!.width);

    const league = computed(() => props.eventList[0].league);

    const [isRefreshing, setIsRefreshing] = useAutoResetState(false, 1000);
    const refresh = () => {
        if (isRefreshing.value) return;

        setIsRefreshing(true);

        eventEmitter.emit('oddsDisplay:refreshEventList');
        props.eventList.forEach((event) => {
            eventEmitter.emit('oddsDisplay:refreshOdds', event.id);
            eventEmitter.emit('oddsDisplay:refreshBetBuilderMatch', event.id);
        });
    };

    const moreMarketEventResultsByMarketGroup = computed(() => {
        const process = flow(
            flatMap<IEvent, ISimpleEventResult>(event => event.eventResults.filter(eventResult => isShowNewBetTypeOnMainPage(sportType, eventResult.marketGroup.id, props.isLive, oddsDisplayMode))),
            groupBy<ISimpleEventResult>(eventResult => eventResult.marketGroup.id),
            args => entries(args),
            sortBy<[string, ISimpleEventResult[]]>(([_marketGroupId, eventResults]) => eventResults[0].marketGroup.displayPriority),
        );

        return process(props.eventList.filter(event => !matchListInBetBuilderMode.value.includes(event.id)));
    });

    const { isGroupCollapsed } = OddsDisplayContext.inject();
    const isCollapsed = isGroupCollapsed(props.eventList[0].id);

    const collapseGtmLabel = computed(() => {
        if (props.isLive) {
            return isCollapsed.value ? 'ExpandLiveMatch' : 'CollapseLiveMatch';
        }
        return isCollapsed.value ? 'ExpandNonLiveMatch' : 'CollapseNonLiveMatch';
    });

    const newBetTypeTeleportTargets = ref(new Map<number, { element: HTMLDivElement, eventResultIndex: number }>());
</script>

<style lang="scss" scoped>
    @import "@/components/oddsDisplay/OddsDisplayLeague";
</style>
