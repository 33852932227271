import type { MaybeElement, UseIntersectionObserverOptions } from '@vueuse/core';
import { useIntersectionObserver } from '@vueuse/core';
import type { Ref } from 'vue';
import { ref } from 'vue';

export const useScrollToShowMore = (visibilityTarget: Ref<MaybeElement>, batchSize: number, options?: UseIntersectionObserverOptions) => {
    const showCount = ref(batchSize);
    const targetIsVisible = ref(false);

    useIntersectionObserver(visibilityTarget, ([{ isIntersecting }]) => {
        if (targetIsVisible.value !== isIntersecting) {
            if (isIntersecting) {
                showCount.value += batchSize;
            }

            targetIsVisible.value = isIntersecting;
        }
    }, options);

    const resetShowCount = (value = batchSize) => {
        showCount.value = value;
    };

    return {
        showCount,
        resetShowCount,
    };
};
