import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { AsiaBetPageType, EventDateType, MarketPage, OddsDaylightType, OddsDisplayMode } from '@/interface/enum';
import { useAsiaSportMarketInfoStore } from '@/store/asiaSportMarketInfoStore';
import { useOddsDisplaySettingsStore } from '@/store/oddsDisplaySettingsStore';

export function useBetPage() {
    const { marketPage, daylightType, eventDate, currentPromotionZoneConfig } = storeToRefs(useAsiaSportMarketInfoStore());
    const { oddsDisplayMode } = storeToRefs(useOddsDisplaySettingsStore());

    const betPage = computed(() => {
        const betPageFromPromotionZone = currentPromotionZoneConfig.value?.betPage;
        if (betPageFromPromotionZone) return betPageFromPromotionZone;

        if (oddsDisplayMode.value === OddsDisplayMode.Single) {
            switch (marketPage.value) {
                case MarketPage.Live:
                    return AsiaBetPageType.SingleLineLive;
                case MarketPage.Today:
                    switch (daylightType.value) {
                        case OddsDaylightType.Within3Hours: return AsiaBetPageType.SingleLineToday3Hours;
                        case OddsDaylightType.Within6Hours: return AsiaBetPageType.SingleLineToday6Hours;
                        default: return AsiaBetPageType.SingleLineToday;
                    }
                case MarketPage.EarlyMarket:
                    switch (eventDate.value) {
                        case EventDateType.Plus1: return AsiaBetPageType.SingleLineEarlyMarketPlus1;
                        case EventDateType.Plus2: return AsiaBetPageType.SingleLineEarlyMarketPlus2;
                        case EventDateType.Plus3: return AsiaBetPageType.SingleLineEarlyMarketPlus3;
                        case EventDateType.Plus4: return AsiaBetPageType.SingleLineEarlyMarketPlus4;
                        case EventDateType.Plus5: return AsiaBetPageType.SingleLineEarlyMarketPlus5;
                        case EventDateType.Plus6Over: return AsiaBetPageType.SingleLineEarlyMarketPlus6Over;
                        default: return AsiaBetPageType.SingleLineEarlyMarket;
                    }
                case MarketPage.MixParlay:
                    return AsiaBetPageType.MixParlay;
                case MarketPage.Outright:
                    return AsiaBetPageType.Outright;
                case MarketPage.MyFavorites:
                    return AsiaBetPageType.SingleLineMyFavorites;
                default:
                    return AsiaBetPageType.Unknown;
            }
        }

        switch (marketPage.value) {
            case MarketPage.Live:
                return AsiaBetPageType.DoubleLineLive;
            case MarketPage.Today:
                switch (daylightType.value) {
                    case OddsDaylightType.Within3Hours: return AsiaBetPageType.DoubleLineToday3Hours;
                    case OddsDaylightType.Within6Hours: return AsiaBetPageType.DoubleLineToday6Hours;
                    default: return AsiaBetPageType.DoubleLineToday;
                }
            case MarketPage.EarlyMarket:
                switch (eventDate.value) {
                    case EventDateType.Plus1: return AsiaBetPageType.DoubleLineEarlyMarketPlus1;
                    case EventDateType.Plus2: return AsiaBetPageType.DoubleLineEarlyMarketPlus2;
                    case EventDateType.Plus3: return AsiaBetPageType.DoubleLineEarlyMarketPlus3;
                    case EventDateType.Plus4: return AsiaBetPageType.DoubleLineEarlyMarketPlus4;
                    case EventDateType.Plus5: return AsiaBetPageType.DoubleLineEarlyMarketPlus5;
                    case EventDateType.Plus6Over: return AsiaBetPageType.DoubleLineEarlyMarketPlus6Over;
                    default: return AsiaBetPageType.DoubleLineEarlyMarket;
                }
            case MarketPage.MixParlay:
                return AsiaBetPageType.MixParlay;
            case MarketPage.Outright:
                return AsiaBetPageType.Outright;
            case MarketPage.MyFavorites:
                return AsiaBetPageType.DoubleLineMyFavorites;
            default:
                return AsiaBetPageType.Unknown;
        }
    });

    return {
        betPage,
    };
}
