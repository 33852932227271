<template>
    <div class="inputFieldWrapper">
        <div v-bind:class="['inputField', { _focused: isFocused, _error: !!errorMsg }]">
            <label for="amount">{{ sboCurrency }}</label>
            <input
                id="amount"
                ref="amountInput"
                v-bind:class="[{ error: !!errorMsg }]"
                type="text"
                autocomplete="off"
                v-on:input="onInput"
                v-on:focus="isFocused = true"
                v-on:blur="isFocused = false"
            >
            <SvgIcon
                v-if="isShowClose"
                size="xs"
                color="primary"
                name="icon-system-close"
                class="close"
                v-on:click="clearInput"
            />
        </div>
        <div v-if="errorMsg" class="invalid">
            {{ errorMsg }}
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { RoundingMode } from '@sports-utils/money';
    import { storeToRefs } from 'pinia';
    import { computed, ref, toRefs, watch } from 'vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import { useCustomerStore } from '@/store/customerStore';

    const isFocused = ref(false);

    const props = defineProps({
        errorMsg: {
            type: String,
            default: '',
        },
        modelValue: {
            type: Number,
            default: 0,
        },
        precision: {
            type: Number,
            default: 2,
        },
        maxIntegerLength: {
            type: Number,
            default: 9,
        },
    });

    const emit = defineEmits<{
        'update:modelValue': [value: number];
        resetInput: [];
    }>();

    const { errorMsg, modelValue, precision, maxIntegerLength } = toRefs(props);

    if (maxIntegerLength.value > 9) {
        throw new Error('maxIntegerLength should be less than or equal to 9');
    }

    const { sboCurrency, moneySeparators } = storeToRefs(useCustomerStore());
    const { formatMoneyWithDecimal, unformatMoney } = useCustomerStore();

    function clearInput() {
        emit('resetInput');
    }

    const isShowClose = computed(() => modelValue.value !== 0);

    const amountInput = ref<HTMLInputElement>();

    const formattedModelValue = ref('');

    function setCursor(el: HTMLInputElement, position: number): void {
        const setSelectionRange = () => {
            if (hasSelection(el)) {
                return;
            }
            el.setSelectionRange(position, position);
        };

        if (el === document.activeElement) {
            setSelectionRange();
            setTimeout(setSelectionRange, 1); // Android Fix
        }
    }

    function hasSelection(el: HTMLInputElement) {
        return el.selectionStart !== el.selectionEnd;
    }

    function setBackInput() {
        const input = amountInput.value;
        if (input) {
            const oldSelectionEnd = (input.selectionEnd || 1) - 1;
            input.value = formattedModelValue.value;
            setCursor(input, oldSelectionEnd);
        }
    }

    function onInput(event: Event) {
        let value = (event.target as HTMLInputElement).value;

        const { decimalSeparator, thousandSeparator } = moneySeparators.value;

        value = value
            .replace(/\\/g, '') // Prevent \
            .replace(/[^\d,\\.]/g, '') // Allow only digits, commas, and dots
            .replace(/,{2,}/g, ',') // Prevent multiple consecutive commas
            .replace(/\.{2,}/g, '.') // Prevent multiple consecutive dots
            .replace(/,\./g, ',') // Prevent the sequence ",." and keep the comma
            .replace(/,\./g, ','); // Prevent the sequence ".,", keep the dot

        if (precision.value === 0) {
            value = value.replace(new RegExp(`\\${decimalSeparator}`, 'g'), '');
        } else {
            const decimalIndex = value.indexOf(decimalSeparator);
            if (decimalIndex !== -1) {
                // Remove extra decimal separators
                value = value.slice(0, decimalIndex + 1) + value.slice(decimalIndex + 1).replace(new RegExp(`\\${decimalSeparator}`, 'g'), '');

                const beforeDecimal = value.slice(0, decimalIndex);
                let afterDecimal = value.slice(decimalIndex);

                // Remove any thousandSeparator after the decimalSeparator
                afterDecimal = afterDecimal.replace(new RegExp(`\\${thousandSeparator}`, 'g'), '');

                value = beforeDecimal + afterDecimal;
            }
        }

        const newValue = unformatMoney(value);

        const [integerPart, precisionPart] = String(newValue).split(decimalSeparator);
        if (integerPart.length > maxIntegerLength.value) {
            setBackInput();
            return;
        }
        if (precisionPart && (precisionPart.length > precision.value)) {
            setBackInput();
            return;
        }

        if (event.target) {
            (event.target as HTMLInputElement).value = value;
        }

        if (value === '') {
            emit('update:modelValue', 0);
        } else {
            emit('update:modelValue', newValue);
        }
    }

    watch([modelValue, amountInput], () => {
        if (amountInput.value) {
            if (modelValue.value === 0) {
                amountInput.value.value = '';
                return;
            }
            const oldSelectionEnd = amountInput.value.selectionEnd || 0;
            const oldValue = amountInput.value.value;

            const formatted = formatMoneyWithDecimal(modelValue.value, { minPrecision: 0, maxPrecision: precision.value, roundingMode: RoundingMode.Down });
            formattedModelValue.value = formatted;
            amountInput.value.value = formatted;

            const { thousandSeparator } = moneySeparators.value;
            const newSeparators = (amountInput.value.value.match(new RegExp(`\\${thousandSeparator}`, 'g')) || []).length;
            const oldSeparators = (oldValue.match(new RegExp(`\\${thousandSeparator}`, 'g')) || []).length;
            const separatorDiff = newSeparators - oldSeparators;

            const newPosition = oldSelectionEnd + separatorDiff;
            setCursor(amountInput.value, newPosition);
        }
    });
</script>

<style lang="scss" scoped>
@import "@/components/common/AmountInput";

</style>
