<template>
    <div class="notification" v-bind:class="type">
        <div class="notificationContent" v-bind:class="contextClass">
            <SvgIcon v-bind:name="icon" />
            <div class="notificationText">
                <slot />
            </div>
        </div>

        <div v-if="isShowCloseButton" class="notificationClose">
            <VButton flat block>
                <SvgIcon name="icon-system-close" />
            </VButton>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import { computed } from 'vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import VButton from '@/components/common/button/VButton.vue';
    import { SiteStyle } from '@/interface/enum';
    import { useCustomerStore } from '@/store/customerStore';

    const { siteStyle } = storeToRefs(useCustomerStore());
    if (siteStyle.value === SiteStyle.Euro) {
        import('@/components/common/Notification.Euro.scss');
    } else {
        import('@/components/common/Notification.scss');
    }

    const props = withDefaults(defineProps<{
        icon?: string;
        type?: 'success' | 'info' | 'warning' | 'error';
        isShowCloseButton?: boolean;
        iconAlign?: 'top' | 'bottom' | null;
    }>(), {
        icon: 'icon-system-infoCircle',
        type: 'info',
        isShowCloseButton: false,
        iconAlign: null,
    });

    const contextClass = computed(() => {
        if (props.iconAlign === 'top') {
            return 'iconUpper';
        }
        if (props.iconAlign === 'bottom') {
            return 'iconLower';
        }
        return '';
    });
</script>
