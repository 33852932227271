import { defineStore, storeToRefs } from 'pinia';
import { computed, watch } from 'vue';
import { useCashOutValues } from '@/components/cashOut/useCashOutValues';
import { formatBet } from '@/components/myBets/betHelper';
import { useDisplayCashOut } from '@/components/myBets/useDisplayCashOut';
import { useLocalStorage } from '@/composable/useLocalStorage';
import { useState } from '@/composable/useState';
import { Api } from '@/core/lib/api';
import { NonNull, removeDuplicates } from '@/core/lib/array';
import { i18n } from '@/core/lib/i18n';
import { useEventLiveScoreInfo } from '@/core/oddsApi/composable/useEventLiveScoreInfo';
import { formatLiveScore, getLiveTimeDisplay, getScore, isShowLiveScore } from '@/core/oddsApi/helpers/liveScore';
import type { IBaseBet, IBet, ISubBet } from '@/interface/IBet';
import { MainBetStatus, SiteStyle } from '@/interface/enum';
import { useCustomerStore } from '@/store/customerStore';
import { useToggleStore } from '@/store/toggleStore';

export const useMiniMyBetsStore = defineStore('MiniMyBets', () => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [rawMyBets, setRawMyBets] = useState<IBet[]>([]);
    const [hiddenBetTransIds, SetHiddenBetTransIds] = useLocalStorage<number[]>('hiddenBetTransIds', []);

    const refreshMyBets = async (useCache: boolean) => {
        try {
            const { data } = await Api.getMiniMyBets(useCache);
            const myBets = data.map(formatBet);
            setRawMyBets(myBets);
            setIsLoaded(true);
        } catch (error) {
            console.error(error);
        }
    };
    const refreshMyBetsThrottled = throttlePromise(refreshMyBets, 5000);
    refreshMyBets(true);

    const updateHiddenBetTransIds = (transId: number) => {
        SetHiddenBetTransIds([...hiddenBetTransIds.value, transId]);
    };

    const { miniMyBetMaxDisplayCount: displayCount } = storeToRefs(useToggleStore(SiteStyle.Asia));

    const displayMiniMyBets = computed(() => {
        const filteredBets = rawMyBets.value.filter(bet => !hiddenBetTransIds.value.includes(bet.transactionId));
        return filteredBets.slice(0, displayCount.value);
    });

    const needToGetLiveScoreBets = computed(() => {
        const excludedWaitingRejectedBets = displayMiniMyBets.value.filter(bet => !bet.isWaitingReject);
        const singleBets = excludedWaitingRejectedBets.filter(bet => !bet.isMixParlay && !bet.isOutright);
        const subBets = excludedWaitingRejectedBets.filter(bet => bet.isMixParlay).flatMap(bet => bet.subBets);
        return [...singleBets, ...subBets]
            .filter(NonNull)
            .filter(bet => isShowLiveScore(bet.sportType, bet.isLive, bet.marketType));
    });

    const needToGetLiveScoreEventIds = computed(() => removeDuplicates(needToGetLiveScoreBets.value.map(bet => bet.eventId)));
    const needToGetLiveScoreEventResultIds = computed(() => removeDuplicates(needToGetLiveScoreBets.value.map(bet => bet.eventResultId)));
    const { eventLiveScoreInfo } = useEventLiveScoreInfo(needToGetLiveScoreEventIds, needToGetLiveScoreEventResultIds);
    const { siteStyle } = storeToRefs(useCustomerStore());

    const liveTime = (bet: IBaseBet) => {
        if (bet.isWaitingReject) return '';

        const scoreInfo = eventLiveScoreInfo.value.find(event => event.eventId === bet.eventId);
        if (!scoreInfo) return '';

        return getLiveTimeDisplay(scoreInfo.period, scoreInfo.periodStartTime, siteStyle.value);
    };

    const liveScore = (bet: IBaseBet) => {
        if (bet.isWaitingReject) return '';

        const eventResults = eventLiveScoreInfo.value.find(event => event.eventId === bet.eventId)?.eventResultsScoreInfo;
        if (!eventResults) return '';
        const score = getScore(eventResults, bet.betTypeGroup.id);
        return score ? formatLiveScore(score.home, score.away, ':') : '';
    };

    const runningBetList = computed(() => displayMiniMyBets.value.filter(bet => bet.mainBetStatus === MainBetStatus.Running));

    const { cashOutServiceList, refreshCashOutValues: _refreshCashOutValues } = useCashOutValues(runningBetList);

    const miniMyBets = computed(() => displayMiniMyBets.value.map<IBet>((bet) => {
        const cashOutService = cashOutServiceList.value.find(x => x.transactionId === bet.transactionId);
        return ({
            ...bet,
            cashOutService,
            liveTime: liveTime(bet),
            liveScore: liveScore(bet),
            subBets: bet.subBets.map<ISubBet>(subBet => ({
                ...subBet,
                liveTime: liveTime(subBet),
                liveScore: liveScore(subBet),
            })),
        });
    }));

    const { isDisplayCashOut } = useDisplayCashOut();
    const isCashOutRefreshEnabled = computed(() => isDisplayCashOut.value);
    const refreshCashOutValues = async () => {
        if (!isCashOutRefreshEnabled.value) return;
        await _refreshCashOutValues();
    };
    watch(rawMyBets, (newMyBets) => {
        const filteredHiddenBetTransIds = newMyBets
            .filter(bet => hiddenBetTransIds.value.includes(bet.transactionId))
            .filter(bet => bet.mainBetStatus === MainBetStatus.WaitingRejected)
            .map(bet => bet.transactionId);
        SetHiddenBetTransIds(filteredHiddenBetTransIds);
    });

    watch(i18n.locale, () => {
        if (rawMyBets.value.length > 0) {
            refreshMyBets(true);
            refreshCashOutValues();
        }
    });

    return {
        isLoaded,
        miniMyBets,
        rawMyBets,
        refreshMyBets,
        refreshMyBetsThrottled,
        updateHiddenBetTransIds,
        refreshCashOutValues,
        isCashOutRefreshEnabled,
    };
});

function throttlePromise<T extends(...args: any) => Promise<void>>(fn: T, wait: number): (...args: Parameters<T>) => Promise<void> {
    let lastRunTime = 0;

    return (...args: Parameters<T>[]) => new Promise<void>((resolve) => {
        const now = new Date().getTime();
        if (now - lastRunTime < wait) {
            resolve();
        } else {
            lastRunTime = now;
            resolve(fn(...args));
        }
    });
}
