import { storeToRefs } from 'pinia';
import type { useBaseBetSlipContext } from '@/components/betSlip/betSlipContext/BaseBetSlipContext';
import { usePlatform } from '@/composable/usePlatform';
import { i18n } from '@/core/lib/i18n';
import { getErrorMessage, getPlaceBetErrorMessage } from '@/core/lib/ticket';
import { PlaceOrderStatus, PlayerType } from '@/interface/enum';
import { useCustomerStore } from '@/store/customerStore';

export type PlaceBetErrorRules = Exclude<PlaceOrderStatus, PlaceOrderStatus.Success
| PlaceOrderStatus.LiveSuccess
| PlaceOrderStatus.EventCheckSuccess
| PlaceOrderStatus.CreditCheckSuccess
| PlaceOrderStatus.BlackListCheckSuccess
| PlaceOrderStatus.StakeProblem
| PlaceOrderStatus.InvalidCookieIdentity
| PlaceOrderStatus.MinBetUnMet
| PlaceOrderStatus.InvalidPriceStyle
| PlaceOrderStatus.IsNotSingleBet
| PlaceOrderStatus.DeductBalanceFail
| PlaceOrderStatus.InvalidHttpReferer
| PlaceOrderStatus.InvalidPriceStyleForVoucher
| PlaceOrderStatus.InvalidAccountForVoucher
| PlaceOrderStatus.PlayerNotLogin
| PlaceOrderStatus.InvalidPriceId
| PlaceOrderStatus.FailToFulfillBetCountRestrictionForSplitPoint
| PlaceOrderStatus.MPSubBetCountExceed >;

export type PlaceParlayBetErrorRules = PlaceBetErrorRules
| PlaceOrderStatus.PriceChange
| PlaceOrderStatus.HdpPointChange
| PlaceOrderStatus.ScoreChanged
| PlaceOrderStatus.PriceSuspend
| PlaceOrderStatus.InvalidPrice
| PlaceOrderStatus.EventClosed
| PlaceOrderStatus.InvalidRefNo
| PlaceOrderStatus.ExistBetOnSameEvent
| PlaceOrderStatus.LessEvent
| PlaceOrderStatus.MPSubBetCountExceed
| PlaceOrderStatus.MPMaxPayoutExceed
| PlaceOrderStatus.MPMaxAccumulatedStakeForSubEventExceed
| PlaceOrderStatus.NotValidMixParlayCombination;

export type PlaceSingleBetErrorRules = PlaceBetErrorRules
| PlaceOrderStatus.PriceChange
| PlaceOrderStatus.HdpPointChange
| PlaceOrderStatus.ScoreChanged
| PlaceOrderStatus.PriceSuspend
| PlaceOrderStatus.InvalidPrice
| PlaceOrderStatus.EventClosed
| PlaceOrderStatus.IOMWalkinOnly
| PlaceOrderStatus.LimitExceedTryLater
| PlaceOrderStatus.ThirdPartyTimeout
| PlaceOrderStatus.InvalidVoucher;

export type PlaceBetBuilderBetErrorRules = PlaceBetErrorRules
| PlaceOrderStatus.PriceChange
| PlaceOrderStatus.ScoreChanged
| PlaceOrderStatus.PriceSuspend
| PlaceOrderStatus.InvalidPrice
| PlaceOrderStatus.EventClosed
| PlaceOrderStatus.LimitExceedTryLater
| PlaceOrderStatus.ThirdPartyTimeout
| PlaceOrderStatus.BetBuilderMaxPayoutExceed
| PlaceOrderStatus.BetBuilderMaxAccumulatedStakeExceed
| PlaceOrderStatus.LessSelection

export type PlaceBetErrorHandler = (placeOrderStatus: PlaceOrderStatus, extraInfo: string, leagueId?: number) => void

export function usePlaceBetErrorHandler(betSlipContext: ReturnType<typeof useBaseBetSlipContext>) {
    const { setBetSlipMessage } = betSlipContext;

    const errorMessageHandler: PlaceBetErrorHandler = (placeOrderStatus: PlaceOrderStatus, _extraInfo: string) => {
        setBetSlipMessage(getPlaceBetErrorMessage(placeOrderStatus));
    };

    const defaultErrorHandler: PlaceBetErrorHandler = (_placeOrderStatus: PlaceOrderStatus, extraInfo: string) => {
        errorMessageHandler(PlaceOrderStatus.GeneralFailure, extraInfo);
    };

    const { logout } = usePlatform();
    const customerClosedHandler: PlaceBetErrorHandler = (_placeOrderStatus: PlaceOrderStatus, _extraInfo: string) => {
        logout();
    };

    const maxWinLoseLimitExceedHandler: PlaceBetErrorHandler = (_placeOrderStatus: PlaceOrderStatus, _extraInfo: string) => {
        const { playerType } = storeToRefs(useCustomerStore());
        if (playerType.value === PlayerType.B2B) setBetSlipMessage(i18n.t('bet_slip_error_message_max_win_loss_limit_contact_upper_line'));
        else setBetSlipMessage(i18n.t('bet_slip_error_message_max_win_loss_limit_contact_cs'));
    };

    const replaceBrandNameErrorMessageHandler: PlaceBetErrorHandler = (_placeOrderStatus: PlaceOrderStatus, _extraInfo: string) => {
        const { brandName } = storeToRefs(useCustomerStore());
        const errorMessage = i18n.t(getErrorMessage(_placeOrderStatus), { brandName: brandName.value });
        setBetSlipMessage(errorMessage);
    };

    const placeBetErrorRuleMap: Partial<Record<PlaceBetErrorRules, PlaceBetErrorHandler>> = {
        [PlaceOrderStatus.HierarchyClosed]: customerClosedHandler,
        [PlaceOrderStatus.MemberClosed]: customerClosedHandler,
        [PlaceOrderStatus.MemberSuspend]: replaceBrandNameErrorMessageHandler,
        [PlaceOrderStatus.HierarchySuspend]: replaceBrandNameErrorMessageHandler,
        [PlaceOrderStatus.AccountProblem]: replaceBrandNameErrorMessageHandler,
        [PlaceOrderStatus.InsufficientCredit]: errorMessageHandler,
        [PlaceOrderStatus.MaxBetExceed]: errorMessageHandler,
        [PlaceOrderStatus.MaxPerMatchExceed]: errorMessageHandler,
        [PlaceOrderStatus.SportExcluded]: errorMessageHandler,
        [PlaceOrderStatus.BettingBudgetExceed]: errorMessageHandler,
        [PlaceOrderStatus.InvalidIp]: errorMessageHandler,
        [PlaceOrderStatus.CountryIsBlock]: errorMessageHandler,
        [PlaceOrderStatus.PhoneBetting]: errorMessageHandler,
        [PlaceOrderStatus.MaxWinLoseLimitExceed]: maxWinLoseLimitExceedHandler,
        [PlaceOrderStatus.GeneralFailure]: errorMessageHandler,
        [PlaceOrderStatus.InBlackList]: errorMessageHandler,
        [PlaceOrderStatus.PTProblem]: errorMessageHandler,
        [PlaceOrderStatus.InvalidCurrencyForVietnam]: errorMessageHandler,
    };

    return {
        placeBetErrorRuleMap,
        defaultErrorHandler,
        errorMessageHandler,
        replaceBrandNameErrorMessageHandler,
    };
}
