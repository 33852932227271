<template>
    <NavbarBlock />

    <TwoSubBetParlayBanner v-if="isMixParlay && isMpTwoSubBetEnabled" />

    <MaxPayout v-if="isShowMpMaxPayout" />

    <component
        v-bind:is="oddsDisplayComponent"
        v-if="oddsDisplayComponent"
        v-bind:key="`${promotionZone}_${sportType}_${marketPage}_${eventDate}_${oddsDisplayMode}`"
    />
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import { computed } from 'vue';
    import TwoSubBetParlayBanner from '@/components/event/TwoSubBetParlayBanner.vue';
    import MaxPayout from '@/components/maxPayout/MaxPayout.vue';
    import NavbarBlock from '@/components/navbarBlock/NavbarBlock.Asia.vue';
    import OddsDisplayBlock from '@/components/oddsDisplay/OddsDisplayBlock.vue';
    import OutrightOddsDisplayBlock from '@/components/oddsDisplay/outright/OutrightOddsDisplayBlock.vue';
    import { useBackToClassicDesktop } from '@/composable/useBackToClassicDesktop';
    import { useMyBets } from '@/composable/useMyBets';
    import { usePlatform } from '@/composable/usePlatform';
    import { useTitle } from '@/composable/useTitle';
    import { MarketPage, PageType } from '@/interface/enum';
    import { useAsiaSportMarketInfoStore } from '@/store/asiaSportMarketInfoStore';
    import { useOddsDisplaySettingsStore } from '@/store/oddsDisplaySettingsStore';
    import { useToggleStore } from '@/store/toggleStore';

    const { oddsDisplayMode } = storeToRefs(useOddsDisplaySettingsStore());
    const { promotionZone, sportType, marketPage, eventDate, isMixParlay } = storeToRefs(useAsiaSportMarketInfoStore());
    const oddsDisplayComponent = computed(() => {
        const doubleLineMarketPages = [
            MarketPage.Live,
            MarketPage.Today,
            MarketPage.EarlyMarket,
            MarketPage.MixParlay,
            MarketPage.MyFavorites,
            MarketPage.All,
        ];
        if (doubleLineMarketPages.includes(marketPage.value)) {
            return OddsDisplayBlock;
        }
        if (marketPage.value === MarketPage.Outright) {
            return OutrightOddsDisplayBlock;
        }
        return null;
    });
    const isShowMpMaxPayout = computed(() => marketPage.value === MarketPage.MixParlay);

    useTitle(PageType.Home);

    const { onOpenMyBets, safariBackPageCallBack, onBackToClassicDesktop } = usePlatform();
    safariBackPageCallBack();

    const { openMyBets } = useMyBets();
    onOpenMyBets(openMyBets);
    const { backToClassicDesktop } = useBackToClassicDesktop();
    onBackToClassicDesktop(backToClassicDesktop);

    const { isMpTwoSubBetEnabled } = storeToRefs(useToggleStore());
</script>
