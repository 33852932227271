<template>
    <ResultsBlock />
</template>

<script lang="ts" setup>
    import ResultsBlock from '@/components/results/ResultsBlock.vue';
    import { useTitle } from '@/composable/useTitle';
    import { PageType } from '@/interface/enum';

    useTitle(PageType.Results);

    document.body.classList.add('sports-background');
</script>
