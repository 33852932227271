import { storeToRefs } from 'pinia';
import { useInterval } from '@/composable/useInterval';
import { useLocalStorage } from '@/composable/useLocalStorage';
import { useSendBehaviorToggle } from '@/composable/useSendBehaviorToggle';
import { useState } from '@/composable/useState';
import { Api } from '@/core/lib/api';
import { BehaviorType } from '@/interface/enum';
import type { SendBehaviorsRequest } from '@/interface/pollux';
import { useCustomerStore } from '@/store/customerStore';

interface IBehaviorStorage {
    count: number;
    calculatedSeconds: number;
}

export function useEventsQueryBehavior() {
    const [count, setCount] = useState(0);

    function resetCount() {
        setCount(0);
    }

    const { durationSeconds, isSendBehaviorEnabled } = useSendBehaviorToggle(BehaviorType.EventsQuery);
    function addCount() {
        if (!isSendBehaviorEnabled.value) return;

        count.value++;
    }

    const { accountId } = storeToRefs(useCustomerStore());
    const [behaviorStorage, setBehaviorStorage] = useLocalStorage<IBehaviorStorage | null>(
        `b:${BehaviorType.EventsQuery}:${accountId.value}`,
        null,
        {
            decode: v => JSON.parse(v),
            encode: v => JSON.stringify(v),
        },
    );

    const [remainSeconds, setRemainSeconds] = useState(durationSeconds.value);

    if (behaviorStorage.value) {
        if (durationSeconds.value > behaviorStorage.value.calculatedSeconds) {
            setRemainSeconds(durationSeconds.value - behaviorStorage.value.calculatedSeconds);
            setCount(behaviorStorage.value.count);
        }
    }

    function resetRemainSeconds() {
        setRemainSeconds(durationSeconds.value);
    }

    async function sendBehavior() {
        if (count.value === 0) return;

        const request: SendBehaviorsRequest = {
            behaviors: [{
                behaviorType: BehaviorType.toPollux(BehaviorType.EventsQuery),
                count: count.value,
                durationMinutes: Math.ceil(durationSeconds.value / 60),
            }],
        };

        resetCount();
        resetRemainSeconds();
        await Api.sendBehaviors(request);
    }

    const { countdown } = useInterval(
        () => sendBehavior(),
        remainSeconds,
        { isEnabled: isSendBehaviorEnabled },
    );

    window.addEventListener('beforeunload', () => {
        setBehaviorStorage({
            count: count.value,
            calculatedSeconds: durationSeconds.value - countdown.value,
        });
    });

    return {
        addCount,
    };
}
