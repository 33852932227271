import { computed, ref } from 'vue';
import {
    EnumEventDateFilterType,
    EnumPresetFilterType,
    EnumTimeZoneType,
    useSportMarketInfoQuery,
} from '@/core/oddsApi/oddsApiType';
import { SportType } from '@/interface/enum';
import { SportInfo } from '@/models/SportInfo';

export function useSportMarketInfo(isEnabled = ref(true)) {
    const variables = computed(() => ({
        query: {
            timeZone: EnumTimeZoneType.UTC__4,
        },
    }));

    const { result, loaded, loading, refetch } = useSportMarketInfoQuery(
        variables,
        computed(() => ({
            pollInterval: 30000,
            enabled: isEnabled.value,
        })),
    );

    const sportList = computed(() => {
        if (!result.value) return [];

        const list = new Map<SportType, SportInfo>();

        result.value.forEach(({ sport, queryFilter, liveEventCount, nonLiveEventCount }) => {
            const sportType = SportType.fromOddsApi(sport);
            if (!sportType) return;

            if (!list.has(sportType)) {
                list.set(sportType, new SportInfo({ sportType }));
            }

            const currentSportInfo = list.get(sportType)!;
            if (queryFilter.date === EnumEventDateFilterType.All) {
                switch (queryFilter.presetFilter) {
                    case EnumPresetFilterType.Live:
                        currentSportInfo.liveEventCount = Math.max(0, liveEventCount);
                        break;
                    case EnumPresetFilterType.NonLive:
                        currentSportInfo.nonLiveEventCount = Math.max(0, nonLiveEventCount);
                        break;
                    case EnumPresetFilterType.Outright:
                        currentSportInfo.outrightLiveEventCount = Math.max(0, liveEventCount);
                        currentSportInfo.outrightNonLiveEventCount = Math.max(0, nonLiveEventCount);
                        break;
                    default:
                }
            } else if (queryFilter.date === EnumEventDateFilterType.TodayExpanded) {
                currentSportInfo.todayExpandedCount = Math.max(0, nonLiveEventCount);
            }
        });
        return [...list.values()];
    });

    const mixParlaySportList = computed(() => {
        if (!result.value) return [];

        return result.value
            .filter(x => x.queryFilter.presetFilter === EnumPresetFilterType.MixParlay)
            .filter(x => x.queryFilter.date === EnumEventDateFilterType.All)
            .map(({ sport, liveEventCount, nonLiveEventCount }) => {
                const sportType = SportType.fromOddsApi(sport);
                return new SportInfo({
                    sportType,
                    liveEventCount: Math.max(0, liveEventCount),
                    nonLiveEventCount: Math.max(0, nonLiveEventCount),
                });
            });
    });

    return {
        loaded,
        loading,
        refetch,
        sportList,
        mixParlaySportList,
        sportMarketData: result,
    };
}
