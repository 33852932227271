import {
    addDays,
    addHours,
    differenceInDays,
    differenceInHours,
    format,
    parse,
    parseISO,
    startOfToday,
    subDays,
    subMonths,
} from 'date-fns';
import {
    bn, enUS, id, ja, km, ko, ptBR, th, vi, zhCN,
} from 'date-fns/locale';
import { i18n } from '@/core/lib/i18n';
import { EnumLanguage, ShowTimeDisplayType } from '@/interface/enum';

const isDate = (date: unknown) => date && (date instanceof Date);
const localeMapping: Record<EnumLanguage, Locale> = {
    [EnumLanguage.EN]: enUS,
    [EnumLanguage.TH_TH]: th,
    [EnumLanguage.VI_VN]: vi,
    [EnumLanguage.ZH_CN]: zhCN,
    [EnumLanguage.ID_ID]: id,
    [EnumLanguage.JA_JP]: ja,
    [EnumLanguage.KO_KR]: ko,
    [EnumLanguage.MY_MM]: enUS,
    [EnumLanguage.PT_BR]: ptBR,
    [EnumLanguage.KM_KH]: km,
    [EnumLanguage.BN_BD]: bn,
};

/**
 * @param   {String} dateString the string to parse
 * @param   {String} dateFormat the format of date string
 * @param   {Number} fromTimeZone the timeZone of date String
 * @returns {Date} the parsed date
 */
export function parseDate(dateString: string, dateFormat: string, fromTimeZone: number | null = null): Date {
    const date = parse(dateString, dateFormat, new Date());
    if (fromTimeZone !== null) {
        const currentTimeZone = -Math.round(date.getTimezoneOffset() / 60);
        return new Date(date.getTime() + (currentTimeZone - fromTimeZone) * 60 * 60 * 1000);
    }
    return date;
}

/**
* @param   {String} ISOString ISO format string. Ex: '2014-02-11T11:30:30'
* @returns {Date} the parsed date
*/
export function parseISOString(ISOString: string): Date {
    return parseISO(ISOString);
}

/**
* @param   {Date} date the Date object
* @param   {String} toFormat the target format of date
* @returns {String} the formatted date string
*/
export function formatDate(date: Date, toFormat: string): string {
    if (!isDate(date)) {
        throw new TypeError('Invalid date type, must be Date object');
    }
    return format(date, toFormat);
}

/**
 * @param   {Date} date the Date object
 * @param   {String} toFormat the target format of date
 * @returns {String} the formatted date string with related locale
 */
export function formatDateWithLocale(date: Date, toFormat: string): string {
    if (!isDate(date)) {
        throw new TypeError('Invalid date type, must be Date object');
    }

    return format(date, toFormat, { locale: localeMapping[i18n.locale.value] });
}

export function formatShowTime(showTime: Date, showTimeType: number): string {
    const shouldDisplayTime = [
        ShowTimeDisplayType.BlueLive,
        ShowTimeDisplayType.RedLive,
        ShowTimeDisplayType.DateTime,
    ];
    if (shouldDisplayTime.find(x => x & showTimeType)) {
        return formatDate(showTime, 'HH:mm');
    }

    if (showTimeType & ShowTimeDisplayType.Date) {
        return '**:**';
    }

    return '';
}

export function formatToServerTime(date: Date, toFormat: string): string {
    return formatDate(getTimeWithGMT(date, -4), toFormat);
}

export function formatToSingaporeTime(date: Date, toFormat: string): string {
    return formatDate(getTimeWithGMT(date, 8), toFormat);
}

/**
* @param   {String} dateString the Date object
* @param   {String} fromFormat the format of date string
* @param   {String} toFormat the target format of date
* @returns {String} the formatted date string
*/
export function formatDateString(dateString: string, fromFormat: string, toFormat: string): string {
    const date = parseDate(dateString, fromFormat);
    return formatDate(date, toFormat);
}

export function getWeekday(day: number) {
    const today = getNowWithGMT(-4);
    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return weekdays[addDay(today, day).getDay()];
}

export function addDay(date: Date, days: number) {
    return addDays(date, days);
}

export function addHour(date: Date, hours: number) {
    return addHours(date, hours);
}

export function subDay(date: Date, days: number) {
    return subDays(date, days);
}

export function subMonth(date: Date, months: number) {
    return subMonths(date, months);
}

/**
* @param   {Date} date the Date object
* @param   {Number} gmtOffset GMT timeZone hour offset(Ex: GMT+8 will be +8, GMT-2 will be -2)
* @returns {Date} the dateTime with specific offset
*/
export function getTimeWithGMT(date: Date, gmtOffset: number): Date {
    if (!isDate(date)) {
        throw new TypeError('Invalid date type, must be Date object');
    }
    // getTimezoneOffset will return minutes
    const timezoneOffset = date.getTimezoneOffset() / 60;
    return new Date(date.getTime() + (timezoneOffset + gmtOffset) * 60 * 60 * 1000);
}

/**
* @param   {Number} gmtOffset GMT timeZone hour offset(Ex: GMT+8 will be +8, GMT-2 will be -2)
* @returns {Date} the dateTime with specific offset
*/
export function getNowWithGMT(gmtOffset: number): Date {
    const now = new Date();
    return getTimeWithGMT(now, gmtOffset);
}

/**
* @param   {Number} gmtOffset GMT timeZone hour offset(Ex: GMT+8 will be +8, GMT-2 will be -2)
* @returns {Date} the dateTime with specific offset without the time component
*/
export function getTodayWithGMT(gmtOffset: number): Date {
    const result = getNowWithGMT(gmtOffset);
    result.setHours(0, 0, 0, 0);
    return result;
}

export function getTodayWithLocalTime(): Date {
    return startOfToday();
}

/**
* @param   {Date} date1
* @param   {Date} date2
* @returns {boolean} are two dates equal
*/
export function areDatesEqual(date1: Date, date2: Date): boolean {
    return date1.getTime() === date2.getTime();
}

/**
* @param   {Date} date1
* @param   {Date} date2
* @returns {number} days different between two date (date2 - date1)
*/
export function getDaysDiff(date1: Date, date2: Date) {
    return differenceInDays(date1, date2);
}

/**
* @param   {Date} date1
* @param   {Date} date2
* @returns {number} hours different between two date (date2 - date1)
*/
export function getHoursDiff(date1: Date, date2: Date) {
    return differenceInHours(date1, date2);
}
