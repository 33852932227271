<template>
    <div class="placeBetBlock notranslate">
        <CheckBox
            id="acceptAnyOdds"
            v-bind:gtmLabel="isAcceptAnyOdds ? 'AcceptAnyOdds_UnCheck' : 'AcceptAnyOdds_Check'"
            v-bind:checked="isAcceptAnyOdds"
            v-bind:onclick="toggleIsAcceptAnyOdds"
        >
            <template #label>
                <span class="labelText">
                    {{ i18n.t('bet_slip_accept_any_odds') }}
                </span>
            </template>
        </CheckBox>
        <CheckBox
            id="rememberLastStake"
            v-bind:gtmLabel="isShowLastStake ? 'LastStake_Off' : 'LastStake_On'"
            v-bind:checked="isShowLastStake"
            v-bind:onclick="handleRememberLastStake"
        >
            <template #label>
                <span class="labelText">
                    {{ i18n.t('bet_slip_remember_last_stake') }}
                </span>
            </template>
        </CheckBox>
        <div
            v-if="isVoucherAvailable"
            class="form"
        >
            <CheckBox
                id="redeemVoucher"
                v-bind:gtmLabel="voucherEnabled ? 'Voucher_UnCheck' : 'Voucher_Check'"
                v-bind:model="voucherEnabled"
                v-bind:disabled="!isVoucherValidForTicket"
                v-bind:onclick="resetSelectedVoucher"
            >
                <template #label>
                    <span class="labelText">
                        {{ i18n.t('redeem_voucher') }}
                        <span>
                            ({{ vouchers.length }})
                        </span>
                    </span>
                </template>
            </CheckBox>
            <VTooltip>
                <SvgIcon
                    right
                    class="header_icon"
                    name="icon-system-cycle-info"
                />
                <template #content>
                    <div
                        v-gtm:type="'BetSlip'"
                        v-gtm:label="'Voucher_Tooltip'"
                        v-gtm:@view
                    >
                        {{ i18n.t('voucher_tooltip_1') }}
                        {{ i18n.t('voucher_tooltip_2') }}
                    </div>
                </template>
            </VTooltip>
        </div>
        <div v-if="voucherEnabled" class="form form-select">
            <select
                ref="voucherSelectorElement"
                class="select"
                v-bind:value="selectedVoucher?.id"
                v-on:change="(e: Event) => changeSelectedVoucher((e.target as HTMLSelectElement).value)"
            >
                <option
                    selected
                    value=""
                    disabled
                >
                    {{ i18n.t('select_voucher') }}
                </option>

                <option
                    v-for="voucher in displayVouchers"
                    v-bind:key="voucher.id"
                    v-bind:value="voucher.id"
                >
                    {{ voucher.text }}
                </option>
            </select>
        </div>
        <div v-else class="form form-input stakeGroup">
            <input
                ref="stakeInputElement"
                v-money="formatStakeConfig"
                v-memo="[stakeDisplay, isBalanceLessThanMinBet, () => i18n.locale]"
                v-bind:value="stakeDisplay"
                class="input input-stake"
                v-bind:class="{ 'stakeGroup_input-hasSuffix': hasMultiplier }"
                type="text"
                v-bind:disabled="isBalanceLessThanMinBet"
                v-bind:placeholder="i18n.t('stake')"
                v-bind:maxlength="stakeInputMaxLength"
                v-on:change="(e: Event) => setDisplayToStake((e.target as HTMLInputElement).value)"
                v-on:keyup.enter="placeBet()"
            >
            <div v-if="hasMultiplier" class="stakeGroup_suffix">
                ,000
            </div>
        </div>
        <Message
            v-bind:show="!!stakeMessage"
            rounded
            noIcon
            status="danger"
            position="bottom"
            v-bind:message="stakeMessage"
        />
        <div class="placeBetBlock_detail">
            <ul class="detailText">
                <li v-if="isMixParlayBetSlip || isBetBuilderBetSlip" class="detailText_item">
                    <span>
                        {{ i18n.t('total_odds') }}
                    </span>
                    <div>
                        <span class="optionOdds-at">@</span>
                        <strong class="optionOddsPrice">{{ totalOddsPriceDisplay }}</strong>
                    </div>
                </li>
                <li class="detailText_item">
                    <span>
                        {{ i18n.t('bet_slip_min') }}
                    </span>
                    <span>
                        {{ minBetDisplay }}
                    </span>
                </li>
                <li class="detailText_item">
                    <span>
                        {{ i18n.t('bet_slip_max') }}
                    </span>
                    <span>
                        {{ maxBetDisplay }}
                    </span>
                </li>
                <li class="detailText_item">
                    <span>
                        {{ i18n.t('bet_slip_max_payout') }}
                    </span>
                    <span>
                        {{ maxPayout }}
                    </span>
                </li>
            </ul>
        </div>
    </div>
    <VButton
        v-if="isShowDepositButton"
        v-gtm:label="'Deposit'"
        v-gtm:@click
        v-gtm:@view
        block
        color="accent"
        textColor="primary"
        class="notranslate"
        v-on:click="openDepositPage"
    >
        {{ i18n.t('bet_slip_deposit') }}
    </VButton>
    <VButton
        v-else
        v-gtm:label="'PlaceBet'"
        v-gtm:@click
        v-gtm:@view
        block
        color="accent"
        textColor="primary"
        v-bind:disabled="!isPlaceBetEnabled"
        class="notranslate"
        v-on:click="placeBet"
    >
        {{ buttonText }}
    </VButton>
</template>

<script lang="ts" setup>
    import orderBy from 'lodash/orderBy';
    import { storeToRefs } from 'pinia';
    import type { Ref } from 'vue';
    import { computed, nextTick, ref, watch } from 'vue';
    import Message from '@/components/betSlip/Message.vue';
    import { BetSlipContext } from '@/components/betSlip/betSlipContext';
    import CheckBox from '@/components/common/CheckBox.vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import VButton from '@/components/common/button/VButton.vue';
    import { useBetBuilderInvalidMessageDisplay } from '@/composable/useBetBuilderInvalidMessageDisplay';
    import { MIN_SUB_BET_COUNT } from '@/composable/useMpInvalidMessageDisplay';
    import { usePlatform } from '@/composable/usePlatform';
    import { useStakeDisplay } from '@/composable/useStakeDisplay';
    import { i18n } from '@/core/lib/i18n';
    import { formatMinDecimalPlace } from '@/core/lib/numberFormat';
    import { isMobile } from '@/core/lib/utils';
    import { getVoucherExpireText } from '@/core/lib/voucherHelper';
    import { VTooltip } from '@/directives/tooltip';
    import { BetSlipType } from '@/interface/IBetSlip';
    import type { IBetBuilderTicket } from '@/interface/betBuilder';
    import { PlayerType, StakeValidStatus } from '@/interface/enum';
    import { useBetSlipStore } from '@/store/betSlipStore';
    import { useCustomerStore } from '@/store/customerStore';

    const stakeInputElement = ref<HTMLInputElement>();
    const voucherSelectorElement = ref<HTMLSelectElement>();
    const { isAcceptAnyOdds } = storeToRefs(useCustomerStore());
    const { updateIsAcceptAnyOdds } = useCustomerStore();
    const { isShowLastStake, singleLastStake, parlayLastStake } = storeToRefs(useBetSlipStore());
    const { setIsShowLastStake } = useBetSlipStore();

    const {
        minBetDisplay,
        maxBetDisplay,
        maxPayout,
        stakeMessage,
        setStakeMessage,
        placeBet: _placeBet,
        checkIsValidStake,
        checkIsValidVoucher,
        isPlaceBetEnabled,
        voucherEnabled,
        setVoucherEnabled,
        isVoucherAvailable,
        isVoucherValidForTicket,
        selectedVoucher,
        setSelectedVoucher,
        vouchers,
        stakeInput,
        isBalanceLessThanMinBet,
        betSlip,
        totalOddsPrice,
    } = BetSlipContext.inject();

    const {
        stakeDisplay,
        hasMultiplier,
        setDisplayToStake,
        formatStakeConfig,
        setStake,
        stakeInputMaxLength,
    } = stakeInput;

    const { sboCurrency, playerType } = storeToRefs(useCustomerStore());

    const isMixParlayBetSlip = computed(() => betSlip.value.type === BetSlipType.Parlay);
    const isBetBuilderBetSlip = computed(() => betSlip.value.type === BetSlipType.BetBuilder);

    const totalOddsPriceDisplay = computed(() => formatMinDecimalPlace(totalOddsPrice.value, 2));

    const resetSelectedVoucher = (checked: boolean) => {
        setVoucherEnabled(checked);
        setSelectedVoucher(null);
    };

    const toggleIsAcceptAnyOdds = (async (checked: boolean) => {
        try {
            await updateIsAcceptAnyOdds(checked);
        } catch (error) {
            setStakeMessage(i18n.t('bet_slip_error_message_general_failure'));
        }
    });

    watch(betSlip, (newBetSlip, oldBetSlip) => {
        if (newBetSlip.uid !== oldBetSlip.uid) {
            if (!isMobile()) {
                nextTick(() => {
                    stakeInputElement.value?.focus();
                    stakeInputElement.value?.select();
                });
            }
        }
    });

    const displayVouchers = computed(() => orderBy(vouchers.value.map(voucher => ({
        ...voucher,
        text: `${sboCurrency.value} ${useStakeDisplay(voucher.stake).stakeDisplay} (${getVoucherExpireText(voucher.expiryDate)})`,
    })), [x => x.expiryDate, x => x.stake]));

    const changeSelectedVoucher = (id: string) => {
        const voucher = vouchers.value.find(voucher => voucher.id === id);
        setSelectedVoucher(voucher ?? null);
    };

    const validStake = () => {
        const result = checkIsValidStake();
        if (result.status !== StakeValidStatus.Valid) {
            if (!isMobile()) {
                nextTick(() => {
                    stakeInputElement.value?.focus();
                    stakeInputElement.value?.select();
                });
            }
            setStakeMessage(result.stakeMessage);
            setStake(result.adjustStake);
            return false;
        }
        return true;
    };

    const validVoucher = () => {
        const result = checkIsValidVoucher();
        if (result.status !== StakeValidStatus.Valid) {
            if (!isMobile()) {
                nextTick(() => {
                    voucherSelectorElement.value?.focus();
                });
            }
            setStakeMessage(result.stakeMessage);
            return false;
        }
        return true;
    };

    const placeBet = async () => {
        if (!isPlaceBetEnabled.value) return;

        const isValidFunc = selectedVoucher.value === null ? validStake : validVoucher;
        if (!isValidFunc()) return;

        setStakeMessage('');
        _placeBet();
    };

    const playerTypesShowDeposit = [PlayerType.B2C, PlayerType.B2B2C];
    const isShowDepositButton = computed(() => {
        if (voucherEnabled.value) return false;
        return isBalanceLessThanMinBet.value && playerTypesShowDeposit.includes(playerType.value);
    });

    const { openDepositPage } = usePlatform();

    const tickets = computed(() => betSlip.value.tickets);
    const { isValidSelectionCount } = useBetBuilderInvalidMessageDisplay(tickets as Ref<IBetBuilderTicket[]>);
    const buttonText = computed(() => {
        if (betSlip.value.uid) {
            return i18n.t('bet_slip_place_bet');
        }

        if (isMixParlayBetSlip.value && tickets.value.length < MIN_SUB_BET_COUNT) {
            return i18n.t('bet_slip_place_bet');
        }

        if (isBetBuilderBetSlip.value && !isValidSelectionCount.value) {
            return i18n.t('bet_slip_place_bet');
        }

        return i18n.t('bet_slip_loading');
    });

    const handleRememberLastStake = () => {
        setIsShowLastStake(!isShowLastStake.value);
        const isEmptyLastStake = singleLastStake.value === 0
            || (isMixParlayBetSlip.value && parlayLastStake.value === 0);

        if (isEmptyLastStake) {
            return;
        }

        if (!isShowLastStake.value) {
            setStake(0);
            return;
        }

        if (isMixParlayBetSlip.value) {
            setStake(parlayLastStake.value);
            return;
        }

        setStake(singleLastStake.value);
    };
</script>

<style lang="scss" scoped>
@import "@/components/betSlip/PlaceBetBlock";
</style>
