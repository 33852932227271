<template>
    <div
        class="oddsBox notranslate"
        v-bind:class="{
            selected: isSelectedOdds,
        }"
        v-on:click="clickOdds"
    >
        <div
            class="content horizontal"
            v-bind:class="{
                rise: priceChangeStatus === PriceChangeStatus.Rise,
                drop: priceChangeStatus === PriceChangeStatus.Drop,
            }"
        >
            <div class="oddsPoint">
                <strong class="left">{{ odds.teamName }}</strong>
            </div>
            <div class="oddsValue">
                <strong class="price">{{ displayPrice }}</strong>
                <SvgIcon
                    v-show="isPriceChange"
                    class="arrow"
                    name="icon-system-arrow-down"
                    v-bind:rotated="priceChangeStatus === PriceChangeStatus.Rise"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import type { PropType } from 'vue';
    import { computed, toRefs } from 'vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import { usePriceChangeStatus } from '@/composable/usePriceChangeStatus';
    import { getTicketKey, outrightLeagueToEvent, outrightOddsToOdds, outrightOddsToPrice } from '@/core/lib/betSlipHelper.euro';
    import { formatMinDecimalPlace } from '@/core/lib/numberFormat';
    import { BetSlipType } from '@/interface/IBetSlip';
    import type { IOutrightLeagueEuro } from '@/interface/IOutrightLeague';
    import type { IOutrightOdds } from '@/interface/IOutrightOdds';
    import { EuroBetPageType, PriceChangeStatus } from '@/interface/enum';
    import { useEuroBetSlipStore } from '@/store/betSlipStore.euro';

    const props = defineProps({
        league: {
            type: Object as PropType<IOutrightLeagueEuro>,
            required: true,
        },
        odds: {
            type: Object as PropType<IOutrightOdds>,
            required: true,
        },
        align: {
            type: String as PropType<'left' | 'center' | 'right'>,
            default: 'center',
        },
    });

    const { league, odds } = toRefs(props);
    const price = computed(() => outrightOddsToPrice(odds.value));

    const displayPrice = computed(() => formatMinDecimalPlace(props.odds.price, 2));

    const priceValue = computed(() => odds.value.price);
    const { priceChangeStatus, isPriceChange } = usePriceChangeStatus(priceValue);

    const isSelectedOdds = computed(() => {
        const { singleBetSlips } = useEuroBetSlipStore();
        const betSlipKey = getTicketKey(odds.value, price.value);
        return singleBetSlips.some(x => x.key === betSlipKey);
    });

    const { useBetSlipsStoreByType } = useEuroBetSlipStore();
    const { addBet, removeBet } = useBetSlipsStoreByType(BetSlipType.Single);

    const clickOdds = () => {
        if (isSelectedOdds.value) {
            removeBet(getTicketKey(odds.value, price.value));
        } else {
            addBet(
                outrightLeagueToEvent(league.value, odds.value),
                outrightOddsToOdds(league.value, odds.value),
                price.value,
                EuroBetPageType.Outright,
                BetSlipType.Outright,
            );
        }
    };
</script>

<style lang="scss" scoped>
    @import "@/components/euroDisplay/Odds";
</style>
