import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useSessionStorage } from '@/composable/useSessionStorage';
import { useCustomerStore } from '@/store/customerStore';
import { useToggleStore } from '@/store/toggleStore';

export function useDisplayCashOut() {
    const { accountId } = storeToRefs(useCustomerStore());
    const [_isDisplayCashOut, setIsDisplayCashOut] = useSessionStorage<boolean>(`isDisplayCashOut:${accountId.value}`, true);
    const { isCashOutEnabled } = storeToRefs(useToggleStore());

    const isDisplayCashOut = computed(() => _isDisplayCashOut.value && isCashOutEnabled.value);

    return {
        isDisplayCashOut,
        setIsDisplayCashOut,
    };
}
