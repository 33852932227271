<template>
    <Message
        v-bind:message="i18n.t(receipts.message?.key ?? '', { count: receipts.message?.failedBetCount })"
        v-bind:status="receipts.message?.status"
        position="top"
        iconSize="lg"
        v-bind:show="true"
    />
    <div class="single">
        <div class="subHeader">
            <div class="description">
                {{ `${ i18n.t('bet_slip_selection_count', { count: receipts.list.length }, receipts.list.length)}` }}
            </div>
            <div class="action" v-on:click="reuseSelections">
                {{ i18n.t('mini_my_bet_reuse_selections') }}
            </div>
        </div>
    </div>
    <Receipt
        v-for="receipt in receipts.list"
        v-bind:key="receipt.transId"
        v-bind:receipt="receipt"
    />
    <div v-if="voucherEnabled" class="totalStakeBlock">
        <div class="detailText_item">
            <span>{{ i18n.t('voucher_stake') }}</span>
            <span class="amount">
                <CurrencyIcon />
                {{ totalVoucherStake }}
            </span>
        </div>
        <div class="detailText_item">
            <strong>{{ i18n.t('stake') }}</strong>
            <strong class="amount">
                <CurrencyIcon />
                {{ totalNormalStake }}
            </strong>
        </div>
    </div>
    <div class="placeBetBlock">
        <div class="detailText_item">
            <span>{{ i18n.t('total_stake') }}</span>
            <span class="amount">
                <CurrencyIcon />
                {{ totalStake }}
            </span>
        </div>
        <div class="detailText_item">
            <strong>{{ i18n.t('bet_slip_total_potential_returns') }}</strong>
            <strong class="amount">
                <CurrencyIcon />
                {{ totalReturn }}
            </strong>
        </div>
        <VButton
            v-gtm:@click
            v-gtm:label="'Continue'"
            block
            class="notranslate"
            color="brandColor-800"
            textColor="white"
            hoverTextColor="white"
            v-on:click="resetReceipts"
        >
            {{ i18n.t('bet_receipt_continue') }}
        </VButton>
        <VButton
            v-gtm:@click
            v-gtm:label="'CheckMyBet'"
            block
            outline
            class="notranslate"
            outlineColor="brandColor-800"
            hoverOutlineColor="brandColor-800"
            hoverTextColor="brandColor-800"
            v-on:click="openMyBets"
        >
            {{ i18n.t('bet_receipt_check_mybets') }}
        </VButton>
    </div>
</template>

<script lang="ts" setup>
    import { format } from '@sports-utils/money';
    import { toRefs } from '@vueuse/core';
    import { storeToRefs } from 'pinia';
    import { computed, onUnmounted } from 'vue';
    import Message from '@/components/betSlip/Message.vue';
    import CurrencyIcon from '@/components/common/CurrencyIcon.vue';
    import VButton from '@/components/common/button/VButton.vue';
    import Receipt from '@/components/receipt/Receipt.vue';
    import { useMyBets } from '@/composable/useMyBets';
    import { i18n } from '@/core/lib/i18n';
    import { sum } from '@/core/lib/math';
    import { BetSlipType } from '@/interface/IBetSlip';
    import { useEuroBetSlipStore } from '@/store/betSlipStore.euro';
    import { useCustomerStore } from '@/store/customerStore';

    const { openMyBets } = useMyBets();
    const { useBetSlipsStoreByType } = useEuroBetSlipStore();
    const { addBet, receipts, resetReceipts } = useBetSlipsStoreByType(BetSlipType.Single);

    const reuseSelections = () => {
        receipts.value.list.forEach((receipt) => {
            const ticket = receipt.tickets[0];
            addBet(ticket.event, ticket.odds, ticket.priceOption, ticket.betPage, BetSlipType.Single);
        });
    };

    const { defaultMoneyConfig } = storeToRefs(useCustomerStore());
    const formattedSum = (list: number[]) => format(sum(...list), defaultMoneyConfig.value);

    const totalNormalStake = computed(() => formattedSum(receipts.value.list.filter(f => !f.voucherId).map(r => r.stake)));
    const totalVoucherStake = computed(() => formattedSum(receipts.value.list.filter(f => f.voucherId).map(r => r.stake)));
    const totalStake = computed(() => formattedSum(receipts.value.list.map(r => r.stake)));
    const totalReturn = computed(() => formattedSum(receipts.value.list.map(r => r.maxPayout)));

    const { voucherEnabled } = toRefs(useEuroBetSlipStore());

    onUnmounted(() => {
        resetReceipts();
    });
</script>

<style scoped lang="scss">
    @import "@/components/betSlip/BetSlipBlock.Euro";
</style>
