import { useIntervalFn } from '@vueuse/core';
import type { Ref } from 'vue';
import { computed, ref } from 'vue';
import { useIdle } from '@/composable/useIdle';
import { assignArray, remove, upsert } from '@/core/lib/array';
import { i18n } from '@/core/lib/i18n';
import { getFilterFromEuroDate } from '@/core/oddsApi/helpers';
import { formatEventEuro } from '@/core/oddsApi/helpers/formatEvent';
import { EnumOddsCategoryType, EnumTimeZoneType, useEventsByRegionsQuery, useEventsByRegionsSubscription } from '@/core/oddsApi/oddsApiType';
import type { IEventEuro } from '@/interface/IEvent';
import type { EuroDate } from '@/interface/enum';
import { EnumLanguage, SportType } from '@/interface/enum';

export function useRegionEvents(sportType: Ref<SportType>, date: Ref<EuroDate>, regionIds: Ref<number[]>) {
    const variables = computed(() => ({
        query: {
            sport: SportType.toOddsApi(sportType.value),
            filter: getFilterFromEuroDate(date.value),
            oddsCategory: EnumOddsCategoryType.All,
            regionIds: regionIds.value,
            timeZone: EnumTimeZoneType.UTC__4,
            lang: EnumLanguage.toOddsApi(i18n.locale.value),
        },
    }));

    const { onResult, loading, loaded, refetch } = useEventsByRegionsQuery(variables);
    const { onSubscription } = useEventsByRegionsSubscription(variables);

    useIntervalFn(refetch, 60 * 1000);

    // Automatically recover from idle state with a force refresh
    // This is to prevent the user from losing data when they are idle
    const { onActive } = useIdle(60 * 1000);
    onActive(() => {
        // eslint-disable-next-line no-console
        console.log('useRegionEvents: Recovering from idle state...');
        refetch();
    });

    const events = ref<IEventEuro[]>([]);

    onResult((result) => {
        if (result) {
            assignArray(events.value, result.map(e => formatEventEuro(e)), item => item.id);
        }
    });

    onSubscription((subscription) => {
        upsert(events.value, (subscription?.updated ?? []).map(e => formatEventEuro(e)), item => item.id);
        remove(events.value, subscription?.deleted ?? [], (a, b) => a.id === b);
    });

    return {
        loaded,
        loading,
        refetch,
        events,
    };
}
