<template>
    <div
        v-if="banner"
        v-gtm:type="'SideBanner'"
        v-gtm:@view
        v-gtm:@click
        v-gtm:content="banner.gtm"
        class="sideBanner"
        v-bind:data-ga="'SideBanner'"
        v-bind:data-img="banner.gtm"
        v-bind:style="{ backgroundImage: `url(${banner.cdn})` }"
        v-on:click="executeBannerAction(banner)"
    />
</template>

<script lang="ts" setup>
    import { computed } from 'vue';
    import { useBannerAsia } from '@/composable/useBanners';
    import { BannerPosition } from '@/interface/enum';

    const { executeBannerAction, banners } = useBannerAsia(BannerPosition.AsiaSide);
    const banner = computed(() => (banners.value ? banners.value[0] : null));
</script>

<style lang="scss" scoped>
    @import "@/components/banner/SideBanner";
</style>
