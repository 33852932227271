import type { Score } from '@/interface/ILiveScore';
import type { FGLGResult, ResultType, ScoringType, SelectionOutcome, SportType } from '@/interface/enum';

export interface IResult {
    sportType: SportType;
    leagueId: number;
    leagueName: string;
    matchId: number;
    matchCode: string;
    showTime: Date;
    showTimeDisplayType: number;
    homeTeam: string;
    awayTeam: string;
    mainMarketMatchResult: IMatchResult;
    newBetTypeMatchResults: IMatchResult[];
    firstGoal: FGLGResult;
    lastGoal: FGLGResult;
    hasBetBuilderResults: boolean;
}

export interface IMatchResult {
    sportType: SportType;
    matchResultId: number;
    betTypeGroupId: number;
    betTypeGroupName: string;
    status: number;
    thirdPartyCode?: string;
    homeOption: string;
    awayOption: string;
    scoringType: ScoringType;
    gameScores: Record<string, Score>;
    totalScores: Partial<Record<ResultType, Score>>;
}

export interface IOutrightTournamentResult {
    id: number;
    name: string;
    eventDate: Date;
    hasNoWinner: boolean;
    outrights: IOutrightResult[];
}

export interface IOutrightResult {
    id: number;
    matchCode: string;
    eventDate: Date;
    result: OutrightSettleStatus;
    teamId: number;
    teamName: string;
}

export enum OutrightSettleStatus {
    Unknown,
    Won,
    Lose,
    Refunded,
}

export interface IBetBuilderResult {
    point: number | null;
    marketType: IMarketType;
    isRefunded: boolean;
    selectionResult: ISelectionResult;
    selectionResults: ISelectionResult[];
}

export interface ISelectionResult {
    outcome: SelectionOutcome;
    name: string;
}

export interface IMarketType {
    id: number;
    name: string;
}
