import type { Ref } from 'vue';
import { BetSlipContextKey } from '@/components/betSlip/betSlipContext/BaseBetSlipContext';
import { createBetBuilderBetSlipContext } from '@/components/betSlip/betSlipContext/BetBuilderBetSlipContext';
import { createOutrightBetSlipContext } from '@/components/betSlip/betSlipContext/OutrightBetSlipContext';
import { createEuroOutrightBetSlipContext } from '@/components/betSlip/betSlipContext/OutrightBetSlipContext.euro';
import { createParlayBetSlipContext } from '@/components/betSlip/betSlipContext/ParlayBetSlipContext';
import { createEuroParlayBetSlipContext } from '@/components/betSlip/betSlipContext/ParlayBetSlipContext.euro';
import { createSingleBetSlipContext } from '@/components/betSlip/betSlipContext/SingleBetSlipContext';
import { createEuroSingleBetSlipContext } from '@/components/betSlip/betSlipContext/SingleBetSlipContext.euro';
import { defineContext } from '@/core/lib/defineContext';
import type { IBetSlip, IBetSlipEuro } from '@/interface/IBetSlip';
import { BetSlipType } from '@/interface/IBetSlip';
import type { IBetSlipContextEuro } from '@/interface/IBetSlipStoreEuro';
import type { IBetBuilderBetSlip } from '@/interface/betBuilder';

export const BetSlipContext = defineContext(BetSlipContextKey, (betSlip: Ref<IBetSlip | IBetBuilderBetSlip>) => {
    switch (betSlip.value.type) {
        case BetSlipType.BetBuilder:
            return createBetBuilderBetSlipContext(betSlip as Ref<IBetBuilderBetSlip>);
        case BetSlipType.Single:
            return createSingleBetSlipContext(betSlip as Ref<IBetSlip>);
        case BetSlipType.Parlay:
            return createParlayBetSlipContext(betSlip as Ref<IBetSlip>);
        case BetSlipType.Outright:
            return createOutrightBetSlipContext(betSlip as Ref<IBetSlip>);
        default:
            return createSingleBetSlipContext(betSlip as Ref<IBetSlip>);
    }
});

export const BetSlipContextEuro = defineContext('BetSlipContextEuro', (betSlip: Ref<IBetSlipEuro>): IBetSlipContextEuro => {
    switch (betSlip.value.type) {
        case BetSlipType.Single:
            return createEuroSingleBetSlipContext(betSlip);
        case BetSlipType.Outright:
            return createEuroOutrightBetSlipContext(betSlip);
        case BetSlipType.Parlay:
            return createEuroParlayBetSlipContext(betSlip);
        default:
            return createEuroSingleBetSlipContext(betSlip);
    }
});
