import { chunk } from 'lodash';
import orderBy from 'lodash/orderBy';
import { padRight, rotate2dArray } from '@/core/lib/array';
import { i18n } from '@/core/lib/i18n';
import type { IBetBuilderMarket, ISelection } from '@/interface/betBuilder';
import { BetBuilderDisplayType, BetBuilderMarket, BetBuilderSelectionType, BetBuilderStatType } from '@/interface/enum';

type ISelectionGroup = { market: IBetBuilderMarket, selection: ISelection }
type WidthType = 'width-21' | 'width-31' | 'width-32' | 'width-full';
type WidthFormatter = (selectionGroups: ISelectionGroup[][]) => WidthType;
type SelectionFormatter = (selections: ISelection[]) => ((ISelection | Pick<ISelection, 'type'>)[]);
type SelectionGroupFormatter = (selectionGroups: ISelectionGroup[][]) => ((ISelectionGroup | null)[][]);
type OptionFormatter = (selectionGroup: ISelectionGroup) => string;
type TooltipFormatter = (selectionGroup: ISelectionGroup) => string;

interface MarketRule {
    widthFormatter: WidthFormatter;
    optionFormatter: OptionFormatter;
    tagFormatter: OptionFormatter;
    selectionFormatter: SelectionFormatter;
    selectionGroupFormatter: SelectionGroupFormatter;
    tooltipFormatter: TooltipFormatter;
    minDecimalPlace: number;
}

const defaultWidthFormatter: WidthFormatter = (selectionGroups) => {
    const selectionGroup = selectionGroups[0];
    if (selectionGroup.length <= 4) return 'width-31';
    if (selectionGroup.length <= 6) return 'width-21';

    return 'width-full';
};

const defaultOptionFormatter: OptionFormatter = selectionGroup => selectionGroup.selection.type.name;
const defaultSelectionFormatter: SelectionFormatter = selections => orderBy(selections, x => x.id);
const defaultSelectionGroupFormatter: SelectionGroupFormatter = selectionGroups => selectionGroups.map(selections => orderBy(selections, x => x.selection.id));
const defaultTagFormatter: OptionFormatter = selectionGroup => selectionGroup.selection.type.name;
const defaultTooltipFormatter: TooltipFormatter = () => '';

const defaultMinDecimalPlace = 0;

const hdpSelectionTypes = [BetBuilderSelectionType.Home, BetBuilderSelectionType.Away];
const hdpRule: Partial<MarketRule> = {
    selectionFormatter: selections => hdpSelectionTypes.map(type => selections.find(x => x.type.id === type) ?? { type: { id: type, name: '' } }),
    selectionGroupFormatter: (selectionGroups) => {
        const ordered = selectionGroups.map(selectionGroup => hdpSelectionTypes
            .map(type => selectionGroup.find(x => x.selection.type.id === type) ?? null));
        return ordered;
    },
    optionFormatter: (selectionGroup) => {
        const selection = selectionGroup.selection;
        if (selection.type.id === BetBuilderSelectionType.Home) return i18n.t('odds_option_home');
        if (selection.type.id === BetBuilderSelectionType.Away) return i18n.t('odds_option_away');
        return selection.type.name;
    },
};

const _1x2SelectionTypesMain = [BetBuilderSelectionType.Home, BetBuilderSelectionType.Away, BetBuilderSelectionType.Draw];
const _1x2SelectionTypesMore = [BetBuilderSelectionType.Home, BetBuilderSelectionType.Draw, BetBuilderSelectionType.Away];
const _1x2Rule: Partial<MarketRule> = {
    selectionFormatter: selections => _1x2SelectionTypesMain.map(type => selections.find(x => x.type.id === type) ?? { type: { id: type, name: '' } }),
    selectionGroupFormatter: (selectionGroups) => {
        const ordered = selectionGroups.map(selectionGroup => _1x2SelectionTypesMore
            .map(type => selectionGroup.find(x => x.selection.type.id === type) ?? null));
        return ordered;
    },
    optionFormatter: (selectionGroup) => {
        const selection = selectionGroup.selection;
        if (selection.type.id === BetBuilderSelectionType.Home) return '1';
        if (selection.type.id === BetBuilderSelectionType.Away) return '2';
        if (selection.type.id === BetBuilderSelectionType.Draw) return 'X';
        return selection.type.name;
    },
    tagFormatter: (selectionGroup) => {
        const selection = selectionGroup.selection;
        if (selection.type.id === BetBuilderSelectionType.Home) return '1';
        if (selection.type.id === BetBuilderSelectionType.Away) return '2';
        if (selection.type.id === BetBuilderSelectionType.Draw) return 'X';
        return selection.type.name;
    },
    tooltipFormatter: (selectionGroup) => {
        const { market, selection } = selectionGroup;
        if (market.matchStatType.id === BetBuilderStatType.FullTime) {
            if (selection.type.id === BetBuilderSelectionType.Home) return i18n.t('odds_option_ft_1_tooltip');
            if (selection.type.id === BetBuilderSelectionType.Away) return i18n.t('odds_option_ft_2_tooltip');
            if (selection.type.id === BetBuilderSelectionType.Draw) return i18n.t('odds_option_ft_x_tooltip');
        }

        if (market.matchStatType.id === BetBuilderStatType.FirstHalf) {
            if (selection.type.id === BetBuilderSelectionType.Home) return i18n.t('odds_option_fh_1_tooltip');
            if (selection.type.id === BetBuilderSelectionType.Away) return i18n.t('odds_option_fh_2_tooltip');
            if (selection.type.id === BetBuilderSelectionType.Draw) return i18n.t('odds_option_fh_x_tooltip');
        }

        return '';
    },
};

const correctScoreRule: Partial<MarketRule> = {
    selectionGroupFormatter: (selectionGroups) => {
        const selectionGroup = selectionGroups[0];
        const numberOfRows = Math.ceil(selectionGroup.length / 13);
        const numberOfColumns = Math.ceil(selectionGroup.length / numberOfRows);

        const sorted = orderBy(selectionGroup, x => x.selection.type.name);
        const priceRows = new Array<Array<ISelection | null>>(numberOfRows)
            .fill(new Array(numberOfColumns).fill(null))
            .map((row, rowIndex) => row
                .map((_, columnIndex) => sorted[rowIndex * row.length + columnIndex] ?? null));

        return priceRows;
    },
    tooltipFormatter: (selectionGroup) => {
        const { market, selection } = selectionGroup;

        const oddsOptionTooltipMapping: Partial<Record<BetBuilderStatType | number, string>> = {
            [BetBuilderStatType.FullTime]: 'odds_option_ftcs_score_tooltip',
            [BetBuilderStatType.FirstHalf]: 'odds_option_fhcs_score_tooltip',
        };

        const oddsOptionTooltip = oddsOptionTooltipMapping[market.matchStatType.id];
        if (!oddsOptionTooltip) return '';

        const [homeOption, awayOption] = selection.type.name.split(':').map(x => parseInt(x));
        return i18n.t(oddsOptionTooltip, {
            home: homeOption,
            away: awayOption,
        });
    },
};

const oddEvenSelectionTypes = [BetBuilderSelectionType.Odd, BetBuilderSelectionType.Even];
const oddEvenRule: Partial<MarketRule> = {
    selectionFormatter: selections => oddEvenSelectionTypes.map(type => selections.find(x => x.type.id === type) ?? { type: { id: type, name: '' } }),
    selectionGroupFormatter: (selectionGroups) => {
        const ordered = selectionGroups.map(selectionGroup => oddEvenSelectionTypes
            .map(type => selectionGroup.find(x => x.selection.type.id === type) ?? null));
        return ordered;
    },
    optionFormatter: (selectionGroup) => {
        const selection = selectionGroup.selection;
        if (selection.type.id === BetBuilderSelectionType.Odd) return i18n.t('odds_option_odd');
        if (selection.type.id === BetBuilderSelectionType.Even) return i18n.t('odds_option_even');
        return selection.type.name;
    },
    tagFormatter: (selectionGroup) => {
        const selection = selectionGroup.selection;
        if (selection.type.id === BetBuilderSelectionType.Odd) return 'O';
        if (selection.type.id === BetBuilderSelectionType.Even) return 'E';
        return selection.type.name;
    },
};

const overUnderSelectionTypes = [BetBuilderSelectionType.Over, BetBuilderSelectionType.Under];
const overUnderRule: Partial<MarketRule> = {
    selectionFormatter: selections => overUnderSelectionTypes.map(type => selections.find(x => x.type.id === type) ?? { type: { id: type, name: '' } }),
    selectionGroupFormatter: (selectionGroups) => {
        const sorted = selectionGroups.map(selectionGroup => orderBy(selectionGroup, x => x.market.point));
        const ordered = sorted.map(selectionGroup => overUnderSelectionTypes
            .map(type => selectionGroup.find(x => x.selection.type.id === type) ?? null));
        return rotate2dArray(ordered);
    },
    optionFormatter: (selectionGroup) => {
        const selection = selectionGroup.selection;
        if (selection.type.id === BetBuilderSelectionType.Over) return i18n.t('odds_option_over');
        if (selection.type.id === BetBuilderSelectionType.Under) return i18n.t('odds_option_under');
        return selection.type.name;
    },
    tagFormatter: (selectionGroup) => {
        const selection = selectionGroup.selection;
        if (selection.type.id === BetBuilderSelectionType.Over) return 'O';
        if (selection.type.id === BetBuilderSelectionType.Under) return 'U';
        return selection.type.name;
    },
    widthFormatter: (selectionGroups) => {
        if (selectionGroups.length <= 4) return 'width-31';
        if (selectionGroups.length <= 6) return 'width-21';
        return 'width-full';
    },
};

const doubleChanceSelectionTypes = [BetBuilderSelectionType._1X, BetBuilderSelectionType._12, BetBuilderSelectionType._2X];
const doubleChanceRule: Partial<MarketRule> = {
    selectionFormatter: selections => doubleChanceSelectionTypes.map(type => selections.find(x => x.type.id === type) ?? { type: { id: type, name: '' } }),
    selectionGroupFormatter: (selectionGroups) => {
        const ordered = selectionGroups.map(selectionGroup => doubleChanceSelectionTypes
            .map(type => selectionGroup.find(x => x.selection.type.id === type) ?? null));
        return ordered;
    },
    optionFormatter: (selectionGroup) => {
        const selection = selectionGroup.selection;
        if (selection.type.id === BetBuilderSelectionType._1X) return i18n.t('odds_option_dc_1x');
        if (selection.type.id === BetBuilderSelectionType._12) return i18n.t('odds_option_dc_12');
        if (selection.type.id === BetBuilderSelectionType._2X) return i18n.t('odds_option_dc_x2');
        return selection.type.name;
    },
};

const teamOrPlayerRule: Partial<MarketRule> = {
    selectionGroupFormatter: (selectionGroups) => {
        const selectionGroup = selectionGroups[0];
        const ordered = orderBy(selectionGroup, x => x.selection.id);
        const chunked = chunk(ordered, 3).map(x => padRight(x, 3, null));
        return chunked;
    },
    widthFormatter: () => 'width-full',
};

const shortOptionsRule: Partial<MarketRule> = {
    selectionGroupFormatter: (selectionGroups) => {
        const selectionGroup = selectionGroups[0];
        if (selectionGroup.length <= 5) {
            return [orderBy(selectionGroup, x => x.selection.id)];
        }
        const orderedSelections = orderBy(selectionGroup, x => x.selection.id);
        const chunkedSelections = chunk(orderedSelections, 3).map(x => padRight(x, 3, null));
        return chunkedSelections;
    },
};

const optionsOrNeitherRule: Partial<MarketRule> = {
    selectionGroupFormatter: (selectionGroups) => {
        const selectionGroup = selectionGroups[0];
        return [orderBy(
            selectionGroup,
            [
                x => [BetBuilderSelectionType.PlayerName, BetBuilderSelectionType.TeamName, BetBuilderSelectionType.Neither].indexOf(x.selection.type.id),
                x => x.selection.id,
            ],
        )];
    },
};

const defaultRule: MarketRule = {
    widthFormatter: defaultWidthFormatter,
    optionFormatter: defaultOptionFormatter,
    tagFormatter: defaultTagFormatter,
    selectionFormatter: defaultSelectionFormatter,
    selectionGroupFormatter: defaultSelectionGroupFormatter,
    tooltipFormatter: defaultTooltipFormatter,
    minDecimalPlace: defaultMinDecimalPlace,
};

export function getMarketRule(market: IBetBuilderMarket): MarketRule {
    const withDefault = (rule: Partial<MarketRule>) => ({ ...defaultRule, ...rule });

    switch (market.displayType) {
        case BetBuilderDisplayType.Handicap: return withDefault(hdpRule);
        case BetBuilderDisplayType.OddEven: return withDefault(oddEvenRule);
        case BetBuilderDisplayType.OverUnder: return withDefault(overUnderRule);
        case BetBuilderDisplayType.CorrectScore: return withDefault(correctScoreRule);
        case BetBuilderDisplayType.DoubleChance: return withDefault(doubleChanceRule);
        case BetBuilderDisplayType._1X2: return withDefault(_1x2Rule);
        case BetBuilderDisplayType.FH_1X2: return withDefault(_1x2Rule);
        case BetBuilderDisplayType.FH_OverUnder: return withDefault(overUnderRule);
        case BetBuilderDisplayType.FH_OddEven: return withDefault(oddEvenRule);
        case BetBuilderDisplayType.FH_CorrectScore: return withDefault(correctScoreRule);

        case BetBuilderDisplayType.Game:
        case BetBuilderDisplayType.OutRight:
        case BetBuilderDisplayType.MixParlay:
            return defaultRule;
        default: {
            switch (market.marketType.id) {
                case BetBuilderMarket.Set1x2: return withDefault(_1x2Rule);

                case BetBuilderMarket.SetExactNumberGoals:
                case BetBuilderMarket.SetTeamExactNumberGoals:
                    return withDefault(shortOptionsRule);

                case BetBuilderMarket.AnytimeGoalscorer:
                case BetBuilderMarket.PlayerToScoreTwoOrMoreGoals:
                case BetBuilderMarket.PlayerToScoreAHatTrick:
                case BetBuilderMarket.FirstGoalscorer:
                case BetBuilderMarket.LastGoalscorer:
                case BetBuilderMarket.TeamToScoreGoal:
                case BetBuilderMarket.PlayerToScoreGoal:
                    return withDefault(teamOrPlayerRule);

                case BetBuilderMarket.FirstTeamToScore:
                case BetBuilderMarket.LastTeamToScore:
                default: {
                    if (market.selections.length > 5) {
                        return withDefault(teamOrPlayerRule);
                    }

                    /**
                     * There are two major types of market containing "Neither" selection:
                     * 1. Market with "Neither" and "Team" selections
                     * 2. Market with "Neither" and "Player" selections
                     */
                    if (market.selections.some(p => p.type.id === BetBuilderSelectionType.Neither)) {
                        return withDefault(optionsOrNeitherRule);
                    }

                    return defaultRule;
                }
            }
        }
    }
}
