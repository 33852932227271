<template>
    <!-- Main Market -->
    <OddsDisplayMatchBody
        v-bind:isOddsLoaded="isOddsLoaded"
        v-bind:isMoreOddsLoaded="isNewBetTypeOddsLoaded"
        v-bind:event="event"
        v-bind:oddsList="filterOddsList(BetTypeGroup.Main)"
        v-bind:betBuilderMarkets="betBuilderMatch?.markets"
        v-bind:marketGroupId="BetTypeGroup.Main"
        v-bind:outrightLeagues="allOutrightLeagues"
    />

    <!-- New Bet Types -->
    <template
        v-for="eventResult in eventResultsOnMainPage"
        v-if="!isInBetBuilderMode"
        v-bind:key="eventResult.id"
    >
        <Teleport
            v-if="newBetTypeTeleportTargets.has(eventResult.id)"
            v-bind:to="newBetTypeTeleportTargets.get(eventResult.id)!.element"
        >
            <OddsDisplayMatchBody
                v-bind:isOddsLoaded="isNewBetTypeOddsLoaded"
                v-bind:isMoreOddsLoaded="isNewBetTypeOddsLoaded"
                v-bind:event="event"
                v-bind:oddsList="filterOddsList(eventResult.marketGroup.id)"
                v-bind:marketGroupId="eventResult.marketGroup.id"
                v-bind:eventResultIndex="getEventResultIndex(eventResult.id)"
            />
        </Teleport>
    </template>
</template>

<script lang="ts" setup>
    import { useThrottleFn } from '@vueuse/core';
    import uniqBy from 'lodash/uniqBy';
    import type { PropType } from 'vue';
    import { computed, toRefs, watch } from 'vue';
    import { isShowNewBetTypeOnMainPage } from './matchDisplay/useMatchDisplay';
    import { OddsDisplayContext } from '@/components/oddsDisplay/OddsDisplayContext';
    import OddsDisplayMatchBody from '@/components/oddsDisplay/OddsDisplayMatchBody.vue';
    import { OddsDisplayMatchContext } from '@/components/oddsDisplay/OddsDisplayMatchContext';
    import { useOpenMoreMarket } from '@/components/oddsDisplay/matchDisplay/useOpenMoreMarket';
    import { useEventEmitter } from '@/composable/useEventEmitter';
    import { getMarketGroupId } from '@/core/lib/oddsHelper';
    import { EnumOddsMarketFilterType } from '@/core/oddsApi/oddsApiType';
    import type { IEvent } from '@/interface/IEvent';
    import { BetTypeGroup } from '@/interface/enum';

    const props = defineProps({
        event: {
            type: Object as PropType<IEvent>,
            required: true,
        },
        newBetTypeTeleportTargets: {
            type: Object as PropType<Map<number, {element: HTMLDivElement, eventResultIndex: number | null }>>,
            required: true,
        },
    });

    const getEventResultIndex = (eventResultIndex: number) => props.newBetTypeTeleportTargets.get(eventResultIndex)?.eventResultIndex ?? null;

    const { oddsCacheWithExpiry, outrightCacheWithExpiry, oddsDisplayMode } = OddsDisplayContext.inject();
    const { event } = toRefs(props);
    const { isOpenMoreMarket } = useOpenMoreMarket(oddsDisplayMode, event, BetTypeGroup.Main);

    const eventResultsOnMainPage = computed(() => props.event.eventResults
        .filter(eventResult => isShowNewBetTypeOnMainPage(props.event.sportType, eventResult.marketGroup.id, props.event.isLive, oddsDisplayMode)));
    const shouldLoadNewBetType = computed(() => eventResultsOnMainPage.value.length > 0 || isOpenMoreMarket.value);
    const isEnableOutright = computed(() => isOpenMoreMarket.value && props.event.outrightIds.length > 0);

    const {
        oddsList, loaded, refetch,
        newBetTypeOddsList, newBetTypeLoaded, newBetTypeRefetch,
        outrightLeagues, outrightLoaded, outrightLeagueRefetch,
        betBuilderMatch, isInBetBuilderMode,
    } = OddsDisplayMatchContext.provide(props.event.id, shouldLoadNewBetType, isEnableOutright);

    /** Put latest odds into odds cache  */
    /** So when user scroll away and back, we can use the cached data to render first */
    const MainMarketOddsCacheKey = `${props.event.id}-${EnumOddsMarketFilterType.MainMarket}`;
    const NewBetTypeOddsCacheKey = `${props.event.id}-${EnumOddsMarketFilterType.NewBetType}`;
    const OutrightLeaguesCacheKey = `${props.event.id}-${'Outright'}`;

    watch(oddsList, value => oddsCacheWithExpiry.set(MainMarketOddsCacheKey, value), { deep: true });
    watch(newBetTypeOddsList, value => oddsCacheWithExpiry.set(NewBetTypeOddsCacheKey, value), { deep: true });
    watch(outrightLeagues, value => outrightCacheWithExpiry.set(OutrightLeaguesCacheKey, value), { deep: true });

    const isOddsLoaded = computed(() => oddsCacheWithExpiry.has(MainMarketOddsCacheKey) || loaded.value);
    const isNewBetTypeOddsLoaded = computed(() => shouldLoadNewBetType.value && (oddsCacheWithExpiry.has(NewBetTypeOddsCacheKey) || newBetTypeLoaded.value));

    /** Automatically fallback to use cached odds */
    const allOddsList = computed(() => uniqBy([
        ...(loaded.value
            ? oddsList.value
            : oddsCacheWithExpiry.get(MainMarketOddsCacheKey) ?? []),
        // eslint-disable-next-line no-nested-ternary
        ...(shouldLoadNewBetType.value
            ? newBetTypeLoaded.value
                ? newBetTypeOddsList.value
                : oddsCacheWithExpiry.get(NewBetTypeOddsCacheKey) ?? []
            : []),
    ], x => x.id));

    const filterOddsList = (marketGroupId: BetTypeGroup) => (marketGroupId === BetTypeGroup.Main
        ? allOddsList.value.filter(odds => !isShowNewBetTypeOnMainPage(props.event.sportType, getMarketGroupId(odds), props.event.isLive, oddsDisplayMode))
        : allOddsList.value.filter(odds => getMarketGroupId(odds) === marketGroupId));
    const allOutrightLeagues = computed(() => (outrightLoaded.value
        ? outrightLeagues.value
        : outrightCacheWithExpiry.get(OutrightLeaguesCacheKey) ?? []));

    const throttledRefetchOdds = useThrottleFn(refetch, 5000, false, true);
    const throttledMoreMarketRefetch = useThrottleFn(newBetTypeRefetch, 5000, false, true);
    const throttledOutrightLeagueRefetch = useThrottleFn(outrightLeagueRefetch, 5000, false, true);

    const eventEmitter = useEventEmitter();
    eventEmitter.on('oddsDisplay:refreshOdds', (eventId) => {
        if (eventId === null || eventId === props.event.id) {
            throttledRefetchOdds();
            throttledMoreMarketRefetch();
            throttledOutrightLeagueRefetch();
        }
    });
</script>
