import { differenceBy, isEqual } from 'lodash';

/**
 * This method is a hacky solution for typescript cannot narrow down
 * the type in Array.prototype.filter
 * Issue: https://github.com/microsoft/TypeScript/issues/7657
 */
export function NonNull<T>(value: T | null | undefined): value is T {
    return value !== null && value !== undefined;
}

export function removeDuplicates<T>(value: T[]): T[] {
    return [...new Set(value)];
}

/**
 * Creates an array being grouped to group of arrays which remains the same order.
 * Iteratee will receive each element and return a value indicate which group the element is.
 * @param collection
 * @param iteratee
 */
export function groupByWithOrder<T>(collection: T[], iteratee: (value: T) => any): T[][] {
    const len = collection.length;
    if (!Array.isArray(collection) || len === 0) return [];

    let prevKey = null;
    const result: T[][] = [];
    for (let i = 0; i < len; i++) {
        const element = collection[i];
        const currKey = iteratee(element);
        if (result.length === 0 || prevKey !== currKey) {
            result.push([element]);
        } else {
            result[result.length - 1].push(element);
        }
        prevKey = currKey;
    }
    return result;
}

/**
 * Assigns destination array items to be same as source array. Order is not guaranteed. This method mutates array.
 * @param array destination array to modify
 * @param source source of items to updates/deletes
 * @param keySelector function to get item's key to compare for equivalency
 */
export function assignArray<T, K>(array: T[], source: T[], keySelector: (item: T) => K) {
    const toDelete = differenceBy(array, source, keySelector);
    remove(array, toDelete, (a, b) => keySelector(a) === keySelector(b));
    upsert(array, source, keySelector);
}

export function upsert<T, K>(list: T[], toUpsert: T[], keySelector: (item: T, index: number, array: T[]) => K) {
    const map = createMap(list, keySelector, (item, index) => ({ item, index }));

    toUpsert.forEach((item, index, array) => {
        const original = map.get(keySelector(item, index, array));
        if (!original) {
            list.push(item);
        } else if (!isEqual(original.item, item)) {
            list.splice(original.index, 1, item);
        }
    });
}

export function remove<T, R>(list: T[], toDelete: R[], comparator: (a: T, b: R) => boolean) {
    toDelete.forEach((item) => {
        const index = list.findIndex(i => comparator(i, item));
        if (index !== -1) {
            list.splice(index, 1);
        }
    });
}

export function createMap<T, K, V>(list: T[], keySelector: (item: T, index: number, array: T[]) => K, valueSelector: (item: T, index: number, array: T[]) => V) {
    return new Map(list.map((item, index, array) => [
        keySelector(item, index, array),
        valueSelector(item, index, array),
    ]));
}

/**
 * Pads the array with the given value to make the array length to be the target length.
 */
export function padRight<T>(arr: T[], targetLength: number, value: T): T[] {
    if (arr.length >= targetLength) return arr;
    return arr.concat(new Array(targetLength - arr.length).fill(value));
}

// Example:
// [[1,2,3],[4,5,6]] -> [[1, 4],[2, 5],[3, 6]]
export function rotate2dArray<T>(arr: T[][]): T[][] {
    return arr[0].map((_, columnIndex) => arr.map(row => row[columnIndex]));
}
