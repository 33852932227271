<template>
    <span class="stateTag" v-bind:class="classes">
        {{ text }}
    </span>
</template>

<script lang="ts" setup>
    import type { PropType } from 'vue';
    import { computed } from 'vue';

    const props = defineProps({
        text: {
            type: String,
            required: true,
        },
        level: {
            type: String as PropType<'success' | 'info' | 'warning' | 'danger'>,
            default: 'info',
        },
        // can be size: sm, md, lg, ...
        size: {
            type: String as PropType<'sm'>,
            default: () => '',
        },
    });

    const classes = computed(() => {
        const result = [];
        result.push(`stateTag-${props.level}`);

        if (props.size) result.push(`stateTag-${props.size}`);

        return result;
    });

</script>

<style lang="scss" scoped>
    @import "@/components/common/statusTag/StatusTag";
</style>
