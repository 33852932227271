<template>
    <ParlayBetSlipBlock v-if="receipts.list.length === 0" />
    <ParlayReceiptBlock v-else />
</template>

<script lang="ts" setup>
    import ParlayBetSlipBlock from '@/components/betSlip/ParlayBetSlipBlock.Euro.vue';
    import ParlayReceiptBlock from '@/components/receipt/ParlayReceiptBlock.vue';
    import { BetSlipType } from '@/interface/IBetSlip';
    import { useEuroBetSlipStore } from '@/store/betSlipStore.euro';

    const { useBetSlipsStoreByType } = useEuroBetSlipStore();
    const { receipts } = useBetSlipsStoreByType(BetSlipType.Parlay);
</script>
