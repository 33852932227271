import type { Ref } from 'vue';
import { computed, watch } from 'vue';
import { useAutoResetState } from '@/composable/useAutoResetState';
import { defineContext } from '@/core/lib/defineContext';
import { toFixedDecimalPoint } from '@/core/lib/numberFormat';
import type { IBetBuilderTicket } from '@/interface/betBuilder';

export const BetBuilderTicketContext = defineContext('BetBuilderTicketContext', (ticket: Ref<IBetBuilderTicket>) => {
    const pointDisplay = computed(() => {
        if (!ticket.value.market.point) return null;
        return toFixedDecimalPoint(ticket.value.market.point, 2);
    });
    const [isPointChange, setPointChange] = useAutoResetState(false, 3000);
    watch(pointDisplay, () => setPointChange(true));

    return {
        pointDisplay,
        isPointChange,
    };
});
