<template>
    <div
        class="videoContainer betRadar"
        v-bind:class="{ hasError: errorMsg }"
    >
        <div v-if="errorMsg" class="videoContainer_message">
            {{ errorMsg }}
        </div>
        <div
            v-else
            id="videoContainer_player"
        />
    </div>
</template>

<script lang="ts" setup>
    import type { PropType } from 'vue';
    import { onUnmounted, ref } from 'vue';
    import { Api } from '@/core/lib/api';
    import { loadCss, loadScript } from '@/core/lib/utils';
    import type { IBetRadar } from '@/interface/IEvent';
    import { LiveStreamResource } from '@/interface/pollux';

    async function setupPlayer() {
        loadCss('https://avplayer-cdn.sportradar.com/dist/latest/styles.css');
        await loadScript('https://avplayer-cdn.sportradar.com/dist/latest/avvpl-player.js');

        // eslint-disable-next-line no-undef, new-cap
        const player = new avvpl.setupPlayer({ id: 'videoContainer_player' });
        player.on('error', e => console.error(e));
        return player;
    }

    const props = defineProps({
        betRadar: {
            type: Object as PropType<IBetRadar>,
            required: true,
        },
    });

    const errorMsg = ref('');

    Api.getLiveStream(props.betRadar.eventId, props.betRadar.streamId, LiveStreamResource.BetRadar)
        .then(async ({ data }) => {
            const { errorCode, errorMessage, url } = data;
            if (errorCode === 0 && url) {
                const player = await setupPlayer();
                player.load(url);

                onUnmounted(() => player.remove());
            } else if (errorMessage) {
                errorMsg.value = errorMessage;
            }
        })
        .catch((err) => {
            errorMsg.value = err.toString();
        });
</script>

<script lang="ts">
    interface AvvplPlayer {
        on(event: string, cb: (e: any) => void): void;
        load(url: string): void;
        remove(): void;
    }

    type AvvplPlayerConstructor = new (config: any) => AvvplPlayer;

    declare let avvpl: { setupPlayer: AvvplPlayerConstructor };
</script>

<style lang="scss" scoped>
    @import "@/components/liveStream/betRadarStream/BetRadarStream";
</style>
