<template>
    <template v-if="typeof props.config === 'string'">
        {{ props.config ? i18n.t(props.config) : '' }}
    </template>
    <I18nT v-else v-bind:keypath="configKey">
        <template
            v-for="slot in props.config.slots"
            #[slot.name]
            v-bind:key="slot.name"
        >
            <component
                v-bind:is="slot.wrapper?.tag"
                v-if="slot.wrapper"
                v-bind:class="slot.wrapper.class"
                v-bind:style="slot.wrapper.style"
            >
                {{ 'key' in slot ? i18n.t(slot.key) : slot.content }}
            </component>
            <template v-else>
                {{ 'key' in slot ? i18n.t(slot.key) : slot.content }}
            </template>
        </template>
    </I18nT>
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import { computed, toRefs } from 'vue';
    import type { TranslateKeys, TranslateValue } from '@/core/lib/i18n';
    import { i18n } from '@/core/lib/i18n';
    import type { IDynamicI18nConfig } from '@/interface/IDynamicI18nConfig';
    import type { EnumLanguage } from '@/interface/enum';
    import { useCustomerStore } from '@/store/customerStore';

    const props = defineProps<{
        config: string | IDynamicI18nConfig;
    }>();

    const { siteStyle } = storeToRefs(useCustomerStore());
    const { config } = toRefs(props);
    const configKey = computed(() => {
        const i18nConfig = config.value as IDynamicI18nConfig;
        const value = (i18n.messages.value as Record<EnumLanguage, Record<TranslateKeys, TranslateValue>>)[i18n.locale.value][i18nConfig.key];
        if (typeof value === 'object' && siteStyle.value.toLowerCase() in value) {
            return `${i18nConfig.key}.${siteStyle.value.toLowerCase()}`;
        }
        return i18nConfig.key;
    });
</script>
