import { storeToRefs } from 'pinia';
import { useInterval } from '@/composable/useInterval';
import { useLocalStorage } from '@/composable/useLocalStorage';
import { useSendBehaviorToggle } from '@/composable/useSendBehaviorToggle';
import { useState } from '@/composable/useState';
import { Api } from '@/core/lib/api';
import { BehaviorType } from '@/interface/enum';
import type { SendBehaviorsRequest } from '@/interface/pollux';
import { useCustomerStore } from '@/store/customerStore';

type OddsCountInfo = Record<number, number>

interface IOddsQueryPerEventCountInfo {
    countInfo: OddsCountInfo;
    calculatedSeconds: number;
}

export function useOddsQueryPerEventBehavior() {
    const [countInfo, setCountInfo] = useState<OddsCountInfo>({});

    function resetCount() {
        setCountInfo({});
    }

    const { durationSeconds, isSendBehaviorEnabled } = useSendBehaviorToggle(BehaviorType.OddsQueryPerEvent);
    function addCount(eventId: number) {
        if (!isSendBehaviorEnabled.value) return;

        if (countInfo.value[eventId]) {
            countInfo.value[eventId]++;
            return;
        }
        countInfo.value[eventId] = 1;
    }

    const { accountId } = storeToRefs(useCustomerStore());
    const [behaviorStorage, setBehaviorStorage] = useLocalStorage<IOddsQueryPerEventCountInfo | null>(
        `b:${BehaviorType.OddsQueryPerEvent}:${accountId.value}`,
        null,
        {
            decode: v => JSON.parse(v),
            encode: v => JSON.stringify(v),
        },
    );

    const [remainSeconds, setRemainSeconds] = useState(durationSeconds.value);

    if (behaviorStorage.value) {
        if (durationSeconds.value > behaviorStorage.value.calculatedSeconds) {
            setRemainSeconds(durationSeconds.value - behaviorStorage.value.calculatedSeconds);
            setCountInfo(behaviorStorage.value.countInfo);
        }
    }

    function resetRemainSeconds() {
        setRemainSeconds(durationSeconds.value);
    }

    async function sendBehavior() {
        const maxCount = Math.max(...Object.values(countInfo.value));

        if (maxCount < 0) return;

        const request: SendBehaviorsRequest = {
            behaviors: [{
                behaviorType: BehaviorType.toPollux(BehaviorType.OddsQueryPerEvent),
                count: maxCount,
                durationMinutes: Math.ceil(durationSeconds.value / 60),
            }],
        };

        resetCount();
        resetRemainSeconds();
        await Api.sendBehaviors(request);
    }

    const { countdown } = useInterval(
        () => sendBehavior(),
        remainSeconds,
        { isEnabled: isSendBehaviorEnabled },
    );

    window.addEventListener('beforeunload', () => {
        setBehaviorStorage({
            countInfo: countInfo.value,
            calculatedSeconds: durationSeconds.value - countdown.value,
        });
    });

    return {
        addCount,
    };
}
