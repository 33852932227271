import { storeToRefs } from 'pinia';
import { capitalize } from '@/core/lib/string';
import { getOddsApiUrl, getPolluxUrl } from '@/core/lib/url';
import { SiteStyle } from '@/interface/enum';
import { useCustomerStore } from '@/store/customerStore';
import { useToggleStore } from '@/store/toggleStore';

function formatEnv(env: string): string {
    if (env === 'uat') return 'UAT';

    return capitalize(env);
}

export function getApmConfig(): any {
    const { oddsApiUrl } = storeToRefs(useToggleStore());
    const { siteStyle } = storeToRefs(useCustomerStore());
    return {
        serviceName: siteStyle.value === SiteStyle.Euro ? 'Kratos-Euro' : 'Kratos-Asia',
        serviceVersion: `${__ENV_NAME__}-${__COMMIT_SHA__}`,
        serverUrl: '//apmsp.cedexis.cc/',
        distributedTracingOrigins: [
            getOddsApiUrl(oddsApiUrl.value),
            getPolluxUrl(),
        ],
        environment: formatEnv(__ENV_NAME__),
        transactionSampleRate: siteStyle.value === SiteStyle.Euro ? __APM_SAMPLE_RATE_EURO__ : __APM_SAMPLE_RATE_ASIA__,
        flushInterval: 15000,
    };
}
