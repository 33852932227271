import { watchDebounced } from '@vueuse/core';
import type { Ref } from 'vue';
import { computed, watch } from 'vue';
import { BetSlipContext } from '@/components/betSlip/betSlipContext';
import { getTicketBetOptionDisplay } from '@/components/betSlip/ticketBetOptionDisplay';
import { getMarketRule } from '@/components/oddsDisplay/moreMarket/marketRules';
import { useAutoResetState } from '@/composable/useAutoResetState';
import { usePriceChangeStatus } from '@/composable/usePriceChangeStatus';
import { useState } from '@/composable/useState';
import { defineContext } from '@/core/lib/defineContext';
import { i18n } from '@/core/lib/i18n';
import { formatMinDecimalPlace, toFixedDecimalPoint } from '@/core/lib/numberFormat';
import { getMarketGroupId, isCS, isShowPoint, shouldNegatePoint } from '@/core/lib/oddsHelper';
import { formatLiveScore, getScore, isShowLiveScore } from '@/core/oddsApi/helpers/liveScore';
import type { ITicket } from '@/interface/IBetSlip';
import { BetSlipMessagePriority, OddsOption, SportType } from '@/interface/enum';

export const TicketContext = defineContext('TicketContext', (ticket: Ref<ITicket>) => {
    const optionDisplay = computed(() => {
        const result = getTicketBetOptionDisplay(ticket.value);
        result.name = result.name || ticket.value.priceOption.option;
        return result;
    });

    const price = computed(() => ticket.value.priceOption.price);
    const { isPriceChange, priceChangeStatus } = usePriceChangeStatus(price);
    const [oldPrice, setOldPrice] = useState(price.value);
    watch(price, (_, oldVal) => setOldPrice(oldVal));

    const marketRule = computed(() => getMarketRule(ticket.value.odds.marketType));
    const minDecimalPlace = computed(() => marketRule.value.minDecimalPlace);
    const priceDisplay = computed(() => formatMinDecimalPlace(price.value, minDecimalPlace.value));
    const oldPriceDisplay = computed(() => formatMinDecimalPlace(oldPrice.value, minDecimalPlace.value));
    const isNegativePrice = computed(() => price.value < 0);

    const pointDisplay = computed(() => {
        if (!isShowPoint(ticket.value.odds.marketType, getMarketGroupId(ticket.value.odds))) return null;

        const odds = ticket.value.odds;
        const point = shouldNegatePoint(odds.point, odds.marketType, ticket.value.priceOption.option)
            ? -odds.point
            : odds.point;
        return toFixedDecimalPoint(point, 2);
    });
    const [isPointChange, setPointChange] = useAutoResetState(false, 3000);
    watch(pointDisplay, () => setPointChange(true));

    const liveScoreDisplay = computed(() => {
        if (!isShowLiveScore(ticket.value.event.sportType, ticket.value.odds.isLive, ticket.value.odds.marketType)) return null;
        const score = getScore(ticket.value.event.eventResults, getMarketGroupId(ticket.value.odds));
        return score ? formatLiveScore(score.home, score.away, ':') : null;
    });
    const [isScoreChange, setScoreChange] = useAutoResetState(false, 3000);
    watch(liveScoreDisplay, () => setScoreChange(true));

    const isLiveCorrectScoreAos = computed(() => ticket.value.odds.isLive && isCS(ticket.value.odds) && ticket.value.priceOption.option === OddsOption.CS_Others);
    const correctScoreExcludingScores = computed(() => {
        if (!isLiveCorrectScoreAos.value) return null;
        return ticket.value.odds.betCondition.split(',').join(', ');
    });

    const { setBetSlipMessage, subscribeOdds, updateWhenLangChanged } = BetSlipContext.inject();
    subscribeOdds(ticket);

    updateWhenLangChanged(ticket);
    watchDebounced([isPointChange, isPriceChange, isScoreChange], () => {
        if (isPointChange.value) {
            setBetSlipMessage(i18n.t('bet_slip_error_message_point_changed'), BetSlipMessagePriority.PointChange);
        } else if (isPriceChange.value) {
            setBetSlipMessage(i18n.t('bet_slip_error_message_odds_changed'), BetSlipMessagePriority.OddsChange);
        } else if (isScoreChange.value && ticket.value.event.sportType === SportType.Soccer) {
            setBetSlipMessage(i18n.t('bet_slip_error_message_score_changed'), BetSlipMessagePriority.ScoreChange);
        }
    }, { debounce: 100 });

    return {
        optionDisplay,
        pointDisplay,
        isPointChange,
        priceDisplay,
        oldPriceDisplay,
        isPriceChange,
        priceChangeStatus,
        isNegativePrice,
        liveScoreDisplay,
        isScoreChange,
        correctScoreExcludingScores,
    };
});
