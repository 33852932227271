<template>
    <MatchLoading v-if="!loaded" />
    <NoData v-else-if="leaguesByRegion.length === 0" v-bind:dataType="NoDataType.Events" />
    <div v-else class="competitionsContainer">
        <RegionCompetitionsDisplay
            v-for="(region, index) in leaguesByRegion"
            v-bind:key="region.id"
            v-bind:region="region"
            v-bind:initialIsExpanded="index < 6"
        />
    </div>
</template>

<script lang="ts" setup>
    import { groupBy, orderBy } from 'lodash';
    import { computed } from 'vue';
    import MatchLoading from '@/components/euroDisplay/MatchLoading.vue';
    import NoData from '@/components/euroDisplay/NoData.vue';
    import type { ILeaguesByRegion } from '@/components/euroDisplay/RegionCompetitionsDisplay.vue';
    import RegionCompetitionsDisplay from '@/components/euroDisplay/RegionCompetitionsDisplay.vue';
    import { useEuroRoute } from '@/composable/useEuroRoute';
    import { useCurrentLeagues } from '@/core/oddsApi/composable/useCurrentLeagues';
    import { LeagueSize, NoDataType, SportType } from '@/interface/enum';

    const { sportType } = useEuroRoute();

    const { leagues, loaded } = useCurrentLeagues(sportType);

    const regionPriority: Partial<Record<SportType, number[]>> = {
        [SportType.Soccer]: [
            3, // England
            26, // Germany
            31, // Italy
            51, // Spain
            233, // World
            227, // Europe
        ],
        [SportType.Basketball]: [
            145, // USA
            233, // World
            227, // Europe
            51, // Spain
            31, // Italy
            26, // Germany
            24, // France
            27, // Greece
            54, // Turkiye
            83, // Korea Republic
        ],
        [SportType.Football]: [
            145, // USA
            147, // Australia
            119, // Canada
        ],
        [SportType.Hockey]: [
            145, // USA
            233, // World
            227, // Europe
        ],
        [SportType.Badminton]: [
            233, // World
            72, // China
            89, // Malaysia
            77, // Indonesia
            3, // England
            80, // Japan
            83, // Korea Republic
            100, // Singapore
        ],
        [SportType.Pool]: [
            233, // World
            227, // Europe
            72, // China
        ],
        [SportType.MotorSport]: [
            233, // World
        ],
        [SportType.Tennis]: [
            233, // World
            145, // USA
            24, // France
            147, // Australia
            3, // England
        ],
        [SportType.Baseball]: [
            145, // USA
            80, // Japan
            83, // Korea Republic
            233, // World
            227, // Europe
            132, // Mexico
        ],
        [SportType.Volleyball]: [
            233, // World
            228, // Asia
            227, // Europe
            43, // Poland
            31, // Italy
        ],
        [SportType.Golf]: [
            145, // USA
        ],
        [SportType.Boxing]: [
            233, // World
        ],
        [SportType.Cricket]: [
            233, // World
            76, // India
            95, // Pakistan
            147, // Australia
            3, // England
            211, // West Indies
            201, // South Africa
            101, // Sri Lanka
        ],
        [SportType.Rugby]: [
            233, // World
            227, // Europe
            147, // Australia
            3, // England
            24, // France
        ],
        [SportType.Handball]: [
            233, // World
            228, // Asia
            227, // Europe
            229, // Africa
            20, // Denmark
            24, // France
            26, // Germany
            51, // Spain
        ],
        [SportType.Cycling]: [
            233, // World
            24, // France
        ],
        [SportType.Athletics]: [
            233, // World
        ],
        [SportType.BeachSoccer]: [
            233, // World
        ],
        [SportType.Futsal]: [
            233, // World
            227, // Europe
            228, // Asia
            51, // Spain
        ],
        [SportType.Darts]: [
            233, // World
            227, // Europe
            3, // England
        ],
        [SportType.Olympic]: [
            233, // World
        ],
        [SportType.Lacrosse]: [
            145, // USA
        ],
        [SportType.WaterPolo]: [
            233, // World
            227, // Europe
            228, // Asia
        ],
        [SportType.WinterSports]: [
            233, // World
        ],
        [SportType.FieldHockey]: [
            233, // World
            227, // Europe
            228, // Asia
        ],
        [SportType.MixedMartialArts]: [
            233, // World
        ],
        [SportType.AussieRulesFootball]: [
            233, // World
        ],
    };

    const sizePriority = [LeagueSize.Big, LeagueSize.Medium, LeagueSize.Small, LeagueSize.Unknown];

    const leaguesByRegion = computed<ILeaguesByRegion[]>(() => {
        const groupByRegion = groupBy(leagues.value, x => x.region.id);
        const groupArray = Object.values(groupByRegion).map(leagues => ({
            id: leagues[0].region.id,
            name: leagues[0].region.name,
            leagues: orderBy(leagues, [
                x => sizePriority.indexOf(x.size),
                x => x.name,
            ], ['asc']),
        }));
        const priority = regionPriority[sportType.value];
        return orderBy(groupArray, [
            x => (priority?.includes(x.id)
                ? priority.indexOf(x.id)
                : Infinity),
            x => x.name,
        ], ['asc', 'asc']);
    });
</script>

<style lang="scss" scoped>
@import "@/views/euro/betting/sport/Competitions";
</style>
