import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useState } from '@/composable/useState';
import { Api } from '@/core/lib/api';
import type { IExchangeCustomerInfo } from '@/interface/IExchange';
import { ExchangeStatus } from '@/interface/enum';

export const useExchangeCustomerStore = defineStore('ExchangeCustomer', () => {
    const [customerInfo, setCustomerInfo] = useState<IExchangeCustomerInfo | null>(null);
    const [exchangeStatus, setExchangeStatus] = useState<ExchangeStatus>(ExchangeStatus.Unknown);
    const [isCustomerInfoLoading, setIsCustomerLoading] = useState<boolean>(false);

    async function fetchCustomerInfo() {
        try {
            setIsCustomerLoading(true);
            const { status, customerInfo } = await Api.getExchangeCustomerInfo();
            if (status === ExchangeStatus.Success && customerInfo) {
                setCustomerInfo(customerInfo);
            }
            setExchangeStatus(status);
        } catch (e) {
            setExchangeStatus(ExchangeStatus.Error);
            console.error(e);
        } finally {
            setIsCustomerLoading(false);
        }
    }

    const hasDepositBonus = computed(() => customerInfo.value?.hasDepositBonus);
    const hasExceedBettingBudget = computed(() => customerInfo.value?.hasBettingBudget && customerInfo.value.bettingBudget <= 0);

    return {
        customerInfo,
        hasDepositBonus,
        hasExceedBettingBudget,
        fetchCustomerInfo,
        isCustomerInfoLoading,
        setCustomerInfo,
        exchangeStatus,
        setExchangeStatus,
    };
});
