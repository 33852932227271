import { tryOnScopeDispose } from '@vueuse/core';
import { IdleManager } from '@/core/lib/idleManager';

export function useIdle(timeout: number) {
    const idleManager = new IdleManager(timeout);
    tryOnScopeDispose(() => idleManager.destroy());

    return {
        onActive: (callback: () => void) => idleManager.onActive(callback),
        onIdle: (callback: () => void) => idleManager.onIdle(callback),
    };
}
