<template>
    <li
        v-gtm:label="sportInfo.gtmLabel"
        v-gtm:@click
        class="sportList_item"
        v-bind:class="{
            sportList_children_item: isChild,
            active: sportInfo.isActive,
            disabled: hasChild,
            [sportInfo.className ?? '']: !!sportInfo.className,
        }"
        v-on:click="sportInfo.onClick"
    >
        <span class="itemTitle">
            <span class="itemIcon">
                <slot />
            </span>
            {{ sportInfo.name }}
        </span>
        <HotTag v-if="sportInfo.isHot" class="itemHotTag" />
        <Count
            v-else-if="sportInfo.isShowCount"
            class="itemCount"
            v-bind:bgColor="sportInfo.isLive ? 'live' : 'nonLive'"
            v-bind:number="sportInfo.count"
            textColor="white"
            v-bind:borderColor="sportInfo.isLive ? 'live' : 'nonLive'"
        />
    </li>

    <ul v-if="hasChild">
        <li
            v-for="subSport in sportInfo.children"
            v-bind:key="subSport.name"
            v-bind:class="{ active: subSport.isActive }"
            class="sportList_children"
        >
            <SportListItem
                isChild
                v-bind:sportInfo="subSport"
            />
        </li>
    </ul>
</template>

<script lang="ts" setup>
    import type { PropType } from 'vue';
    import Count from '@/components/common/Count.vue';
    import HotTag from '@/components/common/HotTag.vue';
    import type { ISportListItem } from '@/interface/ISport';

    const props = defineProps({
        sportInfo: {
            type: Object as PropType<ISportListItem>,
            required: true,
        },
        isChild: {
            type: Boolean,
            default: false,
        },
    });

    const hasChild = !!props.sportInfo?.children;
</script>

<style lang="scss" scoped>
    @import "@/components/sportList/SportListItem";
</style>
