import { storeToRefs } from 'pinia';
import type { Ref } from 'vue';
import { computed } from 'vue';
import { getMarketRule as getMarketRuleEuro } from '@/components/euroDisplay/oddsDisplay/marketRules';
import { getMyBetOptionDisplay } from '@/components/myBets/myBetOptionDisplay';
import { getMarketRule } from '@/components/oddsDisplay/moreMarket/marketRules';
import { useShowTimeDisplay } from '@/composable/useShowTimeDisplay';
import { useStakeDisplay } from '@/composable/useStakeDisplay';
import { i18n } from '@/core/lib/i18n';
import {
    formatMinDecimalPlace,
    toFixedDecimalPoint,
} from '@/core/lib/numberFormat';
import { convertToPriceStyleDisplay, isCS, isMainMarket, isShowPoint } from '@/core/lib/oddsHelper';
import { getActualStake } from '@/core/lib/ticket';
import { formatLiveScore, scoreUseMainMarket } from '@/core/oddsApi/helpers/liveScore';
import type { IBaseBet } from '@/interface/IBet';
import type { IPolluxRejectReason, IRejectReason } from '@/interface/IRejectReason';
import { OddsOption, PlaceOrderStatus, SiteStyle } from '@/interface/enum';
import { useCustomerStore } from '@/store/customerStore';
import { useToggleStore } from '@/store/toggleStore';

function convertToRejectReason(rejectReason: string): IRejectReason {
    const polluxRejectReason = JSON.parse(rejectReason) as IPolluxRejectReason;
    return {
        status: polluxRejectReason.Status,
        extraInfo: polluxRejectReason.ExtraInfo,
    };
}

const mixParlayFoldsMap: Record<number, string> = {
    1: 'betSlip_comboParlay_option_1',
    2: 'betSlip_comboParlay_option_2',
    3: 'betSlip_comboParlay_option_3',
};

export function mixParlaySelectionsDisplay(ticketCount: number) {
    const { siteStyle } = storeToRefs(useCustomerStore());
    if (siteStyle.value === SiteStyle.Asia) {
        return i18n.t('selections', { n: ticketCount });
    }
    return mixParlayFoldsMap[ticketCount] ? i18n.t(mixParlayFoldsMap[ticketCount]) : i18n.t('betSlip_comboParlay_option_n', { n: ticketCount });
}

export function betBuilderSelectionsDisplay(ticketCount: number) {
    return i18n.t('selections', { n: ticketCount });
}

export function useBetDisplay(bet: Ref<IBaseBet>) {
    const price = computed(() => bet.value.odds);
    const marketRule = getMarketRule(bet.value.marketType);
    const { marketTypeDisplay: marketTypeDisplayEuro } = getMarketRuleEuro(bet.value.marketType);
    const minDecimalPlace = computed(() => marketRule.minDecimalPlace);
    const betOption = computed(() => getMyBetOptionDisplay(bet.value));

    function getMarketTypeTitle() {
        const { siteStyle } = storeToRefs(useCustomerStore());
        if (siteStyle.value === SiteStyle.Asia) {
            const marketTypeName = i18n.t(`market_type_name_${bet.value.marketType}`);
            return bet.value.betTypeGroup.name
                ? `${bet.value.betTypeGroup.name} - ${marketTypeName}`
                : `${marketTypeName}`;
        }
        return marketTypeDisplayEuro(bet.value.betTypeGroup, bet.value.marketType);
    }

    const title = computed(() => {
        const sportType = i18n.t(`sport_type_name_${bet.value.sportType}`);
        const marketTypeTitle = getMarketTypeTitle();
        return `${sportType} - ${marketTypeTitle}`;
    });
    const priceDisplay = computed(() => formatMinDecimalPlace(price.value, minDecimalPlace.value));
    const isNegativePrice = computed(() => price.value < 0);
    const priceStyleDisplay = computed(() => convertToPriceStyleDisplay(bet.value.priceStyle));

    const scoreDisplay = computed(() => {
        if (!isMainMarket(bet.value.betTypeGroup.id) && scoreUseMainMarket.includes(bet.value.betTypeGroup.id)) return null;
        if (bet.value.isOutright) return null;
        if (!bet.value.isLive) return null;
        return formatLiveScore(bet.value.betLiveScore.home, bet.value.betLiveScore.away, ':');
    });

    const stake = computed(() => bet.value.stake);
    const actualStake = computed(() => getActualStake(bet.value.stake, price.value));
    const stakeDisplay = computed(() => useStakeDisplay(stake.value).stakeDisplay);

    const { formatMoneyWithDecimal } = useCustomerStore();
    const stakeWithPrecision = computed(() => formatMoneyWithDecimal(stake.value));
    const actualStakeWithPrecision = computed(() => formatMoneyWithDecimal(actualStake.value));

    const pointDisplay = computed(() => (!isShowPoint(bet.value.marketType, bet.value.betTypeGroup.id) ? null : toFixedDecimalPoint(bet.value.point, 2)));

    const { supportFailedBetReasonSports } = storeToRefs(useToggleStore());
    const isShowRejectReason = computed(() => supportFailedBetReasonSports.value.includes(bet.value.sportType) && bet.value.isWaitingReject && !!bet.value.betCondition);
    const rejectReasonDisplay = computed(() => {
        if (!isShowRejectReason.value) return '';
        const rejectReason = convertToRejectReason(bet.value.betCondition);
        switch (rejectReason.status) {
            case PlaceOrderStatus.LimitExceed:
                return i18n.t('bet_slip_error_message_limit_exceed', { alternateStake: rejectReason.extraInfo });
            case PlaceOrderStatus.LimitExceedTryLater:
                return i18n.t('bet_slip_error_message_limit_exceed_try_later');
            case PlaceOrderStatus.EventClosed:
                return i18n.t('bet_slip_error_message_event_closed');
            case PlaceOrderStatus.PriceChange:
                return i18n.t('bet_slip_error_message_odds_changed');
            case PlaceOrderStatus.ThirdPartyTimeout:
                return i18n.t('bet_slip_error_message_third_party_timeout');
            default:
                return '';
        }
    });

    const isLiveCorrectScoreAos = computed(() => bet.value.isLive && isCS(bet.value.marketType) && bet.value.oddsOptionType === OddsOption.CS_Others);
    const correctScoreExcludingScores = computed(() => {
        if (!isLiveCorrectScoreAos.value) return null;
        return bet.value.betCondition.split(',').join(', ');
    });

    const showTimeDisplay = computed(() => {
        const { date, time } = useShowTimeDisplay(bet.value.showTime, bet.value.showTimeDisplayType);
        return `${date} ${time}`;
    });
    return {
        betOption,
        priceDisplay,
        isNegativePrice,
        priceStyleDisplay,
        title,
        pointDisplay,
        scoreDisplay,
        stakeDisplay,
        stakeWithPrecision,
        actualStakeWithPrecision,
        rejectReasonDisplay,
        correctScoreExcludingScores,
        showTimeDisplay,
    };
}
