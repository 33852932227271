<template>
    <NavbarBlock
        v-bind:items="navBarItems"
        v-bind:marketDropdownPosition="route.name === EuroRoutePage.Sport_Matches_Date
            ? EnumEuroMarketDropdownPosition.Bottom
            : EnumEuroMarketDropdownPosition.None"
    >
        <template #title>
            <SportIcon
                v-bind:sportType="sportType"
                colorful
                size="lg"
                class="navbarSportList_icon"
            />
            {{ i18n.t(`sport_type_name_${sportType}`) }}
        </template>
    </NavbarBlock>
    <RouterView />
</template>

<script lang="ts" setup>
    import { computed, ref } from 'vue';
    import { useRouter } from 'vue-router';
    import SportIcon from '@/components/common/SportIcon.vue';
    import { EuroDisplayContext } from '@/components/euroDisplay/EuroDisplayContext';
    import type { INavBarItem } from '@/components/navbarBlock/NavbarBlock.Euro.vue';
    import NavbarBlock from '@/components/navbarBlock/NavbarBlock.Euro.vue';
    import { useEuroRoute } from '@/composable/useEuroRoute';
    import { i18n } from '@/core/lib/i18n';
    import { EnumEuroMarketDropdownPosition, EuroDate, EuroRoutePage } from '@/interface/enum';

    const router = useRouter();
    const { route, sportType, date } = useEuroRoute();

    EuroDisplayContext.provide(sportType, ref(false), date);

    const navBarItems = computed<INavBarItem[]>(() => [
        {
            title: i18n.t('market_page_matches'),
            isActive: () => route.name === EuroRoutePage.Sport_Matches_Date,
            onClick: () => {
                router.push({ name: EuroRoutePage.Sport_Matches });
            },
            subItems: [
                {
                    title: i18n.t('date_filter_0'),
                    isActive: () => date.value === EuroDate.Today,
                    onClick: () => {
                        router.push({ name: EuroRoutePage.Sport_Matches_Date, params: { date: EuroDate.toRoute(EuroDate.Today) } });
                    },
                },
                {
                    title: i18n.t('date_filter_1'),
                    isActive: () => date.value === EuroDate.Tomorrow,
                    onClick: () => {
                        router.push({ name: EuroRoutePage.Sport_Matches_Date, params: { date: EuroDate.toRoute(EuroDate.Tomorrow) } });
                    },
                },
                {
                    title: i18n.t('market_page_future'),
                    isActive: () => date.value === EuroDate.Future,
                    onClick: () => {
                        router.push({ name: EuroRoutePage.Sport_Matches_Date, params: { date: EuroDate.toRoute(EuroDate.Future) } });
                    },
                },
            ],
            gtmLabel: 'Matches',

        },
        {
            title: i18n.t('market_page_competitions'),
            isActive: () => route.name === EuroRoutePage.Sport_Competitions,
            onClick: () => {
                router.push({ name: EuroRoutePage.Sport_Competitions });
            },
            gtmLabel: 'Competitions',

        },
        {
            title: i18n.t('market_page_name_5'),
            isActive: () => route.name === EuroRoutePage.Sport_Outright,
            onClick: () => {
                router.push({ name: EuroRoutePage.Sport_Outright });
            },
            gtmLabel: 'Outright',

        },
    ]);
</script>

<style lang="scss" scoped>
    .navbarSportList_icon {
        margin-right: 8px;
    }
</style>
