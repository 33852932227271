<template>
    <div class="form form-input stakeGroup">
        <input
            ref="stakeInputElement"
            v-money="formatStakeConfig"
            v-memo="[stakeDisplay, () => i18n.locale]"
            type="text"
            class="input input-stake"
            v-bind:disabled="disabled"
            v-bind:value="stakeDisplay"
            v-bind:class="{ 'stakeGroup_input-hasSuffix': hasMultiplier }"
            v-bind:placeholder="i18n.t('stake')"
            v-bind:maxlength="stakeInputMaxLength"
            v-on:change="onChange"
            v-on:keyup.enter="emit('enter', $event)"
        >
        <div v-if="hasMultiplier" class="stakeGroup_suffix">
            ,000
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { ref } from 'vue';
    import { useStakeConfig } from '@/composable/useStakeDisplay';
    import { i18n } from '@/core/lib/i18n';

    const props = withDefaults(defineProps<{
        disabled?: boolean;
        stakeDisplay: string;
        setDisplayToStake: (value: string) => void;
    }>(), {
        disabled: false,
    });

    const emit = defineEmits(['enter']);

    const {
        formatStakeConfig,
        hasMultiplier,
        stakeInputMaxLength,
    } = useStakeConfig();

    const stakeInputElement = ref<HTMLInputElement>();

    const onChange = (event: Event) => {
        const newStakeDisplay = (event.target as HTMLInputElement).value;
        // avoid trigger change event when the value is set by the ref change
        if (props.stakeDisplay === newStakeDisplay) {
            return;
        }

        props.setDisplayToStake(newStakeDisplay);
    };
</script>

<style lang="scss" scoped>
    .input-stake {
        width: 100%;
        padding: var(--spacers-md) var(--spacers-lg);
        border-radius: var(--borderRadius-md);
        color: var(--brandColor-900);
    }

    .input-stake::placeholder {
        text-align: right;
    }

    .input-stake:focus {
        border-color: var(--brandColor-700);
    }

    .stakeGroup {
        position: relative;

        &_input-hasSuffix.input {
            padding-right: 27px;
        }

        &_suffix {
            position: absolute;
            right: 5px;
            caret-color: $brandColor;
            color: $bodyColor;
            font-weight: $fontWeight-bold;
            user-select: none;

            .input:disabled + & {
                color: $formDisabledColor;
            }
        }
    }
</style>
