const liveStreamWindow = {
    name: 'LiveStream',
    config: 'scrollbars=no,resizable=no,width=830,height=352',
};

let count = 0;
const maxWindowCount = 5;

export function openLiveStream(eventId?: number) {
    const url = `/live-stream${eventId ? `/${eventId}` : ''}`;
    window.open(url, `${liveStreamWindow.name} ${count++ % maxWindowCount}`, liveStreamWindow.config);
}
