import { Api } from '@/core/lib/api';

export function useBackToClassicDesktop() {
    async function backToClassicDesktop() {
        const { data } = await Api.toClassicDesktop();
        if (data.redirectUrl) {
            window.location.href = data.redirectUrl;
        }
    }

    return {
        backToClassicDesktop,
    };
}
