<template>
    <div v-bind:class="locale">
        <RouterView />
    </div>
</template>

<script lang="ts" setup>
    import { useAdBlock } from '@/composable/useAdBlock';
    import { useNikeBackdoor } from '@/composable/useBackdoor';
    import { i18n } from '@/core/lib/i18n';

    useAdBlock();
    useNikeBackdoor();

    const { locale } = i18n;
</script>
