<template>
    <div class="cashOut" v-bind:class="`cashOut-${props.width}`">
        <div class="maxPayOut">
            <span>
                {{ i18n.t('cash_out_max_payout') }}
            </span>
            <span class="maxPayOut_stake">
                <CurrencyIcon size="xs" /> {{ maxPayout }}
            </span>
        </div>
        <div v-if="isShowCashOutButton" class="cashOutBtnWrapper">
            <VButton
                v-if="cashOutService.status === CashOutStatus.Confirm"
                v-gtm:label="'CashOut_Cancel'"
                v-gtm:@click
                v-on:click="cashOutService.cancel()"
            >
                <SvgIcon name="icon-system-close" size="sm" />
            </VButton>
            <VButton
                v-gtm:@click
                v-gtm:label="cashOutButton.gtm"
                block
                v-bind:color="cashOutButton.color"
                v-bind:hoverTextColor="cashOutButton.hoverTextColor"
                v-bind:textColor="cashOutButton.textColor"
                v-bind:disabled="isCashOutButtonDisabled"
                v-on:click="cashOutButton.onClick"
            >
                <span class="cashOutBtn">
                    <span v-if="isCashOutButtonDisabled" class="cashOutBtn_item">
                        <SvgIcon name="icon-system-cash-out" />
                        {{ i18n.t('cash_out_btn_unavailable') }}
                    </span>
                    <template v-else>
                        <span class="cashOutBtn_item">
                            <SvgIcon name="icon-system-cash-out" />
                            {{ cashOutButtonText }}
                        </span>
                        <span class="cashOutBtn_item">
                            <CurrencyIcon size="xs" />
                            {{ cashOutValueDisplay }}
                        </span>
                    </template>
                </span>
            </VButton>
        </div>
        <CashOutMessage
            v-if="isShowCashOutMessage"
            v-bind:isShow="isShowCashOutMessage"
            v-bind:cashOutStatus="cashOutService.status"
            v-bind:isMiniMyBet="isMiniMyBet"
        />
    </div>
</template>

<script lang="ts" setup>
    import type { PropType } from 'vue';
    import { computed, toRefs, watch } from 'vue';
    import CashOutMessage from '@/components/cashOut/CashOutMessage.vue';
    import type { CashOutService } from '@/components/cashOut/cashOutService';
    import CurrencyIcon from '@/components/common/CurrencyIcon.vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import VButton from '@/components/common/button/VButton.vue';
    import { i18n } from '@/core/lib/i18n';
    import { getMaxPayout } from '@/core/lib/ticket';
    import type { IBet } from '@/interface/IBet';
    import type { ICashOutButton } from '@/interface/ICashOut';
    import { CashOutStatus } from '@/interface/enum';
    import { useCustomerStore } from '@/store/customerStore';

    const props = defineProps({
        bet: {
            type: Object as PropType<IBet>,
            required: true,
        },
        cashOutService: {
            type: Object as PropType<CashOutService>,
            required: true,
        },
        width: {
            type: String,
            default: '',
        },
        isMiniMyBet: {
            type: Boolean,
            required: true,
        },
    });

    const { bet } = toRefs(props);

    const isShowCashOutMessage = computed(() => [
        CashOutStatus.Success,
        CashOutStatus.Processing,
        CashOutStatus.Error,
        CashOutStatus.PriceError,
        CashOutStatus.SettlementInProgressError,
    ].includes(props.cashOutService.status));

    const { formatMoneyWithDecimal } = useCustomerStore();

    const cashOutValueDisplay = computed(() => {
        const cashOutValue = props.cashOutService.getCashOutValue();
        return formatMoneyWithDecimal(cashOutValue);
    });
    const cashOutButtonText = computed(() => (props.cashOutService.status === CashOutStatus.Confirm ? i18n.t('mini_my_bets_confirm') : i18n.t('mini_my_bets_cash_out')));

    const maxPayout = computed(() => {
        const payout = getMaxPayout(bet.value.stake, bet.value.odds, bet.value.priceStyle);
        return formatMoneyWithDecimal(payout);
    });

    const isShowCashOutButton = computed(() => ![CashOutStatus.Success, CashOutStatus.Processing].includes(props.cashOutService.status));

    const isCashOutButtonDisabled = computed(() => [
        CashOutStatus.Error,
        CashOutStatus.PriceError,
        CashOutStatus.SettlementInProgressError,
        CashOutStatus.UnAvailable,
    ].includes(props.cashOutService.status));

    const cashOutButton = computed<ICashOutButton>(() => {
        if (props.cashOutService.status === CashOutStatus.Confirm) {
            return {
                color: 'accent',
                hoverTextColor: 'primary',
                textColor: 'primary',
                gtm: 'CashOut_Confirm',
                onClick: () => props.cashOutService.confirmCashOut(),
            };
        }
        return {
            color: 'primary',
            hoverTextColor: 'white',
            textColor: 'white',
            gtm: props.cashOutService.status === CashOutStatus.Available ? 'Onboarding_CashOut' : 'CashOut_Disable',
            onClick: () => props.cashOutService.cashOut(),
        };
    });

    watch(() => props.cashOutService.cashOutValue, (newValue, _) => {
        if (props.cashOutService.status === CashOutStatus.UnAvailable && newValue > 0) {
            props.cashOutService.goAvailable();
            return;
        }
        if (props.cashOutService.status === CashOutStatus.Available && newValue === 0) {
            props.cashOutService.goUnAvailable();
        }
    }, { immediate: true });
</script>

<style lang="scss" scoped>
    @import "@/components/cashOut/CashOut";
</style>
