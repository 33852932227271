import { storeToRefs } from 'pinia';
import type { RouteRecordRaw } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';
import { _usePromotionZone } from '@/composable/usePromotionZone';
import { NonNull } from '@/core/lib/array';
import type { IQueryPageRouteMap } from '@/interface/IQueryPageRouteMap';
import { AsiaRoutePage, EuroDate, EuroRoutePage, PromotionZone, QueryPage, RoutePage, SiteStyle, SportType } from '@/interface/enum';
import { useAsiaSportMarketInfoStore } from '@/store/asiaSportMarketInfoStore';
import { useLiveStreamStore } from '@/store/liveStreamStore';
import { useSettledBetsStore } from '@/store/settledBetsStore';
import { useToggleStore } from '@/store/toggleStore';
import Exchange from '@/views/Exchange.vue';
import LiveStream from '@/views/LiveStream.vue';
import MyBets from '@/views/MyBets.vue';
import Preferences from '@/views/Preferences.vue';
import Results from '@/views/Results.vue';
import SettledBets from '@/views/SettledBets.vue';
import AsiaHome from '@/views/asia/Home.vue';
import AsiaLayout from '@/views/asia/Layout.vue';
import EuroHome from '@/views/euro/Home.vue';
import EuroLayout from '@/views/euro/Layout.vue';
import InPlay from '@/views/euro/betting/InPlay.vue';
import League from '@/views/euro/betting/League.vue';
import MatchDetail from '@/views/euro/betting/MatchDetail.vue';
import Multiples from '@/views/euro/betting/Multiples.vue';
import Region from '@/views/euro/betting/Region.vue';
import Sport from '@/views/euro/betting/Sport.vue';
import LeagueMatches from '@/views/euro/betting/league/Matches.vue';
import RegionMatches from '@/views/euro/betting/region/Matches.vue';
import SportCompetitions from '@/views/euro/betting/sport/Competitions.vue';
import SportMatches from '@/views/euro/betting/sport/Matches.vue';
import SportOutright from '@/views/euro/betting/sport/Outright.vue';

declare module 'vue-router' {
    interface RouteMeta {
        breadcrumbs?: EuroRoutePage[];
    }
}

const commonRoutes: Array<RouteRecordRaw> = [
    {
        path: '/live-stream/:selectedEventId?',
        name: RoutePage.LiveStream,
        component: LiveStream,
        beforeEnter: (to, from, next) => {
            const { setSelectedEventId } = useLiveStreamStore();
            setSelectedEventId(Number(to.params.selectedEventId));
            next();
        },
    },
    {
        path: '/results',
        name: RoutePage.Results,
        component: Results,
    },
    {
        path: '/my-bets',
        name: RoutePage.MyBets,
        component: MyBets,
    },
    {
        path: '/settled-bets',
        name: RoutePage.SettledBets,
        component: SettledBets,
        beforeEnter: (to, from, next) => {
            const { setDate } = useSettledBetsStore();
            setDate(to.query.date as string);
            next();
        },
    },
    {
        path: '/preferences',
        name: RoutePage.Preferences,
        component: Preferences,
    },
];

const asiaRoutes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: AsiaRoutePage.Layout,
        component: AsiaLayout,
        children: [
            {
                path: '/exchange',
                name: RoutePage.Exchange,
                component: Exchange,
                meta: {
                    isDisabled() {
                        const { isFairExchangeEnabled } = storeToRefs(useToggleStore());
                        return !isFairExchangeEnabled.value;
                    },
                },
            },
            {
                path: '',
                name: AsiaRoutePage.Home,
                component: AsiaHome,
            },
        ],
        beforeEnter: async (to, from, next) => {
            const { setPromotionZone } = useAsiaSportMarketInfoStore();
            await setPromotionZone(PromotionZone.None);
            next();
        },
    },
    {
        path: '/:pathMatch(.*)*',
        name: RoutePage.NotFound,
        redirect: { name: AsiaRoutePage.Home },
    },
];

const euroRoutes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: EuroRoutePage.Layout,
        component: EuroLayout,
        children: [
            {
                path: '/',
                name: EuroRoutePage.Home,
                component: EuroHome,
                meta: {
                    breadcrumbs: [
                        EuroRoutePage.Home,
                    ],
                },
            },
            {
                path: '/in-play/:sport?',
                name: EuroRoutePage.InPlay,
                component: InPlay,
                meta: {
                    breadcrumbs: [
                        EuroRoutePage.Home,
                        EuroRoutePage.InPlay,
                    ],
                },
            },
            {
                path: '/multiples/:sport?',
                name: EuroRoutePage.Multiples,
                component: Multiples,
                meta: {
                    breadcrumbs: [
                        EuroRoutePage.Home,
                        EuroRoutePage.Multiples,
                    ],
                },
            },
            {
                path: '/multiples/:sport/:eventId(\\d+)/:eventName?',
                name: EuroRoutePage.Multiples_MatchDetail,
                component: MatchDetail,
                meta: {
                    breadcrumbs: [
                        EuroRoutePage.Home,
                        EuroRoutePage.Multiples,
                        EuroRoutePage.MatchDetail,
                    ],
                },
            },
            {
                path: '/exchange',
                name: EuroRoutePage.Exchange,
                component: Exchange,
                meta: {
                    isDisabled() {
                        const { isFairExchangeEnabled } = storeToRefs(useToggleStore());
                        return !isFairExchangeEnabled.value;
                    },
                    breadcrumbs: [
                        EuroRoutePage.Home,
                        EuroRoutePage.Exchange,
                    ],
                },
            },
            {
                path: '/:sport',
                name: EuroRoutePage.Layout_Sport,
                component: Sport,
                meta: {
                    breadcrumbs: [
                        EuroRoutePage.Home,
                        EuroRoutePage.Sport,
                    ],
                },
                beforeEnter: to => (SportType.fromRoute(to.params.sport.toString()) === SportType.Unknown
                    ? { name: EuroRoutePage.NotFound }
                    : undefined),
                children: [
                    {
                        path: '/:sport',
                        name: EuroRoutePage.Sport,
                        redirect: to => ({
                            name: EuroRoutePage.Sport_Matches,
                            params: {
                                ...to.params,
                            },
                        }),
                    },
                    {
                        path: '/:sport/matches',
                        name: EuroRoutePage.Sport_Matches,
                        redirect: to => ({
                            name: EuroRoutePage.Sport_Matches_Date,
                            params: {
                                ...to.params,
                                date: EuroDate.toRoute(EuroDate.Today),
                            },
                        }),
                    },
                    {
                        path: '/:sport/matches/:date',
                        name: EuroRoutePage.Sport_Matches_Date,
                        component: SportMatches,
                    },
                    {
                        path: '/:sport/competitions',
                        name: EuroRoutePage.Sport_Competitions,
                        component: SportCompetitions,
                    },
                    {
                        path: '/:sport/outright',
                        name: EuroRoutePage.Sport_Outright,
                        component: SportOutright,
                    },
                ],
            },
            {
                path: '/:sport/:region(\\d+)/:regionName?',
                name: EuroRoutePage.Layout_Region,
                component: Region,
                meta: {
                    breadcrumbs: [
                        EuroRoutePage.Home,
                        EuroRoutePage.Sport,
                        EuroRoutePage.Region,
                    ],
                },
                children: [
                    {
                        path: '/:sport/:region(\\d+)/:regionName?',
                        name: EuroRoutePage.Region,
                        redirect: to => ({
                            name: EuroRoutePage.Region_Matches,
                            params: {
                                ...to.params,
                            },
                        }),
                    },
                    {
                        path: '/:sport/:region(\\d+)/:regionName?/matches',
                        name: EuroRoutePage.Region_Matches,
                        redirect: to => ({
                            name: EuroRoutePage.Region_Matches_Date,
                            params: {
                                ...to.params,
                                date: EuroDate.toRoute(EuroDate.Today),
                            },
                        }),
                    },
                    {
                        path: '/:sport/:region(\\d+)/:regionName?/matches/:date',
                        name: EuroRoutePage.Region_Matches_Date,
                        component: RegionMatches,
                    },
                ],
            },
            {
                path: '/:sport/:region(\\d+)/:regionName?/:league(\\d+)/:leagueName?',
                name: EuroRoutePage.Layout_League,
                component: League,
                meta: {
                    breadcrumbs: [
                        EuroRoutePage.Home,
                        EuroRoutePage.Sport,
                        EuroRoutePage.Region,
                        EuroRoutePage.League,
                    ],
                },
                children: [
                    {
                        path: '/:sport/:region(\\d+)/:regionName?/:league(\\d+)/:leagueName?',
                        name: EuroRoutePage.League,
                        redirect: to => ({
                            name: EuroRoutePage.League_Matches,
                            params: {
                                ...to.params,
                            },
                        }),
                    },
                    {
                        path: '/:sport/:region(\\d+)/:regionName?/:league(\\d+)/:leagueName?/matches',
                        name: EuroRoutePage.League_Matches,
                        component: LeagueMatches,
                    },
                ],
            },
            {
                path: '/:sport/:region(\\d+)/:regionName?/:league(\\d+)/:leagueName?/:eventId(\\d+)/:eventName?',
                name: EuroRoutePage.MatchDetail,
                component: MatchDetail,
                meta: {
                    breadcrumbs: [
                        EuroRoutePage.Home,
                        EuroRoutePage.Sport,
                        EuroRoutePage.Region,
                        EuroRoutePage.League,
                        EuroRoutePage.MatchDetail,
                    ],
                },
            },
        ],
    },
    {
        path: '/:pathMatch(.*)*',
        name: EuroRoutePage.NotFound,
        redirect: { name: EuroRoutePage.Home },
    },
];

const queryPageRouteMap: IQueryPageRouteMap = {
    [SiteStyle.Euro]: {
        [QueryPage.Football]: { name: EuroRoutePage.Sport_Matches, params: { sport: SportType.toRoute(SportType.Soccer) } },
        [QueryPage.EFootball]: { name: EuroRoutePage.Sport_Matches, params: { sport: SportType.toRoute(SportType.Soccer) } },
        [QueryPage.SportsCup]: { name: EuroRoutePage.Sport_Matches, params: { sport: SportType.toRoute(SportType.Soccer) } },
    },
    [SiteStyle.Asia]: {
        [QueryPage.Football]: { name: AsiaRoutePage.Home },
        [QueryPage.EFootball]: { name: AsiaRoutePage.EFootballPage },
        [QueryPage.SportsCup]: { name: AsiaRoutePage.SportsCupPage },
    },
};

export const routerFactory = (siteStyle: SiteStyle) => {
    const promotionRoutes: Array<RouteRecordRaw> = Object.values(PromotionZone).map((_promotionZone) => {
        const promotionZone = _promotionZone as PromotionZone;
        const promotionZoneConfig = _usePromotionZone().getPromotionZoneConfig(promotionZone);
        if (!promotionZoneConfig?.enabled) return null;

        return {
            path: promotionZoneConfig.route,
            name: promotionZoneConfig.routePage ?? `promotion_zone_${PromotionZone[promotionZone]}`,
            component: AsiaLayout,
            children: [
                {
                    path: '',
                    name: `promotion_zone_${PromotionZone[promotionZone]}`,
                    component: AsiaHome,
                },
            ],
            beforeEnter: async (_to, _from, next) => {
                const { setPromotionZone } = useAsiaSportMarketInfoStore();
                await setPromotionZone(promotionZone);
                next();
            },
        } satisfies RouteRecordRaw;
    }).filter(NonNull);

    const router = createRouter({
        history: createWebHistory(),
        routes: siteStyle === SiteStyle.Euro
            ? [
                ...euroRoutes,
                ...commonRoutes,
            ]
            : [
                ...asiaRoutes,
                ...commonRoutes,
                ...promotionRoutes,
            ],
        scrollBehavior(to, from, savedPosition) {
            if (savedPosition) {
                return savedPosition;
            }
            return {
                top: 0,
                behavior: 'smooth',
            };
        },
    });

    router.beforeEach((to, from, next) => {
        const isDisabled = typeof to.meta?.isDisabled === 'function'
            ? to.meta?.isDisabled()
            : to.meta?.isDisabled;
        if (isDisabled) {
            next({ name: siteStyle === SiteStyle.Asia ? AsiaRoutePage.Home : EuroRoutePage.Home });
            return;
        }

        const cta = to.query.p as QueryPage;

        if (queryPageRouteMap[siteStyle][cta]) {
            next(queryPageRouteMap[siteStyle][cta]);
        } else {
            next();
        }
    });

    return router;
};
