<template>
    <div
        class="moreMarket"
        v-bind:class="{
            isLive: event.isLive,
        }"
    >
        <template
            v-for="groupedMarket in groupedMarkets"
            v-bind:key="groupedMarket.key"
        >
            <BetBuilderBetType
                v-bind:event="event"
                v-bind:markets="groupedMarket.value"
            />
        </template>
        <StaticSpinner v-if="loading" />
    </div>
</template>

<script lang="ts" setup>
    import groupBy from 'lodash/groupBy';
    import { computed } from 'vue';
    import StaticSpinner from '@/components/common/StaticSpinner.vue';
    import BetBuilderBetType from '@/components/oddsDisplay/moreMarket/marketType/BetBuilderBetType.vue';
    import { orderGroupedMarkets } from '@/core/oddsApi/helpers/formatBetBuilder';
    import type { IEvent } from '@/interface/IEvent';
    import type { IBetBuilderMarket } from '@/interface/betBuilder';

    const props = defineProps<{
        loading: boolean;
        event: IEvent;
        markets: IBetBuilderMarket[];
    }>();

    const groupedMarkets = computed(() => {
        const grouped = groupBy(props.markets, market => `${market.marketType.name}_${market.marketType.id}_${market.matchStatType.id}`);
        const pairs = Object.entries(grouped).map(([key, value]) => ({ key, value }));
        return orderGroupedMarkets(pairs);
    });
</script>

<style lang="scss" scoped>
    @import "@/components/oddsDisplay/moreMarket/MoreMarket";
</style>
