<template>
    <div class="item">
        <ToggleSwitch
            v-gtm:label="switchSingleAndMixParlay.gtmLabel"
            v-gtm:@click
            v-bind:modelValue="isMixParlay"
            v-bind:text="i18n.t('market_page_name_4')"
            v-on:update:modelValue="switchSingleAndMixParlay.onClick"
        />
    </div>
    <NavbarDivider />
    <VButton
        v-gtm:label="'Results'"
        v-gtm:@click
        v-on:click="openResultsPage"
    >
        <template #icon>
            <SvgIcon
                left
                name="icon-system-result-outline"
                size="lg"
            />
        </template>
        {{ i18n.t('results') }}
    </VButton>
    <NavbarDivider />
    <VButton
        v-tooltip="i18n.t('live_stream_tooltip')"
        v-gtm:label="'LiveStream'"
        v-gtm:@click
        v-on:click="openLiveStream()"
    >
        <SvgIcon name="icon-system-tvPlay" size="lg" />
    </VButton>

    <template v-if="isScoreAlertEnabled">
        <NavbarDivider />
        <VButton
            v-tooltip="scoreAlert.tooltip"
            v-gtm:label="scoreAlert.gtmLabel"
            v-gtm:@click
            v-on:click="updatePlaySound"
        >
            <SvgIcon
                v-bind:name="scoreAlert.icon"
                size="lg"
            />
        </VButton>
    </template>

    <NavbarDivider />
    <VButton>
        <SvgIcon name="icon-system-setting" size="lg" />
        <SvgIcon
            name="icon-system-arrow"
            v-bind:rotated="!dropdownHovered"
            right
        />
        <Dropdown
            v-bind:openOnHover="!IS_MOBILE"
            v-bind:openOnClick="IS_MOBILE"
            v-on:onOpen="dropdownHovered = true"
            v-on:onClose="dropdownHovered = false"
        >
            <DropdownMenu v-bind:itemGroups="dropdownGroups" />
        </Dropdown>
    </VButton>
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import { computed, ref } from 'vue';
    import { useRouter } from 'vue-router';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import VButton from '@/components/common/button/VButton.vue';
    import Dropdown from '@/components/common/dropdown/Dropdown.vue';
    import DropdownMenu from '@/components/common/dropdown/DropdownMenu.vue';
    import NavbarDivider from '@/components/common/navbar/NavbarDivider.vue';
    import ToggleSwitch from '@/components/common/toggleSwitch/ToggleSwitch.vue';
    import { useSettingFunction } from '@/components/navbarBlock/asia/useSettingFunctions';
    import { i18n } from '@/core/lib/i18n';
    import { openLiveStream } from '@/core/lib/liveStream';
    import { isMobile } from '@/core/lib/utils';
    import { MarketPage, PromotionZone, SiteStyle } from '@/interface/enum';
    import { useAsiaSportMarketInfoStore } from '@/store/asiaSportMarketInfoStore';
    import { useCustomerStore } from '@/store/customerStore';
    import { useToggleStore } from '@/store/toggleStore';

    const { setDefaultMarketPage, setPromotionZone } = useAsiaSportMarketInfoStore();

    const { isMixParlay } = storeToRefs(useAsiaSportMarketInfoStore());
    const { setMarketPage } = useAsiaSportMarketInfoStore();

    const { isOpenScoreAlert } = storeToRefs(useCustomerStore());
    const { updatePlaySound } = useCustomerStore();

    const { isScoreAlertEnabled } = storeToRefs(useToggleStore(SiteStyle.Asia));

    const scoreAlert = computed(() => {
        if (isOpenScoreAlert.value) {
            return {
                tooltip: i18n.t('score_alert_off_tooltip'),
                gtmLabel: 'SoundOff',
                icon: 'icon-system-soundOn',
            };
        }
        return {
            tooltip: i18n.t('score_alert_on_tooltip'),
            gtmLabel: 'SoundOn',
            icon: 'icon-system-soundOff',
        };
    });

    const router = useRouter();
    const switchSingleAndMixParlay = computed(() => {
        if (isMixParlay.value) {
            return {
                onClick: () => setDefaultMarketPage(),
                gtmLabel: 'SingleMode',
            };
        }
        return {
            onClick: async () => {
                router.push('/');
                await setPromotionZone(PromotionZone.None);
                setMarketPage(MarketPage.MixParlay);
            },
            gtmLabel: 'MixParlay',
        };
    });

    const IS_MOBILE = isMobile();
    const { dropdownGroups } = useSettingFunction();
    const dropdownHovered = ref(false);

    const openResultsPage = () => {
        window.open('/results', 'Results', 'width=830,height=600');
    };

</script>
