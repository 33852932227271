import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useCountdown } from '@/composable/useCountdown';
import { MainBetStatus, SiteStyle } from '@/interface/enum';
import { useMiniMyBetsStore } from '@/store/miniMyBetsStore';
import { useToggleStore } from '@/store/toggleStore';

export function useRefresher() {
    const _refresh = async (isThrottled: boolean, useCache: boolean) => {
        const { refreshMyBets, refreshMyBetsThrottled } = useMiniMyBetsStore();
        if (isThrottled) {
            await refreshMyBetsThrottled(useCache);
        } else {
            await refreshMyBets(useCache);
        }
    };

    const { miniMyBets } = storeToRefs(useMiniMyBetsStore());
    const { miniMyBetRefreshSeconds, miniMyBetWaitingRefreshSeconds } = storeToRefs(useToggleStore(SiteStyle.Asia));
    const refreshDuration = computed(() => {
        const hasWaitingBet = miniMyBets.value.some(x => x.mainBetStatus === MainBetStatus.Waiting);
        return hasWaitingBet ? miniMyBetWaitingRefreshSeconds.value : miniMyBetRefreshSeconds.value;
    });

    const { isLoading: isRefreshing, countdown, trigger: refresh, forceTrigger: forceRefresh } = useCountdown(
        refreshDuration,
        _refresh,
        { minLoadingTime: 1000 },
    );

    return {
        countdown,
        refresh,
        forceRefresh,
        isRefreshing,
    };
}
