<template>
    <div v-resize="onResize" class="settledBets">
        <BetsTable
            v-bind:bets="settledBets"
            v-bind:isLoaded="isLoaded"
            v-bind:statusFilter="MyBetsStatusFilter.Settled"
            v-bind:isMyBets="false"
        />
    </div>
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import BetsTable from '@/components/myBets/BetsTable.vue';
    import type { SizeInfo } from '@/directives/resize';
    import { MyBetsStatusFilter } from '@/interface/enum';
    import { useSettledBetsStore } from '@/store/settledBetsStore';

    const { isLoaded, settledBets } = storeToRefs(useSettledBetsStore());

    let previousHeight = 0;
    const onResize = ({ height }: SizeInfo) => {
        if (previousHeight === height) return;

        previousHeight = height;
        window.parent.postMessage({
            location: window.location.href,
            height: Math.ceil(height),
        }, '*');

        /** sample usage
        window.addEventListener('message', ({ data }) => {
            const iframe = window.document.querySelector(`iframe[src='${data.location}']`) as HTMLIFrameElement;
            iframe.style.height = `${data.height}px`;
        });
        */
    };
</script>
