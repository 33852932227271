import type { OutrightTypeFragment } from '@/core/oddsApi/oddsApiType';
import type { IOutrightOdds } from '@/interface/IOutrightOdds';

export function formatOutrightOdds(item: OutrightTypeFragment): IOutrightOdds {
    return {
        id: item.id,
        eventCode: item.eventCode,
        eventDate: item.eventDate,
        isLive: item.isLive,
        isWalkinOnly: item.isWalkinOnly,
        price: item.price,
        teamName: item.team?.teamName ?? '',
    };
}
