import { createInstance } from '@/core/lib/api';
import { getCasinoSubDomainUrl } from '@/core/lib/url';

export const casinoApiInstance = createInstance(`${getCasinoSubDomainUrl()}/api`);

interface CasinoPopUpResult {
    errorCode: number;
    isPopupMessage: boolean;
    popupMessage: string;
}

interface OpenCasinoGameResult {
    gameUrl: string;
}

export const CasinoApi = {
    enrollCasino(promoCode: string) {
        return casinoApiInstance.get(`enroll?promoCode=${promoCode}`, {})
            .catch(() => ({
                errorCode: 1,
            }));
    },
    getCasinoPopUpMessage(productType: number, platForm: number) {
        return casinoApiInstance.post<CasinoPopUpResult>('game/GetPopUpMessage', {
            productType,
            platForm,
        });
    },
    openCasinoGame(productType: number, gameForm: number, autoJoinType: number) {
        return casinoApiInstance.get<OpenCasinoGameResult>(`game/launchCasinoUrl?productType=${productType}&gameFrom=${gameForm}&autoJoinType=${autoJoinType}`, {});
    },
};
