import orderBy from 'lodash/orderBy';
import { storeToRefs } from 'pinia';
import { useSportsOrder } from '@/composable/useSportsOrder';
import { useUrl } from '@/composable/useUrl';
import { getEsportsImUrl } from '@/core/lib/url';
import type { IMarketInfo } from '@/interface/IMarketInfo';
import type { ISportInfo } from '@/interface/ISport';
import { EventDateType, MarketPage, PlayerType, SportType } from '@/interface/enum';
import { SportInfo } from '@/models/SportInfo';
import { useCustomerStore } from '@/store/customerStore';
import { useToggleStore } from '@/store/toggleStore';

export function createMarketInfoIfNotExist(list: IMarketInfo[], sportType: SportType, marketPage: MarketPage) {
    const market = list.find(x => x.marketPage === marketPage);

    if (!market) {
        list.push({
            sportType,
            marketPage,
            date: EventDateType.All,
            nonLiveEventCount: 0,
            liveEventCount: 0,
            totalEventCount: 0,
        });
    }
}

export function addLiveCountToTodayCount(list: IMarketInfo[]) {
    const liveMarket = list.find(x => x.marketPage === MarketPage.Live);
    const todayMarket = list.find(x => x.marketPage === MarketPage.Today);
    if (liveMarket && todayMarket) {
        todayMarket.liveEventCount = liveMarket.liveEventCount;
        todayMarket.totalEventCount += liveMarket.liveEventCount;
    }
}

export function processDefaultSportsOrderPipe(list: ISportInfo[]) {
    const { sportsOrder } = useSportsOrder();
    return orderBy(list, listItem => sportsOrder.value.indexOf(listItem.sportType));
}

function removeEsportsFromList(list: ISportInfo[]): [ISportInfo[], ISportInfo] {
    const eSportsSbo = list.find(item => item.sportType === SportType.Esports)
            ?? new SportInfo({ sportType: SportType.Esports });
    return [
        list.filter(item => item !== eSportsSbo),
        eSportsSbo,
    ];
}

export function processEsportsPipe(list: ISportInfo[]): ISportInfo[] {
    const { isESportsImInSportEnabled } = storeToRefs(useToggleStore());
    const { playerType, isIom } = storeToRefs(useCustomerStore());

    const isNotIomB2c = !(isIom.value && playerType.value === PlayerType.B2C);
    const isDisplayEsportsIm = isESportsImInSportEnabled.value && isNotIomB2c;
    if (!isDisplayEsportsIm) return list;

    const height = Math.floor(window.screen.height * 0.8);

    const [filteredList, eSportsSbo] = removeEsportsFromList(list);
    const eSportsIm = new SportInfo({
        sportType: SportType.EsportsIm,
        onClick: () => {
            window.open(getEsportsImUrl(), 'ESportsIm', `width=1280,height=${height}`);
        },
    });
    const eSportsDisplay = new SportInfo({
        sportType: SportType.EsportsForDisplay,
        children: [eSportsIm, eSportsSbo],
    });

    filteredList.push(eSportsDisplay);

    return filteredList;
}

export function processExcludeSportPipe(list: ISportInfo[]) {
    const { excludeSportList } = storeToRefs(useToggleStore());
    return list.filter(listItem => !excludeSportList.value.includes(listItem.sportType));
}

export function processVirtualSportsPipe(list: ISportInfo[]): ISportInfo[] {
    const { isVirtualFootballEnabled, isVirtualBasketballEnabled } = storeToRefs(useToggleStore());
    const height = Math.floor(window.screen.height * 0.8);

    const virtualSports = new Map([
        [SportType.VirtualFootball, { isEnabled: isVirtualFootballEnabled.value }],
        [SportType.VirtualBasketball, { isEnabled: isVirtualBasketballEnabled.value }],
    ]);

    const sportInfoList = [...list];
    virtualSports.forEach((virtualSport, sportType) => {
        if (!virtualSport.isEnabled) return;

        const sportInfo = new SportInfo({
            sportType,
            onClick: () => {
                const { getVirtualSportsUrl } = useUrl();
                window.open(getVirtualSportsUrl(sportType), 'VirtualSports', `width=1280,height=${height}`);
            },
        });

        sportInfoList.push(sportInfo);
    });

    return sportInfoList;
}
