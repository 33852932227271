import type { MaybeRef } from '@vueuse/core';
import { get, useIntervalFn } from '@vueuse/core';
import { computed, ref, watch } from 'vue';
import { deepCompareComputed } from '@/composable/deepCompareComputed';
import { useIdle } from '@/composable/useIdle';
import { assignArray, remove, upsert } from '@/core/lib/array';
import { i18n } from '@/core/lib/i18n';
import { deepFreeze } from '@/core/lib/utils';
import { formatEventEuro } from '@/core/oddsApi/helpers/formatEvent';
import type { EnumOddsCategoryType, PresetFilterInputType } from '@/core/oddsApi/oddsApiType';
import { EnumPresetFilterType, EnumTimeZoneType, useEventsEuroQuery, useEventsEuroSubscription } from '@/core/oddsApi/oddsApiType';
import type { IEventEuro } from '@/interface/IEvent';
import { EnumLanguage, SportType } from '@/interface/enum';
import { useBehaviorStore } from '@/store/behaviorStore';

interface IUseEventsParam {
    sportType: MaybeRef<SportType>;
    presetFilter: MaybeRef<PresetFilterInputType>;
    oddsCategory: MaybeRef<EnumOddsCategoryType>;
    eventIds?: MaybeRef<number[] | undefined>;
    leagueIds?: MaybeRef<number[] | undefined>;
    leagueNames?: MaybeRef<string[] | undefined>;
    isActive?: MaybeRef<boolean>;
    isResetResults?: MaybeRef<boolean>;
}

export function useEvents({ sportType, oddsCategory, presetFilter, eventIds, leagueIds, leagueNames, isActive = true, isResetResults = true }: IUseEventsParam) {
    const variables = computed(() => ({
        query: {
            sport: SportType.toOddsApi(get(sportType)),
            filter: get(presetFilter),
            oddsCategory: get(oddsCategory),
            eventIds: get(eventIds),
            tournamentIds: get(leagueIds),
            tournamentNames: get(leagueNames),
            timeZone: EnumTimeZoneType.UTC__4,
            lang: EnumLanguage.toOddsApi(i18n.locale.value),
        },
    }));

    const events = ref<IEventEuro[]>([]);

    const { loaded, loading, onResult, refetch } = useEventsEuroQuery(
        variables,
        deepCompareComputed(() => ({
            enabled: get(isActive),
        })),
    );

    const refreshInterval = computed(() => (get(presetFilter).presetFilter === EnumPresetFilterType.NonLive ? 60 : 20) * 1000);
    useIntervalFn(refetch, refreshInterval);

    // Automatically recover from idle state with a force refresh
    // This is to prevent the user from losing data when they are idle
    const { onActive } = useIdle(60 * 1000);
    onActive(() => {
        // eslint-disable-next-line no-console
        console.log('useEventsEuroQuery: Recovering from idle state...');
        refetch();
    });

    const { onSubscription } = useEventsEuroSubscription(
        variables,
        deepCompareComputed(() => ({
            enabled: get(isActive),
        })),
    );

    const { addEventsQueryCount } = useBehaviorStore();
    onResult((result) => {
        if (result) {
            assignArray(events.value, result.map(event => deepFreeze(formatEventEuro(event))), item => item.id);
        }
        addEventsQueryCount();
    });

    onSubscription((subscription) => {
        upsert(events.value, subscription?.updated.map(event => deepFreeze(formatEventEuro(event))) ?? [], item => item.id);
        remove(events.value, subscription?.deleted ?? [], (a, b) => a.id === b);
    });

    watch(variables, () => {
        if (get(isResetResults)) {
            loaded.value = false;
            events.value.length = 0;
        }
    });

    return {
        loaded,
        loading,
        refetch,
        events,
    };
}
