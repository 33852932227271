import { ref } from 'vue';
import { getMaxPayout, getVoucherMaxPayout } from '@/core/lib/ticket';
import type { BetSlipType, IBetSlipEuro, ITicketEuro } from '@/interface/IBetSlip';
import type { PriceStyle } from '@/interface/enum';

import type { OrderResponse } from '@/interface/pollux';

export type ReceiptStatus = 'success' | 'warning';

export interface ReceiptMessage {
    status: ReceiptStatus;
    key: string;
    failedBetCount: number;
}

export interface Receipt {
    type: BetSlipType;
    tickets: ITicketEuro[];
    stake: number;
    transId: number;
    displayPrice: number;
    priceStyle: PriceStyle;
    maxPayout: number;
    voucherId?: string;
}

export interface ReceiptCollection {
    message: ReceiptMessage | null;
    list: Receipt[];
}

export function createReceipt(betSlip: IBetSlipEuro, order: OrderResponse): Receipt {
    return {
        type: betSlip.type,
        transId: order.transId,
        stake: betSlip.stake,
        displayPrice: order.price,
        priceStyle: betSlip.tickets[0].priceStyle,
        maxPayout: betSlip.voucherId ? getVoucherMaxPayout(betSlip.stake, order.price, betSlip.tickets[0].priceStyle) : getMaxPayout(betSlip.stake, order.price, betSlip.tickets[0].priceStyle),
        tickets: betSlip.tickets,
        voucherId: betSlip.voucherId,
    };
}

export function useReceiptCollection() {
    const receipts = ref<ReceiptCollection>({
        message: null,
        list: [],
    });

    const resetReceipts = () => {
        receipts.value = {
            message: null,
            list: [],
        };
    };

    const setReceiptSuccessMessage = () => {
        receipts.value.message = {
            status: 'success',
            key: 'bet_slip_place_multiple_bets_success_message',
            failedBetCount: 0,
        };
    };

    const setReceiptWarningMessage = (failedBetCount: number) => {
        receipts.value.message = {
            status: 'warning',
            key: 'bet_slip_place_multiple_bets_part_success_message',
            failedBetCount,
        };
    };

    return {
        receipts,
        setReceiptSuccessMessage,
        setReceiptWarningMessage,
        resetReceipts,
    };
}
