<template>
    <div
        v-gtm:label="'Odds'"
        v-gtm:@click
        v-debug:oddsId="odds.id"
        class="oddsBox notranslate"
        v-bind:class="{
            selected: isSelectedOdds,
            suspended: isSuspended,
            fixedWidth: fixedWidth,
        }"
        v-on:click="clickOdds"
    >
        <strong v-if="isSuspended">{{ i18n.t('odds_option_status_suspend') }}</strong>
        <div
            v-else
            class="content"
            v-bind:class="{
                horizontal: layout === 'horizontal',
                vertical: layout === 'vertical',
                rise: priceChangeStatus === PriceChangeStatus.Rise,
                drop: priceChangeStatus === PriceChangeStatus.Drop,
            }"
        >
            <div class="oddsPoint">
                <strong v-if="betOption" class="left">{{ betOption }}</strong>
                <strong v-if="isShowPoint(odds.marketType, getMarketGroupId(odds))" class="right">{{ formatPoint }}</strong>
            </div>
            <div class="oddsValue">
                <strong class="price">{{ displayPrice }}</strong>
                <SvgIcon
                    v-show="isPriceChange"
                    class="arrow"
                    name="icon-system-arrow-down"
                    v-bind:rotated="priceChangeStatus === PriceChangeStatus.Rise"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed, toRefs } from 'vue';
    import { useRoute } from 'vue-router';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import { EuroDisplayContext } from '@/components/euroDisplay/EuroDisplayContext';
    import { usePriceChangeStatus } from '@/composable/usePriceChangeStatus';
    import { getTicketKey } from '@/core/lib/betSlipHelper.euro';
    import { i18n } from '@/core/lib/i18n';
    import { formatPointEuro, formatPrice, getMarketGroupId, isShowPoint } from '@/core/lib/oddsHelper';
    import { EnumOddsStatus } from '@/core/oddsApi/oddsApiType';
    import { BetSlipType } from '@/interface/IBetSlip';
    import type { IEventEuro } from '@/interface/IEvent';
    import type { IOdds, IPrice } from '@/interface/IOdds';
    import { EuroBetPageType, EuroRoutePage, PriceChangeStatus } from '@/interface/enum';
    import { useEuroBetSlipStore } from '@/store/betSlipStore.euro';

    const props = defineProps<{
        event: IEventEuro; // TODO: may have prop stability issue
        odds: IOdds;
        price: IPrice;
        fixedWidth: boolean;
        layout: 'vertical' | 'horizontal';
        betOption: string;
    }>();

    const { event, odds, price, layout } = toRefs(props);

    const formatPoint = computed(() => formatPointEuro(odds.value.point, odds.value.marketType, price.value.option).join(', '));

    const displayPrice = computed(() => formatPrice(price.value.price, odds.value.marketType));

    const { isMultiples } = EuroDisplayContext.inject();

    const isSelectedOdds = computed(() => {
        const betSlipKey = getTicketKey(odds.value, price.value);

        if (isMultiples.value) {
            const { mixParlayBetSlip } = useEuroBetSlipStore();
            return !!mixParlayBetSlip?.tickets.some(ticket => ticket.key === betSlipKey);
        }

        const { singleBetSlips } = useEuroBetSlipStore();
        return singleBetSlips.some(x => x.key === betSlipKey);
    });

    const isSuspended = computed(() => odds.value.status === EnumOddsStatus.Suspend);

    const { useBetSlipsStoreByType } = useEuroBetSlipStore();

    const { addBet: addSingleBet, removeBet: removeSingleBetSlip } = useBetSlipsStoreByType(BetSlipType.Single);
    const { addBet: addToParlayBetSlip, removeBet: removeParlayTicket } = useBetSlipsStoreByType(BetSlipType.Parlay);

    const routeName = useRoute().name as EuroRoutePage;
    const getBetPage = (isLiveOdds: boolean): EuroBetPageType => {
        switch (routeName) {
            case EuroRoutePage.Home:
                if (isLiveOdds) {
                    return EuroBetPageType.Home_Live;
                }
                return EuroBetPageType.Home_Upcoming;
            case EuroRoutePage.InPlay:
                return EuroBetPageType.InPlay;
            case EuroRoutePage.Multiples:
                return EuroBetPageType.Multiples;
            case EuroRoutePage.Multiples_MatchDetail:
                return EuroBetPageType.Multiples_MatchDetail;
            case EuroRoutePage.Sport_Matches_Date:
                return EuroBetPageType.Sport_Matches_Date;
            case EuroRoutePage.Sport_Competitions:
                return EuroBetPageType.Sport_Matches_Competitions;
            case EuroRoutePage.Sport_Outright:
                return EuroBetPageType.Outright;
            case EuroRoutePage.Region_Matches_Date:
                return EuroBetPageType.Region_Matches_Date;
            case EuroRoutePage.Region_Outright:
                return EuroBetPageType.Region_Outright;
            case EuroRoutePage.League_Matches:
                return EuroBetPageType.League_Matches;
            case EuroRoutePage.MatchDetail:
                return EuroBetPageType.MatchDetail;
            case EuroRoutePage.NotFound:
            case EuroRoutePage.Layout:
            case EuroRoutePage.Layout_Sport:
            case EuroRoutePage.Sport:
            case EuroRoutePage.Sport_Matches:
            case EuroRoutePage.Layout_Region:
            case EuroRoutePage.Region:
            case EuroRoutePage.Region_Matches:
            case EuroRoutePage.Layout_League:
            case EuroRoutePage.League:
            default:
                return EuroBetPageType.Unknown;
        }
    };
    const clickOddsWhenSingle = () => {
        if (isSelectedOdds.value) {
            removeSingleBetSlip(getTicketKey(odds.value, price.value));
        } else {
            addSingleBet(event.value, odds.value, price.value, getBetPage(odds.value.isLive), BetSlipType.Single);
        }
    };
    const clickOddsWhenParlay = () => {
        if (isSelectedOdds.value) {
            removeParlayTicket(getTicketKey(odds.value, price.value));
        } else {
            addToParlayBetSlip(event.value, odds.value, price.value, getBetPage(odds.value.isLive));
        }
    };
    const clickOdds = () => {
        if (isSuspended.value) return;

        if (isMultiples.value) {
            clickOddsWhenParlay();
        } else {
            clickOddsWhenSingle();
        }
    };

    const priceValue = computed(() => price.value.price);
    const { priceChangeStatus, isPriceChange } = usePriceChangeStatus(priceValue);
</script>

<style lang="scss" scoped>
@import "@/components/euroDisplay/Odds";
</style>
