import type { Ref } from 'vue';
import { computed, ref } from 'vue';
import { assignArray } from '@/core/lib/array';
import { parseDate, parseISOString } from '@/core/lib/date';
import { i18n } from '@/core/lib/i18n';
import { deepFreeze } from '@/core/lib/utils';
import { formatStream } from '@/core/oddsApi/helpers/formatEvent';
import { EnumHandicapType, EnumTournamentSizeType, useStreamEventsQuery } from '@/core/oddsApi/oddsApiType';
import type { IJersey } from '@/interface/IJersey';
import type { IStreamEvent } from '@/interface/IStream';
import { EnumLanguage, LeagueSize, SportType } from '@/interface/enum';

export const formatJersey = (jersey: string): IJersey => {
    const [type, leftSleeve, rightSleeve, body, stripe] = jersey.split(';');
    return {
        type: type ? parseInt(type) : 0,
        leftSleeve: leftSleeve ? `#${leftSleeve}` : null,
        rightSleeve: rightSleeve ? `#${rightSleeve}` : null,
        body: body ? `#${body}` : null,
        stripe: stripe ? `#${stripe}` : null,
    };
};

const formatStreamEvent = (event: NonNullable<(ReturnType<typeof useStreamEventsQuery>)['result']['value']>[number]) => {
    const handicapType = event.isLive
        ? event.mainMarketEventResult.liveHandicapType
        : event.mainMarketEventResult.nonLiveHandicapType;
    return {
        eventId: event.id,
        eventCode: event.eventCode,
        isLive: event.isLive,
        sportType: SportType.fromOddsApi(event.mainMarketEventResult.sport),
        kickoffTime: parseISOString(event.realKickoffTime),
        showTime: parseDate(event.showTime, 'MM/dd/yyyy HH:mm', 8),
        showTimeType: event.showTimeType,
        homeTeam: {
            name: event.homeTeam?.teamName ?? '',
            jersey: formatJersey(event.homeJersey),
            redCard: event.mainMarketEventResult.extraInfo?.homeRedCards ?? 0,
            liveScore: event.mainMarketEventResult.liveHomeScore,
            isFavorite: handicapType === EnumHandicapType.Home,
        },
        awayTeam: {
            name: event.awayTeam?.teamName ?? '',
            jersey: formatJersey(event.awayJersey),
            redCard: event.mainMarketEventResult.extraInfo?.awayRedCards ?? 0,
            liveScore: event.mainMarketEventResult.liveAwayScore,
            isFavorite: handicapType === EnumHandicapType.Away,
        },
        extraInfo: {
            round: event.mainMarketEventResult.extraInfo?.round ?? 0,
            injuryTime: event.mainMarketEventResult.extraInfo?.injuryTime ?? 0,
            period: event.mainMarketEventResult.extraInfo?.period ?? 0,
            periodStartTime: event.mainMarketEventResult.extraInfo?.periodStartTime ?? '0001-01-01T00:00:00',
        },
        league: {
            name: event.tournament?.tournamentName ?? '',
            size: LeagueSize.fromOddsApi(event.tournament?.tournamentSize ?? EnumTournamentSizeType.Small),
        },
        stream: formatStream(event),
    };
};

export function useStreamEvents(isEnabled: Ref<boolean> = ref(true)) {
    const { loaded, loading, onResult, refetch: refetchStreamEvents } = useStreamEventsQuery(
        computed(() => ({
            query: {
                lang: EnumLanguage.toOddsApi(i18n.locale.value),
            },
        })),
        computed(() => ({
            enabled: isEnabled.value,
        })),
    );

    const streamEvents = ref<IStreamEvent[]>([]);
    onResult((result) => {
        const newStreamEvents = (result ?? []).map(event => deepFreeze(formatStreamEvent(event)));

        assignArray(streamEvents.value, newStreamEvents, x => x.eventId);
    });

    return {
        loaded,
        loading,
        streamEvents,
        refetchStreamEvents,
    };
}
