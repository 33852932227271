import type { MaybeRef } from '@vueuse/core';
import { get } from '@vueuse/core';
import orderBy from 'lodash/orderBy';
import type { Ref } from 'vue';
import { computed, nextTick, ref, watch } from 'vue';
import { useState } from '@/composable/useState';
import { assignArray, remove, upsert } from '@/core/lib/array';
import { i18n } from '@/core/lib/i18n';
import { deepFreeze } from '@/core/lib/utils';
import { formatOutrightLeague } from '@/core/oddsApi/helpers/formatOutrightLeague';
import { useOutrightTournamentsQuery, useOutrightTournamentsSubscription } from '@/core/oddsApi/oddsApiType';
import type { IOutrightLeague } from '@/interface/IOutrightLeague';
import { EnumLanguage, SportType } from '@/interface/enum';

interface IOutrightLeaguesParam {
    sportType: SportType;
    isLive: boolean;
    leagueIds?: MaybeRef<number[] | null>;
    leagueNames?: MaybeRef<string[] | null>;
    isActive?: Ref<boolean>;
}

export function useOutrightLeagues({ sportType, isLive, leagueIds, leagueNames, isActive = ref(true) }: IOutrightLeaguesParam) {
    // sort here to generate a stable query
    const sortedLeagueIds = computed(() => {
        const _leagueIds = get(leagueIds);
        return _leagueIds ? orderBy(_leagueIds) : undefined;
    });
    const sortedLeagueNames = computed(() => {
        const _leagueNames = get(leagueNames);
        return _leagueNames ? orderBy(_leagueNames) : undefined;
    });

    const variables = computed(() => ({
        query: {
            sport: SportType.toOddsApi(sportType),
            isLive,
            lang: EnumLanguage.toOddsApi(i18n.locale.value),
            tournamentNames: sortedLeagueNames.value,
            tournamentIds: sortedLeagueIds.value,
        },
    }));

    const [isEnabled, setIsEnabled] = useState(true);
    const refetch = async () => {
        if (isEnabled.value) {
            setIsEnabled(false);
            nextTick(() => setIsEnabled(true));
        }
    };

    const { loaded, loading, onResult } = useOutrightTournamentsQuery(
        variables,
        computed(() => ({
            enabled: isActive.value && isEnabled.value,
        })),
    );
    const { onSubscription } = useOutrightTournamentsSubscription(
        variables,
        computed(() => ({
            enabled: isActive.value,
        })),
    );

    const outrightLeagues = ref<IOutrightLeague[]>([]);

    onResult((result) => {
        const newOutrightLeagues = (result ?? []).map(x => deepFreeze(formatOutrightLeague(x, sportType, isLive)));
        assignArray(outrightLeagues.value, newOutrightLeagues, x => x.id);
    });

    onSubscription((subscription) => {
        const toUpdate = (subscription?.updated ?? []).map<IOutrightLeague>(x => deepFreeze(formatOutrightLeague(x, sportType, isLive)));

        upsert(outrightLeagues.value, toUpdate, item => item.id);
        remove(outrightLeagues.value, subscription?.deleted ?? [], (a, b) => a.id === b.id && a.isLive === b.isLive);
    });

    watch(isActive, () => {
        if (!isActive.value) {
            outrightLeagues.value = [];
        }
    });

    return {
        loaded,
        loading,
        refetch,
        outrightLeagues,
    };
}
