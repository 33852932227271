<template>
    <div
        v-gtm:label="gtmLabel"
        v-gtm:@click
        v-tooltip:top="tooltipText"
        class="icon icon-favorite"
        v-bind:class="{ 'icon-favorite-active': isFavorited }"
        v-on:click="toggleFavorite"
    >
        <SvgIcon
            name="icon-system-star-full"
            v-bind:size="size"
            v-bind:color="color"
        />
    </div>
</template>

<script lang="ts" setup>
    import type { PropType } from 'vue';
    import { computed } from 'vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import { OddsDisplayBlockContext } from '@/components/oddsDisplay/OddsDisplayBlockContext';
    import { i18n } from '@/core/lib/i18n';
    import type { SportType } from '@/interface/enum';
    import { useFavoriteStore } from '@/store/favoriteStore';

    const props = defineProps({
        type: {
            type: String as PropType<'LEAGUE' | 'EVENT'>,
            required: true,
        },
        sport: {
            type: Number as PropType<SportType>,
            required: true,
        },
        leagueId: {
            type: Number,
            required: true,
        },
        eventId: {
            type: Number,
            default: null,
        },
        size: {
            type: [Number, String],
            default: () => 'default',
        },
        color: {
            type: String,
            default: () => '',
        },
    });

    const { isFavoriteEvent, isFavoriteLeague, addLeague, removeLeague, addEvent, removeEvent } = useFavoriteStore();

    if (props.type === 'EVENT') {
        if (isFavoriteLeague(props.sport, props.leagueId)) {
            addEvent(props.sport, props.eventId);
        }
    }

    const isFavorited = computed(() => (props.type === 'LEAGUE'
        ? isFavoriteLeague(props.sport, props.leagueId)
        : isFavoriteEvent(props.sport, props.eventId)));

    const tooltipText = computed(() => (isFavorited.value ? i18n.t('remove_favorites_tooltip') : i18n.t('add_favorites_tooltip')));

    const gtmLabel = computed(() => {
        const type = props.type === 'EVENT' ? 'MyFavoriteMatch' : 'MyFavoriteLeague';
        const status = isFavorited.value ? 'Off' : 'On';
        return `${type}_${status}`;
    });

    const { displayEvents } = OddsDisplayBlockContext.inject();
    const toggleLeague = () => {
        const leagueEvents = displayEvents.value.filter(x => x.league.id === props.leagueId);
        if (isFavorited.value) {
            removeLeague(props.sport, props.leagueId);
            leagueEvents.forEach(x => removeEvent(props.sport, x.id));
        } else {
            addLeague(props.sport, props.leagueId);
            leagueEvents.forEach(x => addEvent(props.sport, x.id));
        }
    };

    const toggleEvent = function () {
        if (isFavorited.value) {
            removeEvent(props.sport, props.eventId);
            removeLeague(props.sport, props.leagueId);
        } else {
            addEvent(props.sport, props.eventId);
        }
    };

    const toggleFavorite = () => (props.type === 'LEAGUE'
        ? toggleLeague()
        : toggleEvent());

</script>

<style lang="scss" scoped>
.icon-favorite {
    color: $gray-200;
    stroke: $brandColor-800;
    transition: $transition-color;
}

.icon-favorite-active {
    color: $iconFavoriteActiveColor;
    stroke: $iconFavoriteActiveStrokeColor;
}
</style>
