import { storeToRefs } from 'pinia';
import type { Ref } from 'vue';
import { watch } from 'vue';
import { useEventEmitter } from '@/composable/useEventEmitter';
import { useState } from '@/composable/useState';
import { removeDuplicates } from '@/core/lib/array';
import type { ILeagueFilter } from '@/interface/ILeagueFilter';
import { LeagueSize } from '@/interface/enum';
import { useSelectedLeagueStore } from '@/store/selectedLeagueStore';

export function useSelectLeague(selectLeagueList: Ref<ILeagueFilter[]>) {
    const [isShowSelectLeague, setIsShowSelectLeague] = useState(false);

    const eventEmitter = useEventEmitter();
    eventEmitter.on('selectLeague:show', () => {
        setIsShowSelectLeague(true);
    });

    const { selectedLeagueIds, isSelectAllBigLeagues, isSelectAllLeagues } = storeToRefs(useSelectedLeagueStore());
    const { setSelectedLeagueInfo, syncSelectedLeagueInfosToSession } = useSelectedLeagueStore();
    watch(selectLeagueList, (newSelectLeagueList) => {
        if (isSelectAllLeagues.value) return;
        if (newSelectLeagueList.length === 0) return;

        const newLeagueIds = newSelectLeagueList.map(x => x.id);
        let leagueIds = selectedLeagueIds.value.filter(id => newLeagueIds.includes(id));

        if (!isSelectAllBigLeagues.value) {
            setSelectedLeagueInfo(leagueIds, isSelectAllBigLeagues.value);
            return;
        }

        const newBigLeagueIds = newSelectLeagueList.filter(x => x.size === LeagueSize.Big).map(x => x.id);
        leagueIds = removeDuplicates(leagueIds.concat(newBigLeagueIds));
        setSelectedLeagueInfo(leagueIds, isSelectAllBigLeagues.value);
        syncSelectedLeagueInfosToSession();
    });

    return {
        isShowSelectLeague,
        setIsShowSelectLeague,
    };
}
