<template>
    <ul class="sportList">
        <SportListItem
            v-for="sport in items"
            v-bind:key="sport.name"
            v-bind:sportInfo="sport"
        >
            <SvgIcon
                v-if="sport.icon"
                v-bind:name="getIconName(sport)"
                v-bind:size="getIconSize(sport)"
                v-bind:style="getIconStyle(sport)"
            />
            <SportIcon
                v-else-if="sport.sportType"
                v-bind:sportType="sport.sportType"
                size="lg"
            />
        </SportListItem>
    </ul>
</template>

<script lang="ts" setup>
    import SportListItem from './SportListItemEuro.vue';
    import SportIcon from '@/components/common/SportIcon.vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import type { ISportListItem } from '@/interface/ISport';

    defineProps<{
        items: ISportListItem[];
    }>();

    function getIconName(sport: ISportListItem) {
        return typeof sport.icon === 'string' ? sport.icon : sport.icon?.name ?? '';
    }

    function getIconSize(sport: ISportListItem) {
        return typeof sport.icon === 'string' ? 'lg' : sport.icon?.size ?? 'lg';
    }

    function getIconStyle(sport: ISportListItem) {
        return typeof sport.icon === 'string' ? {} : sport.icon?.style ?? {};
    }
</script>

<style lang="scss" scoped>
    @import "@/components/sportList/SportList.Euro";
</style>
