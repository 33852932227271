import { autoResetRef } from '@vueuse/core';
import type { MaybeRefOrGetter, Ref } from 'vue';

export function useAutoResetState<T>(initialValue: T, afterMs: MaybeRefOrGetter<number>): [Ref<T>, (value: T) => void] {
    const _value = autoResetRef(initialValue, afterMs);
    const setValue = (value: T) => {
        _value.value = value;
    };
    return [_value, setValue];
}
