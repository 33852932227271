import { storeToRefs } from 'pinia';
import type { Ref } from 'vue';
import { computed, ref, watch } from 'vue';
import { useAutoResetState } from '@/composable/useAutoResetState';
import { PriceChangeStatus } from '@/interface/enum';
import { useCustomerStore } from '@/store/customerStore';

function convertToHKOdds(price: number) {
    if (price < 0) {
        return -1 / price;
    }
    return price;
}

export const usePriceChangeStatus = (price: Ref<number | null>) => {
    const { priceStyle } = storeToRefs(useCustomerStore());
    const prevPriceStyle = ref(priceStyle.value);

    const [priceChangeStatus, setPriceChangeStatus] = useAutoResetState(PriceChangeStatus.Remain, 3000);

    watch(price, (newVal, oldVal) => {
        // Do not show price change status when price style changed
        if (prevPriceStyle.value !== priceStyle.value) {
            prevPriceStyle.value = priceStyle.value;
            return;
        }

        if (newVal === null || oldVal === null) {
            setPriceChangeStatus(PriceChangeStatus.Remain);
            return;
        }

        const oldPrice = convertToHKOdds(oldVal);
        const newPrice = convertToHKOdds(newVal);

        if (oldPrice < newPrice) {
            setPriceChangeStatus(PriceChangeStatus.Rise);
        } else if (oldPrice > newPrice) {
            setPriceChangeStatus(PriceChangeStatus.Drop);
        }
    });

    const isPriceChange = computed(() => priceChangeStatus.value !== PriceChangeStatus.Remain);

    return {
        priceChangeStatus,
        isPriceChange,
    };
};
