<template>
    <div class="mixParlay">
        <div v-if="hasMixParlayBetSlip" class="subHeader">
            <div v-if="hasMixParlayBetSlip" class="description">
                {{ `${ i18n.t('bet_slip_selection_count', { count: mixParlayBetSlip.tickets.length }, mixParlayBetSlip.tickets.length)}` }}
            </div>
            <div
                class="action"
                v-on:click="removeAll"
            >
                {{ i18n.t('remove_all') }}
            </div>
        </div>
        <BetSlip
            v-if="hasMixParlayBetSlip"
            v-bind:betSlip="mixParlayBetSlip"
        />
        <div class="placeBetBlock">
            <template v-if="hasMixParlayBetSlip">
                <AcceptAnyOdds class="placeBetBlockItem" />
                <div class="folds placeBetBlockItem">
                    <span class="info"> {{ `${mixParlayFolds}` }} </span>
                    <span class="price"> {{ totalPrice }} </span>
                </div>
                <StakeInput
                    class="placeBetBlockItem"
                    v-bind:stakeDisplay="stakeDisplay"
                    v-bind:setDisplayToStake="setDisplayToStake"
                />
                <span class="detailText_item placeBetBlockItem">
                    {{ `${i18n.t('bet_slip_min')}:${minBetDisplay} | ${i18n.t('bet_slip_max')}:${maxBetDisplay}` }}
                </span>
                <div class="detailText_item placeBetBlockItem">
                    <strong>{{ i18n.t('bet_slip_potential_returns') }}</strong>
                    <strong class="amount">
                        <CurrencyIcon />
                        {{ maxPayout }}
                    </strong>
                </div>

                <Message
                    v-for="message in displayMessages"
                    v-bind:key="message.key"
                    class="placeBetBlockItem"
                    v-bind:show="message.key !== ''"
                    position="top"
                    iconSize="lg"
                    v-bind:status="message.status"
                    rounded
                    v-bind:message="message.text"
                    v-bind:icon="`icon-system-warningCircle`"
                />
                <PlaceBetBlockEuro
                    class="placeBetBlockItem"
                    v-bind:betSlipType="BetSlipType.Parlay"
                />
            </template>
            <NoBetSlip
                v-else
                v-bind:buttonGtmLabel="'Multiples'"
                v-bind:title="i18n.t('market_page_name_4')"
                v-bind:onClick="goToBettingMultiple"
                v-bind:count="mixParlayEventCount"
            >
                <template #message>
                    <Message
                        v-for="message in displayMessages"
                        v-bind:key="message.key"
                        v-bind:show="message.key !== ''"
                        position="top"
                        iconSize="lg"
                        v-bind:status="message.status"
                        rounded
                        v-bind:message="message.text"
                        v-bind:icon="`icon-system-warningCircle`"
                    />
                </template>
            </NoBetSlip>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { format } from '@sports-utils/money';
    import { storeToRefs } from 'pinia';
    import { computed, ref } from 'vue';
    import AcceptAnyOdds from '@/components/betSlip/AcceptAnyOdds.vue';
    import BetSlip from '@/components/betSlip/BetSlip.Euro.vue';
    import Message from '@/components/betSlip/Message.vue';
    import NoBetSlip from '@/components/betSlip/NoBetSlip.vue';
    import PlaceBetBlockEuro from '@/components/betSlip/PlaceBetBlockEuro.vue';
    import StakeInput from '@/components/betSlip/StakeInput.vue';
    import CurrencyIcon from '@/components/common/CurrencyIcon.vue';
    import { mixParlaySelectionsDisplay } from '@/components/myBets/useBetDisplay';
    import { useEuroRoute } from '@/composable/useEuroRoute';
    import { useEuroRouter } from '@/composable/useEuroRouter';
    import { useMpInvalidMessageDisplay } from '@/composable/useMpInvalidMessageDisplay';
    import { useStakeDisplay } from '@/composable/useStakeDisplay';
    import { useStakeInput } from '@/composable/useStakeInput';
    import { i18n } from '@/core/lib/i18n';
    import { getMaxPayout, getTotalOddsPrice } from '@/core/lib/ticket';
    import { BetSlipType } from '@/interface/IBetSlip';
    import type { IDynamicI18nConfig } from '@/interface/IDynamicI18nConfig';
    import { PriceStyle } from '@/interface/enum';
    import { useEuroBetSlipStore } from '@/store/betSlipStore.euro';
    import { useCustomerStore } from '@/store/customerStore';
    import { useEuroSideBarStore } from '@/store/sideBarStore.Euro';

    const { mixParlayBetSlip } = storeToRefs(useEuroBetSlipStore());
    const { useBetSlipsStoreByType } = useEuroBetSlipStore();
    const { placedErrorMessages, betSlipMessage, removeAll } = useBetSlipsStoreByType(BetSlipType.Parlay);

    const tickets = computed(() => mixParlayBetSlip.value.tickets);
    const isMixParlay = ref(true);
    const { invalidSubBetMessage } = useMpInvalidMessageDisplay(tickets, isMixParlay);
    const displayMessages = computed<{ key: string, text: IDynamicI18nConfig | string, status: 'success' | 'info' | 'warning' | 'danger' }[]>(() => {
        if (invalidSubBetMessage.value) {
            return [{
                key: invalidSubBetMessage.value.key,
                text: invalidSubBetMessage.value,
                status: 'info',
            }];
        }
        const strings = [...placedErrorMessages.value, betSlipMessage.value];
        return strings.map(s => ({
            key: s,
            text: s,
            status: 'danger',
        }));
    });

    const { defaultMoneyConfig } = storeToRefs(useCustomerStore());

    const totalPrice = computed(() => getTotalOddsPrice(mixParlayBetSlip.value.tickets));
    const maxPayout = computed(() => {
        if (!mixParlayBetSlip.value) return 0;
        const payout = getMaxPayout(mixParlayBetSlip.value.stake, totalPrice.value, PriceStyle.Euro);
        return format(payout, defaultMoneyConfig.value);
    });

    const { sportType } = useEuroRoute();
    const { goToMultiple } = useEuroRouter();
    const goToBettingMultiple = () => {
        goToMultiple(sportType.value);
    };
    const { mixParlayEventCount } = storeToRefs(useEuroSideBarStore());

    const hasMixParlayBetSlip = computed(() => mixParlayBetSlip.value.tickets.length);
    const mixParlayFolds = computed(() => mixParlaySelectionsDisplay(mixParlayBetSlip.value.tickets.length));

    const betSlipStake = computed({
        get: () => mixParlayBetSlip.value.stake,
        set: (value: number) => {
            mixParlayBetSlip.value.stake = value;
        },
    });
    const { stakeDisplay, setDisplayToStake } = useStakeInput(betSlipStake);
    const minBetDisplay = computed(() => useStakeDisplay(mixParlayBetSlip.value.minBet).stakeDisplay);
    const maxBetDisplay = computed(() => useStakeDisplay(mixParlayBetSlip.value.maxBet).stakeDisplay);
</script>

<style lang="scss" scoped>
@import "@/components/betSlip/BetSlipBlock.Euro";
@import "@/components/betSlip/PlaceBetBlockEuro";

.form-input {
    width: 100%;
}
</style>
