import { HttpTransportType, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { MessagePackHubProtocol } from '@microsoft/signalr-protocol-msgpack';
import { sleep } from '@sports-utils/timer';
import { useMemoize } from '@vueuse/core';
import { orderBy } from 'lodash';
import { storeToRefs } from 'pinia';
import { onBeforeUnmount, ref } from 'vue';
import { Api } from '@/core/lib/api';
import { parseISOString } from '@/core/lib/date';
import { getJackpotUrl } from '@/core/lib/url';
import type { PoolSummary, PoolSummaryRaw, Winner, WinnerRaw } from '@/interface/jackpot';
import { JackpotPoolType, Method } from '@/interface/jackpot';
import { useCustomerStore } from '@/store/customerStore';
import { useToggleStore } from '@/store/toggleStore';

const token = ref<string | null>(null);
/**
 * @returns {Promise<void>} A Promise that resolves when token is updated. Will return cached promise if called concurrently.
 */
const updateToken = useMemoize(async () => {
    try {
        const { data } = await Api.getJackpotToken();
        token.value = data;
    } finally {
        updateToken.clear(); // clear the cached promise to allow future call to execute
    }
});

const connection = new HubConnectionBuilder()
    .withUrl(`${getJackpotUrl()}/Canvas`, {
        accessTokenFactory: () => token.value ?? '',
        skipNegotiation: true, // so that canvas no need to have sticky session
        transport: HttpTransportType.WebSockets,
    })
    .withHubProtocol(new MessagePackHubProtocol())
    .withAutomaticReconnect()
    .configureLogging(__ENV_PROD__ ? LogLevel.Error : LogLevel.Warning)
    .build();

const totalPoolAmount = ref<number | null>(null);
connection.on(Method.TotalPoolAmount, (data: string) => {
    totalPoolAmount.value = parseFloat(data);
});

const poolOrder = [
    JackpotPoolType.Mega,
    JackpotPoolType.Major,
    JackpotPoolType.Minor,
    JackpotPoolType.Mini,
];
const poolSummary = ref<PoolSummary[]>([]);
connection.on(Method.PoolSummary, (data: PoolSummaryRaw[]) => {
    poolSummary.value = orderBy(data, pool => poolOrder.indexOf(pool.PoolType))
        .map(summary => ({
            poolAmount: parseFloat(summary.PoolAmount),
            round: summary.Round,
            poolType: summary.PoolType,
            targetTriggerAmount: parseFloat(summary.TargetTriggerAmount),
            minQualifyingAmount: parseFloat(summary.MinQualifyingAmount),
            totalNumberOfWinner: summary.TotalNumberOfWinner,
            totalWonAmount: parseFloat(summary.TotalWonAmount),
            lastWinner: summary.LastWinner,
            lastWonAmount: summary.LastWonAmount ? parseFloat(summary.LastWonAmount) : null,
            lastWinnerBetId: summary.LastWinnerBetId,
            lastTriggeredTime: summary.LastTriggeredTime ? parseISOString(summary.LastTriggeredTime) : null,
        }));
});

const winner = ref<Winner | null>(null);
connection.on(Method.Winner, (data: WinnerRaw[]) => {
    const { loginName } = storeToRefs(useCustomerStore());
    const winners = data
        .filter(detail => detail.LoginName === loginName.value) // extra check to validate message is for correct user
        .map(detail => ({
            loginName: detail.LoginName,
            type: detail.Type,
            round: detail.Round,
            amount: parseFloat(detail.Amount),
        }));
    winner.value = winners.length ? winners[0] : null;
});

const subscriptionCounter: Record<Method, number> = {
    [Method.TotalPoolAmount]: 0,
    [Method.PoolSummary]: 0,
    [Method.Winner]: 0,
};

function resubscribe() {
    return Promise.all(Object.entries(subscriptionCounter).map(async ([methodName, count]) => {
        if (count > 0) {
            await connection.invoke(`Subscribe${methodName}`);
        }
    }));
}
connection.onreconnected(resubscribe);

let retryCount = 0;
const retryDelay = [0, 2, 10, 30];
/**
 * @returns {Promise<void>} A Promise that resolves when the connection has been successfully established, or rejects with an error after all retries. Will return cached promise if called concurrently.
 */
const start = useMemoize(async () => {
    try {
        await updateToken();
        await connection.start();
    } catch (err) {
        if (retryCount < retryDelay.length) {
            await sleep(retryDelay[retryCount++]);
            await start.load(); // load() to bypass cache to execute again
        } else {
            throw err;
        }
    } finally {
        start.clear(); // clear the cached promise to allow future call to execute
        retryCount = 0;
    }
});

/**
 * @returns {Promise<void>} A Promise that resolves when connection is started or already started.
 */
async function tryStart() {
    const { isJackpotEnabled } = storeToRefs(useToggleStore());
    if (!isJackpotEnabled.value) return;

    if (connection.state === 'Connected') return;

    await start();
}

async function subscribe(methodName: Method) {
    await tryStart();

    subscriptionCounter[methodName]++;
    if (subscriptionCounter[methodName] > 1) return;

    if (connection.state === 'Connected') {
        await connection.invoke(`Subscribe${methodName}`);
    }
}

async function unsubscribe(methodName: Method) {
    // Wait for 1 second to see if other components will subscribe back again soon,
    // to prevent unsubscribe then subscribe behavior
    // Do not use useTimeoutFn here, because need to run even after caller component unmounted
    await sleep(1);

    subscriptionCounter[methodName]--;
    if (subscriptionCounter[methodName] > 0) return;

    if (connection.state === 'Connected') {
        await connection.invoke(`Unsubscribe${methodName}`);

        const totalSubscriptions = Object.values(subscriptionCounter).reduce((a, b) => a + b, 0);
        if (totalSubscriptions <= 0) {
            await connection.stop();
        }
    }
}

export const useTotalPoolAmount = () => {
    subscribe(Method.TotalPoolAmount);
    onBeforeUnmount(() => unsubscribe(Method.TotalPoolAmount));

    return {
        totalPoolAmount,
    };
};

export const usePoolSummary = () => {
    subscribe(Method.PoolSummary);
    onBeforeUnmount(() => unsubscribe(Method.PoolSummary));

    return {
        poolSummary,
    };
};

export const useWinner = () => {
    subscribe(Method.Winner);
    onBeforeUnmount(() => unsubscribe(Method.Winner));

    return {
        winner,
    };
};
