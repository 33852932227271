<template>
    <NavbarBlock
        v-bind:items="navBarItems"
        v-bind:marketDropdownPosition="EnumEuroMarketDropdownPosition.Bottom"
    >
        <template #title>
            {{ regionName }}
        </template>
    </NavbarBlock>
    <RouterView />
</template>

<script lang="ts" setup>
    import { computed, ref } from 'vue';
    import { useRouter } from 'vue-router';
    import { EuroDisplayContext } from '@/components/euroDisplay/EuroDisplayContext';
    import type { INavBarItem } from '@/components/navbarBlock/NavbarBlock.Euro.vue';
    import NavbarBlock from '@/components/navbarBlock/NavbarBlock.Euro.vue';
    import { useEuroRoute } from '@/composable/useEuroRoute';
    import { i18n } from '@/core/lib/i18n';
    import { EnumEuroMarketDropdownPosition, EuroDate, EuroRoutePage } from '@/interface/enum';

    const router = useRouter();
    const { route, sportType, date } = useEuroRoute();

    EuroDisplayContext.provide(sportType, ref(false), date);

    const { regionName } = useEuroRoute();
    const navBarItems = computed<INavBarItem[]>(() => [
        {
            title: i18n.t('market_page_matches'),
            isActive: () => route.name === EuroRoutePage.Region_Matches_Date,
            onClick: () => {
                router.push({ name: EuroRoutePage.Region_Matches });
            },
            subItems: [
                {
                    title: i18n.t('date_filter_0'),
                    isActive: () => date.value === EuroDate.Today,
                    onClick: () => {
                        router.push({
                            name: EuroRoutePage.Region_Matches_Date,
                            params: {
                                regionName: regionName.value,
                                date: EuroDate.toRoute(EuroDate.Today),
                            },
                        });
                    },
                },
                {
                    title: i18n.t('date_filter_1'),
                    isActive: () => date.value === EuroDate.Tomorrow,
                    onClick: () => {
                        router.push({
                            name: EuroRoutePage.Region_Matches_Date,
                            params: {
                                regionName: regionName.value,
                                date: EuroDate.toRoute(EuroDate.Tomorrow),
                            },
                        });
                    },
                },
                {
                    title: i18n.t('market_page_future'),
                    isActive: () => date.value === EuroDate.Future,
                    onClick: () => {
                        router.push({
                            name: EuroRoutePage.Region_Matches_Date,
                            params: {
                                regionName: regionName.value,
                                date: EuroDate.toRoute(EuroDate.Future),
                            },
                        });
                    },
                },
            ],
        },
    ]);
</script>
