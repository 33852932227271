export default class MapWithDefault<K, V> {
    _map: Map<K, V>;
    defaultFunction: (key: K) => V;

    /* map functions */
    get(key: K) {
        if (!this._map.has(key)) {
            this._map.set(key, this.defaultFunction(key));
        }
        return this._map.get(key)!;
    }

    set(key: K, value: V) {
        return this._map.set(key, value);
    }

    delete(key: K) {
        return this._map.delete(key);
    }

    has(key: K) {
        return this._map.has(key);
    }

    clear() {
        return this._map.clear();
    }

    keys() {
        return this._map.keys();
    }

    values() {
        return [...this._map.values()];
    }

    forEach(callbackfn: (value: V, key: K, map: Map<K, V>) => void, thisArg?: any) {
        return this._map.forEach(callbackfn, thisArg);
    }

    /* array functions */
    map<T>(callbackfn: (value: V, index: number, array: V[]) => T, thisArg?: any) {
        return [...this._map.values()].map<T>(callbackfn, thisArg);
    }

    filter(predicate: (value: V, index: number, array: V[]) => unknown, thisArg?: any) {
        return [...this._map.values()].filter(predicate, thisArg);
    }

    constructor(defaultFunction: (key: K) => V, entries?: readonly (readonly [K, V])[] | null) {
        this._map = new Map<K, V>(entries);
        this.defaultFunction = defaultFunction;
    }

    [Symbol.iterator]() {
        return this._map[Symbol.iterator]();
    }
}
