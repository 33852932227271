<template>
    <div
        v-gtm:page="'Lobby'"
        v-gtm:type="'Preference'"
        v-resize="onResize"
        class="preferences"
    >
        <Preferences />
    </div>
</template>

<script lang="ts" setup>
    import Preferences from '@/components/preferences/Preferences.vue';
    import { useTitle } from '@/composable/useTitle';
    import type { SizeInfo } from '@/directives/resize';
    import { PageType } from '@/interface/enum';

    useTitle(PageType.Preferences);

    let previousHeight = 0;
    const onResize = ({ height }: SizeInfo) => {
        if (previousHeight === height) return;

        previousHeight = height;
        window.parent.postMessage({
            location: window.location.href,
            height: Math.ceil(height),
        }, '*');
    };
</script>
