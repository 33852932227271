import { tryOnUnmounted } from '@vueuse/core';

// Ref: https://github.com/developit/mitt
// added: `once()` API support
// added: return `off()` function for `on()` and `once()`
// deleted: remove wildcard support
// deleted: remove constructor parameter: all

export type EventType = string | symbol;

// An event handler can take an optional event argument
// and should not return a value
export type Handler = (...args: any[]) => void;

export type EventOffFn = () => void;

// An array of all currently registered event handlers for a type
export type EventHandlerList = Array<Handler>;

// A map of event types and their corresponding event handlers.
export type EventHandlerMap<Events extends Record<EventType, unknown>> = Map<keyof Events, EventHandlerList>;

export interface Emitter<Events extends Record<EventType, unknown>> {
    all: EventHandlerMap<Events>;

    on<Key extends keyof Events>(type: Key, handler: Handler): EventOffFn;

    once<Key extends keyof Events>(type: Key, handler: Handler): EventOffFn;

    off<Key extends keyof Events>(type: Key, handler?: Handler): void;

    emit<Key extends keyof Events>(type: Key, ...args: any[]): void;
    emit<Key extends keyof Events>(type: undefined extends Events[Key] ? Key : never): void;
}

/**
 * Mitt: Tiny (~200b) functional event emitter / pubsub.
 * @name mitt
 * @returns {Mitt}
 */
function mitt<Events extends Record<EventType, unknown>>(): Emitter<Events> {
    const all = new Map();

    return {
        /**
         * A Map of event names to registered handler functions.
         */
        all,

        /**
         * Register an event handler for the given type.
         * @param type Type of event to listen for, or `'*'` for all events
         * @param handler Function to call in response to given event
         * @memberOf mitt
         */
        on<Key extends keyof Events>(type: Key, handler: Handler): EventOffFn {
            const handlers: Array<Handler> | undefined = all.get(type);
            if (handlers) {
                handlers.push(handler);
            } else {
                all.set(type, [handler] as EventHandlerList);
            }

            const off = () => this.off(type, handler);
            return off;
        },

        once<Key extends keyof Events>(type: Key, handler: Handler): EventOffFn {
            const fn = (arg: Events[Key]) => {
                this.off(type, fn);
                handler(arg);
            };
            this.on(type, fn);

            const off = () => this.off(type, fn);
            return off;
        },

        /**
         * Remove an event handler for the given type.
         * If `handler` is omitted, all handlers of the given type are removed.
         * @param {string|symbol} type Type of event to unregister `handler` from, or `'*'`
         * @param {Function} [handler] Handler function to remove
         * @memberOf mitt
         */
        off<Key extends keyof Events>(type: Key, handler?: Handler) {
            const handlers: Array<Handler> | undefined = all.get(type);
            if (handlers) {
                if (handler) {
                    handlers.splice(handlers.indexOf(handler) >>> 0, 1);
                } else {
                    all.set(type, []);
                }
            }
        },

        /**
         * Invoke all handlers for the given type.
         *
         * @param {string|symbol} type The event type to invoke
         * @param {Any} [evt] Any value (object is recommended and powerful), passed to each handler
         * @memberOf mitt
         */
        emit<Key extends keyof Events>(type: Key, ...args: any[]) {
            const handlers = all.get(type);
            if (handlers) {
                (handlers as EventHandlerList)
                    .slice()
                    .forEach((handler) => {
                        handler(...args);
                    });
            }
        },
    };
}

const eventEmitter = mitt();

export function useEventEmitter() {
    /**
     * Add a listener for a given event.
     * Will auto remove listener when component `onUnMounted`.
     */
    const on = (event: string | symbol, fn: (...args: any[]) => void): void => {
        const off = eventEmitter.on(event, fn);
        tryOnUnmounted(() => off());
    };
    /**
     * Add a one-time listener for a given event.
     * Will auto remove listener when component `onUnMounted`.
     */
    const once = (event: string | symbol, fn: (...args: any[]) => void): void => {
        const off = eventEmitter.once(event, fn);
        tryOnUnmounted(() => off());
    };

    /**
     * Calls each of the listeners registered for a given event.
     */
    const emit = (event: string | symbol, ...args: any[]): boolean => {
        eventEmitter.emit(event, ...args);
        return true;
    };

    return {
        on,
        once,
        emit,
    };
}
