<template>
    <TransitionGroup
        name="expand-transition"
        v-on:before-enter="onBeforeEnter"
        v-on:enter="onEnter"
        v-on:after-enter="onAfterEnter"
        v-on:enter-cancelled="onEnterCancelled"

        v-on:before-leave="onBeforeLeave"
        v-on:leave="onLeave"
        v-on:after-leave="onAfterLeave"
        v-on:leave-cancelled="onLeaveCancelled"
    >
        <slot />
    </TransitionGroup>
</template>

<script lang="ts" setup>
    import { isDefined } from '@vueuse/core';
    import type { HTMLExpandElement } from '@/components/transitions/ExpandTransition.vue';
    import { capitalize } from '@/core/lib/string';

    const props = defineProps<{
        x?: boolean;
    }>();

    const emit = defineEmits(['after-enter', 'after-leave']);

    const sizeProperty = props.x ? 'width' : 'height' as 'width' | 'height';
    const marginProperties = (props.x ? ['marginLeft', 'marginRight'] : ['marginTop', 'marginBottom']) as ('marginLeft' | 'marginRight' | 'marginTop' | 'marginBottom')[];
    const offsetProperty = `offset${capitalize(sizeProperty)}` as 'offsetHeight' | 'offsetWidth';

    function onBeforeEnter(_el: Element) {
        const el = _el as HTMLExpandElement; // workaround for TransitionGroup hook typing issue

        el._initialStyle = {
            transition: el.style.transition,
            overflow: el.style.overflow,
            [sizeProperty]: el.style[sizeProperty],
        };
        marginProperties.forEach((marginProperty) => {
            el._initialStyle![marginProperty] = el.style[marginProperty];
        });
    }

    function onEnter(_el: Element) {
        const el = _el as HTMLExpandElement; // workaround for TransitionGroup hook typing issue

        const initialStyle = el._initialStyle!;

        el.style.setProperty('transition', 'none', 'important');
        // Hide overflow to account for collapsed margins in the calculated height
        el.style.overflow = 'hidden';
        const offset = `${el[offsetProperty]}px`;

        el.style[sizeProperty] = '0';
        marginProperties.forEach((marginProperty) => {
            el.style[marginProperty] = '0';
        });

        // eslint-disable-next-line no-void
        void el.offsetHeight; // force reflow

        el.style.transition = initialStyle.transition;

        requestAnimationFrame(() => {
            el.style[sizeProperty] = offset;
        });
    }

    function onAfterEnter(_el: Element) {
        const el = _el as HTMLExpandElement; // workaround for TransitionGroup hook typing issue

        resetStyles(el);
        emit('after-enter');
    }

    const onEnterCancelled = onAfterEnter;

    function onBeforeLeave(_el: Element) {
        const el = _el as HTMLExpandElement; // workaround for TransitionGroup hook typing issue

        el._initialStyle = {
            transition: '',
            overflow: el.style.overflow,
            [sizeProperty]: el.style[sizeProperty],
        };
        marginProperties.forEach((marginProperty) => {
            el._initialStyle![marginProperty] = el.style[marginProperty];
        });
    }

    function onLeave(_el: Element) {
        const el = _el as HTMLExpandElement; // workaround for TransitionGroup hook typing issue

        el.style.overflow = 'hidden';
        el.style[sizeProperty] = `${el[offsetProperty]}px`;
        // eslint-disable-next-line no-void
        void el.offsetHeight; // force reflow

        requestAnimationFrame(() => {
            el.style[sizeProperty] = '0';
            marginProperties.forEach((marginProperty) => {
                el.style[marginProperty] = '0';
            });
        });
    }

    function onAfterLeave(_el: Element) {
        const el = _el as HTMLExpandElement; // workaround for TransitionGroup hook typing issue

        resetStyles(el);
        emit('after-leave');
    }

    const onLeaveCancelled = onAfterLeave;

    function resetStyles(_el: Element) {
        const el = _el as HTMLExpandElement; // workaround for TransitionGroup hook typing issue

        el.style.overflow = el._initialStyle!.overflow;

        const size = el._initialStyle![sizeProperty];
        if (isDefined(size)) el.style[sizeProperty] = size;

        marginProperties.forEach((marginProperty) => {
            const margin = el._initialStyle![marginProperty];
            if (isDefined(margin)) el.style[marginProperty] = margin;
        });

        delete el._initialStyle;
    }
</script>

<style lang="scss">
// TODO: fix should use scoped but need to apply style on slot element
.expand-transition-enter-active,
.expand-transition-leave-active {
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
}

.expand-transition-move {
    transition: transform 0.6s;
}
</style>
