import { ref } from 'vue';
import { defineContext } from '@/core/lib/defineContext';

export interface ITab {
    name: string | number;
}

export const TabsContext = defineContext('tabs', (
    {
        initialTab = null,
        initialExpand = true,
        enableCollapse = true,
    }: {
        initialTab?: ITab['name'] | null;
        initialExpand?: boolean;
        enableCollapse?: boolean;
    },
) => {
    const currentTab = ref<ITab['name'] | null>(initialTab);
    const tabs = ref<ITab[]>([]);
    const isExpand = ref(initialExpand);

    const registerTab = (tabName: string | number) => {
        tabs.value.push({ name: tabName });
        if (currentTab.value === null) {
            currentTab.value = tabName;
        }
    };

    const unregisterTab = (tabName: string | number) => {
        tabs.value = tabs.value.filter(tab => tab.name !== tabName);
        if (currentTab.value === tabName) {
            if (tabs.value.length > 0) {
                currentTab.value = tabs.value[0].name;
            } else {
                currentTab.value = null;
            }
        }
    };

    const updateExpand = (newValue: boolean) => {
        if (enableCollapse && isExpand.value !== newValue) {
            isExpand.value = newValue;
        }
    };

    const selectTab = (tabName: string | number) => {
        if (currentTab.value !== tabName) {
            currentTab.value = tabName;
            updateExpand(true);
        } else {
            updateExpand(!isExpand.value);
        }
    };

    return {
        tabs,
        registerTab,
        unregisterTab,
        currentTab,
        selectTab,
        isExpand,
        enableCollapse,
        updateExpand,
    };
});
