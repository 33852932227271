<template>
    <component
        v-bind:is="componentMapping[currentPopup]"
        v-if="currentPopup && componentMapping[currentPopup]"
    />
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import { computed, defineAsyncComponent, ref } from 'vue';
    import PopupBanner from '@/components/banner/PopupBanner.vue';
    import { Popup, SiteStyle } from '@/interface/enum';
    import { useBetBuilderTutorialStore } from '@/store/betBuilderTutorialStore';
    import { useCustomerStore } from '@/store/customerStore';
    import { usePopupBannerStore } from '@/store/popupBannerStore';

    const BetBuilderTutorial = defineAsyncComponent(() => import('@/components/tutorial/betBuilder/BetBuilderTutorial.vue'));

    const asiaOrder = [
        // Jackpot
        Popup.Banner,
        Popup.BetBuilderTutorial,
    ];

    const euroOrder = [
        // Jackpot
        Popup.Banner,
    ];

    const { siteStyle } = storeToRefs(useCustomerStore());
    const queue = ref<Popup[]>(siteStyle.value === SiteStyle.Euro ? euroOrder : asiaOrder);

    const componentMapping = {
        [Popup.Banner]: PopupBanner,
        [Popup.BetBuilderTutorial]: BetBuilderTutorial,
    };

    const currentPopup = computed(() => queue.value[0]);

    const showFunctionMapping: Record<Popup, () => Promise<void>> = {
        [Popup.Banner]: usePopupBannerStore().show,
        [Popup.BetBuilderTutorial]: useBetBuilderTutorialStore().show,
    };

    async function showPopup() {
        if (queue.value.length === 0) {
            return;
        }

        await showFunctionMapping[currentPopup.value]();
        queue.value = queue.value.slice(1);
        await showPopup();
    }

    showPopup();
</script>
