<template>
    <div class="marketGroup notranslate">
        <GroupTitle
            v-model:isExpanded="isExpanded"
            v-bind:expandedGtmLabel="isExpanded ? 'Collapse' : 'Expand'"
        >
            <template #title>
                <strong class="nameItem">
                    {{ marketTypeDisplay(market.marketGroup, market.marketType) }}
                </strong>
            </template>
        </GroupTitle>
        <ExpandTransition>
            <div v-if="isExpanded" class="groupOddsBox">
                <div
                    v-for="odds in market.odds"
                    v-bind:key="odds.id"
                    class="oddsBoxWrapper"
                    v-bind:class="{
                        'flex-row': layout === 'column',
                        'flex-column': layout === 'row',
                    }"
                >
                    <div
                        v-for="group in matchDetailLayout(event, odds)"
                        v-bind:key="group.title"
                        class="oddsBoxPack"
                        v-bind:class="{
                            'flex-row': layout === 'row',
                            'flex-column': layout === 'column',
                        }"
                    >
                        <div v-if="group.title" class="columnTitle">
                            {{ group.title }}
                        </div>
                        <Odds
                            v-for="price in group.prices"
                            v-bind:key="price.option"
                            v-bind:event="event"
                            v-bind:odds="odds"
                            v-bind:price="price"
                            v-bind:fixedWidth="false"
                            layout="horizontal"
                            v-bind:betOption="bigBetOptionFormatter(event, odds, price)"
                        />
                    </div>
                </div>
            </div>
        </ExpandTransition>
    </div>
</template>

<script setup lang="ts">
    import { ref, toRefs } from 'vue';
    import GroupTitle from '@/components/common/groupTitle/GroupTitle.vue';
    import Odds from '@/components/euroDisplay/Odds.vue';
    import { getMarketRule } from '@/components/euroDisplay/oddsDisplay/marketRules';
    import ExpandTransition from '@/components/transitions/ExpandTransition.vue';
    import type { IEventEuro } from '@/interface/IEvent';
    import type { IMarketGroup, IOdds } from '@/interface/IOdds';
    import type { MarketType } from '@/interface/enum';

    export interface IMarket {
        marketGroup: IMarketGroup;
        marketType: MarketType;
        odds: IOdds[];
    }

    const props = defineProps<{
        market: IMarket;
        event: IEventEuro;
    }>();

    const { market, event } = toRefs(props);

    const isExpanded = ref(true);

    const { layout, matchDetailLayout, bigBetOptionFormatter, marketTypeDisplay } = getMarketRule(market.value.marketType);
</script>

<style lang="scss" scoped>
    @import "@/components/euroDisplay/oddsDisplay/MatchDetailMarket";
</style>
