import { storeToRefs } from 'pinia';
import { computed, watch } from 'vue';
import { useCountdown } from '@/composable/useCountdown';
import { useEventEmitter } from '@/composable/useEventEmitter';
import { useIdle } from '@/composable/useIdle';
import { isWsConnected } from '@/core/oddsApi/apollo';
import { EventDateType, MarketPage, SiteStyle } from '@/interface/enum';
import { useAsiaSportMarketInfoStore } from '@/store/asiaSportMarketInfoStore';
import { useToggleStore } from '@/store/toggleStore';

export function useRefresher() {
    const eventEmitter = useEventEmitter();
    const { sportType, marketPage, navbarMarketList, isOutright, daylightType, eventDate } = storeToRefs(useAsiaSportMarketInfoStore());

    const isEventDateWithLive = computed(() => [EventDateType.All, EventDateType.Today].includes(eventDate.value));
    const isMarketPageWithEventDate = computed(() => [MarketPage.MixParlay, MarketPage.MyFavorites].includes(marketPage.value));
    const hasLive = computed(() => {
        if (marketPage.value === MarketPage.Live) return true;

        if (marketPage.value === MarketPage.Today || (isMarketPageWithEventDate.value && isEventDateWithLive.value)) {
            return navbarMarketList.value.some(x => x.marketPage === marketPage.value && x.liveEventCount > 0);
        }

        return false;
    });

    const _refresh = async (shouldCallApi = false) => {
        if (shouldCallApi || hasLive.value || !isWsConnected.value) {
            eventEmitter.emit('oddsDisplay:refreshEventList');
            eventEmitter.emit('oddsDisplay:refreshBetBuilderMatch', null);
            eventEmitter.emit('oddsDisplay:refreshOdds', null);
        }
    };

    const { liveRefreshDuration, nonLiveRefreshDuration } = storeToRefs(useToggleStore(SiteStyle.Asia));
    const refreshSecond = computed(() => {
        if (isOutright.value) return 120;
        return hasLive.value ? liveRefreshDuration.value : nonLiveRefreshDuration.value;
    });

    const { isLoading: isRefreshing, countdown, resetCountdown, trigger: refresh, forceTrigger: forceRefresh } = useCountdown(
        refreshSecond,
        _refresh,
        { minLoadingTime: 1000 },
    );

    watch([sportType, marketPage, daylightType, eventDate], () => {
        resetCountdown();
    });

    // Automatically recover from idle state with a force refresh
    // This is to prevent the user from losing data when they are idle
    const { onActive } = useIdle(60 * 1000);
    onActive(() => {
        // eslint-disable-next-line no-console
        console.log('OddsDisplay: Recovering from idle state...');
        refresh(true);
    });

    return {
        countdown,
        refresh,
        isRefreshing,
        forceRefresh,
    };
}
