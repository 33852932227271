import { storeToRefs } from 'pinia';
import type { Ref } from 'vue';
import { computed } from 'vue';
import { i18n } from '@/core/lib/i18n';
import type { IEvent, IEventEuro } from '@/interface/IEvent';
import { EnumLanguage, SportType } from '@/interface/enum';
import { useCustomerStore } from '@/store/customerStore';

const StatisticsWindow = {
    name: 'Statistics',
    config: 'scrollbars=no,resizable=no,width=1000,height=600',
};

export function useStatistics(event: Ref<IEvent | IEventEuro | null>) {
    const isStatisticsAvailable = computed(() => (event.value ? event.value.hasStatistics : false));
    const { brandName } = storeToRefs(useCustomerStore());

    function openMuayThaiStatistics() {
        const lang = i18n.locale.value === EnumLanguage.TH_TH ? 'th' : 'en';
        const url = `/mt-statistics.html?matchId=${event.value?.id}&lang=${lang}&brandType=${brandName.value}`;
        window.open(url, StatisticsWindow.name, StatisticsWindow.config);
    }

    const { isIndoUserByCountryCode } = storeToRefs(useCustomerStore());
    function _openStatistics() {
        const lang = i18n.locale.value.toLowerCase().replace('_', '-');
        const url = `/statistics.html?matchId=${event.value?.id}&lang=${lang}&pp=${isIndoUserByCountryCode.value}&brandType=${brandName.value}`;
        window.open(url, StatisticsWindow.name, StatisticsWindow.config);
    }

    function openStatistics() {
        if (!event.value) return;
        if (event.value.sportType === SportType.MuayThai) {
            openMuayThaiStatistics();
        } else {
            _openStatistics();
        }
    }

    return {
        isStatisticsAvailable,
        openStatistics,
    };
}
