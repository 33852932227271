import { CasinoAutoJoinType } from '@/interface/enum';

export function useCasinoCta(actionDetail: string) {
    const [_serviceProvider, _productType, _isPromotion] = actionDetail?.split(';') ?? '';
    const productType = parseInt(_productType);
    const isPromotion = _isPromotion === '1';
    const isCasino = _serviceProvider === '1';
    const autoJoinType = CasinoAutoJoinType.None;
    return {
        isCasino,
        productType,
        isPromotion,
        autoJoinType,
    };
}
