import type { Ref } from 'vue';
import { computed, watch } from 'vue';
import { addLiveCountToTodayCount } from '@/core/lib/sportMarketInfoHelper';
import { getMarketPageFromPresetAndDate } from '@/core/oddsApi/helpers';
import { EnumEventDateFilterType, EnumPresetFilterType, EnumTimeZoneType, useEventsCountInfoQuery } from '@/core/oddsApi/oddsApiType';
import type { IMarketInfo } from '@/interface/IMarketInfo';
import type { IZoneEventCount } from '@/interface/ISportMarketCount';
import type { PromotionZone } from '@/interface/enum';
import { EventDateType, MarketPage, SportType } from '@/interface/enum';
import { SportInfo } from '@/models/SportInfo';

const filters = [
    { presetFilter: EnumPresetFilterType.Live, date: EnumEventDateFilterType.All },
    { presetFilter: EnumPresetFilterType.NonLive, date: EnumEventDateFilterType.Today },
    { presetFilter: EnumPresetFilterType.NonLive, date: EnumEventDateFilterType.EarlyMarket },
    { presetFilter: EnumPresetFilterType.NonLive, date: EnumEventDateFilterType.Plus1 },
    { presetFilter: EnumPresetFilterType.NonLive, date: EnumEventDateFilterType.Plus2 },
    { presetFilter: EnumPresetFilterType.NonLive, date: EnumEventDateFilterType.Plus3 },
    { presetFilter: EnumPresetFilterType.NonLive, date: EnumEventDateFilterType.Plus4 },
    { presetFilter: EnumPresetFilterType.NonLive, date: EnumEventDateFilterType.Plus5 },
    { presetFilter: EnumPresetFilterType.NonLive, date: EnumEventDateFilterType.Plus6Over },
    { presetFilter: EnumPresetFilterType.Outright, date: EnumEventDateFilterType.All },
];

export function useSingleSportsEventCount({
    sportType,
    promotionZone,
    filteredLeagueIds,
    filteredLeagueNames,
    isEnabled,
}: {
    sportType: Ref<SportType>;
    promotionZone: Ref<PromotionZone>;
    filteredLeagueIds: Ref<number[]>;
    filteredLeagueNames: Ref<string[]>;
    isEnabled: Ref<boolean>;
}): IZoneEventCount {
    const variables = computed(() => ({
        query: {
            timeZone: EnumTimeZoneType.UTC__4,
            filters,
            sport: SportType.toOddsApi(sportType.value),
            tournamentIds: filteredLeagueIds.value,
            tournamentNames: filteredLeagueNames.value,
        },
    }));

    const { result, loaded, loading } = useEventsCountInfoQuery(
        variables,
        computed(() => ({
            pollInterval: 30000,
            enabled: isEnabled.value,
        })),
    );

    const marketList = computed(() => {
        const list = result.value?.map<IMarketInfo>((x) => {
            const res = getMarketPageFromPresetAndDate(x.queryFilter.presetFilter, x.queryFilter.date);

            if (!res) {
                return {
                    sportType: sportType.value,
                    marketPage: MarketPage.Today,
                    date: EventDateType.All,
                    nonLiveEventCount: 0,
                    liveEventCount: 0,
                    totalEventCount: 0,
                };
            }

            const { marketPage, date } = res;

            return {
                sportType: sportType.value,
                marketPage,
                date,
                nonLiveEventCount: Math.max(0, x.eventCount - x.liveEventCount),
                liveEventCount: Math.max(0, x.liveEventCount),
                totalEventCount: Math.max(0, x.eventCount),
            };
        }) ?? [];

        addLiveCountToTodayCount(list);

        return new Map<SportType, IMarketInfo[]>([[sportType.value, list]]);
    });

    const sportList = computed<SportInfo[]>(() => {
        const list = [];
        const outrightCount = result.value?.find(x => x.queryFilter.presetFilter === EnumPresetFilterType.Outright);
        const liveEventCount = result.value?.find(x => x.queryFilter.presetFilter === EnumPresetFilterType.Live)?.liveEventCount ?? 0;
        const nonLiveEventCount = result.value?.filter(x => x.queryFilter.presetFilter === EnumPresetFilterType.NonLive && [EnumEventDateFilterType.EarlyMarket, EnumEventDateFilterType.Today].includes(x.queryFilter.date)).reduce((acc, x) => acc + x.eventCount, 0);
        list.push(new SportInfo({
            sportType: sportType.value,
            liveEventCount,
            nonLiveEventCount,
            outrightLiveEventCount: outrightCount?.liveEventCount ?? 0,
            outrightNonLiveEventCount: (outrightCount?.eventCount ?? 0) - (outrightCount?.liveEventCount ?? 0),
        }));

        return list;
    });

    watch(promotionZone, () => {
        loaded.value = false;
    });

    return {
        sportList,
        marketList,
        loaded,
        loading,
    };
}
