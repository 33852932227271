<template>
    <div
        v-if="banners.length > 0"
        id="topBanner"
        v-gtm:type="'TopBanner'"
    >
        <Carousel
            v-bind:autoplay="5000"
            v-bind:wrapAround="banners.length > 1"
        >
            <Slide v-for="banner in banners" v-bind:key="banner.cdn">
                <div
                    v-gtm:content="banner.gtm"
                    v-gtm:@view
                    v-gtm:@click
                    class="topBanner"
                    v-bind:data-ga="'TopBanner'"
                    v-bind:data-img="banner.gtm"
                    v-bind:style="{ backgroundImage: `url(${banner.cdn})` }"
                    v-on:click="executeBannerAction(banner)"
                />
            </Slide>
            <template #addons="{ slidesCount }">
                <Pagination v-if="slidesCount > 1" />
            </template>
        </Carousel>
    </div>
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import { Carousel, Pagination, Slide } from 'vue3-carousel';
    import { useBannerAsia, useBannerEuro } from '@/composable/useBanners';
    import { BannerPosition, SiteStyle } from '@/interface/enum';
    import { useCustomerStore } from '@/store/customerStore';

    const { siteStyle } = storeToRefs(useCustomerStore());
    const { executeBannerAction, banners } = siteStyle.value === SiteStyle.Euro
        ? useBannerEuro(BannerPosition.EuroTop)
        : useBannerAsia(BannerPosition.AsiaTop);
</script>

<style lang="scss">
@import "vue3-carousel/dist/carousel";
@import "@/components/banner/TopBanner";
</style>
