import type { DocumentNode } from '@apollo/client';
import gql from 'graphql-tag';
import { storeToRefs } from 'pinia';
import type { Ref } from 'vue';
import { computed, ref } from 'vue';
import { deepCompareComputed } from '@/composable/deepCompareComputed';
import { getMarketPageFromPresetAndDate } from '@/core/oddsApi/helpers';
import type { EnumSportType, EventsCountInfoType, EventsCountInputType } from '@/core/oddsApi/oddsApiType';
import { EnumEventDateFilterType, EnumPresetFilterType, useApolloQuery } from '@/core/oddsApi/oddsApiType';
import type { IMarketInfo } from '@/interface/IMarketInfo';
import type { IEventCount, IZoneEventCount } from '@/interface/ISportMarketCount';
import { EventDateType, MarketPage, SiteStyle, SportType } from '@/interface/enum';
import { SportInfo } from '@/models/SportInfo';
import { useToggleStore } from '@/store/toggleStore';

const filters = [
    { presetFilter: EnumPresetFilterType.All, date: EnumEventDateFilterType.All },
    { presetFilter: EnumPresetFilterType.All, date: EnumEventDateFilterType.Today },
    { presetFilter: EnumPresetFilterType.NonLive, date: EnumEventDateFilterType.Plus1 },
    { presetFilter: EnumPresetFilterType.NonLive, date: EnumEventDateFilterType.Plus2 },
    { presetFilter: EnumPresetFilterType.NonLive, date: EnumEventDateFilterType.Plus3 },
    { presetFilter: EnumPresetFilterType.NonLive, date: EnumEventDateFilterType.Plus4 },
    { presetFilter: EnumPresetFilterType.NonLive, date: EnumEventDateFilterType.Plus5 },
    { presetFilter: EnumPresetFilterType.NonLive, date: EnumEventDateFilterType.Plus6Over },
    { presetFilter: EnumPresetFilterType.Outright, date: EnumEventDateFilterType.All },
];

export function useMultipleSportsEventCount({
    filteredLeagueIds,
    filteredLeagueNames,
    isEnabled,
}: {
    filteredLeagueIds: Ref<number[]>;
    filteredLeagueNames: Ref<string[]>;
    isEnabled: Ref<boolean>;
}): IZoneEventCount {
    const countMap = ref(new Map<SportType, IEventCount[]>());
    const { multipleSportsZoneSupportSports } = storeToRefs(useToggleStore(SiteStyle.Asia));

    const variables = computed(() => {
        if (filteredLeagueIds.value.length === 0 && filteredLeagueNames.value.length === 0) return {};

        return (multipleSportsZoneSupportSports.value.reduce((acc, sport) => {
            const sportType = SportType.toOddsApi(sport);
            acc[sportType] = {
                sport: sportType,
                filters,
                tournamentNames: filteredLeagueNames.value,
                tournamentIds: filteredLeagueIds.value,
            };
            return acc;
        }, {} as Record<string, EventsCountInputType>));
    });

    const document = computed(() => generateMultipleQuery(variables));
    // @ts-expect-error document can be null, but we use isEnabled to make sure it won't be executed
    const useMultipleEventCountQuery = useApolloQuery<Record<SportType, EventsCountInfoType[]>, Record<string, EventsCountInputType>>(document, { clientId: 'oddsApi' });
    const { onResult, loaded, loading } = useMultipleEventCountQuery(
        variables,
        deepCompareComputed(() => ({
            pollInterval: 30000,
            enabled: isEnabled.value && document.value !== null && multipleSportsZoneSupportSports.value.length > 0,
        })),
    );

    onResult((result) => {
        Object.entries(result ?? []).forEach(([key, value]) => {
            const sportType = SportType.fromOddsApi(key as EnumSportType);
            countMap.value.set(sportType, value.map<IEventCount>(x => ({
                sportType,
                eventCount: x.eventCount ?? 0,
                liveEventCount: x.liveEventCount ?? 0,
                date: EventDateType.fromOddsApi(x.queryFilter.date),
                marketPage: getMarketPageFromPresetAndDate(x.queryFilter.presetFilter, x.queryFilter.date)?.marketPage ?? MarketPage.Unknown,
            })));
        });
    });

    const sportList = computed(() => {
        const counts = Array.from(countMap.value.values()).flat();
        const singleAllCount = counts.filter(x => x.marketPage === MarketPage.All);
        return singleAllCount.map((x) => {
            const outrightCount = counts.find(count => count.marketPage === MarketPage.Outright && count.sportType === x.sportType);

            return new SportInfo({
                sportType: x.sportType,
                liveEventCount: x.liveEventCount,
                nonLiveEventCount: x.eventCount - x.liveEventCount,
                outrightLiveEventCount: outrightCount?.liveEventCount ?? 0,
                outrightNonLiveEventCount: (outrightCount?.eventCount ?? 0) - (outrightCount?.liveEventCount ?? 0),
            });
        });
    });

    const marketList = computed(() => {
        const marketInfoMap = new Map<SportType, IMarketInfo[]>();
        countMap.value.forEach((countInfo, sportType) => {
            marketInfoMap.set(sportType, countInfo.map<IMarketInfo>(x => ({
                sportType,
                marketPage: x.marketPage,
                date: x.date,
                nonLiveEventCount: x.eventCount - x.liveEventCount,
                liveEventCount: x.liveEventCount,
                totalEventCount: x.eventCount,
            })));
        });
        return marketInfoMap;
    });

    return {
        sportList,
        marketList,
        loaded,
        loading,
    };
}

function generateMultipleQuery(queries: Ref<Record<string, EventsCountInputType>>): DocumentNode | null {
    const keys = Object.keys(queries.value);
    if (keys.length === 0) return null;

    const queriesString = keys.map(key => `${key}: eventsCountInfo(query: $${key}) {
            eventCount
            liveEventCount
            queryFilter {
                presetFilter
                date
            }
        }`);

    const variableDefinitions = keys.map(key => `$${key}: EventsCountInputType!`).join(', ');

    const document = `
        query MultipleEventsCountInfoQuery(${variableDefinitions}) {
            ${queriesString.join('\n')}
        }
    `;

    return gql`${document}`;
}
