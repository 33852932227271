<template>
    <SvgIcon
        class="svgIcon-hotTag"
        v-bind:name="name"
    />
</template>

<script lang="ts" setup>
    import { computed } from 'vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import { i18n } from '@/core/lib/i18n';
    import { EnumLanguage } from '@/interface/enum';

    const name = computed(() => {
        const isSupportMultiLanguage = [EnumLanguage.JA_JP, EnumLanguage.KO_KR, EnumLanguage.MY_MM, EnumLanguage.TH_TH, EnumLanguage.ZH_CN].includes(i18n.locale.value);
        const lang = isSupportMultiLanguage ? i18n.locale.value.replace('_', '-') : EnumLanguage.EN;
        return `icon-tag-hot-${lang.toLowerCase()}`;
    });
</script>

<style lang="scss" scoped>
.svgIcon.svgIcon-hotTag {
    width: 32px;
    height: 16px;
}
</style>
