<template>
    <VTable
        v-bind:rounded="isMyBets"
        v-bind:striped="isMyBets"
        class="notranslate"
    >
        <VTableRow v-bind:class="{ 'vTable-sticky': isMyBets }">
            <VTableHeader
                v-for="column in columns"
                v-bind:key="column.name"
                v-bind:width="column.width"
                v-bind:style="{ 'min-width': `${column.minWidth}px` }"
            >
                <div v-if="column.tooltips" class="header">
                    {{ column.name }}
                    <VTooltip v-if="column.tooltips" v-bind:direction="isMyBets ? 'top' : 'bottom'">
                        <SvgIcon
                            class="header_icon"
                            name="icon-system-cycle-info"
                        />
                        <template #content>
                            <div v-for="(tooltip, index) in column.tooltips" v-bind:key="index">
                                {{ tooltip }}
                            </div>
                        </template>
                    </VTooltip>
                </div>
                <template v-else>
                    {{ column.name }}
                </template>
            </VTableHeader>
        </VTableRow>
        <VTableRow v-if="!isLoaded">
            <VTableCell v-bind:colspan="columns.length" class="noBets">
                <div class="loadingOverlay">
                    <StaticSpinner />
                </div>
            </VTableCell>
        </VTableRow>
        <VTableRow v-else-if="bets.length === 0">
            <VTableCell v-bind:colspan="columns.length" class="noBets">
                <strong>
                    {{ i18n.t('no_bets_found') }}
                </strong>
            </VTableCell>
        </VTableRow>
        <template v-else>
            <MyBetRow
                v-for="(bet, index) in bets"
                v-bind:key="`${statusFilter}.${bet.transactionId}.${bet.mainBetStatus}`"
                v-bind:bet="bet"
                v-bind:index="index"
                v-bind:bgColor="isMyBets ? index % 2 === 0 ? 'indigo-100' : 'gray-100' : 'white'"
            />
            <VTableRow
                v-if="isMyBets && statusFilter === MyBetsStatusFilter.Settled"
                bgColor="highlight"
                class="total"
            >
                <VTableCell v-bind:colspan="columns.length - 2" align="right">
                    <strong>{{ i18n.t('total') }}</strong>
                </VTableCell>
                <VTableCell align="right">
                    <strong>
                        <div v-bind:class="totalWinLoss < 0 ? 'negative' : ''">
                            {{ totalWinLossDisplay }}
                        </div>
                        <div class="subItem">
                            {{ totalCommissionDisplay }}
                        </div>
                    </strong>
                </VTableCell>
                <VTableCell />
            </VTableRow>
        </template>
        <VTableRow v-if="isMyBets" bgColor="tableFooter">
            <VTableCell
                v-bind:colspan="columns.length"
                class="footer"
                align="left"
            >
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span v-html="i18n.t('time_stamp')" />
            </VTableCell>
        </VTableRow>
    </VTable>
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import type { PropType } from 'vue';
    import { computed } from 'vue';
    import StaticSpinner from '@/components/common/StaticSpinner.vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import VTable from '@/components/common/table/VTable.vue';
    import VTableCell from '@/components/common/table/VTableCell.vue';
    import VTableHeader from '@/components/common/table/VTableHeader.vue';
    import VTableRow from '@/components/common/table/VTableRow.vue';
    import MyBetRow from '@/components/myBets/MyBetRow.vue';
    import { i18n } from '@/core/lib/i18n';
    import { VTooltip } from '@/directives/tooltip';
    import type { IBet } from '@/interface/IBet';
    import { MyBetsColumn, MyBetsStatusFilter, SiteStyle } from '@/interface/enum';
    import { useCustomerStore } from '@/store/customerStore';

    const props = defineProps({
        bets: {
            type: Array as PropType<IBet[]>,
            required: true,
        },
        isLoaded: {
            type: Boolean,
            default: true,
        },
        statusFilter: {
            type: Number,
            required: true,
        },
        isMyBets: {
            type: Boolean,
            default: true,
        },
    });

    const allColumns = computed(() => [
        {
            key: MyBetsColumn.Id,
            name: '#',
            width: 50,
        },
        {
            key: MyBetsColumn.Time,
            name: i18n.t('my_bets_header_id_time'),
            width: 106,
        },
        {
            key: MyBetsColumn.Selection,
            name: i18n.t('my_bets_header_selection'),
        },
        {
            key: MyBetsColumn.Odds,
            name: i18n.t('my_bets_header_odds'),
            minWidth: 75,
        },
        {
            key: MyBetsColumn.Stake,
            name: i18n.t('my_bets_header_stake'),
            tooltips: [i18n.t('stake_tooltip_1'), i18n.t('stake_tooltip_2')],
            minWidth: 92,
        },
        {
            key: MyBetsColumn.WinLoss,
            name: i18n.t('my_bets_header_win_loss'),
            tooltips: [i18n.t('win_loss_tooltip_1'), i18n.t('win_loss_tooltip_2')],
            minWidth: 92,
        },
        {
            key: MyBetsColumn.Status,
            name: i18n.t('my_bets_header_status'),
            width: 126,
        },
    ]);
    const columns = computed(() => (props.statusFilter === MyBetsStatusFilter.Running
        ? allColumns.value.filter(col => col.key !== MyBetsColumn.WinLoss)
        : allColumns.value));

    const totalWinLoss = computed(() => {
        if (props.statusFilter === MyBetsStatusFilter.Running) return 0;

        return props.bets.reduce((total, bet) => total + bet.playerWinLoss, 0);
    });

    const { formatMoneyWithDecimal } = useCustomerStore();
    const totalWinLossDisplay = computed(() => formatMoneyWithDecimal(totalWinLoss.value));

    const totalCommissionDisplay = computed(() => {
        if (props.statusFilter === MyBetsStatusFilter.Running) return 0;

        const total = props.bets.reduce((total, bet) => total + bet.playerCommission, 0);
        return formatMoneyWithDecimal(total);
    });

    const { siteStyle } = storeToRefs(useCustomerStore());
    if (siteStyle.value === SiteStyle.Euro) {
        import('@/components/myBets/BetsTable.Euro.scss');
    } else {
        import('@/components/myBets/BetsTable.scss');
    }
</script>
