<template>
    <div class="column column-left collapsedHint">
        <div class="component-controller">
            <VButton
                v-gtm:type="'LeftList'"
                v-gtm:label="isLayoutExpanded ? 'Collapse' : 'Expand'"
                v-gtm:@click
                rounded
                padding="0"
                class="button-collapsed"
                color="collapsedButtonBg"
                hoverColor="collapsedButtonHoverBg"
                v-on:click="toggleLayoutExpanded"
            >
                <SvgIcon
                    name="icon-system-bold-arrow"
                    v-bind:rotated="!isLayoutExpanded"
                />
            </VButton>
        </div>

        <div class="wrapper">
            <LeftSideBar />
            <CollapsedLeftSideBar />
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import VButton from '@/components/common/button/VButton.vue';
    import CollapsedLeftSideBar from '@/components/sideBar/CollapsedLeftSideBar.vue';
    import LeftSideBar from '@/components/sideBar/LeftSideBar.vue';
    import { useLayoutStore } from '@/store/layoutStore';

    const { isLayoutExpanded } = storeToRefs(useLayoutStore());
    const { toggleLayoutExpanded } = useLayoutStore();
</script>
