<template>
    <NavbarBlock
        v-bind:items="navBarItems"
        v-bind:marketDropdownPosition="EnumEuroMarketDropdownPosition.Middle"
    >
        <template #title>
            {{ leagueName }}
            <div class="leagueInfoIcon">
                <!-- src/views/euro/betting/league/Matches.vue might teleport league info icon here -->
            </div>
        </template>
    </NavbarBlock>
    <RouterView />
</template>

<script lang="ts" setup>
    import { computed, ref } from 'vue';
    import { useRouter } from 'vue-router';
    import { EuroDisplayContext } from '@/components/euroDisplay/EuroDisplayContext';
    import type { INavBarItem } from '@/components/navbarBlock/NavbarBlock.Euro.vue';
    import NavbarBlock from '@/components/navbarBlock/NavbarBlock.Euro.vue';
    import { useEuroRoute } from '@/composable/useEuroRoute';
    import { i18n } from '@/core/lib/i18n';
    import { EnumEuroMarketDropdownPosition, EuroDate, EuroRoutePage } from '@/interface/enum';

    const router = useRouter();
    const { route, sportType, leagueName } = useEuroRoute();

    EuroDisplayContext.provide(sportType, ref(false), ref(EuroDate.Unknown));

    const navBarItems = computed<INavBarItem[]>(() => [
        {
            title: i18n.t('market_page_matches'),
            isActive: () => route.name === EuroRoutePage.League_Matches,
            onClick: () => {
                router.push({ name: EuroRoutePage.League_Matches });
            },
        },
    ]);
</script>
