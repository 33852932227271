import type { MaybeRef } from '@vueuse/core';
import { get } from '@vueuse/core';
import { computed, nextTick, ref, shallowReactive, watch } from 'vue';
import { deepCompareComputed } from '@/composable/deepCompareComputed';
import { useState } from '@/composable/useState';
import { assignArray, createMap, remove, upsert } from '@/core/lib/array';
import { i18n } from '@/core/lib/i18n';
import { deepFreeze } from '@/core/lib/utils';
import { formatEvent } from '@/core/oddsApi/helpers/formatEvent';
import type {
    EnumOddsCategoryType,
    EventTypeFragment,
    OddsDisplayEventResultsTypeFragment,
    PresetFilterInputType,
} from '@/core/oddsApi/oddsApiType';
import {
    EnumTimeZoneType,
    useEventResultsQuery,
    useEventResultsSubscription,
    useEventsQuery,
    useEventsSubscription,
} from '@/core/oddsApi/oddsApiType';
import type { IEvent } from '@/interface/IEvent';
import { EnumLanguage, SportType } from '@/interface/enum';
import { useBehaviorStore } from '@/store/behaviorStore';

interface IUseEventsParam {
    sportType: MaybeRef<SportType>;
    presetFilter: MaybeRef<PresetFilterInputType>;
    oddsCategory: MaybeRef<EnumOddsCategoryType>;
    eventIds?: MaybeRef<number[] | null>;
    leagueIds?: MaybeRef<number[] | null>;
    leagueNames?: MaybeRef<string[] | null>;
    isActive?: MaybeRef<boolean>;
    isResetResults?: MaybeRef<boolean>;
}

export function useEvents({ sportType, oddsCategory, presetFilter, eventIds, leagueIds, leagueNames, isActive = true, isResetResults = true }: IUseEventsParam) {
    const variables = computed(() => ({
        query: {
            sport: SportType.toOddsApi(get(sportType)),
            filter: get(presetFilter),
            oddsCategory: get(oddsCategory),
            eventIds: get(eventIds),
            tournamentIds: get(leagueIds),
            tournamentNames: get(leagueNames),
            timeZone: EnumTimeZoneType.UTC__4,
            lang: EnumLanguage.toOddsApi(i18n.locale.value),
        },
    }));

    const [isEnabled, setIsEnabled] = useState(true);
    const refetch = async () => {
        if (isEnabled.value) {
            setIsEnabled(false);
            nextTick(() => setIsEnabled(true));
        }
    };

    const _rawEvents = shallowReactive<EventTypeFragment[]>([]);
    const _rawEventResults = shallowReactive<OddsDisplayEventResultsTypeFragment[]>([]);

    const events = ref<IEvent[]>([]);
    watch([_rawEvents, _rawEventResults], () => {
        const eventResultsCache = createMap(_rawEventResults, x => x.id, x => x.eventResults);

        const newEvents = _rawEvents.reduce<IEvent[]>((results, event) => {
            const eventResults = eventResultsCache.get(event.id);
            if (eventResults) { // only show events with eventResults
                results.push(deepFreeze(formatEvent(event, eventResults)));
            }
            return results;
        }, []);

        assignArray(events.value, newEvents, a => a.id);
    });

    const { loaded: eventsLoaded, loading: eventsLoading, error: eventsError, onResult } = useEventsQuery(
        variables,
        deepCompareComputed(() => ({
            enabled: get(isActive) && isEnabled.value,
        })),
    );

    const { onSubscription } = useEventsSubscription(
        variables,
        deepCompareComputed(() => ({
            enabled: get(isActive),
        })),
    );

    const { loaded: eventResultsLoaded, loading: eventResultsLoading, onResult: onEventResultsResult } = useEventResultsQuery(
        variables,
        deepCompareComputed(() => ({
            enabled: get(isActive) && isEnabled.value,
        })),
    );

    const { onSubscription: onEventResultSubscription } = useEventResultsSubscription(
        variables,
        deepCompareComputed(() => ({
            enabled: get(isActive),
        })),
    );

    const { addEventsQueryCount } = useBehaviorStore();
    onResult((result) => {
        if (result) {
            assignArray(_rawEvents, result, item => item.id);
        }
        addEventsQueryCount();
    });

    onEventResultsResult((result) => {
        if (result) {
            assignArray(_rawEventResults, result, item => item.id);
        }
    });

    onSubscription((subscription) => {
        upsert(_rawEvents, subscription?.updated ?? [], item => item.id);
        remove(_rawEvents, subscription?.deleted ?? [], (a, b) => a.id === b);
    });

    onEventResultSubscription((subscription) => {
        upsert(_rawEventResults, subscription?.updated ?? [], item => item.id);
        remove(_rawEventResults, subscription?.deleted ?? [], (a, b) => a.id === b);
    });

    watch(variables, () => {
        if (get(isResetResults)) {
            eventsLoaded.value = false;
            eventResultsLoaded.value = false;
            _rawEvents.length = 0;
            _rawEventResults.length = 0;
        }
    });

    const loaded = computed(() => eventsLoaded.value && eventResultsLoaded.value);
    const loading = computed(() => eventsLoading.value || eventResultsLoading.value);

    return {
        loaded,
        loading,
        eventsError,
        refetch,
        events,
    };
}
