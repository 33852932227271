/* eslint-disable vue/one-component-per-file */
/* eslint-disable max-classes-per-file */
/* eslint-disable no-empty-function */
import { useEventListener, useThrottleFn } from '@vueuse/core';
import type { Directive, PropType, Ref, Slot } from 'vue';
import { computed, createApp, defineComponent, h, onUnmounted, onUpdated, ref } from 'vue';
import '@/directives/tooltip.scss';
import { useToggle } from '@/composable/useToggle';
import { isMobile } from '@/core/lib/utils';

const DELAY = 150;

class EventManager<K extends keyof HTMLElementEventMap> {
    // eslint-disable-next-line no-useless-constructor
    constructor(private type: K) {}

    private callbacks = new WeakMap<HTMLElement, (evt: HTMLElementEventMap[K]) => void>();

    public add(el: HTMLElement, callback: (evt: HTMLElementEventMap[K]) => void) {
        el.addEventListener(this.type, callback, { passive: true });
        this.callbacks.set(el, callback);
    }

    public remove(el: HTMLElement) {
        const cb = this.callbacks.get(el);
        if (cb) {
            el.removeEventListener(this.type, cb);
            this.callbacks.delete(el);
        }
    }
}

class Rect {
    constructor(public left: number, public top: number, public width: number, public height: number) {}

    get right() {
        return this.left + this.width;
    }

    get bottom() {
        return this.top + this.height;
    }

    get centerX() {
        return this.left + this.width / 2;
    }

    get centerY() {
        return this.top + this.height / 2;
    }

    distanceOf(another: Rect) {
        const dx = this.centerX - another.centerX;
        const dy = this.centerY - another.centerY;
        return Math.sqrt(dx * dx + dy * dy);
    }

    // are the sides of one rectangle touching the other?
    checkCollision(another: Rect) {
        return this.right >= another.left // r1 right edge past r2 left
            && this.left <= another.right // r1 left edge past r2 right
            && this.bottom >= another.top // r1 top edge past r2 bottom
            && this.top <= another.bottom; // r1 bottom edge past r2 top
    }
}

const SCREEN_GAP = 16;
const OUTSIDE_SCREEN_MARGIN = 100;

type Direction = 'top' | 'bottom';
const DEFAULT_DIRECTION = 'bottom';

class TooltipManager {
    private static indicatorWidth = 12;
    private static indicatorHeight = 6;
    private static indicatorOffset = 16;

    private tooltip: Ref<{
        targetElement: Element;
        direction: Direction;
        content: string | Slot;
    } | null> = ref(null);

    constructor() {
        const div = document.createElement('div');
        div.id = 'v-tooltip-wrapper';
        document.body.appendChild(div);

        const { tooltip } = this;
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;

        createApp(defineComponent({
            setup() {
                let lastDisplayTime = new Date().getTime();
                const tooltipElement = ref<HTMLDivElement>();
                const indicatorElement = ref<HTMLDivElement>();
                const targetElement = computed(() => {
                    if (tooltip.value) {
                        return tooltip.value.targetElement as HTMLElement;
                    }
                    return null;
                });
                const isShow = computed(() => tooltip.value && !!tooltip.value.content);

                const adjustPosition = () => {
                    if (!isShow.value) {
                        lastDisplayTime = new Date().getTime();
                        return;
                    }

                    const tooltipEl = tooltipElement.value;
                    const indicatorEl = indicatorElement.value;
                    const tooltipValue = tooltip.value;
                    if (!tooltipEl || !indicatorEl || !tooltipValue) return;

                    const { targetElement: target, direction } = tooltipValue;
                    const targetClientRect = target.getBoundingClientRect();
                    const tooltipClientRect = tooltipEl.getBoundingClientRect();

                    const targetRect = new Rect(targetClientRect.left, targetClientRect.top, targetClientRect.width, targetClientRect.height);
                    const tooltipRect = new Rect(tooltipClientRect.left, tooltipClientRect.top, tooltipClientRect.width, tooltipClientRect.height);
                    const oldTooltipRect = new Rect(tooltipClientRect.left, tooltipClientRect.top, tooltipClientRect.width, tooltipClientRect.height);
                    const indicatorRect = new Rect(0, 0, TooltipManager.indicatorWidth, TooltipManager.indicatorHeight);

                    const leftBoundaryRect = new Rect(-OUTSIDE_SCREEN_MARGIN, 0, OUTSIDE_SCREEN_MARGIN + SCREEN_GAP, window.innerHeight);
                    const leftIndicatorBoundaryRect = new Rect(-OUTSIDE_SCREEN_MARGIN, 0, OUTSIDE_SCREEN_MARGIN + SCREEN_GAP + TooltipManager.indicatorOffset, window.innerHeight);
                    const rightBoundaryRect = new Rect(window.innerWidth - SCREEN_GAP, 0, SCREEN_GAP + OUTSIDE_SCREEN_MARGIN, window.innerHeight);
                    const rightIndicatorBoundaryRect = new Rect(window.innerWidth - SCREEN_GAP - TooltipManager.indicatorOffset, 0, SCREEN_GAP + TooltipManager.indicatorOffset + OUTSIDE_SCREEN_MARGIN, window.innerHeight);
                    const bottomBoundaryRect = new Rect(0, window.innerHeight - SCREEN_GAP - tooltipRect.height, window.innerWidth, SCREEN_GAP + OUTSIDE_SCREEN_MARGIN);
                    const indicatorDirection: Direction = targetRect.checkCollision(bottomBoundaryRect) ? 'top' : direction;

                    // move tooltip to the center of target
                    tooltipRect.left = targetRect.centerX - tooltipClientRect.width / 2;
                    indicatorRect.left = targetRect.centerX;

                    if (tooltipRect.checkCollision(leftBoundaryRect)) {
                        tooltipRect.left = leftBoundaryRect.right;
                    } else if (tooltipRect.checkCollision(rightBoundaryRect)) {
                        tooltipRect.left = rightBoundaryRect.left - tooltipClientRect.width;
                    }

                    if (indicatorRect.checkCollision(leftIndicatorBoundaryRect)) {
                        indicatorRect.left = leftIndicatorBoundaryRect.right;
                    } else if (indicatorRect.checkCollision(rightIndicatorBoundaryRect)) {
                        indicatorRect.left = rightIndicatorBoundaryRect.left;
                    }

                    if (indicatorDirection === 'top') {
                        tooltipRect.top = targetRect.top - tooltipClientRect.height - TooltipManager.indicatorHeight;
                        indicatorRect.top = tooltipRect.bottom;
                    } else {
                        tooltipRect.top = targetRect.bottom + TooltipManager.indicatorHeight;
                        indicatorRect.top = targetRect.bottom;
                    }

                    if (indicatorRect.left < targetRect.left + 4
                        || indicatorRect.left > targetRect.right - 4) {
                        tooltipEl.hidden = true;
                        indicatorEl.hidden = true;
                        return;
                    }

                    const timeToLastDisplayTooLong = (new Date().getTime() - lastDisplayTime) > 200;
                    const isDistanceTooFar = targetRect.distanceOf(oldTooltipRect) > 200;
                    const isTransition = !timeToLastDisplayTooLong && !isDistanceTooFar;

                    tooltipEl.style.transition = isTransition ? 'all 0.15s ease' : 'none';
                    tooltipEl.style.left = `${tooltipRect.left}px`;
                    tooltipEl.style.top = `${tooltipRect.top}px`;

                    indicatorEl.style.transition = isTransition ? 'all 0.15s ease' : 'none';
                    indicatorEl.style.left = `${indicatorRect.left}px`;
                    indicatorEl.style.top = `${indicatorRect.top}px`;
                    indicatorEl.classList.toggle('v-tooltip-indicator_top', indicatorDirection === 'top');
                    indicatorEl.classList.toggle('v-tooltip-indicator_bottom', indicatorDirection === 'bottom');
                };

                onUpdated(adjustPosition);

                const content = computed(() => {
                    if (tooltip.value === null) return h('span', '');
                    if (typeof tooltip.value.content === 'function') return tooltip.value.content();
                    return h('span', tooltip.value.content);
                });

                const triggeredTargetElement = ref<HTMLElement | null>(null);
                const [isTargetHovered, toggleIsTargetHovered] = useToggle(false);
                const [isTooltipHovered, toggleIsTooltipHovered] = useToggle(false);

                const onDelayedHovered = useThrottleFn(() => {
                    const newIsOpened = isTooltipHovered.value || isTargetHovered.value;
                    if (!newIsOpened && targetElement.value === triggeredTargetElement.value) {
                        self.hide();
                    }
                }, DELAY, true, false);

                useEventListener(tooltipElement, 'mouseenter', () => {
                    toggleIsTooltipHovered(true);
                    onDelayedHovered();
                }, { passive: true });
                useEventListener(tooltipElement, 'mouseleave', () => {
                    toggleIsTooltipHovered(false);
                    onDelayedHovered();
                }, { passive: true });
                useEventListener(indicatorElement, 'mouseenter', () => {
                    toggleIsTooltipHovered(true);
                    onDelayedHovered();
                }, { passive: true });
                useEventListener(indicatorElement, 'mouseleave', () => {
                    toggleIsTooltipHovered(false);
                    onDelayedHovered();
                }, { passive: true });
                useEventListener(targetElement, 'mouseenter', async () => {
                    triggeredTargetElement.value = targetElement.value;
                    toggleIsTargetHovered(true);
                    onDelayedHovered();
                }, { passive: true, capture: true });
                useEventListener(targetElement, 'mouseleave', () => {
                    triggeredTargetElement.value = targetElement.value;
                    toggleIsTargetHovered(false);
                    onDelayedHovered();
                }, { passive: true });

                return () => [
                    h(
                        h('div', { class: 'v-tooltip', ref: tooltipElement, hidden: !isShow.value }),
                        h('div', { class: 'v-tooltip-container' }, content.value),
                    ),
                    h('div', { class: 'v-tooltip-indicator', ref: indicatorElement, hidden: !isShow.value }),
                ];
            },
        })).mount(div);
    }

    public show(target: HTMLElement, direction: Direction, content: string | Slot) {
        this.tooltip.value = { targetElement: target, direction, content };
    }

    public hide(target?: HTMLElement) {
        if (
            !target // not specify target
            || (target && this.tooltip.value?.targetElement === target)
        ) {
            this.tooltip.value = null;
        }
    }
}

const mouseEnterManager = new EventManager('mouseenter');
const tooltipManager = new TooltipManager();

export const ToolTipDirective: Directive<HTMLElement, string> = {
    mounted(el, binding) {
        if (isMobile()) return;

        const direction: Direction = (binding.arg as Direction) || DEFAULT_DIRECTION;
        const callback = onMouseEnter(el, direction, binding.value);
        mouseEnterManager.add(el, callback);
    },
    updated(el, binding) {
        if (isMobile()) return;

        mouseEnterManager.remove(el);

        const direction: Direction = (binding.arg as Direction) || DEFAULT_DIRECTION;
        const callback = onMouseEnter(el, direction, binding.value);
        mouseEnterManager.add(el, callback);
    },
    beforeUnmount(el) {
        if (isMobile()) return;

        tooltipManager.hide(el);
        mouseEnterManager.remove(el);
    },
};

export const VTooltip = defineComponent({
    name: 'VTooltip',
    props: {
        text: { type: String, default: '' },
        direction: { type: String as PropType<Direction>, default: DEFAULT_DIRECTION },
    },
    setup(props, { slots }) {
        let target: HTMLElement | null = null;

        const onMouseenter = (e: MouseEvent) => {
            if (!slots.content && !props.text) return;

            const el = e.currentTarget as HTMLElement;
            tooltipManager.show(el, props.direction, slots.content ?? props.text);

            // used for event deduplication
            if (target !== el) {
                target = el;

                onUnmounted(() => tooltipManager.hide(el));
            }
        };
        return () => {
            if (!('default' in slots)) return undefined;

            return slots.default?.().map(node => h(node, {
                onMouseenter,
            }));
        };
    },
});

function onMouseEnter(el: HTMLElement, direction: Direction, content: string | Slot) {
    return () => {
        tooltipManager.show(el, direction, content);
    };
}
