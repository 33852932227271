import { tryOnUnmounted } from '@vueuse/core';
import type { Ref } from 'vue';
import { computed, nextTick, watch } from 'vue';
import { useState } from '@/composable/useState';
import type { Serializer } from '@/composable/useStorage';
import { getDefaultSerializer } from '@/composable/useStorage';
import { hookBefore } from '@/core/lib/utils';

// @ts-expect-error we patch the method to mark it as patched
if (!localStorage.setItem.__patched__) {
    hookBefore(localStorage, 'setItem', (key: string, newValue: string) => {
        const oldValue = localStorage.getItem(key);
        const event = new StorageEvent('storage', {
            key,
            newValue,
            oldValue,
            storageArea: localStorage,
        });
        nextTick(() => window.dispatchEvent(event));
    });

    hookBefore(localStorage, 'removeItem', (key: string) => {
        const oldValue = localStorage.getItem(key);
        const event = new StorageEvent('storage', {
            key,
            newValue: undefined,
            oldValue,
            storageArea: localStorage,
        });
        nextTick(() => window.dispatchEvent(event));
    });

    // @ts-expect-error we patch the method to mark it as patched
    localStorage.setItem.__patched__ = true;
}

function tryParseFn<T>(decode: (input: string) => T) {
    return (str: string | null, defaultValue: T): T => {
        try {
            if (str === '' || str === null || str === undefined) {
                return defaultValue;
            }

            return decode(str);
        } catch (err) {
            console.error('useLocalStorage parse failed with value:', str, err);
            return defaultValue;
        }
    };
}

export function useLocalStorage<T>(key: string, defaultValue: T, options?: Serializer<T>): [Ref<T>, (newVal: T) => void] {
    const defaultSerializer = getDefaultSerializer(defaultValue);
    const decode = options?.decode ?? defaultSerializer.decode;
    const encode = options?.encode ?? defaultSerializer.encode;

    const initValue = localStorage.getItem(key) ?? '';
    const [rawValue, setRawValue] = useState<string>(initValue);
    watch(rawValue, value => localStorage.setItem(key, value));

    const tryParse = tryParseFn(decode);
    const target = computed<T>(() => tryParse(rawValue.value, defaultValue));

    const setValue = (newValue: T) => {
        const encoded = encode(newValue);
        localStorage.setItem(key, encoded);
        setRawValue(encoded);
    };
    const value = computed({
        get: () => target.value,
        set: setValue,
    });

    const eventCallback = (event: StorageEvent): void => {
        if (event.storageArea === localStorage && event.key === key) {
            setRawValue(event.newValue ?? '');
        }
    };

    window.addEventListener('storage', eventCallback);
    tryOnUnmounted(() => {
        window.removeEventListener('storage', eventCallback);
    });

    return [value, setValue];
}
