import { parseISOString } from '@/core/lib/date';
import { isMainMarket } from '@/core/lib/oddsHelper';
import type { Score } from '@/interface/ILiveScore';
import type { IBetBuilderResult, IMatchResult, IOutrightResult, IOutrightTournamentResult, IResult, ISelectionResult } from '@/interface/IResult';
import type { ResultType } from '@/interface/enum';
import { EventSettleStatus, FGLGResult, ScoringType, SelectionOutcome, SportType } from '@/interface/enum';
import type { MarketResultResponse, MatchResponse, MatchResultResponse2, SelectionResultResponse, TournamentResponse } from '@/interface/pollux';

const defaultMatchResult: IMatchResult = {
    sportType: SportType.Soccer,
    matchResultId: 0,
    betTypeGroupId: 0,
    betTypeGroupName: '',
    status: EventSettleStatus.Settled,
    homeOption: '',
    awayOption: '',
    scoringType: ScoringType.Football,
    gameScores: {},
    totalScores: {},
};

const formatMatchResult = (matchResult: MatchResultResponse2, sportType: SportType): IMatchResult => ({
    sportType,
    matchResultId: matchResult.matchResultId,
    betTypeGroupId: matchResult.betTypeGroupId,
    betTypeGroupName: matchResult.betTypeGroupName ?? '',
    status: matchResult.status,
    thirdPartyCode: matchResult.thirdPartyCode,
    homeOption: matchResult.homeOption ?? '',
    awayOption: matchResult.awayOption ?? '',
    // because cricket scoringType in database always be wrong
    scoringType: sportType === SportType.Cricket ? ScoringType.Cricket : ScoringType.fromPollux(matchResult.scoringType),
    gameScores: matchResult.gameScores as Record<string, Score>,
    totalScores: matchResult.scores as Partial<Record<ResultType, Score>>,
});
function formatSelectionResult(selectionResult: SelectionResultResponse | undefined): ISelectionResult {
    if (selectionResult) {
        return {
            outcome: SelectionOutcome.fromPollux(selectionResult.outcome),
            name: selectionResult.name,
        };
    }

    return {
        outcome: SelectionOutcome.NoOutcome,
        name: '',
    };
}

function getDefaultSelectionResult(): ISelectionResult {
    return {
        outcome: SelectionOutcome.NoOutcome,
        name: '',
    };
}

export function formatBetBuilderResults(results: MarketResultResponse[]): IBetBuilderResult[] {
    return results.map(result => ({
        point: result.point ?? null,
        marketType: {
            id: result.marketType.id,
            name: result.marketType.name,
        },
        isRefunded: result.isRefunded,
        selectionResult: formatSelectionResult(result.selectionResult),
        selectionResults: result.selectionResults?.map(formatSelectionResult) ?? [getDefaultSelectionResult()],
    }));
}

export const formatResults = (sportType: SportType, Matches: MatchResponse[]): IResult[] => Matches.map<IResult>((match: MatchResponse) => {
    const mainMarketMatchResult = match.results.find(result => isMainMarket(result.betTypeGroupId));
    return {
        sportType,
        leagueId: match.leagueId,
        leagueName: match.leagueName,
        matchId: match.matchId,
        matchCode: match.matchCode,
        showTime: parseISOString(match.showTime),
        showTimeDisplayType: match.showTimeDisplayType,
        homeTeam: match.homeTeamName,
        awayTeam: match.awayTeamName,
        mainMarketMatchResult: mainMarketMatchResult ? formatMatchResult(mainMarketMatchResult, sportType) : defaultMatchResult,
        newBetTypeMatchResults: match.results.filter(result => !isMainMarket(result.betTypeGroupId)).map(result => formatMatchResult(result, sportType)),
        firstGoal: FGLGResult.fromPollux(match.firstGoal),
        lastGoal: FGLGResult.fromPollux(match.lastGoal),
        hasBetBuilderResults: match.hasBetBuilderResults,
    };
});

export const formatOutrightResults = (tournament: TournamentResponse): IOutrightTournamentResult => ({
    id: tournament.id,
    name: tournament.name ?? '',
    eventDate: parseISOString(tournament.eventDate),
    hasNoWinner: tournament.hasNoWinner,
    outrights: (tournament.outrights ?? []).map<IOutrightResult>(o => ({
        id: o.id,
        matchCode: o.matchCode ?? '',
        eventDate: parseISOString(tournament.eventDate),
        result: o.result,
        teamId: o.teamId,
        teamName: o.teamName ?? '',
    })),
});
