<template>
    <div
        class="navbarItem hover_overlay"
        v-bind:class="getClasses"
        v-on:click="onclick"
    >
        <div
            class="navbarItem_content"
            v-bind:class="isShowCount ? 'navbarItem_content_hasCount' : '' "
        >
            {{ name }}
        </div>
        <Count
            v-if="isShowCount"
            class="navbarItem_count"
            v-bind:number="count"
            v-bind:bgColor="countBgColor"
            v-bind:textColor="countTextColor"
            v-bind:borderColor="countBorderColor"
        />
    </div>
</template>

<script lang="ts" setup>
    import type { PropType } from 'vue';
    import { computed } from 'vue';
    import Count from '@/components/common/Count.vue';
    import type { IColors, IRootObject } from '@/interface/sassVariables';

    const props = defineProps({
        name: {
            type: String,
            required: true,
        },
        isShowCount: {
            type: Boolean,
            default: false,
        },
        count: {
            type: Number,
            default: 0,
        },
        countBgColor: {
            type: String as PropType<keyof IColors | keyof IRootObject>,
            default: () => 'countBgColor',
        },
        countTextColor: {
            type: String as PropType<keyof IColors | keyof IRootObject>,
            default: () => 'countColor',
        },
        countBorderColor: {
            type: String as PropType<keyof IColors | keyof IRootObject>,
            default: () => 'nonLive',
        },
        isActive: {
            type: Boolean,
            required: true,
        },
        onclick: {
            type: Function as PropType<() => void | Promise<void>>,
            required: true,
        },
        isEuroSite: {
            type: Boolean,
            default: false,
        },
        hasBottomRow: {
            type: Boolean,
            default: true,
        },
    });

    const getClasses = computed(() => {
        if (!props.isActive) return [];

        const classes = [];
        if (props.hasBottomRow) {
            classes.push('navbarItem-activeTriangle');
        }
        if (props.isEuroSite) {
            classes.push('navbarItem-active');
        }
        return classes;
    });
</script>

<style lang="scss" scoped>
    @import "@/components/common/navbar/NavbarItem";
</style>
