import type { ComputedGetter, Ref } from 'vue';
import { readonly, shallowRef, watch } from 'vue';

/**
 * Computed value that initializes immediately, and future updates with `flush: 'post'` (after Vue component updates).
 *
 * The difference between this and using {@link https://vueuse.org/shared/computedEager computedEager} or `watchEffect` with `{ flush: 'post' }` option
 * is that they will first start with `undefined` and initializes after Vue component updates,
 * missing out on the `immediate: true` effect
 *
 * @see https://vuejs.org/guide/essentials/watchers.html#callback-flush-timing
 */
export function computedNextTick<T>(getter: ComputedGetter<T>): Readonly<Ref<T>> {
    const current = shallowRef();

    watch(getter, (newValue) => {
        current.value = newValue;
    }, {
        immediate: true,
        flush: 'post',
    });

    return readonly(current);
}
