<template>
    <div class="resultRow resultRow-header">
        <div class="resultColumn resultColumn-fluid name">
            {{ i18n.t('next_goal') }}
        </div>
        <div
            v-for="i in matchResults.length"
            v-bind:key="i"
            class="resultColumn"
            v-bind:style="{ width: `${columnWidth}px` }"
        >
            {{ i18n.t(`results_header_next_goal_${i}`) }}
        </div>
    </div>
    <div class="resultRow resultRow-content">
        <div
            v-bind:style="{ width: `${columnWidth}px` }"
            class="resultColumn resultColumn-fluid name"
        >
            {{ matchResults[0].homeOption }}
        </div>
        <div
            v-for="result in matchResultDisplay"
            v-bind:key="result.betTypeGroupId"
            class="resultColumn"
            v-bind:style="{ width: `${columnWidth}px` }"
        >
            {{ result.homeResult.resultDisplay }}
            <StatusTag
                v-if="result.homeResult.statusDisplay"
                v-bind:text="result.homeResult.statusDisplay"
                size="sm"
            />
        </div>
    </div>
    <div class="resultRow resultRow-content">
        <div
            v-bind:style="{ width: `${columnWidth}px` }"
            class="resultColumn resultColumn-fluid name"
        >
            {{ matchResults[0].awayOption }}
        </div>
        <div
            v-for="result in matchResultDisplay"
            v-bind:key="result.betTypeGroupId"
            class="resultColumn"
            v-bind:style="{ width: `${columnWidth}px` }"
        >
            {{ result.awayResult.resultDisplay }}
            <StatusTag
                v-if="result.awayResult.statusDisplay"
                v-bind:text="result.awayResult.statusDisplay"
                size="sm"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import type { PropType } from 'vue';
    import { computed } from 'vue';
    import StatusTag from '@/components/common/statusTag/StatusTag.vue';
    import { getColumnWidth } from '@/components/results/resultColumnHelper';
    import { i18n } from '@/core/lib/i18n';
    import { findFirstHalfStatusWithResult, findFirstHalfStatusWithoutResult, findFullTimeStatusWithResult, findFullTimeStatusWithoutResult } from '@/core/lib/matchStatusHelper';
    import type { IMatchResult } from '@/interface/IResult';
    import { ResultType, ResultsBlockType } from '@/interface/enum';

    import { useResultsStore } from '@/store/resultsStore';

    const props = defineProps({
        matchResults: {
            type: Array as PropType<IMatchResult[]>,
            required: true,
        },
    });

    const { sportType } = storeToRefs(useResultsStore());
    const columnWidth = computed(() => getColumnWidth(ResultsBlockType.NextGoal, sportType.value));

    const getScoreDisplay = (status: number, score: number | null) => {
        const statusWithoutResultDisplay = findFullTimeStatusWithoutResult(status) ?? findFirstHalfStatusWithoutResult(status);
        if (statusWithoutResultDisplay) {
            return {
                resultDisplay: null,
                statusDisplay: i18n.t(`settlement_status_${statusWithoutResultDisplay}`),
            };
        }

        const statusWithResultDisplay = findFullTimeStatusWithResult(status) ?? findFirstHalfStatusWithResult(status);
        return {
            resultDisplay: score?.toString() ?? '',
            statusDisplay: statusWithResultDisplay ? i18n.t(`settlement_status_${statusWithResultDisplay}`) : null,
        };
    };

    const matchResultDisplay = computed(() => props.matchResults.map(x => ({
        ...x,
        homeResult: getScoreDisplay(x.status, x.totalScores[ResultType.FT]?.home ?? null),
        awayResult: getScoreDisplay(x.status, x.totalScores[ResultType.FT]?.away ?? null),
    })));
</script>

<style lang="scss" scoped>
    @import "@/components/results/NewBetTypeResult";
</style>
