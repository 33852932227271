<template>
    <div class="selection">
        <div
            class="selection_line"
            v-bind:class="leg.isLive ? 'live' : ''"
        />
        <component
            v-bind:is="isNeedDelStatus ? 'del' : 'div'"
        >
            <ul>
                <li>
                    <strong>{{ leg.marketType.name }}</strong>
                </li>
                <li class="betOption">
                    <div class="contentGroup">
                        {{ leg.betTeam }}
                    </div>
                    <div v-if="leg.point" class="contentGroup">
                        @ {{ leg.point }}
                    </div>
                </li>
            </ul>
        </component>
    </div>
</template>

<script lang="ts" setup>
    import { computed, toRefs } from 'vue';
    import { invalidSubBetStatusList } from '@/components/myBets/betHelper';
    import type { IBetBuilderLeg } from '@/interface/betBuilder';

    const props = defineProps<{
        leg: IBetBuilderLeg;
        isInvalidStatus: boolean;
    }>();

    const { leg, isInvalidStatus } = toRefs(props);

    const isNeedDelStatus = computed(() => invalidSubBetStatusList.includes(leg.value.status) || isInvalidStatus.value);

</script>

<style lang="scss" scoped>
    @import "@/components/myBets/Selection";
</style>
