<template>
    <div class="breadcrumbs" v-bind:class="classes">
        <div class="breadcrumbs_container">
            <BreadcrumbsItem
                v-for="(breadcrumb, index) in breadcrumbs"
                v-bind:key="index"
                v-bind:breadcrumb="breadcrumb"
            />
        </div>
        <slot />
    </div>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import type { RouteLocationNamedRaw } from 'vue-router';
    import BreadcrumbsItem from '@/components/navbarBlock/euro/BreadcrumbsItem.vue';
    import { useEuroRoute } from '@/composable/useEuroRoute';
    import { i18n } from '@/core/lib/i18n';
    import type { IBreadcrumb } from '@/interface/IBreadcrumb';
    import { EuroRoutePage, SportType } from '@/interface/enum';

    const { route, sportType, regionId, regionName, leagueId, leagueName, eventId, eventName } = useEuroRoute();

    const props = defineProps({
        borderRadius: {
            type: Boolean,
            default: () => false,
        },
    });

    const breadcrumbMapping = computed<Partial<Record<EuroRoutePage, {title: string, route: RouteLocationNamedRaw}>>>(() => ({
        [EuroRoutePage.Home]: {
            title: i18n.t('main_header_default_page'),
            route: {
                name: EuroRoutePage.Home,
            },
        },
        [EuroRoutePage.InPlay]: {
            title: i18n.t('market_page_name_3'),
            route: {
                name: EuroRoutePage.InPlay,
            },
        },
        [EuroRoutePage.Multiples]: {
            title: i18n.t('market_page_name_4'),
            route: {
                name: EuroRoutePage.Multiples,
            },
        },
        [EuroRoutePage.Sport]: {
            title: i18n.t(`sport_type_name_${sportType.value}`),
            route: {
                name: EuroRoutePage.Sport,
                params: {
                    sport: SportType.toRoute(sportType.value),
                },
            },
        },
        [EuroRoutePage.Region]: {
            title: regionName.value.toString() ?? '',
            route: {
                name: EuroRoutePage.Region,
                params: {
                    sport: SportType.toRoute(sportType.value),
                    region: regionId.value,
                    regionName: regionName.value,
                },
            },
        },
        [EuroRoutePage.League]: {
            title: leagueName.value.toString() ?? '',
            route: {
                name: EuroRoutePage.League,
                params: {
                    sport: SportType.toRoute(sportType.value),
                    region: regionId.value,
                    regionName: regionName.value,
                    league: leagueId.value,
                    leagueName: leagueName.value,
                },
            },
        },
        [EuroRoutePage.MatchDetail]: {
            title: eventName.value.toString() ?? '',
            route: {
                name: EuroRoutePage.MatchDetail,
                params: {
                    sport: SportType.toRoute(sportType.value),
                    region: regionId.value,
                    regionName: regionName.value,
                    league: leagueId.value,
                    leagueName: leagueName.value,
                    eventId: eventId.value,
                    eventName: eventName.value,
                },
            },
        },
        [EuroRoutePage.Exchange]: {
            title: 'Exchange',
            route: {
                name: EuroRoutePage.Exchange,
            },
        },
    }));

    const breadcrumbs = computed<IBreadcrumb[]>(() => {
        const routeBreadcrumbs = route.meta.breadcrumbs ?? [];
        return routeBreadcrumbs.map(
            (breadcrumb, index) => ({
                title: breadcrumbMapping.value[breadcrumb]?.title ?? '',
                route: index < routeBreadcrumbs.length - 1
                    ? breadcrumbMapping.value[breadcrumb]?.route ?? null
                    : null,
            }),
        );
    });

    const classes = computed(() => {
        const result = [];
        if (props.borderRadius) result.push('borderRadius');
        return result;
    });
</script>

<style lang="scss" scoped>
    @import "@/components/navbarBlock/euro/Breadcrumbs";
</style>
