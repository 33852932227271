import { getDaysDiff, getHoursDiff } from '@/core/lib/date';
import { i18n } from '@/core/lib/i18n';
import { PriceStyle } from '@/interface/enum';

export const isVoucherValidForOdds = (price: number, priceStyle: PriceStyle) => {
    if (priceStyle === PriceStyle.Euro) {
        return price >= 1.5;
    }
    if (priceStyle === PriceStyle.Indo) {
        return price >= 1;
    }
    if (priceStyle === PriceStyle.Malay) {
        return price >= 0.5 || price < 0;
    }
    if (priceStyle === PriceStyle.HK) {
        return price >= 0.5;
    }
    return false;
};

export const isVoucherValidForMixMaxBet = (stake: number, minBet: number, maxBet: number) => {
    if (stake < minBet) return false;
    if (stake > maxBet) return false;
    return true;
};

export const getVoucherExpireText = (expiryDate: Date) => {
    const dayDiff = getDaysDiff(expiryDate, new Date());
    if (dayDiff > 0) {
        return i18n.t('voucher_expire_days', { number: dayDiff }, dayDiff);
    }

    const hoursDiff = getHoursDiff(expiryDate, new Date());
    if (hoursDiff >= 1) {
        return i18n.t('voucher_expire_hours', { number: hoursDiff }, hoursDiff);
    }

    return i18n.t('voucher_expire_soon');
};
