<template>
    <div
        ref="viewport"
        v-bind:style="{
            height: (ready ? viewportHeight2 : minHeight) + 'px',
            overflow: viewportHeight ? 'auto' : undefined,
            transition: ready ? 'height 0.15s linear 0s' : 'none',
            backgroundColor: ready ? '' : 'transparent',
        }"
    >
        <div
            v-bind:style="{
                height: screenHeight + 'px',
                overflow: 'hidden',
                position: 'relative',
                transition: ready ? 'height 0.15s linear 0s' : 'none',
            }"
        >
            <div ref="header" v-resize="onHeaderResize">
                <slot name="header" />
            </div>
            <template v-for="item, index in list">
                <div
                    v-if="$slots.placeholder"
                    v-bind:key="item[keyAttrName]"
                    v-bind:style="{
                        position: 'absolute',
                        transform: `translateY(${getRowTop(index)}px)`,
                        height: heightCache.get(item[keyAttrName]) + 'px',
                        overflow: 'hidden',
                        'content-visibility': 'auto',
                    }"
                >
                    <slot
                        name="placeholder"
                        v-bind:item="item"
                    />
                </div>
                <VirtualScrollItem
                    v-if="index >= startIdx && index < endIdx"
                    v-bind:key="item[keyAttrName]"
                    v-bind:item="item"
                    v-bind:onResize="onResize"
                    v-bind:translateY="getRowTop(index)"
                >
                    <slot
                        v-bind:item="item"
                        v-bind:index="index"
                    />
                </VirtualScrollItem>
            </template>
            <div ref="footer" v-resize="onFooterResize">
                <slot name="footer" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    /* eslint-disable no-console */
    import { debounceAfterFrame, debounceAnimationFrame } from '@sports-utils/timer';
    import { useEventListener } from '@vueuse/core';
    import type { PropType } from 'vue';
    import { computed, nextTick, onMounted, ref, toRefs, watch } from 'vue';
    import VirtualScrollItem from '@/components/virtualScroll/VirtualScrollItem.vue';

    type Size = { width: number, height: number }
    /** mark if source data has changed */
    let isDataDirty = true;
    /** height of the window */
    let windowHeight = window.innerHeight;
    /** used to determine the default value of padding. A ratio to the visible portion. */
    const defaultPaddingRatio = 0.5;
    /** the first index of the visible portion of list */
    const startIdx = ref(0);
    /** the last index+1 of the visible portion of list */
    const endIdx = ref(0);
    const ready = ref(false);
    watch(endIdx, async (value) => {
        if (!ready.value && value > 0) {
            await nextTick();
            ready.value = true;
        }
    });
    /** top location of startIdx on the viewport except for the header */
    let startTop = 0;
    /** top location of endIdx on the viewport except for the header */
    let endTop = 0;
    /** scroll top position of viewport */
    let scrollTop = 0;
    /** the y position of the viewport */
    let viewportTop = 0;
    /** height of the viewport */
    const viewportHeight2 = ref(0);
    /** height of the screen */
    const screenHeight = ref(0);
    /** reference to the viewport element */
    const viewport = ref<HTMLElement | null>(null);
    /** reference to the header element */
    const header = ref<HTMLElement | null>(null);
    /** reference to the footer element */
    const footer = ref<HTMLElement | null>(null);
    /** height of header */
    const headerHeight = ref(0);
    /** height of footer */
    const footerHeight = ref(0);
    const heightCache = new Map<number, number>();
    const props = defineProps({
        debug: { type: Boolean, default: false },
        /**
         * The big array data for virtual scroll.
         * Each element of the array corresponds to a row in the table.
         */
        list: { type: Array as PropType<any[]>, required: true },
        /**
         * Attribute name of the key of each item in the list.
         */
        keyAttrName: { type: String, required: true },
        /**
         * Function to get the default height of each item.
         * This is used to estimate the height of rows which
         * are not yet displayed.
         */
        defaultItemHeight: { type: Function as PropType<(item: any, index: number) => number>, required: true },
        /**
         * The height of the viewport. If omitted, summation of
         * all the height of list rows are used.
         */
        viewportHeight: { type: Number, default: null },
        /**
         * The size of gap between rows.
         *
         * @default 0
         */
        gapSize: { type: Number, default: 0 },
        /**
         * Additional size of the screen extended from the height of
         * the visible area. If omitted, it will be the smaller one of
         * browser screen height or viewport height multiplied by 0.5.
         */
        padding: { type: Number, default: null },
        /**
         * The hysteresis ratio is used to determine how far a user
         * needs to scroll before the virtual table updates its content.
         * If the hysteresis ratio is 0.5 (50%), this means that
         * the user needs to scroll through half of the `padding` value
         * for the virtual table to update and display new content.
         *
         * It must be in range [0, 1.0].
         *
         * @default 0.5
         */
        hysteresis: { type: Number, default: 0.5 },
        /**
         * If `true`, event listener will be added to the global window
         * so that virtual table is redrawn whenever whole browser screen
         * is scrolled or resized.
         *
         * @default true
         */
        addWindowEvent: { type: Boolean, default: true },
        /**
         * This property is watched for change and redraw() function is called
         * whenever any change is detected. The value of this property is not used.
         * list property is also watched but deep option of watcher API
         * is not used. So, changing deep element of list cannot be detected.
         */
        redrawSignal: { type: Number, default: 0 },
    });
    // eslint-disable-next-line vue/no-setup-props-destructure
    const { debug } = props;
    const {
        list,
        viewportHeight,
        padding,
        hysteresis,
        gapSize,
        redrawSignal,
    } = toRefs(props);
    const minHeight = computed(() => props.list.reduce((acc, item, ri) => {
        const itemHeight = props.defaultItemHeight(item, ri);
        return acc + itemHeight + props.gapSize;
    }, 0));
    /**
     * The cache of the height of each row and summation of heights.
     * For all ri = 0..(list.length-1)
     * rowInfo(ri*2) = h(ri) = height of list[ri]
     * rowInfo(ri*2+1) = sum(ri)
     * where sum(ri) is the summation of 0~ri rows:
     * sum(ri) = h(0) + h(1) + ... + h(ri)
     */
    const rowInfo = ref<number[]>([]);
    /** length cache of list */
    const listLength = ref(0);
    /**
     * We assume that 2 list has only 1 item diff and others keeping the order.
     * So we can use dichotomy algorithm to find changed one.
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function findListDiffIndex<T>(
        originList: T[],
        targetList: T[],
        getKey: (item: T) => number | string,
    ): { index: number, multiple: boolean } | null {
        if (debug) console.time('findListDiffIndex');

        const originLen = originList.length;
        const targetLen = targetList.length;

        if (originLen === 0 && targetLen === 0) {
            return null;
        }

        let shortList: T[];
        let longList: T[];

        if (originLen < targetLen) {
            shortList = originList;
            longList = targetList;
        } else {
            shortList = targetList;
            longList = originList;
        }

        function getItemKey(item: T) {
            if (item !== undefined) {
                return getKey(item);
            }
            return undefined;
        }

        // Loop to find diff one
        let diffIndex = -1;
        let multiple = Math.abs(originLen - targetLen) !== 1;
        for (let i = 0; i < longList.length; i += 1) {
            const shortKey = getItemKey(shortList[i]);
            const longKey = getItemKey(longList[i]);

            if (shortKey !== longKey) {
                diffIndex = i;
                multiple = multiple || shortKey !== getItemKey(longList[i + 1]);
                break;
            }
        }

        if (debug) console.timeEnd('findListDiffIndex');

        return diffIndex === -1 ? null : { index: diffIndex, multiple };
    }
    const updateRowInfo = () => {
        if (debug) console.time('updateRowInfo');
        const len = list.value.length;

        if (len === 0) {
            rowInfo.value = [];
            listLength.value = 0;
            return;
        }

        const oldLen = listLength.value;
        if (len !== oldLen) {
            rowInfo.value = Array(len * 2).fill(0);
            listLength.value = len;
        }

        let accumulateHeight = 0;
        let isHeightDirty = false;
        const listValue = list.value;
        const gapSizeValue = gapSize.value;

        for (let ri = 0; ri < len; ri++) {
            const item = listValue[ri];
            const newHeight = heightCache.get(item[props.keyAttrName])
                ?? props.defaultItemHeight(item, ri);
            const originalHeight = rowInfo.value[ri * 2];
            const diff = newHeight - originalHeight;
            if (diff !== 0) {
                rowInfo.value[ri * 2] = newHeight;
                isHeightDirty = true;
            }
            accumulateHeight += newHeight + gapSizeValue;
            if (isHeightDirty) {
                rowInfo.value[ri * 2 + 1] = accumulateHeight;
            }
        }

        if (debug) console.timeEnd('updateRowInfo');
    };
    /** update rowInfo according to the current value of list */
    const update = () => {
        updateRowInfo();
    };
    /** calculate h(0)+h(1)+...+h(ri-1) */
    const getRowTop = (rowIdx: number) => {
        if (rowIdx <= 0) return 0;
        if (rowIdx >= listLength.value) return rowInfo.value[listLength.value * 2 - 1];
        const ri = Math.min(rowIdx, listLength.value);
        return rowInfo.value[ri * 2 - 1] || 0;
    };
    /**
     * find ri where getRowTop(ri) <= rowTop < getRowTop(ri+1)
     * with binary search
     */
    const getRowIdx = (_name: string, rowTop: number) => {
        const len = listLength.value;
        if (len === 0) return -1;
        if (rowTop <= getRowTop(0)) return -1;
        if (rowTop >= getRowTop(len)) return len;

        let l = 0;
        let r = len - 1;
        let m = 0;
        let mTop = 0;
        let mBottom = 0;
        while (l <= r) {
            m = Math.floor((l + r) / 2);
            mTop = getRowTop(m);
            mBottom = getRowTop(m + 1);
            if (rowTop < mTop) {
                r = m - 1;
            } else if (rowTop >= mBottom) {
                l = m + 1;
            } else {
                return m;
            }
        }

        return l;
    };
    /** calculate h(0)+h(1)+...+h(list.length-1) */
    const getTotalRowHeight = () => getRowTop(listLength.value);
    /** get first index of the visible portion of list */
    const getStartIdx = (topOffset: number, padding2: number) => {
        const paddedTop1 = topOffset - (padding2 * (1 + hysteresis.value));
        const paddedTop2 = topOffset - (padding2 * (1 - hysteresis.value));

        if (isDataDirty || startTop < paddedTop1 || startTop > paddedTop2) {
            return Math.max(0, getRowIdx('start', topOffset - padding2));
        }
        return startIdx.value;
    };
    /** get last index+1 of the visible portion of list */
    const getEndIdx = (bottomOffset: number, padding2: number) => {
        const paddedBottom1 = bottomOffset + (padding2 * (1 - hysteresis.value));
        const paddedBottom2 = bottomOffset + (padding2 * (1 + hysteresis.value));

        if (isDataDirty || endTop < paddedBottom1 || endTop > paddedBottom2) {
            const len = listLength.value;
            return Math.min(len, getRowIdx('end', bottomOffset + padding2) + 1);
        }
        return endIdx.value;
    };
    /** calculate visible portion of list and update visible list */
    const redraw = () => {
        if (debug) console.time('VScroll.redraw');
        update();
        screenHeight.value = headerHeight.value + getTotalRowHeight() + footerHeight.value;
        viewportHeight2.value = viewportHeight.value ?? screenHeight.value;
        const padding2 = padding.value ?? Math.min(windowHeight, viewportHeight2.value) * defaultPaddingRatio;
        const visibleViewportTop = Math.max(0, -viewportTop);
        const topOffset = scrollTop + visibleViewportTop - headerHeight.value;
        const newStartIdx = getStartIdx(topOffset, padding2);
        const visibleViewportHeight = Math.min(viewportHeight2.value, windowHeight - viewportTop);
        const bottomOffset = scrollTop + visibleViewportHeight - headerHeight.value;
        const newEndIdx = Math.max(newStartIdx, getEndIdx(bottomOffset, padding2));
        if (isDataDirty || newStartIdx !== startIdx.value || newEndIdx !== endIdx.value) {
            startIdx.value = newStartIdx;
            endIdx.value = newEndIdx;
            startTop = getRowTop(newStartIdx);
            endTop = getRowTop(newEndIdx);
            isDataDirty = false;
        }
        if (debug) console.timeEnd('VScroll.redraw');
    };
    /** debounced version of redraw */
    const onRedraw = debounceAnimationFrame(redraw);

    watch(list, () => {
        isDataDirty = true;
        onRedraw();
    });
    watch(headerHeight, onRedraw);
    watch(footerHeight, onRedraw);
    watch(viewportHeight, onRedraw);
    watch(padding, onRedraw);
    watch(hysteresis, onRedraw);

    useEventListener('resize', () => {
        windowHeight = window.innerHeight;
    }, { passive: true });

    const updateViewport = () => {
        if (!viewport.value) return;
        if (debug) console.time('VScroll.updateViewport');
        viewportTop = viewport.value.getBoundingClientRect().y;
        scrollTop = viewport.value.scrollTop;
        if (debug) console.timeEnd('VScroll.updateViewport');
        onRedraw();
    };
    const onUpdateViewport = debounceAfterFrame(updateViewport);
    if (props.addWindowEvent) {
        useEventListener('scroll', onUpdateViewport, { passive: true });
        useEventListener('resize', onUpdateViewport, { passive: true });
    }
    onMounted(onUpdateViewport);

    watch(redrawSignal, () => {
        isDataDirty = true;
        return onUpdateViewport();
    });

    const onResize = (row: any, { height }: Size) => {
        const id = row[props.keyAttrName];
        if (heightCache.get(id) === height) return;
        isDataDirty = true;
        heightCache.set(id, height);
        onRedraw();
    };
    const updateHeaderHeight = ({ height }: Size) => {
        headerHeight.value = height;
    };
    const onHeaderResize = debounceAfterFrame(updateHeaderHeight);
    const updateFooterHeight = ({ height }: Size) => {
        footerHeight.value = height;
    };
    const onFooterResize = debounceAfterFrame(updateFooterHeight);
</script>
