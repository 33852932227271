<template>
    <div
        v-gtm:type="'MatchCard'"
        class="leagueGroup"
    >
        <MatchLoading v-if="loading" />
        <NoData v-else-if="groups.length === 0" v-bind:dataType="NoDataType.Events" />
        <VirtualScroll
            v-else-if="enableVirtualScroll"
            v-bind:list="groups"
            keyAttrName="id"
            v-bind:defaultItemHeight="(item: any, index: number) => getGroupElementHeight(initialIsExpanded(index) ? item.events.length : 0)"
        >
            <template #default="{ item }">
                <GroupedMatchesDisplay
                    v-bind:id="item.id"
                    v-bind:title="item.title"
                    v-bind:info="item.info"
                    v-bind:events="item.events"
                    v-bind:isExpanded="expandedMapping[item.id] ?? false"
                    v-bind:viewAll="groupViewAll"
                    v-on:update:isExpanded="toggleExpanded(item.id)"
                />
            </template>
        </VirtualScroll>
        <template v-else>
            <GroupedMatchesDisplay
                v-for="(group, index) in groups"
                v-bind:id="group.id"
                v-bind:key="`${index}-${group.title}`"
                v-bind:title="group.title"
                v-bind:info="group.info"
                v-bind:events="group.events"
                v-bind:isExpanded="expandedMapping[group.id]"
                v-bind:viewAll="groupViewAll"
                v-on:update:isExpanded="toggleExpanded(group.id)"
            />
        </template>
    </div>
</template>

<script lang="ts" setup>
    import { ref, toRefs, watch } from 'vue';
    import type { RouteLocationNamedRaw } from 'vue-router';
    import { EuroDisplayContext } from '@/components/euroDisplay/EuroDisplayContext';
    import GroupedMatchesDisplay from '@/components/euroDisplay/GroupedMatchesDisplay.vue';
    import MatchLoading from '@/components/euroDisplay/MatchLoading.vue';
    import NoData from '@/components/euroDisplay/NoData.vue';
    import VirtualScroll from '@/components/virtualScroll/VirtualScroll.vue';
    import type { IEventEuro } from '@/interface/IEvent';
    import { EuroMarket, NoDataType } from '@/interface/enum';

    const props = withDefaults(defineProps<{
        groups: {
            id: number;
            title: string;
            info?: string;
            events: IEventEuro[];
        }[];
        loading: boolean;
        initialIsExpanded?: (index: number) => boolean;
        groupViewAll?: ((group: IEventEuro[]) => RouteLocationNamedRaw);
        enableVirtualScroll?: boolean;
    }>(), {
        initialIsExpanded: () => true,
        groupViewAll: undefined,
        enableVirtualScroll: false,
    });

    const { groups, loading } = toRefs(props);

    const { sportType, market, date } = EuroDisplayContext.inject();

    const getGroupElementHeight = (eventCount: number) => {
        const titleHeight = 35;
        const rowHeight = 56;
        const borderHeight = 1;
        const rowCount = market.value.market === EuroMarket.GameLines ? 2 : 1;

        const eventHeight = rowCount * rowHeight + borderHeight;
        return titleHeight + eventCount * eventHeight;
    };

    const expandedMapping = ref<Record<number, boolean>>({});
    watch(sportType, () => {
        expandedMapping.value = {};
    });
    watch(date, () => {
        expandedMapping.value = {};
    });
    watch(groups, () => {
        groups.value.forEach((g, index) => {
            if (expandedMapping.value[g.id] === undefined) {
                expandedMapping.value[g.id] = props.initialIsExpanded(index);
            }
        });
    });

    function toggleExpanded(id: number) {
        expandedMapping.value[id] = !expandedMapping.value[id];
    }
</script>

<style lang="scss" scoped>
@import "@/components/euroDisplay/oddsDisplay/OddsDisplayBlock";
</style>
