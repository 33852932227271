import { debounceAnimationFrame } from '@sports-utils/timer';
import { useWindowSize } from '@vueuse/core';
import type { Ref } from 'vue';
import { onMounted, watch } from 'vue';

export function useLiveStreamResize(outerBox: Ref<HTMLDivElement | null>, innerBox: Ref<HTMLDivElement | null>) {
    const { width, height } = useWindowSize();
    const resizeHandler = debounceAnimationFrame(() => {
        if (outerBox.value && innerBox.value) {
            // resize video back to 0 first to prevent child element bigger than container
            innerBox.value.style.width = '0';
            innerBox.value.style.height = '0';

            const boxRect = outerBox.value.getBoundingClientRect();
            const maxHeight = boxRect.height;
            const maxWidth = boxRect.width;
            const newHeight = maxWidth * (9 / 16);

            if (newHeight < maxHeight) {
                innerBox.value.style.width = `${maxWidth}px`;
                innerBox.value.style.height = `${newHeight}px`;
            } else {
                const newWidth = maxHeight / (9 / 16);
                innerBox.value.style.width = `${newWidth}px`;
                innerBox.value.style.height = `${maxHeight}px`;
            }

            // trigger resize events
            window.dispatchEvent(new Event('resize'));
        }
    });

    watch([width, height], resizeHandler);
    onMounted(() => {
        resizeHandler();
    });
}
