<template>
    <OddsDisplayBlock
        v-bind:groups="groups"
        v-bind:loading="!loaded"
        v-bind:initialIsExpanded="(index: number) => index < 3"
        v-bind:groupViewAll="groupViewAll"
        enableVirtualScroll
    />
</template>

<script lang="ts" setup>
    import orderBy from 'lodash/orderBy';
    import { computed } from 'vue';
    import { EuroDisplayContext } from '@/components/euroDisplay/EuroDisplayContext';
    import OddsDisplayBlock from '@/components/euroDisplay/oddsDisplay/OddsDisplayBlock.vue';
    import { useEuroRouter } from '@/composable/useEuroRouter';
    import { groupByWithOrder } from '@/core/lib/array';
    import { useEvents } from '@/core/oddsApi/composable/useEvents.euro';
    import { getFilterFromEuroDate } from '@/core/oddsApi/helpers';
    import { EnumOddsCategoryType } from '@/core/oddsApi/oddsApiType';
    import type { IEventEuro } from '@/interface/IEvent';

    const { sportType, date } = EuroDisplayContext.inject();
    const presetFilter = computed(() => getFilterFromEuroDate(date.value));
    const oddsCategory = EnumOddsCategoryType.All;

    const { events, loaded } = useEvents({
        sportType,
        presetFilter,
        oddsCategory,
    });

    const groups = computed(() => {
        const sortedEvents = orderBy(events.value, e => e.eventCode);
        const groups = groupByWithOrder(sortedEvents, e => e.league.id);
        return groups.map((group) => {
            const events = orderBy(group, [e => e.isLive], ['desc']);
            return {
                id: events[0].id,
                events,
                title: events[0].league.name,
                info: events[0].league.info ?? undefined,
            };
        });
    });

    const { getLeagueRoute } = useEuroRouter();
    const groupViewAll = (events: IEventEuro[]) => getLeagueRoute(sportType.value, events[0].league);
</script>
