import { usePlatform } from '@/composable/usePlatform';
import { PlaceOrderStatus } from '@/interface/enum';

export function usePlaceBetErrorHandler() {
    function doErrorHandleActions(status: PlaceOrderStatus, actions: { cancelTicket: () => void, openTicket: () => Promise<void> }) {
        const { logout } = usePlatform();
        switch (status) {
            case PlaceOrderStatus.ThirdPartyTimeout:
            case PlaceOrderStatus.EventClosed:
            case PlaceOrderStatus.InvalidPrice:
            case PlaceOrderStatus.PriceSuspend:
                actions.cancelTicket();
                break;
            case PlaceOrderStatus.ScoreChanged:
            case PlaceOrderStatus.HdpPointChange:
            case PlaceOrderStatus.PriceChange:
            case PlaceOrderStatus.RetryOpenTicket:
                actions.openTicket();
                break;
            case PlaceOrderStatus.MemberClosed:
            case PlaceOrderStatus.HierarchyClosed:
                logout();
                break;
            default:
                break;
        }
    }
    return {
        doErrorHandleActions,
    };
}
