import { defineStore, storeToRefs } from 'pinia';
import { computed, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import { NonNull } from '@/core/lib/array';
import { scrollToTopImmediate } from '@/core/lib/dom';
import { i18n } from '@/core/lib/i18n';
import type { ISportInfo, ISportListItem } from '@/interface/ISport';
import { EventDateType, MarketPage, PromotionZone, RoutePage, SiteStyle, SportType } from '@/interface/enum';
import { useAsiaSportMarketInfoStore } from '@/store/asiaSportMarketInfoStore';
import { useToggleStore } from '@/store/toggleStore';

export const useSideBarStore = defineStore('SideBarStore', () => {
    const { setSportType, setPromotionZone, getPromotionZoneConfig, setMarketPage } = useAsiaSportMarketInfoStore();
    const {
        isPromotionZone,
        promotionZone,
        marketListMap,
        isMixParlay,
        sportType,
        sportsInfoDisplay,
        firstHasMixParlayCountSport,
        isMyFavorite,
        firstHasFavoritesCountSport,
        favoriteSportsInfoDisplay,
        favoriteCount,
    } = storeToRefs(useAsiaSportMarketInfoStore());
    const { isMyFavoriteEnabled, isFairExchangeEnabled } = storeToRefs(useToggleStore(SiteStyle.Asia));

    const router = useRouter();
    function getFavoriteLink(): ISportListItem {
        const liveCount = favoriteSportsInfoDisplay.value.reduce((acc, cur) => acc + cur.liveEventCount, 0);
        const nonLiveCount = favoriteSportsInfoDisplay.value.reduce((acc, cur) => acc + cur.nonLiveEventCount, 0);
        const todayExpandedCount = favoriteSportsInfoDisplay.value.reduce((acc, cur) => acc + cur.todayExpandedCount, 0);

        return {
            name: i18n.t('tab_title_favorites'),
            icon: 'icon-system-star-full-color',
            isHot: false,
            isShowCount: true,
            isLive: favoriteSportsInfoDisplay.value.some(x => x.liveEventCount > 0),
            isActive: isMyFavorite.value,
            count: favoriteCount.value,
            liveCount,
            nonLiveCount,
            todayExpandedCount,
            onClick: async () => {
                router.push('/');
                await setPromotionZone(PromotionZone.None);
                await setSportType(firstHasFavoritesCountSport.value, MarketPage.MyFavorites);
                await nextTick();
                scrollToTopImmediate();
            },
            isDisabled: !isMyFavoriteEnabled.value,
            gtmLabel: 'MyFavorite',
        };
    }

    const isExchangePage = computed(() => router.currentRoute.value.name === RoutePage.Exchange);
    function getExchangeLink(): ISportListItem {
        return {
            name: i18n.t('exchange'),
            icon: 'icon-system-exchange',
            isHot: false,
            isShowCount: false,
            isLive: false,
            isActive: isExchangePage.value,
            count: 0,
            liveCount: 0,
            nonLiveCount: 0,
            todayExpandedCount: 0,
            onClick: async () => {
                router.push(RoutePage.Exchange);
                await setPromotionZone(PromotionZone.None);
                setMarketPage(MarketPage.Unknown);
                await nextTick();
                scrollToTopImmediate();
            },
            isDisabled: !isFairExchangeEnabled.value,
            gtmLabel: 'Exchange',
        };
    }

    function getMixParlayLink(): ISportListItem {
        const mixParlays = [...marketListMap.value.values()]
            .flatMap(x => x.filter(y => y.marketPage === MarketPage.MixParlay && y.date === EventDateType.All));
        const liveCount = mixParlays.reduce((acc, cur) => acc + cur.liveEventCount, 0);
        const nonLiveCount = mixParlays.reduce((acc, cur) => acc + cur.nonLiveEventCount, 0);

        return {
            name: i18n.t(`market_page_name_${MarketPage.MixParlay}`),
            icon: 'icon-system-ticketMultiple',
            isHot: false,
            isShowCount: true,
            isLive: mixParlays.some(x => x.liveEventCount > 0),
            isActive: isMixParlay.value,
            count: mixParlays.reduce((acc, cur) => acc + cur.totalEventCount, 0),
            liveCount,
            nonLiveCount,
            todayExpandedCount: 0, // TODO: get real count
            onClick: async () => {
                router.push('/');
                await setPromotionZone(PromotionZone.None);
                await setSportType(firstHasMixParlayCountSport.value, MarketPage.MixParlay);
                await nextTick();
                scrollToTopImmediate();
            },
            isDisabled: false,
            gtmLabel: 'MixParlay',
        };
    }

    const getPromotionZoneHotLink = (_promotionZone: PromotionZone): ISportListItem | null => {
        const config = getPromotionZoneConfig(_promotionZone);
        if (!config) return null;

        return {
            name: i18n.t(config.name),
            icon: config.hotLink?.icon ?? null,
            isHot: true,
            isShowCount: false,
            isLive: false,
            isActive: _promotionZone === promotionZone.value,
            count: 0,
            liveCount: 0,
            nonLiveCount: 0,
            todayExpandedCount: 0,
            onClick: async () => {
                await router.push(config.route);
                await nextTick();
                scrollToTopImmediate();
            },
            isDisabled: !config.enabled,
            gtmLabel: config.gtmLabel,
        };
    };
    const hotLinks = computed((): ISportListItem[] => [
        getPromotionZoneHotLink(PromotionZone.MultipleSports),
        getPromotionZoneHotLink(PromotionZone.SportsCup),
        getPromotionZoneHotLink(PromotionZone.SportsCup2),
        getFavoriteLink(),
        getPromotionZoneHotLink(PromotionZone.EFootball),
        getMixParlayLink(),
        getExchangeLink(),
    ].filter(NonNull).filter(x => !x.isDisabled));

    const isDisplay = (sportInfo: ISportInfo) => sportInfo.hasEvents || sportInfo.children !== undefined || [SportType.Racing].includes(sportInfo.sportType) || [SportType.VirtualFootball].includes(sportInfo.sportType) || [SportType.VirtualBasketball].includes(sportInfo.sportType);
    const isShowCount = (sportType: SportType) => ![SportType.Racing, SportType.EsportsIm, SportType.EsportsForDisplay, SportType.VirtualFootball, SportType.VirtualBasketball].includes(sportType);
    const formatAsiaSportList = (x: ISportInfo): ISportListItem => ({
        name: x.name,
        sportType: x.sportType,
        count: x.totalEventCount,
        liveCount: x.liveEventCount,
        nonLiveCount: x.nonLiveEventCount,
        todayExpandedCount: x.todayExpandedCount,
        isShowCount: isShowCount(x.sportType),
        isHot: false,
        isLive: x.hasLiveEvents,
        isActive: !isPromotionZone.value && x.sportType === sportType.value && !isMixParlay.value && !isMyFavorite.value && !isExchangePage.value,
        children: x.children?.map(formatAsiaSportList),
        onClick: async () => {
            if (x.children) return;
            if (x.onClick) {
                x.onClick();
                return;
            }
            router.push('/');
            await setPromotionZone(PromotionZone.None);
            await setSportType(x.sportType);
            await nextTick();
            scrollToTopImmediate();
        },
        gtmLabel: x.sportType.toString(),
    });

    const sportList = computed<ISportListItem[]>(() => sportsInfoDisplay.value.filter(item => isDisplay(item)).map(formatAsiaSportList));

    return {
        hotLinks,
        sportList,
    };
});
