import { getColumnGroupCount, getColumnWidth } from '@/components/results/resultColumnHelper';
import { i18n } from '@/core/lib/i18n';
import { findFirstHalfStatusWithResult, findFirstHalfStatusWithoutResult, findFullTimeStatusWithResult, findFullTimeStatusWithoutResult } from '@/core/lib/matchStatusHelper';
import { isNewBetType } from '@/core/lib/oddsHelper';
import type { Score } from '@/interface/ILiveScore';
import type { IMatchResult } from '@/interface/IResult';
import type { IResultDisplayInfo } from '@/interface/IResultDisplayInfo';
import { ColumnGroup, ResultType, SportType } from '@/interface/enum';

const isRankingScore = (sportType: SportType) => [SportType.MotorSport].includes(sportType);

const getWinnerDisplay = (matchResult: IMatchResult): string => {
    const score = matchResult.totalScores[ResultType.FT];
    if (!score) {
        return '-';
    }
    if (score.home === score.away) {
        return i18n.t('draw');
    }

    if (isRankingScore(matchResult.sportType)) {
        return score.home < score.away ? matchResult.homeOption : matchResult.awayOption;
    }
    return score.home > score.away ? matchResult.homeOption : matchResult.awayOption;
};

const getResultDisplay = (matchResult: IMatchResult): string => {
    let extra = '';
    if (matchResult.sportType === SportType.Cricket && isNewBetType(matchResult.betTypeGroupId)) {
        const score = matchResult.totalScores[ResultType.FT];
        if (score && matchResult.thirdPartyCode) {
            extra = ` ${matchResult.thirdPartyCode}`;
        }
    }
    return getWinnerDisplay(matchResult) + extra;
};

const getRoundDisplay = (matchResult: IMatchResult): string => {
    const score = matchResult.totalScores[ResultType.FT];
    if (!score) {
        return '-';
    }

    if (matchResult.sportType === SportType.MuayThai) {
        const maxRound = Math.max(score.home, score.away);
        if (maxRound <= 5) {
            return i18n.t('results_muay_thai_ko', { round: maxRound });
        }
        return i18n.t('results_muay_thai_round_completed', { round: 5 });
    }

    return score.home >= score.away ? score.home.toString() : score.away.toString();
};

const getGameScoreDisplays = (scores: Record<string, Score>, size: number): string[] => {
    const result = [...Array(size)].map((_, index) => {
        const score = scores[index + 1];
        return score ? `${score.home}:${score.away}` : '--:--';
    });
    return result;
};

const getScoreDisplay = (resultType: ResultType, matchResult: IMatchResult): string => {
    const score = matchResult.totalScores[resultType];
    return score ? `${score.home}:${score.away}` : '--:--';
};

export const ResultDisplayFunctions: Partial<Record<ColumnGroup, (matchResult: IMatchResult) => string[]>> = {
    [ColumnGroup.FirstHalfScore]: (matchResult: IMatchResult) => [getScoreDisplay(ResultType.HT, matchResult)],
    [ColumnGroup.FullTimeScore]: (matchResult: IMatchResult) => [getScoreDisplay(ResultType.FT, matchResult)],
    [ColumnGroup.FinalScore]: (matchResult: IMatchResult) => [getScoreDisplay(ResultType.FT, matchResult)],
    [ColumnGroup.TotalPointsWon]: (matchResult: IMatchResult) => [getScoreDisplay(ResultType.TotalPointWin, matchResult)],
    [ColumnGroup.TotalSetsWon]: (matchResult: IMatchResult) => [getScoreDisplay(ResultType.TotalSetsWin, matchResult)],
    [ColumnGroup.TotalGameWon]: (matchResult: IMatchResult) => [getScoreDisplay(ResultType.TotalGamesWin, matchResult)],
    [ColumnGroup.Winner]: (matchResult: IMatchResult) => [getWinnerDisplay(matchResult)],
    [ColumnGroup.Round]: (matchResult: IMatchResult) => [getRoundDisplay(matchResult)],
    [ColumnGroup.Result]: (matchResult: IMatchResult) => [getResultDisplay(matchResult)],
    [ColumnGroup.G5]: (matchResult: IMatchResult) => getGameScoreDisplays(matchResult.gameScores, getColumnGroupCount(ColumnGroup.G5)),
    [ColumnGroup.G7]: (matchResult: IMatchResult) => getGameScoreDisplays(matchResult.gameScores, getColumnGroupCount(ColumnGroup.G7)),
    [ColumnGroup.P3]: (matchResult: IMatchResult) => getGameScoreDisplays(matchResult.gameScores, getColumnGroupCount(ColumnGroup.P3)),
};

const noNeedShowStatusColumn = (column: ColumnGroup) => [
    ColumnGroup.G5,
    ColumnGroup.G7,
    ColumnGroup.P3,
    ColumnGroup.Round,
].includes(column);

const getStatusWithoutResultDisplay = (column: ColumnGroup, status: number) => {
    if (noNeedShowStatusColumn(column)) {
        return null;
    }

    if (column === ColumnGroup.FirstHalfScore) {
        const statusDisplay = findFirstHalfStatusWithoutResult(status);
        return statusDisplay ? i18n.t(`settlement_status_${statusDisplay}`) : null;
    }

    const statusDisplay = findFullTimeStatusWithoutResult(status);
    return statusDisplay ? i18n.t(`settlement_status_${statusDisplay}`) : null;
};

const getStatusDisplay = (column: ColumnGroup, status: number) => {
    if (noNeedShowStatusColumn(column)) {
        return null;
    }

    if (column === ColumnGroup.FirstHalfScore) {
        const statusDisplay = findFirstHalfStatusWithResult(status);
        return statusDisplay ? i18n.t(`settlement_status_${statusDisplay}`) : null;
    }

    const statusDisplay = findFullTimeStatusWithResult(status);
    return statusDisplay ? i18n.t(`settlement_status_${statusDisplay}`) : null;
};

export const getResultDisplayInfos = (column: ColumnGroup, matchResult: IMatchResult): IResultDisplayInfo[] => {
    const width = getColumnWidth(column, matchResult.sportType);

    const statusWithoutResultDisplay = getStatusWithoutResultDisplay(column, matchResult.status);
    if (statusWithoutResultDisplay) {
        return [{
            resultDisplay: null,
            statusDisplay: statusWithoutResultDisplay,
            width,
        }];
    }

    const fn = ResultDisplayFunctions[column];
    const resultDisplays = fn ? fn(matchResult) : [];
    return resultDisplays.map(x => ({
        resultDisplay: x,
        statusDisplay: getStatusDisplay(column, matchResult.status),
        width,
    }));
};
