<template>
    <div class="betOptionInfo" v-bind:class="{ live: leg.isLive }">
        <div class="betOptionInfo_header">
            <div class="betOptionInfo_header_title">
                <strong class="optionTitle">
                    {{ leg.marketType.name }}
                </strong>
            </div>
        </div>
        <div class="betOptionInfo_option">
            <div class="optionContent">
                <ul class="optionContent_inner">
                    <li>
                        <strong class="optionTeam">
                            {{ leg.betTeam }}
                        </strong>
                    </li>
                    <li class="optionOddsGroup">
                        <span v-if="leg.point" class="optionOdds optionOdds-point">{{ leg.point }}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { toRefs } from 'vue';
    import type { IBetBuilderLeg } from '@/interface/betBuilder';

    const props = defineProps<{
        leg: IBetBuilderLeg;
    }>();

    const { leg } = toRefs(props);
</script>

<style scoped lang="scss">
@import "@/components/myBets/miniMyBets/BetOptionInfo";
</style>
