<template>
    <VTable class="resultTable" rounded>
        <VTableRow class="vTable-sticky">
            <VTableHeader
                v-for="column in columns"
                v-bind:key="column.key"
                v-bind:width="column.width"
            >
                {{ column.name }}
            </VTableHeader>
        </VTableRow>
        <VTableRow v-if="isLoading">
            <VTableCell v-bind:colspan="columns.length" class="empty">
                <StaticSpinner />
            </VTableCell>
        </VTableRow>
        <VTableRow v-else-if="isEmpty">
            <VTableCell v-bind:colspan="columns.length" class="empty">
                {{ i18n.t('no_events_available') }}
            </VTableCell>
        </VTableRow>
        <template v-else>
            <template v-if="!isOutright">
                <LeagueResults
                    v-for="results in filteredResults.slice(0, showCount)"
                    v-bind:key="results[0].leagueId"
                    v-bind:results="results"
                    v-bind:leagueName="results[0].leagueName"
                    v-bind:columnCount="columns.length"
                />
            </template>
            <template v-else>
                <LeagueOutrightResults
                    v-for="results in filteredOutrightResults.slice(0, showCount)"
                    v-bind:key="results.tournament.id"
                    v-bind:leagueName="results.tournament.name"
                    v-bind:results="results.outrights"
                    v-bind:columnCount="columns.length"
                />
            </template>
            <VTableRow ref="showMoreTarget" />
        </template>
    </VTable>
</template>

<script lang="ts" setup>
    import type { MaybeElement } from '@vueuse/core';
    import { storeToRefs } from 'pinia';
    import { computed, ref, watch } from 'vue';
    import StaticSpinner from '@/components/common/StaticSpinner.vue';
    import VTable from '@/components/common/table/VTable.vue';
    import VTableCell from '@/components/common/table/VTableCell.vue';
    import VTableHeader from '@/components/common/table/VTableHeader.vue';
    import VTableRow from '@/components/common/table/VTableRow.vue';
    import LeagueOutrightResults from '@/components/results/LeagueOutrightResults.vue';
    import LeagueResults from '@/components/results/LeagueResults.vue';
    import { getColumnGroupsBySport, getColumnWidth } from '@/components/results/resultColumnHelper';
    import { useScrollToShowMore } from '@/composable/useScrollToShowMore';
    import { i18n } from '@/core/lib/i18n';
    import type { IColumn } from '@/interface/IColumn';
    import { ColumnGroup, ResultsBlockType, SportType } from '@/interface/enum';
    import { useResultsStore } from '@/store/resultsStore';

    const { isLoading, isEmpty, sportType, filteredResults, isOutright, filteredOutrightResults } = storeToRefs(useResultsStore());

    const columnGroups = computed(() => getColumnGroupsBySport(sportType.value));
    const columns = computed(() => {
        if (isOutright.value) {
            return [
                { key: 'date_time', name: i18n.t('results_header_date_time'), width: getColumnWidth(ResultsBlockType.Time, sportType.value) },
                { key: 'event', name: i18n.t('results_header_event') },
                { key: 'result', name: i18n.t('results_header_result') },
            ];
        }

        let columns = [
            { key: 'date_time', name: i18n.t('results_header_date_time'), width: getColumnWidth(ResultsBlockType.Time, sportType.value) },
            { key: 'event', name: i18n.t('results_header_event') },
        ];
        columns = columns.concat(columnGroups.value.reduce<IColumn[]>((result, columnGroup) => {
            const { group, count } = columnGroup;
            const columnTitle = (sportType.value === SportType.Baseball && columnGroup.group === ColumnGroup.FirstHalfScore)
                ? i18n.t('results_header_first_five_innings')
                : i18n.t(`results_header_${group}`);
            const cols = [...Array(count).keys()].reduce<IColumn[]>((cols, index) => cols.concat([{
                key: `${group}_${index + 1}`,
                name: count !== 1
                    ? i18n.t(`results_header_${group}_${index + 1}`)
                    : columnTitle,
                width: getColumnWidth(group, sportType.value),
            }]), []);
            return result.concat(cols);
        }, []));
        columns.push({ key: 'more', name: i18n.t('odds_header_more'), width: getColumnWidth(ResultsBlockType.More, sportType.value) });
        return columns;
    });

    const showMoreTarget = ref<MaybeElement>();
    const { showCount, resetShowCount } = useScrollToShowMore(showMoreTarget, 20, { rootMargin: '100px' });
    watch([filteredResults, filteredOutrightResults], () => {
        resetShowCount();
    });
</script>

<style lang="scss" scoped>
    @import "@/components/results/ResultTable";
</style>
