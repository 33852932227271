<template>
    <div class="betBuilder_ticket notranslate">
        <div class="betBuilder_ticket_option">
            <div class="optionTitle">
                <strong>{{ ticket.market.marketType.name }}</strong>
                <span
                    v-gtm:label="'CloseTicket_Bb'"
                    v-gtm:@click
                    class="ticket_header_cancel"
                    v-on:click="cancelTicket"
                >
                    <SvgIcon name="icon-system-close" />
                </span>
            </div>
            <div class="optionContent">
                <div class="optionContent_inner">
                    <div class="optionTeam">
                        {{ optionName }}
                    </div>
                    <div class="optionOddsGroup">
                        <template v-if="pointDisplay">
                            <span class="optionOdds optionOdds-point" v-bind:class="{ 'optionOdds-change': isPointChange }">{{ pointDisplay }}</span>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { computed, toRefs } from 'vue';
    import { getMarketRule } from '../oddsDisplay/moreMarket/betBuilderMarketRules';
    import { BetBuilderTicketContext } from '@/components/betSlip/BetBuilderTicketContext';
    import { BetSlipContext } from '@/components/betSlip/betSlipContext';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import type { IBetBuilderTicket } from '@/interface/betBuilder';

    const props = defineProps<{
        ticket: IBetBuilderTicket;
    }>();
    const { ticket } = toRefs(props);

    const { cancelTicket: _cancelTicket } = BetSlipContext.inject();
    const cancelTicket = () => {
        _cancelTicket(ticket.value.key);
    };

    const {
        pointDisplay,
        isPointChange,
    } = BetBuilderTicketContext.provide(ticket);

    const optionName = computed(() => {
        const rule = getMarketRule(ticket.value.market);
        return rule.optionFormatter({
            market: ticket.value.market,
            selection: ticket.value.selection,
        });
    });
</script>

<style lang="scss" scoped>
    @import "@/components/betSlip/Ticket";
</style>
