<template>
    <Notification v-if="isLoaded">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="message" />
    </Notification>
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import { computed } from 'vue';
    import Notification from '@/components/common/Notification.vue';
    import { i18n } from '@/core/lib/i18n';
    import { useCustomerStore } from '@/store/customerStore';
    import { useMaxPayoutStore } from '@/store/maxPayoutStore';

    const { maxPayout, isLoaded } = storeToRefs(useMaxPayoutStore());
    const { fetchMixParlayMaxPayout } = useMaxPayoutStore();

    if (!isLoaded.value) {
        fetchMixParlayMaxPayout();
    }

    const { sboCurrency } = storeToRefs(useCustomerStore());
    const { formatMoneyWithDecimal } = useCustomerStore();
    const soccerValue = computed(() => formatMoneyWithDecimal(maxPayout.value.soccer));
    const otherSportsMin = computed(() => formatMoneyWithDecimal(maxPayout.value.otherSportsMin));
    const otherSportsMax = computed(() => formatMoneyWithDecimal(maxPayout.value.otherSportsMax));
    const message = computed(() => i18n.t('max_payout_message', {
        currency: sboCurrency.value,
        soccerValue: soccerValue.value,
        otherSportsMin: otherSportsMin.value,
        otherSportsMax: otherSportsMax.value,
    }));
</script>
