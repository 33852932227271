import type { TranslateKeys } from '@/core/lib/i18n';
import { i18n } from '@/core/lib/i18n';
import { isFastMarket } from '@/core/lib/oddsHelper';
import type { Score } from '@/interface/ILiveScore';
import type { BetTypeGroup } from '@/interface/enum';
import { HandicapType, MarketType, OddsOption } from '@/interface/enum';

export const getBetOptionDisplay = {
    displayYesNo,
    displayHDP,
    displayOE,
    displayOU,
    displayCS,
    displayDoubleChance,
    displayTotalGoal,
    displayHTFT,
    displayFGLG,
    display1X2,
    displayNewBetType1X2,
    displayAsianLiveScore,
    displayML,
    isFavoriteTeam,
};

export interface ITeamNameDisplay{
    homeTeamName: string;
    homeExtension: string;
    homeTemplate: string;
    awayTeamName: string;
    awayExtension: string;
    awayTemplate: string;
}

function isFavoriteTeam(option: OddsOption, favoriteTeam: HandicapType) {
    return (favoriteTeam === HandicapType.Home && option === OddsOption.Home)
        || (favoriteTeam === HandicapType.Away && option === OddsOption.Away);
}

function displayYesNo(option: OddsOption, favoriteTeam: HandicapType, teamDisplayData: ITeamNameDisplay) {
    if (option === OddsOption._1X2_X) {
        return {
            name: i18n.t('draw'),
            isFavorite: false,
        };
    }

    return {
        name: getTeamDisplay(option, teamDisplayData),
        isFavorite: isFavoriteTeam(option, favoriteTeam),
    };
}

function displayHDP(option: OddsOption, favoriteTeam: HandicapType, teamDisplayData: ITeamNameDisplay) {
    return {
        name: getTeamDisplay(option, teamDisplayData),
        isFavorite: isFavoriteTeam(option, favoriteTeam),
    };
}

function displayOE(option: OddsOption) {
    return {
        name: option === OddsOption.Odd
            ? i18n.t('odds_option_odd')
            : i18n.t('odds_option_even'),
        tooltip: option === OddsOption.Odd
            ? i18n.t('odds_option_odd')
            : i18n.t('odds_option_even'),
        isFavorite: false,
    };
}

function displayOU(option: OddsOption, marketGroupId: BetTypeGroup) {
    const displayName = () => {
        if (isFastMarket(marketGroupId)) {
            return getFastMarketDisplay(option);
        }
        return option === OddsOption.Over
            ? i18n.t('odds_option_over')
            : i18n.t('odds_option_under');
    };
    return {
        name: displayName(),
        isFavorite: false,
    };
}

function displayCS(option: OddsOption, liveScore: Score, marketType: MarketType, delimiter: string) {
    if (option === OddsOption.CS_Others) {
        return {
            name: i18n.t('odds_option_cs_others'),
            tooltip: i18n.t('odds_option_cs_others_tooltip'),
            isFavorite: false,
        };
    }

    const oddsOptionTooltip = marketType === MarketType.FH_CorrectScore
        ? 'odds_option_fhcs_score_tooltip'
        : 'odds_option_ftcs_score_tooltip';
    const [homeOption, awayOption] = option.split(':').map(x => parseInt(x));
    return {
        name: i18n.t('odds_option_cs_score', {
            home: liveScore.home + homeOption,
            away: liveScore.away + awayOption,
        }).replace(':', delimiter),
        tooltip: i18n.t(oddsOptionTooltip, {
            home: liveScore.home + homeOption,
            away: liveScore.away + awayOption,
        }).replace(':', delimiter),
        isFavorite: false,
    };
}

function displayDoubleChance(option: OddsOption) {
    return {
        name: i18n.t(`odds_option_dc_${option}`),
        tooltip: i18n.t(`odds_option_dc_${option}_tooltip`),
        isFavorite: false,
    };
}

function displayTotalGoal(option: OddsOption, delimiter: string) {
    const displayName = () => {
        if (option === OddsOption.TG_7_Up) return i18n.t('odds_option_tg_7_up');
        return option.replace('-', delimiter);
    };
    const tooltip = () => i18n.t(`odds_option_tg_${option.replace('-', '')}_tooltip`);
    return {
        name: displayName(),
        tooltip: tooltip(),
        isFavorite: false,
    };
}

function displayHTFT(option: OddsOption) {
    return {
        name: i18n.t(`odds_option_htft_${option}`),
        tooltip: i18n.t(`odds_option_htft_${option}_tooltip`),
        isFavorite: false,
    };
}

function displayFGLG(option: OddsOption) {
    return {
        name: i18n.t(`odds_option_fglg_${option}`),
        tooltip: i18n.t(`odds_option_fglg_${option}_tooltip`),
        isFavorite: false,
    };
}

function displayML(option: OddsOption, teamDisplayData: ITeamNameDisplay) {
    return {
        name: getTeamDisplay(option, teamDisplayData),
        isFavorite: false,
    };
}

function display1X2(option: OddsOption, teamDisplayData: ITeamNameDisplay) {
    return {
        name: get1X2Display(option, teamDisplayData),
        isFavorite: false,
    };
}

function displayNewBetType1X2(betTeam: string) {
    return {
        name: betTeam,
        isFavorite: false,
    };
}

function displayAsianLiveScore(option: OddsOption, marketType: MarketType, liveScore: Score, teamDisplayData: ITeamNameDisplay) {
    return {
        name: getAsian1X2Display(option, marketType, liveScore, teamDisplayData),
        isFavorite: false,
    };
}

function getTeamDisplay(option: OddsOption, teamDisplayData: ITeamNameDisplay) {
    const homeOptions: OddsOption[] = [OddsOption.Home, OddsOption._1X2_1, OddsOption.ML_1, OddsOption.Over, OddsOption.Odd];
    const template = homeOptions.includes(option)
        ? teamDisplayData.homeTemplate
        : teamDisplayData.awayTemplate;
    return template
        .replace(/_/g, '')
        .replace(/{home}/ig, teamDisplayData.homeTeamName)
        .replace(/{homeext}/ig, teamDisplayData.homeExtension)
        .replace(/{away}/ig, teamDisplayData.awayTeamName)
        .replace(/{awayext}/ig, teamDisplayData.awayExtension);
}

function getFastMarketDisplay(option: OddsOption) {
    if (option === OddsOption.Over) return i18n.t('odds_option_fm_goal');
    if (option === OddsOption.Under) return i18n.t('odds_option_fm_nogoal');
    return '';
}

function get1X2Display(option: OddsOption, teamDisplayData: ITeamNameDisplay) {
    if (option === OddsOption._1X2_X) return i18n.t('draw');

    const teamOption = option === OddsOption._1X2_1 ? OddsOption.Home : OddsOption.Away;
    return getTeamDisplay(teamOption, teamDisplayData);
}

function getAsian1X2Lang(key: TranslateKeys, option: OddsOption, teamDisplayData: ITeamNameDisplay, goals = 0) {
    return goals
        ? i18n.t(key, { team: getTeamDisplay(option, teamDisplayData), goals }, goals)
        : i18n.t(key, { team: getTeamDisplay(option, teamDisplayData) });
}

function getAsian1X2Display(option: OddsOption, marketType: MarketType, liveScores: Score, teamDisplayData: ITeamNameDisplay) {
    const isHalfTime = marketType === MarketType.FH_LiveScore;
    const goals = Math.abs(liveScores.home - liveScores.away);

    const OptionMappingData: Record<string, Partial<Record<OddsOption, string>>> = {
        draw: {
            [OddsOption.LS_1]: getAsian1X2Lang('odds_option_a1x2_team_wins', OddsOption.Home, teamDisplayData),
            [OddsOption.LS_X]: i18n.t(`odds_option_a1x2_draw_${(isHalfTime ? 'fh' : 'ft')}`),
            [OddsOption.LS_2]: getAsian1X2Lang('odds_option_a1x2_team_wins', OddsOption.Away, teamDisplayData),
        },
        homeWin: {
            [OddsOption.LS_1]: getAsian1X2Lang('odds_option_a1x2_team_wins_by_margin', OddsOption.Home, teamDisplayData, goals + 1),
            [OddsOption.LS_X]: getAsian1X2Lang('odds_option_a1x2_team_wins_by_exactly', OddsOption.Home, teamDisplayData, goals),
            [OddsOption.LS_2]: goals === 1
                ? getAsian1X2Lang(`odds_option_a1x2_team_wins_or_draw_${(isHalfTime ? 'fh' : 'ft')}`, OddsOption.Away, teamDisplayData, goals)
                : getAsian1X2Lang('odds_option_a1x2_team_loses_by_margin', OddsOption.Away, teamDisplayData, goals - 1),
        },
        awayWin: {
            [OddsOption.LS_1]: goals === 1
                ? getAsian1X2Lang(`odds_option_a1x2_team_wins_or_draw_${(isHalfTime ? 'fh' : 'ft')}`, OddsOption.Home, teamDisplayData, goals)
                : getAsian1X2Lang('odds_option_a1x2_team_loses_by_margin', OddsOption.Home, teamDisplayData, goals - 1),
            [OddsOption.LS_X]: getAsian1X2Lang('odds_option_a1x2_team_wins_by_exactly', OddsOption.Away, teamDisplayData, goals),
            [OddsOption.LS_2]: getAsian1X2Lang('odds_option_a1x2_team_wins_by_margin', OddsOption.Away, teamDisplayData, goals + 1),
        },
    };

    if (liveScores.home === liveScores.away) {
        return OptionMappingData.draw[option] ?? option;
    }
    if (liveScores.home > liveScores.away) {
        return OptionMappingData.homeWin[option] ?? option;
    }
    if (liveScores.home < liveScores.away) {
        return OptionMappingData.awayWin[option] ?? option;
    }

    return option;
}
