import { useThrottleFn } from '@vueuse/core';
import { defineStore } from 'pinia';
import { computed, watch } from 'vue';
import { useCashOutValues } from '@/components/cashOut/useCashOutValues';
import { useDisplayCashOut } from '@/components/myBets/useDisplayCashOut';
import { useState } from '@/composable/useState';
import { Api } from '@/core/lib/api';
import { i18n } from '@/core/lib/i18n';
import type { IBet } from '@/interface/IBet';
import { MyBetsStatusFilter } from '@/interface/enum';

export const useMyBetsStore = defineStore('MyBets', () => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [_runningBets, setRunningBets] = useState<IBet[]>([]);
    const [settledBets, setSettledBets] = useState<IBet[]>([]);
    const [statusFilter, setStatusFilter] = useState(MyBetsStatusFilter.Running);

    const refreshMyBets = async (useCache: boolean) => {
        try {
            const { runningBets, settledBets } = await Api.getMyBets(useCache);
            setRunningBets(runningBets);
            setSettledBets(settledBets);
            setIsLoaded(true);
        } catch (error) {
            console.error(error);
        }
    };
    const refreshMyBetsThrottled = useThrottleFn(refreshMyBets, 5000, false, true);

    const { cashOutServiceList, refreshCashOutValues: _refreshCashOutValues } = useCashOutValues(_runningBets);

    const runningBets = computed(() => _runningBets.value.map((bet) => {
        const cashOutService = cashOutServiceList.value.find(x => x.transactionId === bet.transactionId);
        return ({
            ...bet,
            cashOutService,
        });
    }));

    const filteredMyBets = computed(() => {
        if (statusFilter.value === MyBetsStatusFilter.Settled) return settledBets.value;
        return runningBets.value;
    });

    const { isDisplayCashOut } = useDisplayCashOut();
    const isCashOutRefreshEnabled = computed(() => isDisplayCashOut.value);
    const refreshCashOutValues = async () => {
        if (!isCashOutRefreshEnabled.value) return;
        await _refreshCashOutValues();
    };

    watch(i18n.locale, () => {
        refreshMyBets(true);
        refreshCashOutValues();
    });

    refreshMyBets(true);

    return {
        isLoaded,
        myBets: filteredMyBets,
        runningBets,
        settledBets,
        refreshMyBets,
        refreshMyBetsThrottled,
        statusFilter,
        setStatusFilter,
        refreshCashOutValues,
    };
});
