import { whenever } from '@vueuse/core';
import { computed, ref } from 'vue';
import { OddsDisplayBlockContext } from '@/components/oddsDisplay/OddsDisplayBlockContext';
import { useCollapse } from '@/composable/useCollapse';
import { useState } from '@/composable/useState';
import { createMap, groupByWithOrder } from '@/core/lib/array';
import { defineContext } from '@/core/lib/defineContext';
import { ExpiryCache } from '@/core/lib/expiryCache';
import type { IOdds } from '@/interface/IOdds';
import type { IOutrightLeague } from '@/interface/IOutrightLeague';
import type { IBetBuilderMatch } from '@/interface/betBuilder';

const ODDS_CACHE_EXPIRY_TIME = 3 * 60 * 1000; // 3 minute

// each OddsDisplayContext instance will only serve for one OddsDisplay.vue
export const OddsDisplayContext = defineContext('OddsDisplay', (isLive: boolean) => {
    const {
        oddsDisplayMode,
        sportType,
        marketPage,
        displayEvents,
        loaded,
    } = OddsDisplayBlockContext.inject();

    const filteredEvents = computed(() => displayEvents.value.filter(e => e.isLive === isLive));

    const eventIndexMapping = computed(() => createMap(filteredEvents.value, event => event.id, (_event, index) => index));

    const leagueEvents = computed(() => groupByWithOrder(filteredEvents.value, x => x.league.id));
    const collapseIds = computed(() => leagueEvents.value.map(e => e[0].id));

    const {
        isItemCollapsedRef: isGroupCollapsed,
        setCollapsedItems,
        isCollapsedAll,
    } = useCollapse(collapseIds);

    const eventCache = computed(() => createMap(displayEvents.value, event => event.id, event => event));

    const oddsCacheWithExpiry = new ExpiryCache<string, IOdds[]>(ODDS_CACHE_EXPIRY_TIME);
    const outrightCacheWithExpiry = new ExpiryCache<string, IOutrightLeague[]>(ODDS_CACHE_EXPIRY_TIME);

    const betBuilderExistsCache = ref(new ExpiryCache<number, boolean>(ODDS_CACHE_EXPIRY_TIME));
    const betBuilderMatchCache = ref(new ExpiryCache<number, IBetBuilderMatch>(ODDS_CACHE_EXPIRY_TIME));

    const [matchListInBetBuilderMode, setMatchListInBetBuilderMode] = useState<number[]>([]);
    whenever(loaded, () => {
        setMatchListInBetBuilderMode([]);
    }, { immediate: true });

    return {
        sportType,
        marketPage,
        oddsDisplayMode,

        filteredEvents,
        eventIndexMapping,
        leagueEvents,

        isGroupCollapsed,
        isCollapsedAll,
        collapseIds,
        setCollapsedItems,

        eventCache,
        oddsCacheWithExpiry,
        outrightCacheWithExpiry,

        betBuilderExistsCache,
        betBuilderMatchCache,

        matchListInBetBuilderMode,
        setMatchListInBetBuilderMode,
    };
});
