import { sleep } from '@sports-utils/timer';
import { useTimeoutFn } from '@vueuse/core';
import type { CancelTokenSource } from 'axios';
import axios from 'axios';
import { storeToRefs } from 'pinia';
import type { Ref } from 'vue';
import { computed, ref, watch } from 'vue';
import type { IAsiaBetSlipContext } from '@/components/betSlip/betSlipContext/BaseBetSlipContext';
import { useBaseBetSlipContext } from '@/components/betSlip/betSlipContext/BaseBetSlipContext';
import type { PlaceBetErrorHandler, PlaceSingleBetErrorRules } from '@/components/betSlip/betSlipContext/usePlaceBetErrorHandler';
import { usePlaceBetErrorHandler } from '@/components/betSlip/betSlipContext/usePlaceBetErrorHandler';
import { usePlatform } from '@/composable/usePlatform';
import { Api } from '@/core/lib/api';
import { i18n } from '@/core/lib/i18n';
import { getMaxPayout, getOpenTicketErrorMessage, getPlaceBetErrorMessage } from '@/core/lib/ticket';
import { useOutrightInfoSubscriptionCallback } from '@/core/oddsApi/composable/useOutrightInfoSubscriptionCallback';
import { useOutrightLeagueQuery } from '@/core/oddsApi/composable/useOutrightLeagueQuery';
import { useTicketOutrightInfo } from '@/core/oddsApi/composable/useTicketOutrightInfo';
import type { IBetSlip, ITicket } from '@/interface/IBetSlip';
import { OpenTicketStatus, PlaceOrderStatus, PriceStyle, SiteStyle } from '@/interface/enum';
import { useBetSlipStore } from '@/store/betSlipStore';
import { useCustomerStore } from '@/store/customerStore';
import { useLayoutStore } from '@/store/layoutStore';
import { useToggleStore } from '@/store/toggleStore';

export const createOutrightBetSlipContext = (singleBetSlip: Ref<IBetSlip>): IAsiaBetSlipContext => {
    const {
        removeSingleBetSlip,
        updateSingleBetSlip,
        setSingleLastStake,
    } = useBetSlipStore();

    const betSlipContext = useBaseBetSlipContext(singleBetSlip);
    const {
        stakeInput,
        totalOddsPrice,
        setBetSlipMessage,
        setStakeMessage,
        cancelTicketMillisecond,
        toggleIsPlaceBetInProgress,
        emitPlaceBetSuccess,
        autoRefresh,
    } = betSlipContext;

    const { setStake } = stakeInput;
    const { formatMoneyWithDecimal } = useCustomerStore();

    const singleTicket = computed(() => singleBetSlip.value.tickets[0]);
    const maxPayout = computed(() => {
        const payout = getMaxPayout(stakeInput.stake.value, totalOddsPrice.value, singleTicket.value.priceStyle);
        return formatMoneyWithDecimal(payout);
    });

    let cancelToken: CancelTokenSource;
    const openTicket = async () => {
        try {
            if (cancelToken) cancelToken.cancel('invalid previous openTicket');
            cancelToken = axios.CancelToken.source();

            const { data: ticketResponse } = await Api.openOutrightTicket(singleTicket.value, cancelToken.token);

            if (!ticketResponse.oddsInfo) return;

            const oddsData = ticketResponse.oddsInfo[singleTicket.value.key];
            if (!oddsData) return;

            if (oddsData.errorCode) {
                setBetSlipMessage(getOpenTicketErrorMessage(oddsData.errorCode));

                const cancelTicketStatus = [
                    OpenTicketStatus.EventClosed,
                    OpenTicketStatus.EventCompleted,
                    OpenTicketStatus.EventInternal,
                    OpenTicketStatus.OddsClosed,
                    OpenTicketStatus.OddsCompleted,
                    OpenTicketStatus.OddsInternal,
                ];

                if (cancelTicketStatus.includes(oddsData.errorCode)) {
                    useTimeoutFn(() => cancelTicket(), cancelTicketMillisecond);
                }

                return;
            }

            updateSingleBetSlip((betSlip) => {
                betSlip.uid = oddsData.uid ?? '';
                betSlip.minBet = ticketResponse.minBet;
                betSlip.maxBet = ticketResponse.maxBet;
                betSlip.balance = ticketResponse.balance;
                const ticket = betSlip.tickets[0];
                ticket.priceStyle = PriceStyle.fromPollux(oddsData.priceStyle);
                ticket.priceOption.price = oddsData.price;
                return betSlip;
            });
        } catch (error) {
            if (axios.isCancel(error)) {
                // do nothing
            } else {
                console.error(error);
                setBetSlipMessage(i18n.t('bet_slip_error_message_general_failure'));
                useTimeoutFn(() => cancelTicket(), cancelTicketMillisecond);
            }
        }
    };

    const ticketContextChangeHandler: PlaceBetErrorHandler = (_placeOrderStatus: PlaceOrderStatus, _extraInfo: string) => {
        openTicket();
    };

    const invalidEventOrPriceHandler: PlaceBetErrorHandler = (placeOrderStatus: PlaceOrderStatus, _extraInfo: string) => {
        setBetSlipMessage(getPlaceBetErrorMessage(placeOrderStatus));
        useTimeoutFn(() => cancelTicket(), cancelTicketMillisecond);
    };

    const limitExceedErrorHandler: PlaceBetErrorHandler = (_placeOrderStatus: PlaceOrderStatus, extraInfo: string) => {
        const alternateStake = parseInt(extraInfo);
        if (!Number.isNaN(alternateStake)) {
            setBetSlipMessage(i18n.t('bet_slip_error_message_limit_exceed', { alternateStake }));
            setStake(alternateStake);
        } else {
            setBetSlipMessage(getPlaceBetErrorMessage(PlaceOrderStatus.LimitExceedTryLater));
        }
    };

    const { placeBetErrorRuleMap, defaultErrorHandler, errorMessageHandler, replaceBrandNameErrorMessageHandler } = usePlaceBetErrorHandler(betSlipContext);

    const placeOutrightBetErrorRuleMap: Partial<Record<PlaceSingleBetErrorRules, PlaceBetErrorHandler>> = {
        ...placeBetErrorRuleMap,
        [PlaceOrderStatus.RetryOpenTicket]: ticketContextChangeHandler,
        [PlaceOrderStatus.PriceChange]: ticketContextChangeHandler,
        [PlaceOrderStatus.HdpPointChange]: ticketContextChangeHandler,
        [PlaceOrderStatus.ScoreChanged]: ticketContextChangeHandler,
        [PlaceOrderStatus.PriceSuspend]: invalidEventOrPriceHandler,
        [PlaceOrderStatus.InvalidPrice]: invalidEventOrPriceHandler,
        [PlaceOrderStatus.EventClosed]: invalidEventOrPriceHandler,
        [PlaceOrderStatus.IOMWalkinOnly]: replaceBrandNameErrorMessageHandler,
        [PlaceOrderStatus.LimitExceedTryLater]: errorMessageHandler,
        [PlaceOrderStatus.LimitExceed]: limitExceedErrorHandler,
        [PlaceOrderStatus.ThirdPartyTimeout]: errorMessageHandler,
    };

    const { refreshBalanceAfterAction } = usePlatform();

    const { singleLastStake, isShowLastStake } = storeToRefs(useBetSlipStore());
    const { placeBetDelayTime } = storeToRefs(useToggleStore(SiteStyle.Asia));

    const placeBet = async () => {
        toggleIsPlaceBetInProgress(true);
        try {
            const [{ data: response }] = await Promise.all([
                Api.placeAsiaOutrightBet(singleBetSlip.value, stakeInput.stake.value),
                sleep(placeBetDelayTime.value * 1000),
            ]);
            if (response.isPlaceBetSuccess) {
                const { toggleBetSlipExpanded } = useLayoutStore();
                toggleBetSlipExpanded(false);
                removeSingleBetSlip();
                emitPlaceBetSuccess();
                if (isShowLastStake.value) {
                    setSingleLastStake(stakeInput.stake.value);
                }
                refreshBalanceAfterAction();
                toggleIsPlaceBetInProgress(false);
                return;
            }
            const placeOrderStatus = PlaceOrderStatus.fromPollux(response.placeOrderStatus);
            const handler = placeOutrightBetErrorRuleMap[placeOrderStatus as PlaceSingleBetErrorRules] ?? defaultErrorHandler;
            handler(placeOrderStatus, response.extraInfo);
        } catch (error) {
            console.error(error);
            errorMessageHandler(PlaceOrderStatus.GeneralFailure, '');
        }
        toggleIsPlaceBetInProgress(false);
    };

    if (isShowLastStake.value) {
        setStake(singleLastStake.value);
    }

    function cancelTicket() {
        removeSingleBetSlip();
    }

    function subscribeOdds(ticket: Ref<ITicket>) {
        const { onUpdated, onDeleted } = useOutrightInfoSubscriptionCallback({
            sportType: ticket.value.event.sportType,
            leagueId: ticket.value.event.league.id,
            oddsId: ticket.value.odds.id,
            isLive: ticket.value.odds.isLive,
        });

        onUpdated(() => {
            if (!singleBetSlip.value.uid) return;

            openTicket();
        });

        onDeleted(() => {
            setBetSlipMessage(i18n.t('bet_slip_error_message_odds_closed'));
            useTimeoutFn(cancelTicket, cancelTicketMillisecond);
        });
    }

    function updateWhenLangChanged(ticket: Ref<ITicket>) {
        const isLeagueQueryEnabled = ref(true);
        const isOutrightInfoQueryEnabled = ref(true);

        watch(i18n.locale, () => {
            isLeagueQueryEnabled.value = true;
            isOutrightInfoQueryEnabled.value = true;
        });

        const { outrightLeagues, loading: leagueLoading } = useOutrightLeagueQuery({
            sportType: ticket.value.event.sportType,
            isLive: ticket.value.odds.isLive,
            leagueId: ticket.value.event.league.id,
            isActive: isLeagueQueryEnabled,
        });

        watch(leagueLoading, (isLoading) => {
            if (isLoading) return;

            isLeagueQueryEnabled.value = false;
            if (outrightLeagues.value.length !== 0) {
                const league = outrightLeagues.value[0];
                singleTicket.value.event.league.name = league.name;
                singleTicket.value.event.league.info = league.info;
            }
        });

        const { outrightOdds, loading } = useTicketOutrightInfo({
            sportType: ticket.value.event.sportType,
            leagueId: ticket.value.event.league.id,
            oddsId: ticket.value.odds.id,
            isLive: ticket.value.odds.isLive,
            isActive: isOutrightInfoQueryEnabled,
        });

        watch(loading, (isLoading) => {
            if (isLoading) return;

            isOutrightInfoQueryEnabled.value = false;
            if (outrightOdds.value.length !== 0) {
                const odds = outrightOdds.value[0];
                singleTicket.value.event.homeTeam.name = odds.teamName ?? '';
                singleTicket.value.event.awayTeam.name = odds.teamName ?? '';
            }
        });
    }

    autoRefresh(openTicket);

    watch([() => singleBetSlip.value.uid, () => singleTicket.value.key], () => {
        if (!singleBetSlip.value.uid) {
            openTicket();
            setStakeMessage('');
            if (isShowLastStake.value) {
                setStake(singleLastStake.value);
                return;
            }
            setStake(0);
        }
    }, { immediate: true });

    const isVoucherAvailable = computed (() => false);
    const isVoucherValidForTicket = computed(() => false);

    return {
        ...betSlipContext,
        maxPayout,
        openTicket,
        cancelTicket,
        placeBet,
        subscribeOdds,
        updateWhenLangChanged,
        isVoucherAvailable,
        isVoucherValidForTicket,
    };
};
