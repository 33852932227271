<template>
    <Teleport v-if="banner" to="#sports">
        <div
            v-gtm:page="siteStyle === SiteStyle.Euro ? 'G_Lobby' : 'K_Lobby'"
            v-gtm:type="'PopupBanner'"
            v-gtm:identity="PlayerType[playerType]"
            v-gtm:license="LicenseType[licenseType]"
            v-gtm:currency="sboCurrency"
            v-gtm:uuid="trackingId"
            class="popupBanner"
        >
            <div class="popupBanner_content">
                <div
                    v-gtm:content="banner.gtm"
                    v-gtm:@view
                    v-gtm:@click
                    class="popupBanner_content_image"
                    v-bind:style="{ backgroundImage: `url(${banner.cdn})` }"
                    v-bind:data-ga="'PopupBanner'"
                    v-bind:data-img="banner.gtm"
                    v-on:click="onClickBanner"
                />
                <SvgIcon
                    class="popupBanner_content_close"
                    size="xl"
                    color="white"
                    name="icon-system-close"
                    v-on:click="closePopup"
                />
            </div>
            <div class="popupBanner_mask" v-on:click="closePopup" />
        </div>
    </Teleport>
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import { onMounted, onUnmounted } from 'vue';
    import { usePopupBannerStore } from '../../store/popupBannerStore';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import { LicenseType, PlayerType, SiteStyle } from '@/interface/enum';
    import { useCustomerStore } from '@/store/customerStore';
    import { useScrollableStore } from '@/store/scrollableStore';

    const { playerType, licenseType, sboCurrency, trackingId, siteStyle } = storeToRefs(useCustomerStore());

    const { banner } = storeToRefs(usePopupBannerStore());
    const { closePopup, onClickBanner } = usePopupBannerStore();
    const { setIsScrollable } = useScrollableStore();

    onMounted(() => {
        setIsScrollable(false);
    });

    onUnmounted(() => {
        setIsScrollable(true);
    });
</script>

<style lang="scss" scoped>
@import "@/components/banner/PopupBanner";
</style>
