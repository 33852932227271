<template>
    <Tabs
        v-model:isExpand="isSportListExpanded"
        class="sportListWrapper"
    >
        <Tab
            v-gtm:@click
            v-gtm:label="isSportListExpanded ? 'Collapse' : 'Expand'"
            v-gtm:type="'SportList'"
            name="sports"
            v-bind:label="i18n.t('tab_title_sports')"
            icon="icon-system-sports"
        />
        <template #sports>
            <SportList
                v-gtm:type="'HotLink'"
                v-bind:title="i18n.t('hot_link')"
                v-bind:items="hotLinks"
            />
            <SportList
                v-bind:title="i18n.t('sports_type')"
                v-bind:items="sportList"
            />
        </template>
    </Tabs>
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import SportList from '@/components/sportList/SportList.vue';
    import Tab from '@/components/tabs/Tab.vue';
    import Tabs from '@/components/tabs/Tabs.vue';
    import { i18n } from '@/core/lib/i18n';
    import { useLayoutStore } from '@/store/layoutStore';
    import { useSideBarStore } from '@/store/sideBarStore';

    const { isSportListExpanded } = storeToRefs(useLayoutStore());
    const { sportList, hotLinks } = storeToRefs(useSideBarStore());
</script>

<style lang="scss" scoped>
    @import "@/components/sportList/SportListBlock";
</style>
