import { useEventListener } from '@vueuse/core';
import { defineStore, storeToRefs } from 'pinia';
import { computed } from 'vue';
import { SportType } from '@/interface/enum';
import { MainBetStatus, SubBetStatus } from '@/interface/pollux';
import { useMiniMyBetsStore } from '@/store/miniMyBetsStore';

export const useScoreAlertStore = defineStore('ScoreAlert', () => {
    const { rawMyBets } = storeToRefs(useMiniMyBetsStore());
    const eventIds = computed<Set<number>>(() => {
        const runningBets = rawMyBets.value.filter(bet => bet.mainBetStatus === MainBetStatus.Running);
        const singleBets = runningBets.filter(bet => !bet.isMixParlay && !bet.isOutright);
        const subBets = runningBets
            .filter(bet => bet.isMixParlay)
            .flatMap(bet => bet.subBets)
            .filter(bet => bet.subBetStatus === SubBetStatus.Running);
        const footballLiveEventIds = [...singleBets, ...subBets]
            .filter(bet => bet.isLive && bet.sportType === SportType.Soccer)
            .map(bet => bet.eventId);

        return new Set(footballLiveEventIds);
    });

    const soundUrl = new URL('../assets/sounds/sound_01.mp3', import.meta.url).href;
    const audio = new Audio(soundUrl);

    function playSound() {
        audio.play();
    }

    const cleanup = useEventListener(audio, 'ended', () => {
        audio.muted = false;
        cleanup();
    });

    function initAudioAutoPlay() {
        audio.muted = true;
        audio.play();
    }

    return {
        eventIds,
        playSound,
        initAudioAutoPlay,
    };
});
