<template>
    <VTableRow
        v-debug:matchId="result.matchId"
        v-bind:bgColor="isOpenMore ? 'white' : index % 2 === 0 ? 'tableEven' : 'tableOdd'"
    >
        <VTableCell>
            {{ showTimeDisplay }}
        </VTableCell>
        <VTableCell align="left">
            <div class="team">
                {{ result.homeTeam }}
                <SvgIcon
                    v-if="result.firstGoal === FGLGResult.Home"
                    v-tooltip="i18n.t('first_goal')"
                    name="icon-system-first-goal"
                    class="goal goal-first"
                />
                <SvgIcon
                    v-if="result.lastGoal === FGLGResult.Home"
                    v-tooltip="i18n.t('last_goal')"
                    name="icon-system-last-goal"
                    class="goal goal-last"
                />
            </div>
            <div class="team">
                {{ result.awayTeam }}
                <SvgIcon
                    v-if="result.firstGoal === FGLGResult.Away"
                    v-tooltip="i18n.t('first_goal')"
                    name="icon-system-first-goal"
                    class="goal goal-first"
                />
                <SvgIcon
                    v-if="result.lastGoal === FGLGResult.Away"
                    v-tooltip="i18n.t('last_goal')"
                    name="icon-system-last-goal"
                    class="goal goal-last"
                />
            </div>
        </VTableCell>
        <VTableCell v-for="(column, i) in columns" v-bind:key="i">
            <div v-if="column.resultDisplay">
                {{ column.resultDisplay }}
            </div>
            <div v-if="column.statusDisplay" class="description">
                <StatusTag v-bind:text="column.statusDisplay" size="sm" />
            </div>
        </VTableCell>
        <VTableCell>
            <MoreButton
                v-if="isShowMoreButton"
                v-bind:isOpenMore="isOpenMore"
                v-bind:isMatchResult="true"
                class="moreButton"
                v-on:click="toggleIsOpenMore"
            />
        </VTableCell>
    </VTableRow>
    <VTableRow
        v-if="isOpenMore"
        bgColor="white"
    >
        <VTableCell v-bind:colspan="columns.length + 3">
            <MoreResultBlock v-bind:result="result" v-bind:betBuilderResult="betBuilderResult" />
        </VTableCell>
    </VTableRow>
</template>

<script lang="ts" setup>
    import { watchOnce } from '@vueuse/core';
    import { flatten } from 'lodash';
    import groupBy from 'lodash/groupBy';
    import orderBy from 'lodash/orderBy';
    import { storeToRefs } from 'pinia';
    import type { PropType } from 'vue';
    import { computed, ref, toRefs } from 'vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import MoreButton from '@/components/common/button/MoreButton.vue';
    import StatusTag from '@/components/common/statusTag/StatusTag.vue';
    import VTableCell from '@/components/common/table/VTableCell.vue';
    import VTableRow from '@/components/common/table/VTableRow.vue';
    import MoreResultBlock from '@/components/results/MoreResultBlock.vue';
    import { getColumnGroupsBySport } from '@/components/results/resultColumnHelper';
    import { getResultDisplayInfos } from '@/components/results/resultHelper';
    import { useShowTimeDisplay } from '@/composable/useShowTimeDisplay';
    import { Api } from '@/core/lib/api';
    import { i18n } from '@/core/lib/i18n';
    import type { IBetBuilderResult, IResult } from '@/interface/IResult';
    import type { IResultDisplayInfo } from '@/interface/IResultDisplayInfo';
    import { FGLGResult } from '@/interface/enum';
    import { useResultsStore } from '@/store/resultsStore';
    import { useToggleStore } from '@/store/toggleStore';

    const props = defineProps({
        result: {
            type: Object as PropType<IResult>,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    });

    const { result } = toRefs(props);

    const betBuilderResult = ref<IBetBuilderResult[]>([]);

    const showTimeDisplay = computed(() => {
        const { date, time } = useShowTimeDisplay(result.value.showTime, result.value.showTimeDisplayType);
        return `${date} ${time}`;
    });

    const columnGroups = computed(() => getColumnGroupsBySport(result.value.sportType));
    const columns = computed(() => columnGroups.value.reduce<IResultDisplayInfo[]>(
        (columns, columnGroup) => columns.concat(
            getResultDisplayInfos(columnGroup.group, result.value.mainMarketMatchResult),
        ),
        [],
    ));

    const { isBetBuilderResultEnabled } = storeToRefs(useToggleStore());

    const isShowMoreButton = computed(() => {
        if (isBetBuilderResultEnabled.value) {
            return result.value.hasBetBuilderResults || result.value.newBetTypeMatchResults.length > 0;
        }
        return result.value.newBetTypeMatchResults.length > 0;
    });

    const { isOpenMoreMatch } = storeToRefs(useResultsStore());
    const { setIsOpenMoreMatch } = useResultsStore();
    const isOpenMore = computed(() => isOpenMoreMatch.value === result.value.matchId);
    const toggleIsOpenMore = async () => {
        if (isOpenMore.value) {
            setIsOpenMoreMatch(null);
        } else {
            setIsOpenMoreMatch(result.value.matchId);
        }
    };

    watchOnce(isOpenMore, async () => {
        if (result.value.hasBetBuilderResults && isBetBuilderResultEnabled.value) {
            const response = await Api.getBetBuilderResults(result.value.sportType, result.value.matchId);
            const groupedByMarketName = groupBy(response, market => market.marketType.name);
            const orderedByPoint = Object.keys(groupedByMarketName).map(key => orderBy(groupedByMarketName[key], market => market.point));
            betBuilderResult.value = flatten(orderBy(orderedByPoint, x => x[0].marketType.id, ['asc']));
        }
    });

</script>

<style lang="scss" scoped>
    @import "@/components/results/MatchResult";
</style>
