import type { IEvent, IEventEuro } from '@/interface/IEvent';
import type { IOdds, IPrice } from '@/interface/IOdds';
import type { IVoucher } from '@/interface/IVoucher';
import type { AsiaBetPageType, EuroBetPageType, OpenTicketStatus, PlaceOrderStatus, PriceStyle } from '@/interface/enum';

export enum BetSlipType {
    Single,
    Parlay,
    Outright,
    BetBuilder
}

export interface IBetSlip {
    type: BetSlipType.Single | BetSlipType.Parlay | BetSlipType.Outright;
    uid: string; // uid comes from pollux response, to identify this bet slip has opened ticket, don't change it
    minBet: number;
    maxBet: number;
    balance: number | null;
    tickets: ITicket[];
    message: OpenTicketStatus;
}

export type IBetSlipEuro<Type extends BetSlipType = BetSlipType> = {
    type: Type;
    uid: string; // uid comes from pollux response, to identify this bet slip has opened ticket, don't change it
    key: string;
    minBet: number;
    maxBet: number;
    tickets: ITicketEuro[];
    stake: number;
    voucherId?: string;
    vouchers: IVoucher[];
    placedOrderError: {
        status: PlaceOrderStatus;
        message: string;
    };
    openTicketError: OpenTicketStatus;
}

export interface ITicket {
    key: string;
    betPage: AsiaBetPageType;
    event: IEvent;
    odds: IOdds;
    priceStyle: PriceStyle;
    priceOption: IPrice;
}

export interface ITicketEuro {
    key: string;
    betPage: EuroBetPageType;
    event: IEventEuro;
    odds: IOdds;
    priceStyle: PriceStyle;
    priceOption: IPrice;
    liveHomeScore: number;
    liveAwayScore: number;
}
