<template>
    <div v-debug:leagueId="league.id" class="league notranslate">
        <div
            class="leagueTitle"
            v-bind:class="{ live: isLive }"
        >
            <div
                class="leagueTitle_collapseBtn"
                v-bind:style="{ width: `${timeColumnWidth}px` }"
            >
                <VButton
                    flat
                    block
                    padding="0"
                    v-on:click="isCollapsed = !isCollapsed"
                >
                    <SvgIcon
                        name="icon-system-arrow"
                        v-bind:rotated="isCollapsed"
                    />
                </VButton>
            </div>
            <span class="leagueTitle_name">
                <span>{{ `${formatLeagueDate(league.eventDate)} - ${league.name}` }}</span>
            </span>
            <SvgIcon
                v-if="league.info"
                v-tooltip="league.info"
                name="icon-system-cycle-info"
                right
            />
            <div
                class="leagueTitle_refresher"
                v-bind:style="{ width: `${moreColumWidth}px` }"
            >
                <VButton
                    flat
                    block
                    padding="0"
                    v-on:click="refresh"
                >
                    <SvgIcon
                        name="icon-system-refresh"
                        v-bind:rotating="isRefreshing"
                    />
                </VButton>
            </div>
        </div>
        <template v-if="!isCollapsed">
            <OutrightMatch
                v-bind:league="league"
                v-bind:isLive="isLive"
            />
        </template>
    </div>
</template>

<script lang="ts" setup>
    import type { PropType } from 'vue';
    import { computed, toRefs } from 'vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import VButton from '@/components/common/button/VButton.vue';
    import { getOddsTableColumnsOutright } from '@/components/oddsDisplay/oddsTableCalculation';
    import OutrightMatch from '@/components/oddsDisplay/outright/OutrightMatch.vue';
    import { OutrightOddsDisplayContext } from '@/components/oddsDisplay/outright/OutrightOddsDisplayContext';
    import { useAutoResetState } from '@/composable/useAutoResetState';
    import { useEventEmitter } from '@/composable/useEventEmitter';
    import { formatDate, parseISOString } from '@/core/lib/date';
    import type { IOutrightLeague } from '@/interface/IOutrightLeague';
    import { OddsDisplayBlockType } from '@/interface/enum';

    const props = defineProps({
        isLive: {
            type: Boolean,
            required: true,
        },
        league: {
            type: Object as PropType<IOutrightLeague>,
            required: true,
        },
    });

    const { league, isLive } = toRefs(props);

    const eventEmitter = useEventEmitter();

    const [isRefreshing, setIsRefreshing] = useAutoResetState(false, 1000);
    const refresh = () => {
        if (isRefreshing.value) return;

        setIsRefreshing(true);

        eventEmitter.emit('oddsDisplay:refreshEventList', isLive.value);
        eventEmitter.emit('oddsDisplay:refreshOdds', league.value.id);
    };

    const columns = computed(() => getOddsTableColumnsOutright(true));
    const timeColumnWidth = computed(() => columns.value.find(({ key }) => key === OddsDisplayBlockType.Time)!.width);
    const moreColumWidth = computed(() => columns.value.find(({ key }) => key === OddsDisplayBlockType.More)!.width);

    const { isItemCollapsedRef } = OutrightOddsDisplayContext.inject();
    const isCollapsed = isItemCollapsedRef(league.value.id);

    const formatLeagueDate = (eventDate: string) => formatDate(parseISOString(eventDate), 'MM/dd/yyyy');
</script>

<style lang="scss" scoped>
    @import "@/components/oddsDisplay/OddsDisplayLeague";
</style>
