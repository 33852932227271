import uaParserJs from 'ua-parser-js';

/* eslint-disable @typescript-eslint/ban-types */
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => { };

export function deepEqual<T>(a: T, b: T): boolean {
    return JSON.stringify(a) === JSON.stringify(b);
}

export function shallowDeepEqual(expected: any, actual: any): boolean {
    if (expected === actual) {
        return true;
    }
    if (typeof (actual) !== typeof (expected)) {
        return false;
    }
    if (typeof (expected) !== 'object' || expected === null) {
        return expected === actual;
    }
    if (Array.isArray(expected) !== Array.isArray(actual)) {
        return false;
    }
    if (!!expected && !actual) {
        return false;
    }

    if (Array.isArray(expected) && Array.isArray(actual)) {
        const aa = Array.prototype.slice.call(actual);
        return expected.every(exp => aa.some(act => shallowDeepEqual(exp, act)));
    }

    if (expected instanceof Date) {
        if (actual instanceof Date) {
            return expected.getTime() === actual.getTime();
        }
        return false;
    }

    return Object.keys(expected).every((key) => {
        const eo = expected[key];
        const ao = actual[key];
        if (typeof (eo) === 'object' && eo !== null && ao !== null) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            return shallowDeepEqual(eo, ao);
        }
        if (typeof (eo) === 'function') {
            return eo(ao);
        }
        return ao === eo;
    });
}

export function hookBefore(target: any, methodName: string, hookFn: Function) {
    const original = target[methodName];
    target[methodName] = function (...args: any[]) {
        hookFn(...args);
        return original.apply(this, args);
    };
}

export function hookAfter(target: any, methodName: string, hookFn: Function) {
    const original = target[methodName];
    target[methodName] = function (...args: any[]) {
        const ret = original.apply(this, args);
        hookFn(...args);
        return ret;
    };
}

export function stringReverse(str: string) {
    let result = '';
    for (let index = str.length - 1; index >= 0; index--) {
        result += str[index];
    }
    return result;
}

export function pick<T extends object>(obj: T, keys: (keyof T)[] | readonly (keyof T)[]): Pick<T, keyof T> {
    const result: any = {};
    if (!obj) return result;

    keys.forEach((key) => {
        result[key] = obj[key];
    });
    return result;
}

const result = uaParserJs(navigator.userAgent);

export function isMobile() {
    if (['mobile', 'tablet'].includes(result.device.type ?? '')) {
        return true;
    }

    // to try to detect mobile users with desktop mode
    if (hasTouchScreen() && isVerticalScreen()) {
        return true;
    }

    return false;
}

export function hasTouchScreen() {
    return 'ontouchstart' in window
        || navigator.maxTouchPoints > 0;
}

export function isVerticalScreen() {
    return window.screen.height > window.screen.width;
}

export function isSupportedDeviceForWs() {
    if (!isMobile()) return true;

    const { name, version: _version } = result.os;
    const version = Number.parseFloat(_version ?? '');

    const isSupportedIOs = name === 'iOS' && version >= 12;

    return isSupportedIOs;
}

export function isSupportedDeviceForGtm() {
    if (!isMobile()) return true;

    const { name = '', version: _version = '' } = result.os;
    const version = Number.parseFloat(_version);

    const isSupportedAndroid = name.startsWith('Android') && version >= 8;
    const isSupportedIOs = name === 'iOS' && version >= 12;

    return isSupportedAndroid || isSupportedIOs;
}

export function getBrowserInfo() {
    return result.browser;
}

export function loadScript(url: string, target: HTMLElement = document.head) {
    const script = document.createElement('script');
    script.setAttribute('src', url);
    target.appendChild(script);

    return new Promise((res, rej) => {
        script.onload = res;
        script.onerror = rej;
    });
}

export function loadCss(url: string, target: HTMLElement = document.head) {
    const element = document.createElement('link');
    element.setAttribute('href', url);
    element.setAttribute('rel', 'stylesheet');
    element.setAttribute('type', 'text/css');
    target.appendChild(element);
}

export function deepFreeze<T>(obj: T) {
    const propNames = Object.getOwnPropertyNames(obj) as (keyof T)[];

    propNames.forEach((name) => {
        const prop = obj[name];

        if (typeof prop === 'object' && prop !== null) {
            deepFreeze(prop);
        }
    });

    return Object.freeze(obj);
}
