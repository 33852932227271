<template>
    <Teleport to="#sports">
        <div
            v-gtm:page="'SelectLeague'"
            class="selectLeague"
        >
            <div class="selectLeague_mask" v-on:click="emit('close')" />
            <div class="selectLeague_modal">
                <div class="selectLeague_modal_dialog">
                    <div class="selectLeague_modal_header">
                        <div class="selectLeagueTitle">
                            <SvgIcon
                                size="md"
                                color="white"
                                class="selectLeagueTitle_icon"
                                name="icon-system-filter"
                            />
                            <span class="selectLeagueTitle_text">
                                {{ i18n.t('select_league') }}
                            </span>
                            <Count
                                right
                                v-bind:number="tempSelectedLeagueIds.length"
                                bgColor="white"
                                textColor="primary"
                            />
                        </div>
                        <SvgIcon
                            class="selectLeague_modal_close"
                            size="md"
                            color="white"
                            name="icon-system-close"
                            v-on:click="emit('close')"
                        />
                    </div>
                    <div class="selectLeague_modal_body">
                        <template v-if="loaded">
                            <div
                                v-bind:class="{ 'leagueList_catolog-disabled': !hasBigLeagues }"
                                class="leagueList_catolog"
                            >
                                <CheckBox
                                    id="selectMajorLeague"
                                    v-bind:gtmLabel="isSelectAllTempBigLeagues ? `MajorLeague_Off` : `MajorLeague_On`"
                                    v-bind:checked="isSelectAllTempBigLeagues"
                                    v-bind:indeterminate="isSelectPartOfTempBigLeagues"
                                    v-bind:disabled="!hasBigLeagues"
                                    v-on:change="toggleSelectAllBigLeague"
                                >
                                    <template #label>
                                        <span class="leagueList_catolog_label labelText">{{ i18n.t('major_leagues') }}</span>
                                        <span v-if="!hasBigLeagues">- {{ i18n.t('no_leagues_available_for_this_market') }}</span>
                                    </template>
                                </CheckBox>
                            </div>
                            <ul
                                v-if="hasBigLeagues"
                                class="leagueList_list"
                            >
                                <li
                                    v-for="league in bigLeagueFilters"
                                    v-bind:key="league.id"
                                    class="leagueList_list_item"
                                >
                                    <CheckBox
                                        v-bind:id="league.name"
                                        v-bind:gtmLabel="tempSelectedLeagueIds.includes(league.id) ? `MajorLeague_${league.id}_Off` : `MajorLeague_${league.id}_On`"
                                        v-bind:checked="tempSelectedLeagueIds.includes(league.id)"
                                        v-on:change="toggleChecked(league)"
                                    >
                                        <template #label>
                                            <span class="labelText">
                                                {{ league.name }}
                                            </span>
                                        </template>
                                    </CheckBox>
                                </li>
                            </ul>
                            <div
                                v-bind:class="{ 'leagueList_catolog-disabled': !hasLeagues }"
                                class="leagueList_catolog"
                            >
                                <CheckBox
                                    id="selectAllLeague"
                                    v-bind:gtmLabel="isSelectAllTempLeagues ? `AllLeague_Off` : `AllLeague_On`"
                                    v-bind:checked="isSelectAllTempLeagues"
                                    v-bind:indeterminate="isSelectPartOfTempLeagues"
                                    v-bind:disabled="!hasLeagues"
                                    v-on:change="toggleSelectAll"
                                >
                                    <template #label>
                                        <span class="leagueList_catolog_label labelText">{{ i18n.t('all_leagues') }}</span>
                                        <span v-if="!hasLeagues"> - {{ i18n.t('no_leagues_available_for_this_market') }}</span>
                                    </template>
                                </CheckBox>
                            </div>
                            <ul v-if="hasLeagues" class="leagueList_list">
                                <li
                                    v-for="league in sortedLeagueFilters"
                                    v-bind:key="league.id"
                                    class="leagueList_list_item"
                                >
                                    <CheckBox
                                        v-bind:id="league.name"
                                        v-bind:gtmLabel="tempSelectedLeagueIds.includes(league.id) ? `AllLeague_${league.id}_Off` : `AllLeague_${league.id}_On`"
                                        v-bind:checked="tempSelectedLeagueIds.includes(league.id)"
                                        v-on:change="toggleChecked(league)"
                                    >
                                        <template #label>
                                            <span class="labelText">
                                                {{ league.name }}
                                            </span>
                                        </template>
                                    </CheckBox>
                                </li>
                            </ul>
                        </template>
                        <StaticSpinner v-else class="loading" />
                    </div>
                    <div class="selectLeague_modal_footer">
                        <VButton
                            v-gtm:@click
                            v-gtm:label="'Cancel'"
                            outline
                            color="white"
                            hoverOutlineColor="secondary"
                            hoverTextColor="secondary"
                            textColor="primary"
                            v-on:click="emit('close')"
                        >
                            {{ i18n.t('cancel') }}
                        </VButton>
                        <VButton
                            v-gtm:@click
                            v-gtm:label="'Go'"
                            color="primary"
                            hoverTextColor="white"
                            textColor="white"
                            v-bind:disabled="tempSelectedLeagueIds.length === 0"
                            v-on:click="applyFilter"
                        >
                            {{ i18n.t('go') }}
                        </VButton>
                    </div>
                </div>
            </div>
        </div>
    </Teleport>
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import type { PropType } from 'vue';
    import { computed, nextTick, onUnmounted, toRefs, watch } from 'vue';
    import CheckBox from '@/components/common/CheckBox.vue';
    import Count from '@/components/common/Count.vue';
    import StaticSpinner from '@/components/common/StaticSpinner.vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import VButton from '@/components/common/button/VButton.vue';
    import { useState } from '@/composable/useState';
    import { removeDuplicates } from '@/core/lib/array';
    import { scrollToTopImmediate } from '@/core/lib/dom';
    import { i18n } from '@/core/lib/i18n';
    import { getLocalesSortingFn } from '@/core/lib/string';
    import type { ILeagueFilter } from '@/interface/ILeagueFilter';
    import { LeagueSize } from '@/interface/enum';
    import { useScrollableStore } from '@/store/scrollableStore';
    import { useSelectedLeagueStore } from '@/store/selectedLeagueStore';

    const props = defineProps({
        leagueFilters: {
            type: Array as PropType<ILeagueFilter[]>,
            required: true,
            default: () => [],
        },
        loaded: {
            type: Boolean,
            required: true,
        },
    });
    const emit = defineEmits(['close']);

    const { leagueFilters, loaded } = toRefs(props);

    const sortedLeagueFilters = computed(() => {
        const filters = [...leagueFilters.value];
        const sortFn = getLocalesSortingFn();
        filters.sort((a, b) => sortFn(a.name, b.name));
        return filters;
    });

    const bigLeagueFilters = computed(() => sortedLeagueFilters.value.filter(league => league.size === LeagueSize.Big));

    const { selectedLeagueIds, selectedLeagueCount } = storeToRefs(useSelectedLeagueStore());
    const { setSelectedLeagueInfo, selectAllLeagues, syncSelectedLeagueInfosToSession } = useSelectedLeagueStore();
    const [tempSelectedLeagueIds, setTempSelectedLeagueIds] = useState<number[]>([]);
    const [isSelectAllTempLeagues, setIsSelectAllTempLeagues] = useState(false);
    const [isSelectAllTempBigLeagues, setIsSelectAllTempBigLeagues] = useState(false);

    const hasLeagues = computed(() => sortedLeagueFilters.value.length !== 0);
    const hasBigLeagues = computed(() => bigLeagueFilters.value.length !== 0);
    const isSelectPartOfTempLeagues = computed(() => !isSelectAllTempLeagues.value && tempSelectedLeagueIds.value.length > 0);
    const isSelectPartOfTempBigLeagues = computed(() => !isSelectAllTempBigLeagues.value
        && bigLeagueFilters.value.some(league => tempSelectedLeagueIds.value.includes(league.id)));

    const syncTempSelectAll = () => {
        setIsSelectAllTempLeagues(hasLeagues.value && leagueFilters.value.length === tempSelectedLeagueIds.value.length);
        setIsSelectAllTempBigLeagues(hasBigLeagues.value && bigLeagueFilters.value.every(league => tempSelectedLeagueIds.value.includes(league.id)));
    };

    const toggleChecked = (league: ILeagueFilter) => {
        const index = tempSelectedLeagueIds.value.findIndex(id => id === league.id);
        if (index === -1) {
            tempSelectedLeagueIds.value.push(league.id);
        } else {
            tempSelectedLeagueIds.value.splice(index, 1);
        }
        syncTempSelectAll();
    };

    const toggleSelectAll = () => {
        if (isSelectAllTempLeagues.value) {
            setTempSelectedLeagueIds([]);
        } else {
            setTempSelectedLeagueIds(leagueFilters.value.map(league => league.id));
        }
        syncTempSelectAll();
    };

    const toggleSelectAllBigLeague = () => {
        if (isSelectAllTempBigLeagues.value) {
            setTempSelectedLeagueIds(tempSelectedLeagueIds.value.filter(id => bigLeagueFilters.value.every(league => id !== league.id)));
        } else {
            setTempSelectedLeagueIds(removeDuplicates([...tempSelectedLeagueIds.value, ...bigLeagueFilters.value.map(league => league.id)]));
        }
        syncTempSelectAll();
    };

    const updateStore = () => {
        if (isSelectAllTempLeagues.value) {
            selectAllLeagues();
        } else {
            setSelectedLeagueInfo([...tempSelectedLeagueIds.value], isSelectAllTempBigLeagues.value);
        }
    };

    const applyFilter = async () => {
        updateStore();
        syncSelectedLeagueInfosToSession();
        emit('close');
        await nextTick();
        scrollToTopImmediate();
    };

    watch(leagueFilters, (newLeagueFilters) => {
        const newLeagueIds = newLeagueFilters.map(x => x.id);

        if (isSelectAllTempLeagues.value) {
            setTempSelectedLeagueIds(newLeagueIds);
            return;
        }

        let tempLeagueIds = tempSelectedLeagueIds.value.filter(id => newLeagueIds.includes(id));

        if (isSelectAllTempBigLeagues.value) {
            const newBigLeagueIds = newLeagueFilters.filter(x => x.size === LeagueSize.Big).map(x => x.id);
            tempLeagueIds = removeDuplicates(tempLeagueIds.concat(newBigLeagueIds));
        }

        setTempSelectedLeagueIds(tempLeagueIds);
    });

    watch(loaded, () => {
        if (!loaded.value) return;

        const leagueIds = selectedLeagueCount.value > 0
            ? [...selectedLeagueIds.value]
            : sortedLeagueFilters.value.map(x => x.id);
        setTempSelectedLeagueIds(leagueIds);
        syncTempSelectAll();
    }, { immediate: true });

    const { setIsScrollable } = useScrollableStore();
    setIsScrollable(false);
    onUnmounted(() => setIsScrollable(true));
</script>

<style lang="scss" scoped>
    @import "@/components/selectLeague/SelectLeague";
</style>
