import type { Ref } from 'vue';
import { ref } from 'vue';
import { CashOutService } from '@/components/cashOut/cashOutService';
import { Api } from '@/core/lib/api';
import type { IBet } from '@/interface/IBet';

export function useCashOutValues(runningBets: Ref<IBet[]>) {
    const cashOutServiceList = ref<CashOutService[]>([]);

    const refreshCashOutValues = async () => {
        try {
            const singleTransactionIds = runningBets.value.filter(bet => !bet.isMixParlay && !bet.isOutright).map(bet => bet.transactionId);
            const mpTransactionIds = runningBets.value.filter(bet => bet.isMixParlay).map(bet => bet.transactionId);

            const responses = await Promise.all([
                singleTransactionIds.length ? Api.getCashOutValues(singleTransactionIds) : Promise.resolve([]),
                mpTransactionIds.length ? Api.getMpCashOutValues(mpTransactionIds) : Promise.resolve([]),
            ]);
            const cashOutData = responses.flat();

            const transactionIds = [...singleTransactionIds, ...mpTransactionIds];
            cashOutServiceList.value = cashOutServiceList.value.filter(x => transactionIds.includes(x.transactionId));

            cashOutData.forEach((data) => {
                const index = cashOutServiceList.value.findIndex(cashOutService => cashOutService.transactionId === data.transactionId);

                if (index === -1) {
                    cashOutServiceList.value.push(new CashOutService(data));
                } else {
                    cashOutServiceList.value[index].updateCashOutData(data);
                }
            });
        } catch (error) {
            console.error(error);
        }
    };

    return {
        cashOutServiceList,
        refreshCashOutValues,
    };
}
