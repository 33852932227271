<template>
    <OddsDisplayBlock
        v-bind:groups="groups"
        v-bind:loading="!loaded"
        v-bind:initialIsExpanded="(index: number) => index < 3"
        v-bind:groupViewAll="groupViewAll"
        enableVirtualScroll
    />
</template>

<script lang="ts" setup>
    import orderBy from 'lodash/orderBy';
    import { computed, watchEffect } from 'vue';
    import { EuroDisplayContext } from '@/components/euroDisplay/EuroDisplayContext';
    import OddsDisplayBlock from '@/components/euroDisplay/oddsDisplay/OddsDisplayBlock.vue';
    import { useEuroRoute } from '@/composable/useEuroRoute';
    import { useEuroRouter } from '@/composable/useEuroRouter';
    import { groupByWithOrder } from '@/core/lib/array';
    import { useRegionEvents } from '@/core/oddsApi/composable/useRegionEvents';
    import type { IEventEuro } from '@/interface/IEvent';

    const { sportType, regionId, regionName, updateNameParams } = useEuroRoute();
    const regionIds = computed(() => [regionId.value]);

    const { date } = EuroDisplayContext.inject();
    const { events, loaded } = useRegionEvents(sportType, date, regionIds);

    const groups = computed(() => {
        const sortedEvents = orderBy(events.value, e => e.eventCode);
        const groups = groupByWithOrder(sortedEvents, e => e.league.id);
        return groups.map((group) => {
            const events = orderBy(group, [e => e.isLive], ['desc']);
            return {
                id: events[0].id,
                events,
                title: events[0].league.name,
                info: events[0].league.info ?? undefined,
            };
        });
    });

    watchEffect(() => {
        if (!events.value[0]) return;
        if (events.value[0].league.region.name === regionName.value) return;

        updateNameParams({
            regionName: events.value[0].league.region.name,
        });
    });

    const { getLeagueRoute } = useEuroRouter();
    const groupViewAll = (events: IEventEuro[]) => getLeagueRoute(sportType.value, events[0].league);
</script>
