import { intersection } from 'lodash';
import type { IColors, IRootObject } from '@/interface/sassVariables';

export enum BetSlipsEuroStatus {
    // lowest
    Valid,
    LessThanMinBet,
    GreaterThanMaxBet,
    PriceChange,
    Unavailable,
    Loading,
    // highest
}

export namespace BetSlipsEuroStatus{
    export const isInvalidStake = (statusList: BetSlipsEuroStatus[]) => intersection([
        BetSlipsEuroStatus.LessThanMinBet,
        BetSlipsEuroStatus.GreaterThanMaxBet,
    ], statusList).length > 0;
}

export interface IPlaceBetButton {
    title: string;
    isEnabled: boolean;
    color: keyof IColors | keyof IRootObject;
    action: () => void;
}
