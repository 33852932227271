import type { MaybeRef } from '@vueuse/core';
import { get } from '@vueuse/core';
import { computed, nextTick, ref } from 'vue';
import { deepCompareComputed } from '@/composable/deepCompareComputed';
import { useState } from '@/composable/useState';
import { assignArray } from '@/core/lib/array';
import { i18n } from '@/core/lib/i18n';
import { isInvalidLiveMp1X2Odds, isValidOdds } from '@/core/lib/oddsHelper';
import { deepFreeze } from '@/core/lib/utils';
import { formatOdds } from '@/core/oddsApi/helpers/formatOdds';
import type { EnumPresetFilterType } from '@/core/oddsApi/oddsApiType';
import { EnumOddsCategoryType, EnumOddsMarketFilterType, useOddsQuery } from '@/core/oddsApi/oddsApiType';
import type { IOdds } from '@/interface/IOdds';
import { EnumLanguage, PriceStyle } from '@/interface/enum';

export function useTicketOdds({
    eventId,
    oddsId,
    presetFilter,
    oddsCategory,
    priceStyle,
    marketFilter = EnumOddsMarketFilterType.All,
    isActive = true,
}: {
    eventId: number;
    oddsId?: number;
    presetFilter: EnumPresetFilterType;
    oddsCategory: EnumOddsCategoryType;
    priceStyle: MaybeRef<PriceStyle>;
    marketFilter?: EnumOddsMarketFilterType;
    isActive?: MaybeRef<boolean>;
}) {
    const variables = computed(() => ({
        query: {
            id: eventId,
            oddsId,
            filter: presetFilter,
            marketFilter,
            oddsCategory,
            priceStyle: PriceStyle.toOddsApi(get(priceStyle)),
            lang: EnumLanguage.toOddsApi(i18n.locale.value),
        },
    }));

    const [isEnabled, setIsEnabled] = useState(true);
    const refetch = async () => {
        if (isEnabled.value) {
            setIsEnabled(false);
            nextTick(() => setIsEnabled(true));
        }
    };
    const { onResult, loaded, loading } = useOddsQuery(
        variables,
        deepCompareComputed(() => ({
            enabled: get(isActive) && isEnabled.value,
        })),
    );

    const oddsList = ref<IOdds[]>([]);

    onResult((result) => {
        const isMixParlayMode = oddsCategory === EnumOddsCategoryType.MixParlay;
        const newOddsList = (result ?? [])
            .filter(odds => odds.eventResult?.marketGroup)
            .filter(odds => oddsId && odds.id === oddsId)
            .map(odds => deepFreeze(formatOdds(odds)))
            .filter(odds => isValidOdds(odds) && !isInvalidLiveMp1X2Odds(odds, isMixParlayMode));
        assignArray(oddsList.value, newOddsList, item => item.id);
    });

    return {
        loaded,
        loading,
        oddsList,
        refetch,
    };
}
