<template>
    <SvgIcon
        v-if="isLiveCourtAvailable"
        v-tooltip:top="i18n.t('live_court_icon_tooltip')"
        v-gtm:label="'LiveCourt'"
        v-gtm:@click
        class="icon live-court"
        v-bind:name="`icon-system-live-court-${invertCapitalize(SportType[event.sportType])}`"
        v-on:click="onClick"
    />
</template>

<script lang="ts" setup>
    import { toRefs } from 'vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import { useLiveCourt } from '@/composable/useLiveCourt';
    import { i18n } from '@/core/lib/i18n';
    import { invertCapitalize } from '@/core/lib/string';
    import type { IEvent, IEventEuro } from '@/interface/IEvent';
    import { SportType } from '@/interface/enum';

    const props = defineProps<{
        event: IEvent | IEventEuro;
        openPopupWindow: boolean;
    }>();

    const { event } = toRefs(props);
    const { isLiveCourtAvailable, openLiveCourt } = useLiveCourt(event);

    const onClick = () => {
        if (!props.openPopupWindow) return;

        openLiveCourt();
    };
</script>
