<template>
    <div class="subTabs">
        <ul class="subTabs_header">
            <slot />
        </ul>
        <div class="subTabs_content">
            <slot v-if="slots.All" name="All" />
            <template v-for="tab in tabs" v-else>
                <slot
                    v-if="tab.name === currentTab"
                    v-bind:key="tab.name"
                    v-bind:name="tab.name"
                />
            </template>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import { useSlots, watch } from 'vue';
    import { SubTabsContext } from '@/components/subTabs/SubTabsContext';
    import { SiteStyle } from '@/interface/enum';
    import { useCustomerStore } from '@/store/customerStore';

    const { siteStyle } = storeToRefs(useCustomerStore());
    if (siteStyle.value === SiteStyle.Euro) {
        import('@/components/subTabs/SubTabs.Euro.scss');
    } else {
        import('@/components/subTabs/SubTabs.scss');
    }

    const emit = defineEmits([
        'update:modelValue',
    ]);

    const props = defineProps({
        modelValue: {
            type: [String, Number],
            default: null,
        },
    });

    const { currentTab, tabs, selectTab } = SubTabsContext.provide({
        initialTab: props.modelValue,
    });

    const slots = useSlots();

    watch(() => props.modelValue, (newValue) => {
        if (currentTab.value !== newValue) selectTab(newValue);
    });

    watch(currentTab, (newValue) => {
        emit('update:modelValue', newValue);
    });
</script>
