import { storeToRefs } from 'pinia';
import { formatDate, getTimeWithGMT } from '@/core/lib/date';
import { DisplayTime } from '@/interface/enum';
import { useCustomerStore } from '@/store/customerStore';

export function useDisplayTimeFormat(date: Date, toFormat: string) {
    const displayDate = useDisplayTime(date);
    return formatDate(displayDate, toFormat);
}

export function useDisplayTime(date: Date) {
    const { displayTime } = storeToRefs(useCustomerStore());
    return displayTime.value === DisplayTime.SystemTime ? getTimeWithGMT(date, 8) : date;
}
