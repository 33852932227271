import { useDebounceFn, useIntervalFn } from '@vueuse/core';
import type { Ref } from 'vue';
import { computed } from 'vue';
import { useState } from '@/composable/useState';
import MapWithDefault from '@/core/lib/mapWithDefault';
import { EnumEventDateFilterType, EnumPresetFilterType, useEventsCountInfoQuery } from '@/core/oddsApi/oddsApiType';
import type { IMarketInfo } from '@/interface/IMarketInfo';
import type { IEventCount } from '@/interface/ISportMarketCount';
import { EventDateType, MarketPage, PresetFilterType, SportType } from '@/interface/enum';
import { SportInfo } from '@/models/SportInfo';
import type { IFavorite } from '@/store/favoriteStore';

const filters = [
    { presetFilter: EnumPresetFilterType.All, date: EnumEventDateFilterType.All },
    { presetFilter: EnumPresetFilterType.All, date: EnumEventDateFilterType.Today },
    { presetFilter: EnumPresetFilterType.NonLive, date: EnumEventDateFilterType.EarlyMarket },
    { presetFilter: EnumPresetFilterType.NonLive, date: EnumEventDateFilterType.Plus1 },
    { presetFilter: EnumPresetFilterType.NonLive, date: EnumEventDateFilterType.Plus2 },
    { presetFilter: EnumPresetFilterType.NonLive, date: EnumEventDateFilterType.Plus3 },
    { presetFilter: EnumPresetFilterType.NonLive, date: EnumEventDateFilterType.Plus4 },
    { presetFilter: EnumPresetFilterType.NonLive, date: EnumEventDateFilterType.Plus5 },
    { presetFilter: EnumPresetFilterType.NonLive, date: EnumEventDateFilterType.Plus6Over },
];

export function useFavoriteCount(favorites: Ref<MapWithDefault<SportType, IFavorite>>) {
    const [countMap] = useState(new MapWithDefault<SportType, IEventCount[]>(sportType => ([{
        sportType,
        eventCount: 0,
        liveEventCount: 0,
        presetFilter: PresetFilterType.All,
        date: EventDateType.All,
        marketPage: MarketPage.MyFavorites,
    }])));

    const _updateFavoriteCount = (favorite: IFavorite) => {
        if (favorite.eventIds.length || favorite.leagueIds.length) {
            const { onResult } = useEventsCountInfoQuery({
                query: {
                    sport: SportType.toOddsApi(favorite.sportType),
                    filters,
                    eventIds: favorite.eventIds,
                    tournamentIds: favorite.leagueIds,
                },
            });
            onResult((result) => {
                countMap.value.set(favorite.sportType, (result ?? []).map<IEventCount>(countInfo => ({
                    sportType: favorite.sportType,
                    eventCount: countInfo.eventCount,
                    liveEventCount: countInfo.liveEventCount,
                    presetFilter: PresetFilterType.fromOddsApi(countInfo.queryFilter.presetFilter),
                    date: EventDateType.fromOddsApi(countInfo.queryFilter.date),
                    marketPage: MarketPage.MyFavorites,
                })));
            });
        } else {
            countMap.value.set(favorite.sportType, [{
                sportType: favorite.sportType,
                eventCount: 0,
                liveEventCount: 0,
                presetFilter: PresetFilterType.All,
                date: EventDateType.All,
                marketPage: MarketPage.MyFavorites,
            }]);
        }
    };

    const _updateCount = () => {
        favorites.value.forEach(x => _updateFavoriteCount(x));
    };
    const updateCount = useDebounceFn(_updateCount, 300, { maxWait: 5000 });
    useIntervalFn(() => updateCount(), 30000, { immediateCallback: true });

    const favoritesSportList = computed(() => countMap.value.values()
        .flat()
        .filter(x => x.presetFilter === PresetFilterType.All && x.date === EventDateType.All)
        .map(x => new SportInfo({
            sportType: x.sportType,
            liveEventCount: x.liveEventCount,
            nonLiveEventCount: x.eventCount - x.liveEventCount,
        })));

    const favoriteMarketListMap = computed(() => {
        const marketInfoMap = new Map<SportType, IMarketInfo[]>();
        countMap.value.forEach((countInfo, sportType) => {
            marketInfoMap.set(sportType, countInfo.map<IMarketInfo>(x => ({
                sportType,
                marketPage: x.marketPage,
                date: x.date,
                presetFilter: x.presetFilter,
                nonLiveEventCount: x.eventCount - x.liveEventCount,
                liveEventCount: x.liveEventCount,
                totalEventCount: x.eventCount,
            })));
        });
        return marketInfoMap;
    });

    return {
        updateCount,
        favoritesSportList,
        favoriteMarketListMap,
    };
}
