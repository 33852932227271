import { first } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { SportType } from '@/interface/enum';
import { useToggleStore } from '@/store/toggleStore';

const defaultSportsOrder = [
    SportType.Soccer,
    SportType.VirtualFootball,
    SportType.Basketball,
    SportType.VirtualBasketball,
    SportType.Badminton,
    SportType.Tennis,
    SportType.Volleyball,
    SportType.EsportsForDisplay,
    SportType.Esports,
    SportType.TableTennis,
    SportType.Football,
    SportType.Baseball,
    SportType.MuayThai,
    SportType.Golf,
    SportType.Olympic,
    SportType.Pool,
    SportType.Cricket,
    SportType.Hockey,
    SportType.Rugby,
    SportType.MixedMartialArts,
    SportType.Handball,
    SportType.MotorSport,
    SportType.Darts,
    SportType.Boxing,
    SportType.BeachVolleyball,
    SportType.AussieRulesFootball,
    SportType.FieldHockey,
    SportType.WaterPolo,
    SportType.Futsal,
    SportType.WinterSports,
    SportType.Bandy,
    SportType.Cycling,
    SportType.Athletics,
    SportType.Hurling,
    SportType.Squash,
    SportType.GaelicFootball,
    SportType.Others,
    SportType.WinterOlympic,
    SportType.BeachSoccer,
    SportType.Entertainment,
    SportType.Lacrosse,
    SportType.Racing,
];

export function useSportsOrder() {
    const { defaultSportType: _defaultSportType } = storeToRefs(useToggleStore());

    const sportsOrder = computed(() => ([
        _defaultSportType.value,
        ...defaultSportsOrder.filter(sport => sport !== _defaultSportType.value),
    ]));

    const defaultSportType = computed(() => first(sportsOrder.value) ?? SportType.Soccer);

    return {
        sportsOrder,
        defaultSportType,
    };
}
