import { uniq } from 'lodash';
import { defineStore, storeToRefs } from 'pinia';
import { watch } from 'vue';
import { useFavoriteCount } from '@/composable/useFavoriteCount';
import { useSessionStorage } from '@/composable/useSessionStorage';
import { useState } from '@/composable/useState';
import MapWithDefault from '@/core/lib/mapWithDefault';
import { SiteStyle, type SportType } from '@/interface/enum';
import { useCustomerStore } from '@/store/customerStore';
import { useToggleStore } from '@/store/toggleStore';

export interface IFavorite {
    sportType: SportType;
    eventIds: number[];
    leagueIds: number[];
}

export const useFavoriteStore = defineStore('Favorite', () => {
    const [favorites] = useState(new MapWithDefault<SportType, IFavorite>(sportType => ({
        sportType,
        eventIds: [],
        leagueIds: [],
    })));
    const { isMyFavoriteEnabled } = storeToRefs(useToggleStore(SiteStyle.Asia));
    const { favoritesSportList, favoriteMarketListMap, updateCount } = useFavoriteCount(favorites);
    const { accountId } = storeToRefs(useCustomerStore());

    const [sessionFavorites, setSessionFavorites] = useSessionStorage<IFavorite[]>(
        `favorites:${accountId.value}`,
        [],
        {
            decode: v => JSON.parse(v),
            encode: v => JSON.stringify(v),
        },
    );

    const getFavorites = () => {
        if (!isMyFavoriteEnabled.value) return;

        sessionFavorites.value.forEach((x) => {
            favorites.value.set(x.sportType, {
                sportType: x.sportType,
                eventIds: x.eventIds ?? [],
                leagueIds: x.leagueIds ?? [],
            });
        });
    };
    getFavorites();

    const updateFavorites = () => {
        if (!isMyFavoriteEnabled.value) return;

        const result = favorites.value.filter(x => x.eventIds.length !== 0 || x.leagueIds.length !== 0);
        setSessionFavorites(result);
    };
    watch(favorites, updateFavorites, { deep: true });

    const getFavoriteBySport = (sportType: SportType) => favorites.value.get(sportType);

    const isFavoriteEvent = (sport: SportType, eventId: number) => getFavoriteBySport(sport).eventIds.includes(eventId);

    const isFavoriteLeague = (sport: SportType, leagueId: number) => getFavoriteBySport(sport).leagueIds.includes(leagueId);

    const addEvent = (sport: SportType, eventId: number) => {
        const sportFavorite = getFavoriteBySport(sport);
        sportFavorite.eventIds = uniq([...sportFavorite.eventIds, eventId]);
        updateCount();
    };
    const removeEvent = (sport: SportType, eventId: number) => {
        const sportFavorite = getFavoriteBySport(sport);
        sportFavorite.eventIds = sportFavorite.eventIds.filter(e => e !== eventId);
        updateCount();
    };

    const addLeague = (sport: SportType, leagueId: number) => {
        const sportFavorite = getFavoriteBySport(sport);
        sportFavorite.leagueIds = uniq([...sportFavorite.leagueIds, leagueId]);
        updateCount();
    };
    const removeLeague = (sport: SportType, leagueId: number) => {
        const sportFavorite = getFavoriteBySport(sport);
        sportFavorite.leagueIds = sportFavorite.leagueIds.filter(e => e !== leagueId);
        updateCount();
    };

    return {
        favorites,
        isFavoriteEvent,
        isFavoriteLeague,
        addEvent,
        removeEvent,
        addLeague,
        removeLeague,
        getFavoriteBySport,
        favoritesSportList,
        favoriteMarketListMap,
    };
});
