<template>
    <div
        class="SportsNavBar_container"
        v-bind:class="{ showTriangle }"
    >
        <div
            v-if="isShowSwiper"
            class="SportsNavBar_swiper_left"
            v-bind:class="{
                'SportsNavBar_swiper--disabled': arrivedState.left,
            }"
            v-on:click="scrollToLeft"
        >
            <SvgIcon size="xl" name="icon-system-arrow" />
        </div>
        <div
            ref="scrollContainer"
            class="SportsNavBar_sport_list"
        >
            <!-- todo: SportsNavBar_sport_item don't show active::after on home page -->
            <div
                v-for="sport in sportList"
                v-bind:key="sport.sportType"
                v-gtm:@click
                v-gtm:disabled="!gtmLabelPrefix"
                v-gtm:type="'MatchCard'"
                v-gtm:label="gtmLabelPrefix ? gtmLabelPrefix + '_' + sport.sportType : null"
                class="SportsNavBar_sport_item"
                v-bind:class="sportType === sport.sportType ? 'active' : ''"
                v-on:click="sportType = sport.sportType"
            >
                <SportIcon
                    v-bind:sportType="sport.sportType"
                    v-bind:size="24"
                    v-bind:twoColor="sportType !== sport.sportType"
                    v-bind:colorful="sportType === sport.sportType"
                    v-bind:color="'white'"
                    v-bind:style="sportType === sport.sportType ? {} : {
                        '--color1': 'var(--gray-600)',
                        '--color2': 'var(--gray-800)',
                        '--color3': 'var(--gray-500)',
                        '--color4': 'var(--gray-700)',
                    }"
                />
                <Count
                    v-if="showCount"
                    class="SportsNavBar_sport_item_count"
                    bgColor="live"
                    textColor="white"
                >
                    {{ sport.count }}
                </Count>
                <span class="SportsNavBar_sport_item_name">
                    {{ i18n.t(`sport_type_name_${sport.sportType}`) }}
                </span>
            </div>
        </div>
        <div
            v-if="isShowSwiper"
            class="SportsNavBar_swiper_right"
            v-bind:class="{
                'SportsNavBar_swiper--disabled': arrivedState.right,
            }"
            v-on:click="scrollToRight"
        >
            <SvgIcon size="xl" name="icon-system-arrow" />
        </div>
    </div>
</template>

<script setup lang="ts">
    import { useResizeObserver, useScroll } from '@vueuse/core';
    import { storeToRefs } from 'pinia';
    import { computed, nextTick, onUpdated, ref, watch } from 'vue';
    import Count from '@/components/common/Count.vue';
    import SportIcon from '@/components/common/SportIcon.vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import { EuroDisplayContext } from '@/components/euroDisplay/EuroDisplayContext';
    import { i18n } from '@/core/lib/i18n';
    import type { ISportListItem } from '@/interface/ISport';
    import type { SportType } from '@/interface/enum';
    import { useEuroSideBarStore } from '@/store/sideBarStore.Euro';

    const props = withDefaults(defineProps<{
        showCount?: boolean;
        showSportLiveCount?: boolean;
        showTriangle?: boolean;
        gtmLabelPrefix?: string | null;
    }>(), {
        showCount: false,
        showSportLiveCount: false,
        showTriangle: false,
        gtmLabelPrefix: null,
    });

    const { sportType, isMultiples } = EuroDisplayContext.inject();
    const { sportList: _sportList, mixParlaySportInfo } = storeToRefs(useEuroSideBarStore());
    const sportList = computed(() => _sportList.value
        .filter((sport): sport is ISportListItem & { sportType: SportType } => !!sport.sportType)
        .map((sport): {sportType: SportType, count: number} => {
            if (isMultiples.value) {
                const mpSport = mixParlaySportInfo.value.find(mpSport => mpSport.sportType === sport.sportType);
                if (mpSport) {
                    return {
                        sportType: sport.sportType,
                        count: props.showSportLiveCount ? mpSport.liveEventCount : mpSport.totalEventCount,
                    };
                }
            } else {
                return {
                    sportType: sport.sportType,
                    count: props.showSportLiveCount ? sport.liveCount : sport.todayExpandedCount,
                };
            }
            return ({
                sportType: sport.sportType,
                count: 0,
            });
        })
        .filter(sport => sport.count > 0));

    const scrollContainer = ref<HTMLElement | null>(null);
    const { x, arrivedState, measure } = useScroll(scrollContainer, { offset: { left: 1, right: 1 } });
    const isShowSwiper = computed(() => !(arrivedState.left && arrivedState.right));

    const scrollToLeft = () => {
        scrollContainer.value?.scroll({ left: x.value - 200, behavior: 'smooth' });
    };
    const scrollToRight = () => {
        scrollContainer.value?.scroll({ left: x.value + 200, behavior: 'smooth' });
    };

    const setSportIfNotAvailable = () => {
        if (sportList.value.length === 0) return;

        if (!sportList.value.find(sport => sport.sportType === sportType.value)) {
            sportType.value = sportList.value[0].sportType;
        }
    };

    const scrollToCurrentSport = () => {
        const sportIndex = sportList.value.findIndex(sport => sport.sportType === sportType.value);
        if (sportIndex > 0) {
            const sportItem = scrollContainer.value?.children[sportIndex];
            if (sportItem) {
                // scroll to active sport item
                const { left, width } = sportItem.getBoundingClientRect();
                const { left: containerLeft, width: containerWidth } = scrollContainer.value?.getBoundingClientRect() ?? { left: 0, width: 0 };
                const offset = left - containerLeft;
                const offsetRight = offset + width;
                if (offset < 0) {
                    scrollContainer.value?.scroll({ left: x.value + offset, behavior: 'smooth' });
                } else if (offsetRight > containerWidth) {
                    scrollContainer.value?.scroll({ left: x.value + offsetRight - containerWidth, behavior: 'smooth' });
                }
            }
        }
    };

    watch(sportList, async (newValue, oldValue) => {
        const dataAvailableImmediately = oldValue === undefined && newValue.length > 0;
        const dataAvailableAfterQuery = oldValue?.length === 0 && newValue.length > 0;
        if (dataAvailableImmediately || dataAvailableAfterQuery) {
            setSportIfNotAvailable();
            await nextTick();
            scrollToCurrentSport();
        }
    }, { immediate: true });

    useResizeObserver(scrollContainer, () => {
        measure();
    });

    onUpdated(() => {
        measure();
    });
</script>

<style lang="scss" scoped>
    @import "@/components/navbarBlock/euro/SportsNavBar";
</style>
