<template>
    <div class="skeleton">
        <span v-bind:class="['skeleton_line', widthType]" />
    </div>
</template>

<script lang="ts" setup>
    import { computed } from 'vue';
    import type { PropType } from 'vue';

    const props = defineProps({
        widthType: {
            type: String as PropType<'third' | 'half' | ''>,
            default: '',
        },
    });

    const widthType = computed(() => {
        switch (props.widthType) {
            case 'third':
                return 'skeleton_line-third';
            case 'half':
                return 'skeleton_line-half';
            default:
                return '';
        }
    });
</script>

<style lang="scss" scoped>
    @import "@/components/common/skeleton/Skeleton";
</style>
