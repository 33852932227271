<template>
    <div class="betSlipContainer notranslate">
        <div class="ticketContainer">
            <span class="ticketId">ID: {{ receipt.transId }}</span>
            <slot name="price" />
            <ReceiptTicket
                v-for="ticket in receipt.tickets"
                v-bind:key="ticket.key"
                v-bind:ticket="ticket"
                v-bind:hidePrice="hideTicketPrice"
            />
        </div>
        <div v-if="!hideDetail" class="receipt">
            <div class="detailText_item">
                <span v-if="receipt.voucherId">{{ i18n.t('voucher_stake') }}</span>
                <span v-else>{{ i18n.t('stake') }}</span>
                <span class="amount">
                    <CurrencyIcon />
                    {{ displayStake }}
                </span>
            </div>
            <div class="detailText_item">
                <strong>{{ i18n.t('bet_slip_max_payout') }}</strong>
                <strong class="amount">
                    <CurrencyIcon />
                    {{ maxPayout }}
                </strong>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import CurrencyIcon from '@/components/common/CurrencyIcon.vue';
    import ReceiptTicket from '@/components/receipt/ReceiptTicket.vue';
    import type { Receipt } from '@/composable/useReceiptCollection';
    import { i18n } from '@/core/lib/i18n';
    import { useCustomerStore } from '@/store/customerStore';

    const props = withDefaults(defineProps<{
        receipt: Receipt;
        hideTicketPrice?: boolean;
        hideDetail?: boolean;
    }>(), {
        hideTicketPrice: false,
        hideDetail: false,
    });

    const { formatMoneyWithDecimal } = useCustomerStore();
    const displayStake = computed(() => formatMoneyWithDecimal(props.receipt.stake));
    const maxPayout = computed(() => formatMoneyWithDecimal(props.receipt.maxPayout));
</script>

<style scoped lang="scss">
    @import "@/components/betSlip/BetSlip.Euro";
    @import "@/components/betSlip/BetSlipBlock.Euro";

    .receipt {
        display: flex;
        width: 100%;
        flex-direction: column;
        color: var(--brandColor-900);

        .detailText_item {
            &:not(:last-child) {
                margin-bottom: var(--spacers-sm);
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
</style>
