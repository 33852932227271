<template>
    <SingleBetSlipBlock v-if="receipts.list.length === 0" />
    <SingleReceiptBlock v-else />
</template>

<script lang="ts" setup>
    import SingleBetSlipBlock from '@/components/betSlip/SingleBetSlipBlock.Euro.vue';
    import SingleReceiptBlock from '@/components/receipt/SingleReceiptBlock.vue';
    import { BetSlipType } from '@/interface/IBetSlip';
    import { useEuroBetSlipStore } from '@/store/betSlipStore.euro';

    const { useBetSlipsStoreByType } = useEuroBetSlipStore();
    const { receipts } = useBetSlipsStoreByType(BetSlipType.Single);
</script>
