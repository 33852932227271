import type { OddsTypeFragment } from '@/core/oddsApi/oddsApiType';
import { EnumHandicapType, EnumMarketType } from '@/core/oddsApi/oddsApiType';
import type { IOdds } from '@/interface/IOdds';
import { HandicapType, MarketType, OddsOption, SportType } from '@/interface/enum';

export function formatOdds(odds: OddsTypeFragment): IOdds {
    const marketType = MarketType.fromOddsApi(odds.marketType);
    // eslint-disable-next-line no-nested-ternary
    const handicapType = odds.eventResult
        ? (odds.isLive ? odds.eventResult.liveHandicapType : odds.eventResult.nonLiveHandicapType)
        : EnumHandicapType.Unknown;

    const getOddsHandicapType = (odds: OddsTypeFragment): HandicapType => {
        const isHandicap = [EnumMarketType.Handicap, EnumMarketType.FH_Handicap].includes(odds.marketType);
        if (!isHandicap) return HandicapType.Unknown;

        let handicapType = HandicapType.Unknown;
        if (odds.point > 0) handicapType = HandicapType.Home;
        if (odds.point < 0) handicapType = HandicapType.Away;

        return handicapType;
    };
    return {
        id: odds.id,
        isLive: odds.isLive,
        hasMixParlay: odds.hasMixParlay,
        point: odds.point,
        handicapType: getOddsHandicapType(odds),
        status: odds.status,
        betCondition: odds.betCondition,
        sortOrder: odds.sortOrder,
        sportType: SportType.fromOddsApi(odds.sport),
        marketType,
        prices: odds.prices ? odds.prices.map(({ price, option }) => ({
            price: price || 0,
            option: OddsOption.fromOddsApi(option, marketType),
        })) : [],
        eventResult: {
            id: odds.eventResult?.id ?? 0,
            eventId: odds.eventResult?.eventId ?? 0,
            handicapType: HandicapType.fromOddsApi(handicapType),
            marketGroup: odds.eventResult?.marketGroup ? {
                id: odds.eventResult.marketGroup.id,
                name: odds.eventResult.marketGroup.marketGroupName ?? '',
                awayExtension: odds.eventResult.marketGroup.awayExtension ?? '',
                awayTemplate: odds.eventResult.marketGroup.awayTemplate,
                displayPriority: odds.eventResult.marketGroup.displayPriority,
                homeExtension: odds.eventResult.marketGroup.homeExtension ?? '',
                homeTemplate: odds.eventResult.marketGroup.homeTemplate,
                isSingle: odds.eventResult.marketGroup.isSingle,
                isYesNo: odds.eventResult.marketGroup.isYesNo,
            } : {
                id: -1,
                name: '',
                awayExtension: '',
                awayTemplate: '',
                displayPriority: 0,
                homeExtension: '',
                homeTemplate: '',
                isSingle: false,
                isYesNo: false,
            },
            liveHomeScore: odds.eventResult?.liveHomeScore ?? 0,
            liveAwayScore: odds.eventResult?.liveAwayScore ?? 0,
        },
    };
}
