import type { ITeamNameDisplay } from '@/core/lib/betOptionDisplay';
import { getBetOptionDisplay } from '@/core/lib/betOptionDisplay';
import type { ITicket as ITicketAsia, ITicketEuro } from '@/interface/IBetSlip';
import { MarketType } from '@/interface/enum';

type ITicket = ITicketAsia | ITicketEuro;

type DisplayOptionHandler = (ticket: ITicket) => {
    name: string;
    isFavorite: boolean;
}

type DisplayMarketRules = Exclude<MarketType, MarketType.Unknown
| MarketType.Game
| MarketType.MixParlay
| MarketType.BetBuilder
>;

const marketRuleMap: Record<DisplayMarketRules, DisplayOptionHandler> = {
    [MarketType.Handicap]: displayHDP,
    [MarketType.FH_Handicap]: displayHDP,
    [MarketType.OddEven]: displayOE,
    [MarketType.FH_OddEven]: displayOE,
    [MarketType.OverUnder]: displayOU,
    [MarketType.FH_OverUnder]: displayOU,
    [MarketType.CorrectScore]: displayCS,
    [MarketType.FH_CorrectScore]: displayCS,
    [MarketType.DoubleChance]: displayDoubleChance,
    [MarketType.TotalGoal]: displayTotalGoal,
    [MarketType.HTFT]: displayHTFT,
    [MarketType.FGLG]: displayFGLG,
    [MarketType.MoneyLine]: displayML,
    [MarketType._1X2]: display1X2,
    [MarketType.FH_1X2]: display1X2,
    [MarketType.LiveScore]: displayAsianLiveScore,
    [MarketType.FH_LiveScore]: displayAsianLiveScore,
    [MarketType.OutRight]: displayOutright,
};

export function getTicketBetOptionDisplay(ticket: ITicket) {
    const displayFunc = marketRuleMap[ticket.odds.marketType as DisplayMarketRules];
    return displayFunc ? displayFunc(ticket) : {
        name: '',
        isFavorite: false,
    };
}

function displayHDP(ticket: ITicket) {
    const teams = createTeamNameDisplay(ticket);
    return getBetOptionDisplay.displayHDP(ticket.priceOption.option, ticket.odds.handicapType, teams);
}

function displayOE(ticket: ITicket) {
    return getBetOptionDisplay.displayOE(ticket.priceOption.option);
}

function displayOU(ticket: ITicket) {
    return getBetOptionDisplay.displayOU(ticket.priceOption.option, ticket.odds.eventResult.marketGroup.id);
}

function displayCS(ticket: ITicket) {
    return getBetOptionDisplay.displayCS(
        ticket.priceOption.option,
        {
            home: ticket.event.homeTeam.liveScore ?? 0,
            away: ticket.event.awayTeam.liveScore ?? 0,
        },
        ticket.odds.marketType,
        ':',
    );
}

function displayDoubleChance(ticket: ITicket) {
    return getBetOptionDisplay.displayDoubleChance(ticket.priceOption.option);
}

function displayTotalGoal(ticket: ITicket) {
    return getBetOptionDisplay.displayTotalGoal(ticket.priceOption.option, '~');
}

function displayHTFT(ticket: ITicket) {
    return getBetOptionDisplay.displayHTFT(ticket.priceOption.option);
}

function displayFGLG(ticket: ITicket) {
    return getBetOptionDisplay.displayFGLG(ticket.priceOption.option);
}

function displayML(ticket: ITicket) {
    const teams = createTeamNameDisplay(ticket);
    return getBetOptionDisplay.displayML(ticket.priceOption.option, teams);
}

function display1X2(ticket: ITicket) {
    const teams = createTeamNameDisplay(ticket);
    return getBetOptionDisplay.display1X2(ticket.priceOption.option, teams);
}

function displayAsianLiveScore(ticket: ITicket) {
    const teams = createTeamNameDisplay(ticket);
    return getBetOptionDisplay.displayAsianLiveScore(
        ticket.priceOption.option,
        ticket.odds.marketType,
        {
            home: ticket.event.homeTeam.liveScore ?? 0,
            away: ticket.event.awayTeam.liveScore ?? 0,
        },
        teams,
    );
}

function displayOutright(ticket: ITicket) {
    return {
        name: ticket.event.homeTeam.name ?? '',
        isFavorite: false,
    };
}

function createTeamNameDisplay(ticket: ITicket): ITeamNameDisplay {
    return {
        homeTeamName: ticket.event.homeTeam.name ?? '',
        homeExtension: ticket.odds.eventResult.marketGroup.homeExtension,
        homeTemplate: ticket.odds.eventResult.marketGroup.homeTemplate,
        awayTeamName: ticket.event.awayTeam.name ?? '',
        awayExtension: ticket.odds.eventResult.marketGroup.awayExtension,
        awayTemplate: ticket.odds.eventResult.marketGroup.awayTemplate,
    };
}
