<template>
    <div class="resultRow resultRow-header">
        <div
            v-for="column in headerColumns"
            v-bind:key="column.key"
            class="resultColumn"
            v-bind:class="{
                'resultColumn-fluid': column.key === 'name',
                name: column.key === 'name',
            }"
            v-bind:style="{ width: column.width ? `${column.width}px` : 'auto' }"
        >
            {{ column.name }}
        </div>
    </div>
    <div class="resultRow resultRow-content">
        <div class="resultColumn resultColumn-fluid name">
            <div>
                {{ matchResult.homeOption }}
            </div>
            <div>
                {{ matchResult.awayOption }}
            </div>
        </div>
        <div
            v-for="(column, i) in bodyColumns"
            v-bind:key="i"
            class="resultColumn"
            v-bind:style="{ width: `${column.width}px` }"
        >
            <div>{{ column.resultDisplay }}</div>
            <StatusTag
                v-if="column.statusDisplay"
                v-bind:text="column.statusDisplay"
                size="sm"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
    import type { PropType } from 'vue';
    import { computed } from 'vue';
    import StatusTag from '@/components/common/statusTag/StatusTag.vue';
    import { getColumnGroupsByScoringType, getColumnWidth } from '@/components/results/resultColumnHelper';
    import { getResultDisplayInfos } from '@/components/results/resultHelper';
    import { i18n } from '@/core/lib/i18n';
    import type { IColumn } from '@/interface/IColumn';
    import type { IMatchResult } from '@/interface/IResult';
    import type { IResultDisplayInfo } from '@/interface/IResultDisplayInfo';
    import { ColumnGroup, SportType } from '@/interface/enum';

    const props = defineProps({
        matchResult: {
            type: Object as PropType<IMatchResult>,
            required: true,
        },
    });

    const betTypeGroupName = computed(() => {
        const { betTypeGroupName, thirdPartyCode, sportType } = props.matchResult;
        return sportType === SportType.Cricket && thirdPartyCode
            ? `${betTypeGroupName} [${thirdPartyCode}]`
            : betTypeGroupName;
    });

    const columnGroups = computed(() => getColumnGroupsByScoringType(props.matchResult.scoringType));

    const headerColumns = computed(() => {
        let columns: IColumn[] = [{ key: 'name', name: betTypeGroupName.value }];
        columns = columns.concat(columnGroups.value.reduce<IColumn[]>((result, columnGroup) => {
            const { group, count } = columnGroup;
            const columnTitle = (props.matchResult.sportType === SportType.Baseball && columnGroup.group === ColumnGroup.FirstHalfScore)
                ? i18n.t('results_header_first_five_innings')
                : i18n.t(`results_header_${group}`);
            const cols = [...Array(count).keys()].reduce<IColumn[]>((cols, index) => cols.concat({
                key: `${group}_${index + 1}`,
                name: count !== 1
                    ? i18n.t(`results_header_${group}_${index + 1}`)
                    : columnTitle,
                width: getColumnWidth(group, props.matchResult.sportType),
            }), []);
            return result.concat(cols);
        }, []));
        return columns;
    });

    const bodyColumns = computed(() => columnGroups.value.reduce<IResultDisplayInfo[]>(
        (columns, columnGroup) => columns.concat(
            getResultDisplayInfos(columnGroup.group, props.matchResult),
        ),
        [],
    ));
</script>

<style lang="scss" scoped>
    @import "@/components/results/NewBetTypeResult";
</style>
