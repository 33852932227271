<template>
    <SvgIcon
        v-bind:name="name"
        v-bind:size="size"
        v-bind:color="color"
    />
</template>

<script lang="ts" setup>
    /* eslint-disable vue/require-default-prop */
    import type { PropType } from 'vue';
    import { computed } from 'vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import { invertCapitalize } from '@/core/lib/string';
    import { SportType } from '@/interface/enum';

    const props = defineProps({
        sportType: {
            type: Number as PropType<SportType>,
            required: true,
        },
        colorful: {
            type: Boolean,
            required: false,
        },
        twoColor: {
            type: Boolean,
            required: false,
        },
        size: {
            type: [Number, String],
            default: () => 'default',
        },
        color: {
            type: String,
            default: () => '',
        },
    });

    const name = computed(() => {
        // eslint-disable-next-line no-nested-ternary
        const prefix = props.colorful
            ? 'icon-sport-color'
            : props.twoColor
                ? 'icon-sport-2color'
                : 'icon-sport';
        const sportName = invertCapitalize(SportType[props.sportType]);
        return `${prefix}-${sportName}`;
    });
</script>
