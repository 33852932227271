<template>
    <VButton
        v-gtm:label="'Statistics'"
        v-gtm:@click
        v-tooltip="i18n.t('statistics_icon_tooltip')"
        v-bind:disabled="!isStatisticsAvailable"
        v-on:click="openStatistics"
    >
        <SvgIcon
            class="icon live-stream"
            name="icon-system-chart"
            size="xl"
        />
    </VButton>
</template>

<script lang="ts" setup>
    import { toRefs } from 'vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import VButton from '@/components/common/button/VButton.vue';
    import { useStatistics } from '@/composable/useStatistics';
    import { i18n } from '@/core/lib/i18n';
    import type { IEvent, IEventEuro } from '@/interface/IEvent';

    const props = defineProps<{
        event: IEvent | IEventEuro | null;
    }>();
    const { event } = toRefs(props);
    const { isStatisticsAvailable, openStatistics } = useStatistics(event);
</script>
