import { createInstance } from '@/core/lib/api';
import { getGamesApiUrl, getHost } from '@/core/lib/url';

export const gamesApiInstance = createInstance(`${getGamesApiUrl()}/games`);

interface OpenGameResult {
    gameUrl: string;
}

export const GamesApi = {
    openGame(gameCode: number, gameFrom: number) {
        return gamesApiInstance.post<OpenGameResult>('WebOpenGame', {
            gameCode,
            gameFrom,
            serviceProvider: 'C',
            logonDomain: getHost(),
        });
    },
};
