import type { Ref } from 'vue';
import { computed } from 'vue';
import { useStreamRestriction } from '@/composable/useStreamRestriction';
import { openLiveStream } from '@/core/lib/liveStream';
import type { IStreamEvent } from '@/interface/IStream';
import { BetRadarStreamStatus } from '@/interface/enum';

export type UseLiveStreamEvent = Pick<IStreamEvent, 'stream' | 'isLive' | 'sportType' | 'league'>;

// TODO: refactor with open-close principle to separate provider implementations
export function useLiveStream(event: Ref<UseLiveStreamEvent | null>) {
    const {
        allowBetRadarLiveStream,
        allowGLiveStream,
        allowMuayThaiLiveStream,
        allowBetConstructLiveStream,
        isBetRadarMapped,
    } = useStreamRestriction();

    const isTwitchAvailable = computed(() => !!(event.value?.stream?.videoUrl?.includes('player.twitch.tv')));

    const isMuayThaiAvailable = computed(() => !!(event.value?.stream?.videoUrl
        && allowMuayThaiLiveStream(event.value.sportType)));

    const isBetRadarAvailable = computed(() => !!(event.value?.stream?.betRadar
        && allowBetRadarLiveStream(event.value.stream.betRadar.allowedCountries)
        && isBetRadarMapped(event.value.stream.betRadar)));

    const isGLiveAvailable = computed(() => !!(event.value?.stream?.gLive
        && allowGLiveStream(event.value.league.size)));

    const isBetConstructAvailable = computed(() => !!(event.value?.stream?.betConstruct
        && allowBetConstructLiveStream(event.value.stream.betConstruct.allowedCountries)));

    /** whether live stream is mapped */
    const isLiveStreamAvailable = computed(() => {
        if (!event.value?.stream) return false;

        return [
            isTwitchAvailable,
            isMuayThaiAvailable,
            isBetRadarAvailable,
            isGLiveAvailable,
            isBetConstructAvailable,
        ].some(x => x.value);
    });

    const isTwitchPlayable = computed(() => isTwitchAvailable.value);
    const isMuayThaiPlayable = computed(() => isMuayThaiAvailable.value);
    const isBetRadarPlayable = computed(() => isBetRadarAvailable.value && event.value?.stream?.betRadar?.streamStatus === BetRadarStreamStatus.OnAirBroadcast);
    const isGLivePlayable = computed(() => !!(isGLiveAvailable.value && event.value?.stream?.gLive?.isPlaying));
    const isBetConstructPlayable = computed(() => isBetConstructAvailable.value);

    /** whether can open video player */
    const isLiveStreamPlayable = computed(() => {
        if (!event.value?.isLive) return false;
        if (!event.value?.stream) return false;

        return [
            isTwitchPlayable,
            isMuayThaiPlayable,
            isBetRadarPlayable,
            isGLivePlayable,
            isBetConstructPlayable,
        ].some(x => x.value);
    });

    return {
        isLiveStreamAvailable,
        isLiveStreamPlayable,

        isTwitchAvailable,
        isMuayThaiAvailable,
        isBetRadarAvailable,
        isGLiveAvailable,
        isBetConstructAvailable,

        isTwitchPlayable,
        isMuayThaiPlayable,
        isBetRadarPlayable,
        isGLivePlayable,
        isBetConstructPlayable,

        openLiveStream,
    };
}
