<template>
    <th
        class="vTableHeader"
        v-bind:style="[style, {
            textAlign: props.align,
        }]"
    >
        <slot />
    </th>
</template>

<script lang="ts" setup>
    import type { PropType } from 'vue';
    import { computed } from 'vue';
    import type { CSSPropertiesWithVars } from '@/core/lib/style';
    import type { IColors, IRootObject } from '@/interface/sassVariables';

    const props = defineProps({
        align: {
            type: String as PropType<'left' | 'center' | 'right'>,
            default: 'center',
        },
        bgColor: {
            type: String as PropType<keyof IColors | keyof IRootObject>,
            default: 'brandColor-800',
        },
        textColor: {
            type: String as PropType<keyof IColors | keyof IRootObject>,
            default: 'white',
        },
        borderColor: {
            type: String as PropType<keyof IColors | keyof IRootObject>,
            default: 'indigo-700',
        },
    });

    const style = computed(() => {
        const result: CSSPropertiesWithVars = {};

        result['--v-table-header-background-color'] = `var(--${props.bgColor})`;
        result['--v-table-header-text-color'] = `var(--${props.textColor})`;
        result['--v-table-header-border-color'] = `var(--${props.borderColor})`;

        return result;
    });
</script>

<style lang="scss" scoped>
    .vTableHeader {
        background-color: var(--v-table-header-background-color);
        color: var(--v-table-header-text-color);
    }

    .vTableRow:first-child .vTableHeader:not(:last-child)::before {
        background-color: var(--v-table-header-border-color);
    }
</style>
