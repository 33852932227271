import { i18n } from '@/core/lib/i18n';
import { EnumLanguage } from '@/interface/enum';

export function capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function invertCapitalize(str: string): string {
    return str.charAt(0).toLowerCase() + str.slice(1);
}

function isSupportLocales() {
    try {
        'foo'.localeCompare('bar', 'i');
    } catch (e) {
        return (e as Error).name === 'RangeError';
    }
    return false;
}

export function getLocalesSortingFn() {
    if (!isSupportLocales()) {
        return (a: string, b: string) => a.localeCompare(b);
    }

    const mapping = {
        [EnumLanguage.EN]: 'en',
        [EnumLanguage.TH_TH]: 'th-TH',
        [EnumLanguage.VI_VN]: 'vi-VN',
        [EnumLanguage.ZH_CN]: 'zh-Hans',
        [EnumLanguage.ID_ID]: 'id-ID',
        [EnumLanguage.JA_JP]: 'ja-JP',
        [EnumLanguage.KO_KR]: 'ko-KR',
        [EnumLanguage.MY_MM]: 'my-MM',
        [EnumLanguage.PT_BR]: 'pt-br',
        [EnumLanguage.KM_KH]: 'km-KH',
        [EnumLanguage.BN_BD]: 'bn-BD',
    };

    const locale = mapping[i18n.locale.value];
    return (a: string, b: string) => a.localeCompare(b, locale);
}
