import type { EnumMarketType, OddsTypeFragment } from '@/core/oddsApi/oddsApiType';
import { EnumHandicapType } from '@/core/oddsApi/oddsApiType';
import { OddsOption } from '@/interface/enum';

export function FakeOdds(
    marketType: EnumMarketType,
    prices: OddsTypeFragment['prices'],
    eventResult = FakeOddsEventResult(),
) {
    return {
        id: randomNum(1E7),
        isLive: false,
        point: 0.25 * randomInt(-9, 9),
        sport: 'Basketball',
        status: 'Running',
        betCondition: '',
        sortOrder: randomNum(9e4),
        marketType,
        prices,
        eventResult,
    };
}

export function FakeOddsEventResult(marketGroup = FakeMarketGroup()): NonNullable<OddsTypeFragment['eventResult']> {
    return {
        id: randomNum(1E7),
        eventId: randomNum(1E7),
        liveHandicapType: randomPick([
            EnumHandicapType.Home,
            EnumHandicapType.Away,
            EnumHandicapType.Unknown,
        ]),
        nonLiveHandicapType: randomPick([
            EnumHandicapType.Home,
            EnumHandicapType.Away,
            EnumHandicapType.Unknown,
        ]),
        marketGroup,
        liveHomeScore: randomNum(9),
        liveAwayScore: randomNum(9),
    };
}

export function FakeMarketGroup(
    id = 0,
    marketGroupName = '',
    {
        homeTemplate = '__{home}__',
        awayTemplate = '__{away}__',
        isSingle = false,
        isYesNo = false,
    } = {},
): NonNullable<OddsTypeFragment['eventResult']>['marketGroup'] {
    return {
        id,
        marketGroupName,
        homeTemplate,
        awayTemplate,
        homeExtension: '',
        awayExtension: '',
        displayPriority: 0,
        isSingle,
        isYesNo,
    };
}

export function Handicap(
    price1 = randomFloat(-1, 1),
    price2 = randomFloat(-1, 1),
): NonNullable<OddsTypeFragment['prices']> {
    return [
        { price: price1, option: OddsOption.Home },
        { price: price2, option: OddsOption.Away },
    ];
}

export const FH_Handicap = Handicap;

export function OddEven(
    price1 = randomFloat(-1, 1),
    price2 = randomFloat(-1, 1),
): NonNullable<OddsTypeFragment['prices']> {
    return [
        { price: price1, option: OddsOption.Odd },
        { price: price2, option: OddsOption.Even },
    ];
}

export const FH_OddEven = OddEven;

export function OverUnder(
    price1 = randomFloat(-1, 1),
    price2 = randomFloat(-1, 1),
): NonNullable<OddsTypeFragment['prices']> {
    return [
        { price: price1, option: OddsOption.Over },
        { price: price2, option: OddsOption.Under },
    ];
}

export const FH_OverUnder = OverUnder;

export function MoneyLine(
    price1 = randomFloat(-1, 1),
    price2 = randomFloat(-1, 1),
): NonNullable<OddsTypeFragment['prices']> {
    return [
        { price: price1, option: OddsOption.ML_1 },
        { price: price2, option: OddsOption.ML_2 },
    ];
}

export function TotalGoal(
    price1 = randomFloat(-1, 1),
    price2 = randomFloat(-1, 1),
    price3 = randomFloat(-1, 1),
    price4 = randomFloat(-1, 1),
): NonNullable<OddsTypeFragment['prices']> {
    return [
        { price: price1, option: OddsOption.TG_0_1 },
        { price: price2, option: OddsOption.TG_2_3 },
        { price: price3, option: OddsOption.TG_4_6 },
        { price: price4, option: OddsOption.TG_7_Up },
    ];
}

export function DoubleChance(
    price1 = randomFloat(-1, 1),
    price2 = randomFloat(-1, 1),
    price3 = randomFloat(-1, 1),
): NonNullable<OddsTypeFragment['prices']> {
    return [
        { price: price1, option: OddsOption.DC_12 },
        { price: price2, option: OddsOption.DC_1X },
        { price: price3, option: OddsOption.DC_2X },
    ];
}

export function _1X2(
    price1 = randomFloat(-1, 1),
    price2 = randomFloat(-1, 1),
    price3 = randomFloat(-1, 1),
): NonNullable<OddsTypeFragment['prices']> {
    return [
        { price: price1, option: OddsOption._1X2_1 },
        { price: price3, option: OddsOption._1X2_X },
        { price: price2, option: OddsOption._1X2_2 },
    ];
}

export const FH_1X2 = _1X2;

export function A1X2(
    price1 = randomFloat(-1, 1),
    price2 = randomFloat(-1, 1),
    price3 = randomFloat(-1, 1),
): NonNullable<OddsTypeFragment['prices']> {
    return [
        { price: price1, option: OddsOption.LS_1 },
        { price: price3, option: OddsOption.LS_X },
        { price: price2, option: OddsOption.LS_2 },
    ];
}

export const FH_A1X2 = A1X2;

export function randomNum(max: number) {
    return randomInt(0, max);
}

export function randomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function randomFloat(min: number, max: number): number {
    return parseFloat((Math.random() * (max - min) + min).toFixed(2));
}

export function randomPick<T>(arr: T[]): T {
    return arr[Math.floor(Math.random() * arr.length)];
}

export function randomHash() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}
