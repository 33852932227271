<template>
    <VTableRow class="originalRow" v-bind:bgColor="bgColor">
        <VTableCell>
            <strong>{{ index + 1 }}</strong>
        </VTableCell>

        <VTableCell>
            <ul>
                <li>{{ bet.transactionId }}</li>
                <li>{{ transDate }}</li>
                <li>{{ transTime }}</li>
            </ul>
        </VTableCell>
        <VTableCell align="left">
            <MixParlayRow
                v-if="bet.isMixParlay"
                v-bind:bet="bet"
            />
            <BetBuilderRow
                v-else-if="bet.isBetBuilder"
                v-bind:bet="bet"
            />
            <Selection
                v-else
                v-bind:bet="bet"
            />

            <div v-if="bet.isPartialCashOutBet && bet.originalTransId">
                <strong>{{ i18n.t('original_bet_id') }}</strong>: {{ bet.originalTransId }}
            </div>
            <CashOut
                v-if="isDisplayCashOut && bet.cashOutService && bet.cashOutService.isSupported"
                v-bind:bet="bet"
                v-bind:cashOutService="bet.cashOutService"
                width="w75"
                v-bind:isMiniMyBet="false"
            />
            <VButton
                v-if="partiallyCashedOutBets.length > 0 && !bet.isResettled"
                flat
                color="showMoreBtnColor"
                activeColor="showMoreBtnActiveColor"
                hoverColor="showMoreBtnHoverBgColor"
                class="historyBtn"
                v-bind:active="isShowPartialCashOutHistory"
                v-on:click="toggleShowPartialCashOutHistory"
            >
                <template #icon>
                    <SvgIcon
                        left
                        size="12"
                        v-bind:name="isShowPartialCashOutHistory ? 'icon-system-minus' : 'icon-system-plusMoreDetail'"
                    />
                </template>
                {{ i18n.t('more_details') }}
            </VButton>
        </VTableCell>

        <VTableCell>
            <strong>
                <div v-bind:class="isNegativePrice ? 'negative' : ''">
                    {{ priceDisplay }}
                </div>
                <div>{{ `(${priceStyleDisplay})` }}</div>
            </strong>
        </VTableCell>
        <VTableCell align="right">
            <strong>
                <div>
                    {{ stakeWithPrecision }}
                </div>
                <div v-if="bet.isVoucher">
                    {{ i18n.t('voucher') }}
                </div>
                <div v-else class="subItem">
                    {{ actualStakeWithPrecision }}
                </div>
                <div v-if="isRemainingStake">({{ i18n.t('remaining') }})</div>
            </strong>
        </VTableCell>

        <VTableCell
            v-if="bet.isSettled"
            align="right"
        >
            <strong>
                <div v-bind:class="bet.playerWinLoss < 0 ? 'negative' : ''">
                    {{ playerWinLossDisplay }}
                </div>
                <div class="subItem">
                    {{ playerCommissionDisplay }}
                </div>
            </strong>
        </VTableCell>

        <VTableCell>
            <BetStatusTag v-bind:status="bet.mainBetStatus" />
            <!-- TODO: receipt -->
            <a
                v-if="false"
                class="receipt"
                href="#"
            >
                {{ i18n.t('bet_receipt') }}
            </a>
        </VTableCell>
    </VTableRow>
    <VTableRow v-if="isShowPartialCashOutHistory" v-bind:bgColor="bgColor">
        <VTableCell colspan="7">
            <div class="innerTableHead" colspan="4">
                {{ i18n.t('partial_cash_out_history_title') }}
            </div>
            <PartialCashOutTable v-bind:bets="partiallyCashedOutBets" />
        </VTableCell>
    </VTableRow>
</template>

<script lang="ts" setup>
    import { computed, ref, toRefs } from 'vue';
    import BetBuilderRow from './BetBuilderRow.vue';
    import CashOut from '@/components/cashOut/CashOut.vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import VButton from '@/components/common/button/VButton.vue';
    import VTableCell from '@/components/common/table/VTableCell.vue';
    import VTableRow from '@/components/common/table/VTableRow.vue';
    import BetStatusTag from '@/components/myBets/BetStatusTag.vue';
    import MixParlayRow from '@/components/myBets/MixParlayRow.vue';
    import PartialCashOutTable from '@/components/myBets/PartialCashOutTable.vue';
    import Selection from '@/components/myBets/Selection.vue';
    import { useBetDisplay } from '@/components/myBets/useBetDisplay';
    import { useDisplayCashOut } from '@/components/myBets/useDisplayCashOut';
    import { formatToServerTime } from '@/core/lib/date';
    import { i18n } from '@/core/lib/i18n';
    import type { IBet } from '@/interface/IBet';
    import type { IColors, IRootObject } from '@/interface/sassVariables';
    import { useCustomerStore } from '@/store/customerStore';

    const props = defineProps<{
        bet: IBet;
        index: number;
        bgColor: keyof IColors | keyof IRootObject;
    }>();

    const { bet } = toRefs(props);
    const {
        priceDisplay,
        stakeWithPrecision,
        actualStakeWithPrecision,
        isNegativePrice,
        priceStyleDisplay,
    } = useBetDisplay(bet);

    const transDate = computed(() => formatToServerTime(bet.value.transactionDate, 'MM/dd/yyyy'));
    const transTime = computed(() => formatToServerTime(bet.value.transactionDate, 'HH:mm:ss'));

    const { formatMoneyWithDecimal } = useCustomerStore();
    const playerWinLossDisplay = computed(() => formatMoneyWithDecimal(bet.value.playerWinLoss));
    const playerCommissionDisplay = computed(() => formatMoneyWithDecimal(bet.value.playerCommission));
    const { isDisplayCashOut } = useDisplayCashOut();
    const isShowPartialCashOutHistory = ref(false);

    const toggleShowPartialCashOutHistory = () => {
        isShowPartialCashOutHistory.value = !isShowPartialCashOutHistory.value;
    };

    const partiallyCashedOutBets = computed(() => Array.from(bet.value.partiallyCashedOutBets?.values() ?? []));
    const isRemainingStake = computed(() => bet.value.originalStake && bet.value.originalStake > Math.abs(bet.value.stake));

</script>

<style lang="scss" scoped>
@import "@/components/myBets/MyBetRow";
</style>
