<template>
    <div class="matchDetailPreview">
        <div class="previewLoading">
            <StaticSpinner />
        </div>
        <slot />
        <div class="popupWindow hover_overlay" v-on:click="emit('click')">
            <SvgIcon
                name="icon-system-open-link"
                size="lg"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
    import StaticSpinner from '@/components/common/StaticSpinner.vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';

    const emit = defineEmits(['click']);
</script>

<style lang="scss" scoped>
@import "@/components/euroDisplay/MatchDetailPreview";
</style>
