<template>
    <div
        class="ticket notranslate"
        v-bind:class="{
            live: ticket.odds.isLive,
        }"
    >
        <div class="ticket_header">
            <div class="ticket_header_title">
                <span v-tooltip="i18n.t('wait_tooltip')" class="oddsDataStates">
                    <span class="oddsDataStates_light" />
                </span>
                <strong class="optionTitle">
                    <span> {{ title }} </span>
                </strong>
            </div>
            <span
                v-gtm:label="'CloseTicket'"
                v-gtm:@click
                class="ticket_header_cancel"
                v-on:click="cancelTicket(ticket.key)"
            >
                <SvgIcon name="icon-system-close" />
            </span>
        </div>
        <div class="ticket_option">
            <div class="optionContent">
                <div class="optionContent_inner">
                    <div class="optionTeam" v-bind:class="{ 'optionTeam-favorite': optionDisplay.isFavorite }">
                        {{ optionDisplay.name }}
                    </div>
                    <TicketOdds v-bind:ticket="ticket" />
                </div>
            </div>
        </div>
        <div class="ticket_detail">
            <div
                v-if="correctScoreExcludingScores"
                class="detailText"
                style="color: var(--primary);"
            >
                <span>
                    {{ i18n.t('odds_option_cs_excluding') }}
                    {{ correctScoreExcludingScores }}
                </span>
            </div>
            <div
                v-if="!isOutright"
                class="detailText"
                style="color: var(--primary);"
            >
                <span>
                    {{ ticket.event.homeTeam.name }}
                    -{{ i18n.t('vs') }}-
                    {{ ticket.event.awayTeam.name }}
                </span>
            </div>
            <div class="detailText" style="color: var(--primary);">
                <span>{{ ticket.event.league.name }}</span>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { computed, toRefs } from 'vue';
    import { TicketContext } from '@/components/betSlip/TicketContext';
    import TicketOdds from '@/components/betSlip/TicketOdds.vue';
    import { BetSlipContext } from '@/components/betSlip/betSlipContext';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import { i18n } from '@/core/lib/i18n';
    import { isMainMarket } from '@/core/lib/oddsHelper';
    import type { ITicket } from '@/interface/IBetSlip';
    import { MarketType } from '@/interface/enum';

    const props = defineProps<{
        ticket: ITicket;
    }>();
    const { ticket } = toRefs(props);

    const { optionDisplay, correctScoreExcludingScores } = TicketContext.provide(ticket);
    const { cancelTicket } = BetSlipContext.inject();

    const getSportName = (key: number) => i18n.t(`sport_type_name_${key}`);
    const getMarketName = (key: number) => i18n.t(`market_type_name_${key}`);

    const title = computed(() => {
        const sportName = getSportName(ticket.value.event.sportType);
        const marketName = getMarketName(ticket.value.odds.marketType);
        const marketGroupName = ticket.value.odds.eventResult.marketGroup.name;
        return isMainMarket(ticket.value.odds.eventResult.marketGroup.id)
            ? `${sportName} - ${marketName}`
            : `${sportName} - ${marketGroupName} - ${marketName}`;
    });

    const isOutright = computed(() => ticket.value.odds.marketType === MarketType.OutRight);
</script>

<style lang="scss" scoped>
    @import "@/components/betSlip/Ticket";
</style>
