<template>
    <div class="betSlipContainer">
        <BetSlipBlock />
    </div>

    <div
        v-if="isMiniGameWidgetEnabled"
        id="mini-game-entry"
        v-bind:data-site-style="SiteStyle[siteStyle]"
    />
</template>

<script lang="ts" setup>
    import { loadScript } from '@sports-utils/dom';
    import { storeToRefs } from 'pinia';
    import BetSlipBlock from '@/components/betSlip/BetSlipBlock.Euro.vue';
    import { getMiniGameUrl } from '@/core/lib/url';
    import { SiteStyle } from '@/interface/enum';
    import { useCustomerStore } from '@/store/customerStore';
    import { useToggleStore } from '@/store/toggleStore';

    const { siteStyle } = storeToRefs(useCustomerStore());

    const { isMiniGameWidgetEnabled } = storeToRefs(useToggleStore());

    if (isMiniGameWidgetEnabled.value) {
        loadScript(getMiniGameUrl());
    }
</script>

<style lang="scss" scoped>
.betSlipContainer {
    :deep(.tabs_header) {
        height: auto;

        .tab_header_item:only-child {
            padding: var(--spacers-lg);
            border-radius: var(--borderRadius-md) var(--borderRadius-md) 0 0;
        }
    }

    :deep(.tabs_content:last-child) {
        border-radius: 0 0 var(--borderRadius-md) var(--borderRadius-md);
    }
}
</style>
