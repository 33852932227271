<template>
    <li
        class="subTabs_item"
        v-bind:class="{
            'subTabs_item-active': isActive,
            'subTabs_item-indicator': showIndicator,
        }"
        v-on:click="selectTab(name)"
    >
        <Count v-if="typeof count === 'number'" class="subTabs_item_count">
            <AnimatedText v-bind:content="count" />
        </Count>
        <div class="subTabs_item_label">
            {{ label }}
        </div>
    </li>
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import { computed, onMounted, onUnmounted } from 'vue';
    import AnimatedText from '@/components/common/AnimatedText.vue';
    import Count from '@/components/common/Count.vue';
    import { SubTabsContext } from '@/components/subTabs/SubTabsContext';
    import { SiteStyle } from '@/interface/enum';
    import { useCustomerStore } from '@/store/customerStore';

    const { siteStyle } = storeToRefs(useCustomerStore());
    if (siteStyle.value === SiteStyle.Euro) {
        import('@/components/subTabs/SubTab.Euro.scss');
    } else {
        import('@/components/subTabs/SubTab.scss');
    }

    const props = defineProps<{
        name: string;
        label: string;
        count?: number;
        showIndicator?: boolean;
    }>();

    const { currentTab, registerTab, unregisterTab, selectTab } = SubTabsContext.inject();
    const isActive = computed(() => props.name === currentTab.value);

    onMounted(() => {
        registerTab(props.name);
    });

    onUnmounted(() => {
        unregisterTab(props.name);
    });
</script>
