<template>
    <CheckBox
        id="acceptAnyCashOutValue"
        v-bind:checked="isAcceptAnyCashOutValue"
        v-bind:onclick="updateIsAcceptAnyCashOutValue"
        v-bind:gtmLabel="isAcceptAnyCashOutValue ? 'AcceptAnyCashOut_Off' : 'AcceptAnyCashOut_On'"
    >
        <template #label>
            <span class="labelText">
                {{ i18n.t('accept_any_cash_out_value') }}
            </span>
        </template>
    </CheckBox>
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import CheckBox from '@/components/common/CheckBox.vue';
    import { i18n } from '@/core/lib/i18n';
    import { useCustomerStore } from '@/store/customerStore';

    const { isAcceptAnyCashOutValue } = storeToRefs(useCustomerStore());
    const { updateIsAcceptAnyCashOutValue } = useCustomerStore();
</script>
