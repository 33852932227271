import { isNumber, isString } from '@/core/lib/is';
import { SboCurrency } from '@/interface/enum';

/**
 * @param   value a Number or a String to be transformed
 * @param   decimalPlaces how many decimals you would like to place
 * @returns a string rounded by given decimal
 */
export function toFixedDecimalPoint(value: number | string = '', decimalPlaces = 0): string {
    if (value === '') return '';

    let valFloat = parseFloat(value.toString().replace(/,/g, ''));
    valFloat = Number.isNaN(valFloat) ? 0 : valFloat;

    return valFloat.toFixed(decimalPlaces);
}

// format 1234567.89 to 1,234,567.89
function formatNumber(numberStr: string, thousandSeparator: string, decimalSeparator: string): string {
    const [integer, decimal] = numberStr.split('.');
    const newInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
    return [newInteger, decimal].filter(x => x).join(decimalSeparator);
}

/** currencies that uses #.###,## format */
export const europeStyleCurrency = [
    SboCurrency.PP,
    SboCurrency.VND,
    SboCurrency.BRL,
];

/**
 * @param   value a Number or a String to be transformed
 * @param   currency currency type got from customerInfo
 * @returns a formatted number string with comma or dot
 */
export function toFormatWithCommas(value: string | number, currency: SboCurrency): string {
    if (!isString(value) && !isNumber(value)) return '';

    value = value.toString();

    const isEuroStyle = europeStyleCurrency.includes(currency);

    if (isEuroStyle) {
        const thousandSeparator = '.';
        const decimalSeparator = ',';
        return formatNumber(value, thousandSeparator, decimalSeparator);
    }

    const thousandSeparator = ',';
    const decimalSeparator = '.';
    return formatNumber(value, thousandSeparator, decimalSeparator);
}

/**
 * @param   value a Number to be transformed
 * @param   decimalPlace how many decimals you would like to place
 * @returns a string with decimal or not
 */
export function formatMinDecimalPlace(value: number, decimalPlace = 0): string {
    if (value === null || value === undefined) {
        // eslint-disable-next-line no-console
        console.error('invalid value:', value);
        return '';
    }

    const split = String(value).split('.');
    const currentDecimalPlaceLength = split[1] ? split[1].length : 0;

    return value.toFixed(
        Math.max(currentDecimalPlaceLength, decimalPlace),
    );
}
