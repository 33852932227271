<template>
    <div class="navbar">
        <div class="navbar_row">
            <NavbarRowItem v-if="!!$slots.navbarTitle">
                <div class="navbarTitle">
                    <div class="navbarTitle_icon">
                        <slot name="navbarTitleIcon" />
                    </div>
                    <slot name="navbarTitle" />
                </div>
                <slot name="navbarDivider" />
            </NavbarRowItem>
            <slot name="topRow" />
        </div>
        <div v-if="isShowBottomRow" class="navbar_row">
            <slot name="bottomRow" />
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import NavbarRowItem from '@/components/common/navbar/NavbarRowItem.vue';
    import { SiteStyle } from '@/interface/enum';
    import { useCustomerStore } from '@/store/customerStore';

    const { siteStyle } = storeToRefs(useCustomerStore());
    if (siteStyle.value === SiteStyle.Euro) {
        import('@/components/common/navbar/Navbar.Euro.scss');
    } else {
        import('@/components/common/navbar/Navbar.scss');
    }

    defineProps({
        isShowBottomRow: {
            type: Boolean,
            default: true,
        },
    });
</script>
