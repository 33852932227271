import type { Ref } from 'vue';
import { computed, onUnmounted, ref } from 'vue';
import { i18n } from '@/core/lib/i18n';
import { deepFreeze } from '@/core/lib/utils';
import { formatOutrightOdds } from '@/core/oddsApi/helpers/formatOutrightOdds';
import { useOutrightsSubscription } from '@/core/oddsApi/oddsApiType';
import type { IOutrightOdds } from '@/interface/IOutrightOdds';
import { EnumLanguage, SportType } from '@/interface/enum';

export function useOutrightInfoSubscriptionCallback({
    sportType,
    leagueId,
    oddsId,
    isLive,
    isActive = ref(true),
}: {
    sportType: SportType;
    leagueId: number;
    oddsId: number;
    isLive: boolean;
    isActive?: Ref<boolean>;
}) {
    const variables = computed(() => ({
        query: {
            sport: SportType.toOddsApi(sportType),
            tournamentId: leagueId,
            isLive,
            lang: EnumLanguage.toOddsApi(i18n.locale.value),
        },
    }));

    const { onSubscription } = useOutrightsSubscription(
        variables,
        computed(() => ({
            enabled: isActive.value,
        })),
    );

    type UpdatedFn = (outrightOdds: IOutrightOdds) => void;
    let updatedFn: UpdatedFn[] = [];
    const onUpdated = (callback: UpdatedFn) => {
        updatedFn.push(callback);
        onUnmounted(() => {
            updatedFn = updatedFn.filter(fn => fn !== callback);
        });
    };

    type DeletedFn = (oddsId: number) => void;
    let deletedFn: DeletedFn[] = [];
    const onDeleted = (callback: DeletedFn) => {
        deletedFn.push(callback);
        onUnmounted(() => {
            deletedFn = deletedFn.filter(fn => fn !== callback);
        });
    };

    onSubscription((subscription) => {
        subscription?.updated.forEach((toUpdate) => {
            if (toUpdate.id === oddsId) {
                const updatedOdds = deepFreeze(formatOutrightOdds(toUpdate));
                updatedFn.forEach(fn => fn(updatedOdds));
            }
        });

        subscription?.deleted.forEach((toDeleteId) => {
            if (toDeleteId === oddsId) {
                deletedFn.forEach(fn => fn(toDeleteId));
            }
        });
    });

    return {
        onUpdated,
        onDeleted,
    };
}
