<template>
    <div
        class="oddsDisplayHeader notranslate"
        v-bind:class="{
            live: isLive,
            sticky: isSticky,
        }"
    >
        <template v-for="column in columns" v-bind:key="column.key">
            <div class="oddsDisplayHeader_col" v-bind:class="{ hasChildren: column.type === ColumnType.Block && column.children }">
                <template v-if="column.type === ColumnType.Block && column.children">
                    <div class="oddsDisplayHeader_col_cell" v-bind:style="{ width: `${column.width}px` }">
                        {{ column.description.label }}
                    </div>
                    <div class="oddsDisplayHeader_col_row">
                        <template
                            v-for="childCol in column.children"
                            v-bind:key="childCol.key"
                        >
                            <div
                                v-tooltip="childCol.description.tooltip"
                                class="oddsDisplayHeader_col_cell"
                                v-bind:class="{ 'cursor-help': !!childCol.description.tooltip }"
                                v-bind:style="{ width: `${childCol.width}px` }"
                            >
                                {{ childCol.description.label }}
                            </div>
                            <template v-if="isSingleLine">
                                <div
                                    v-tooltip="getOptionTooltip(childCol.key, TeamOption.Home)"
                                    class="oddsDisplayHeader_col_cell"
                                    v-bind:type="childCol.key"
                                    v-bind:class="{ 'cursor-help': !!getOptionTooltip(childCol.key, TeamOption.Home) }"
                                    v-bind:style="{ width: `${childCol.width}px` }"
                                >
                                    {{ getSingleLineHome(childCol.key) }}
                                </div>
                                <div
                                    v-tooltip="getOptionTooltip(childCol.key, TeamOption.Away)"
                                    class="oddsDisplayHeader_col_cell"
                                    v-bind:type="childCol.key"
                                    v-bind:class="{ 'cursor-help': !!getOptionTooltip(childCol.key, TeamOption.Away) }"
                                    v-bind:style="{ width: `${childCol.width}px` }"
                                >
                                    {{ getSingleLineAway(childCol.key) }}
                                </div>
                            </template>
                        </template>
                    </div>
                </template>
                <template v-else>
                    <div
                        class="oddsDisplayHeader_col_cell"
                        v-bind:type="column.key"
                        v-bind:style="{ width: `${column.width}px` }"
                    >
                        <template v-if="column.key === OddsDisplayBlockType.Time">
                            <VButton
                                v-gtm:label="collapseGtmLabel"
                                v-gtm:@click
                                flat
                                block
                                v-on:click="toggleCollapse"
                            >
                                <SvgIcon
                                    name="icon-system-arrow"
                                    v-bind:rotated="isCollapsedAll"
                                    v-bind:class="{ disabled: !hasEvent }"
                                />
                            </VButton>
                        </template>
                        <template v-else-if="column.key === OddsDisplayBlockType.Team">
                            {{ headerTitle }}
                        </template>
                        <template v-else>
                            <span v-tooltip="column.description.tooltip">
                                {{ column.description.label }}
                            </span>
                        </template>
                    </div>
                    <template v-if="isSingleLine && column.type === ColumnType.Market">
                        <div
                            class="oddsDisplayHeader_col_cell"
                            v-bind:type="column.key"
                            v-bind:style="{ width: `${column.width}px` }"
                        >
                            {{ getSingleLineHome(column.key) }}
                        </div>
                        <div
                            class="oddsDisplayHeader_col_cell"
                            v-bind:type="column.key"
                            v-bind:style="{ width: `${column.width}px` }"
                        >
                            {{ getSingleLineAway(column.key) }}
                        </div>
                    </template>
                </template>
            </div>
        </template>
    </div>
</template>

<script lang="ts" setup>
    import { computed } from 'vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import VButton from '@/components/common/button/VButton.vue';
    import { OddsDisplayContext } from '@/components/oddsDisplay/OddsDisplayContext';
    import { AwayOptionTooltipMapping, HomeOptionTooltipMapping, getBlockColumnDescription, getMarketColumnDescription } from '@/components/oddsDisplay/marketTypeDisplayRule';
    import type { IColumnDescription } from '@/components/oddsDisplay/marketTypeDisplayRule';
    import { getOddsTableGroupedColumns } from '@/components/oddsDisplay/oddsTableCalculation';
    import type { BlockColumn, MarketColumn } from '@/components/oddsDisplay/oddsTableCalculation';
    import { i18n } from '@/core/lib/i18n';
    import { isMobile } from '@/core/lib/utils';
    import { ColumnType, MarketPage, MarketType, OddsDisplayBlockType, OddsDisplayMode, TeamOption } from '@/interface/enum';

    const props = defineProps<{
        isLive: boolean;
        isSticky: boolean;
        hasEvent: boolean;
    }>();

    interface IDisplayMarketColumn extends MarketColumn {
        description: IColumnDescription;
    }

    interface IDisplayBlockColumn extends BlockColumn {
        children?: IDisplayMarketColumn[];
        description: IColumnDescription;
    }

    type IDisplayColumn = IDisplayMarketColumn | IDisplayBlockColumn

    const { sportType, marketPage, oddsDisplayMode, isCollapsedAll, setCollapsedItems, collapseIds } = OddsDisplayContext.inject();
    const isSingleLine = oddsDisplayMode === OddsDisplayMode.Single;
    const columns = computed(() => {
        const oddsTableGroupedColumns = getOddsTableGroupedColumns(sportType, props.isLive, oddsDisplayMode);
        return oddsTableGroupedColumns.map<IDisplayColumn>((column) => {
            if (column.type === ColumnType.Market) {
                return {
                    ...column,
                    description: getMarketColumnDescription(sportType, column.key, column.marketGroup, isSingleLine),
                };
            }
            return {
                ...column,
                children: column.children?.map<IDisplayMarketColumn>(child => ({
                    ...child,
                    description: getMarketColumnDescription(sportType, child.key, child.marketGroup, isSingleLine),
                })),
                description: getBlockColumnDescription(sportType, column.key),
            };
        });
    });

    const marketPageMapping: Partial<Record<MarketPage, string>> = {
        [MarketPage.Live]: 'Live',
        [MarketPage.Today]: 'Today',
        [MarketPage.EarlyMarket]: 'Early',
        [MarketPage.MixParlay]: 'MixParlay',
        [MarketPage.MyFavorites]: 'MyFav',
    };
    const collapseGtmLabel = computed(() => {
        const prefix = `${marketPageMapping[marketPage]}_${props.isLive ? 'Live' : 'NonLive'}`;
        return (isCollapsedAll.value ? `${prefix}Expd` : `${prefix}Colps`);
    });

    const headerTitle = computed(() => {
        if (marketPage === MarketPage.MixParlay) {
            return props.isLive ? i18n.t('live_mix_parlay') : i18n.t('non_live_mix_parlay');
        }
        return props.isLive ? i18n.t('live') : i18n.t('non_live');
    });

    function toggleCollapse() {
        if (!props.hasEvent) return;

        if (isCollapsedAll.value) {
            setCollapsedItems(id => collapseIds.value.indexOf(id) > 10);
            setTimeout(() => {
                isCollapsedAll.value = false;
            }, 150);
        } else {
            isCollapsedAll.value = true;
        }
    }

    function getOptionTooltip(marketType: MarketType, teamOption: TeamOption) {
        if (isMobile()) return '';

        const key = teamOption === TeamOption.Home ? HomeOptionTooltipMapping[marketType] : AwayOptionTooltipMapping[marketType];
        return key ? i18n.t(key) : '';
    }

    function getSingleLineHome(marketType: MarketType) {
        switch (marketType) {
            case MarketType.Handicap:
            case MarketType.FH_Handicap:
            case MarketType.MoneyLine:
                return i18n.t('odds_option_home');
            case MarketType.OverUnder:
            case MarketType.FH_OverUnder:
                return i18n.t('odds_option_over');
            default:
                return '';
        }
    }

    function getSingleLineAway(marketType: MarketType) {
        switch (marketType) {
            case MarketType.Handicap:
            case MarketType.FH_Handicap:
            case MarketType.MoneyLine:
                return i18n.t('odds_option_away');
            case MarketType.OverUnder:
            case MarketType.FH_OverUnder:
                return i18n.t('odds_option_under');
            default:
                return '';
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/components/oddsDisplay/OddsDisplayHeader";
</style>
