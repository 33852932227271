<template>
    <div
        class="groupTitle"
        v-bind:class="{
            noDetail: !viewAll,
        }"
        v-on:click="emit('update:isExpanded', !isExpanded)"
    >
        <div
            v-gtm:@click
            v-gtm:disabled="!expandedGtmLabel"
            v-gtm:label="expandedGtmLabel"
            class="toggleCollapse"
        >
            <div class="front">
                <div class="arrow">
                    <SvgIcon
                        name="icon-system-arrow"
                        v-bind:rotated="!isExpanded"
                    />
                </div>
                <div class="name">
                    <slot name="title" />
                </div>
            </div>
            <slot v-if="isExpanded" name="content" />
        </div>
        <RouterLink
            v-if="viewAll"
            v-gtm:@click
            v-gtm:label="viewAllGtmLabel"
            v-bind:to="viewAll"
            class="detail"
        >
            {{ i18n.t('mini_my_bets_view_all') }} <SvgIcon name="icon-system-arrow" v-bind:size="10" />
        </RouterLink>
    </div>
</template>

<script lang="ts" setup>
    import type { RouteLocationNamedRaw } from 'vue-router';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import { i18n } from '@/core/lib/i18n';

    withDefaults(defineProps<{
        isExpanded: boolean;
        viewAll?: RouteLocationNamedRaw;
        expandedGtmLabel: string | null;
        viewAllGtmLabel?: string;
    }>(), {
        viewAllGtmLabel: 'ViewAll',
        viewAll: undefined,
    });

    const emit = defineEmits([
        'update:isExpanded',
    ]);
</script>

<style lang="scss" scoped>
@import "@/components/common/groupTitle/GroupTitle";
</style>
