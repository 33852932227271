<template>
    <dd v-debug:eventId="stream.eventId">
        <div class="schedule_event_time">
            <template v-if="stream.isLive">
                <template v-if="displayScore">
                    <span v-if="displayScore.setDisplayText" class="schedule_event_period">{{ displayScore.setDisplayText }}</span>
                    <span v-if="displayScore.score">{{ displayScore.score.home }} : {{ displayScore.score.away }}</span>
                    <span v-if="displayScore.periodText" class="schedule_event_period">{{ displayScore.periodText }}</span>
                </template>
                <template v-else>
                    <span class="schedule_event_period">{{ liveDisplayText }}</span>
                </template>
                <span
                    v-if="isShowInjuryTime(stream)"
                    v-tooltip="i18n.t('injury_time')"
                    class="schedule_event_injuryTime"
                >
                    +{{ stream.extraInfo.injuryTime }}'
                </span>
            </template>
            <template v-else>
                <span>{{ useShowTimeDisplay(stream.showTime, stream.showTimeType).date }}</span>
                <span class="schedule_event_showTime">{{ useShowTimeDisplay(stream.showTime, stream.showTimeType).time }}</span>
            </template>
        </div>
        <div class="schedule_event_info">
            <div>
                {{ `${i18n.t('sport_type_name_' + stream.sportType)} - ${stream.league.name}` }}
            </div>
            <div class="schedule_event_team">
                <span v-bind:class="stream.homeTeam.isFavorite ? 'favorite' : 'underdog'">
                    {{ stream.homeTeam.name }}
                </span>
                <FoulCard v-bind:count="stream.homeTeam.redCard" />
                <span class="vs">{{ i18n.t('vs') }}</span>
                <span v-bind:class="stream.awayTeam.isFavorite ? 'favorite' : 'underdog'">
                    {{ stream.awayTeam.name }}
                </span>
                <FoulCard v-bind:count="stream.awayTeam.redCard" />
            </div>
        </div>
    </dd>
</template>

<script setup lang="ts">
    import { storeToRefs } from 'pinia';
    import { type PropType, computed, toRefs } from 'vue';
    import FoulCard from '@/components/common/foulCard/FoulCard.vue';
    import { isShowInjuryTime } from '@/components/oddsDisplay/matchDisplay/useMatchDisplay';
    import { useLiveScoreDisplay } from '@/composable/useLiveScoreDisplay';
    import { useLiveScoreRestriction } from '@/composable/useLiveScoreRestriction';
    import { useShowTimeDisplay } from '@/composable/useShowTimeDisplay';
    import { i18n } from '@/core/lib/i18n';
    import { useLiveScore } from '@/core/oddsApi/composable/useLiveScore';
    import type { Score } from '@/interface/ILiveScore';
    import type { IStreamEvent } from '@/interface/IStream';
    import { SiteStyle } from '@/interface/enum';
    import { useCustomerStore } from '@/store/customerStore';

    const props = defineProps({
        stream: {
            type: Object as PropType<IStreamEvent>,
            required: true,
        },
    });
    const { stream } = toRefs(props);

    const { isSupported: isEnableLiveScore } = useLiveScoreRestriction(stream);
    const { liveScore: providerLiveScore } = useLiveScore(stream.value.eventId, isEnableLiveScore);
    const sboLiveScore = computed<Score>(() => ({ home: props.stream.homeTeam.liveScore, away: props.stream.awayTeam.liveScore }));
    const { displayScore } = useLiveScoreDisplay(stream, providerLiveScore, sboLiveScore, SiteStyle.Asia);

    const { isAsiaSite } = storeToRefs(useCustomerStore());

    const liveDisplayText = computed(() => (isAsiaSite.value ? i18n.t('live').toUpperCase() : i18n.t('live')));
</script>

<style lang="scss" scoped>
    @import "@/components/liveStream/LiveStream";
</style>
