<template>
    <VTable bordered>
        <VTableRow>
            <VTableHeader
                v-for="column in allColumns"
                v-bind:key="column.name"
                bgColor="white"
                textColor="brandColor-800"
                borderColor="indigo-200"
            >
                <div v-if="column.tooltips" class="header">
                    {{ column.name }}
                    <VTooltip v-if="column.tooltips" direction="top">
                        <SvgIcon
                            class="header_icon"
                            name="icon-system-cycle-info"
                        />
                        <template #content>
                            <div v-for="(tooltip, index) in column.tooltips" v-bind:key="index">
                                {{ tooltip }}
                            </div>
                        </template>
                    </VTooltip>
                </div>
                <template v-else>
                    {{ column.name }}
                </template>
            </VTableHeader>
        </VTableRow>
        <PartialCashOutRow
            v-for="(partialBets, index) in bets"
            v-bind:key="partialBets[0].transId"
            v-bind:bets="partialBets"
            v-bind:index="index"
        />
    </VTable>
</template>

<script lang="ts" setup>
    import type { PropType } from 'vue';
    import { computed } from 'vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import VTable from '@/components/common/table/VTable.vue';
    import VTableHeader from '@/components/common/table/VTableHeader.vue';
    import VTableRow from '@/components/common/table/VTableRow.vue';
    import PartialCashOutRow from '@/components/myBets/PartialCashOutRow.vue';
    import { i18n } from '@/core/lib/i18n';
    import { VTooltip } from '@/directives/tooltip';
    import type { IPartialCashOutBet } from '@/interface/IBet';
    import { PartialCashOutColumn } from '@/interface/enum';

    defineProps({
        bets: {
            type: Object as PropType<IPartialCashOutBet[][]>,
            required: true,
        },
    });

    const allColumns = computed(() => [
        {
            key: PartialCashOutColumn.Id,
            name: i18n.t('my_bets_header_id'),
        },
        {
            key: PartialCashOutColumn.Time,
            name: i18n.t('my_bets_header_time'),
        },
        {
            key: PartialCashOutColumn.Stake,
            name: i18n.t('my_bets_header_stake'),
            tooltips: [i18n.t('stake_tooltip_1'), i18n.t('stake_tooltip_2')],
        },
        {
            key: PartialCashOutColumn.WinLoss,
            name: i18n.t('my_bets_header_win_loss'),
            tooltips: [i18n.t('win_loss_tooltip_1'), i18n.t('win_loss_tooltip_2')],
        },
    ]);

</script>

<style lang="scss" scoped>
.header {
    display: flex;
    align-items: center;
    justify-content: center;

    .header_icon {
        margin-left: map-get($spacers, sm);
    }
}
</style>
