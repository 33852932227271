import type { AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios';
import axios from 'axios';
import throttle from 'lodash/throttle';
import { ref } from 'vue';
import { formatBet } from '@/components/myBets/betHelper';
import { formatBetBuilderResults, formatOutrightResults, formatResults } from '@/components/results/formatResult';
import { parseISOString } from '@/core/lib/date';
import { getPolluxUrl } from '@/core/lib/url';
import { formatBetBuilderOrder, formatBetBuilderTicket } from '@/core/oddsApi/helpers/formatBetBuilder';
import type { IMyBets } from '@/interface/IBet';
import type { IBetSlip, IBetSlipEuro, ITicket, ITicketEuro } from '@/interface/IBetSlip';
import type { ICashOutData } from '@/interface/ICashOut';
import type { IToggleResponse } from '@/interface/IToggle';
import type { IVoucher } from '@/interface/IVoucher';
import type { IBetBuilderBetSlip } from '@/interface/betBuilder';
import {
    AsiaBetPageType,
    BannerPosition,
    BrandType,
    CashOutResult,
    DisplayTime,
    ExchangeStatus,
    MarketType,
    OddsDisplayMode,
    OddsSorting,
    PriceStyle,
    SiteStyle,
    SportType,
} from '@/interface/enum';
import { JackpotPoolType, TriggerResult } from '@/interface/jackpot';
import type {
    BannerInfo,
    BannerRequest,
    Bet,
    BetBuilderOrderRequest,
    BetBuilderOrderResponse,
    BetBuilderResultResponse,
    BetBuilderTicketRequest,
    BetBuilderTicketResponse,
    BettingPageResponse,
    CashOutRequest,
    CashOutResponse,
    CashOutStatus,
    CashOutValueResponse,
    CashOutValuesRequest,
    ClaimJackpotRequest,
    ConfigurationSettingsParams,
    CustomerInfo,
    ExchangeResponseOfBettingPageResponse,
    ExchangeResponseOfCustomerInfoResponse,
    ExchangeResponseOfObject,
    LiveStreamGetLiveStreamUrlParams,
    LiveStreamResource,
    LiveStreamResponse,
    LocationResponse,
    MatchResponse,
    MatchResultGetBetBuilderResultParams,
    MatchResultGetMatchResultsParams,
    MatchResultGetOutrightTournamentResultsParams,
    MaxPayoutResponse,
    MixParlaySubBet,
    MyBetListMiniMyBetsParams,
    MyBetListMyBetListParams,
    MyBetListSettledBetListParams,
    MyBetsResponse,
    OpenTicketStatus,
    OrderResponse,
    OutrightTicketInfo,
    PlaceComboParlayOrderRequest,
    PlaceOutrightOrderRequest,
    PlaceSingleOrderRequest,
    TriggerResult as PolluxTriggerResult,
    ProductSwitchToClassicDesktopResponse,
    RemoveSingleTicket,
    SelectedLeagueInfoRequest,
    SelectedLeagueInfoResponse,
    SendBehaviorsRequest,
    SettingRequest,
    TicketInfo,
    TicketMpResponse,
    TicketResponse,
    TournamentResponse,
    TransferRequest,
    Voucher,
    VoucherGetAllParams,
} from '@/interface/pollux';
import { EnumProject, PolluxPlaceOrderStatus } from '@/interface/pollux';

declare interface IOpenTicketDebug {
    ticket: string;
    duration: number;
    requestTime: Date;
}

export const isWaitingForOpenTicket = ref(false);

export const lastOpenTickets = ref<IOpenTicketDebug[]>([]);

export const mockOpenTicketStatus = ref<OpenTicketStatus | null>(null);

export const mockPlaceBetStatus = ref<PolluxPlaceOrderStatus | null>(null);

export const mockPlaceBetExtraInfo = ref<number | null>(null);

export const mockCashOutResult = ref<CashOutStatus | null>(null);

export const mockCashOutValueIsSupported = ref<boolean | null>(null);

export function createInstance(baseUrl: string) {
    return axios.create({
        baseURL: baseUrl,
        withCredentials: true,
    });
}

export const apiInstance = createInstance(`${getPolluxUrl()}/api`);

const goToLoginPage = throttle(() => {
    window.location.href = `${getPolluxUrl()}/api/signin-sbo`;
}, 5000, { trailing: false });

apiInstance.interceptors.response.use(
    (response) => {
        const isUM = response.headers['um-enabled'] === 'y';
        if (isUM) {
            window.location.reload();
        }
        return response;
    },
    (error) => {
        const { response } = error;
        if (response) {
            if (response.status === 401) {
                return goToLoginPage();
            }
        }

        return Promise.reject(error);
    },
);

function convertToTicketInfo(ticket: ITicket | ITicketEuro): TicketInfo {
    return {
        eventId: ticket.event.id,
        oddsId: ticket.odds.id,
        marketType: MarketType.toPollux(ticket.odds.marketType),
        option: ticket.priceOption.option,
        sportType: SportType.toPollux(ticket.odds.sportType),
        isLive: ticket.odds.isLive,
    };
}

function convertToOutrightTicketInfo(ticket: ITicket | ITicketEuro): OutrightTicketInfo {
    return {
        leagueId: ticket.event.league.id,
        eventId: ticket.event.id,
        oddsId: ticket.odds.id,
        marketType: MarketType.toPollux(ticket.odds.marketType),
        option: ticket.priceOption.option,
        sportType: SportType.toPollux(ticket.odds.sportType),
        isLive: ticket.odds.isLive,
    };
}

function convertToAsiaSubBetRequest(ticket: ITicket): MixParlaySubBet {
    const eventResult = ticket.event.eventResults.find(x => x.id === ticket.odds.eventResult.id);
    return {
        eventId: ticket.event.id,
        oddsId: ticket.odds.id,
        marketType: MarketType.toPollux(ticket.odds.marketType),
        option: ticket.priceOption.option,
        leagueId: ticket.event.league.id,
        liveScore: {
            home: eventResult?.homeTeam.liveScore ?? 0,
            away: eventResult?.awayTeam.liveScore ?? 0,
        },
        point: ticket.odds.point,
    };
}

function convertToEuroSubBetRequest(ticket: ITicketEuro): MixParlaySubBet {
    return {
        eventId: ticket.event.id,
        oddsId: ticket.odds.id,
        marketType: MarketType.toPollux(ticket.odds.marketType),
        option: ticket.priceOption.option,
        leagueId: ticket.event.league.id,
        liveScore: {
            home: ticket.liveHomeScore ?? 0,
            away: ticket.liveAwayScore ?? 0,
        },
        point: ticket.odds.point,
    };
}

function handleMockOpenTicketStatus<T extends Pick<TicketResponse, 'errorCode' | 'oddsInfo'>>(response: AxiosResponse<T>) {
    if (mockOpenTicketStatus.value !== null) {
        const mock = mockOpenTicketStatus.value;
        response.data.errorCode = mock;

        if (response.data.oddsInfo) {
            Object.values(response.data.oddsInfo).forEach((oddsInfo) => {
                oddsInfo.errorCode = mock;
            });
        }
    }
    return response;
}

function handleMockPlaceBetStatus<T extends Pick<OrderResponse, 'isPlaceBetSuccess' | 'placeOrderStatus' | 'extraInfo'>>(response: AxiosResponse<T>) {
    if (mockPlaceBetStatus.value) {
        response.data.isPlaceBetSuccess = mockPlaceBetStatus.value === PolluxPlaceOrderStatus.Success;
        response.data.placeOrderStatus = mockPlaceBetStatus.value;
        response.data.extraInfo = mockPlaceBetExtraInfo.value;
    }
    return response;
}

function handleMockCashOutStatus(response: AxiosResponse<CashOutResponse>) {
    if (mockCashOutResult.value !== null) {
        response.data.status = mockCashOutResult.value;
    }
    return response;
}

function handleMockCashOutIsSupported(response: AxiosResponse<CashOutValueResponse[]>) {
    const mockIsSupported = mockCashOutValueIsSupported.value;
    if (mockIsSupported !== null) {
        response.data.forEach((value) => {
            value.isSupported = mockIsSupported;
        });
    }
    return response;
}

function convertToCashOutData(response: AxiosResponse<CashOutValueResponse[]>, isMixParlay: boolean) {
    return response.data.map<ICashOutData>(value => ({
        ...value,
        result: CashOutResult.fromPollux(value.status),
        isMixParlay,
    }));
}

function formatExchangeCustomerInfo(response: BettingPageResponse) {
    return {
        betCredit: response.betCredit,
        hasBettingBudget: response.hasBettingBudget,
        bettingBudget: response.bettingBudget,
        isSuspended: response.isSuspended,
        exchangeBalance: response.exchangeBalance,
        hasDepositBonus: response.hasDepositBonus,
    };
}

const notDefinedType: unique symbol = Symbol('nonExist');
type NotDefinedType = typeof notDefinedType

type TypedParamsAxiosRequestConfig<TParams> = Omit<AxiosRequestConfig, 'params'> & { params?: TParams}

/** wrapper for `axios.get` with request & response type checking */
function get<TRequest = NotDefinedType, TResponse = NotDefinedType>(
    url: string,
    config?: TypedParamsAxiosRequestConfig<TRequest extends NotDefinedType ? 'You must provide type parameters' : NoInfer<TRequest>>,
): Promise<AxiosResponse<TResponse>> {
    return apiInstance.get<TResponse>(url, config);
}

/** wrapper for `axios.post` with request & response type checking */
function post<TRequest = NotDefinedType, TResponse = NotDefinedType>(
    url: string,
    data?: TRequest extends NotDefinedType ? 'You must provide type parameters' : NoInfer<TRequest>,
    config?: AxiosRequestConfig,
): Promise<AxiosResponse<TResponse>> {
    return apiInstance.post<TResponse>(url, data, config);
}

const openSingleTicket = (ticket: ITicket | ITicketEuro, path: 'singleBetSlip/openTicket' | 'singleBetSlip/openEuroTicket', cancelToken?: CancelToken) => {
    const request = convertToTicketInfo(ticket);
    const requestTime = new Date();
    isWaitingForOpenTicket.value = true;
    return post<TicketInfo, TicketResponse>(path, request, { cancelToken })
        .then(handleMockOpenTicketStatus)
        .then((response) => {
            const responseTime = new Date();
            lastOpenTickets.value.unshift({
                ticket: ticket.key,
                duration: responseTime.getTime() - requestTime.getTime(),
                requestTime,
            });
            lastOpenTickets.value = lastOpenTickets.value.slice(0, 5);
            return response;
        }).finally(() => {
            isWaitingForOpenTicket.value = false;
        });
};

const openOutrightTicket = (ticket: ITicket | ITicketEuro, path: 'outrightBetSlip/openTicket' | 'outrightBetSlip/openEuroTicket', cancelToken?: CancelToken) => {
    const request = convertToOutrightTicketInfo(ticket);
    return post<OutrightTicketInfo, TicketResponse>(path, request, { cancelToken })
        .then(handleMockOpenTicketStatus);
};

function convertToAsiaSingleOrderRequest(stake: number, uid: string, ticket: ITicket, voucherId?: string): PlaceSingleOrderRequest {
    const eventResult = ticket.event.eventResults.find(x => x.id === ticket.odds.eventResult.id);

    return {
        stake,
        uid,
        oddsId: ticket.odds.id,
        betPage: ticket.betPage,
        sportType: SportType.toPollux(ticket.odds.sportType),
        marketType: MarketType.toPollux(ticket.odds.marketType),
        option: ticket.priceOption.option,
        eventId: ticket.event.id,
        liveScore: {
            home: eventResult?.homeTeam.liveScore ?? 0,
            away: eventResult?.awayTeam.liveScore ?? 0,
        },
        point: ticket.odds.point,
        voucherIdString: voucherId ?? '',
    };
}

function convertToEuroSingleOrderRequest(stake: number, uid: string, ticket: ITicketEuro, voucherId?: string): PlaceSingleOrderRequest {
    return {
        stake,
        uid,
        oddsId: ticket.odds.id,
        betPage: ticket.betPage,
        sportType: SportType.toPollux(ticket.odds.sportType),
        marketType: MarketType.toPollux(ticket.odds.marketType),
        option: ticket.priceOption.option,
        eventId: ticket.event.id,
        liveScore: {
            home: ticket.liveHomeScore ?? 0,
            away: ticket.liveAwayScore ?? 0,
        },
        point: ticket.odds.point,
        voucherIdString: voucherId ?? '',
    };
}

export const Api = {
    getOddsApiToken() {
        return get<never, string>('/oddsApi/getToken');
    },
    getCustomerInfo() {
        return get<never, CustomerInfo>('/account/getCustomerInfo')
            .then((response) => {
                apiInstance.defaults.baseURL = `${getPolluxUrl()}${response.data.basePath ?? '/api'}`;

                return response;
            });
    },
    getLocation() {
        return get<never, LocationResponse>('configuration/location');
    },
    getToggle(siteStyle: SiteStyle) {
        return get<ConfigurationSettingsParams, IToggleResponse>('/configuration/settings', {
            params: {
                project: EnumProject.Kratos,
                siteStyle: SiteStyle.toPollux(siteStyle),
            },
        });
    },
    updateIsAcceptAnyOdds(isAcceptAnyOdds: boolean) {
        return post<SettingRequest, CustomerInfo>('/userSetting/updateIsAcceptAnyOdds', { isAcceptAnyOdds });
    },
    updateIsAcceptAnyCashOutValue(isAcceptAnyCashOutValue: boolean) {
        return post<SettingRequest, CustomerInfo>('/userSetting/updateIsAcceptAnyCashOutValue', { isAcceptAnyCashOutValue });
    },
    updatePriceStyle(priceStyle: PriceStyle) {
        return post<SettingRequest, CustomerInfo>('/userSetting/updatePriceStyle', {
            priceStyle: PriceStyle.toPollux(priceStyle),
        });
    },
    updateOddsSorting(oddsSorting: OddsSorting) {
        return post<SettingRequest, CustomerInfo>('/userSetting/updateOddsSorting', {
            oddsSorting: OddsSorting.toPollux(oddsSorting),
        });
    },
    updateOddsDisplayMode(oddsDisplayMode: OddsDisplayMode) {
        return post<SettingRequest, CustomerInfo>('/userSetting/updateOddsDisplayMode', {
            oddsDisplayMode: OddsDisplayMode.toPollux(oddsDisplayMode),
        });
    },
    updateDisplayTime(displayTime: DisplayTime) {
        return post<SettingRequest, CustomerInfo>('/userSetting/updateDisplayTime', {
            displayTime: DisplayTime.toPollux(displayTime),
        });
    },
    updatePlaySound(playSound: string) {
        return post<SettingRequest, CustomerInfo>('/userSetting/updatePlaySound', {
            playSound,
        });
    },
    updateSiteStyle(siteStyle: SiteStyle) {
        return post<SettingRequest, CustomerInfo>('/userSetting/updateSiteStyle', {
            siteStyle: SiteStyle.toPollux(siteStyle),
        });
    },
    getVouchers(sportType: SportType, leagueId: number) {
        return get<VoucherGetAllParams, Voucher[]>('/voucher/getAll', {
            params: {
                sportType: SportType.toPollux(sportType),
                leagueId,
            },
        }).then(response => response.data.map<IVoucher>(voucher => ({
            id: voucher.id.toString(),
            stake: voucher.stake,
            expiryDate: parseISOString(voucher.expiryDate),
        })));
    },
    openSingleTicket(ticket: ITicket | ITicketEuro, cancelToken?: CancelToken) {
        return openSingleTicket(ticket, 'singleBetSlip/openTicket', cancelToken);
    },
    openSingleTicketEuro(ticket: ITicket | ITicketEuro, cancelToken?: CancelToken) {
        return openSingleTicket(ticket, 'singleBetSlip/openEuroTicket', cancelToken);
    },
    openMixParlayTicket(tickets: ITicket[] | ITicketEuro[], cancelToken?: CancelToken) {
        const request = tickets.map(convertToTicketInfo);
        return post<TicketInfo[], TicketMpResponse>('parlayBetSlip/openTicket', request, { cancelToken })
            .then(handleMockOpenTicketStatus);
    },
    openOutrightTicket(ticket: ITicket, cancelToken?: CancelToken) {
        return openOutrightTicket(ticket, 'outrightBetSlip/openTicket', cancelToken);
    },
    openOutrightTicketEuro(ticket: ITicket | ITicketEuro, cancelToken?: CancelToken) {
        return openOutrightTicket(ticket, 'outrightBetSlip/openEuroTicket', cancelToken);
    },
    async openBetBuilderTicket(request: BetBuilderTicketRequest, cancelToken?: CancelToken) {
        const response = await post<BetBuilderTicketRequest, BetBuilderTicketResponse>('betBuilderBetSlip/openTicket', request, { cancelToken });
        return formatBetBuilderTicket(response.data);
    },
    clearTicket() {
        return post('betSlip/clearTicket');
    },
    clearSingleTicket(uid: string) {
        return post<RemoveSingleTicket>('betSlip/clearSingleTicket', { uid });
    },
    placeAsiaSingleBet(betSlip: IBetSlip, stake: number) {
        const ticket = betSlip.tickets[0];
        const request = convertToAsiaSingleOrderRequest(stake, betSlip.uid, ticket);
        return post<PlaceSingleOrderRequest, OrderResponse>('singleBetSlip/placeBet', request)
            .then(handleMockPlaceBetStatus);
    },
    placeEuroAllSingleBets(singleList: IBetSlipEuro[]) {
        const request = singleList.map(betSlip => convertToEuroSingleOrderRequest(betSlip.stake, betSlip.uid, betSlip.tickets[0], betSlip.voucherId));

        return post<PlaceSingleOrderRequest[], OrderResponse[]>('singleBetSlip/placeBets', request);
    },
    placeAsiaFreeBet(betSlip: IBetSlip, stake: number, voucherId: string) {
        const ticket = betSlip.tickets[0];
        const request = convertToAsiaSingleOrderRequest(stake, betSlip.uid, ticket, voucherId);
        return post<PlaceSingleOrderRequest, OrderResponse>('singleBetSlip/placeFreeBet', request)
            .then(handleMockPlaceBetStatus);
    },
    /**
     * Temporarily use this because this response have transId and desktop don't need combo parlay feature yet
     */
    placeAsiaMixParlayBet(betSlip: IBetSlip, stake: number) {
        const request: PlaceComboParlayOrderRequest = {
            stake,
            uid: betSlip.uid,
            mpBet: betSlip.tickets.map(convertToAsiaSubBetRequest),
            selectedCombo: [betSlip.tickets.length],
            betPage: AsiaBetPageType.MixParlay,
        };

        return post<PlaceComboParlayOrderRequest, OrderResponse>('parlayBetSlip/placeMixParlay', request)
            .then(handleMockPlaceBetStatus);
    },
    placeEuroMixParlayBet(betSlip: IBetSlipEuro, stake: number) {
        const request: PlaceComboParlayOrderRequest = {
            stake,
            uid: betSlip.uid,
            mpBet: betSlip.tickets.map(convertToEuroSubBetRequest),
            selectedCombo: [betSlip.tickets.length],
            betPage: AsiaBetPageType.MixParlay,
        };

        return post<PlaceComboParlayOrderRequest, OrderResponse>('parlayBetSlip/placeMixParlay', request)
            .then(handleMockPlaceBetStatus);
    },
    placeAsiaOutrightBet(betSlip: IBetSlip, stake: number) {
        const ticket = betSlip.tickets[0];
        const request: PlaceOutrightOrderRequest = {
            stake,
            uid: betSlip.uid,
            betPage: ticket.betPage,
            sportType: SportType.toPollux(ticket.odds.sportType),
            outrightId: ticket.odds.id,
            leagueId: ticket.event.league.id,
            isLive: ticket.event.isLive,
        };
        return post<PlaceOutrightOrderRequest, OrderResponse>('outrightBetSlip/placeBet', request)
            .then(handleMockPlaceBetStatus);
    },
    placeEuroOutrightBets(betSlip: IBetSlipEuro[]) {
        const request: PlaceOutrightOrderRequest[] = betSlip.map((betSlip) => {
            const ticket = betSlip.tickets[0];
            return {
                stake: betSlip.stake,
                uid: betSlip.uid,
                betPage: ticket.betPage,
                sportType: SportType.toPollux(ticket.odds.sportType),
                marketType: MarketType.toPollux(ticket.odds.marketType),
                outrightId: ticket.odds.id,
                leagueId: ticket.event.league.id,
                isLive: ticket.event.isLive,
            };
        });
        return post<PlaceOutrightOrderRequest[], OrderResponse[]>('outrightBetSlip/placeBets', request);
    },
    async placeBetBuilder(betSlip: IBetBuilderBetSlip, stake: number) {
        const ticket = betSlip.tickets[0];
        const request: BetBuilderOrderRequest = {
            stake,
            uid: betSlip.uid,
            selectionIds: betSlip.tickets.map(ticket => ticket.selection.id),
            betPage: ticket.betPage,
            liveScore: {
                home: ticket.match.homeTeam.liveScore ?? 0,
                away: ticket.match.awayTeam.liveScore ?? 0,
            },
        };
        const response = await post<BetBuilderOrderRequest, BetBuilderOrderResponse>('betBuilderBetSlip/placeBet', request);
        return formatBetBuilderOrder(response.data);
    },
    getMiniMyBets(useCache: boolean) {
        return get<MyBetListMiniMyBetsParams, Bet[]>('myBetList/miniMyBets', {
            params: {
                c: useCache,
            },
        });
    },
    getMyBets(useCache: boolean) {
        return get<MyBetListMyBetListParams, MyBetsResponse>('myBetList/myBetList', {
            params: {
                c: useCache,
            },
        })
            .then<IMyBets>(x => (
            {
                runningBets: x.data.runningBets?.map(formatBet) ?? [],
                settledBets: x.data.settledBets?.map(formatBet) ?? [],
            }));
    },
    getSettledBetList(winLostDate: string) {
        return get<MyBetListSettledBetListParams, Bet[]>('myBetList/settledBetList', {
            params: {
                winLostDate,
            },
        });
    },
    getCashOutValues(transactionIds: number[]) {
        const request: CashOutValuesRequest = {
            transactionIds,
        };
        return post<CashOutValuesRequest, CashOutValueResponse[]>('cashOut/getValues', request)
            .then(handleMockCashOutIsSupported)
            .then(response => convertToCashOutData(response, false));
    },
    getMpCashOutValues(transactionIds: number[]) {
        const request: CashOutValuesRequest = {
            transactionIds,
        };
        return post<CashOutValuesRequest, CashOutValueResponse[]>('cashOut/getMpValues', request)
            .then(handleMockCashOutIsSupported)
            .then(response => convertToCashOutData(response, true));
    },
    cashOutSingleBet(transactionId: number, cashOutValue: number) {
        const request: CashOutRequest = {
            transactionId,
            cashOutValue,
            fullCashOutValue: cashOutValue,
        };
        return post<CashOutRequest, CashOutResponse>('cashOut/single', request)
            .then(handleMockCashOutStatus);
    },
    cashOutMixParlayBet(transactionId: number, cashOutValue: number) {
        const request: CashOutRequest = {
            transactionId,
            cashOutValue,
            fullCashOutValue: cashOutValue,
        };
        return post<CashOutRequest, CashOutResponse>('cashOut/mixParlay', request)
            .then(handleMockCashOutStatus);
    },
    getBannerFromUmai(position: BannerPosition, brandType: BrandType) {
        const request: BannerRequest = {
            brand: BrandType.toPollux(brandType),
            position: BannerPosition.toPollux(position),
        };
        return post<BannerRequest, BannerInfo[]>('banner/getBanners', request);
    },
    getLiveStream(matchId: number, streamId: number, liveStreamType: LiveStreamResource) {
        return get<LiveStreamGetLiveStreamUrlParams, LiveStreamResponse>('liveStream/getLiveStreamUrl', {
            params: {
                matchId,
                streamId,
                liveStreamType,
            },
        });
    },
    getMyEventIds() {
        return get<never, number[]>('account/myMatchIds');
    },
    async getMatchResults(sportType: SportType, resultDate: string) {
        const response = await get<MatchResultGetMatchResultsParams, MatchResponse[]>('matchResult/getMatchResults', {
            params: {
                sportType: SportType.toPollux(sportType),
                resultDate,
            },
        });
        return formatResults(sportType, response.data);
    },
    async getBetBuilderResults(sportType: SportType, matchId: number) {
        const response = await get<MatchResultGetBetBuilderResultParams, BetBuilderResultResponse>('matchResult/getBetBuilderResult', {
            params: {
                sportType: SportType.toPollux(sportType),
                matchId,
            },
        });
        return formatBetBuilderResults(response.data.marketResults);
    },
    getOutrightResults(sportType: SportType, startDate: string, endDate: string) {
        return get<MatchResultGetOutrightTournamentResultsParams, TournamentResponse[]>('matchResult/getOutrightTournamentResults', {
            params: {
                sportType: SportType.toPollux(sportType),
                startDate,
                endDate,
            },
        })
            .then(response => response.data.map(formatOutrightResults));
    },
    getSelectedLeagueInfos() {
        return get<never, SelectedLeagueInfoResponse[]>('userSetting/getSelectedLeagueInfos');
    },
    updateSelectedLeagueInfos(request: SelectedLeagueInfoRequest[]) {
        return post<SelectedLeagueInfoRequest[]>('userSetting/updateSelectedLeagueInfos', request);
    },
    sendBehaviors(request: SendBehaviorsRequest) {
        return post<SendBehaviorsRequest>('behavior/sendBehaviors', request);
    },
    toClassicDesktop() {
        return post<never, ProductSwitchToClassicDesktopResponse>('productSwitch/toClassicDesktop');
    },
    getMixParlayMaxPayout() {
        return get<never, MaxPayoutResponse>('maxPayout/mixParlay');
    },
    getJackpotToken() {
        return get<never, string>('jackpot/token');
    },
    claimJackpot(poolType: JackpotPoolType, round: number) {
        return post<ClaimJackpotRequest, PolluxTriggerResult>('jackpot/claim', {
            poolType: JackpotPoolType.ToPollux(poolType),
            round,
        }).then(response => TriggerResult.FromPollux(response.data));
    },
    async getExchangeBettingInfo() {
        const response = await get<never, ExchangeResponseOfBettingPageResponse>('/exchange/getBettingPage');
        return {
            status: ExchangeStatus.fromPollux(response.data.status),
            customerInfo: response.data.data ? formatExchangeCustomerInfo(response.data.data) : null,
            iframeUrl: response.data.data?.iframeUrl ?? '',
        };
    },
    async exchangeTransferIn(amount: number) {
        const response = await post<TransferRequest, ExchangeResponseOfObject>('/exchange/TransferIn', { amount });
        return { status: ExchangeStatus.fromPollux(response.data.status) };
    },
    async exchangeTransferOut(amount: number) {
        const response = await post<TransferRequest, ExchangeResponseOfObject>('/exchange/TransferOut', { amount });
        return { status: ExchangeStatus.fromPollux(response.data.status) };
    },
    async getExchangeCustomerInfo() {
        const response = await get<never, ExchangeResponseOfCustomerInfoResponse>('/exchange/getCustomerInfo');
        return {
            status: ExchangeStatus.fromPollux(response.data.status),
            customerInfo: response.data.data ? formatExchangeCustomerInfo(response.data.data) : null,
        };
    },
};
