<template>
    <div class="betSlipContainer">
        <Message
            v-bind:show="!!betSlipMessage"
            position="top"
            padding="4px 13px"
            status="danger"
            v-bind:message="betSlipMessage"
        />
        <div class="betSlip">
            <div ref="ticketContainerRef" class="ticketContainer">
                <ExpandTransitionGroup v-if="isEnableAnimation" v-on:after-enter="scrollToBottom()">
                    <Ticket
                        v-for="ticket in betSlip.tickets"
                        v-bind:key="ticket.key"
                        v-bind:ticket="ticket"
                        class="ticket-animated"
                    />
                </ExpandTransitionGroup>
                <template v-else>
                    <Ticket
                        v-for="ticket in betSlip.tickets"
                        v-bind:key="ticket.key"
                        v-bind:ticket="ticket"
                    />
                </template>
                <Notification
                    v-if="invalidSubBetMessage"
                    iconAlign="top"
                >
                    <span>
                        <DynamicI18n v-bind:config="invalidSubBetMessage" />
                    </span>
                </Notification>
            </div>
            <VButton
                v-if="isShowAddToParlay"
                v-gtm:label="'AddToParlay'"
                v-gtm:@click
                outline
                block
                color="primary"
                hoverOutlineColor="primary"
                v-bind:disabled="hasAddedToParlay"
                class="notranslate"
                v-on:click="addToParlay"
            >
                <AnimatedText v-bind:content="addedToParlayTitle" />
            </VButton>
            <PlaceBetBlock
                v-bind:key="betSlip.type === BetSlipType.Single
                    ? betSlip.tickets[0].key
                    : 'parlay do not rerender this component'"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import type { PropType } from 'vue';
    import { computed, nextTick, ref, toRefs } from 'vue';

    import DynamicI18n from '../common/DynamicI18n.vue';
    import Message from '@/components/betSlip/Message.vue';
    import PlaceBetBlock from '@/components/betSlip/PlaceBetBlock.vue';
    import Ticket from '@/components/betSlip/Ticket.vue';
    import { BetSlipContext } from '@/components/betSlip/betSlipContext';
    import AnimatedText from '@/components/common/AnimatedText.vue';
    import Notification from '@/components/common/Notification.vue';
    import VButton from '@/components/common/button/VButton.vue';
    import ExpandTransitionGroup from '@/components/transitions/ExpandTransitionGroup.vue';
    import { useEventEmitter } from '@/composable/useEventEmitter';
    import { useMpInvalidMessageDisplay } from '@/composable/useMpInvalidMessageDisplay';
    import { i18n } from '@/core/lib/i18n';
    import { getBrowserInfo } from '@/core/lib/utils';
    import type { IBetSlip } from '@/interface/IBetSlip';
    import { BetSlipType } from '@/interface/IBetSlip';
    import { useBetSlipStore } from '@/store/betSlipStore';

    const props = defineProps({
        betSlip: {
            type: Object as PropType<IBetSlip>,
            required: true,
        },
    });

    const browserInfo = getBrowserInfo();
    const isEnableAnimation = !(browserInfo.name?.toLowerCase().includes('safari') && parseFloat(browserInfo.version ?? '0') < 15);

    const { betSlip } = toRefs(props);
    const { betSlipMessage } = BetSlipContext.provide(betSlip);
    const { singleTicket, parlayBetSlip } = storeToRefs(useBetSlipStore());

    const isOutright = computed(() => betSlip.value.type === BetSlipType.Outright);
    const isMixParlay = computed(() => betSlip.value.type === BetSlipType.Parlay);

    const isShowAddToParlay = computed(() => {
        if (isMixParlay.value || isOutright.value) return false;
        return singleTicket.value && singleTicket.value.odds.hasMixParlay;
    });
    const hasAddedToParlay = computed(() => parlayBetSlip.value?.tickets.some(x => x.key === singleTicket.value?.key) ?? false);
    const addedToParlayTitle = computed(() => (hasAddedToParlay.value
        ? i18n.t('bet_slip_added_to_parlay')
        : i18n.t('bet_slip_add_to_parlay')));

    const { addSingleToParlayBetSlip } = useBetSlipStore();
    function addToParlay() {
        if (!singleTicket.value) return;
        addSingleToParlayBetSlip(
            singleTicket.value.event,
            singleTicket.value.odds,
            singleTicket.value.priceOption,
            singleTicket.value.betPage,
            singleTicket.value.priceStyle,
        );
    }

    const eventEmitter = useEventEmitter();
    const ticketContainerRef = ref<HTMLElement | null>(null);
    eventEmitter.on('betSlip:addToParlayBetSlip', () => {
        scrollToBottom();
    });

    scrollToBottom();

    function scrollToBottom() {
        nextTick(() => {
            if (ticketContainerRef.value) {
                const scrollHeight = ticketContainerRef.value.scrollHeight;
                ticketContainerRef.value.scroll({ top: scrollHeight, behavior: 'smooth' });
            }
        });
    }

    const tickets = computed(() => betSlip.value.tickets);
    const { invalidSubBetMessage } = useMpInvalidMessageDisplay(tickets, isMixParlay);
</script>

<style lang="scss" scoped>
    @import "@/components/betSlip/BetSlip";
</style>
