<template>
    <div class="betSlipContainer notranslate">
        <div ref="ticketContainerRef" class="ticketContainer">
            <ExpandTransitionGroup v-if="isEnableAnimation">
                <Ticket
                    v-for="ticket in betSlip.tickets"
                    v-bind:key="ticket.key"
                    v-bind:ticket="ticket"
                    v-bind:stakeDisplay="stakeDisplay"
                    class="ticket-animated"
                />
            </ExpandTransitionGroup>
            <template v-else>
                <Ticket
                    v-for="ticket in betSlip.tickets"
                    v-bind:key="ticket.key"
                    v-bind:ticket="ticket"
                    v-bind:stakeDisplay="stakeDisplay"
                />
            </template>
        </div>

        <template v-if="!isMixParlay">
            <div class="footer">
                <StakeInput
                    v-bind:disabled="hasSelectedVoucher"
                    v-bind:stakeDisplay="stakeDisplay"
                    v-bind:setDisplayToStake="setDisplayToStake"
                />

                <div class="return">
                    <div class="returnInfo">
                        {{ `${i18n.t('bet_slip_max_payout')}:` }}
                    </div>
                    <div class="returnAmount">
                        <CurrencyIcon />
                        {{ displayMaxPayout }}
                    </div>
                </div>
            </div>

            <div class="form-select">
                <select
                    v-if="showVoucherList"
                    ref="voucherSelectRef"
                    v-model="selectedVoucherId"
                    v-bind:disabled="displayVouchers.length === 0"
                    class="select"
                    required
                >
                    <option value="" hidden>
                        {{ i18n.t('redeem_voucher') }}
                    </option>
                    <option value="0">
                        {{ i18n.t('voucher_no_selected') }}
                    </option>
                    <option
                        v-for="voucher in displayVouchers"
                        v-bind:key="voucher.id"
                        v-bind:value="voucher.id"
                        v-bind:selected="voucher.id === selectedVoucherId"
                        v-bind:disabled="voucher.selected && voucher.id !== selectedVoucherId"
                    >
                        {{ getVoucherText(voucher) }}
                    </option>
                </select>
            </div>

            <div class="stakeDetailText">
                {{ `${i18n.t('bet_slip_min')}:${minBetDisplay} | ${i18n.t('bet_slip_max')}:${maxBetDisplay}` }}
            </div>

            <div class="footer message">
                <Message
                    class="placeBetBlockItem"
                    v-bind:show="!!message"
                    position="top"
                    iconSize="lg"
                    status="danger"
                    rounded
                    v-bind:message="message"
                    v-bind:icon="`icon-system-warningCircle`"
                />
            </div>
        </template>
    </div>
</template>

<script lang="ts" setup>
    import orderBy from 'lodash/orderBy';
    import { storeToRefs } from 'pinia';
    import { computed, ref, toRefs, watch } from 'vue';
    import Message from '@/components/betSlip/Message.vue';
    import StakeInput from '@/components/betSlip/StakeInput.vue';
    import Ticket from '@/components/betSlip/Ticket.Euro.vue';
    import { BetSlipContextEuro } from '@/components/betSlip/betSlipContext';
    import CurrencyIcon from '@/components/common/CurrencyIcon.vue';
    import ExpandTransitionGroup from '@/components/transitions/ExpandTransitionGroup.vue';
    import { useStakeDisplay } from '@/composable/useStakeDisplay';
    import { useStakeInput } from '@/composable/useStakeInput';
    import { sportsGtm } from '@/core/lib/gtm';
    import { i18n } from '@/core/lib/i18n';
    import { getBrowserInfo } from '@/core/lib/utils';
    import { getVoucherExpireText } from '@/core/lib/voucherHelper';
    import type { IBetSlipEuro } from '@/interface/IBetSlip';
    import { BetSlipType } from '@/interface/IBetSlip';
    import type { IVoucherWithSelected } from '@/interface/IBetSlipStoreEuro';
    import { useEuroBetSlipStore } from '@/store/betSlipStore.euro';
    import { useCustomerStore } from '@/store/customerStore';

    const props = defineProps<{
        betSlip: IBetSlipEuro;
    }>();
    const { betSlip } = toRefs(props);

    const voucherSelectRef = ref<HTMLSelectElement | null>(null);
    const ticketContainerRef = ref<HTMLElement | null>(null);

    const { sboCurrency } = storeToRefs(useCustomerStore());

    const browserInfo = getBrowserInfo();
    const isEnableAnimation = !(browserInfo.name?.toLowerCase().includes('safari') && parseFloat(browserInfo.version ?? '0') < 15);

    const isMixParlay = computed(() => betSlip.value.type === BetSlipType.Parlay);

    const minBetDisplay = computed(() => useStakeDisplay(betSlip.value.minBet).stakeDisplay);
    const maxBetDisplay = computed(() => useStakeDisplay(betSlip.value.maxBet).stakeDisplay);

    const { useBetSlipsStoreByType } = useEuroBetSlipStore();
    const { voucherEnabled } = toRefs(useEuroBetSlipStore());

    const { betSlipType, maxPayout, message, vouchers } = BetSlipContextEuro.provide(betSlip);
    const { updateBet } = useBetSlipsStoreByType(betSlipType);

    const selectedVoucherId = computed({
        get() {
            return betSlip.value.voucherId ?? '';
        },
        set(value: string) {
            if (value === '0') {
                value = '';

                if (voucherSelectRef.value) {
                    const gtmPayload = sportsGtm.getGtmEventFromEl(voucherSelectRef.value);
                    sportsGtm.pushToDataLayer('click', {
                        ...gtmPayload,
                        label: 'Voucher_None',
                    });
                }
            }

            updateBet(betSlip.value.key, (_betSlip) => {
                _betSlip.voucherId = value === '' ? undefined : value;
                _betSlip.stake = vouchers.value.find(voucher => voucher.id === value)?.stake ?? 0;
            });
        },
    });

    watch(voucherEnabled, (value) => {
        if (!value) {
            if (selectedVoucherId.value) {
                selectedVoucherId.value = '';
            }
        }
    });

    const displayVouchers = computed(() => orderBy(vouchers.value, [
        x => x.expiryDate,
        x => x.stake,
    ]));

    const getVoucherText = (voucher: IVoucherWithSelected) => {
        const isSelectedByOthers = voucher.selected && voucher.id !== selectedVoucherId.value;
        const stakeDisplay = useStakeDisplay(voucher.stake).stakeDisplay;
        const expireText = getVoucherExpireText(voucher.expiryDate);
        const selectedText = isSelectedByOthers ? ` (${i18n.t('voucher_selected')})` : '';
        return `${sboCurrency.value} ${stakeDisplay} (${expireText})${selectedText}`;
    };

    const hasSelectedVoucher = computed(() => voucherEnabled.value && !!selectedVoucherId.value);
    const showVoucherList = computed(() => voucherEnabled.value && betSlip.value.type === BetSlipType.Single);

    const betSlipStake = computed({
        get() {
            if (hasSelectedVoucher.value) return 0;

            return betSlip.value.stake;
        },
        set(value: number) {
            betSlip.value.stake = value;
        },
    });

    const { stakeDisplay, setDisplayToStake } = useStakeInput(betSlipStake);

    const { formatMoneyWithDecimal } = useCustomerStore();
    const displayMaxPayout = computed(() => formatMoneyWithDecimal(maxPayout.value));
</script>

<style lang="scss" scoped>
    @import "@/components/betSlip/BetSlip.Euro";
</style>
