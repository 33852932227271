import { useIntervalFn } from '@vueuse/core';
import type { Ref } from 'vue';
import { computed, ref } from 'vue';
import { useIdle } from '@/composable/useIdle';
import { assignArray } from '@/core/lib/array';
import { i18n } from '@/core/lib/i18n';
import { deepFreeze } from '@/core/lib/utils';
import type { CurrentTournamentsFragment } from '@/core/oddsApi/oddsApiType';
import { useCurrentTournamentsQuery } from '@/core/oddsApi/oddsApiType';
import type { ILeague } from '@/interface/ILeague';
import { EnumLanguage, LeagueSize, SportType } from '@/interface/enum';

const formatLeague = (league: CurrentTournamentsFragment): ILeague => ({
    id: league.id,
    name: league.tournamentName ?? '',
    info: league.tournamentInfo ?? '',
    size: LeagueSize.fromOddsApi(league.tournamentSize),
    region: {
        id: league.region?.id ?? 0,
        name: league.region?.regionName ?? '',
    },
});

export function useCurrentLeagues(sportType: Ref<SportType>) {
    const variables = computed(() => ({
        query: {
            sport: SportType.toOddsApi(sportType.value),
            lang: EnumLanguage.toOddsApi(i18n.locale.value),
        },
    }));
    const { loaded, loading, onResult, refetch } = useCurrentTournamentsQuery(
        variables,
        computed(() => ({
            enabled: true,
        })),
    );

    useIntervalFn(refetch, 60 * 1000);

    // Automatically recover from idle state with a force refresh
    // This is to prevent the user from losing data when they are idle
    const { onActive } = useIdle(60 * 1000);
    onActive(() => {
        // eslint-disable-next-line no-console
        console.log('useCurrentLeagues: Recovering from idle state...');
        refetch();
    });

    const leagues = ref<ILeague[]>([]);

    onResult((result) => {
        const formatted = (result ?? [])
            .map(item => deepFreeze(formatLeague(item)));

        assignArray(leagues.value, formatted, item => item.id);
    });

    return {
        loaded,
        loading,
        refetch,
        leagues,
    };
}
