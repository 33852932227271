<template>
    <div v-gtm:page="'MyBets'" class="myBets">
        <div v-if="banner">
            <img
                class="my-bets-banner"
                v-bind:src="banner?.cdn"
                alt=""
                v-on:click="onClickBanner"
            />
        </div>
        <Navbar v-gtm:type="'Header'" class="sticky">
            <template #navbarTitleIcon>
                <SvgIcon name="icon-system-mini-my-bets" />
            </template>
            <template #navbarTitle>
                {{ i18n.t('tab_title_mybets') }}
            </template>
            <template #navbarDivider>
                <NavbarDivider />
            </template>
            <template #topRow>
                <NavbarRowItem fluid>
                    <NavbarMain>
                        <NavbarItem
                            v-for="filter in statusFilters"
                            v-bind:key="filter.key"
                            v-gtm:label="filter.gtmLabel"
                            v-gtm:@click
                            v-bind:name="filter.name"
                            v-bind:onclick="filter.onClick"
                            isShowCount
                            v-bind:count="filter.count"
                            v-bind:isActive="filter.isActive"
                        />
                    </NavbarMain>
                </NavbarRowItem>
                <NavbarRowItem v-if="isCashOutEnabled && statusFilter === MyBetsStatusFilter.Running">
                    <VButton>
                        <SvgIcon left name="icon-system-cash-out" />
                        {{ i18n.t('cash_out') }}
                        <SvgIcon
                            name="icon-system-arrow"
                            v-bind:rotated="!isCashOutDropdownHovered"
                            right
                        />
                        <Dropdown
                            v-bind:openOnHover="!IS_MOBILE"
                            v-bind:openOnClick="IS_MOBILE"
                            v-on:onOpen="isCashOutDropdownHovered = true"
                            v-on:onClose="isCashOutDropdownHovered = false"
                        >
                            <div
                                v-gtm:page="'MyBets'"
                                v-gtm:type="'CashOut'"
                                class="cashOutMenu"
                                v-bind:class="i18n.locale.value"
                            >
                                <div class="cashOutItem">
                                    <CheckBox
                                        id="cashOut"
                                        v-bind:gtmLabel="isDisplayCashOut ? 'Switch_Off' : 'Switch_On'"
                                        v-bind:checked="isDisplayCashOut"
                                        v-bind:onclick="setIsDisplayCashOut"
                                    >
                                        <template #label>
                                            <span class="labelText">
                                                {{ i18n.t('cash_out') }}
                                            </span>
                                        </template>
                                    </CheckBox>

                                    <div class="cashOutItem_content">
                                        {{ i18n.t('cash_out_tooltip') }}
                                        <a
                                            v-gtm:label="'LearnMore'"
                                            v-gtm:@click
                                            v-bind:href="cashOutLearnMoreUrl"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {{ i18n.t('learn_more') }}
                                        </a>
                                    </div>
                                </div>
                                <div class="cashOutItem">
                                    <AcceptAnyCashOutValue />
                                    <div class="cashOutItem_content">
                                        {{ i18n.t('accept_any_cash_out_value_tooltip') }}
                                    </div>
                                </div>
                            </div>
                        </Dropdown>
                    </VButton>
                </NavbarRowItem>
            </template>
            <template #bottomRow>
                <NavbarRowItem fluid>
                    <SlideTab v-bind:items="dateFilters" />
                </NavbarRowItem>
                <NavbarRowItem>
                    <VButton
                        v-gtm:label="'Refresh'"
                        v-gtm:@click
                        flat
                        v-on:click="refresh(true, true)"
                    >
                        <template #icon>
                            <SvgIcon
                                name="icon-system-refresh"
                                size="16"
                                left
                                v-bind:rotating="isRefreshing"
                            />
                        </template>
                        <span class="refresher_title">
                            {{ countdown }}
                        </span>
                    </VButton>
                </NavbarRowItem>
            </template>
        </Navbar>
        <BetsTable
            v-bind:bets="myBets"
            v-bind:isLoaded="isLoaded"
            v-bind:statusFilter="statusFilter"
        />
    </div>
</template>

<script lang="ts" setup>
    import { useBroadcastChannel, useTimeoutFn } from '@vueuse/core';
    import { storeToRefs } from 'pinia';
    import { computed, ref, watch } from 'vue';
    import AcceptAnyCashOutValue from '@/components/cashOut/AcceptAnyCashOutValue.vue';
    import { useCashOutRefresher } from '@/components/cashOut/useCashOutRefresher';
    import CheckBox from '@/components/common/CheckBox.vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import VButton from '@/components/common/button/VButton.vue';
    import Dropdown from '@/components/common/dropdown/Dropdown.vue';
    import Navbar from '@/components/common/navbar/Navbar.vue';
    import NavbarDivider from '@/components/common/navbar/NavbarDivider.vue';
    import NavbarItem from '@/components/common/navbar/NavbarItem.vue';
    import NavbarMain from '@/components/common/navbar/NavbarMain.vue';
    import NavbarRowItem from '@/components/common/navbar/NavbarRowItem.vue';
    import SlideTab from '@/components/common/slideTab/SlideTab.vue';
    import BetsTable from '@/components/myBets/BetsTable.vue';
    import { accountDbReplicationDelay, cashOutedDelay } from '@/components/myBets/betHelper';
    import { useDisplayCashOut } from '@/components/myBets/useDisplayCashOut';
    import { useRefresher } from '@/components/myBets/useRefresher';
    import { useBannerAsia, useBannerEuro } from '@/composable/useBanners';
    import { useIdle } from '@/composable/useIdle';
    import { GetCashOutLearnMoreUrl } from '@/core/lib/helpCenter';
    import { i18n } from '@/core/lib/i18n';
    import { isMobile } from '@/core/lib/utils';
    import type { AcceptAnyCashOutBroadcastMessage, BetSlipBroadcastMessage } from '@/interface/broadcastchannel';
    import { BannerPosition, BroadcastChannelName, MyBetsDateFilter, MyBetsStatusFilter, SiteStyle } from '@/interface/enum';
    import { useCustomerStore } from '@/store/customerStore';
    import { useMyBetsStore } from '@/store/myBetsStore';
    import { useToggleStore } from '@/store/toggleStore';

    const { myBets, isLoaded, statusFilter, runningBets, settledBets } = storeToRefs(useMyBetsStore());
    const { setStatusFilter } = useMyBetsStore();
    const { isCashOutEnabled } = storeToRefs(useToggleStore());

    const statusFilters = computed(() => [
        {
            key: MyBetsStatusFilter.Running,
            name: i18n.t('view_running'),
            count: runningBets.value.length,
            isActive: statusFilter.value === MyBetsStatusFilter.Running,
            onClick: () => {
                setStatusFilter(MyBetsStatusFilter.Running);
            },
            gtmLabel: 'ViewRunning',
        },
        {
            key: MyBetsStatusFilter.Settled,
            name: i18n.t('view_settled'),
            count: settledBets.value.length,
            isActive: statusFilter.value === MyBetsStatusFilter.Settled,
            onClick: () => {
                setStatusFilter(MyBetsStatusFilter.Settled);
            },
            gtmLabel: 'ViewSettled',
        },
    ]);

    const dateFilters = computed(() => {
        const dateFilters = statusFilter.value === MyBetsStatusFilter.Running
            ? [MyBetsDateFilter.AllDate]
            : [MyBetsDateFilter.Today];
        return dateFilters.map(filter => ({
            key: filter,
            name: i18n.t(`date_filter_${filter}`),
            isActive: true,
        }));
    });

    const { refresh, isRefreshing, countdown, forceRefresh } = useRefresher();
    const { refreshCashOutValues } = useMyBetsStore();
    const { refresh: cashOutRefresh } = useCashOutRefresher(refreshCashOutValues);

    const { data } = useBroadcastChannel<BetSlipBroadcastMessage, BetSlipBroadcastMessage>({ name: BroadcastChannelName.BetSlip });
    watch(data, () => {
        useTimeoutFn(() => forceRefresh(false, false), accountDbReplicationDelay);
    });

    const { data: cashOuted } = useBroadcastChannel({ name: BroadcastChannelName.CashOut });
    watch(cashOuted, () => {
        useTimeoutFn(() => forceRefresh(false, false), cashOutedDelay);
    });

    const { setIsAcceptAnyCashOutValue } = useCustomerStore();
    const { data: acceptAnyCashOutValue } = useBroadcastChannel<AcceptAnyCashOutBroadcastMessage, AcceptAnyCashOutBroadcastMessage>({ name: BroadcastChannelName.AcceptAnyCashOutValue });
    watch(acceptAnyCashOutValue, () => {
        setIsAcceptAnyCashOutValue(acceptAnyCashOutValue.value.isAcceptAnyCashOutValue);
    });

    const { brandType } = storeToRefs(useCustomerStore());
    const cashOutLearnMoreUrl = computed(() => GetCashOutLearnMoreUrl(brandType.value, i18n.locale.value));

    const { isDisplayCashOut, setIsDisplayCashOut } = useDisplayCashOut();

    const IS_MOBILE = isMobile();
    const isCashOutDropdownHovered = ref(false);

    // Automatically recover from idle state with a force refresh
    // This is to prevent the user from losing data when they are idle
    const { onActive } = useIdle(60 * 1000);
    onActive(() => {
        // eslint-disable-next-line no-console
        console.log('MyBets:Recovering from idle state...');
        refresh(true, true);
        cashOutRefresh();
    });

    const { siteStyle } = storeToRefs(useCustomerStore());
    const { executeBannerAction, banners } = siteStyle.value === SiteStyle.Euro
        ? useBannerEuro(BannerPosition.EuroMyBetTop)
        : useBannerAsia(BannerPosition.AsiaMyBetTop);

    const banner = computed(() => (banners.value ? banners.value[0] : null));

    function onClickBanner() {
        executeBannerAction(banner.value);
    }
</script>

<style lang="scss" scoped>
    @import "@/components/myBets/MyBetsBlock";

    .myBets {
        :deep(.navbar) {
            background-color: var(--brandColor-300, #a1bee6);

            .navbar_row:first-child {
                border-radius: var(--borderRadius) var(--borderRadius) 0 0;
            }
        }
    }
</style>
