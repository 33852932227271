<template>
    <iframe
        title="Live Court"
        v-bind:src="liveCourtUrl"
        width="100%"
        height="100%"
        class="liveCourt"
    />
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import { i18n } from '@/core/lib/i18n';
    import { getKratosLiveCourtUrl } from '@/core/lib/url';
    import { EnumLanguage } from '@/interface/enum';
    import { BrandType } from '@/interface/pollux';
    import { useCustomerStore } from '@/store/customerStore';

    const props = defineProps({
        liveCourtMatchId: {
            type: Number,
            required: true,
        },
    });

    const lang = EnumLanguage.toLiveCourt(i18n.locale.value) ?? 'en';
    const { brandType } = storeToRefs(useCustomerStore());
    const brandName = BrandType[brandType.value].toLowerCase();
    const liveCourtUrl = `${getKratosLiveCourtUrl()}/live-court-preview.html?liveCourtMatchId=${props.liveCourtMatchId}&lang=${lang}&brandType=${brandName}`;
</script>

<style lang="scss" scoped>
.liveCourt {
    position: relative;
    z-index: 1;
    border: none;
}
</style>
