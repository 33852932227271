<template>
    <div class="betOptionInfo" v-bind:class="{ live: bet.isLive }">
        <div class="betOptionInfo_header">
            <div class="betOptionInfo_header_title">
                <strong class="optionTitle">
                    {{ title }}
                </strong>
            </div>
            <div v-if="!bet.isMixParlay && bet.isWaitingReject" class="betOptionInfo_header_aside">
                <VButton
                    padding="2"
                    rounded
                    v-on:click="updateHiddenBetTransIds(bet.transactionId)"
                >
                    <SvgIcon name="icon-system-close" size="md" />
                </VButton>
            </div>
        </div>
        <div class="betOptionInfo_option">
            <component v-bind:is="bet.isWaitingReject ? 'del' : 'div'" class="optionContent">
                <ul class="optionContent_inner">
                    <li>
                        <strong class="optionTeam" v-bind:class="{ 'optionTeam-favorite': betOption.isFavorite }">
                            {{ betOption.name }}
                        </strong>
                    </li>
                    <li class="optionOddsGroup">
                        <template v-if="pointDisplay">
                            <span class="optionOdds optionOdds-point">{{ pointDisplay }}</span>
                            <span class="optionOdds optionOdds-at">@</span>
                        </template>
                        <template v-if="scoreDisplay">
                            <span class="optionOdds optionOdds-score">{{ scoreDisplay }}</span>
                            <span class="optionOdds optionOdds-at">@</span>
                        </template>
                        <span class="optionOdds optionOdds-price">
                            <strong class="optionOddsPrice">
                                <span v-bind:class="{ 'optionOddsPrice-negative': isNegativePrice }">{{ priceDisplay }}</span>
                                {{ `(${priceStyleDisplay})` }}
                            </strong>
                        </span>
                    </li>
                    <li v-if="!bet.isOutright" class="optionGameInfo">
                        <span v-if="bet.isLive">
                            {{ bet.liveTime }} {{ bet.liveScore }}
                        </span>
                        <span v-else>
                            {{ showTimeDisplay }}
                        </span>
                    </li>
                    <!--  -->
                </ul>
                <strong v-if="!bet.isMixParlay" class="optionContent_Stake">
                    <SvgIcon
                        v-if="bet.isVoucher"
                        v-tooltip="i18n.t('voucher')"
                        name="icon-system-voucher"
                        size="md"
                    />
                    <CurrencyIcon />
                    {{ stakeDisplay }}
                </strong>
            </component>
        </div>
        <div class="betOptionInfo_detail">
            <component
                v-bind:is="bet.isWaitingReject ? 'del' : 'div'"
                class="detailText"
                style="color: var(--primary);"
            >
                <div v-if="!bet.isOutright">
                    {{ bet.homeTeamName }} -{{ i18n.t('vs') }}-  {{ bet.awayTeamName }}
                </div>
                <div>
                    {{ bet.leagueName }}
                </div>
            </component>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { toRefs } from 'vue';
    import CurrencyIcon from '@/components/common/CurrencyIcon.vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import VButton from '@/components/common/button/VButton.vue';
    import { useBetDisplay } from '@/components/myBets/useBetDisplay';
    import { i18n } from '@/core/lib/i18n';
    import type { IBaseBet } from '@/interface/IBet';
    import { useMiniMyBetsStore } from '@/store/miniMyBetsStore';

    const props = defineProps<{
        bet: IBaseBet;
    }>();

    const { bet } = toRefs(props);
    const { updateHiddenBetTransIds } = useMiniMyBetsStore();
    const {
        betOption, title, pointDisplay, priceDisplay, scoreDisplay,
        isNegativePrice, priceStyleDisplay, stakeDisplay,
        showTimeDisplay,
    } = useBetDisplay(bet);

</script>

<style lang="scss" scoped>
    @import "@/components/myBets/miniMyBets/BetOptionInfo";
</style>
