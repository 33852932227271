import { computed } from 'vue';
import { OutrightOddsDisplayBlockContext } from '@/components/oddsDisplay/outright/OutrightOddsDisplayBlockContext';
import { useCollapse } from '@/composable/useCollapse';
import { defineContext } from '@/core/lib/defineContext';
import { ExpiryCache } from '@/core/lib/expiryCache';
import type { IOutrightOdds } from '@/interface/IOutrightOdds';

const ODDS_CACHE_EXPIRY_TIME = 3 * 60 * 1000; // 3 minute

// each OutrightOddsDisplayContext instance will only serve for one OutrightOddsDisplay.vue
export const OutrightOddsDisplayContext = defineContext('OutrightOddsDisplay', (isLive: boolean) => {
    const {
        sportType,
        marketPage,
        displayLiveLeagues,
        displayNonLiveLeagues,
    } = OutrightOddsDisplayBlockContext.inject();

    const leagues = computed(() => (isLive ? displayLiveLeagues.value : displayNonLiveLeagues.value));
    const collapseIds = computed(() => leagues.value.map(e => e.id));
    const {
        isItemCollapsedRef,
        isCollapsedAll,
    } = useCollapse(collapseIds);

    const oddsCache = new ExpiryCache<string, IOutrightOdds[]>(ODDS_CACHE_EXPIRY_TIME);

    return {
        sportType,
        marketPage,
        leagues,
        isItemCollapsedRef,
        isCollapsedAll,
        oddsCache,
    };
});
