import { GamesApi } from '@/core/lib/gamesApi';
import { getGamesOpenGameUrl, getGamesProductSwitchUrl } from '@/core/lib/url';

export interface IOpenGame {
    gameCode: number;
    gameFrom: number;
}

export function openInJoiGame(gameCode: string | number, gameFrom: number) {
    const url = `${getGamesOpenGameUrl()}?gameFrom=${gameFrom}&gameCode=${gameCode}`;
    window.open(url, 'game', 'popup=yes,scrollbars=no,resizable=yes,width=1200,height=770');
}

export async function openInJoiGameByApi({ gameCode, gameFrom }: IOpenGame) {
    /**
     * We set `target` to gameCode to make sure the window is unique by game.
     * We open blank window first so user do not feel any interaction delay due to slow games api response.
     * We also use this pattern because Safari will block any call to window.open() made after an async call.
     * https://stackoverflow.com/a/39387533
     */
    const popup = window.open('/prepare.html', gameCode.toString(), 'popup=yes,scrollbars=no,resizable=yes,width=940,height=600');
    popup?.focus(); // Bring reused window back to focus

    const { data: { gameUrl } } = await GamesApi.openGame(gameCode, gameFrom);
    if (popup) {
        popup.location.href = gameUrl;
    }
}

export function openInJoiPromotionPage(promotion: string, gameFrom: number) {
    const url = `${getGamesProductSwitchUrl()}?gameFrom=${gameFrom}&promotion=${promotion}`;
    window.open(url, 'event', 'popup=yes,scrollbars=no,resizable=yes,width=1200,height=770');
}
