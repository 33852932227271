import { useRoute, useRouter } from 'vue-router';
import { computedNextTick } from './computedNextTick';
import { EuroDate, EuroRoutePage, SportType } from '@/interface/enum';

/**
 * Computed values are using {@link computedNextTick} as workaround
 * for {@link https://github.com/vuejs/vue-router/issues/3393 issue} of route change updating state before the previous component has been unmounted.
 *
 * Author has closed the issue saying it is fixed in vue-router 4, but it still happens. ୧༼ಠ益ಠ༽୨
 *
 * Reproduce this issue by going from a sport page to home page, and page will throw error trying to query odds-api for undefined sportType
 * or refresh sport page and it might initialize with undefined sport first, before updating to param value.
 */
export const useEuroRoute = () => {
    const route = useRoute();

    const sportType = computedNextTick(() => SportType.fromRoute(route.params.sport?.toString() ?? ''));

    const regionId = computedNextTick(() => parseInt(route.params.region?.toString() ?? '0'));
    const regionName = computedNextTick(() => route.params.regionName?.toString() ?? '');

    const leagueId = computedNextTick(() => parseInt(route.params.league?.toString() ?? '0'));
    const leagueName = computedNextTick(() => route.params.leagueName?.toString() ?? '');

    const eventId = computedNextTick(() => parseInt(route.params.eventId?.toString() ?? '0'));
    const eventName = computedNextTick(() => route.params.eventName?.toString() ?? '');

    const date = computedNextTick(() => EuroDate.fromRoute(route.params.date?.toString() ?? ''));

    const isInPage = (...pages: EuroRoutePage[]) => pages.includes(route.name as EuroRoutePage);
    const isMultiplesPage = computedNextTick(() => isInPage(EuroRoutePage.Multiples, EuroRoutePage.Multiples_MatchDetail));

    const router = useRouter();
    const updateNameParams = (params: {regionName?: string, leagueName?: string, eventName?: string}) => {
        const keys = Object.keys(params) as (keyof typeof params)[];
        const isDifferent = keys.some(key => params[key] !== route.params[key]);

        if (isDifferent) {
            router.replace({
                params: {
                    ...route.params,
                    ...params,
                },
            });
        }
    };

    return {
        route,

        sportType,
        regionId,
        regionName,
        leagueId,
        leagueName,
        eventId,
        eventName,
        date,

        isInPage,
        isMultiplesPage,

        updateNameParams,
    };
};
