import type { Ref } from 'vue';
import { computed, nextTick, ref } from 'vue';
import { useState } from '@/composable/useState';
import { assignArray } from '@/core/lib/array';
import { i18n } from '@/core/lib/i18n';
import { deepFreeze } from '@/core/lib/utils';
import { formatOutrightLeague } from '@/core/oddsApi/helpers/formatOutrightLeague';
import { useOutrightTournamentsQuery } from '@/core/oddsApi/oddsApiType';
import type { IOutrightLeague } from '@/interface/IOutrightLeague';
import { EnumLanguage, SportType } from '@/interface/enum';

interface IOutrightLeagueParam {
    sportType: SportType;
    isLive: boolean;
    leagueId: number;
    isActive?: Ref<boolean>;
}

export function useOutrightLeagueQuery({
    sportType,
    isLive,
    leagueId,
    isActive = ref(true),
}: IOutrightLeagueParam) {
    const variables = computed(() => ({
        query: {
            sport: SportType.toOddsApi(sportType),
            isLive,
            lang: EnumLanguage.toOddsApi(i18n.locale.value),
            tournamentNames: undefined,
            tournamentIds: [leagueId],
        },
    }));

    const [isEnabled, setIsEnabled] = useState(true);
    const refetch = async () => {
        if (isEnabled.value) {
            setIsEnabled(false);
            nextTick(() => setIsEnabled(true));
        }
    };

    const { loaded, loading, onResult } = useOutrightTournamentsQuery(
        variables,
        computed(() => ({
            enabled: isActive.value && isEnabled.value,
        })),
    );

    const outrightLeagues = ref<IOutrightLeague[]>([]);

    onResult((result) => {
        const newOutrightLeagues = (result ?? [])
            .filter(x => x.tournament?.id === leagueId)
            .map(x => deepFreeze(formatOutrightLeague(x, sportType, isLive)));

        assignArray(outrightLeagues.value, newOutrightLeagues, x => x.id);
    });

    return {
        loaded,
        loading,
        outrightLeagues,
        refetch,
    };
}
