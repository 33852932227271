<template>
    <MyBetsBlock />
</template>

<script lang="ts" setup>
    import MyBetsBlock from '@/components/myBets/MyBetsBlock.vue';
    import { useTitle } from '@/composable/useTitle';
    import { PageType } from '@/interface/enum';

    useTitle(PageType.Mybets);

    document.body.classList.add('sports-background');
</script>
