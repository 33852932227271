<template>
    <div class="bet_detail">
        <div class="bet_detail_info">
            <div v-if="bet.isBetBuilder">
                <div>
                    {{ bet.homeTeamName }} -{{ i18n.t('vs') }}-  {{ bet.awayTeamName }}
                    <span v-if="bet.isLive">
                        @ {{ bet.liveTime }} {{ bet.liveScore }}
                    </span>
                    <span v-else>
                        {{ showTimeDisplay }}
                    </span>
                </div>
                <div>
                    {{ bet.leagueName }}
                </div>
            </div>
            <component v-bind:is="bet.isWaitingReject ? 'del' : 'div'" class="detailText">
                <div>
                    ID: {{ bet.transactionId }}
                </div>
                <div>
                    {{ formatToServerTime(bet.transactionDate, 'MM/dd HH:mm:ss') }} (GMT -4)
                </div>
            </component>
            <div class="detailStatus">
                <BetStatusTag v-bind:status="betStatus" />
            </div>
        </div>
        <div v-if="rejectReasonDisplay" class="bet_detail_msg">
            {{ rejectReasonDisplay }}
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { computed, toRefs } from 'vue';
    import BetStatusTag from '@/components/myBets/BetStatusTag.vue';
    import { useBetDisplay } from '@/components/myBets/useBetDisplay';
    import { formatToServerTime } from '@/core/lib/date';
    import { i18n } from '@/core/lib/i18n';
    import type { IBet } from '@/interface/IBet';
    import { MainBetStatus } from '@/interface/enum';

    const props = defineProps<{
        bet: IBet;
    }>();

    const { bet } = toRefs(props);
    const { rejectReasonDisplay, showTimeDisplay } = useBetDisplay(bet);

    const betStatus = computed(() => {
        if (bet.value.isWaitingReject) return MainBetStatus.WaitingRejected;
        return bet.value.mainBetStatus;
    });

</script>

<style lang="scss" scoped>
    @import "@/components/myBets/miniMyBets/BetDetail";
</style>
