import { i18n } from '@/core/lib/i18n';
import type { ISportInfo } from '@/interface/ISport';
import { SportType } from '@/interface/enum';

export class SportInfo implements ISportInfo {
    sportType: SportType;
    liveEventCount = 0;
    nonLiveEventCount = 0;
    outrightLiveEventCount = 0;
    outrightNonLiveEventCount = 0;
    children?: ISportInfo[] = [];
    todayExpandedCount = 0;
    onClick?(): void | Promise<void>;

    get name() {
        return i18n.t(`sport_type_name_${this.sportType}`);
    }

    get totalEventCount() {
        return this.liveEventCount + this.nonLiveEventCount + this.outrightLiveEventCount + this.outrightNonLiveEventCount;
    }

    get hasEvents() {
        return this.totalEventCount > 0;
    }

    get hasLiveEvents() {
        return this.liveEventCount > 0 || this.outrightLiveEventCount > 0;
    }

    get outrightTotalEventCount() {
        return this.outrightLiveEventCount + this.outrightNonLiveEventCount;
    }

    constructor(fields: Partial<Pick<ISportInfo, 'sportType' | 'liveEventCount' | 'nonLiveEventCount' | 'children' | 'onClick' | 'outrightLiveEventCount' | 'outrightNonLiveEventCount'>>) {
        this.sportType = fields.sportType ?? SportType.Unknown;
        this.liveEventCount = fields.liveEventCount ?? 0;
        this.nonLiveEventCount = fields.nonLiveEventCount ?? 0;
        this.outrightLiveEventCount = fields.outrightLiveEventCount ?? 0;
        this.outrightNonLiveEventCount = fields.outrightNonLiveEventCount ?? 0;
        this.children = fields.children;
        this.onClick = fields.onClick;
    }
}
