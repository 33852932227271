import { flow, orderBy } from 'lodash';
import { defineStore, storeToRefs } from 'pinia';
import { computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { oncePromise } from '@/composable/once';
import { _usePromotionZone } from '@/composable/usePromotionZone';
import { usePromotionZoneEventCount } from '@/composable/usePromotionZoneEventCount';
import { useSportsOrder } from '@/composable/useSportsOrder';
import { useState } from '@/composable/useState';
import { addDay, formatDate, getTodayWithGMT } from '@/core/lib/date';
import { i18n } from '@/core/lib/i18n';
import {
    addLiveCountToTodayCount,
    createMarketInfoIfNotExist,
    processDefaultSportsOrderPipe,
    processEsportsPipe,
    processExcludeSportPipe,
    processVirtualSportsPipe,
} from '@/core/lib/sportMarketInfoHelper';
import { useSportMarketInfo } from '@/core/oddsApi/composable/useSportMarketInfo';
import { getMarketPageFromPresetAndDate } from '@/core/oddsApi/helpers';
import type { IMarketInfo } from '@/interface/IMarketInfo';
import { EventDateType, MarketPage, OddsDaylightType, PromotionZone, RoutePage, SportType } from '@/interface/enum';
import { SportInfo } from '@/models/SportInfo';
import { useFavoriteStore } from '@/store/favoriteStore';

const marketPageEventDateOrder: Map<MarketPage, EventDateType[]> = new Map([
    [MarketPage.EarlyMarket, [
        EventDateType.Plus1,
        EventDateType.Plus2,
        EventDateType.Plus3,
        EventDateType.Plus4,
        EventDateType.Plus5,
        EventDateType.Plus6Over,
        EventDateType.All,
    ]],
    [MarketPage.MixParlay, [
        EventDateType.Today,
        EventDateType.Plus1,
        EventDateType.Plus2,
        EventDateType.Plus3,
        EventDateType.Plus4,
        EventDateType.Plus5,
        EventDateType.Plus6Over,
        EventDateType.All,
    ]],
    [MarketPage.MyFavorites, [
        EventDateType.Today,
        EventDateType.Plus1,
        EventDateType.Plus2,
        EventDateType.Plus3,
        EventDateType.Plus4,
        EventDateType.Plus5,
        EventDateType.Plus6Over,
        EventDateType.All,
    ]],
]);

const marketPageList = [MarketPage.Live, MarketPage.Today, MarketPage.EarlyMarket, MarketPage.MixParlay, MarketPage.Outright];

const daylightTypes = [OddsDaylightType.All, OddsDaylightType.Within3Hours, OddsDaylightType.Within6Hours];

export const useAsiaSportMarketInfoStore = defineStore('AsiaSportMarketInfo', () => {
    const { sportsOrder, defaultSportType } = useSportsOrder();
    const [sportType, _setSportType] = useState(defaultSportType.value);
    const [marketPage, _setMarketPage] = useState(MarketPage.Today);
    const [eventDate, setEventDate] = useState(EventDateType.Unknown);
    const [daylightType, setDaylightType] = useState(OddsDaylightType.All);

    const {
        promotionZone,
        setPromotionZone: _setPromotionZone,
        getPromotionZoneConfig,
        currentPromotionZoneConfig,
        multipleSportsZoneEventDateOrder,
    } = _usePromotionZone();

    const { sportList, mixParlaySportList, sportMarketData, loaded, loading, refetch } = useSportMarketInfo();

    const isPromotionZone = computed(() => promotionZone.value !== PromotionZone.None);
    const isMultipleSportsZone = computed(() => promotionZone.value === PromotionZone.MultipleSports);

    const isMixParlay = computed(() => marketPage.value === MarketPage.MixParlay);
    const isOutright = computed(() => marketPage.value === MarketPage.Outright);
    const isMyFavorite = computed(() => marketPage.value === MarketPage.MyFavorites);

    const { favoriteMarketListMap, favoritesSportList } = storeToRefs(useFavoriteStore());
    const { getFavoriteBySport } = useFavoriteStore();

    const filteredLeagueIds = computed(() => {
        if (isMyFavorite.value) {
            return getFavoriteBySport(sportType.value).leagueIds;
        }

        if (currentPromotionZoneConfig.value) {
            return currentPromotionZoneConfig.value.filteredLeagueIds ?? [];
        }

        return [];
    });

    const filteredLeagueNames = computed(() => {
        if (currentPromotionZoneConfig.value) {
            return currentPromotionZoneConfig.value.filteredLeagueNames ?? [];
        }

        return [];
    });

    const filteredEventIds = computed(() => {
        if (isMyFavorite.value) {
            return getFavoriteBySport(sportType.value).eventIds;
        }

        return [];
    });

    const {
        sportList: promotionZoneSportList,
        marketList: promotionZoneMarketList,
        loaded: promotionZoneLoaded,
        loading: promotionZoneLoading,
    } = usePromotionZoneEventCount({
        sportType,
        promotionZone,
        promotionZoneConfig: currentPromotionZoneConfig,
        filteredLeagueIds,
        filteredLeagueNames,
    });

    const isShowEventDateMarket = computed(() => [MarketPage.EarlyMarket, MarketPage.MixParlay, MarketPage.MyFavorites].includes(marketPage.value));
    const needToShowDaylightType = computed(() => loaded.value && marketPage.value === MarketPage.Today && sportType.value === SportType.Soccer);
    const needToShowEventDates = computed(() => loaded.value && isShowEventDateMarket.value);
    const needToShowMultipleSportsWithDates = computed(() => promotionZoneLoaded.value && isMultipleSportsZone.value);

    const marketListMap = computed<Map<SportType, IMarketInfo[]>>(() => {
        const list = new Map<SportType, IMarketInfo[]>();

        sportMarketData.value?.forEach(({ sport, queryFilter, liveEventCount, nonLiveEventCount }) => {
            const sportType = SportType.fromOddsApi(sport);
            if (!list.has(sportType)) {
                list.set(sportType, []);
            }

            const currentMarketList = list.get(sportType)!;
            const res = getMarketPageFromPresetAndDate(queryFilter.presetFilter, queryFilter.date);
            if (!res) return;

            const { marketPage, date } = res;

            currentMarketList.push({
                sportType,
                marketPage,
                date,
                nonLiveEventCount: Math.max(0, nonLiveEventCount),
                liveEventCount: Math.max(0, liveEventCount),
                totalEventCount: Math.max(0, nonLiveEventCount) + Math.max(0, liveEventCount),
            });
        });

        list.forEach((currentMarketList, sportType) => {
            marketPageList.forEach((marketPage) => {
                createMarketInfoIfNotExist(currentMarketList, sportType, marketPage);
            });

            addLiveCountToTodayCount(currentMarketList);
        });

        return list;
    });

    const processFlow = flow(
        processExcludeSportPipe,
        processVirtualSportsPipe,
        processEsportsPipe,
        processDefaultSportsOrderPipe,
    );
    const sportsInfoDisplay = computed(() => processFlow(sportList.value));

    const marketList = computed(() => {
        const currentMarketList = isPromotionZone.value
            ? promotionZoneMarketList.value.get(sportType.value) ?? []
            : marketListMap.value.get(sportType.value) ?? [];

        if (isMultipleSportsZone.value) {
            return currentMarketList.sort((a, b) => multipleSportsZoneEventDateOrder.indexOf(a.date) - multipleSportsZoneEventDateOrder.indexOf(b.date));
        }

        return currentMarketList
            .filter(x => x.date === EventDateType.All)
            .sort((a, b) => marketPageList.indexOf(a.marketPage) - marketPageList.indexOf(b.marketPage));
    });

    const marketListBySport = computed(() => {
        if (isPromotionZone.value) {
            return promotionZoneMarketList.value.get(sportType.value) ?? [];
        }

        if (isMyFavorite.value) {
            return favoriteMarketListMap.value.get(sportType.value) ?? [];
        }

        return marketListMap.value.get(sportType.value) ?? [];
    });

    const eventDates = computed(() => {
        if (!needToShowEventDates.value && !needToShowMultipleSportsWithDates.value) return [];

        const today = getTodayWithGMT(-4);
        const eventDateDisplayMap = {
            [EventDateType.Plus1]: formatDate(addDay(today, 1), 'MM/dd'),
            [EventDateType.Plus2]: formatDate(addDay(today, 2), 'MM/dd'),
            [EventDateType.Plus3]: formatDate(addDay(today, 3), 'MM/dd'),
            [EventDateType.Plus4]: formatDate(addDay(today, 4), 'MM/dd'),
            [EventDateType.Plus5]: formatDate(addDay(today, 5), 'MM/dd'),
            [EventDateType.Plus6]: formatDate(addDay(today, 6), 'MM/dd'),
            [EventDateType.Plus6Over]: i18n.t('onwards'),
            [EventDateType.All]: i18n.t('all_dates'),
            [EventDateType.Today]: i18n.t(`market_page_name_${MarketPage.Today}`),
            [EventDateType.Unknown]: '',
            [EventDateType.EarlyMarket]: '',
            [EventDateType.Plus2Over]: '',
            [EventDateType.Plus4Over]: '',
            [EventDateType.TodayExpanded]: '',
        };

        if (isMultipleSportsZone.value) {
            return marketListBySport.value
                .filter(x => multipleSportsZoneEventDateOrder.includes(x.date) && x.totalEventCount > 0)
                .map(x => ({
                    ...x,
                    eventCount: x.totalEventCount,
                    displayName: x.marketPage === MarketPage.Outright
                        ? i18n.t('market_page_name_5')
                        : eventDateDisplayMap[x.date] ?? eventDateDisplayMap[EventDateType.All],
                }))
                .sort((a, b) => multipleSportsZoneEventDateOrder.indexOf(a.date) - multipleSportsZoneEventDateOrder.indexOf(b.date));
        }

        const order = marketPageEventDateOrder.get(marketPage.value) ?? [];

        return marketListBySport.value
            .filter(x => x.marketPage === marketPage.value)
            .filter(x => order.includes(x.date) && x.totalEventCount > 0)
            .map(x => ({
                ...x,
                eventCount: x.totalEventCount,
                displayName: eventDateDisplayMap[x.date] ?? eventDateDisplayMap[EventDateType.All],
            }))
            .sort((a, b) => order.indexOf(a.date) - order.indexOf(b.date));
    });

    const setDefaultEventDate = () => {
        if (eventDates.value.length === 0) {
            setEventDate(EventDateType.Unknown);
            return;
        }
        const eventDateIndex = Math.max(eventDates.value.findIndex(x => x.eventCount > 0), 0);
        setEventDate(eventDates.value[eventDateIndex].date);
    };

    const setMarketPage = (market: MarketPage) => {
        _setMarketPage(market);
        if (needToShowMultipleSportsWithDates.value) {
            setDefaultEventDate();
        }
        if (needToShowDaylightType.value) {
            setDaylightType(OddsDaylightType.All);
        }
        if (needToShowEventDates.value) {
            setDefaultEventDate();
        }
        if (market === MarketPage.MixParlay) {
            _setPromotionZone(PromotionZone.None);
        }
    };

    const setDefaultMarketPage = async () => {
        const _loaded = computed(() => (isPromotionZone.value ? promotionZoneLoaded.value : loaded.value));
        const _loading = computed(() => (isPromotionZone.value ? promotionZoneLoading.value : loading.value));

        await Promise.all([oncePromise(_loaded, val => val === true), oncePromise(_loading, val => val === false)]);

        const firstValidMarketPage = marketList.value
            .filter(x => x.marketPage !== MarketPage.Live)
            .find(x => x.totalEventCount > 0);
        setMarketPage(firstValidMarketPage?.marketPage ?? MarketPage.Today);
    };

    const multipleSportsList = computed(() => promotionZoneSportList.value.filter(x => x.sportType === sportType.value || x.hasEvents));

    const router = useRouter();
    const isExchangePage = computed(() => router.currentRoute.value.name === RoutePage.Exchange);

    async function setSportType(newSportType: SportType, defaultMarket: MarketPage | null = null) {
        if (sportType.value === newSportType && !defaultMarket) {
            if (isMixParlay.value || isMyFavorite.value || isExchangePage.value) {
                await setDefaultMarketPage();
            }

            return;
        }

        _setSportType(newSportType);

        if (defaultMarket) {
            setMarketPage(defaultMarket);
        } else {
            await setDefaultMarketPage();
        }
    }

    const setPromotionZone = async (zone: PromotionZone) => {
        if (zone === promotionZone.value) return;

        _setPromotionZone(zone);

        const newPromotionZoneConfig = getPromotionZoneConfig(zone);
        const defaultSport = newPromotionZoneConfig?.defaultSport;
        if (defaultSport) {
            await setSportType(defaultSport);
        }
        await setDefaultMarketPage();
    };

    const singleCount = computed(() => {
        const marketPages = marketList.value.filter(x => ![MarketPage.Live, MarketPage.MixParlay].includes(x.marketPage));
        return marketPages.reduce((acc, cur) => acc + cur.totalEventCount, 0);
    });

    const mixParlayCount = computed(() => marketList.value.find(x => x.marketPage === MarketPage.MixParlay)?.totalEventCount ?? 0);

    setDefaultMarketPage();

    const mixParlaySportsInfoDisplay = computed(() => {
        const list = [...mixParlaySportList.value];
        sportsOrder.value.forEach((sportType) => {
            if (!list.find(x => x.sportType === sportType)) {
                list.push(new SportInfo({
                    sportType,
                    liveEventCount: 0,
                    nonLiveEventCount: 0,
                }));
            }
        });

        const filteredList = list.filter(x => x.sportType === sportType.value || x.totalEventCount > 0);
        return orderBy(filteredList, x => sportsOrder.value.indexOf(x.sportType));
    });

    const getFirstSportType = (sportsInfo: SportInfo[]) => sportsInfo.find(x => x.totalEventCount > 0)?.sportType ?? SportType.Soccer;
    const firstHasMixParlayCountSport = computed(() => getFirstSportType(mixParlaySportList.value));

    const favoriteSportsInfoDisplay = computed(() => {
        const filteredList = [...favoritesSportList.value].filter(x => x.totalEventCount > 0);
        return orderBy(filteredList, x => sportsOrder.value.indexOf(x.sportType));
    });
    const favoriteMarketList = computed(() => favoriteMarketListMap.value.get(sportType.value) ?? []);
    const favoriteCount = computed(() => favoriteSportsInfoDisplay.value.reduce((prev, curr) => (prev + curr.totalEventCount), 0));
    const firstHasFavoritesCountSport = computed(() => getFirstSportType(favoriteSportsInfoDisplay.value));

    watch(favoriteSportsInfoDisplay, () => {
        if (isMyFavorite.value && (favoriteSportsInfoDisplay.value.find(x => x.sportType === sportType.value)?.totalEventCount ?? 0) <= 0) {
            setSportType(firstHasFavoritesCountSport.value, MarketPage.MyFavorites);
        }
    });
    watch(favoriteMarketList, () => {
        if (isMyFavorite.value && (favoriteMarketList.value.find(x => x.date === eventDate.value)?.totalEventCount ?? 0) <= 0) {
            setDefaultEventDate();
        }
    });

    const navbarMarketList = computed(() => (isMyFavorite.value ? favoriteMarketList.value : marketList.value));

    return {
        loaded,
        promotionZone,
        setPromotionZone,
        getPromotionZoneConfig,
        currentPromotionZoneConfig,
        isPromotionZone,
        isMultipleSportsZone,
        filteredLeagueIds,
        filteredEventIds,
        filteredLeagueNames,
        sportType,
        setSportType,
        marketPage,
        setMarketPage,
        sportsInfoDisplay,
        mixParlaySportsInfoDisplay,
        marketList,
        marketListMap,
        loading,
        refetch,
        setDefaultMarketPage,
        isMixParlay,
        isOutright,
        eventDates,
        daylightTypes,
        daylightType,
        setDaylightType,
        eventDate,
        setEventDate,
        needToShowEventDates,
        needToShowDaylightType,
        singleCount,
        mixParlayCount,
        firstHasMixParlayCountSport,
        isMyFavorite,
        firstHasFavoritesCountSport,
        favoriteSportsInfoDisplay,
        favoriteCount,
        favoriteMarketList,
        navbarMarketList,
        multipleSportsList,
        needToShowMultipleSportsWithDates,
        defaultSportType,
    };
});
