import { useMutationObserver } from '@vueuse/core';

type IAdBlockElementRule =
{ tag: string, styles?: Partial<CSSStyleDeclaration> } |
{ id: string, isPrefix?: boolean } |
{ class: string }

const bodyWhiteList: IAdBlockElementRule[] = [
    { tag: 'script' },
    { tag: 'noscript' },
    { tag: 'svg' },
    { tag: 'iframe', styles: { display: 'none' } }, // distil fingerprinting
    { tag: 'div', styles: { position: 'fixed', zIndex: '2147483647', backgroundColor: 'rgba(65, 184, 131, 0.35)' } }, // vue devtool
    { tag: 'div', styles: { position: 'fixed', zIndex: '2147483647', border: '1px solid rgba(65, 184, 131, 0.5)' } }, // vue devtool
    { id: 'sports' },
    { id: 'v-tooltip-wrapper' },
    { id: 'ID-google-optimizer-editor' }, // GTM
    { id: 'gtm-debug-mask' }, // GTM
    { id: '_hjRemoteVarsFrame' }, // hotjar
    { id: '_hjSafeContext_', isPrefix: true }, // hotjar
    { id: 'expiredPassword' }, // expired Password
    { id: 'termAndCondition' }, // term and condition

    ...(__ENV_PROD__ ? [] : [
        { class: 'v-gtm-tooltip' },
    ]),
];

const bodyBlackList: IAdBlockElementRule[] = [
    { id: 'bdstat' },
];

const isElementMatchRule = (element: Element, rule: IAdBlockElementRule) => {
    if ('tag' in rule && isMatchTag(element, rule.tag) && isMatchStyles(element, rule.styles)) return true;
    if ('id' in rule && isMatchId(element, rule)) return true;
    if ('class' in rule && isMatchClass(element, rule.class)) return true;

    return false;
};

function isMatchTag(element: Element, tag: string) {
    return element.tagName.toUpperCase() === tag.toUpperCase();
}

function isMatchStyles(element: Element, styles?: Partial<CSSStyleDeclaration>) {
    if (!styles) return true;

    const elementStyles = (element as HTMLElement).style;
    const keys = Object.keys(styles) as (keyof CSSStyleDeclaration)[];
    return keys.every(key => elementStyles[key] === styles[key]);
}

function isMatchId(element: Element, rule: { id: string, isPrefix?: boolean }) {
    if (rule.isPrefix) return element.id.startsWith(rule.id);
    return rule.id === element.id;
}

function isMatchClass(element: Element, className: string) {
    return element.classList.contains(className);
}

function checkAndRemove() {
    const toRemove = [...document.body.children].filter((x) => {
        const isInWhiteList = bodyWhiteList.some(w => isElementMatchRule(x, w));
        const isInBlackList = bodyBlackList.some(b => isElementMatchRule(x, b));

        return !isInWhiteList || isInBlackList;
    });

    toRemove.forEach((x) => {
        x.remove();
        console.warn('Removed', x);
    });
}

export function useAdBlock() {
    useMutationObserver(
        document.body,
        () => checkAndRemove(),
        { childList: true },
    );

    checkAndRemove();
}
