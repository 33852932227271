<template>
    <VTableRow bgColor="white">
        <VTableCell>
            {{ displayBet.transId }}
        </VTableCell>
        <VTableCell>
            {{ transDate }}
        </VTableCell>
        <VTableCell
            v-bind:class="{
                del: isStakeBackToMainBet,
            }"
            align="right"
        >
            <strong>
                <div>
                    {{ stakeWithPrecision }}
                </div>
                <div class="subItem">
                    {{ actualStakeWithPrecision }}
                </div>
            </strong>
        </VTableCell>
        <VTableCell
            v-bind:class="{
                del: isAmended,
            }"
            align="right"
        >
            <strong>
                <div v-bind:class="displayBet.result < 0 ? 'negative' : ''">
                    {{ result }}
                </div>
                <div class="subItem">
                    {{ commission }}
                </div>
            </strong>
        </VTableCell>
    </VTableRow>
</template>

<script lang="ts" setup>
    import type { PropType } from 'vue';
    import { computed } from 'vue';
    import VTableCell from '@/components/common/table/VTableCell.vue';
    import VTableRow from '@/components/common/table/VTableRow.vue';
    import { formatToServerTime } from '@/core/lib/date';
    import type { IPartialCashOutBet } from '@/interface/IBet';
    import { useCustomerStore } from '@/store/customerStore';

    const props = defineProps({
        bets: {
            type: Object as PropType<IPartialCashOutBet[]>,
            required: true,
        },
    });

    const displayBet = computed(() => {
        const voidBetWithOverZeroStake = props.bets.find(bet => !bet.isResettled && bet.isVoid && bet.stake > 0);
        const cashedOutBet = props.bets.find(bet => !bet.isResettled && bet.isCashedOut);
        const amendedBet = props.bets.find(bet => bet.isResettled && bet.stake > 0);

        return voidBetWithOverZeroStake ?? cashedOutBet ?? amendedBet ?? props.bets[0];
    });

    const transDate = computed(() => formatToServerTime(displayBet.value.transDate, 'MM/dd/yyyy HH:mm:ss'));

    const isStakeBackToMainBet = computed(() => props.bets.some(bet => !bet.isResettled && bet.isVoid && bet.stake === 0));
    const isAmended = computed(() => displayBet.value.isResettled && displayBet.value.stake > 0);

    const { formatMoneyWithDecimal } = useCustomerStore();
    const stakeWithPrecision = computed(() => formatMoneyWithDecimal(displayBet.value.stake));
    const actualStakeWithPrecision = computed(() => formatMoneyWithDecimal(displayBet.value.actualStake));
    const result = computed(() => formatMoneyWithDecimal(displayBet.value.result));
    const commission = computed(() => formatMoneyWithDecimal(displayBet.value.commission));
</script>

<style lang="scss" scoped>
    @import "@/components/myBets/PartialCashOutRow";
</style>
