import {
    ApolloClient,
    ApolloLink,
    InMemoryCache,
    Observable,
    createHttpLink,
    from,
    split,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { RetryLink } from '@apollo/client/link/retry';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { ApolloClients, DefaultApolloClient, provideApolloClients } from '@vue/apollo-composable';
import sha256 from 'crypto-js/sha256';
import { print } from 'graphql';
import { createClient } from 'graphql-ws';
import { memoize } from 'lodash';
import type { App } from 'vue';
import { ref } from 'vue';
import { Api } from '@/core/lib/api';
import { formatDate } from '@/core/lib/date';
import { getOddsApiUrl, getOddsApiWsUrl } from '@/core/lib/url';
import { isSupportedDeviceForWs } from '@/core/lib/utils';
import { useToggleStore } from '@/store/toggleStore';

window.isUseTokenPrefix = false;

const GET_TOKEN_RETRY_COUNT = 5;

let oddsApiToken: string | null = null;
type OddApiStatus = 'NO_TOKEN' | 'LOADED' | 'LOADING' | 'ERRORED'
let oddsApiStatus: OddApiStatus = 'NO_TOKEN';

let unauthenticatedCount = 0;

const startTime = new Date().getTime();
function getPageOpenedMinutes() {
    try {
        return `${~~(((new Date().getTime()) - startTime) / 1000 / 60)}m`;
    } catch (error) {
        return '--m';
    }
}

function debugLog(title: string, message: any) {
    const timeStamp = formatDate(new Date(), 'HH:mm:ss');
    console.warn(`[${timeStamp}][${getPageOpenedMinutes()}] ${title}: ${message}`);
}

const pendingResolves: Array<VoidFunction> = [];

const wait = (ms: number) => new Promise((r) => {
    window.setTimeout(r, ms);
});
function withRetry<T>(fn: () => Promise<T>, retries: number, delay = 0, error = null): Promise<T> {
    if (retries === 0) return Promise.reject(error);
    return fn().catch(err => wait(delay).then(() => withRetry(fn, (retries - 1), delay, err)));
}

async function refreshOddsApiToken() {
    await withRetry(() => Api.getOddsApiToken(), GET_TOKEN_RETRY_COUNT, 1000)
        .then(({ data: newToken }) => {
            oddsApiStatus = 'LOADED';
            oddsApiToken = newToken;

            pendingResolves.forEach(req => req());

            registerNextAutoRefreshToken();
        })
        .catch((err) => {
            oddsApiStatus = 'ERRORED';
            console.error('get OddsApi token failed', err);

            /**
             * Wait for 15 seconds before retrying to avoid
             * getting the token immediately because the
             * server may be down.
             */
            window.setTimeout(() => {
                if (oddsApiStatus === 'ERRORED') {
                    oddsApiStatus = 'NO_TOKEN';
                    getOddsApiToken();
                }
            }, 15 * 1000);

            throw Error(`GetOddsApiToken failed: ${err.message}`);
        })
        .finally(() => {
            pendingResolves.length = 0;
        });
}

const turnstileNoChallenge = memoize(() => Promise.resolve(true));

export type ChallengeStatus = 'NONE' | 'PENDING' | 'SUCCESS' | 'ERROR';

export const challengeStatus = ref<ChallengeStatus>('NONE');

const turnstileChallenge = memoize((): Promise<boolean> => {
    const { oddsApiUrl } = useToggleStore();
    const iframeUrl = `${getOddsApiUrl(oddsApiUrl)}/pong`;
    const iframe = document.createElement('iframe');
    iframe.setAttribute('src', iframeUrl);
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
    challengeStatus.value = 'PENDING';

    return new Promise((resolve, reject) => {
        const messageListener = ({ data = {} }: { data: any }) => {
            const { type, action } = data;

            if (type === 'challenge') {
                if (action === 'ready') {
                    iframe.contentWindow!.postMessage({ type: 'challenge', action: 'verify' }, '*');
                } else if (action === 'success') {
                    window.removeEventListener('message', messageListener);
                    challengeStatus.value = 'SUCCESS';
                    resolve(true);
                } else if (action === 'error') {
                    window.removeEventListener('message', messageListener);
                    challengeStatus.value = 'ERROR';
                    reject(data.error);
                }
            }
        };

        window.addEventListener('message', messageListener, false);
    });
});

let needChallenge = false;
function waitTurnstile() {
    return __ENABLE_TURNSTILE__ && needChallenge && navigator.onLine ? turnstileChallenge() : turnstileNoChallenge();
}

export async function getOddsApiToken() {
    const oddsApiTokenPromise = new Promise((resolve) => {
        switch (oddsApiStatus) {
            case 'LOADED':
                resolve(oddsApiToken);
                break;
            case 'LOADING':
                pendingResolves.push(() => resolve(oddsApiToken));
                break;
            case 'NO_TOKEN':
                pendingResolves.push(() => resolve(oddsApiToken));

                oddsApiStatus = 'LOADING';
                refreshOddsApiToken();
                break;
            case 'ERRORED':
            default:
                throw Error('GetOddsApiToken died');
        }
    });
    const [token] = await Promise.all([oddsApiTokenPromise, waitTurnstile()]);
    return token;
}

window.addEventListener('offline', () => {
    if (__ENV_PROD__) debugLog('network', 'offline');
});

// update token when user [disconnected] -> [connected]
window.addEventListener('online', () => {
    if (__ENV_PROD__) debugLog('network', 'online');

    if (oddsApiStatus === 'ERRORED') {
        oddsApiStatus = 'NO_TOKEN';
        getOddsApiToken();
    }
});

// update token when the page [invisible] -> [visible]
window.addEventListener('visibilitychange', () => {
    if (__ENV_PROD__) debugLog('visibility', document.visibilityState);
    if (document.visibilityState === 'visible' && oddsApiStatus === 'ERRORED') {
        oddsApiStatus = 'NO_TOKEN';
        getOddsApiToken();
    }
});

let refreshTokenTimeout: number;
/**
 * Register a timeout(10 mins) to refresh token before it expires (15 mins)
 */
function registerNextAutoRefreshToken() {
    window.clearTimeout(refreshTokenTimeout);

    refreshTokenTimeout = window.setTimeout(() => {
        refreshOddsApiToken();
    }, 10 * 60 * 1000); // 10 minutes
}

export const isWsConnected = ref(false);

const apolloClients: Record<string, ApolloClient<any>> = {};
function createApolloClient({
    clientId,
    queryUrl,
    wsUrl,
    isEnableWebSocket,
}: {
    clientId: string;
    queryUrl: string;
    wsUrl: string;
    isEnableWebSocket: boolean;
}) {
    if (apolloClients[clientId]) return apolloClients[clientId];

    const PREFIX = 'YplbcXy';
    const authLink = setContext(async () => {
        const _token = await getOddsApiToken();
        const token = window.isUseTokenPrefix ? PREFIX + _token : _token;
        return {
            headers: {
                authorization: token,
            },
        };
    });

    const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
        if (!__ENV_PROD__) {
            if (graphQLErrors) {
                graphQLErrors.forEach((error) => {
                    console.error(`[GraphQL error]: Message: ${error.message}`, error);
                });
            }
        }

        const errorCode = graphQLErrors?.[0]?.extensions?.code;
        switch (errorCode) {
            case 'UNAUTHENTICATED':
                debugLog('UNAUTHENTICATED', ++unauthenticatedCount);

                if (oddsApiStatus === 'LOADED') {
                    oddsApiStatus = 'NO_TOKEN';
                }
                getOddsApiToken();
                break;
            case 'BAD_ENCODE_INPUT':
                window.isUseTokenPrefix = true;
                break;
            case 'BAD_TOKEN_INPUT':
                window.isUseTokenPrefix = false;
                break;
            default:
                break;
        }

        // networkError means
        // 1. network is down
        // 2. request is blocked by cloudflare
        //   - we have no way to verify if the request need to pass
        //     the turnstile challenge, so we assume it needs to.
        // 3. server error response
        //   - we exclude such errors by checking for existence of graphQLErrors returned by server
        if (networkError && !graphQLErrors) {
            if (__ENABLE_TURNSTILE__ && navigator.onLine) {
                needChallenge = true;
            }
        }

        return forward(operation);
    });

    const persistedQueryLink = createPersistedQueryLink({
        generateHash: document => sha256(print(document)).toString(),
        useGETForHashedQueries: true,
    });

    const httpLink = createHttpLink({
        uri: getOddsApiUrl(queryUrl),
        credentials: 'include',
    });

    const wsClient = createClient({
        url: getOddsApiWsUrl(wsUrl),
        connectionParams: async () => {
            const _token = await getOddsApiToken();
            const token = window.isUseTokenPrefix ? PREFIX + _token : _token;
            // payload in connection_init
            return ({
                authorization: token,
            });
        },
        shouldRetry: () => true,
        retryAttempts: Infinity,
        lazy: true,
        on: {
            error: () => {
                isWsConnected.value = false;
            },
            closed: () => {
                isWsConnected.value = false;
            },
            connecting: () => {
                isWsConnected.value = false;
            },
            connected: () => {
                isWsConnected.value = true;
            },
        },
    });

    const wsLink = split(
        () => !__MOCK_ODDS_API__ && isEnableWebSocket && !!wsUrl,
        new GraphQLWsLink(wsClient),
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        new ApolloLink(() => new Observable(() => {})),
    );

    const retryLink = new RetryLink({
        delay: {
            initial: 200,
            max: 1000,
            jitter: true,
        },
        attempts: {
            max: 3,
        // retryIf: (error, _operation) => !!error,
        },
    });

    const requestLink = __MOCK_ODDS_API__
        ? httpLink
        : from([
            persistedQueryLink,
            httpLink,
        ]);

    const terminateLink = split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return definition.kind === 'OperationDefinition'
            && definition.operation === 'subscription';
        },
        wsLink,
        requestLink,
    );

    const apolloClient = new ApolloClient({
        link: from([
            errorLink,
            authLink,
            retryLink,
            terminateLink,
        ]),
        cache: new InMemoryCache({
            addTypename: false,
        }),
        defaultOptions: {
            query: {
                fetchPolicy: 'no-cache',
            },
            watchQuery: {
                fetchPolicy: 'no-cache',
            },
        },
        connectToDevTools: __ENV_DEV__,
    });

    apolloClients[clientId] = apolloClient;

    return apolloClient;
}

export function setupApollo(app: App) {
    const { oddsApiUrl, oddsApiWsUrl, betBuilderApiUrl, betBuilderWsUrl } = useToggleStore();
    const isEnableWebSocket = isSupportedDeviceForWs();
    const oddsApiApolloClient = createApolloClient({
        clientId: 'oddsApi',
        queryUrl: `${oddsApiUrl}/api`,
        wsUrl: oddsApiWsUrl
            ? `${oddsApiWsUrl}/ws`
            : '',
        isEnableWebSocket,
    });
    const betBuilderApolloClient = createApolloClient({
        clientId: 'betBuilder',
        queryUrl: `${betBuilderApiUrl}/graphql`,
        wsUrl: betBuilderWsUrl
            ? `${betBuilderWsUrl}/graphql/ws`
            : '',
        isEnableWebSocket,
    });

    provideApolloClients({
        default: oddsApiApolloClient,
        oddsApi: oddsApiApolloClient,
        betBuilder: betBuilderApolloClient,
    });

    app.provide(DefaultApolloClient, oddsApiApolloClient);
    app.provide(ApolloClients, {
        default: oddsApiApolloClient,
        oddsApi: oddsApiApolloClient,
        betBuilder: betBuilderApolloClient,
    });
}
