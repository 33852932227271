import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useToggleStore } from '../store/toggleStore';
import type { IBetRadar } from '@/interface/IEvent';
import { BetRadarStreamStatus, BrandType, LicenseType, PlayerType, SportType } from '@/interface/enum';
import { useCustomerStore } from '@/store/customerStore';

// TODO: refactor with open-close principle to separate provider implementations
export const useStreamRestriction = () => {
    const { playerType, sboCurrency, ipCountryCode, licenseType, brandType } = storeToRefs(useCustomerStore());
    const { isGLiveStreamEnabled, gLiveStreamRestrictions, isInLiveStreamWhiteList, isAvApiLiveStreamEnabled } = storeToRefs(useToggleStore());

    const allowMuayThaiLiveStream = (sportType: SportType) => sportType === SportType.MuayThai && licenseType.value !== LicenseType.IOM;

    const allowBetRadarLiveStream = (allowedCountries: Array<string>) => {
        if (!isAvApiLiveStreamEnabled.value) return false;
        return isInLiveStreamWhiteList.value || allowedCountries.includes(ipCountryCode.value);
    };

    const allowBetConstructLiveStream = (allowedCountries: Array<string>) => allowedCountries.includes(ipCountryCode.value);

    const gLiveValidRestrictionGroups = computed(() => gLiveStreamRestrictions.value
        .map(rawGroup => parseGLiveGroupRestrict(rawGroup))
        .filter(group => validate(group.licenses, LicenseType[licenseType.value])
        && validate(group.userTypes, PlayerType[playerType.value])
        && validate(group.brandTypes, BrandType[brandType.value])
        && validate(group.currencies, sboCurrency.value)
        && validate(group.countries, ipCountryCode.value)));

    const allowGLiveStream = (leagueSize: string) => {
        if (!isGLiveStreamEnabled.value) return false;

        return gLiveValidRestrictionGroups.value.some(group => validate(group.leagueSizes, leagueSize));
    };

    const isBetRadarMapped = (betRadar: IBetRadar | null) => {
        if (!betRadar) return false;

        const status = [BetRadarStreamStatus.OnAirBroadcast, BetRadarStreamStatus.OnAirReady, BetRadarStreamStatus.Approved];
        return status.includes(betRadar.streamStatus);
    };

    return {
        allowBetRadarLiveStream,
        allowGLiveStream,
        allowMuayThaiLiveStream,
        allowBetConstructLiveStream,
        isBetRadarMapped,
    };
};

type gLivesGroupRestrictionType = {
    licenses: string[];
    userTypes: string[];
    currencies: string[];
    countries: string[];
    leagueSizes: string[];
    brandTypes: string[];
};

function validate(restriction: string[], value: string): boolean {
    return restriction.includes('*') || restriction.some(x => x.toUpperCase() === value?.toUpperCase());
}

function parseGLiveGroupRestrict(rawGroup: string): gLivesGroupRestrictionType {
    // rawGroup format = `License:UserType:Currency:IpCountry:LeagueSize:BrandType`
    const [license, userType, currencies, countries, leagueSizes, brandTypes] = rawGroup?.toUpperCase().split(':') ?? [];
    return {
        licenses: license?.split(',') ?? [],
        userTypes: userType?.split(',') ?? [],
        currencies: currencies?.split(',') ?? [],
        countries: countries?.split(',') ?? [],
        leagueSizes: leagueSizes?.split(',') ?? [],
        brandTypes: brandTypes?.split(',') ?? [],
    };
}
