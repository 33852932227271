<template>
    <div v-if="isShowPopup && winner" class="jackpotWinnerPopUpWrapper">
        <div class="jackpotWinnerPopUp">
            <div class="jackpotWinnerPopUp_header" />
            <div class="jackpotWinnerPopUp_content">
                <i>
                    {{ i18n.t('jackpot_congratulations') }}
                </i>
                <I18nT
                    tag="div"
                    class="jackpotWinnerInfo"
                    keypath="jackpot_youWon"
                >
                    <template #jackpot>
                        <div class="highlight highlight-primary">
                            <strong>
                                {{ i18n.t(`jackpot_${winner.type.toLowerCase()}Jackpot`) }}
                            </strong>
                        </div>
                    </template>
                    <template #currency>
                        <strong class="highlight">
                            {{ sboCurrency }}
                        </strong>
                    </template>
                    <template #amount>
                        <strong class="highlight">
                            {{ formatMoneyWithDecimal(winner.amount) }}
                        </strong>
                    </template>
                </I18nT>
                <a
                    v-if="isShowTncLink"
                    class="jackpotLinkTnC"
                    v-bind:href="tncLink"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {{ i18n.t('jackpot_tnc_link') }}
                </a>
                <button
                    class="claimButton"
                    type="button"
                    v-on:click="claim()"
                >
                    <strong>
                        {{ i18n.t('jackpot_claimNow') }}
                    </strong>
                </button>
            </div>
            <div class="bgDeco">
                <div class="ray ray-1" />
                <div class="ray ray-2" />
                <span
                    v-for="i in 20"
                    v-bind:key="i"
                    v-bind:class="`shiny shiny-${i}`"
                />
                <Vue3Lottie class="bgDeco_coins" v-bind:animationData="animationData" />
            </div>
        </div>
    </div>

    <FadeTransition>
        <Notification
            v-if="notification"
            class="notification"
            v-bind:type="notification.type"
            v-bind:icon="notification.icon"
        >
            {{ notification.message }}
        </Notification>
    </FadeTransition>
</template>

<script lang="ts" setup>
    import { autoResetRef } from '@vueuse/core';
    import { storeToRefs } from 'pinia';
    import { computed, ref, watch, watchEffect } from 'vue';
    import { Vue3Lottie } from 'vue3-lottie';
    import Notification from '../common/Notification.vue';
    import FadeTransition from '../transitions/FadeTransition.vue';
    import animationData from '@/assets/images/jackpot/lottie/coinSpray.json';
    import { useWinner } from '@/composable/useJackpotData';
    import { usePlatform } from '@/composable/usePlatform';
    import { Api } from '@/core/lib/api';
    import { i18n } from '@/core/lib/i18n';
    import { getInfoCenterUrl } from '@/core/lib/url';
    import { BrandType, EnumLanguage, PlayerType } from '@/interface/enum';
    import { TriggerResult } from '@/interface/jackpot';
    import { useCustomerStore } from '@/store/customerStore';
    import { useScrollableStore } from '@/store/scrollableStore';

    const { winner } = useWinner();

    const { sboCurrency, playerType } = storeToRefs(useCustomerStore());
    const { formatMoneyWithDecimal } = useCustomerStore();

    const { setIsScrollable } = useScrollableStore();
    const isShowPopup = ref(false);
    watchEffect(() => {
        if (winner.value) {
            isShowPopup.value = true;
        }
    });

    watch(isShowPopup, (isShow) => {
        if (isShow) {
            setIsScrollable(false);
        } else {
            setIsScrollable(true);
        }
    });

    const notification = autoResetRef<{
        type: 'success' | 'info' | 'warning' | 'error';
        icon: string;
        message: string;
    } | null>(null, 5000);

    const { refreshBalanceAfterAction } = usePlatform();
    const claim = async () => {
        isShowPopup.value = false;

        try {
            if (!winner.value) throw new Error('No winner data');

            const triggerResult = await Api.claimJackpot(winner.value.type, winner.value.round);
            if (triggerResult !== TriggerResult.Success) throw new Error(`claimJackpot: ${TriggerResult[triggerResult]}`);

            notification.value = {
                type: 'success',
                icon: 'icon-system-checkCircle',
                message: i18n.t('jackpot_claimSuccess'),
            };
            refreshBalanceAfterAction();
        } catch (err) {
            console.error(err);

            notification.value = {
                type: 'error',
                icon: 'icon-system-warning',
                message: i18n.t('jackpot_tryAgainLater'),
            };
        }
    };

    const { brandType, isMainDomain } = storeToRefs(useCustomerStore());
    const paths: Partial<Record<BrandType, Partial<Record<EnumLanguage, string>>>> = {
        [BrandType.Sbobet]: {
            [EnumLanguage.EN]: '/article/12430.html',
            [EnumLanguage.MY_MM]: '/article/12432.html',
            [EnumLanguage.ZH_CN]: '/article/12433.html',
            [EnumLanguage.ID_ID]: '/article/12434.html',
            [EnumLanguage.JA_JP]: '/article/12435.html',
            [EnumLanguage.KO_KR]: '/article/12436.html',
            [EnumLanguage.PT_BR]: '/article/12437.html',
            [EnumLanguage.TH_TH]: '/article/12438.html',
            [EnumLanguage.VI_VN]: '/article/12439.html',
            [EnumLanguage.KM_KH]: '/article/12430.html',
            [EnumLanguage.BN_BD]: '/article/12430.html',
        },
        [BrandType.Sbotop]: {
            [EnumLanguage.EN]: '/article/12645.html',
            [EnumLanguage.MY_MM]: '/article/12647.html',
            [EnumLanguage.ZH_CN]: '/article/12648.html',
            [EnumLanguage.ID_ID]: '/article/12649.html',
            [EnumLanguage.JA_JP]: '/article/12650.html',
            [EnumLanguage.KO_KR]: '/article/12651.html',
            [EnumLanguage.PT_BR]: '/article/12652.html',
            [EnumLanguage.TH_TH]: '/article/12653.html',
            [EnumLanguage.VI_VN]: '/article/12654.html',
            [EnumLanguage.KM_KH]: '/article/12645.html',
            [EnumLanguage.BN_BD]: '/article/12645.html',
        },
    };
    const tncLink = computed(() => {
        const infoCenterUrl = getInfoCenterUrl(brandType.value, isMainDomain.value);
        const path = paths[brandType.value]?.[i18n.locale.value as EnumLanguage] ?? paths[brandType.value]?.[EnumLanguage.EN] ?? '#';
        return `${infoCenterUrl}${path}`;
    });
    const isShowTncLink = computed(() => playerType.value === PlayerType.B2C);
</script>

<style lang="scss" scoped>
@import "@/components/jackpot/JackpotWinnerPopup";
</style>
