<template>
    <label class="toggleSwitch">
        <input
            type="checkbox"
            class="toggleSwitch_toggle"
            v-bind:checked="modelValue"
            v-on:change="() => emit('update:modelValue', !modelValue)"
        >
        <div class="toggleSwitch_text">
            <template v-if="text">
                {{ text }}
            </template>
            <slot v-else name="text" />
        </div>
    </label>
</template>

<script lang="ts" setup>
    defineProps({
        text: {
            type: String,
            default: '',
        },
        modelValue: {
            type: Boolean,
            default: false,
        },
    });

    const emit = defineEmits(['update:modelValue']);
</script>

<style lang="scss" scoped>
    @import "@/components/common/toggleSwitch/ToggleSwitch";
</style>
