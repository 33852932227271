import type { Ref } from 'vue';
import { computed } from 'vue';
import type { IDynamicI18nConfig } from '@/interface/IDynamicI18nConfig';
import type { IBetBuilderTicket } from '@/interface/betBuilder';
import { useToggleStore } from '@/store/toggleStore';

export function useBetBuilderInvalidMessageDisplay(tickets: Ref<(IBetBuilderTicket[])>): {
    isValidSelectionCount: Ref<boolean>;
    invalidSelectionMessage: Ref<IDynamicI18nConfig | null>;
} {
    const { getBetBuilderMinSelectionCount } = useToggleStore();
    const minSelectionCount = computed(() => getBetBuilderMinSelectionCount(tickets.value.length > 0 ? tickets.value[0]?.match.league.id : 0));
    const isValidSelectionCount = computed(() => tickets.value.length >= minSelectionCount.value);

    const invalidSelectionMessage = computed<IDynamicI18nConfig | null>(() => {
        if (isValidSelectionCount.value) {
            return null;
        }

        return {
            key: 'bet_builder_insufficient_selections',
            slots: [{
                name: 'n',
                content: minSelectionCount.value.toString(),
            }],
        };
    });

    return {
        isValidSelectionCount,
        invalidSelectionMessage,
    };
}
