<template>
    <div
        v-if="outrightOdds.length > 0 && league.name"
        class="outright"
        v-bind:class="['width-full', { _live: event.isLive }]"
    >
        <strong class="outright-title" v-bind:class="{ _live: event.isLive }">
            {{ league.name }}
        </strong>
        <div class="outright-content">
            <div
                v-for="(columnOdds, index) in allColumnOdds"
                v-bind:key="index"
                class="outright-odd-display"
            >
                <div
                    v-for="odds in columnOdds"
                    v-bind:key="odds.id"
                    class="odds"
                >
                    <div class="odds-team-name" v-bind:class="{ _live: event.isLive }">
                        {{ odds.teamName }}
                    </div>
                    <div class="outright-league-odds-odds-content">
                        <OutrightOdds
                            v-bind:league="league"
                            v-bind:oddsInfo="odds"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import orderBy from 'lodash/orderBy';
    import { computed } from 'vue';
    import OutrightOdds from '@/components/odds/OutrightOdds.vue';
    import { useOutrightOdds } from '@/core/oddsApi/composable/useOutrightOdds';
    import type { IEvent } from '@/interface/IEvent';
    import type { IOutrightLeague } from '@/interface/IOutrightLeague';

    const props = defineProps<{
        event: IEvent;
        league: IOutrightLeague;
    }>();

    const { outrightOdds } = useOutrightOdds(props.event.sportType, props.league.id, props.event.isLive);
    const formattedOutrightOdds = computed(() => orderBy(outrightOdds.value, [x => x.price, x => x.teamName?.toUpperCase()]));

    const allColumnOdds = computed(() => {
        const columnOddsLimit = Math.ceil(formattedOutrightOdds.value.length / 3);
        const leftColumnOdds = formattedOutrightOdds.value.slice(columnOddsLimit * 0, columnOddsLimit * 1);
        const centerColumnOdds = formattedOutrightOdds.value.slice(columnOddsLimit * 1, columnOddsLimit * 2);
        const rightColumnOdds = formattedOutrightOdds.value.slice(columnOddsLimit * 2, columnOddsLimit * 3);
        return [leftColumnOdds, centerColumnOdds, rightColumnOdds];
    });
</script>

<style lang="scss" scoped>
    @import "@/components/oddsDisplay/moreMarket/marketType/Outright";
</style>
