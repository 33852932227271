<template>
    <div v-gtm:page="'LiveStream'" class="liveStream">
        <div
            class="liveStream_leftPanel"
            v-bind:style="leftPanelStyle"
        >
            <div class="liveStream_leftPanel_header">
                <SvgIcon
                    name="icon-system-tvPlay"
                    size="xl"
                    color="#062f6e"
                />
                <div
                    class="liveStream_leftPanel_header_toggle"
                    v-bind:class="{ isExpand: isExpand }"
                    v-on:click="toggleExpand"
                >
                    <SvgIcon class="arrow_icon" name="icon-system-arrow" />
                </div>
            </div>

            <div ref="outerBox" class="liveStream_leftPanel_content">
                <div v-if="!streamDisplay" v-bind:class="`logoCover logoCover_${brandName}`" />
                <div ref="innerBox" class="liveStream_leftPanel_contentInner">
                    <component
                        v-bind:is="streamDisplay.component"
                        v-if="streamDisplay"
                        v-bind:key="streamDisplay.key"
                        v-bind:videoUrl="streamDisplay.videoUrl"
                        v-bind:betRadar="streamDisplay.betRadar!"
                        v-bind:gLive="streamDisplay.gLive!"
                        v-bind:betConstruct="streamDisplay.betConstruct"
                    />
                    <div class="liveStream_leftPanel_contentInner_overlay" />
                    <Transition name="slideIn">
                        <div
                            v-show="isShowFloatingBanner"
                            v-gtm:type="'Banner'"
                            v-gtm:@view
                            class="liveStream_leftPanel_banner_wrapper"
                        >
                            <p class="liveStream_leftPanel_banner_title">
                                {{ i18n.t('live_stream_banner_title') }}
                            </p>
                            <div
                                v-if="isLiveStreamBanner1Enabled"
                                v-gtm:label="'LiveCasinoBaccarat'"
                                v-gtm:@view
                                v-gtm:@click
                                class="liveStream_leftPanel_banner_item"
                                v-bind:style="{
                                    backgroundImage: `url(${bannerImageUrl1})`,
                                }"
                                v-on:click="openCasinoGame(casinoLiveCasino)"
                            />
                            <div
                                v-if="isLiveStreamBanner2Enabled"
                                v-gtm:label="'GatesOfOlympus'"
                                v-gtm:@view
                                v-gtm:@click
                                class="liveStream_leftPanel_banner_item"
                                v-bind:style="{
                                    backgroundImage: `url(${bannerImageUrl2})`,
                                }"
                                v-on:click="openGame(gatesOfOlympusGame)"
                            />
                            <div
                                v-if="!isLiveStreamBanner1Enabled || !isLiveStreamBanner2Enabled"
                                class="liveStream_leftPanel_banner_item liveStream_leftPanel_banner_item_disabled"
                            />
                            <VButton
                                v-gtm:label="'CloseBtn'"
                                v-gtm:@click
                                flat
                                padding="4px"
                                color="white"
                                v-on:click="setIsShowFloatingBanner(false)"
                            >
                                <template #icon>
                                    <SvgIcon
                                        name="icon-system-close"
                                        size="md"
                                        color="white"
                                    />
                                </template>
                            </VButton>
                        </div>
                    </Transition>
                </div>
            </div>
            <div class="liveStream_leftPanel_footer">
                <template v-if="selectedStreamEvent">
                    <Jersey v-bind:jersey="selectedStreamEvent.homeTeam.jersey" />
                    <span v-bind:class="selectedStreamEvent.homeTeam.isFavorite ? 'favorite' : 'underdog'">
                        {{ selectedStreamEvent.homeTeam.name }}
                    </span>
                    <span v-bind:class="selectedStreamEvent.awayTeam.isFavorite ? 'favorite' : 'underdog'">
                        {{ selectedStreamEvent.awayTeam.name }}
                    </span>
                    <Jersey v-bind:jersey="selectedStreamEvent.awayTeam.jersey" />
                </template>
            </div>
        </div>
        <div
            ref="rightPanelEl"
            v-resize="onResizeRightPanel"
            class="liveStream_rightPanel"
            v-bind:style="rightPanelStyle"
        >
            <div class="liveStream_rightPanel_toolbar">
                <DropDown
                    v-gtm:type="'SelectSportType'"
                    v-bind:value="selectedSport"
                    v-bind:list="sportList"
                    v-on:update="setSelectSport"
                />

                <VButton
                    v-gtm:type="'Header'"
                    v-gtm:label="'Refresh'"
                    v-gtm:@click
                    tile
                    color="white"
                    class="refresh"
                    padding="4px 8px"
                    v-on:click="refresh(true)"
                >
                    <SvgIcon
                        name="icon-system-refresh"
                        size="16"
                        left
                        v-bind:rotating="isRefreshing"
                    />
                    {{ countdown }}
                </VButton>
            </div>
            <SubTabs v-model="activeTab" style="height: 100%;">
                <SubTab
                    v-gtm:type="'Header'"
                    v-gtm:label="'MyVideos'"
                    v-gtm:@click
                    v-bind:name="TabType.MyVideos"
                    v-bind:label="`${i18n.t('video_scheduler_my_video')}`"
                    v-bind:count="filteredMyStreamEvents.length"
                />
                <SubTab
                    v-gtm:type="'Header'"
                    v-gtm:label="'Schedule'"
                    v-gtm:@click
                    v-bind:name="TabType.Schedule"
                    v-bind:label="`${i18n.t('video_scheduler')}`"
                    v-bind:count="filteredStreamEvents.length"
                />
                <template #All>
                    <div class="liveStream_schedule">
                        <StaticSpinner v-if="!loaded" class="loading" />
                        <div v-else-if="hasNoVideo" class="liveStream_schedule-empty">
                            <span>{{ i18n.t('video_scheduler_no_video') }}</span>
                        </div>
                        <dl
                            v-for="category in streamCategories"
                            v-else
                            v-bind:key="category.name"
                        >
                            <dt
                                class="schedule_category"
                                v-bind:class="{ live: category.streams[0].isLive }"
                            >
                                {{ category.name }}
                            </dt>
                            <LiveStreamEvent
                                v-for="stream in category.streams"
                                v-bind:key="stream.eventId"
                                v-bind:stream="stream"
                                class="schedule_event"
                                v-bind:class="{ live: category.streams[0].isLive }"
                                v-on:click="setSelectedEventId(stream.eventId)"
                            />
                        </dl>
                    </div>
                </template>
            </SubTabs>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { useThrottleFn } from '@vueuse/core';
    import entries from 'lodash/fp/entries';
    import flow from 'lodash/fp/flow';
    import groupBy from 'lodash/fp/groupBy';
    import map from 'lodash/fp/map';
    import sortBy from 'lodash/fp/sortBy';
    import { storeToRefs } from 'pinia';
    import { computed, ref, watch } from 'vue';
    import Jersey from '@/components/common/Jersey.vue';
    import StaticSpinner from '@/components/common/StaticSpinner.vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import VButton from '@/components/common/button/VButton.vue';
    import DropDown from '@/components/liveStream/DropDown.vue';
    import LiveStreamEvent from '@/components/liveStream/LiveStreamEvent.vue';
    import { useLiveStreamComponent } from '@/components/liveStream/useLiveStreamComponent';
    import { useLiveStreamExpand } from '@/components/liveStream/useLiveStreamExpand';
    import { useLiveStreamResize } from '@/components/liveStream/useLiveStreamResize';
    import { useRefresher } from '@/components/liveStream/useRefresher';
    import SubTab from '@/components/subTabs/SubTab.vue';
    import SubTabs from '@/components/subTabs/SubTabs.vue';
    import { useDisplayTimeFormat } from '@/composable/useDisplayTime';
    import { useState } from '@/composable/useState';
    import { removeDuplicates } from '@/core/lib/array';
    import type { IOpenCasinoGame } from '@/core/lib/casinoHelper';
    import { openCasinoGameByApi } from '@/core/lib/casinoHelper';
    import type { IOpenGame } from '@/core/lib/gameHelper';
    import { openInJoiGameByApi } from '@/core/lib/gameHelper';
    import { i18n } from '@/core/lib/i18n';
    import type { IStreamEvent } from '@/interface/IStream';
    import { BrandType, EnumLanguage, SportType } from '@/interface/enum';
    import { useCustomerStore } from '@/store/customerStore';
    import { useLiveStreamStore } from '@/store/liveStreamStore';
    import { useToggleStore } from '@/store/toggleStore';

    enum TabType {
        MyVideos = 'MyVideos',
        Schedule = 'Schedule'
    }

    interface IStreamCategory {
        name: string;
        streams: IStreamEvent[];
    }

    const {
        allStreamEvents,
        filteredStreamEvents,
        filteredMyStreamEvents,
        loaded,
        selectedSport,
        selectedStreamEvent,
    } = storeToRefs(useLiveStreamStore());

    const { refresh, isRefreshing, countdown } = useRefresher();

    const { setSelectSport, setSelectedEventId } = useLiveStreamStore();

    const activeTab = ref(TabType.Schedule);

    const selectedStreamEvents = computed(() => (activeTab.value === TabType.MyVideos ? filteredMyStreamEvents.value : filteredStreamEvents.value));

    const hasNoVideo = computed(() => loaded.value && selectedStreamEvents.value.length === 0);

    const streamCategories = computed<IStreamCategory[]>(() => {
        const process: (list: IStreamEvent[]) => IStreamCategory[] = flow(
            sortBy([
                event => !event.isLive,
                event => event.showTime,
                event => event.eventCode,
            ]),
            groupBy(event => ((event.isLive)
                ? i18n.t('live')
                : useDisplayTimeFormat(event.showTime, 'MM/dd/yyyy'))),
            entries,
            map(([name, streams]) => ({ name, streams })),
        );
        return process(selectedStreamEvents.value);
    });

    const sportList = computed(() => {
        const sports = removeDuplicates(allStreamEvents.value.map(event => event.sportType));

        return [
            { name: i18n.t('all_sports'), value: SportType.Unknown },
            ...sports.map(sport => ({
                name: i18n.t(`sport_type_name_${sport}`),
                value: sport,
            })),
        ];
    });

    const { brandType, ipCountryCode, sboCurrency } = storeToRefs(useCustomerStore());
    const brandName = computed(() => BrandType[brandType.value].toLowerCase());

    const isPlayerAllowToPlayPpGames = computed(() => {
        const currencyWhitelist = ['SD$', 'RM', 'US$', 'TB', 'HK$', 'EURO', 'Pp', 'AUD', 'GBP', 'RMB', 'BDT', 'PESO', 'NIO', 'YEN', 'USD', 'LKR', 'ZAR', 'KRW', 'CAD', 'NZD', 'NOK', 'SEK', 'CHF', 'BND', 'VND', 'MMK', 'TMP', 'ZWD', 'INR', 'CNY', 'HKD', 'MYR', 'RR', 'USDT', 'BRL'];
        const countryCodeBlacklist = ['IN', 'IR', 'GB', 'AE', 'AU', 'ZA', 'US', 'TW', 'SE', 'RS', 'RO', 'PT', 'PH', 'LT', 'KP', 'IT', 'IM', 'IL', 'GR', 'GI', 'GG', 'FR', 'ES', 'DK', 'CO', 'BY', 'BS', 'BG', 'UA', 'SG'];

        const isCurrencyAllowed = currencyWhitelist.includes(sboCurrency.value);
        const isCountryAllowed = !countryCodeBlacklist.includes(ipCountryCode.value);

        return isCurrencyAllowed && isCountryAllowed;
    });

    const {
        isExpand,
        toggleExpand,
        leftPanelStyle,
        rightPanelStyle,
        rightPanelEl,
        onResizeRightPanel,
    } = useLiveStreamExpand();

    const { streamDisplay } = useLiveStreamComponent(selectedStreamEvent);

    const outerBox = ref<HTMLDivElement | null>(null);
    const innerBox = ref<HTMLDivElement | null>(null);
    useLiveStreamResize(outerBox, innerBox);

    const casinoBaccaratEn = new URL('../../assets/images/casino/CasinoBaccarat_en.gif', import.meta.url).href;
    const casinoBaccaratId = new URL('../../assets/images/casino/CasinoBaccarat_id.gif', import.meta.url).href;
    const casinoBaccaratTh = new URL('../../assets/images/casino/CasinoBaccarat_th.gif', import.meta.url).href;
    const casinoBaccaratVn = new URL('../../assets/images/casino/CasinoBaccarat_vn.gif', import.meta.url).href;
    const bannerImageUrl1 = computed(() => {
        switch (i18n.locale.value) {
            case EnumLanguage.ID_ID:
                return casinoBaccaratId;
            case EnumLanguage.TH_TH:
                return casinoBaccaratTh;
            case EnumLanguage.VI_VN:
                return casinoBaccaratVn;
            case EnumLanguage.EN:
            default:
                return casinoBaccaratEn;
        }
    });
    const gatesOfOlympusEn = new URL('../../assets/images/games/GatesOfOlympus_en.gif', import.meta.url).href;
    const gatesOfOlympusID = new URL('../../assets/images/games/GatesOfOlympus_id.gif', import.meta.url).href;
    const gatesOfOlympusTH = new URL('../../assets/images/games/GatesOfOlympus_th.gif', import.meta.url).href;
    const gatesOfOlympusVN = new URL('../../assets/images/games/GatesOfOlympus_vn.gif', import.meta.url).href;
    const bannerImageUrl2 = computed(() => {
        switch (i18n.locale.value) {
            case EnumLanguage.ID_ID:
                return gatesOfOlympusID;
            case EnumLanguage.TH_TH:
                return gatesOfOlympusTH;
            case EnumLanguage.VI_VN:
                return gatesOfOlympusVN;
            case EnumLanguage.EN:
            default:
                return gatesOfOlympusEn;
        }
    });

    const {
        liveStreamBannerEnableMatchIds,
        isLiveStreamBanner1Enabled,
        isLiveStreamBanner2Enabled: _isLiveStreamBanner2Enabled,
    } = storeToRefs(useToggleStore());
    const isLiveStreamBanner2Enabled = computed(() => _isLiveStreamBanner2Enabled.value && isPlayerAllowToPlayPpGames.value);

    const [isShowFloatingBanner, setIsShowFloatingBanner] = useState(false);
    const selectedEventId = computed(() => selectedStreamEvent.value?.eventId ?? 0);
    const isShowLiveStreamBanner = computed(() => {
        if (!selectedStreamEvent.value) return false;
        if (!isLiveStreamBanner1Enabled.value && !isLiveStreamBanner2Enabled.value) return false;

        const matchIds = liveStreamBannerEnableMatchIds.value;
        return matchIds.includes(selectedEventId.value);
    });
    /**
     * Watch selectedEventId to reset the floating banner visibility
     * when user select another event
     */
    watch([isShowLiveStreamBanner, selectedEventId], ([newValue]) => {
        setIsShowFloatingBanner(newValue);
    });

    const gatesOfOlympusGame: IOpenGame = {
        gameCode: 2402776,
        gameFrom: 10243,
    };

    const openGame = useThrottleFn(openInJoiGameByApi, 1000);

    const casinoLiveCasino: IOpenCasinoGame = {
        gameCode: 1,
        gameFrom: 328,
        autoJoinType: 14,
    };
    const openCasinoGame = useThrottleFn(openCasinoGameByApi, 1000);
</script>

<style lang="scss" scoped>
    @import "@/components/liveStream/LiveStream";
</style>
