import { EventSettleStatus } from '@/interface/enum';

export const isRefunded = (status: EventSettleStatus) => [EventSettleStatus.Refunded, EventSettleStatus.FTRefunded].some(x => x & status);

export const findFirstHalfStatusWithoutResult = (status: number) => [
    EventSettleStatus.Refunded,
    EventSettleStatus.FHSuspendedMatch,
    EventSettleStatus.FHCancelled,
    EventSettleStatus.FHPostponed,
].find(x => x & status);

export const findFullTimeStatusWithoutResult = (status: number) => [
    EventSettleStatus.Refunded,
    EventSettleStatus.FTSuspendedMatch,
    EventSettleStatus.FTCancelled,
    EventSettleStatus.FTPostponed,
    EventSettleStatus.FTRefunded,
].find(x => x & status);

export const findFirstHalfStatusWithResult = (status: number) => [
    EventSettleStatus.FHAbandoned,
    EventSettleStatus.FHCompleteEarly,
].find(x => x & status);

export const findFullTimeStatusWithResult = (status: number) => [
    EventSettleStatus.FTAbandoned,
    EventSettleStatus.FTCompleteEarly,
].find(x => x & status);
