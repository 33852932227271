<template>
    <MatchLoading v-if="!loaded" />
    <NoData v-else-if="leagues.length === 0" v-bind:dataType="NoDataType.Events" />
    <div v-else class="leagueGroup">
        <OutrightOddsDisplay
            v-for="(league, index) in leagues"
            v-bind:key="league.id"
            v-bind:league="league"
            v-bind:initialIsExpanded="index < 3"
        />
    </div>
</template>

<script lang="ts" setup>
    import orderBy from 'lodash/orderBy';
    import { computed } from 'vue';
    import MatchLoading from '@/components/euroDisplay/MatchLoading.vue';
    import NoData from '@/components/euroDisplay/NoData.vue';
    import OutrightOddsDisplay from '@/components/euroDisplay/oddsDisplay/outright/OutrightOddsDisplay.vue';
    import { useEuroRoute } from '@/composable/useEuroRoute';
    import { useOutrightLeaguesEuro } from '@/core/oddsApi/composable/useOutrightLeagues.euro';
    import type { IOutrightLeagueEuro } from '@/interface/IOutrightLeague';
    import { NoDataType } from '@/interface/enum';

    const { sportType } = useEuroRoute();

    const { outrightLeagues, loaded } = useOutrightLeaguesEuro({
        sportType: sportType.value,
    });

    const leagues = computed(() => orderBy<IOutrightLeagueEuro>(outrightLeagues.value, [
        e => e.isLive,
        e => e.leagueCode,
    ], ['desc']));
</script>

<style lang="scss" scoped>
.leagueGroup {
    overflow: hidden;
    border-radius: var(--containerBorderRadius);
}
</style>
