<template>
    <div class="singleBetType notranslate" v-bind:class="[styleWidth, { live: props.odds.isLive }]">
        <strong class="singleBetType_title">
            <span
                v-if="isShowScore && liveScore"
                class="score"
            >
                [ {{ liveScore.home }}<span>:</span>{{ liveScore.away }} ]
            </span>
            {{ title }}

            <VButton
                v-if="false"
                padding="0 2px"
                class="singleBetType_title-button"
                v-on:click="toggleIsExpand"
            >
                <SvgIcon
                    name="icon-system-arrow"
                    v-bind:rotated="!isExpand"
                />
            </VButton>
        </strong>
        <ExpandTransition>
            <div v-if="isExpand">
                <div
                    v-for="(row, rowIdx) in priceRows"
                    v-bind:key="rowIdx"
                    class="singleBetType_content"
                >
                    <div
                        v-for="(price, priceIdx) in row"
                        v-bind:key="price?.option || priceIdx"
                        v-tooltip:top="price ? tooltipFormatter(event, odds, price) : ''"
                        class="singleBetType_content_col"
                    >
                        <template v-if="price">
                            <div
                                class="oddsOption"
                                v-bind:class="{
                                    'team-favorite': isFavoriteTeam(price.option),
                                    'cursor-help': !!tooltipFormatter(event, odds, price),
                                }"
                            >
                                {{ optionFormatter(event, odds, price) }}
                            </div>
                            <strong
                                class="oddsItem"
                            >
                                <div v-if="getOddsPoint(odds, price)" class="oddsPoint">
                                    {{ getOddsPoint(odds, price) }}
                                </div>
                                <Odds
                                    v-bind:eventId="event.id"
                                    v-bind:oddsId="odds.id"
                                    v-bind:oddsPrice="price"
                                    v-bind:oddsStatus="odds.status"
                                    v-bind:minDecimalPlace="minDecimalPlace"
                                    align="center"
                                    class="oddsPrice"
                                />
                            </strong>
                        </template>
                    </div>
                </div>
            </div>
        </ExpandTransition>
    </div>
</template>

<script lang="ts" setup>
    import { computed, toRefs } from 'vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import VButton from '@/components/common/button/VButton.vue';
    import Odds from '@/components/odds/Odds.vue';
    import { isAwayFavorite, isHomeFavorite } from '@/components/oddsDisplay/matchDisplay/useMatchDisplay';
    import { getMarketRule } from '@/components/oddsDisplay/moreMarket/marketRules';
    import ExpandTransition from '@/components/transitions/ExpandTransition.vue';
    import { useToggle } from '@/composable/useToggle';
    import { getMarketGroupId, getOddsPoint, isHandicap } from '@/core/lib/oddsHelper';
    import { getScore, isShowScoreInMoreMarket } from '@/core/oddsApi/helpers/liveScore';
    import type { IEvent } from '@/interface/IEvent';
    import type { IOdds } from '@/interface/IOdds';
    import { OddsOption } from '@/interface/enum';

    const props = defineProps<{
        event: IEvent;
        odds: IOdds;
        oddsList: IOdds[];
    }>();

    const [isExpand, toggleIsExpand] = useToggle(true);

    const { event } = toRefs(props);

    const marketRule = computed(() => getMarketRule(props.odds.marketType));

    const isShowScore = computed(() => isShowScoreInMoreMarket(props.event, getMarketGroupId(props.odds)));

    const liveScore = computed(() => getScore(event.value.eventResults, getMarketGroupId(props.odds)));
    const title = computed(() => marketRule.value.titleFormatter(props.event, props.odds));

    const priceRows = computed(() => marketRule.value.singlePricesFormatter(props.odds.prices));

    const styleWidth = computed(() => marketRule.value.widthFormatter(props.odds, priceRows.value));

    const optionFormatter = computed(() => marketRule.value.singleOptionFormatter);

    const tooltipFormatter = computed(() => marketRule.value.singleTooltipFormatter);

    const minDecimalPlace = computed(() => marketRule.value.minDecimalPlace);

    const isFavoriteTeam = (option: OddsOption) => {
        if (!isHandicap(props.odds.marketType)) return false;

        if (option === OddsOption.Home) {
            return isHomeFavorite(props.odds?.eventResult);
        }

        if (option === OddsOption.Away) {
            return isAwayFavorite(props.odds?.eventResult);
        }

        return false;
    };
</script>

<style lang="scss" scoped>
    @import "@/components/oddsDisplay/moreMarket/marketType/SingleBetType";
</style>
