import { storeToRefs } from 'pinia';
import { useCountdown } from '@/composable/useCountdown';
import { useIdle } from '@/composable/useIdle';
import { useLiveStreamStore } from '@/store/liveStreamStore';
import { useToggleStore } from '@/store/toggleStore';

export function useRefresher() {
    const {
        refetchStreamEvents,
        refreshMyEventIds,
        refreshMyEventIdsThrottled,
        refetchStreamEventsThrottled,
    } = useLiveStreamStore();

    const { liveStreamRefreshSeconds } = storeToRefs(useToggleStore());

    const _refresh = async (isThrottled: boolean) => {
        if (isThrottled) {
            await refetchStreamEventsThrottled();
            await refreshMyEventIdsThrottled();
        } else {
            await refetchStreamEvents();
            await refreshMyEventIds();
        }
    };

    const { isLoading: isRefreshing, countdown, trigger: refresh } = useCountdown(
        liveStreamRefreshSeconds.value,
        _refresh,
        { minLoadingTime: 1000 },
    );

    const { onActive } = useIdle(60 * 1000);

    onActive(() => {
        // eslint-disable-next-line no-console
        console.log('LiveStream:Recovering from idle state...');
        refresh(true);
    });

    return {
        refresh,
        isRefreshing,
        countdown,
    };
}
