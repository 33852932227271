import { useThrottleFn } from '@vueuse/core';
import orderBy from 'lodash/orderBy';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useEventEmitter } from '@/composable/useEventEmitter';
import { defineContext } from '@/core/lib/defineContext';
import { useOutrightLeagues } from '@/core/oddsApi/composable/useOutrightLeagues';
import type { IOutrightLeague } from '@/interface/IOutrightLeague';
import { useAsiaSportMarketInfoStore } from '@/store/asiaSportMarketInfoStore';
import { useSelectedLeagueStore } from '@/store/selectedLeagueStore';

export const OutrightOddsDisplayBlockContext = defineContext('OutrightOddsDisplayBlock', () => {
    const { selectedLeagueIds } = storeToRefs(useSelectedLeagueStore());

    const { sportType, marketPage, filteredLeagueIds, filteredLeagueNames } = storeToRefs(useAsiaSportMarketInfoStore());

    const { loaded: liveLoaded, loading: liveLoading, refetch: liveRefetch, outrightLeagues: liveLeagues } = useOutrightLeagues({
        sportType: sportType.value,
        leagueIds: filteredLeagueIds,
        leagueNames: filteredLeagueNames,
        isLive: true,
    });
    const { loaded: nonLiveLoaded, loading: nonLiveLoading, refetch: nonLiveRefetch, outrightLeagues: nonLiveLeagues } = useOutrightLeagues({
        sportType: sportType.value,
        leagueIds: filteredLeagueIds,
        leagueNames: filteredLeagueNames,
        isLive: false,
    });

    const loaded = computed(() => liveLoaded.value && nonLiveLoaded.value);
    const loading = computed(() => liveLoading.value || nonLiveLoading.value);

    const allLeagues = computed(() => [...liveLeagues.value, ...nonLiveLeagues.value]);

    const throttledLiveRefetch = useThrottleFn(liveRefetch, 5000, false, true);
    const throttledNonLiveRefetch = useThrottleFn(nonLiveRefetch, 5000, false, true);
    const eventEmitter = useEventEmitter();
    eventEmitter.on('oddsDisplay:refreshEventList', (isLive?: boolean) => {
        if (isLive === undefined) {
            throttledLiveRefetch();
            throttledNonLiveRefetch();
        } else if (isLive === true) {
            throttledLiveRefetch();
        } else {
            throttledNonLiveRefetch();
        }
    });

    const sortLeagues = (leagues: IOutrightLeague[]) => orderBy(leagues, league => (league.leagueCode));
    const sortedLiveLeagues = computed(() => sortLeagues(liveLeagues.value));
    const sortedNonLiveLeagues = computed(() => sortLeagues(nonLiveLeagues.value));
    const sortedAllLeagues = computed(() => sortLeagues(allLeagues.value));

    const filterLeagues = (leagues: IOutrightLeague[]) => (selectedLeagueIds.value.length > 0
        ? leagues.filter(x => selectedLeagueIds.value.includes(x.id))
        : leagues);
    const filteredLiveLeagues = computed(() => filterLeagues(sortedLiveLeagues.value));
    const filteredNonLiveLeagues = computed(() => filterLeagues(sortedNonLiveLeagues.value));

    return {
        sportType,
        marketPage,
        loaded,
        loading,
        liveRefetch,
        nonLiveRefetch,
        sortedAllLeagues,
        displayLiveLeagues: filteredLiveLeagues,
        displayNonLiveLeagues: filteredNonLiveLeagues,
    };
});
