import type { Ref } from 'vue';
import { computed, ref, watch } from 'vue';
import { defineContext } from '@/core/lib/defineContext';
import { i18n } from '@/core/lib/i18n';
import type { EuroDate } from '@/interface/enum';
import { EuroMarket, MarketType, SportType } from '@/interface/enum';

interface IEuroMarketConfig {
    market: EuroMarket;
    name: string;
    marketTypes: MarketType[];
    headers: string[];
    gtmLabel: string;
}

export const EuroDisplayContext = defineContext('EuroDisplayContext', (sportType: Ref<SportType>, isMultiples: Ref<boolean>, date: Ref<EuroDate>) => {
    const marketConfigs = computed<Record<EuroMarket, IEuroMarketConfig>>(() => ({
        [EuroMarket._1X2]: {
            market: EuroMarket._1X2,
            name: i18n.t('market_type_name_5'),
            marketTypes: [MarketType._1X2],
            headers: [
                '1',
                'X',
                '2',
            ],
            gtmLabel: 'Sorting_5',

        },
        [EuroMarket.MoneyLine]: {
            market: EuroMarket.MoneyLine,
            name: i18n.t('market_type_name_11'),
            marketTypes: [MarketType.MoneyLine],
            headers: [
                '1',
                '2',
            ],
            gtmLabel: 'Sorting_11',
        },
        [EuroMarket.TotalGoals]: {
            market: EuroMarket.TotalGoals,
            name: i18n.t('market_type_name_3'),
            marketTypes: [MarketType.OverUnder],
            headers: [
                i18n.t('odds_option_over'),
                i18n.t('odds_option_under'),
            ],
            gtmLabel: 'Sorting_3',
        },
        [EuroMarket.Handicap]: {
            market: EuroMarket.Handicap,
            name: i18n.t('euro_market_display_asian_handicap'),
            marketTypes: [MarketType.Handicap],
            headers: [
                '1',
                '2',
            ],
            gtmLabel: 'Sorting_1',
        },
        [EuroMarket.GameLines]: {
            market: EuroMarket.GameLines,
            name: i18n.t('euro_market_display_game_line'),
            marketTypes: [
                MarketType.Handicap,
                MarketType.OverUnder,
                MarketType.MoneyLine,
            ],
            headers: [
                i18n.t('euro_market_display_spread'),
                i18n.t('euro_market_display_over_under'),
                i18n.t('market_type_name_11'),
            ],
            gtmLabel: 'Sorting_GL',
        },
    }));

    const sportConfigsMapping = computed<Partial<Record<SportType, IEuroMarketConfig[]>>>(() => ({
        [SportType.Soccer]: [
            marketConfigs.value[EuroMarket._1X2],
            marketConfigs.value[EuroMarket.TotalGoals],
            marketConfigs.value[EuroMarket.Handicap],
        ],
        [SportType.Basketball]: [
            marketConfigs.value[EuroMarket.GameLines],
        ],
        [SportType.Badminton]: [
            marketConfigs.value[EuroMarket.Handicap],
        ],
        [SportType.Tennis]: [
            marketConfigs.value[EuroMarket.Handicap],
        ],
        [SportType.TableTennis]: [
            marketConfigs.value[EuroMarket.MoneyLine],
        ],
        [SportType.Baseball]: [
            marketConfigs.value[EuroMarket.MoneyLine],
            marketConfigs.value[EuroMarket.TotalGoals],
            marketConfigs.value[EuroMarket.Handicap],
        ],
        [SportType.MuayThai]: [
            marketConfigs.value[EuroMarket.Handicap],
        ],
        [SportType.Football]: [
            marketConfigs.value[EuroMarket.Handicap],
            marketConfigs.value[EuroMarket.TotalGoals],
        ],
        [SportType.Volleyball]: [
            marketConfigs.value[EuroMarket.MoneyLine],
        ],
        [SportType.Pool]: [
            marketConfigs.value[EuroMarket.Handicap],
        ],
        [SportType.Hockey]: [
            marketConfigs.value[EuroMarket.TotalGoals],
            marketConfigs.value[EuroMarket.Handicap],
            marketConfigs.value[EuroMarket._1X2],
        ],
        [SportType.Cricket]: [
            marketConfigs.value[EuroMarket.Handicap],
        ],
        [SportType.Rugby]: [
            marketConfigs.value[EuroMarket.Handicap],
            marketConfigs.value[EuroMarket.TotalGoals],
        ],
        [SportType.MixedMartialArts]: [
            marketConfigs.value[EuroMarket.Handicap],
        ],
        [SportType.Handball]: [
            marketConfigs.value[EuroMarket.Handicap],
            marketConfigs.value[EuroMarket.TotalGoals],
        ],
        [SportType.MotorSport]: [
            marketConfigs.value[EuroMarket.Handicap],
        ],
        [SportType.Darts]: [
            marketConfigs.value[EuroMarket.MoneyLine],
            marketConfigs.value[EuroMarket.Handicap],
            marketConfigs.value[EuroMarket.TotalGoals],
        ],
        [SportType.BeachVolleyball]: [
            marketConfigs.value[EuroMarket.MoneyLine],
        ],
        [SportType.FieldHockey]: [
            marketConfigs.value[EuroMarket.Handicap],
            marketConfigs.value[EuroMarket.TotalGoals],
        ],
        [SportType.Futsal]: [
            marketConfigs.value[EuroMarket.Handicap],
            marketConfigs.value[EuroMarket.TotalGoals],
        ],
        [SportType.Boxing]: [
            marketConfigs.value[EuroMarket.Handicap],
        ],
        [SportType.Golf]: [
            marketConfigs.value[EuroMarket.Handicap],
            marketConfigs.value[EuroMarket.MoneyLine],
        ],
        [SportType.Unknown]: [
            marketConfigs.value[EuroMarket.MoneyLine],
            marketConfigs.value[EuroMarket.Handicap],
        ],
    }));

    const markets = computed(() => sportConfigsMapping.value[sportType.value] ?? sportConfigsMapping.value[SportType.Unknown] ?? []);

    const market = ref(markets.value[0]);
    watch(markets, () => {
        market.value = markets.value[0];
    });

    return {
        sportType,
        isMultiples,
        date,
        markets,
        market,
    };
});
