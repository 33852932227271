import { MarketType } from '@/interface/enum';

export const mainMarketOrder = [
    MarketType.MoneyLine,
    MarketType._1X2,
    MarketType.FH_1X2,
    MarketType.LiveScore,
    MarketType.FH_LiveScore,
    MarketType.OverUnder,
    MarketType.FH_OverUnder,
    MarketType.Handicap,
    MarketType.FH_Handicap,
    MarketType.DoubleChance,
    MarketType.OddEven,
    MarketType.FH_OddEven,
    MarketType.TotalGoal,
    MarketType.CorrectScore,
    MarketType.FH_CorrectScore,
    MarketType.HTFT,
    MarketType.FGLG,
];

export const nbtMarketOrder = [
    MarketType.OverUnder,
    MarketType.FH_OverUnder,
    MarketType.Handicap,
    MarketType.FH_Handicap,
    MarketType.OddEven,
    MarketType.MoneyLine,
    MarketType._1X2,
    MarketType.FH_1X2,
];
