import { flow } from 'lodash';
import { defineStore, storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useEuroRoute } from '@/composable/useEuroRoute';
import { useEuroRouter } from '@/composable/useEuroRouter';
import { i18n } from '@/core/lib/i18n';
import {
    processDefaultSportsOrderPipe,
    processEsportsPipe,
    processExcludeSportPipe,
    processVirtualSportsPipe,
} from '@/core/lib/sportMarketInfoHelper';
import { useSportMarketInfo } from '@/core/oddsApi/composable/useSportMarketInfo';
import type { ISportInfo, ISportListItem } from '@/interface/ISport';
import { EuroRoutePage, RoutePage, SportType } from '@/interface/enum';
import { useToggleStore } from '@/store/toggleStore';

export const useEuroSideBarStore = defineStore('EuroSideBarStore', () => {
    const router = useRouter();
    const { goToSportPage } = useEuroRouter();
    const { sportType, isMultiplesPage, isInPage } = useEuroRoute();

    const { sportList: _sportList, mixParlaySportList: mixParlaySportInfo } = useSportMarketInfo();

    const processFlow = flow(
        processExcludeSportPipe,
        processVirtualSportsPipe,
        processEsportsPipe,
        processDefaultSportsOrderPipe,
    );
    const sportsInfoDisplay = computed(() => processFlow(_sportList.value));
    const mixParlayEventCount = computed(() => mixParlaySportInfo.value.reduce((acc, cur) => acc + cur.totalEventCount, 0));

    function getMixParlayLink(): ISportListItem {
        const liveCount = mixParlaySportInfo.value.reduce((acc, cur) => acc + cur.liveEventCount, 0);
        const nonLiveCount = mixParlaySportInfo.value.reduce((acc, cur) => acc + cur.nonLiveEventCount, 0);
        const todayExpandedCount = mixParlaySportInfo.value.reduce((acc, cur) => acc + cur.todayExpandedCount, 0);

        return {
            name: i18n.t('market_page_name_4'),
            icon: 'icon-system-ticketMultiple',
            isHot: false,
            isShowCount: true,
            isLive: mixParlaySportInfo.value.some(x => x.liveEventCount > 0),
            isActive: isMultiplesPage.value,
            count: mixParlayEventCount.value,
            liveCount,
            nonLiveCount,
            todayExpandedCount,
            onClick: () => {
                router.push({
                    name: EuroRoutePage.Multiples,
                });
            },
            gtmLabel: 'Multiples',
        };
    }

    function getLivePageLink(): ISportListItem {
        const liveCount = _sportList.value.reduce((acc, cur) => acc + cur.liveEventCount, 0);
        const nonLiveCount = _sportList.value.reduce((acc, cur) => acc + cur.nonLiveEventCount, 0);
        const todayExpandedCount = _sportList.value.reduce((acc, cur) => acc + cur.todayExpandedCount, 0);

        return {
            name: i18n.t('market_page_name_3'),
            icon: 'icon-system-stopwatch',
            isHot: false,
            isShowCount: true,
            isLive: _sportList.value.some(x => x.liveEventCount > 0),
            isActive: isInPage(EuroRoutePage.InPlay),
            count: liveCount,
            liveCount,
            nonLiveCount,
            todayExpandedCount,
            onClick: () => {
                router.push({
                    name: EuroRoutePage.InPlay,
                });
            },
            gtmLabel: 'InPlay',
        };
    }

    function getHomePageLink(): ISportListItem {
        return {
            name: i18n.t('main_header_default_page'),
            icon: 'icon-system-house',
            isHot: false,
            isShowCount: false,
            isLive: false,
            isActive: isInPage(EuroRoutePage.Home),
            count: 0,
            liveCount: 0,
            nonLiveCount: 0,
            todayExpandedCount: 0,
            onClick: () => {
                router.push({
                    name: EuroRoutePage.Home,
                });
            },
            gtmLabel: 'Home',
        };
    }

    const route = useRoute();
    const isExchangePage = computed(() => route.name === RoutePage.Exchange);

    function getExchangeLink(): ISportListItem {
        const { isFairExchangeEnabled } = storeToRefs(useToggleStore());
        return {
            name: i18n.t('exchange'),
            icon: 'icon-system-exchange',
            isHot: false,
            isShowCount: false,
            isLive: false,
            isActive: isExchangePage.value,
            count: 0,
            liveCount: 0,
            nonLiveCount: 0,
            todayExpandedCount: 0,
            onClick: () => {
                router.push({
                    name: EuroRoutePage.Exchange,
                });
            },
            gtmLabel: 'Exchange',
            isDisabled: !isFairExchangeEnabled.value,
        };
    }

    const hotLinks = computed((): ISportListItem[] => [
        getHomePageLink(),
        getLivePageLink(),
        getMixParlayLink(),
        getExchangeLink(),
    ].filter(x => !x.isDisabled));

    const isDisplay = (sportInfo: ISportInfo) => sportInfo.hasEvents
        || sportInfo.children !== undefined
        || [SportType.VirtualFootball, SportType.VirtualBasketball].includes(sportInfo.sportType);

    const hotLinkPages = [EuroRoutePage.Home, EuroRoutePage.InPlay, EuroRoutePage.Multiples];

    const formatEuroSportList = (x: ISportInfo): ISportListItem => ({
        name: x.name,
        sportType: x.sportType,
        count: x.totalEventCount,
        liveCount: x.liveEventCount,
        nonLiveCount: x.nonLiveEventCount,
        todayExpandedCount: x.todayExpandedCount,
        isShowCount: false,
        isHot: false,
        isLive: x.hasLiveEvents,
        isActive: x.sportType === sportType.value && !isInPage(...hotLinkPages),
        children: x.children?.map(formatEuroSportList),
        onClick: () => {
            if (x.children) return;
            if (x.onClick) {
                x.onClick();
                return;
            }
            goToSportPage(x.sportType);
        },
        gtmLabel: x.sportType.toString(),
    });
    const sportList = computed<ISportListItem[]>(() => sportsInfoDisplay.value.filter(item => isDisplay(item)).map(formatEuroSportList));

    return {
        hotLinks,
        sportList,

        mixParlaySportInfo,
        mixParlayEventCount,
    };
});
