<template>
    <div v-gtm:type="'MCard'">
        <OddsDisplayHeader
            v-bind:isLive="isLive"
            v-bind:isSticky="isSticky"
            v-bind:hasEvent="leagueEvents.length > 0"
        />
        <VirtualScroll
            v-if="leagueEvents.length > 0"
            class="oddsDisplay_content"
            v-bind:list="dataList"
            keyAttrName="id"
            v-bind:defaultItemHeight="item => item.height"
            v-bind:hysteresis="0"
            v-bind:redrawSignal="redrawSignal"
        >
            <template #default="{ item }">
                <OddsDisplayLeague
                    v-bind:isLive="isLive"
                    v-bind:eventList="item.events"
                />
            </template>
            <template #placeholder="{ item }">
                <OddsDisplayPlaceholder
                    v-bind:eventList="item.events"
                />
            </template>
        </VirtualScroll>
        <div
            v-if="leagueEvents.length === 0"
            class="oddsDisplay_empty"
            v-bind:class="{ live: isLive }"
        >
            <span v-if="loaded || eventsError || !isEnabledEvents">{{ noEventsDescription }}</span>
            <StaticSpinner v-else />
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import { computed } from 'vue';
    import StaticSpinner from '@/components/common/StaticSpinner.vue';
    import { OddsDisplayBlockContext } from '@/components/oddsDisplay/OddsDisplayBlockContext';
    import { OddsDisplayContext } from '@/components/oddsDisplay/OddsDisplayContext';
    import OddsDisplayHeader from '@/components/oddsDisplay/OddsDisplayHeader.vue';
    import OddsDisplayLeague from '@/components/oddsDisplay/OddsDisplayLeague.vue';
    import OddsDisplayPlaceholder from '@/components/oddsDisplay/OddsDisplayPlaceholder.vue';
    import { OpenMoreMarketContext } from '@/components/oddsDisplay/matchDisplay/OpenMoreMarketContext';
    import { getLeaguePlaceHolderHeight } from '@/components/oddsDisplay/oddsTableCalculation';
    import VirtualScroll from '@/components/virtualScroll/VirtualScroll.vue';
    import { i18n } from '@/core/lib/i18n';
    import { challengeStatus } from '@/core/oddsApi/apollo';
    import { useAsiaSportMarketInfoStore } from '@/store/asiaSportMarketInfoStore';

    const props = defineProps<{
        isLive: boolean;
        isSticky: boolean;
        redrawSignal?: number;
    }>();

    const { loaded, eventsError, isEnabledEvents, oddsDisplayMode } = OddsDisplayBlockContext.inject();
    const { leagueEvents } = OddsDisplayContext.provide(props.isLive);
    const { isMyFavorite } = storeToRefs(useAsiaSportMarketInfoStore());
    OpenMoreMarketContext.provide();

    const errorCode = computed(() => (challengeStatus.value === 'ERROR' ? ' (E403)' : ''));
    const noEventsDescription = computed(() => {
        const key = isMyFavorite.value ? 'no_favorite_description' : 'no_events_available_for_this_market';
        return i18n.t(key) + errorCode.value;
    });

    const dataList = computed(() => leagueEvents.value.map(eventList => ({
        id: eventList[0].id,
        height: getLeaguePlaceHolderHeight(oddsDisplayMode, props.isLive, eventList),
        events: eventList,
    })));
</script>

<style lang="scss" scoped>
    @import "@/components/oddsDisplay/OddsDisplay";
</style>
