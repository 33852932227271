<template>
    <div class="matchDetailWrapper notranslate">
        <div class="matchDetailNavbar">
            <div class="matchDetailInfo">
                <div class="eventHeader">
                    <div v-if="liveTimeDisplay" class="matchesTime">
                        <div class="redDot" />
                        <div class="time">
                            {{ liveTimeDisplay }}
                            <span v-if="event && isShowInjuryTime(event)">
                                +{{ event.extraInfo?.injuryTime }}'
                            </span>
                        </div>
                    </div>
                    <div v-else-if="showTimeDisplay" class="matchesTime">
                        {{ i18n.t('upcoming') }}: {{ showTimeDisplay }}
                    </div>
                    <div class="leagueName">
                        {{ event?.league.name ?? leagueName }}
                        <SvgIcon
                            v-if="event?.league.info"
                            v-tooltip="event?.league.info"
                            name="icon-system-cycle-info"
                            right
                        />
                    </div>
                    <div class="eventItems">
                        <StatisticsButton
                            v-bind:event="event"
                            flat
                        />
                        <NavbarDivider />
                        <VButton
                            v-tooltip="i18n.t('live_stream_icon_tooltip')"
                            v-gtm:@click
                            v-gtm:label="'LiveStream'"
                            flat
                            v-bind:active="matchDetailPreview === MatchDetailPreview.LiveStream"
                            v-bind:disabled="!isLiveStreamPlayable"
                            v-bind:activeUnderline="matchDetailPreview === MatchDetailPreview.LiveStream"
                            v-on:click="toggleMatchDetailPreview(MatchDetailPreview.LiveStream)"
                        >
                            <SvgIcon name="icon-system-tvPlay" size="xl" />
                        </VButton>
                        <VButton
                            v-tooltip="i18n.t('live_court_icon_tooltip')"
                            v-gtm:@click
                            v-gtm:label="'LiveCourt'"
                            flat
                            v-bind:active="matchDetailPreview === MatchDetailPreview.LiveCourt"
                            v-bind:disabled="!isLiveCourtAvailable"
                            v-bind:activeUnderline="matchDetailPreview === MatchDetailPreview.LiveCourt"
                            v-on:click="toggleMatchDetailPreview(MatchDetailPreview.LiveCourt)"
                        >
                            <SvgIcon name="icon-system-courtFootball" size="xl" />
                        </VButton>
                    </div>
                </div>
                <div class="eventInfo">
                    <div v-if="event && isShowJersey" class="flag">
                        <div class="flag-item">
                            <Jersey v-bind:jersey="event.homeTeam.jersey" />
                        </div>
                        <div class="flag-item">
                            <Jersey v-bind:jersey="event.awayTeam.jersey" />
                        </div>
                    </div>
                    <template v-if="event?.isLive">
                        <template v-for="item in liveScoreItems" v-bind:key="item.name">
                            <div v-if="item.isShow" class="teamLiveScore">
                                <div v-bind:class="item.isSetScore ? 'setScore' : 'liveScore'">
                                    {{ item.homeScore }}
                                </div>
                                <div v-bind:class="item.isSetScore ? 'setScore' : 'liveScore'">
                                    {{ item.awayScore }}
                                </div>
                            </div>
                        </template>
                    </template>
                    <div class="teamName">
                        <div class="teamName-item">
                            {{ event?.homeTeam.name ?? eventName.split(' v ')[0] }}
                        </div>
                        <div class="teamName-item">
                            {{ event?.awayTeam.name ?? eventName.split(' v ')[1] }}
                        </div>
                    </div>
                    <div class="matchFoulCard">
                        <div class="foulCardGroup">
                            <FoulCard v-bind:count="event?.homeTeam.redCard ?? 0" />
                        </div>
                        <div class="foulCardGroup">
                            <FoulCard v-bind:count="event?.awayTeam.redCard ?? 0" />
                        </div>
                    </div>
                </div>
            </div>

            <Preview
                v-if="matchDetailPreview === MatchDetailPreview.LiveStream"
                v-gtm:@click
                v-gtm:label="'LiveStream_Expand'"
                v-on:click="openLiveStream"
            >
                <component
                    v-bind:is="streamDisplay.component"
                    v-if="streamDisplay"
                    v-bind:key="streamDisplay.key"
                    v-bind:videoUrl="streamDisplay.videoUrl"
                    v-bind:betRadar="streamDisplay.betRadar!"
                    v-bind:gLive="streamDisplay.gLive!"
                    v-bind:betConstruct="streamDisplay.betConstruct"
                    v-bind:isEmbedOnMainPage="true"
                />
            </Preview>
            <Preview v-else-if="matchDetailPreview === MatchDetailPreview.LiveCourt" v-on:click="openLiveCourt">
                <LiveCourtPreview v-if="displayPreviewEvent" v-bind:liveCourtMatchId="displayPreviewEvent.liveCourtMatchId" />
            </Preview>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { computed, toRefs, watch } from 'vue';
    import { useRoute } from 'vue-router';
    import Jersey from '@/components/common/Jersey.vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import VButton from '@/components/common/button/VButton.vue';
    import FoulCard from '@/components/common/foulCard/FoulCard.vue';
    import NavbarDivider from '@/components/common/navbar/NavbarDivider.vue';
    import Preview from '@/components/euroDisplay/MatchDetailPreview.vue';
    import LiveCourtPreview from '@/components/liveCourt/LiveCourtPreview.vue';
    import { useLiveStreamComponent } from '@/components/liveStream/useLiveStreamComponent';
    import StatisticsButton from '@/components/oddsDisplay/functions/StatisticsButton.vue';
    import { isShowInjuryTime } from '@/components/oddsDisplay/matchDisplay/useMatchDisplay';
    import { once } from '@/composable/once';
    import { useEuroRoute } from '@/composable/useEuroRoute';
    import { useLiveCourt } from '@/composable/useLiveCourt';
    import { useLiveScoreDisplay } from '@/composable/useLiveScoreDisplay';
    import { useLiveScoreRestriction } from '@/composable/useLiveScoreRestriction';
    import { useLiveStream } from '@/composable/useLiveStream';
    import { useShowTimeDisplay } from '@/composable/useShowTimeDisplay';
    import { useState } from '@/composable/useState';
    import { i18n } from '@/core/lib/i18n';
    import { useLiveScore } from '@/core/oddsApi/composable/useLiveScore';
    import type { IEventEuro } from '@/interface/IEvent';
    import type { Score } from '@/interface/ILiveScore';
    import { MatchDetailPreview, SiteStyle } from '@/interface/enum';

    const props = defineProps<{
        event: IEventEuro | null;
    }>();

    const { event } = toRefs(props);

    const { leagueName, eventName } = useEuroRoute();

    const eventId = computed(() => event.value?.id ?? 0);
    const { isSupported: isEnableLiveScore } = useLiveScoreRestriction(event);
    const { liveScore: providerLiveScore } = useLiveScore(eventId, isEnableLiveScore);
    const sboLiveScore = computed<Score>(() => ({ home: event.value?.homeTeam.liveScore ?? 0, away: event.value?.awayTeam.liveScore ?? 0 }));
    const { displayScore } = useLiveScoreDisplay(event, providerLiveScore, sboLiveScore, SiteStyle.Euro);

    const liveScoreItems = computed(() => [
        {
            name: 'set',
            isShow: displayScore.value && displayScore.value.setScore,
            homeScore: displayScore.value?.setScore?.home,
            awayScore: displayScore.value?.setScore?.away,
            isSetScore: true,
        },
        {
            name: 'score',
            isShow: displayScore.value && displayScore.value.score,
            homeScore: displayScore.value?.score?.home,
            awayScore: displayScore.value?.score?.away,
            isSetScore: false,
        },
    ]);

    const liveTimeDisplay = computed(() => {
        if (!event.value?.isLive) return null;
        if (!displayScore.value) return i18n.t('live');
        if (displayScore.value.cricketDisplayText) return displayScore.value.cricketDisplayText;
        return displayScore.value.setDisplayText ? displayScore.value.setDisplayText : displayScore.value.periodText;
    });

    const showTimeDisplay = computed(() => {
        if (!event.value) return null;
        const displayDateTime = useShowTimeDisplay(event.value.showTime, event.value.showTimeType);
        return `${displayDateTime.date} ${displayDateTime.time}`;
    });

    const isShowJersey = computed(() => {
        if (!event.value) return false;
        return event.value.homeTeam.jersey.type > 0 && event.value.awayTeam.jersey.type > 0;
    });

    const [displayPreviewEvent, setDisplayPreviewEvent] = useState(event.value);
    const { streamDisplay } = useLiveStreamComponent(displayPreviewEvent);

    const { isLiveStreamPlayable, openLiveStream: _openLiveStream } = useLiveStream(displayPreviewEvent);
    const { isLiveCourtAvailable, openLiveCourt: _openLiveCourt } = useLiveCourt(displayPreviewEvent);

    const [matchDetailPreview, _setMatchDetailPreview] = useState(MatchDetailPreview.None);
    function toggleMatchDetailPreview(newMatchDetailPreview: MatchDetailPreview) {
        if (newMatchDetailPreview === MatchDetailPreview.LiveStream && !isLiveStreamPlayable.value) {
            return;
        }

        if (newMatchDetailPreview === matchDetailPreview.value) {
            _setMatchDetailPreview(MatchDetailPreview.None);
        } else {
            _setMatchDetailPreview(newMatchDetailPreview);
        }
    }

    function openLiveStream() {
        _openLiveStream(displayPreviewEvent.value?.id);
        _setMatchDetailPreview(MatchDetailPreview.None);
    }

    function openLiveCourt() {
        _openLiveCourt();
        _setMatchDetailPreview(MatchDetailPreview.None);
    }

    watch(event, () => {
        if (event.value) {
            setDisplayPreviewEvent(event.value);
        }
    });

    const route = useRoute();
    once(() => !!displayPreviewEvent.value, () => {
        if (route.query.watch) {
            if (route.query.watch === MatchDetailPreview.LiveStream && isLiveStreamPlayable.value) {
                toggleMatchDetailPreview(MatchDetailPreview.LiveStream);
            }
            if (route.query.watch === MatchDetailPreview.LiveCourt && isLiveCourtAvailable.value) {
                toggleMatchDetailPreview(MatchDetailPreview.LiveCourt);
            }
        }
    });
</script>

<style lang="scss" scoped>
@import "@/components/euroDisplay/MatchDetailHeader";
</style>
