<template>
    <div
        class="oddsDisplayHeader"
        v-bind:class="{ live: isLive, sticky: isSticky }"
    >
        <template v-for="column in columns" v-bind:key="column.key">
            <div class="oddsDisplayHeader_col">
                <div
                    class="oddsDisplayHeader_col_cell"
                    v-bind:type="column.key"
                    v-bind:style="{ width: `${column.width}px` }"
                >
                    <template v-if="column.key === OddsDisplayBlockType.Time">
                        <VButton
                            flat
                            block
                            v-on:click="toggleCollapse"
                        >
                            <SvgIcon
                                name="icon-system-arrow"
                                v-bind:rotated="isCollapsedAll"
                                v-bind:class="{ disabled: !hasEvent }"
                            />
                        </VButton>
                    </template>
                    <template v-else-if="column.key === OddsDisplayBlockType.Team">
                        {{ isLive ? i18n.t('live') : i18n.t('non_live') }}
                    </template>
                </div>
            </div>
        </template>
    </div>
</template>

<script lang="ts" setup>
    import { computed } from 'vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import VButton from '@/components/common/button/VButton.vue';
    import { getOddsTableColumnsOutright } from '@/components/oddsDisplay/oddsTableCalculation';
    import { OutrightOddsDisplayContext } from '@/components/oddsDisplay/outright/OutrightOddsDisplayContext';
    import { i18n } from '@/core/lib/i18n';
    import { OddsDisplayBlockType } from '@/interface/enum';

    defineProps<{
        isLive: boolean;
        isSticky: boolean;
    }>();

    const { leagues, isCollapsedAll } = OutrightOddsDisplayContext.inject();
    const columns = computed(() => getOddsTableColumnsOutright(false));
    const hasEvent = computed(() => leagues.value.length > 0);

    function toggleCollapse() {
        if (!hasEvent.value) return;
        isCollapsedAll.value = !isCollapsedAll.value;
    }
</script>

<style lang="scss" scoped>
    @import "@/components/oddsDisplay/OddsDisplayHeader";
</style>
