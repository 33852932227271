<template>
    <OddsDisplayBlock
        v-bind:groups="groups"
        v-bind:loading="!loaded"
        v-bind:initialIsExpanded="() => true"
        enableVirtualScroll
    />

    <Teleport v-if="info" to=".leagueInfoIcon">
        <!-- teleport to src/views/euro/betting/League.vue -->
        <SvgIcon
            v-tooltip="info"
            name="icon-system-cycle-info"
            right
        />
    </Teleport>
</template>

<script lang="ts" setup>
    import { groupBy, orderBy } from 'lodash';
    import { computed, watchEffect } from 'vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import OddsDisplayBlock from '@/components/euroDisplay/oddsDisplay/OddsDisplayBlock.vue';
    import { useEuroRoute } from '@/composable/useEuroRoute';
    import { formatDateWithLocale } from '@/core/lib/date';
    import { useEvents } from '@/core/oddsApi/composable/useEvents.euro';
    import { EnumEventDateFilterType, EnumOddsCategoryType, EnumPresetFilterType } from '@/core/oddsApi/oddsApiType';

    const { sportType, leagueId, leagueName, regionName, updateNameParams } = useEuroRoute();

    const leagueIds = computed(() => [leagueId.value]);

    const { events, loaded } = useEvents({
        sportType,
        presetFilter: { presetFilter: EnumPresetFilterType.All, date: EnumEventDateFilterType.All },
        oddsCategory: EnumOddsCategoryType.All,
        leagueIds,
    });

    const groups = computed(() => {
        const titleFormatter = (showTime: Date) => formatDateWithLocale(showTime, 'E MM/dd');
        const groups = Object.values(groupBy(events.value, e => titleFormatter(e.showTime)));
        return groups.map((group) => {
            const events = orderBy(group, [e => e.isLive, e => e.eventCode], ['desc', 'asc']);
            return {
                id: events[0].id,
                events,
                title: titleFormatter(events[0].showTime),
            };
        });
    });

    watchEffect(() => {
        if (!events.value[0]) return;
        if (events.value[0].league.region.name === regionName.value
            && events.value[0].league.name === leagueName.value) return;

        updateNameParams({
            regionName: events.value[0].league.region.name,
            leagueName: events.value[0].league.name,
        });
    });

    const info = computed(() => events.value[0]?.league.info ?? '');
</script>
