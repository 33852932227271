import { useThrottleFn } from '@vueuse/core';
import orderBy from 'lodash/orderBy';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useEventEmitter } from '@/composable/useEventEmitter';
import { addHour, getNowWithGMT } from '@/core/lib/date';
import { defineContext } from '@/core/lib/defineContext';
import { useEvents } from '@/core/oddsApi/composable/useEvents';
import { getEventPresetFilterInputType, getOddsCategory } from '@/core/oddsApi/helpers';
import type { IEvent } from '@/interface/IEvent';
import { EventDateType, MarketPage, OddsDaylightType, OddsSorting, SportType } from '@/interface/enum';
import { useAsiaSportMarketInfoStore } from '@/store/asiaSportMarketInfoStore';
import { useCustomerStore } from '@/store/customerStore';
import { useOddsDisplaySettingsStore } from '@/store/oddsDisplaySettingsStore';
import { useSelectedLeagueStore } from '@/store/selectedLeagueStore';

const HoursMapping: Partial<Record<OddsDaylightType, number>> = {
    [OddsDaylightType.Within3Hours]: 3,
    [OddsDaylightType.Within6Hours]: 6,
};

// each OddsDisplayBlockContext instance will only serve for one sport / market OddsDisplayBlock.vue
export const OddsDisplayBlockContext = defineContext('OddsDisplayBlock', () => {
    const { selectedLeagueIds } = storeToRefs(useSelectedLeagueStore());

    const { oddsSorting } = storeToRefs(useCustomerStore());

    // these properties are static inside the context
    const {
        sportType,
        marketPage,
        eventDate,
    } = useAsiaSportMarketInfoStore();

    const { oddsDisplayMode } = useOddsDisplaySettingsStore();
    const { isMyFavorite } = storeToRefs(useAsiaSportMarketInfoStore());

    const {
        daylightType,
        filteredLeagueIds,
        filteredEventIds,
        filteredLeagueNames,
        favoriteCount,
    } = storeToRefs(useAsiaSportMarketInfoStore());

    const isEnabledEvents = computed(() => {
        if (isMyFavorite.value) return favoriteCount.value > 0;
        if (marketPage === MarketPage.EarlyMarket) return eventDate !== EventDateType.Unknown;

        return true;
    });

    const presetFilter = getEventPresetFilterInputType(marketPage, eventDate);
    const oddsCategory = getOddsCategory(marketPage);

    const { events, loaded, loading, eventsError, refetch } = useEvents({
        sportType,
        presetFilter,
        oddsCategory,
        eventIds: filteredEventIds,
        leagueIds: filteredLeagueIds,
        leagueNames: filteredLeagueNames,
        isActive: isEnabledEvents,
        isResetResults: !isMyFavorite.value,
    });

    const throttledRefetch = useThrottleFn(refetch, 5000, false, true);
    const eventEmitter = useEventEmitter();
    eventEmitter.on('oddsDisplay:refreshEventList', () => {
        throttledRefetch();
    });

    const sortedEvents = computed<IEvent[]>(() => orderBy(events.value, event => (oddsSorting.value === OddsSorting.ShowTime
        ? event.showTime
        : event.eventCode)));

    const filteredEvents = computed<IEvent[]>(() => (selectedLeagueIds.value.length > 0
        ? sortedEvents.value.filter(x => selectedLeagueIds.value.includes(x.league.id))
        : sortedEvents.value));

    const shouldFilterByDaylight = computed(() => sportType === SportType.Soccer
        && marketPage === MarketPage.Today
        && [OddsDaylightType.Within3Hours, OddsDaylightType.Within6Hours].includes(daylightType.value));

    const displayEvents = computed<IEvent[]>(() => {
        if (!isEnabledEvents.value) return [];
        if (!shouldFilterByDaylight.value) return filteredEvents.value;

        return filteredEvents.value.filter((x) => {
            const serverTimeNow = getNowWithGMT(-4);
            const threshold = addHour(serverTimeNow, HoursMapping[daylightType.value] ?? 0);
            return x.kickOffTime <= threshold;
        });
    });

    return {
        sportType,
        marketPage,
        oddsDisplayMode,
        loaded,
        loading,
        eventsError,
        refetch,
        events,
        displayEvents,
        isEnabledEvents,
    };
});
