<template>
    <ScoreAlertEvent
        v-for="eventId in eventIds"
        v-bind:key="eventId"
        v-bind:eventId="eventId"
    />
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import ScoreAlertEvent from '@/components/scoreAlert/ScoreAlertEvent.vue';
    import { useScoreAlertStore } from '@/store/scoreAlertStore';

    const { eventIds } = storeToRefs(useScoreAlertStore());
</script>
