import type { CSSProperties } from 'vue';

export interface CSSPropertiesWithVars extends CSSProperties {
    [key: `--${string}`]: string | number;
}

export function parsePadding(padding: string | number) {
    if (typeof padding === 'number') {
        if (Number.isNaN(padding)) return null;
        return `${padding}px`;
    }

    if (typeof padding === 'string') {
        if (padding === '') return null;
        if (/^\d+$/.test(padding)) return `${padding}px`;
        return padding;
    }

    return null;
}
