<template>
    <div class="placeholder" v-bind:style="{ 'min-height': `${outrightMatchPlaceHolderHeight}px` }">
        <div>
            {{ league.name }}
        </div>
        <div
            v-for="(oddsItem, index) in leftColumnOdds"
            v-if="!isCollapsed"
            v-bind:key="oddsItem.id"
        >
            {{ oddsItem.teamName }}
            <template v-if="rightColumnOdds[index]">
                <div>
                    {{ rightColumnOdds[index].teamName }}
                </div>
            </template>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { orderBy } from 'lodash';
    import { type PropType, computed } from 'vue';
    import { toRefs } from 'vue';
    import { getOutrightMatchPlaceHolderHeight } from '@/components/oddsDisplay/oddsTableCalculation';
    import { OutrightOddsDisplayContext } from '@/components/oddsDisplay/outright/OutrightOddsDisplayContext';
    import { useOutrightOdds } from '@/core/oddsApi/composable/useOutrightOdds';
    import type { IOutrightLeague } from '@/interface/IOutrightLeague';

    const props = defineProps({
        league: {
            type: Object as PropType<IOutrightLeague>,
            required: true,
        },
        isLive: {
            type: Boolean,
            required: true,
        },
    });

    const { league, isLive } = toRefs(props);

    const { sportType, isItemCollapsedRef } = OutrightOddsDisplayContext.inject();
    const { outrightOdds } = useOutrightOdds(sportType.value, league.value.id, isLive.value);

    const sortedOutrightOdds = computed(() => orderBy(outrightOdds.value, [x => x.price, x => x.teamName.toUpperCase()]));
    const leftColumnOdds = computed(() => sortedOutrightOdds.value.slice(0, Math.ceil(sortedOutrightOdds.value.length / 2)));
    const rightColumnOdds = computed(() => sortedOutrightOdds.value.slice(Math.ceil(sortedOutrightOdds.value.length / 2)));

    const outrightMatchPlaceHolderHeight = computed(() => getOutrightMatchPlaceHolderHeight(league.value));

    const isCollapsed = isItemCollapsedRef(league.value.id);
</script>

<style lang="scss" scoped>
.placeholder {
    opacity: 0;
}
</style>
