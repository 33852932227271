<template>
    <div class="optionOddsGroup notranslate">
        <template v-if="pointDisplay">
            <span class="optionOdds optionOdds-point" v-bind:class="{ 'optionOdds-change': isPointChange }">{{ pointDisplay }}</span>
            <span class="optionOdds optionOdds-at">@</span>
        </template>
        <template v-if="liveScoreDisplay">
            <span class="optionOdds optionOdds-score" v-bind:class="{ 'optionOdds-change': isScoreChange }">{{ liveScoreDisplay }}</span>
            <span class="optionOdds optionOdds-at">@</span>
        </template>
        <template v-if="priceDisplay">
            <span class="optionOdds optionOdds-price" v-bind:class="{ 'optionOdds-change': isPriceChange }">
                <del v-if="isPriceChange" class="optionOddsPrice-old">{{ oldPriceDisplay }}</del>
                <strong class="optionOddsPrice" v-bind:class="{ 'optionOddsPrice-negative': isNegativePrice }">{{ priceDisplay }}</strong>
                <div
                    v-if="isPriceChange"
                    class="oddsStates"
                    v-bind:class="{
                        'oddsStates-rise': priceChangeStatus === PriceChangeStatus.Rise,
                        'oddsStates-drop': priceChangeStatus === PriceChangeStatus.Drop,
                    }"
                >
                    <div class="triangle" />
                </div>
            </span>
        </template>
    </div>
</template>

<script lang="ts" setup>
    import { TicketContext } from '@/components/betSlip/TicketContext';
    import { PriceChangeStatus } from '@/interface/enum';

    const {
        pointDisplay,
        isPointChange,
        priceDisplay,
        oldPriceDisplay,
        isPriceChange,
        priceChangeStatus,
        isNegativePrice,
        liveScoreDisplay,
        isScoreChange,
    } = TicketContext.inject();
</script>

<style lang="scss" scoped>
    @import "@/components/betSlip/Ticket";
</style>
