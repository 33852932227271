import { storeToRefs } from 'pinia';
import type { Ref } from 'vue';
import { computed } from 'vue';
import type { ITicket, ITicketEuro } from '@/interface/IBetSlip';
import type { IDynamicI18nConfig } from '@/interface/IDynamicI18nConfig';
import { useToggleStore } from '@/store/toggleStore';

export const MIN_SUB_BET_COUNT = 3;

export const MIN_MP_TWO_SUB_BET_COUNT = 2;

export function useMpInvalidMessageDisplay(tickets: Ref<(ITicketEuro[] | ITicket[])>, isMixParlay: Ref<boolean>): {
    isValidSubBetCount: Ref<boolean>;
    invalidSubBetMessage: Ref<IDynamicI18nConfig | null>;
} {
    const { mpTwoSubBetLeagueIds, isMpTwoSubBetEnabled } = storeToRefs(useToggleStore());

    const isValidMpTwoSubBet = computed(() => {
        const isAllMpTwoSubBet = tickets.value.every(ticket => mpTwoSubBetLeagueIds.value.includes(ticket.event.league.id));
        return isMpTwoSubBetEnabled.value && isAllMpTwoSubBet;
    });

    const isMpWithTickets = computed(() => isMixParlay.value && tickets.value.length > 0);

    const isValidSubBetCount = computed(() => {
        if (isValidMpTwoSubBet.value && tickets.value.length >= MIN_MP_TWO_SUB_BET_COUNT) {
            return true;
        }
        return tickets.value.length >= MIN_SUB_BET_COUNT;
    });

    const invalidSubBetMessage = computed<IDynamicI18nConfig | null>(() => {
        if (isValidSubBetCount.value || !isMpWithTickets.value) {
            return null;
        }

        if (isValidMpTwoSubBet.value) {
            return {
                key: 'bet_slip_message_mp_two_sub_bets',
                slots: [{
                    name: 'events',
                    key: 'bet_slip_message_mp_two_sub_bets_slot_events',
                    wrapper: {
                        tag: 'b',
                    },
                }],
            };
        }

        return {
            key: 'bet_slip_error_message_less_than_three_bets',
        };
    });

    return {
        isValidSubBetCount,
        invalidSubBetMessage,
    };
}
