/* eslint-disable import/export */
import {
    PoolType as PolluxPoolType,
    TriggerResult as PolluxTriggerResult,
} from '@/interface/pollux';

export enum Method {
    TotalPoolAmount = 'TotalPoolAmount',
    PoolSummary = 'PoolSummary',
    Winner = 'Winner',
}

export enum JackpotPoolType {
    Unknown = 'Unknown',
    Mini = 'Mini',
    Minor = 'Minor',
    Major = 'Major',
    Mega = 'Mega',
}

export namespace JackpotPoolType {
    export function ToPollux(triggerResult: JackpotPoolType): PolluxPoolType {
        const mapping: Record<JackpotPoolType, PolluxPoolType> = {
            [JackpotPoolType.Unknown]: PolluxPoolType.Unknown,
            [JackpotPoolType.Mini]: PolluxPoolType.Mini,
            [JackpotPoolType.Minor]: PolluxPoolType.Minor,
            [JackpotPoolType.Major]: PolluxPoolType.Major,
            [JackpotPoolType.Mega]: PolluxPoolType.Mega,
        };
        return mapping[triggerResult] ?? PolluxPoolType.Unknown;
    }
}

export enum TriggerResult {
    Success = 0,
    NotPendingStatus = 1,
    NoWinnerName = 2,
    LessThan4DaysFromTriggerDate = 3,
    GeneralError = 4,
    NotApprovedStatus = 5,
}

export namespace TriggerResult {
    export function FromPollux(triggerResult: PolluxTriggerResult): TriggerResult {
        const mapping: Record<PolluxTriggerResult, TriggerResult> = {
            [PolluxTriggerResult.Success]: TriggerResult.Success,
            [PolluxTriggerResult.NotPendingStatus]: TriggerResult.NotPendingStatus,
            [PolluxTriggerResult.NoWinnerName]: TriggerResult.NoWinnerName,
            [PolluxTriggerResult.LessThan4DaysFromTriggerDate]: TriggerResult.LessThan4DaysFromTriggerDate,
            [PolluxTriggerResult.GeneralError]: TriggerResult.GeneralError,
            [PolluxTriggerResult.NotApprovedStatus]: TriggerResult.NotApprovedStatus,
        };

        return mapping[triggerResult] ?? TriggerResult.GeneralError;
    }
}

export interface PoolSummaryRaw {
    PoolAmount: string;
    Round: number;
    PoolType: JackpotPoolType;
    TargetTriggerAmount: string;
    MinQualifyingAmount: string;
    TotalNumberOfWinner: number;
    TotalWonAmount: string;
    LastWinner: string | null;
    LastWonAmount: string | null;
    LastWinnerBetId: number | null;
    LastTriggeredTime: string | null;
}

export interface PoolSummary {
    poolAmount: number;
    round: number;
    poolType: JackpotPoolType;
    targetTriggerAmount: number;
    minQualifyingAmount: number;
    totalNumberOfWinner: number;
    totalWonAmount: number;
    lastWinner: string | null;
    lastWonAmount: number | null;
    lastWinnerBetId: number | null;
    lastTriggeredTime: Date | null;
}

export interface WinnerRaw {
    LoginName: string;
    Type: JackpotPoolType;
    Round: number;
    Amount: string;
}

export interface Winner {
    loginName: string;
    type: JackpotPoolType;
    round: number;
    amount: number;
}
