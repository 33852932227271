<template>
    <div
        ref="containerRef"
        class="slideTabs"
    >
        <template
            v-for="tab in tabs"
            v-bind:key="tab.key"
        >
            <hr v-if="tab.isShowDivider" class="slideTabs_divider">
            <div
                v-bind:ref="(el) => setTabRef(tab.key, el)"
                class="slideTabs_item hover_overlay"
                v-bind:class="{ slideTabs_item_hasCount: tab.isShowCount }"
                v-on:click="tab.onClick"
            >
                {{ tab.name }}

                <Count
                    v-if="tab.isShowCount"
                    v-bind:number="tab.count"
                    bgColor="nonLive"
                    textColor="white"
                />
            </div>
        </template>
        <div
            v-if="items.length > 0"
            class="slideTabs_slider"
            v-bind:style="sliderStyle"
        />
    </div>
</template>

<script lang="ts" setup>
    import type { CSSProperties, PropType } from 'vue';
    import { nextTick, ref, watch } from 'vue';
    import Count from '@/components/common/Count.vue';
    import type { ISlideTabItem } from '@/interface/ISlideTabItem';
    import type { IColors, IRootObject } from '@/interface/sassVariables';

    const props = defineProps({
        items: {
            type: Array as PropType<ISlideTabItem[]>,
            default: () => [],
        },
        countBgColor: {
            type: String as PropType<keyof IColors | keyof IRootObject>,
            default: () => 'countBgColor',
        },
        countTextColor: {
            type: String as PropType<keyof IColors | keyof IRootObject>,
            default: () => 'countColor',
        },
        countBorderColor: {
            type: String as PropType<keyof IColors | keyof IRootObject>,
            default: () => 'nonLive',
        },
    });

    const currentTab = ref<ISlideTabItem['key'] | null>(null);
    const tabs = ref<ISlideTabItem[]>([...props.items]);

    watch(() => props.items, (newValue) => {
        tabs.value = [...newValue];
        currentTab.value = tabs.value.find(item => item.isActive)?.key ?? null;
        nextTick(() => animate());
    }, { immediate: true });

    const containerRef = ref<HTMLDivElement | null>(null);
    const tabRefs = ref<{ [key: ISlideTabItem['key']]: HTMLElement }>({});
    const setTabRef = (key: ISlideTabItem['key'], ref: any) => {
        tabRefs.value[key] = ref;
    };

    const sliderStyle = ref<CSSProperties>({});

    function animate() {
        const currTab = currentTab.value;
        if (currTab === null) {
            sliderStyle.value.width = 0;
            return;
        }

        const currentTabRef = tabRefs.value[currTab];
        if (containerRef.value && currentTabRef) {
            const rect = currentTabRef.getBoundingClientRect();
            const containerRect = containerRef.value.getBoundingClientRect();

            sliderStyle.value.transform = `translateX(${rect.left - containerRect.left}px) translateZ(0)`;
            sliderStyle.value.width = `${rect.width}px`;
        }
    }

</script>

<style lang="scss" scoped>
    @import "@/components/common/slideTab/SlideTab";
    @import "@/components/common/navbar/NavbarDivider";
    @import "@/components/common/navbar/NavbarItem";
</style>
