<template>
    <div
        v-gtm:label="isSelected ? 'Odds_Unclick' : 'Odds'"
        v-gtm:@click
        v-debug:oddsId="oddsId"
        class="oddsArea"
        v-on:click="handleClick"
    >
        <div
            v-bind:class="['odds', {
                'odds-rise': !isOddsSuspend && isOddsRise,
                'odds-drop': !isOddsSuspend && isOddsDrop,
                'odds-picked': !isOddsSuspend && isSelected,
                'odds-suspended': isOddsSuspend,
                'odds-negative': isNegative,
            }]"
        >
            <div v-if="isOddsSuspend" class="oddsSuspend">
                <SvgIcon name="icon-system-odds-lock" />
            </div>
            <div
                v-else
                class="oddsValue"
                v-bind:style="{ textAlign: align }"
            >
                {{ priceDisplay }}
            </div>
        </div>
        <div class="oddsStates" v-bind:class="oddsChangeStates">
            <div v-show="oddsChangeStates" class="triangle" />
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { toRefs } from '@vueuse/core';
    import { storeToRefs } from 'pinia';
    import type { PropType } from 'vue';
    import { computed } from 'vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import { OddsDisplayContext } from '@/components/oddsDisplay/OddsDisplayContext';
    import { OddsDisplayMatchContext } from '@/components/oddsDisplay/OddsDisplayMatchContext';
    import { useBetPage } from '@/composable/useBetPage';
    import { usePriceChangeStatus } from '@/composable/usePriceChangeStatus';
    import { formatMinDecimalPlace } from '@/core/lib/numberFormat';
    import { EnumOddsStatus } from '@/core/oddsApi/oddsApiType';
    import { BetSlipType } from '@/interface/IBetSlip';
    import type { IPrice } from '@/interface/IOdds';
    import { MarketPage, MarketType, PriceChangeStatus, TabName } from '@/interface/enum';
    import { useAsiaSportMarketInfoStore } from '@/store/asiaSportMarketInfoStore';
    import { useBetSlipStore } from '@/store/betSlipStore';
    import { useLayoutStore } from '@/store/layoutStore';

    const props = defineProps({
        eventId: {
            type: Number,
            required: true,
        },
        oddsId: {
            type: Number,
            required: true,
        },
        oddsPrice: {
            type: Object as PropType<IPrice>,
            required: true,
        },
        oddsStatus: {
            type: String as PropType<EnumOddsStatus>,
            required: true,
        },
        minDecimalPlace: {
            type: Number as PropType<number>,
            default: 2,
        },
        align: {
            type: String as PropType<'left' | 'center' | 'right'>,
            default: 'right',
        },
    });

    const { eventId, oddsId, oddsPrice, oddsStatus, minDecimalPlace } = toRefs(props);
    const { marketPage } = storeToRefs(useAsiaSportMarketInfoStore());
    const { singleTicket, parlayBetSlip } = storeToRefs(useBetSlipStore());
    const {
        addToSingleBetSlip, removeSingleBetSlip,
        addToParlayBetSlip, getParlayTicketKey, removeParlayTicket,
    } = useBetSlipStore();
    const isSelected = computed(() => {
        if (marketPage.value === MarketPage.MixParlay) {
            return parlayBetSlip.value?.tickets.some(
                ticket => ticket.odds.id === oddsId.value
                    && ticket.priceOption.option === oddsPrice.value.option,
            );
        }

        return !!singleTicket.value
            && singleTicket.value.odds.id === oddsId.value
            && singleTicket.value.priceOption.option === oddsPrice.value.option;
    });
    const isOddsSuspend = computed(() => oddsStatus.value === EnumOddsStatus.Suspend);
    const isNegative = computed(() => oddsPrice.value.price < 0);

    const price = computed(() => oddsPrice.value.price);
    const priceDisplay = computed(() => formatMinDecimalPlace(oddsPrice.value.price, minDecimalPlace.value));

    const { priceChangeStatus } = usePriceChangeStatus(price);
    const isOddsRise = computed(() => priceChangeStatus.value === PriceChangeStatus.Rise);
    const isOddsDrop = computed(() => priceChangeStatus.value === PriceChangeStatus.Drop);
    const oddsChangeStates = computed(() => {
        if (isOddsSuspend.value) return '';
        if (isOddsRise.value) return 'oddsStates-rise';
        if (isOddsDrop.value) return 'oddsStates-drop';
        return '';
    });

    const { betPage } = useBetPage();

    const { isLayoutExpanded } = storeToRefs(useLayoutStore());
    const { toggleIsSportListExpanded, toggleBetSlipExpanded, toggleMyBetsExpanded, setBetSlipActiveTab } = useLayoutStore();

    const { eventCache } = OddsDisplayContext.inject();
    const { oddsCache } = OddsDisplayMatchContext.inject();

    function handleClick() {
        if (oddsStatus.value === EnumOddsStatus.Suspend) return;

        if (marketPage.value === MarketPage.MixParlay) {
            handleClickParlay();
        } else {
            handleClickSingleOrOutright();
        }

        toggleIsSportListExpanded(false);
        toggleBetSlipExpanded(true);
        if (!isLayoutExpanded.value) toggleMyBetsExpanded(false);
    }

    function handleClickSingleOrOutright() {
        const event = eventCache.value.get(eventId.value)!;
        const odds = oddsCache.value.get(oddsId.value)!;

        if (isSelected.value) {
            removeSingleBetSlip();
        } else {
            const betSlipType = odds.marketType === MarketType.OutRight ? BetSlipType.Outright : BetSlipType.Single;
            addToSingleBetSlip(event, odds, oddsPrice.value, betPage.value, betSlipType);
        }
        setBetSlipActiveTab(TabName.Single);
    }

    function handleClickParlay() {
        const event = eventCache.value.get(eventId.value)!;
        const odds = oddsCache.value.get(oddsId.value)!;

        if (isSelected.value) {
            const key = getParlayTicketKey(odds, oddsPrice.value);
            removeParlayTicket(key);
        } else {
            addToParlayBetSlip(event, odds, oddsPrice.value, betPage.value);
        }
        setBetSlipActiveTab(TabName.Parlay);
    }
</script>

<style lang="scss" scoped>
    @import "@/components/odds/Odds";
</style>
