<template>
    <GroupTitle
        v-model:isExpanded="isExpanded"
        v-bind:expandedGtmLabel="null"
    >
        <template #title>
            <div v-if="league.isLive" class="live-box">
                <div class="live-indicator" />
                {{ i18n.t('live') }}
            </div>
            <strong class="nameItem">{{ `${formatLeagueDate(league.eventDate)} - ${league.name}` }}</strong>
            <SvgIcon
                v-if="league.info"
                v-tooltip="league.info"
                name="icon-system-cycle-info"
                right
            />
        </template>
    </GroupTitle>
    <div
        v-if="isExpanded"
        v-debug:leagueId="league.id"
        class="league"
    >
        <OutrightMatch v-if="isExpanded" v-bind:league="league" />
    </div>
</template>

<script lang="ts" setup>
    import { ref } from 'vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import GroupTitle from '@/components/common/groupTitle/GroupTitle.vue';
    import OutrightMatch from '@/components/euroDisplay/oddsDisplay/outright/OutrightMatch.vue';
    import { formatDate, parseISOString } from '@/core/lib/date';
    import { i18n } from '@/core/lib/i18n';
    import type { IOutrightLeagueEuro } from '@/interface/IOutrightLeague';

    const props = withDefaults(defineProps<{
        league: IOutrightLeagueEuro;
        initialIsExpanded?: boolean;
    }>(), {
        initialIsExpanded: true,
    });

    const isExpanded = ref(props.initialIsExpanded);

    const formatLeagueDate = (eventDate: string) => formatDate(parseISOString(eventDate), 'MM/dd/yyyy');
</script>

<style lang="scss" scoped>
@import "@/components/euroDisplay/oddsDisplay/outright/OutrightOddsDisplay";
</style>
