<template>
    <VTableRow
        v-bind:key="result.id"
        v-bind:bgColor="index % 2 === 0 ? 'tableEven' : 'tableOdd'"
    >
        <VTableCell>
            {{ displayDate }}
        </VTableCell>
        <VTableCell>
            {{ result.teamName }}
        </VTableCell>
        <VTableCell>
            <StatusTag
                v-bind:text="i18n.t(getResultStatus(result.result))"
                v-bind:level="getResultLevel(result.result)"
                size="sm"
            />
        </VTableCell>
    </VTableRow>
</template>

<script lang="ts" setup>
    import { computed, toRefs } from 'vue';
    import type { PropType } from 'vue';
    import StatusTag from '@/components/common/statusTag/StatusTag.vue';
    import VTableCell from '@/components/common/table/VTableCell.vue';
    import VTableRow from '@/components/common/table/VTableRow.vue';
    import { formatToServerTime } from '@/core/lib/date';
    import { i18n } from '@/core/lib/i18n';
    import type { IOutrightResult } from '@/interface/IResult';
    import { OutrightSettleStatus } from '@/interface/IResult';

    const props = defineProps({
        result: {
            type: Object as PropType<IOutrightResult>,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    });
    const { result } = toRefs(props);

    const displayDate = computed(() => formatToServerTime(result.value.eventDate, 'MM/dd/yyyy'));

    function getResultStatus(status: OutrightSettleStatus) {
        const mapping: Partial<Record<OutrightSettleStatus, string>> = {
            [OutrightSettleStatus.Won]: 'bet_status_11',
            [OutrightSettleStatus.Refunded]: 'bet_status_5',
        };

        return mapping[status] ?? '';
    }

    function getResultLevel(status: OutrightSettleStatus) {
        const mapping: Partial<Record<OutrightSettleStatus, 'success' | 'danger'>> = {
            [OutrightSettleStatus.Won]: 'success',
            [OutrightSettleStatus.Refunded]: 'danger',
        };

        return mapping[status] ?? 'info';
    }
</script>

<style lang="scss" scoped>
    @import "@/components/results/MatchResult";
</style>
