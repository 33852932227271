import { defineStore, storeToRefs } from 'pinia';
import { computed } from 'vue';
import { formatSelectedLeagueInfo, formatSelectedLeagueInfoRequest } from '@/components/selectLeague/formatSelectedLeagueInfo';
import { useState } from '@/composable/useState';
import { Api } from '@/core/lib/api';
import type { ISelectedLeagueInfo } from '@/interface/ISelectedLeagueInfo';
import { useAsiaSportMarketInfoStore } from '@/store/asiaSportMarketInfoStore';

export const useSelectedLeagueStore = defineStore('SelectedLeague', () => {
    const { sportType, marketPage, daylightType, eventDate, needToShowDaylightType, needToShowEventDates, needToShowMultipleSportsWithDates } = storeToRefs(useAsiaSportMarketInfoStore());
    const [selectedLeagueInfos, setSelectedLeagueInfos] = useState<ISelectedLeagueInfo[]>([]);

    function findCurrentSelectedLeagueInfo(info: ISelectedLeagueInfo) {
        if (info.sportType !== sportType.value) return false;
        if (info.marketPage !== marketPage.value) return false;
        if (needToShowMultipleSportsWithDates.value) return info.eventDate === eventDate.value;
        if (needToShowEventDates.value) return info.eventDate === eventDate.value;
        if (needToShowDaylightType.value) return info.daylightType === daylightType.value;
        return true;
    }

    const selectedLeagueInfo = computed(() => selectedLeagueInfos.value.find(findCurrentSelectedLeagueInfo));
    const selectedLeagueIds = computed(() => selectedLeagueInfo.value?.leagueIds ?? []);
    const isSelectAllBigLeagues = computed(() => selectedLeagueInfo.value?.isSelectAllBigLeagues ?? true);
    const isSelectAllLeagues = computed(() => selectedLeagueInfo.value === undefined);
    const selectedLeagueCount = computed(() => selectedLeagueIds.value.length);

    function setSelectedLeagueInfo(leagueIds: number[], isSelectAllBigLeagues: boolean) {
        const index = selectedLeagueInfos.value.findIndex(findCurrentSelectedLeagueInfo);
        if (index === -1) {
            selectedLeagueInfos.value.push({
                sportType: sportType.value,
                marketPage: marketPage.value,
                eventDate: eventDate.value,
                daylightType: daylightType.value,
                leagueIds,
                isSelectAllBigLeagues,
            });
        } else {
            selectedLeagueInfos.value[index].leagueIds = leagueIds;
            selectedLeagueInfos.value[index].isSelectAllBigLeagues = isSelectAllBigLeagues;
        }
    }

    function selectAllLeagues() {
        const index = selectedLeagueInfos.value.findIndex(findCurrentSelectedLeagueInfo);
        if (index === -1) return;
        selectedLeagueInfos.value.splice(index, 1);
    }

    async function getSelectedLeagueInfos() {
        const { data } = await Api.getSelectedLeagueInfos();
        const results = data.map(x => formatSelectedLeagueInfo(x));
        setSelectedLeagueInfos(results);
    }

    async function syncSelectedLeagueInfosToSession() {
        const request = selectedLeagueInfos.value.map(x => formatSelectedLeagueInfoRequest(x));
        await Api.updateSelectedLeagueInfos(request);
    }

    getSelectedLeagueInfos();

    return {
        selectedLeagueIds,
        isSelectAllBigLeagues,
        isSelectAllLeagues,
        setSelectedLeagueInfo,
        selectAllLeagues,
        selectedLeagueCount,
        getSelectedLeagueInfos,
        syncSelectedLeagueInfosToSession,
    };
});
