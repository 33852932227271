import type { Ref } from 'vue';
import { computed, ref, watch } from 'vue';

/**
 * Explicitly define the deps of computed.
 *
 * @param fn
 */

const compare = (a: any, b: any) => {
    if (a === b) return true;
    return JSON.stringify(a) === JSON.stringify(b);
};

export function deepCompareComputed<T>(fn: () => T): Ref<T> {
    const inner = computed<T>(fn);
    const current = ref<T>(inner.value) as Ref<T>;

    watch(inner, (newValue, oldValue) => {
        if (!compare(newValue, oldValue)) {
            current.value = newValue;
        }
    }, { flush: 'sync', immediate: true });

    return current;
}
