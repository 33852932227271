export function disableScrolling(el = window.document.body) {
    el.style.overflow = 'hidden';
}

export function enableScrolling(el = window.document.body) {
    el.style.overflow = '';
}

export function getScrollTop(): number {
    let bodyTop = 0;
    if (typeof window.scrollY !== 'undefined') {
        bodyTop = window.scrollY;
    } else if (typeof document.compatMode !== 'undefined' && document.compatMode !== 'BackCompat') {
        bodyTop = document.documentElement.scrollTop;
    } else if (typeof document.body !== 'undefined') {
        bodyTop = document.body.scrollTop;
    }
    return bodyTop;
}

export function scrollToTop(scrollDuration: number, scrollDelay = 15) {
    const scrollHeight = window.scrollY;
    const scrollStep = Math.PI / (scrollDuration / 15);
    const cosParameter = scrollHeight / 2;
    let scrollCount = 0;
    let scrollMargin: number;
    const scrollInterval = setInterval(() => {
        if (window.scrollY !== 0) {
            scrollCount += 1;
            scrollMargin = cosParameter - cosParameter * Math.cos(scrollCount * scrollStep);
            window.scrollTo(0, scrollHeight - scrollMargin);
        } else {
            clearInterval(scrollInterval);
        }
    }, scrollDelay);
}

export function scrollToTopImmediate() {
    window.scrollTo(window.scrollX, 0);
}

export function getHeight(selector: string, el: ParentNode = document) {
    return el.querySelector(selector)?.getBoundingClientRect().height ?? 0;
}
