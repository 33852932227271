import type { Ref, WatchCallback, WatchOptions, WatchSource, WatchStopHandle } from 'vue';
import { isRef, nextTick, watch } from 'vue';
import { noop } from '@/core/lib/utils';

/**
 * Shorthand for watching value to be truthy once
 *
 * @example
 * const count = ref(0)
 * once(() => count > 0, () => console.log('count is greater than 0 now'))
 */
export function once<T>(source: WatchSource<T>, cb: WatchCallback<T>, options?: WatchOptions) {
    if (options?.immediate) {
        const result = isRef(source) ? source.value : source();
        if (result) {
            cb(result, null, noop);
            return noop;
        }
    }

    let stop: WatchStopHandle | null = null;
    stop = watch(
        source,
        (v, ov, onInvalidate) => {
            if (v) {
                stop ? stop() : nextTick(() => stop?.());
                cb(v, ov, onInvalidate);
            }
        },
        {
            ...options,
            immediate: false,
        },
    );
    return stop;
}

/**
 * Return a promise which will be resolved when the *condition* being met.
 *
 * @example
 * const count = ref(0)
 * await oncePromise(count, (newCount) => newCount === 99)
 * // do something after count is 99
 */
export function oncePromise<T>(ref: Ref<T>, condition: (refValue: T) => boolean) {
    if (condition(ref.value)) return Promise.resolve(true);

    return new Promise((resolve) => {
        const stop = watch(ref, (newVal) => {
            if (condition(newVal)) {
                stop();
                resolve(true);
            }
        });
    });
}
