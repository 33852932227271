import type { MaybeRef } from '@vueuse/core';
import { get, useIntervalFn } from '@vueuse/core';
import type { Ref } from 'vue';
import { computed, ref, watch } from 'vue';
import { deepCompareComputed } from '@/composable/deepCompareComputed';
import { useIdle } from '@/composable/useIdle';
import { assignArray, remove, upsert } from '@/core/lib/array';
import { i18n } from '@/core/lib/i18n';
import { isInvalidLiveMp1X2Odds, isValidOdds } from '@/core/lib/oddsHelper';
import { deepFreeze } from '@/core/lib/utils';
import { formatOdds } from '@/core/oddsApi/helpers/formatOdds';
import { EnumOddsCategoryType, EnumOddsMarketFilterType, EnumPresetFilterType, useOddsQuery, useOddsSubscription } from '@/core/oddsApi/oddsApiType';
import type { IOdds } from '@/interface/IOdds';
import { EnumLanguage, MarketType, PriceStyle } from '@/interface/enum';
import { useBehaviorStore } from '@/store/behaviorStore';

export function useEventOddsEuro({
    eventId,
    isLive,
    isMixParlay,
    marketTypes,
    priceStyle,
    marketFilter,
    isActive = ref(true),
}: {
    eventId: number;
    isLive: boolean;
    isMixParlay: boolean;
    marketTypes?: MaybeRef<MarketType[]>;
    priceStyle: MaybeRef<PriceStyle>;
    marketFilter?: MaybeRef<EnumOddsMarketFilterType>;
    isActive?: Ref<boolean>;
}) {
    const variables = computed(() => ({
        query: {
            id: eventId,
            filter: isLive ? EnumPresetFilterType.Live : EnumPresetFilterType.NonLive,
            marketFilter: get(marketFilter) ?? EnumOddsMarketFilterType.MainMarket,
            marketTypes: get(marketTypes)?.map(marketType => MarketType.toOddsApi(marketType)),
            oddsCategory: isMixParlay ? EnumOddsCategoryType.MixParlay : EnumOddsCategoryType.All,
            priceStyle: PriceStyle.toOddsApi(get(priceStyle)),
            lang: EnumLanguage.toOddsApi(i18n.locale.value),
        },
    }));

    const { loaded, loading, onResult, refetch } = useOddsQuery(
        variables,
        deepCompareComputed(() => ({
            enabled: isActive.value,
        })),
    );

    const refreshInterval = computed(() => (isLive ? 20 : 60) * 1000);
    useIntervalFn(refetch, refreshInterval);

    // Automatically recover from idle state with a force refresh
    // This is to prevent the user from losing data when they are idle
    const { onActive } = useIdle(60 * 1000);
    onActive(() => {
        // eslint-disable-next-line no-console
        console.log('useEventOddsEuro: Recovering from idle state...');
        refetch();
    });

    const { onSubscription } = useOddsSubscription(
        variables,
        deepCompareComputed(() => ({
            enabled: isActive.value,
        })),
    );

    const _oddsList = ref<IOdds[]>([]);
    const oddsList = computed(() => _oddsList.value.filter(odds => isValidOdds(odds) && !isInvalidLiveMp1X2Odds(odds, isMixParlay)));
    const { addOddsQueryPerEventCount } = useBehaviorStore();
    onResult((result) => {
        const newOddsList = (result ?? [])
            .filter(odds => odds.eventResult?.marketGroup)
            .map(odds => deepFreeze(formatOdds(odds)));
        assignArray(_oddsList.value, newOddsList, item => item.id);

        addOddsQueryPerEventCount(eventId);
    });

    onSubscription((subscription) => {
        const toUpdate = (subscription?.updated ?? [])
            .filter(x => x.eventResult?.marketGroup)
            .map(x => deepFreeze(formatOdds(x)));

        upsert(_oddsList.value, toUpdate, item => item.id);
        remove(_oddsList.value, subscription?.deleted ?? [], (a, b) => a.id === b);
    });

    watch(isActive, (value) => {
        if (!value) {
            loaded.value = false;
        }
    });

    return {
        loaded,
        loading,
        refetch,
        oddsList,
    };
}
