<template>
    <VButton
        ref="placeBetButtonRef"
        v-gtm:@click
        v-gtm:label="GetButtonGtmLabel(buttonDisplay)"
        v-gtm:disabled="!GetButtonGtmLabel(buttonDisplay)"
        block
        textColor="primary"
        class="notranslate placeBet"
        v-bind:disabled="!buttonDisplay.isEnabled"
        v-bind:color="buttonDisplay.color"
        v-on:click="buttonDisplay.action"
    >
        {{ i18n.t(buttonDisplay.title) }}
        <template #icon>
            <StaticSpinner v-if="isLoading" v-bind:margin="`auto`" />
        </template>
    </VButton>
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import type { VNodeRef } from 'vue';
    import { computed, ref } from 'vue';
    import StaticSpinner from '@/components/common/StaticSpinner.vue';
    import VButton from '@/components/common/button/VButton.vue';
    import { useEventEmitter } from '@/composable/useEventEmitter';
    import { sportsGtm } from '@/core/lib/gtm';
    import { i18n } from '@/core/lib/i18n';
    import { noop } from '@/core/lib/utils';
    import { BetSlipType } from '@/interface/IBetSlip';
    import type { IBetSlipEuro } from '@/interface/IBetSlip';

    import type { IPlaceBetButton } from '@/interface/IPlaceBetButton';
    import { BetSlipsEuroStatus } from '@/interface/IPlaceBetButton';
    import { useEuroBetSlipStore } from '@/store/betSlipStore.euro';

    const props = defineProps<{
        betSlipType: BetSlipType;
    }>();

    const placeBetButtonRef = ref<VNodeRef | null>(null);

    const { singleBetSlips, mixParlayBetSlip } = storeToRefs(useEuroBetSlipStore());
    const betSlips = computed(() => (props.betSlipType === BetSlipType.Single ? singleBetSlips.value : [mixParlayBetSlip.value]));
    const { useBetSlipsStoreByType } = useEuroBetSlipStore();
    const { placeBet, placeBetStatus, betSlipStatusList } = useBetSlipsStoreByType(props.betSlipType);

    const isStakeMoreThanMaxBet = (betSlip: IBetSlipEuro) => betSlip.stake > betSlip.maxBet;
    const isStakeLessThanMinBet = (betSlip: IBetSlipEuro) => betSlip.stake < betSlip.minBet;

    const betSlipsWithStake = computed(() => betSlips.value.filter(x => x.stake > 0));
    const updateStakeToValid = (betSlip: IBetSlipEuro) => {
        if (isStakeMoreThanMaxBet(betSlip)) {
            betSlip.stake = betSlip.maxBet;
        } else if (isStakeLessThanMinBet(betSlip)) {
            betSlip.stake = betSlip.minBet;
        }
    };

    function updateStakeAction() {
        betSlipsWithStake.value.forEach(betSlip => updateStakeToValid(betSlip));
    }

    function GetButtonGtmLabel(button: IPlaceBetButton) {
        if (button.title.includes('place_bet')) {
            return 'PlaceBet';
        }
        if (button.title.includes('update_stake')) {
            return 'UpdateStake';
        }
        return null;
    }
    const eventEmitter = useEventEmitter();

    const unavailableButton: IPlaceBetButton = {
        title: 'bet_slip_place_bet',
        isEnabled: false,
        color: 'accent',
        action: noop,
    };

    const acceptChangeButton: IPlaceBetButton = {
        title: 'bet_slip_accept_change',
        isEnabled: true,
        color: 'yellow-600',
        action: () => {
            eventEmitter.emit('ticket:accept:price:change');
            if (BetSlipsEuroStatus.isInvalidStake(betSlipStatusList.value)) {
                updateStakeAction();
            }
        },
    };
    const placeBetButton: IPlaceBetButton = {
        title: 'bet_slip_place_bet',
        isEnabled: true,
        color: 'accent',
        action: () => {
            placeBet();

            if (props.betSlipType === BetSlipType.Single && placeBetButtonRef.value && placeBetButtonRef.value.elementRef) {
                const gtmPayload = sportsGtm.getGtmEventFromEl(placeBetButtonRef.value.elementRef);
                sportsGtm.pushToDataLayer('click', {
                    ...gtmPayload,
                    label: 'multiSingleBet',
                    value: betSlips.value.length,
                });
            }
        },
    };
    const updateStakeButton: IPlaceBetButton = {
        title: 'bet_slip_update_stake',
        isEnabled: true,
        color: 'yellow-600',
        action: updateStakeAction,

    };
    const loadingButton: IPlaceBetButton = {
        title: '',
        isEnabled: true,
        color: 'accent',
        action: noop,

    };
    const buttonStatusMapping: Record<BetSlipsEuroStatus, IPlaceBetButton> = {
        [BetSlipsEuroStatus.Valid]: placeBetButton,
        [BetSlipsEuroStatus.LessThanMinBet]: updateStakeButton,
        [BetSlipsEuroStatus.GreaterThanMaxBet]: updateStakeButton,
        [BetSlipsEuroStatus.PriceChange]: acceptChangeButton,
        [BetSlipsEuroStatus.Unavailable]: unavailableButton,
        [BetSlipsEuroStatus.Loading]: loadingButton,
    };

    const buttonDisplay = computed<IPlaceBetButton>(() => {
        const status = placeBetStatus.value;
        return buttonStatusMapping[status];
    });

    const isLoading = computed(() => placeBetStatus.value === BetSlipsEuroStatus.Loading);
</script>

<style lang="scss" scoped>
@import "@/components/betSlip/PlaceBetBlockEuro";
</style>
