<template>
    <div class="sectionTabs">
        <span
            v-for="tab in tabs"
            v-bind:key="tab.value"
            v-gtm:label="tab.gtmLabel"
            v-gtm:@click
            class="sectionTab hover_overlay"
            v-bind:class="{ 'sectionTab-active': activeTab === tab.value }"
            v-on:click="setActiveTab(tab.value)"
        >
            {{ tab.title }}
        </span>
    </div>
</template>

<script lang="ts" setup>
    import { ref } from 'vue';
    import type { ITabItem } from '@/interface/ITabItem';

    const props = defineProps<{
        tabs: ITabItem[];
    }>();

    const activeTab = ref(props.tabs[0].value);

    const emit = defineEmits(['changeTab']);

    const setActiveTab = (tab: string) => {
        activeTab.value = tab;
        emit('changeTab', tab);
    };
</script>

<style lang="scss" scoped>
    @import "@/components/exchange/SectionTabs";
</style>
