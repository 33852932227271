import { useEventListener, useTimeoutFn } from '@vueuse/core';
import { getCasaApiUrl } from '@/core/lib/url';

export const accountSecondaryDbDelay = 1000;

export function usePlatform() {
    function logout() {
        const event = new CustomEvent('platform:logout');
        window.dispatchEvent(event);
    }

    function refreshBalance() {
        const event = new CustomEvent('platform:balance-changed');
        window.dispatchEvent(event);
    }

    function refreshBalanceAfterAction() {
        useTimeoutFn(() => {
            refreshBalance();
        }, accountSecondaryDbDelay);
    }

    function onOpenMyBets(callback: () => void) {
        useEventListener(window, 'platform:open-my-bets', callback);
    }

    function onBackToClassicDesktop(callback: () => void) {
        useEventListener(window, 'platform:back-to-classic-desktop', callback);
    }

    // fix when user being quarantined and logout and be redirected to bsi
    // but user clicked back button to kratos
    // user will not be immediate logout in safari
    // the fix here is a bit tricky that we rely on the balance api to check if user is still logged in
    function safariBackPageCallBack() {
        if (isHistoryBackForward()) {
            refreshBalance();
        }
    }

    function isHistoryBackForward() {
        return (window.performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming | undefined)?.type === 'back_forward'
            || window.performance.navigation.type === 2;
    }

    function openDepositPage() {
        const casaUrl = getCasaApiUrl();
        window.open(`${casaUrl}/api/Product/Switch?product=Payment&from=Sports&page=Deposit`, '_blank');
    }

    function openLiveChat() {
        const event = new CustomEvent('platform:open-liveChat');
        window.dispatchEvent(event);
    }

    function openStatement() {
        const event = new CustomEvent('platform:open-memberCenter', { detail: 'Statement' });
        window.dispatchEvent(event);
    }

    return {
        logout,
        onOpenMyBets,
        onBackToClassicDesktop,
        safariBackPageCallBack,
        openDepositPage,
        openLiveChat,
        openStatement,
        refreshBalanceAfterAction,
    };
}
