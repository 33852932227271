import { storeToRefs } from 'pinia';
import { computed, nextTick } from 'vue';
import { scrollToTopImmediate } from '@/core/lib/dom';
import { i18n } from '@/core/lib/i18n';
import { MarketPage } from '@/interface/enum';
import { useAsiaSportMarketInfoStore } from '@/store/asiaSportMarketInfoStore';

export function useTimeSelection() {
    const { marketPage, needToShowDaylightType, needToShowEventDates, needToShowMultipleSportsWithDates } = storeToRefs(useAsiaSportMarketInfoStore());
    const { eventDate, eventDates, daylightType } = storeToRefs(useAsiaSportMarketInfoStore());
    const { setEventDate, setMarketPage, setDaylightType, daylightTypes } = useAsiaSportMarketInfoStore();

    const timeSelections = computed(() => {
        if (needToShowMultipleSportsWithDates.value) {
            return eventDates.value.map(x => ({
                key: `${x.marketPage}-${x.date}`,
                name: x.displayName,
                isActive: eventDate.value === x.date && marketPage.value === x.marketPage,
                isShowCount: x.marketPage === MarketPage.Outright,
                isShowDivider: eventDates.value.length > 1 && x.marketPage === MarketPage.Outright,
                count: x.totalEventCount,
                onClick: async () => {
                    setMarketPage(x.marketPage);
                    setEventDate(x.date);
                    await nextTick();
                    scrollToTopImmediate();
                },
            }));
        }

        if (needToShowDaylightType.value) {
            return daylightTypes.map(x => ({
                key: x,
                name: i18n.t(`daylight_type_${x}`),
                isActive: daylightType.value === x,
                onClick: async () => {
                    setDaylightType(x);
                    await nextTick();
                    scrollToTopImmediate();
                },
            }));
        }

        if (needToShowEventDates.value) {
            return eventDates.value.map(x => ({
                key: x.date,
                name: x.displayName,
                isActive: eventDate.value === x.date,
                onClick: async () => {
                    setEventDate(x.date);
                    await nextTick();
                    scrollToTopImmediate();
                },
            }));
        }

        return [];
    });

    return {
        timeSelections,
    };
}
