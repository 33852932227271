<template>
    <h3 class="preferences_title">
        {{ i18n.t('main_header_sports') }}
    </h3>
    <div class="preferences_content">
        <div
            v-for="preference in preferences"
            v-show="preference.isEnabled"
            v-bind:key="preference.key"
            class="preferences_selector"
        >
            <label
                class="preferences_selector_label"
                v-bind:for="preference.key"
            >
                {{ preference.title }}
            </label>
            <select
                v-bind:id="preference.key"
                class="preferences_selector_select"
                v-on:change="preference.onChange"
            >
                <option
                    v-for="option in preference.options"
                    v-bind:key="option.key"
                    v-bind:value="option.value"
                    v-bind:selected="option.value === preference.currentValue"
                >
                    {{ option.key }}
                </option>
            </select>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import { computed } from 'vue';
    import { sportsGtm } from '@/core/lib/gtm';
    import { i18n } from '@/core/lib/i18n';
    import { PriceStyle, SiteStyle } from '@/interface/enum';
    import { useCustomerStore } from '@/store/customerStore';

    interface PreferenceOption {
        key: string;
        title: string;
        currentValue: any;
        options: { key: string, value: any }[];
        isEnabled: boolean;
        onChange: (event: Event) => void;
    }

    const { updateSiteStyle, updatePriceStyle } = useCustomerStore();
    const { siteStyle, priceStyle, priceStyleOptions: _priceStyleOptions } = storeToRefs(useCustomerStore());

    const siteStyleOptions = computed(() => [
        { key: i18n.t('setting_regional_display_asia'), value: SiteStyle.Asia },
        { key: i18n.t('setting_regional_display_euro'), value: SiteStyle.Euro },
    ]);

    const priceStyleOptions = computed(() => _priceStyleOptions.value.map(priceStyle => ({
        key: i18n.t(`price_style_${priceStyle}`),
        value: priceStyle,
    })));

    const preferences = computed<PreferenceOption[]>(() => [
        {
            key: 'SiteStyle',
            title: i18n.t('setting_regional_display'),
            options: siteStyleOptions.value,
            currentValue: siteStyle.value,
            isEnabled: true,
            onChange: onSiteStyleChange,
        },
        {
            key: 'PriceStyle',
            title: i18n.t('setting_price_style'),
            currentValue: priceStyle.value,
            options: priceStyleOptions.value,
            onChange: onPriceStyleChange,
            isEnabled: true,
        },
    ]);

    async function onSiteStyleChange(event: Event) {
        const target = event.target as HTMLSelectElement;
        const siteStyleValue = target.value as SiteStyle;
        // eslint-disable-next-line no-restricted-globals, no-alert
        const result = confirm(i18n.t('setting_regional_display_confirm'));
        if (!result) {
            // rollback to previous value
            target.value = siteStyle.value;
            return;
        }

        const gtmPayload = sportsGtm.getGtmEventFromEl(target);
        sportsGtm.pushToDataLayer('click', { ...gtmPayload, label: `Regional_${SiteStyle[siteStyleValue]}` });

        updateSiteStyle(siteStyleValue);
    }

    function onPriceStyleChange(event: Event) {
        const target = event.target as HTMLSelectElement;
        const priceStyleValue: PriceStyle = parseInt(target.value);

        const gtmPayload = sportsGtm.getGtmEventFromEl(target);
        sportsGtm.pushToDataLayer('click', { ...gtmPayload, label: `Odds_${PriceStyle[priceStyleValue]}` });
        updatePriceStyle(priceStyleValue);
    }
</script>

<style lang="scss" scoped>
    @import "@/components/preferences/Preferences";
</style>
