import { CasinoApi } from '@/core/lib/casinoApi';

export interface IOpenCasinoGame {
    gameCode: number;
    gameFrom: number;
    autoJoinType: number;
}

export async function openCasinoGameByApi({ gameCode, gameFrom, autoJoinType }: IOpenCasinoGame) {
    /**
     * We set `target` to gameCode to make sure the window is unique by game.
     * We open blank window first so user do not feel any interaction delay due to slow games api response.
     * We also use this pattern because Safari will block any call to window.open() made after an async call.
     * https://stackoverflow.com/a/39387533
     */
    const popup = window.open('/prepare.html', gameCode.toString(), 'popup=yes,scrollbars=no,resizable=yes,width=940,height=600');
    popup?.focus(); // Bring reused window back to focus

    const { data: { gameUrl } } = await CasinoApi.openCasinoGame(gameCode, gameFrom, autoJoinType);
    if (popup) {
        popup.location.href = gameUrl;
    }
}
