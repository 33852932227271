<template>
    <span class="animated" v-bind:class="animationClass">
        {{ animationContent }}
    </span>
</template>

<script lang="ts" setup>
    import { useTimeoutFn } from '@vueuse/core';
    import { watch } from 'vue';
    import { useState } from '@/composable/useState';

    const props = defineProps({
        content: {
            type: [String, Number],
            default: '',
        },
    });

    const [animationClass, setAnimationClass] = useState('initial');
    const [animationContent, setAnimationContent] = useState(props.content);

    watch(() => props.content, (newVal, oldValue) => {
        const goUp = typeof newVal === 'number' ? newVal > +oldValue : true;
        useTimeoutFn(() => setAnimationClass(goUp ? 'up' : 'down'), 0);
        useTimeoutFn(() => setAnimationContent(newVal), 100);
        useTimeoutFn(() => setAnimationClass(goUp ? 'down' : 'up'), 100);
        useTimeoutFn(() => setAnimationClass('initial'), 200);
    });
</script>

<style lang="scss" scoped>
    .animated {
        display: inline-flex;

        &.up {
            opacity: 0;
            transform: translate3d(0, -100%, 0);
            transition: 0.1s ease-in-out;
        }

        &.down {
            opacity: 0;
            transform: translate3d(0, 100%, 0);
        }

        &.initial {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            transition: 0.1s ease-in-out;
        }
    }
</style>
