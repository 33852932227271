import type { InjectionKey } from 'vue';
import { inject, provide } from 'vue';

// eslint-disable-next-line space-before-function-paren
export function defineContext<F extends (
    (...args: any[]) => any), R = ReturnType<F>>(
    name: string | InjectionKey<R>,
    hook: F,
): { provide: F, inject: (defaultStore?: R) => R } {
    // @ts-expect-error I don't know how to type this
    const provideStore: F = (...parameters: Parameters<F>): R => {
        const store = hook(...parameters);
        provide(name, store);

        return store;
    };

    const injectStore = (defaultStore?: R): R => {
        const store = inject<R>(name);
        if (store) return store;
        if (defaultStore) return defaultStore;

        throw new Error(`[Context]: Inject failed. Context ${String(name)} could not found`);
    };

    return {
        provide: provideStore,
        inject: injectStore,
    };
}
