<template>
    <hr class="navbarDivider">
</template>

<script lang="ts" setup>
</script>

<style lang="scss" scoped>
    @import "@/components/common/navbar/NavbarDivider";
</style>
