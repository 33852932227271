import { computed, nextTick, ref } from 'vue';
import { useState } from '@/composable/useState';
import { assignArray, remove, upsert } from '@/core/lib/array';
import { i18n } from '@/core/lib/i18n';
import { deepFreeze } from '@/core/lib/utils';
import { formatOutrightOdds } from '@/core/oddsApi/helpers/formatOutrightOdds';
import { useOutrightsQuery, useOutrightsSubscription } from '@/core/oddsApi/oddsApiType';
import type { IOutrightOdds } from '@/interface/IOutrightOdds';
import { EnumLanguage, SportType } from '@/interface/enum';

export function useOutrightOdds(
    sportType: SportType,
    leagueId: number,
    isLive: boolean,
    isActive = ref(true),
) {
    const variables = computed(() => ({
        query: {
            sport: SportType.toOddsApi(sportType),
            tournamentId: leagueId,
            isLive,
            lang: EnumLanguage.toOddsApi(i18n.locale.value),
        },
    }));

    const [isEnabled, setIsEnabled] = useState(true);
    const refetch = async () => {
        if (isEnabled.value) {
            setIsEnabled(false);
            nextTick(() => setIsEnabled(true));
        }
    };
    const { loaded, loading, onResult } = useOutrightsQuery(
        variables,
        computed(() => ({
            enabled: isActive.value && isEnabled.value,
        })),
    );

    const { onSubscription } = useOutrightsSubscription(
        variables,
        computed(() => ({
            enabled: isActive.value,
        })),
    );

    const outrightOdds = ref<IOutrightOdds[]>([]);

    onResult((result) => {
        const newOutrightOdds = (result ?? []).map(odds => deepFreeze(formatOutrightOdds(odds)));
        assignArray(outrightOdds.value, newOutrightOdds, x => x.id);
    });

    onSubscription((subscription) => {
        const toUpdate = (subscription?.updated ?? []).map(odds => deepFreeze(formatOutrightOdds(odds)));

        upsert(outrightOdds.value, toUpdate, item => item.id);
        remove(outrightOdds.value, subscription?.deleted ?? [], (a, b) => a.id === b);
    });

    return {
        loaded,
        loading,
        outrightOdds,
        refetch,
    };
}
