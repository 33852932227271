<template>
    <SideBanner class="component" />
    <SportListBlock class="component" />
    <div v-bind:class="{ sticky: isLayoutExpanded }">
        <BetSlipBlock
            class="component component-float betSlipBlock"
            v-bind:class="{
                show: isBetSlipExpanded,
            }"
        />
        <MiniMyBetsBlock
            class="component component-float"
            v-bind:class="{
                show: isMyBetsExpanded,
                autoGrow: isLayoutExpanded && isMyBetsExpanded,
            }"
        />
    </div>
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import SideBanner from '@/components/banner/SideBanner.vue';
    import BetSlipBlock from '@/components/betSlip/BetSlipBlock.vue';
    import MiniMyBetsBlock from '@/components/myBets/miniMyBets/MiniMyBetsBlock.vue';
    import SportListBlock from '@/components/sportList/SportListBlock.vue';
    import { useLayoutStore } from '@/store/layoutStore';

    const { isBetSlipExpanded, isMyBetsExpanded, isLayoutExpanded } = storeToRefs(useLayoutStore());
</script>
