import { removeDuplicates } from '@/core/lib/array';
import { i18n } from '@/core/lib/i18n';
import { isFirstHalfBaseball, isFirstHalfBaseballMarket, isFullTimeMainMarket, isHighestOpeningPartnership, isNextGoal, isOU, nextGoalMarketGroups } from '@/core/lib/oddsHelper';
import { isMobile } from '@/core/lib/utils';
import type { DoubleLineMarketTypeConfig, MarketTypeInfo, SingleLineMarketTypeConfig } from '@/interface/IMarketTypeInfo';
import { BetBuilderMarket, BetBuilderStatType, BetTypeGroup, MarketType, MarketTypeHeader, OddsDisplayBlockType, OddsDisplayMode, SportType } from '@/interface/enum';

const SingleLineMainMarketConfig: SingleLineMarketTypeConfig = {
    default: [
        { marketGroup: BetTypeGroup.Main, marketType: MarketType.Handicap },
        { marketGroup: BetTypeGroup.Main, marketType: MarketType.OverUnder },
        { marketGroup: BetTypeGroup.Main, marketType: MarketType.FH_Handicap },
        { marketGroup: BetTypeGroup.Main, marketType: MarketType.FH_OverUnder },
    ],
};

const DoubleLineMainMarketConfig: DoubleLineMarketTypeConfig = {
    [SportType.Soccer]: {
        live: [
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.LiveScore },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.Handicap },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.OverUnder },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType._1X2 },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.FH_Handicap },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.FH_OverUnder },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.FH_1X2 },
            { marketGroup: nextGoalMarketGroups[0], marketType: MarketType.Handicap }, // Next goal
        ],
        nonLive: [
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.Handicap },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.OverUnder },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType._1X2 },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.OddEven },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.FH_Handicap },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.FH_OverUnder },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.FH_1X2 },
        ],
    },
    [SportType.Cricket]: {
        live: [
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.Handicap },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType._1X2 },
            { marketGroup: BetTypeGroup.Cricket_HighestOpeningPartnership, marketType: MarketType._1X2 },
        ],
        nonLive: [
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.Handicap },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType._1X2 },
            { marketGroup: BetTypeGroup.Cricket_HighestOpeningPartnership, marketType: MarketType._1X2 },
        ],
    },
    [SportType.Tennis]: {
        live: [
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.MoneyLine },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.Handicap },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.OverUnder },
            { marketGroup: BetTypeGroup.Tennis_GameHandicap, marketType: MarketType.Handicap },
            { marketGroup: BetTypeGroup.Tennis_GameHandicap, marketType: MarketType.OverUnder },
        ],
        nonLive: [
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.MoneyLine },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.Handicap },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.OverUnder },
            { marketGroup: BetTypeGroup.Tennis_GameHandicap, marketType: MarketType.Handicap },
            { marketGroup: BetTypeGroup.Tennis_GameHandicap, marketType: MarketType.OverUnder },
            { marketGroup: BetTypeGroup.Tennis_GameHandicap, marketType: MarketType.OddEven },
        ],
    },
    [SportType.TableTennis]: {
        live: [
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.MoneyLine },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.Handicap },
            { marketGroup: BetTypeGroup.TableTennis_TotalMatchPoints, marketType: MarketType.Handicap },
            { marketGroup: BetTypeGroup.TableTennis_TotalMatchPoints, marketType: MarketType.OverUnder },
            { marketGroup: BetTypeGroup.TableTennis_TotalMatchPoints, marketType: MarketType.OddEven },
        ],
        nonLive: [
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.MoneyLine },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.Handicap },
            { marketGroup: BetTypeGroup.TableTennis_TotalMatchPoints, marketType: MarketType.Handicap },
            { marketGroup: BetTypeGroup.TableTennis_TotalMatchPoints, marketType: MarketType.OverUnder },
            { marketGroup: BetTypeGroup.TableTennis_TotalMatchPoints, marketType: MarketType.OddEven },
        ],
    },
    [SportType.Badminton]: {
        live: [
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.MoneyLine },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.Handicap },
            { marketGroup: BetTypeGroup.Badminton_TotalMatchPoints, marketType: MarketType.Handicap },
            { marketGroup: BetTypeGroup.Badminton_TotalMatchPoints, marketType: MarketType.OverUnder },
            { marketGroup: BetTypeGroup.Badminton_TotalMatchPoints, marketType: MarketType.OddEven },
        ],
        nonLive: [
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.MoneyLine },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.Handicap },
            { marketGroup: BetTypeGroup.Badminton_TotalMatchPoints, marketType: MarketType.Handicap },
            { marketGroup: BetTypeGroup.Badminton_TotalMatchPoints, marketType: MarketType.OverUnder },
            { marketGroup: BetTypeGroup.Badminton_TotalMatchPoints, marketType: MarketType.OddEven },
        ],
    },
    [SportType.Volleyball]: {
        live: [
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.MoneyLine },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.Handicap },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.OverUnder },
            { marketGroup: BetTypeGroup.Volleyball_TotalMatchPoints, marketType: MarketType.Handicap },
            { marketGroup: BetTypeGroup.Volleyball_TotalMatchPoints, marketType: MarketType.OverUnder },
            { marketGroup: BetTypeGroup.Volleyball_TotalMatchPoints, marketType: MarketType.OddEven },
        ],
        nonLive: [
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.MoneyLine },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.Handicap },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.OverUnder },
            { marketGroup: BetTypeGroup.Volleyball_TotalMatchPoints, marketType: MarketType.Handicap },
            { marketGroup: BetTypeGroup.Volleyball_TotalMatchPoints, marketType: MarketType.OverUnder },
            { marketGroup: BetTypeGroup.Volleyball_TotalMatchPoints, marketType: MarketType.OddEven },
        ],
    },
    [SportType.Basketball]: {
        live: [
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.MoneyLine },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.Handicap },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.OverUnder },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.OddEven },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.FH_Handicap },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.FH_OverUnder },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.FH_OddEven },
        ],
        nonLive: [
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.MoneyLine },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.Handicap },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.OverUnder },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.OddEven },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.FH_Handicap },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.FH_OverUnder },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.FH_OddEven },
        ],
    },
    [SportType.Football]: {
        live: [
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.Handicap },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.OverUnder },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.FH_Handicap },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.FH_OverUnder },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.FH_OddEven },
        ],
        nonLive: [
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.MoneyLine },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.Handicap },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.OverUnder },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.OddEven },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.FH_Handicap },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.FH_OverUnder },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.FH_OddEven },
        ],
    },
    [SportType.Hockey]: {
        live: [
            { marketGroup: BetTypeGroup.Main, marketType: MarketType._1X2 },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.Handicap },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.OverUnder },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.OddEven },
            { marketGroup: BetTypeGroup.Hockey_FirstPeriod, marketType: MarketType._1X2 },
            { marketGroup: BetTypeGroup.Hockey_FirstPeriod, marketType: MarketType.Handicap },
            { marketGroup: BetTypeGroup.Hockey_FirstPeriod, marketType: MarketType.OverUnder },
        ],
        nonLive: [
            { marketGroup: BetTypeGroup.Main, marketType: MarketType._1X2 },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.Handicap },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.OverUnder },
            { marketGroup: BetTypeGroup.Hockey_FirstPeriod, marketType: MarketType._1X2 },
            { marketGroup: BetTypeGroup.Hockey_FirstPeriod, marketType: MarketType.Handicap },
            { marketGroup: BetTypeGroup.Hockey_FirstPeriod, marketType: MarketType.OverUnder },
        ],
    },
    [SportType.Futsal]: {
        live: [
            { marketGroup: BetTypeGroup.Main, marketType: MarketType._1X2 },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.Handicap },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.OverUnder },
            { marketGroup: BetTypeGroup.Futsal_FirstHalf, marketType: MarketType._1X2 },
            { marketGroup: BetTypeGroup.Futsal_FirstHalf, marketType: MarketType.Handicap },
            { marketGroup: BetTypeGroup.Futsal_FirstHalf, marketType: MarketType.OverUnder },
        ],
        nonLive: [
            { marketGroup: BetTypeGroup.Main, marketType: MarketType._1X2 },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.Handicap },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.OverUnder },
            { marketGroup: BetTypeGroup.Futsal_FirstHalf, marketType: MarketType._1X2 },
            { marketGroup: BetTypeGroup.Futsal_FirstHalf, marketType: MarketType.Handicap },
            { marketGroup: BetTypeGroup.Futsal_FirstHalf, marketType: MarketType.OverUnder },
        ],
    },
    [SportType.Baseball]: {
        live: [
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.MoneyLine },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.Handicap },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.OverUnder },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.FH_Handicap },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.FH_OverUnder },
        ],
        nonLive: [
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.MoneyLine },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.Handicap },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.OverUnder },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.FH_Handicap },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.FH_OverUnder },
            { marketGroup: BetTypeGroup.Baseball_FirstInningRuns, marketType: MarketType._1X2 },
        ],
    },
    default: {
        live: [
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.MoneyLine },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.Handicap },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.OverUnder },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.FH_Handicap },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.FH_OverUnder },
        ],
        nonLive: [
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.MoneyLine },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.Handicap },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.OverUnder },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.OddEven },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.FH_Handicap },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.FH_OverUnder },
            { marketGroup: BetTypeGroup.Main, marketType: MarketType.FH_OddEven },
        ],
    },
};

export const OddsDisplayBlockTypeToColumnWidthMapping: Partial<Record<OddsDisplayBlockType, number>> = {
    [OddsDisplayBlockType.Time]: 66,
    [OddsDisplayBlockType.Team]: 180,
    [OddsDisplayBlockType.More]: 50,
};

export const MarketTypeToColumnWidthMapping: Record<MarketType, number> = {
    [MarketType.Unknown]: 0,
    [MarketType.Handicap]: 90,
    [MarketType.OddEven]: 80,
    [MarketType.OverUnder]: 108,
    [MarketType.CorrectScore]: 0,
    [MarketType._1X2]: 64,
    [MarketType.TotalGoal]: 0,
    [MarketType.FH_Handicap]: 90,
    [MarketType.FH_1X2]: 64,
    [MarketType.FH_OverUnder]: 108,
    [MarketType.HTFT]: 0,
    [MarketType.MoneyLine]: 80,
    [MarketType.FH_OddEven]: 80,
    [MarketType.FGLG]: 0,
    [MarketType.FH_CorrectScore]: 0,
    [MarketType.DoubleChance]: 0,
    [MarketType.LiveScore]: 60,
    [MarketType.FH_LiveScore]: 40,
    [MarketType.Game]: 0,
    [MarketType.OutRight]: 0,
    [MarketType.MixParlay]: 0,
    [MarketType.BetBuilder]: 0,
};

export const MarketGroupToColumnWidthMapping: Partial<Record<BetTypeGroup, number>> = {
    [BetTypeGroup.Soccer_FirstGoal]: 50,
    [BetTypeGroup.Soccer_SecondGoal]: 50,
    [BetTypeGroup.Soccer_ThirdGoal]: 50,
    [BetTypeGroup.Soccer_ForthGoal]: 50,
    [BetTypeGroup.Soccer_FifthGoal]: 50,
    [BetTypeGroup.Soccer_SixthGoal]: 50,
    [BetTypeGroup.Soccer_SeventhGoal]: 50,
    [BetTypeGroup.Soccer_EighthGoal]: 50,
    [BetTypeGroup.Soccer_NinthGoal]: 50,
    [BetTypeGroup.Soccer_TenthGoal]: 50,
    [BetTypeGroup.Cricket_HighestOpeningPartnership]: 126,
};

export const MarketToColumnWidthLimitMapping = new Map<BetTypeGroup, Map<MarketType, number>>([
    [BetTypeGroup.Cricket_HighestOpeningPartnership, new Map([
        [MarketType._1X2, 80],
    ])],
]);

export const MarketTypeGroupingMapping = new Map<MarketType, OddsDisplayBlockType>([
    [MarketType.Handicap, OddsDisplayBlockType.FullTime],
    [MarketType._1X2, OddsDisplayBlockType.FullTime],
    [MarketType.OverUnder, OddsDisplayBlockType.FullTime],
    [MarketType.OddEven, OddsDisplayBlockType.FullTime],
    [MarketType.CorrectScore, OddsDisplayBlockType.FullTime],
    [MarketType.LiveScore, OddsDisplayBlockType.FullTime],
    [MarketType.MoneyLine, OddsDisplayBlockType.FullTime],
    [MarketType.FH_Handicap, OddsDisplayBlockType.FirstHalf],
    [MarketType.FH_1X2, OddsDisplayBlockType.FirstHalf],
    [MarketType.FH_OverUnder, OddsDisplayBlockType.FirstHalf],
    [MarketType.FH_OddEven, OddsDisplayBlockType.FirstHalf],
    [MarketType.FH_CorrectScore, OddsDisplayBlockType.FirstHalf],
    [MarketType.FH_LiveScore, OddsDisplayBlockType.FirstHalf],
]);

export const NewBetTypeGroupingMapping = new Map<BetTypeGroup, OddsDisplayBlockType>([
    [BetTypeGroup.Basketball_FirstQuarter, OddsDisplayBlockType.FirstQuarter],
    [BetTypeGroup.Tennis_GameHandicap, OddsDisplayBlockType.GameHandicap],
    [BetTypeGroup.TableTennis_TotalMatchPoints, OddsDisplayBlockType.TotalMatchPoints],
    [BetTypeGroup.Badminton_TotalMatchPoints, OddsDisplayBlockType.TotalMatchPoints],
    [BetTypeGroup.Volleyball_TotalMatchPoints, OddsDisplayBlockType.TotalMatchPoints],
    [BetTypeGroup.Hockey_FirstPeriod, OddsDisplayBlockType.FirstPeriod],
    [BetTypeGroup.Baseball_FirstInningRuns, OddsDisplayBlockType.FirstInningRuns],
    [BetTypeGroup.Futsal_FirstHalf, OddsDisplayBlockType.FirstHalf],
]);

export function isSingleMode(oddsDisplayMode: OddsDisplayMode): boolean {
    return oddsDisplayMode === OddsDisplayMode.Single;
}

export function getDisplayMarketTypeInfos(sportType: SportType, isLive: boolean, oddsDisplayMode: OddsDisplayMode): MarketTypeInfo[] {
    if (isSingleMode(oddsDisplayMode)) {
        return SingleLineMainMarketConfig[sportType] ?? SingleLineMainMarketConfig.default;
    }

    const configBySportType = DoubleLineMainMarketConfig[sportType] ?? DoubleLineMainMarketConfig.default;

    return configBySportType[isLive ? 'live' : 'nonLive'];
}

export function getMainMarketGroups(displayMarketTypeInfos: MarketTypeInfo[]) {
    const marketGroups = displayMarketTypeInfos.map(info => info.marketGroup);
    const hasNextGoal = displayMarketTypeInfos.some(info => isNextGoal(info.marketGroup));
    if (hasNextGoal) {
        marketGroups.push(...nextGoalMarketGroups);
    }

    return removeDuplicates(marketGroups);
}

const fhMarketTypes = [
    MarketType.FH_1X2,
    MarketType.FH_Handicap,
    MarketType.FH_OverUnder,
    MarketType.FH_OddEven,
    MarketType.FH_CorrectScore,
    MarketType.FH_LiveScore,
];

const marketTypeBetBuilderMapping: Partial<Record<MarketType, BetBuilderMarket>> = {
    [MarketType._1X2]: BetBuilderMarket.Set1x2,
    [MarketType.FH_1X2]: BetBuilderMarket.Set1x2,
    [MarketType.DoubleChance]: BetBuilderMarket.SetDoubleChance,
    [MarketType.OverUnder]: BetBuilderMarket.SetTotalGoalsOverUnder,
    [MarketType.FH_OverUnder]: BetBuilderMarket.SetTotalGoalsOverUnder,
    [MarketType.OddEven]: BetBuilderMarket.SetTotalGoalsOddEven,
    [MarketType.FH_OddEven]: BetBuilderMarket.SetTotalGoalsOddEven,
    [MarketType.CorrectScore]: BetBuilderMarket.SetCorrectScore,
};

interface IBetBuilderMarketTypeInfo {
    marketType: MarketType;
    statType: BetBuilderStatType.FirstHalf | BetBuilderStatType.FullTime;
    betBuilderMarketType: BetBuilderMarket;
}

export function toBetBuilderMarketTypeInfos(displayMarketTypeInfos: MarketTypeInfo[]): (IBetBuilderMarketTypeInfo | undefined)[] {
    return displayMarketTypeInfos.map(({ marketType }) => {
        const betBuilderMarketType = marketTypeBetBuilderMapping[marketType];
        if (!betBuilderMarketType) {
            return undefined;
        }

        const statType = fhMarketTypes.includes(marketType)
            ? BetBuilderStatType.FirstHalf
            : BetBuilderStatType.FullTime;

        return {
            marketType,
            statType,
            betBuilderMarketType,
        };
    });
}

export const MarketTypeNameMapping: Partial<Record<MarketType|MarketTypeHeader, string>> = {
    [MarketType.Handicap]: 'hdp',
    [MarketType.FH_Handicap]: 'hdp',
    [MarketType.OverUnder]: 'ou',
    [MarketType.FH_OverUnder]: 'ou',
    [MarketType._1X2]: '1X2',
    [MarketType.FH_1X2]: '1X2',
    [MarketType.OddEven]: 'oe',
    [MarketType.FH_OddEven]: 'oe',
    [MarketType.TotalGoal]: 'total_goal',
    [MarketType.MoneyLine]: 'ml',
    [MarketType.LiveScore]: 'asian1X2',
    [MarketTypeHeader.More]: 'more',
};

export const MoreMarketTooltipMapping: Partial<Record<MarketType, string>> = {
    [MarketType.Handicap]: 'more_market_hdp_tooltip',
    [MarketType.FH_Handicap]: 'more_market_hdp_tooltip',
    [MarketType.OverUnder]: 'more_market_ou_tooltip',
    [MarketType.FH_OverUnder]: 'more_market_ou_tooltip',
    [MarketType._1X2]: 'more_market_1X2_tooltip',
    [MarketType.FH_1X2]: 'more_market_1X2_tooltip',
    [MarketType.OddEven]: 'more_market_oe_tooltip',
    [MarketType.FH_OddEven]: 'more_market_oe_tooltip',
    [MarketType.TotalGoal]: 'Total Goal_tooltip',
    [MarketType.MoneyLine]: 'more_market_ml_tooltip',
    [MarketType.LiveScore]: 'asian1X2_tooltip',
};

export const HomeOptionTooltipMapping: Partial<Record<MarketType, string>> = {
    [MarketType.Handicap]: 'ft_hdp_home_tooltip',
    [MarketType.FH_Handicap]: 'fh_hdp_home_tooltip',
    [MarketType.OverUnder]: 'ft_ou_over_tooltip',
    [MarketType.FH_OverUnder]: 'fh_ou_over_tooltip',
};

export const AwayOptionTooltipMapping: Partial<Record<MarketType, string>> = {
    [MarketType.Handicap]: 'ft_hdp_away_tooltip',
    [MarketType.FH_Handicap]: 'fh_hdp_away_tooltip',
    [MarketType.OverUnder]: 'ft_ou_under_tooltip',
    [MarketType.FH_OverUnder]: 'fh_ou_under_tooltip',
};

export interface IColumnDescription {
    label: string;
    tooltip: string;
}

export function getMarketColumnDescription(sportType: SportType, marketType: MarketType, marketGroup: BetTypeGroup, isSingleLine: boolean): IColumnDescription {
    if (isHighestOpeningPartnership(marketGroup)) {
        return {
            label: `${i18n.t('odds_header_highest_opening_partnership')} - ${i18n.t('odds_header_1X2')}`,
            tooltip: '',
        };
    }

    if (isNextGoal(marketGroup)) {
        return {
            label: `${i18n.t('odds_header_next_goal')}`,
            tooltip: '',
        };
    }

    const marketTypeKey = MarketTypeNameMapping[marketType] ?? MarketType[marketType];

    if (isSingleLine && isOU(marketType)) {
        const key = marketType === MarketType.OverUnder ? 'ft_goal_tooltip' : 'fh_goal_tooltip';
        return {
            label: i18n.t('odds_header_goal'),
            tooltip: i18n.t(key),
        };
    }

    if (isMobile()) {
        return {
            label: i18n.t(`odds_header_${marketTypeKey}`),
            tooltip: '',
        };
    }

    const isShowSetTitle = isSetGameScoreSport(sportType) && isFullTimeMainMarket(marketType, marketGroup);

    const tooltipKeyPrefix = isShowSetTitle || isFirstHalfBaseballMarket(sportType, marketType, marketGroup) ? `sport_type_${sportType}_` : '';
    const tooltipKey = `odds_header_tooltip_${tooltipKeyPrefix}bet_type_group_${marketGroup}_market_type_${marketType}`;

    return {
        label: i18n.t(`odds_header_${marketTypeKey}`),
        tooltip: i18n.t(tooltipKey),
    };
}

export function getBlockColumnDescription(sportType: SportType, blockType: OddsDisplayBlockType): IColumnDescription {
    let label = i18n.t(`odds_header_${blockType}`);

    if (isSetGameScoreSport(sportType) && blockType === OddsDisplayBlockType.FullTime) {
        label = i18n.t('odds_header_set');
    }

    if (isFirstHalfBaseball(sportType, blockType)) {
        label = i18n.t('odds_header_first5');
    }
    return {
        label,
        tooltip: '',
    };
}

function isSetGameScoreSport(sportType: SportType) {
    const setSportListForOddsDisplay = [SportType.Tennis, SportType.TableTennis, SportType.Volleyball, SportType.Badminton];
    return setSportListForOddsDisplay.includes(sportType);
}
