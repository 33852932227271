<template>
    <slot ref="activatorRef" name="activator" />
    <Teleport to="#sports" v-bind:disabled="!isOpened">
        <div
            v-if="isOpened"
            ref="dropdownRef"
            class="dropdown_content"
            v-bind:style="style"
        >
            <slot />
        </div>
    </Teleport>
</template>

<script lang="ts" setup>
    import { useEventListener, useTimeoutFn } from '@vueuse/core';
    import type { CSSProperties } from 'vue';
    import { effectScope, getCurrentInstance, nextTick, onMounted, ref, watch } from 'vue';
    import { useToggle } from '@/composable/useToggle';

    const emits = defineEmits([
        'onOpen',
        'onClose',
    ]);

    const props = defineProps({
        openOnClick: {
            type: Boolean,
            default: false,
        },
        openOnHover: {
            type: Boolean,
            default: false,
        },
    });

    const vm = getCurrentInstance();
    const activatorRef = ref<HTMLElement | null>(null);
    const dropdownRef = ref<HTMLElement | null>(null);
    const [isActivatorHovered, toggleIsActivatorHovered] = useToggle(false);
    const [isDropdownHovered, toggleIsDropdownHovered] = useToggle(false);
    const [isOpened, toggleIsOpened] = useToggle(false);

    function getActivator(): EventTarget & HTMLElement | undefined {
        return vm?.parent?.proxy?.$el;
    }
    const activatorEl = ref<EventTarget & HTMLElement | undefined>(undefined);
    onMounted(() => {
        activatorEl.value = getActivator();
    });

    const style = ref<CSSProperties>({});
    function calculatePosition() {
        const targetEl = activatorEl.value;
        const dropdownEl = dropdownRef.value;
        if (!targetEl || !dropdownEl) return;

        const targetRect = targetEl.getBoundingClientRect();
        const dropdownRect = dropdownEl.getBoundingClientRect();

        const offset = 0;
        const screenGap = 16;
        const leftBoundary = window.scrollX;
        const rightBoundary = window.scrollX + window.innerWidth;
        const topBoundary = window.scrollY;
        const bottomBoundary = window.scrollY + window.innerHeight;

        const targetRectRealXAxis = window.scrollX + targetRect.left;
        // eslint-disable-next-line no-nested-ternary
        const x = targetRect.left < 0
            ? leftBoundary
            : targetRectRealXAxis + dropdownRect.width + screenGap + offset < rightBoundary
                ? targetRectRealXAxis + offset
                : rightBoundary - dropdownRect.width - screenGap - offset;

        // eslint-disable-next-line no-nested-ternary
        const y = window.scrollY + targetRect.bottom + dropdownRect.height + screenGap < bottomBoundary
            ? window.scrollY + targetRect.bottom
            : targetRect.top < 0
                ? topBoundary
                : window.scrollY + targetRect.top - dropdownRect.height;

        style.value = {
            top: `${y}px`,
            left: `${x}px`,
        };
    }

    watch(isOpened, (_, __, onCleanup) => {
        if (!isOpened.value) return;

        nextTick().then(() => calculatePosition());

        const scope = effectScope();
        scope.run(() => {
            useEventListener(document, 'scroll', () => {
                calculatePosition();
            }, { passive: true });
        });
        onCleanup(() => scope.stop());
    }, { immediate: true });

    watch(() => props.openOnClick, (_, __, onCleanup) => {
        if (!props.openOnClick) return;

        const scope = effectScope();
        scope.run(() => {
            useEventListener(activatorEl, 'click', () => {
                toggleIsOpened();
                emits(isOpened.value ? 'onOpen' : 'onClose');
            });
        });
        onCleanup(() => scope.stop());
    }, { immediate: true });

    const onDelayedHovered = () => useTimeoutFn(() => {
        const newIsOpened = isDropdownHovered.value || isActivatorHovered.value;
        toggleIsOpened(newIsOpened);
        emits(newIsOpened ? 'onOpen' : 'onClose');
    }, 100);

    watch(() => props.openOnHover, (_, __, onCleanup) => {
        if (!props.openOnHover) return;

        const scope = effectScope();
        scope.run(() => {
            useEventListener(dropdownRef, 'mouseenter', () => {
                toggleIsDropdownHovered(true);
                onDelayedHovered();
            }, { passive: true });
            useEventListener(dropdownRef, 'mouseleave', () => {
                toggleIsDropdownHovered(false);
                onDelayedHovered();
            }, { passive: true });

            useEventListener(activatorEl, 'mouseenter', async () => {
                toggleIsActivatorHovered(true);
                onDelayedHovered();
            }, { passive: true });
            useEventListener(activatorEl, 'mouseleave', () => {
                toggleIsActivatorHovered(false);
                onDelayedHovered();
            }, { passive: true });
        });
        onCleanup(() => scope.stop());
    }, { immediate: true });
</script>

<style lang="scss" scoped>
    @import "@/components/common/dropdown/Dropdown";
</style>
