import { sortBy } from 'lodash';
import { getTeamName } from '@/components/oddsDisplay/matchDisplay/useMatchDisplay';
import { getBetOptionDisplay } from '@/core/lib/betOptionDisplay';
import { i18n } from '@/core/lib/i18n';
import { isMainMarket } from '@/core/lib/oddsHelper';
import type { IEventEuro } from '@/interface/IEvent';
import type { IBaseMarketGroup, IOdds, IPrice } from '@/interface/IOdds';
import { MarketType, OddsOption, TeamOption } from '@/interface/enum';

interface IGroup {
    title: string;
    prices: IPrice[];
}

interface IRule {
    layout: 'column' | 'row';
    marketTypeDisplay: (marketGroup: IBaseMarketGroup, marketType: MarketType) => string;
    smallBetOptionFormatter: (event: IEventEuro, odds: IOdds, price: IPrice) => string;
    bigBetOptionFormatter: (event: IEventEuro, odds: IOdds, price: IPrice) => string;
    matchDetailLayout: (event: IEventEuro, odds: IOdds) => IGroup[];
}

const defaultRule: IRule = {
    layout: 'row',
    marketTypeDisplay: (marketGroup: IBaseMarketGroup, marketType: MarketType) => {
        if (isMainMarket(marketGroup.id)) {
            return i18n.t(`market_type_name_${marketType}`);
        }
        return `${marketGroup.name} - ${i18n.t(`market_type_short_name_${marketType}`)}`;
    },
    smallBetOptionFormatter: () => '',
    bigBetOptionFormatter: () => '',
    matchDetailLayout: (event: IEventEuro, odds: IOdds) => [{
        title: '',
        prices: odds.prices,
    }],
};

const csRule: Partial<IRule> = {
    layout: 'column',
    bigBetOptionFormatter: (event: IEventEuro, odds: IOdds, price: IPrice) => {
        const score = {
            home: event.homeTeam.liveScore ?? 0,
            away: event.awayTeam.liveScore ?? 0,
        };
        return getBetOptionDisplay.displayCS(price.option, score, odds.marketType, '-').name;
    },
    matchDetailLayout: (event: IEventEuro, odds: IOdds) => {
        const _prices = odds.prices.map((p) => {
            const [home, away] = p.option.split(':').map(x => parseInt(x));
            return {
                price: p,
                homeScore: home + (event.homeTeam.liveScore ?? 0),
                awayScore: away + (event.awayTeam.liveScore ?? 0),
            };
        });

        return [{
            title: event.homeTeam.name,
            prices: sortBy(_prices.filter(p => p.homeScore > p.awayScore), [p => p.homeScore, p => p.awayScore])
                .map(p => p.price),
        }, {
            title: i18n.t('odds_option_draw'),
            prices: sortBy(_prices.filter(p => p.homeScore === p.awayScore || p.price.option === OddsOption.CS_Others), [p => p.homeScore, p => p.awayScore])
                .map(p => p.price),
        }, {
            title: event.awayTeam.name,
            prices: sortBy(_prices.filter(p => p.homeScore < p.awayScore), [p => p.awayScore, p => p.homeScore])
                .map(p => p.price),
        }];
    },
};

const hdpRule: Partial<IRule> = {
    bigBetOptionFormatter: (event: IEventEuro, odds: IOdds, price: IPrice) => {
        if (price.option === OddsOption.Home) return getTeamName(TeamOption.Home, event, [odds]);
        if (price.option === OddsOption.Away) return getTeamName(TeamOption.Away, event, [odds]);
        return '';
    },
};

const _1x2Rule: Partial<IRule> = {
    bigBetOptionFormatter: (event: IEventEuro, odds: IOdds, price: IPrice) => {
        if (price.option === OddsOption._1X2_1) return getTeamName(TeamOption.Home, event, [odds]);
        if (price.option === OddsOption._1X2_2) return getTeamName(TeamOption.Away, event, [odds]);
        if (price.option === OddsOption._1X2_X) return i18n.t('odds_option_draw');
        return '';
    },
};

const ouRule: Partial<IRule> = {
    smallBetOptionFormatter: (event: IEventEuro, odds: IOdds, price: IPrice) => {
        if (price.option === OddsOption.Over) return i18n.t('odds_option_over_short');
        if (price.option === OddsOption.Under) return i18n.t('odds_option_under_short');
        return '';
    },
    bigBetOptionFormatter: (event: IEventEuro, odds: IOdds, price: IPrice) => {
        if (price.option === OddsOption.Over) return i18n.t('odds_option_over');
        if (price.option === OddsOption.Under) return i18n.t('odds_option_under');
        return '';
    },
};

const oeRule: Partial<IRule> = {
    bigBetOptionFormatter: (event: IEventEuro, odds: IOdds, price: IPrice) => {
        if (price.option === OddsOption.Odd) return i18n.t('odds_option_odd');
        if (price.option === OddsOption.Even) return i18n.t('odds_option_even');
        return '';
    },
};

const tgRule: Partial<IRule> = {
    bigBetOptionFormatter: (event: IEventEuro, odds: IOdds, price: IPrice) => getBetOptionDisplay.displayTotalGoal(price.option, '-').name,
};

const dcRule: Partial<IRule> = {
    bigBetOptionFormatter: (event: IEventEuro, odds: IOdds, price: IPrice) => {
        if (price.option === OddsOption.DC_12) {
            return i18n.t('odds_option_dc', {
                option1: event.homeTeam.name,
                option2: event.awayTeam.name,
            });
        }
        if (price.option === OddsOption.DC_1X) {
            return i18n.t('odds_option_dc', {
                option1: event.homeTeam.name,
                option2: i18n.t('odds_option_draw'),
            });
        }
        if (price.option === OddsOption.DC_2X) {
            return i18n.t('odds_option_dc', {
                option1: i18n.t('odds_option_draw'),
                option2: event.awayTeam.name,
            });
        }
        return '';
    },
    matchDetailLayout: (event: IEventEuro, odds: IOdds) => [{
        title: '',
        prices: sortBy(odds.prices, [p => [OddsOption.DC_1X, OddsOption.DC_12, OddsOption.DC_2X].indexOf(p.option)]),
    }],
};

const htftRule: Partial<IRule> = {
    layout: 'column',
    bigBetOptionFormatter: (event: IEventEuro, odds: IOdds, price: IPrice) => {
        if (price.option === OddsOption.HTFT_AA) return `${event.awayTeam.name}/${event.awayTeam.name}`;
        if (price.option === OddsOption.HTFT_AD) return `${event.awayTeam.name}/${i18n.t('odds_option_draw')}`;
        if (price.option === OddsOption.HTFT_AH) return `${event.awayTeam.name}/${event.homeTeam.name}`;
        if (price.option === OddsOption.HTFT_DA) return `${i18n.t('odds_option_draw')}/${event.awayTeam.name}`;
        if (price.option === OddsOption.HTFT_DD) return `${i18n.t('odds_option_draw')}/${i18n.t('odds_option_draw')}`;
        if (price.option === OddsOption.HTFT_DH) return `${i18n.t('odds_option_draw')}/${event.homeTeam.name}`;
        if (price.option === OddsOption.HTFT_HA) return `${event.homeTeam.name}/${event.awayTeam.name}`;
        if (price.option === OddsOption.HTFT_HD) return `${event.homeTeam.name}/${i18n.t('odds_option_draw')}`;
        if (price.option === OddsOption.HTFT_HH) return `${event.homeTeam.name}/${event.homeTeam.name}`;
        return '';
    },
    matchDetailLayout: (event: IEventEuro, odds: IOdds) => [{
        title: '',
        prices: [OddsOption.HTFT_HH, OddsOption.HTFT_DH, OddsOption.HTFT_AH].map(o => odds.prices.find(p => p.option === o)!),
    }, {
        title: '',
        prices: [OddsOption.HTFT_HD, OddsOption.HTFT_DD, OddsOption.HTFT_AD].map(o => odds.prices.find(p => p.option === o)!),
    }, {
        title: '',
        prices: [OddsOption.HTFT_HA, OddsOption.HTFT_DA, OddsOption.HTFT_AA].map(o => odds.prices.find(p => p.option === o)!),
    }],
};

const fglgRule: Partial<IRule> = {
    layout: 'column',
    bigBetOptionFormatter: (event: IEventEuro, odds: IOdds, price: IPrice) => {
        if (price.option === OddsOption.FGLG_AF) return i18n.t(`${event.awayTeam.name} ${i18n.t('first_goal')}`);
        if (price.option === OddsOption.FGLG_AL) return i18n.t(`${event.awayTeam.name} ${i18n.t('last_goal')}`);
        if (price.option === OddsOption.FGLG_HF) return i18n.t(`${event.homeTeam.name} ${i18n.t('first_goal')}`);
        if (price.option === OddsOption.FGLG_HL) return i18n.t(`${event.homeTeam.name} ${i18n.t('last_goal')}`);
        if (price.option === OddsOption.FGLG_NG) return i18n.t(`${i18n.t('odds_option_fglg_ng_tooltip')}`);
        return '';
    },
    matchDetailLayout: (event: IEventEuro, odds: IOdds) => [{
        title: '',
        prices: [OddsOption.FGLG_HF, OddsOption.FGLG_HL].map(o => odds.prices.find(p => p.option === o)!),
    }, {
        title: '',
        prices: [OddsOption.FGLG_NG].map(o => odds.prices.find(p => p.option === o)!),
    }, {
        title: '',
        prices: [OddsOption.FGLG_AF, OddsOption.FGLG_AL].map(o => odds.prices.find(p => p.option === o)!),
    }],
};

const outrightRule: Partial<IRule> = {
    layout: 'column',
    /**
     * Outright bet in betSlip will have the same home team name and away team name as the option name
     */
    bigBetOptionFormatter: (event: IEventEuro, _odds: IOdds, _price: IPrice) => event.homeTeam.name,
};

const rulesConfig: Partial<Record<MarketType, Partial<IRule>>> = {
    [MarketType.Handicap]: hdpRule,
    [MarketType.FH_Handicap]: hdpRule,
    [MarketType.LiveScore]: _1x2Rule,
    [MarketType.FH_LiveScore]: _1x2Rule,
    [MarketType._1X2]: _1x2Rule,
    [MarketType.FH_1X2]: _1x2Rule,
    [MarketType.MoneyLine]: _1x2Rule,
    [MarketType.OverUnder]: ouRule,
    [MarketType.FH_OverUnder]: ouRule,
    [MarketType.OddEven]: oeRule,
    [MarketType.FH_OddEven]: oeRule,
    [MarketType.CorrectScore]: csRule,
    [MarketType.FH_CorrectScore]: csRule,
    [MarketType.TotalGoal]: tgRule,
    [MarketType.DoubleChance]: dcRule,
    [MarketType.HTFT]: htftRule,
    [MarketType.FGLG]: fglgRule,
    [MarketType.OutRight]: outrightRule,
};

export const getMarketRule = (marketType: MarketType): IRule => ({
    ...defaultRule,
    ...rulesConfig[marketType],
});
