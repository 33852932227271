import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { i18n } from '@/core/lib/i18n';
import type { IDropdownGroup, IDropdownItem } from '@/interface/IDropdown';
import { DisplayTime, LicenseType, MarketShowingType, OddsDisplayMode, OddsSorting, PlayerType, PriceStyle, SportType } from '@/interface/enum';
import { useAsiaSportMarketInfoStore } from '@/store/asiaSportMarketInfoStore';
import { useCustomerStore } from '@/store/customerStore';
import { useOddsDisplaySettingsStore } from '@/store/oddsDisplaySettingsStore';

enum SettingGroup {
    Sorting = 'sorting',
    OddsDisplayMode = 'odds_display_mode',
    PriceStyle = 'price_style',
    MarketShowing = 'market_showing',
    DisplayTime = 'display_time',
}

interface ISettingGroup<T> {
    key: SettingGroup;
    items: T[];
    isActive: (newVal: T) => boolean;
    onClick: (newVal: T) => void;
    isShow: boolean;
    isDisabled: boolean;
}

export function useSettingFunction() {
    const { sportType, isMixParlay, isOutright } = storeToRefs(useAsiaSportMarketInfoStore());
    const { marketShowing, oddsDisplayMode } = storeToRefs(useOddsDisplaySettingsStore());
    const { setMarketShowing } = useOddsDisplaySettingsStore();
    const { priceStyle, oddsSorting, priceStyleOptions: _priceStyleOptions, displayTime } = storeToRefs(useCustomerStore());
    const { updatePriceStyle, updateOddsSorting, updateOddsDisplayMode, updateDisplayTime } = useCustomerStore();
    const { playerType, licenseType, sboCurrency, trackingId } = storeToRefs(useCustomerStore());

    const priceStyleGtmMapping: Partial<Record<PriceStyle, string>> = {
        [PriceStyle.Malay]: 'MalayOdds',
        [PriceStyle.HK]: 'HKOdds',
        [PriceStyle.Euro]: 'EuroOdds',
    };
    const priceStyleOptions = computed(() => _priceStyleOptions.value.map(style => ({
        option: style,
        gtmLabel: priceStyleGtmMapping[style] ?? '',
    })));

    const settingGroups = computed<ISettingGroup<any>[]>(() => [
        {
            key: SettingGroup.Sorting,
            items: [
                { option: OddsSorting.Normal, gtmLabel: 'NormalSorting' },
                { option: OddsSorting.ShowTime, gtmLabel: 'SortByTime' },
            ],
            isActive: (item: OddsSorting) => oddsSorting.value === item,
            onClick: (item: OddsSorting) => {
                if (item === oddsSorting.value) return;
                updateOddsSorting(item);
            },
            isShow: !isOutright.value,
            isDisabled: false,
        },
        {
            key: SettingGroup.OddsDisplayMode,
            items: [
                { option: OddsDisplayMode.Double, gtmLabel: 'DoubleLine' },
                { option: OddsDisplayMode.Single, gtmLabel: 'SingleLine' },
            ],
            isActive: (item: OddsDisplayMode) => oddsDisplayMode.value === item,
            onClick: (item: OddsDisplayMode) => {
                if (item === oddsDisplayMode.value) return;
                updateOddsDisplayMode(item);
            },
            isShow: !isOutright.value,
            isDisabled: sportType.value !== SportType.Soccer,
        },
        {
            key: SettingGroup.PriceStyle,
            items: priceStyleOptions.value,
            isActive: (item: PriceStyle) => {
                if (isMixParlay.value || isOutright.value) return item === PriceStyle.Euro;
                return priceStyle.value === item;
            },
            onClick: (item: PriceStyle) => {
                if (item === priceStyle.value) return;
                updatePriceStyle(item);
            },
            isShow: priceStyleOptions.value.length > 1,
            isDisabled: isMixParlay.value || isOutright.value,
        },
        {
            key: SettingGroup.MarketShowing,
            items: [
                { option: MarketShowingType.All, gtmLabel: 'AllMarket' },
                { option: MarketShowingType.Main, gtmLabel: 'MainMarket' },
            ],
            isActive: (item: MarketShowingType) => marketShowing.value === item,
            onClick: (item: MarketShowingType) => setMarketShowing(item),
            isShow: !isOutright.value,
            isDisabled: isMixParlay.value,
        },
        {
            key: SettingGroup.DisplayTime,
            items: [
                { option: DisplayTime.SystemTime, gtmLabel: 'HongKongTime' },
                { option: DisplayTime.LocalTime, gtmLabel: 'ComputerTime' },
            ],
            isActive: (item: DisplayTime) => displayTime.value === item,
            onClick: (item: DisplayTime) => {
                if (item === displayTime.value) return;
                updateDisplayTime(item);
            },
            isShow: true,
            isDisabled: false,
        },
    ].filter(x => x.isShow));

    const dropdownGroups = computed(() => {
        const groups = settingGroups.value.map<IDropdownGroup>(group => ({
            key: group.key.toString(),
            title: i18n.t(`setting_${group.key}`),
            isDisabled: group.isDisabled,
            items: group.items.map<IDropdownItem>((item, index) => ({
                key: index.toString(),
                title: i18n.t(`${group.key}_${item.option}`),
                isActive: group.isActive(item.option),
                onClick: () => group.onClick(item.option),
                gtm: {
                    page: 'K_Lobby',
                    type: 'SubHeader',
                    label: item.gtmLabel,
                    identity: PlayerType[playerType.value],
                    license: LicenseType[licenseType.value],
                    currency: sboCurrency.value,
                    uuid: trackingId.value,
                },
            })),
        }));
        return groups;
    });

    return {
        dropdownGroups,
    };
}
