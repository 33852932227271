<template>
    <div
        v-resize="(size: Size) => onResize(item, size)"
        v-bind:style="style"
    >
        <slot />
    </div>
</template>

<script setup lang="ts">
    import type { CSSProperties, PropType } from 'vue';
    import { computed } from 'vue';

    type Size = { width: number, height: number }

    const props = defineProps({
        item: { type: Object, required: true },
        translateY: { type: Number, required: true },
        onResize: { type: Function as PropType<(item: any, size: Size) => void>, required: true },
    });

    const style = computed<CSSProperties>(() => ({
        position: 'absolute',
        width: '100%',
        transform: `translateY(${props.translateY}px)`,
        transition: 'transform 0.15s linear 0s',
        animation: 'fadeIn 0.3s ease-out',
    }));
</script>
