<template>
    <div class="navbarMain">
        <div class="navbarMain_content">
            <div
                class="arrow arrow-left"
                v-bind:class="{ hidden: !showLeftArrow }"
                v-on:click="scrollToLeft"
            >
                <SvgIcon class="arrow_icon" name="icon-system-arrow" />
            </div>
            <div ref="navbarGroupEl" class="navbarMain_content_inner">
                <slot />
            </div>
            <div
                class="arrow arrow-right"
                v-bind:class="{ hidden: !showRightArrow }"
                v-on:click="scrollToRight"
            >
                <SvgIcon class="arrow_icon" name="icon-system-arrow" />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { useResizeObserver, useScroll } from '@vueuse/core';
    import { computed, onUpdated, ref, toRefs } from 'vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import { useState } from '@/composable/useState';

    const navbarGroupEl = ref<HTMLElement | null>(null);
    const { x, arrivedState, measure } = useScroll(navbarGroupEl, { offset: { left: 1, right: 1 } });
    const { left, right } = toRefs(arrivedState);
    const [hasScrollBar, setHasScrollBar] = useState(false);

    const showLeftArrow = computed(() => hasScrollBar.value && left.value === false);
    const showRightArrow = computed(() => hasScrollBar.value && right.value === false);
    const scrollToLeft = () => {
        if (navbarGroupEl.value) {
            navbarGroupEl.value.scroll({ left: x.value - 200, behavior: 'smooth' });
        }
    };
    const scrollToRight = () => {
        if (navbarGroupEl.value) {
            navbarGroupEl.value.scroll({ left: x.value + 200, behavior: 'smooth' });
        }
    };

    function resetHasScrollBar() {
        if (!navbarGroupEl.value) return;
        measure();
        setHasScrollBar(navbarGroupEl.value.scrollWidth > navbarGroupEl.value.clientWidth);
    }

    useResizeObserver(navbarGroupEl, () => {
        resetHasScrollBar();
    });

    onUpdated(() => {
        resetHasScrollBar();
    });
</script>

<style lang="scss" scoped>
    @import "@/components/common/navbar/NavbarMain";
</style>
