<template>
    <SvgIcon
        v-if="isLiveStreamPlayable"
        v-tooltip:top="liveStreamTooltip"
        v-gtm:label="'LiveStream'"
        v-gtm:@click
        class="icon live-video"
        name="icon-system-play-video-cycle"
        v-on:click="onClick"
    />
</template>

<script lang="ts" setup>
    import { computed, toRefs } from 'vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import { useLiveStream } from '@/composable/useLiveStream';
    import { i18n } from '@/core/lib/i18n';
    import type { IEvent, IEventEuro } from '@/interface/IEvent';

    const props = defineProps<{
        event: IEvent | IEventEuro;
        openPopupWindow: boolean;
    }>();

    const { event } = toRefs(props);
    const { isLiveStreamPlayable, openLiveStream } = useLiveStream(event);

    const onClick = () => {
        if (!props.openPopupWindow) return;

        openLiveStream(event.value.id);
    };

    const liveStreamTooltip = computed(() => (props.event.isLive
        ? i18n.t('live_stream_icon_tooltip')
        : i18n.t('non_live_stream_icon_tooltip')));
</script>
