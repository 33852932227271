import { storeToRefs } from 'pinia';
import { useDisplayTime } from '@/composable/useDisplayTime';
import { formatDate, formatShowTime } from '@/core/lib/date';
import { i18n } from '@/core/lib/i18n';
import { SiteStyle } from '@/interface/enum';
import { useCustomerStore } from '@/store/customerStore';

function useShowTimeDisplayEuro(date: Date, showTimeType: number) {
    const displayDate = useDisplayTime(date);
    const now = useDisplayTime(new Date());

    const isToday = displayDate.getDate() === now.getDate()
        && displayDate.getMonth() === now.getMonth();

    return {
        date: isToday
            ? i18n.t('results_date_filter_0')
            : formatDate(displayDate, 'MM/dd'),
        time: formatShowTime(displayDate, showTimeType),
    };
}

function useShowTimeDisplayAsia(date: Date, showTimeType: number) {
    const displayDate = useDisplayTime(date);
    return {
        date: formatDate(displayDate, 'MM/dd'),
        time: formatShowTime(displayDate, showTimeType),
    };
}

export function useShowTimeDisplay(date: Date, showTimeType: number) {
    const { siteStyle } = storeToRefs(useCustomerStore());
    return siteStyle.value === SiteStyle.Euro
        ? useShowTimeDisplayEuro(date, showTimeType)
        : useShowTimeDisplayAsia(date, showTimeType);
}
