import CryptoJS from 'crypto-js';
import { HashType } from '@/interface/enum';

export function hashString(value: string, hashType: HashType): string {
    switch (hashType) {
        case HashType.MD5:
            return CryptoJS.MD5(value).toString();
        case HashType.SHA256:
            return CryptoJS.SHA256(value).toString();
        default:
            console.error('Unsupported hash type');
            return value;
    }
}
