<template>
    <div class="fakeHeader">
        <div class="fakeHeader_inner">
            Header
            <select
                v-bind:value="locale"
                v-on:change="(e: any) => setI18nLanguage(e.target.value)"
            >
                <option
                    v-for="lang in languages"
                    v-bind:key="lang"
                    v-bind:value="lang"
                >
                    {{ lang }}
                </option>
            </select>
            <select v-model="priceStyle">
                <option
                    v-for="style in [PriceStyle.HK, PriceStyle.Euro, PriceStyle.Indo, PriceStyle.Malay]"
                    v-bind:key="style"
                    v-bind:value="style"
                >
                    {{ PriceStyle[style] }}
                </option>
            </select>
            <select v-model="sboCurrency">
                <option
                    v-for="currency in sboCurrencies"
                    v-bind:key="currency"
                    v-bind:value="currency"
                >
                    {{ currency }}
                </option>
            </select>

            <div>
                Mock Place Bet Status
                <select v-model="mockPlaceBetStatus">
                    <option v-bind:value="null">
                        Disabled
                    </option>
                    <option
                        v-for="item in placeBetError"
                        v-bind:key="item"
                        v-bind:value="item"
                    >
                        {{ PlaceOrderStatus[item] }}
                    </option>
                </select>
            </div>
            <div>
                Mock Place Bet ExtraInfo
                <input v-model="mockPlaceBetExtraInfo">
            </div>
            <div>
                Mock Open Ticket Status
                <select v-model="mockOpenTicketStatus">
                    <option v-bind:value="null">
                        Disabled
                    </option>
                    <option
                        v-for="item in openTicketError"
                        v-bind:key="item"
                        v-bind:value="item"
                    >
                        {{ OpenTicketStatus[item] }}
                    </option>
                </select>
            </div>

            <div>
                Mock CashOut Status
                <select v-model="mockCashOutResult">
                    <option v-bind:value="null">
                        Disabled
                    </option>
                    <option
                        v-for="item in cashOutStatus"
                        v-bind:key="item"
                        v-bind:value="item"
                    >
                        {{ CashOutStatus[item] }}
                    </option>
                </select>
            </div>
            <div>
                Mock CashOut Value Response Supported
                <select v-model="mockCashOutValueIsSupported">
                    <option v-bind:value="null">
                        Disabled
                    </option>
                    <option
                        v-for="item in [true, false]"
                        v-bind:key="item.toString()"
                        v-bind:value="item"
                    >
                        {{ item }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import { mockCashOutResult, mockCashOutValueIsSupported, mockOpenTicketStatus, mockPlaceBetExtraInfo, mockPlaceBetStatus } from '@/core/lib/api';
    import { i18n, setI18nLanguage } from '@/core/lib/i18n';
    import { EnumLanguage, OpenTicketStatus, PlaceOrderStatus, PriceStyle, SboCurrency } from '@/interface/enum';
    import { CashOutStatus } from '@/interface/pollux';
    import { useCustomerStore } from '@/store/customerStore';

    const { locale } = i18n;

    const languages = Object.values(EnumLanguage)
        .filter(x => typeof x !== 'function') as unknown as EnumLanguage[];

    const sboCurrencies = Object.values(SboCurrency)
        .filter(x => typeof x !== 'function') as unknown as SboCurrency[];

    const placeBetError = Object.values(PlaceOrderStatus)
        .filter(x => typeof x === 'number') as unknown as PlaceOrderStatus[];

    const openTicketError = Object.values(OpenTicketStatus)
        .filter(x => typeof x === 'number') as unknown as OpenTicketStatus[];

    const cashOutStatus = Object.values(CashOutStatus)
        .filter(x => typeof x === 'number') as unknown as CashOutStatus[];

    const { sboCurrency, priceStyle } = storeToRefs(useCustomerStore());
</script>

<style lang="scss">
    .fakeHeader_inner {
        display: flex;
        flex-flow: wrap;
    }
</style>
