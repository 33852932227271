import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useCountdown } from '@/composable/useCountdown';
import { useIdle } from '@/composable/useIdle';
import { MainBetStatus } from '@/interface/enum';
import { useMyBetsStore } from '@/store/myBetsStore';
import { useToggleStore } from '@/store/toggleStore';

export function useRefresher() {
    const _refresh = async (isThrottled: boolean, useCache: boolean) => {
        const { refreshMyBets, refreshMyBetsThrottled } = useMyBetsStore();
        if (isThrottled) {
            await refreshMyBetsThrottled(useCache);
        } else {
            await refreshMyBets(useCache);
        }
    };

    const { runningBets } = storeToRefs(useMyBetsStore());
    const { myBetWaitingRefreshSeconds, myBetRefreshSeconds } = storeToRefs(useToggleStore());
    const refreshDuration = computed(() => {
        const hasWaitingBet = runningBets.value.some(x => x.mainBetStatus === MainBetStatus.Waiting);
        return hasWaitingBet ? myBetWaitingRefreshSeconds.value : myBetRefreshSeconds.value;
    });

    const { isLoading: isRefreshing, countdown, trigger: refresh, forceTrigger: forceRefresh } = useCountdown(
        refreshDuration,
        _refresh,
        { minLoadingTime: 1000 },
    );

    // Automatically recover from idle state with a force refresh
    // This is to prevent the user from losing data when they are idle
    const { onActive } = useIdle(60 * 1000);
    onActive(() => {
        // eslint-disable-next-line no-console
        console.log('MyBets:Recovering from idle state...');
        refresh(true, true);
    });

    return {
        countdown,
        refresh,
        forceRefresh,
        isRefreshing,
    };
}
