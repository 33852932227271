<template>
    <div
        v-for="(row, rowIndex) in tableRows"
        v-bind:key="rowIndex"
        class="matches_subRow"
    >
        <template v-if="oddsDisplayMode === OddsDisplayMode.Single">
            <template
                v-for="(cells, cellsIndex) in row"
                v-bind:key="cellsIndex"
            >
                <div
                    class="matches_row_cell matches_row_cell-point"
                    v-bind:style="{ width: getColumnWidth(cells.marketType, cells.marketGroup) }"
                >
                    <div class="oddsItem">
                        <div class="oddsPoint">
                            {{ cells.selections.length > 0 ? formatPoint(cells.selections?.[0]?.market.point ?? 0) : '' }}
                        </div>
                    </div>
                </div>
                <div
                    v-for="(cell, idx) in cells.selections"
                    v-bind:key="`${cell.market.matchMarketId}-${'id' in cell.selection ? cell.selection.id : `i${idx}`}`"
                    class="matches_row_cell"
                    v-bind:style="{ width: getColumnWidth(cells.marketType, cells.marketGroup) }"
                >
                    <div class="oddsItem">
                        <Selection
                            v-if="cell.isAvailable && 'id' in cell.selection"
                            v-bind:matchId="event.id"
                            v-bind:market="cell.market"
                            v-bind:selection="cell.selection"
                            class="oddsPrice"
                        />
                    </div>
                </div>
                <template v-if="cells.selections.length === 0">
                    <div
                        v-for="i in getPriceCount(cells.marketType)"
                        v-bind:key="i"
                        class="matches_row_cell"
                        v-bind:style="{ width: getColumnWidth(cells.marketType, cells.marketGroup) }"
                    />
                </template>
            </template>
        </template>
        <template v-if="oddsDisplayMode === OddsDisplayMode.Double">
            <div
                v-for="(cells, cellsIndex) in row"
                v-bind:key="cellsIndex"
                class="matches_row_cell"
                v-bind:style="{ width: getColumnWidth(cells.marketType, cells.marketGroup) }"
                v-bind:class="`group-${cells.block ?? `other`}`"
            >
                <div
                    v-for="(cell, idx) in cells.selections"
                    v-bind:key="`${cell.market.matchMarketId}-${'id' in cell.selection ? cell.selection.id : `i${idx}`}`"
                    class="oddsItem"
                    v-bind:style="{
                        maxWidth: `${getColumnWidthLimit(cells.marketType, cells.marketGroup)}px`,
                    }"
                >
                    <div v-if="cells.selections.some(p => p.point)" class="oddsPoint">
                        {{ cell.point }}
                    </div>

                    <Selection
                        v-if="cell.isAvailable && 'id' in cell.selection"
                        v-bind:matchId="event.id"
                        v-bind:market="cell.market"
                        v-bind:selection="cell.selection"
                        class="oddsPrice"
                    />
                </div>
            </div>
        </template>
        <div
            v-if="rowIndex === 0 && moreMarketCount > 0"
            class="matches_row_cell matchesMore"
            v-bind:class="{ showMore: isOpenMoreMarket }"
            v-bind:style="{ width: getColumnWidth(OddsDisplayBlockType.More) }"
        >
            <MoreButton
                v-gtm:label="'More'"
                v-gtm:@click
                v-bind:isOpenMore="isOpenMoreMarket"
                v-bind:count="moreMarketCount"
                v-on:click="emit('clickMore')"
            />
        </div>
        <div
            v-else
            class="matches_row_cell"
            v-bind:style="{ width: getColumnWidth(OddsDisplayBlockType.More) }"
        />
    </div>
</template>

<script lang="ts" setup>
    import { computed, toRefs } from 'vue';
    import MoreButton from '@/components/common/button/MoreButton.vue';
    import Selection from '@/components/odds/Selection.vue';
    import { OddsDisplayContext } from '@/components/oddsDisplay/OddsDisplayContext';
    import { useMatchBodyCalculation } from '@/components/oddsDisplay/matchBodyCalculation';
    import { getOddsTableBetBuilder } from '@/components/oddsDisplay/oddsTableCalculation';
    import { formatPoint } from '@/core/lib/oddsHelper';
    import type { IEvent } from '@/interface/IEvent';
    import type { IBetBuilderMarket } from '@/interface/betBuilder';
    import { OddsDisplayBlockType, OddsDisplayMode } from '@/interface/enum';

    const props = defineProps<{
        event: IEvent;
        mainMarkets: IBetBuilderMarket[];
        moreMarkets: IBetBuilderMarket[];
        isOpenMoreMarket: boolean;
    }>();

    const { event, isOpenMoreMarket } = toRefs(props);
    const { oddsDisplayMode } = OddsDisplayContext.inject();

    const { getColumnWidth, getColumnWidthLimit, getPriceCount } = useMatchBodyCalculation(oddsDisplayMode, event);

    const tableRows = computed(() => getOddsTableBetBuilder(oddsDisplayMode, props.event, props.mainMarkets));

    const moreMarketCount = computed(() => props.moreMarkets.length);

    const emit = defineEmits(['clickMore']);
</script>

<style lang="scss" scoped>
    @import "@/components/oddsDisplay/OddsDisplayMatchBody";
</style>
