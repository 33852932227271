import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import type { BehaviorType } from '@/interface/enum';
import { useCustomerStore } from '@/store/customerStore';
import { useToggleStore } from '@/store/toggleStore';

export function useSendBehaviorToggle(behaviorType: BehaviorType) {
    const { isSendBehaviorEnabled: _isSendBehaviorEnabled } = storeToRefs(useToggleStore());
    const { isQ } = storeToRefs(useCustomerStore());
    const { getBehaviorMinutes } = useToggleStore();

    const durationSeconds = computed(() => Math.ceil(getBehaviorMinutes(behaviorType) * 60));

    const isSendBehaviorEnabled = computed(() => _isSendBehaviorEnabled.value && !isQ.value && durationSeconds.value !== 0);

    return {
        durationSeconds,
        isSendBehaviorEnabled,
    };
}
