<template>
    <div class="ticket notranslate">
        <div class="topInfo">
            <div class="marketOption topInfoItem">
                <div class="optionDisplay">
                    <span v-if="liveScoreDisplay" class="optionOddsGroup notranslate">
                        ({{ liveScoreDisplay }})
                    </span>
                    {{ optionDisplay }}
                    <span v-if="isShowPoint">
                        {{ pointDisplay }}
                    </span>
                </div>
                <div v-if="!hidePrice" class="betSlipOdds">
                    <span class="price"> {{ priceDisplay }} </span>
                </div>
            </div>
            <div class="marketType topInfoItem">
                {{ marketGroupName }}
            </div>
            <div class="teamName topInfoItem">
                {{ ticket.event.homeTeam.name }}
                v
                {{ ticket.event.awayTeam.name }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { toRefs } from 'vue';
    import { useTicketDisplay } from '@/composable/useTicketDisplay';
    import type { ITicketEuro } from '@/interface/IBetSlip';

    const props = withDefaults(defineProps<{
        ticket: ITicketEuro;
        hidePrice?: boolean;
    }>(), {
        hidePrice: false,
    });
    const { ticket } = toRefs(props);

    const {
        marketGroupName,
        optionDisplay,
        liveScoreDisplay, priceDisplay,
        isShowPoint, pointDisplay,
    } = useTicketDisplay(ticket);
</script>

<style scoped lang="scss">
    @import "@/components/betSlip/Ticket.Euro";
</style>
