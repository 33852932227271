<template>
    <div
        class="count count-color"
        v-bind:style="style"
        v-bind:class="{
            'count-right': right,
        }"
    >
        <slot>{{ number }}</slot>
    </div>
</template>

<script lang="ts" setup>
    import type { PropType } from 'vue';
    import { computed } from 'vue';
    import type { CSSPropertiesWithVars } from '@/core/lib/style';
    import type { IColors, IRootObject } from '@/interface/sassVariables';

    const props = defineProps({
        number: {
            type: Number,
            default: () => 0,
        },
        right: {
            type: Boolean,
            default: () => false,
        },
        bgColor: {
            type: String as PropType<keyof IColors | keyof IRootObject>,
            default: () => 'countBgColor',
        },
        textColor: {
            type: String as PropType<keyof IColors | keyof IRootObject>,
            default: () => 'countColor',
        },
        borderColor: {
            type: String as PropType<keyof IColors | keyof IRootObject>,
            default: () => 'countBorderColor',
        },
    });

    const style = computed(() => {
        const result: CSSPropertiesWithVars = {};
        result['--v-count-bg-color'] = `var(--${props.bgColor})`;
        result['--v-count-text-color'] = `var(--${props.textColor})`;
        result['--v-count-border-color'] = `var(--${props.borderColor})`;
        return result;
    });

</script>

<style lang="scss" scoped>
    .count {
        padding: map-get($spacers, xs) map-get($spacers, sm);
        border-width: 1px;
        border-style: solid;
        border-color: $countBorderColor;
        background-color: $countBgColor;
        border-radius: $countBorderRadius;
        color: $countColor;
        font-size: $fontSize-base;
        font-weight: bold;
    }

    .count-color {
        border-color: var(--v-count-border-color);
        background-color: var(--v-count-bg-color);
        color: var(--v-count-text-color);
    }

    .count-right {
        margin-left: map-get($spacers, sm);
    }
</style>
