import { defineStore } from 'pinia';
import { useState } from '@/composable/useState';
import { Api } from '@/core/lib/api';

interface IMaxPayout {
    soccer: number;
    otherSportsMin: number;
    otherSportsMax: number;
}

export const useMaxPayoutStore = defineStore('MaxPayoutStore', () => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [maxPayout, setMaxPayout] = useState<IMaxPayout>({
        soccer: 0,
        otherSportsMin: 0,
        otherSportsMax: 0,
    });

    async function fetchMixParlayMaxPayout() {
        const { data: { soccer, otherSportsMin, otherSportsMax } } = await Api.getMixParlayMaxPayout();
        setMaxPayout({
            soccer,
            otherSportsMin,
            otherSportsMax,
        });
        setIsLoaded(true);
    }

    return {
        maxPayout,
        isLoaded,
        fetchMixParlayMaxPayout,
    };
});
