import { storeToRefs } from 'pinia';
import { computed, nextTick } from 'vue';
import { scrollToTopImmediate } from '@/core/lib/dom';
import { i18n } from '@/core/lib/i18n';
import { MarketPage } from '@/interface/enum';
import { useAsiaSportMarketInfoStore } from '@/store/asiaSportMarketInfoStore';

export function useSportMarketPage() {
    const { sportType, marketPage, navbarMarketList, mixParlaySportsInfoDisplay, favoriteSportsInfoDisplay, isMultipleSportsZone, multipleSportsList } = storeToRefs(useAsiaSportMarketInfoStore());
    const { setSportType, setMarketPage } = useAsiaSportMarketInfoStore();
    const { currentPromotionZoneConfig } = storeToRefs(useAsiaSportMarketInfoStore());

    const showDividingMarkets = [MarketPage.MixParlay, MarketPage.Outright];
    const showSportsItems = [MarketPage.MixParlay, MarketPage.MyFavorites];

    const gtmLabelMapping: Partial<Record<MarketPage, string>> = {
        [MarketPage.Live]: 'Live',
        [MarketPage.Today]: 'Today',
        [MarketPage.EarlyMarket]: 'EarlyMarket',
        [MarketPage.MixParlay]: 'MixParlay',
        [MarketPage.Outright]: 'Outright',
    };

    const navbarInfoMap = new Map([
        [MarketPage.MixParlay, { sportsInfo: mixParlaySportsInfoDisplay, marketPage: MarketPage.MixParlay, gtmLabel: 'MPNavbar_' }],
        [MarketPage.MyFavorites, { sportsInfo: favoriteSportsInfoDisplay, marketPage: MarketPage.MyFavorites, gtmLabel: '' }],
    ]);

    const navbarItems = computed(() => {
        if (isMultipleSportsZone.value) {
            const gtmPrefix = currentPromotionZoneConfig.value?.gtmLabel ?? '';
            return multipleSportsList.value.map(sport => ({
                key: sport.sportType,
                isActive: sport.sportType === sportType.value,
                name: sport.name,
                count: sport.totalEventCount,
                isLive: sport.hasLiveEvents,
                onClick: async () => {
                    setSportType(sport.sportType);
                    await nextTick();
                    scrollToTopImmediate();
                },
                showDividing: false,
                gtmLabel: `${gtmPrefix}_${sport.sportType.toString()}`,
            }));
        }

        if (showSportsItems.includes(marketPage.value)) {
            const navbarInfo = navbarInfoMap.get(marketPage.value);
            return navbarInfo?.sportsInfo.value
                .map(sportInfo => ({
                    key: `${sportInfo.sportType}-${marketPage.value}`,
                    isActive: sportInfo.sportType === sportType.value,
                    name: sportInfo.name,
                    count: sportInfo.totalEventCount,
                    isLive: sportInfo.hasLiveEvents,
                    onClick: async () => {
                        setSportType(sportInfo.sportType, navbarInfo.marketPage);
                        await nextTick();
                        scrollToTopImmediate();
                    },
                    showDividing: false,
                    gtmLabel: `${navbarInfo.gtmLabel}${sportInfo.sportType.toString()}`,
                })) ?? [];
        }
        return navbarMarketList.value
            .filter(x => x.marketPage !== MarketPage.MixParlay)
            .map(market => ({
                key: market.marketPage,
                isActive: market.marketPage === marketPage.value,
                name: i18n.t(`market_page_name_${market.marketPage}`),
                count: market.totalEventCount,
                isLive: market.liveEventCount > 0,
                onClick: async () => {
                    setMarketPage(market.marketPage);
                    await nextTick();
                    scrollToTopImmediate();
                },
                showDividing: showDividingMarkets.includes(market.marketPage),
                gtmLabel: gtmLabelMapping[market.marketPage] ?? '',
            }));
    });

    return {
        navbarItems,
    };
}
