<template>
    <div class="bet bet-group notranslate" v-bind:class="{ rejected: bet.isWaitingReject }">
        <div class="bet_header">
            <div class="bet_header_title">
                <strong class="optionTitle">
                    <span v-if="bet.isBetBuilder">
                        {{ i18n.t(`market_type_name_${MarketType.BetBuilder}`) }}
                        -
                        {{ i18n.t(`sport_type_name_${bet.sportType}`) }}
                    </span>
                    <span v-else>{{ i18n.t(`market_type_name_${MarketType.MixParlay}`) }}</span>
                </strong>
            </div>
            <div class="bet_header_aside">
                <VButton
                    v-gtm:label="isExpand ? 'MPCollapse' : 'MPExpand'"
                    v-gtm:@click
                    padding="0 2px"
                    color="primary"
                    textColor="white"
                    hoverTextColor="white"
                    style="margin-right: 2px;"
                    v-on:click="toggleIsExpand"
                >
                    <SvgIcon
                        name="icon-system-arrow"
                        size="md"
                        v-bind:rotated="!isExpand"
                    />
                </VButton>
                <VButton
                    v-if="bet.isWaitingReject"
                    padding="2"
                    rounded
                    v-on:click="updateHiddenBetTransIds(bet.transactionId)"
                >
                    <SvgIcon name="icon-system-close" size="md" />
                </VButton>
            </div>
        </div>
        <div class="bet_option" v-bind:class="{ live: hasLiveSubBets || isLiveBetBuilder }">
            <component
                v-bind:is="bet.isWaitingReject ? 'del' : 'div'"
                class="parlayOption"
            >
                <ul class="parlayOption_inner">
                    <li>
                        <strong>{{ selectionsDisplay }}</strong>
                    </li>
                    <li class="parlayOptionInfo">
                        <span class="optionOdds optionOdds-price">
                            <strong class="optionOddsPrice">
                                {{ priceDisplay }} {{ `(${priceStyleDisplay})` }}
                            </strong>
                        </span>
                    </li>
                </ul>
                <strong class="parlayOption_Stake">
                    <CurrencyIcon />
                    {{ stakeDisplay }}
                </strong>
            </component>
        </div>
        <div v-if="isExpand" class="bet_group">
            <template v-if="bet.isMixParlay">
                <BetOptionInfo
                    v-for="subBet in bet.subBets"
                    v-bind:key="subBet.transactionId"
                    v-bind:bet="subBet"
                />
            </template>
            <template v-else-if="bet.isBetBuilder">
                <LegInfo
                    v-for="leg in bet.betBuilderLegs"
                    v-bind:key="leg.transactionId"
                    v-bind:leg="leg"
                />
            </template>
        </div>
        <BetDetail v-bind:bet="bet" />
        <ExpandTransition>
            <div v-if="isDisplayCashOut && bet.cashOutService && bet.cashOutService.isSupported" class="bet_footer">
                <div class="bet_footer_info">
                    <CashOut
                        isMiniMyBet
                        v-bind:bet="bet"
                        v-bind:cashOutService="bet.cashOutService"
                    />
                </div>
            </div>
        </ExpandTransition>
    </div>
</template>

<script lang="ts" setup>
    import { computed, toRefs } from 'vue';

    import CashOut from '@/components/cashOut/CashOut.vue';
    import CurrencyIcon from '@/components/common/CurrencyIcon.vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import VButton from '@/components/common/button/VButton.vue';
    import BetDetail from '@/components/myBets/miniMyBets/BetDetail.vue';
    import BetOptionInfo from '@/components/myBets/miniMyBets/BetOptionInfo.vue';
    import LegInfo from '@/components/myBets/miniMyBets/LegInfo.vue';
    import {
        betBuilderSelectionsDisplay,
        mixParlaySelectionsDisplay,
        useBetDisplay,
    } from '@/components/myBets/useBetDisplay';
    import { useDisplayCashOut } from '@/components/myBets/useDisplayCashOut';
    import ExpandTransition from '@/components/transitions/ExpandTransition.vue';
    import { useToggle } from '@/composable/useToggle';
    import { i18n } from '@/core/lib/i18n';
    import type { IBet } from '@/interface/IBet';
    import { MarketType } from '@/interface/enum';
    import { useMiniMyBetsStore } from '@/store/miniMyBetsStore';

    const props = defineProps<{
        bet: IBet;
    }>();

    const { bet } = toRefs(props);
    const [isExpand, toggleIsExpand] = useToggle(false);
    const { updateHiddenBetTransIds } = useMiniMyBetsStore();
    const { priceDisplay, priceStyleDisplay, stakeDisplay } = useBetDisplay(bet);
    const hasLiveSubBets = bet.value.subBets.some(subBet => subBet.isLive);
    const isLiveBetBuilder = computed(() => bet.value.isBetBuilder && bet.value.isLive);
    const { isDisplayCashOut } = useDisplayCashOut();
    const selectionsDisplay = computed(() => (bet.value.isMixParlay
        ? mixParlaySelectionsDisplay(bet.value.subBets.length)
        : betBuilderSelectionsDisplay(bet.value.betBuilderLegs?.length ?? 0)));
</script>

<style lang="scss" scoped>
    @import "@/components/myBets/miniMyBets/Bet";
</style>
