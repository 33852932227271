<template>
    <Breadcrumbs />
    <SportsNavBar showCount showTriangle />
    <Navbar>
        <template #bottomRow>
            <NavbarRowItem class="right">
                <div class="formGroup">
                    <select v-model="market" class="select euroSelect">
                        <option
                            v-for="m in markets"
                            v-bind:key="m.market"
                            v-bind:value="m"
                        >
                            {{ m.name }}
                        </option>
                    </select>
                </div>
            </NavbarRowItem>
        </template>
    </Navbar>

    <TwoSubBetParlayBanner v-if="isMpTwoSubBetEnabled" />

    <MaxPayout />

    <OddsDisplayBlock
        v-bind:groups="groups"
        v-bind:loading="!loaded"
        v-bind:initialIsExpanded="(index: number) => index < 3"
        enableVirtualScroll
    />
</template>

<script lang="ts" setup>
    import orderBy from 'lodash/orderBy';
    import { storeToRefs } from 'pinia';
    import { computed, ref, watch } from 'vue';
    import Navbar from '@/components/common/navbar/Navbar.vue';
    import NavbarRowItem from '@/components/common/navbar/NavbarRowItem.vue';
    import { EuroDisplayContext } from '@/components/euroDisplay/EuroDisplayContext';
    import OddsDisplayBlock from '@/components/euroDisplay/oddsDisplay/OddsDisplayBlock.vue';
    import TwoSubBetParlayBanner from '@/components/event/TwoSubBetParlayBanner.vue';
    import MaxPayout from '@/components/maxPayout/MaxPayout.vue';
    import Breadcrumbs from '@/components/navbarBlock/euro/Breadcrumbs.vue';
    import SportsNavBar from '@/components/navbarBlock/euro/SportsNavBar.vue';
    import { useEuroRoute } from '@/composable/useEuroRoute';
    import { useEuroRouter } from '@/composable/useEuroRouter';
    import { useSportsOrder } from '@/composable/useSportsOrder';
    import { groupByWithOrder } from '@/core/lib/array';
    import { useEvents } from '@/core/oddsApi/composable/useEvents.euro';
    import { EnumEventDateFilterType, EnumOddsCategoryType, EnumPresetFilterType } from '@/core/oddsApi/oddsApiType';
    import { EuroDate, SportType } from '@/interface/enum';
    import { useToggleStore } from '@/store/toggleStore';

    const { sportType: _sportType } = useEuroRoute();

    const { defaultSportType } = useSportsOrder();
    const defaultSport = _sportType.value === SportType.Unknown
        ? defaultSportType.value
        : _sportType.value;

    const { sportType, markets, market } = EuroDisplayContext.provide(ref(defaultSport), ref(true), ref(EuroDate.Unknown));

    const { goToMultiple } = useEuroRouter();
    watch(sportType, () => goToMultiple(sportType.value, 'replace'));

    const { events, loaded } = useEvents({
        sportType,
        presetFilter: { presetFilter: EnumPresetFilterType.MixParlay, date: EnumEventDateFilterType.All },
        oddsCategory: EnumOddsCategoryType.MixParlay,
    });

    const groups = computed(() => {
        const sortedEvents = orderBy(events.value, e => e.eventCode);
        const groups = groupByWithOrder(sortedEvents, e => e.league.id);
        return groups
            .map((group) => {
                const events = orderBy(group, [e => e.isLive], ['desc']);
                return {
                    id: events[0].id,
                    events,
                    title: events[0].league.name,
                    info: events[0].league.info ?? undefined,
                };
            });
    });

    const { isMpTwoSubBetEnabled } = storeToRefs(useToggleStore());
</script>

<style lang="scss" scoped>
.notification {
    &.info {
        background-color: var(--brandColor-100);
    }

    :deep(.notificationContent) {
        padding: 8px;
    }
}

.right {
    margin-left: auto;
}
</style>
