import { getKratosUrl } from '@/core/lib/url';

declare global {
    interface Window {
        __public_path__: string;
        __dynamicImportHandler__: (importer: string) => string;
        __dynamicImportPreload__: (preloadList: string[]) => string[];
    }
}

const base = getKratosUrl();

window.__public_path__ = `${base}/`;

window.__dynamicImportHandler__ = importer => base + importer;

window.__dynamicImportPreload__ = preloadList => preloadList.map(preload => base + preload);
