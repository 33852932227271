<template>
    <div class="fastMarket width-full" v-bind:class="{ live: odds.isLive }">
        <strong class="fastMarket_title">
            {{ title }}
        </strong>
        <div class="fastMarket_content">
            <div class="fastMarket_content_col">
                <div class="fastMarketDescription" />
                <div class="fastMarketDescription">
                    {{ i18n.t('odds_option_fm_goal') }}
                </div>
                <div class="fastMarketDescription">
                    {{ i18n.t('odds_option_fm_nogoal') }}
                </div>
            </div>
            <div
                v-for="fastMarketOdds in fastMarketOddsList"
                v-bind:key="fastMarketOdds.id"
                class="fastMarket_content_col"
            >
                <div class="fastMarketDescription">
                    {{ getFastMarketDisplayName(fastMarketOdds) }}
                </div>
                <div class="goal">
                    <Odds
                        v-bind:eventId="event.id"
                        v-bind:oddsId="fastMarketOdds.id"
                        v-bind:oddsPrice="fastMarketOdds.prices[0]"
                        v-bind:oddsStatus="fastMarketOdds.status"
                        align="center"
                        class="oddsPrice"
                    />
                </div>
                <div class="no-goal">
                    <Odds
                        v-bind:eventId="event.id"
                        v-bind:oddsId="fastMarketOdds.id"
                        v-bind:oddsPrice="fastMarketOdds.prices[1]"
                        v-bind:oddsStatus="fastMarketOdds.status"
                        align="center"
                        class="oddsPrice"
                    />
                </div>
            </div>
            <div
                v-for="index in Math.max(0, 5 - fastMarketOddsList.length)"
                v-bind:key="index"
                class="fastMarket_content_col"
            >
                <div class="fastMarketDescription" />
                <div class="goal" />
                <div class="no-goal" />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import flow from 'lodash/fp/flow';
    import map from 'lodash/fp/map';
    import orderBy from 'lodash/fp/orderBy';
    import { computed } from 'vue';
    import Odds from '@/components/odds/Odds.vue';
    import { i18n } from '@/core/lib/i18n';
    import { getFastMarketDisplayName, isFiveMinFastMarket, isOneMinFastMarket } from '@/core/lib/oddsHelper';
    import type { IEvent } from '@/interface/IEvent';
    import type { IOdds, IPrice } from '@/interface/IOdds';

    const props = defineProps<{
        event: IEvent;
        odds: IOdds;
        oddsList: IOdds[];
    }>();

    const title = computed(() => {
        if (isOneMinFastMarket(props.odds)) {
            return i18n.t('market_type_name_fast_market_1');
        }
        return isFiveMinFastMarket(props.odds)
            ? i18n.t('market_type_name_fast_market_5')
            : '';
    });

    const fastMarketPriceSort: (list: IPrice[]) => IPrice[] = flow(
        orderBy<IPrice>([
            price => ['h', 'a'].indexOf(price.option.toUpperCase()),
        ], ['asc']),
    );

    const fastMarketOddsSort: (list: IOdds[]) => IOdds[] = flow(
        orderBy<IOdds>([
            odds => odds.eventResult.marketGroup.id,
        ], ['asc']),
        map<IOdds, IOdds>(odds => ({
            ...odds,
            prices: fastMarketPriceSort(odds.prices),
        })),
    );

    const fastMarketOddsList = computed(() => fastMarketOddsSort(props.oddsList));
</script>

<style lang="scss" scoped>
    @import "@/components/oddsDisplay/moreMarket/marketType/FastMarket";
</style>
