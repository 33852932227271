<template>
    <div class="bet notranslate" v-bind:class="{ rejected: bet.isWaitingReject, live: bet.isLive }">
        <BetOptionInfo v-bind:bet="bet" />
        <BetDetail v-bind:bet="bet" />
        <ExpandTransition>
            <div v-if="isDisplayCashOut && bet.cashOutService && bet.cashOutService.isSupported" class="bet_footer">
                <div class="bet_footer_info">
                    <CashOut
                        v-bind:bet="bet"
                        v-bind:cashOutService="bet.cashOutService"
                        isMiniMyBet
                    />
                </div>
            </div>
        </ExpandTransition>
    </div>
</template>

<script lang="ts" setup>
    import type { PropType } from 'vue';
    import { toRefs } from 'vue';
    import CashOut from '@/components/cashOut/CashOut.vue';
    import BetDetail from '@/components/myBets/miniMyBets/BetDetail.vue';
    import BetOptionInfo from '@/components/myBets/miniMyBets/BetOptionInfo.vue';
    import { useDisplayCashOut } from '@/components/myBets/useDisplayCashOut';
    import ExpandTransition from '@/components/transitions/ExpandTransition.vue';
    import type { IBet } from '@/interface/IBet';

    const props = defineProps({
        bet: {
            type: Object as PropType<IBet>,
            required: true,
        },
    });
    const { bet } = toRefs(props);
    const { isDisplayCashOut } = useDisplayCashOut();
</script>

<style lang="scss" scoped>
    @import "@/components/myBets/miniMyBets/Bet";
</style>
