import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { usePlatform } from '@/composable/usePlatform';
import { useState } from '@/composable/useState';
import { Api } from '@/core/lib/api';
import { ExchangeAction, ExchangeStatus, ExchangeTransferState, ExchangeTransferValidStatus } from '@/interface/enum';
import { useExchangeCustomerStore } from '@/store/exchangeCustomerStore';

export function useTransfer() {
    const [exchangeTransferState, setExchangeTransferState] = useState<ExchangeTransferState>(ExchangeTransferState.BeforeTransfer);
    const [amount, setAmount] = useState(0);

    const { refreshBalanceAfterAction } = usePlatform();

    const statusMapping: Record<ExchangeStatus, Record<ExchangeAction, ExchangeTransferState>> = {
        [ExchangeStatus.Success]: {
            [ExchangeAction.TransferIn]: ExchangeTransferState.TransferInSuccess,
            [ExchangeAction.TransferOut]: ExchangeTransferState.TransferOutSuccess,
        },
        [ExchangeStatus.ThirdPartyError]: {
            [ExchangeAction.TransferIn]: ExchangeTransferState.TransferInThirdPartyError,
            [ExchangeAction.TransferOut]: ExchangeTransferState.TransferOutThirdPartyError,
        },
        [ExchangeStatus.Error]: {
            [ExchangeAction.TransferIn]: ExchangeTransferState.TransferInError,
            [ExchangeAction.TransferOut]: ExchangeTransferState.TransferOutError,
        },
        [ExchangeStatus.Unknown]: {
            [ExchangeAction.TransferIn]: ExchangeTransferState.BeforeTransfer,
            [ExchangeAction.TransferOut]: ExchangeTransferState.BeforeTransfer,
        },
    };

    async function handleTransferSuccess(status: ExchangeStatus, exchangeAction: ExchangeAction) {
        const { fetchCustomerInfo } = useExchangeCustomerStore();
        setExchangeTransferState(statusMapping[status][exchangeAction]);
        setTimeout(() => {
            fetchCustomerInfo();
            refreshBalanceAfterAction();
        }, 1000);
    }

    async function transferIn() {
        await transfer(ExchangeAction.TransferIn);
    }

    async function transferOut() {
        await transfer(ExchangeAction.TransferOut);
    }

    const isTransferring = ref(false);

    async function transfer(exchangeAction: ExchangeAction) {
        const isValid = await transferValidation(exchangeAction);
        if (!isValid) {
            return;
        }
        isTransferring.value = true;
        const transferApi = exchangeAction === ExchangeAction.TransferIn ? Api.exchangeTransferIn : Api.exchangeTransferOut;
        try {
            const { status } = await transferApi(amount.value);
            switch (status) {
                case ExchangeStatus.Success: {
                    handleTransferSuccess(status, exchangeAction);
                    break;
                }
                case ExchangeStatus.Error:
                case ExchangeStatus.ThirdPartyError: {
                    setExchangeTransferState(statusMapping[status][exchangeAction]);
                    break;
                }
                case ExchangeStatus.Unknown:
                default: {
                    break;
                }
            }
        } catch (e) {
            setExchangeTransferState(statusMapping[ExchangeStatus.Error][exchangeAction]);
        } finally {
            isTransferring.value = false;
        }
    }

    const { customerInfo } = storeToRefs(useExchangeCustomerStore());

    const [exchangeTransferValidStatus, setExchangeTransferValidStatus] = useState<ExchangeTransferValidStatus>(ExchangeTransferValidStatus.Valid);

    async function transferValidation(exchangeAction: ExchangeAction): Promise<boolean> {
        if (exchangeAction === ExchangeAction.TransferIn) {
            if (customerInfo.value?.isSuspended) {
                setExchangeTransferValidStatus(ExchangeTransferValidStatus.AccountProblem);
                return false;
            }
            if (customerInfo.value?.betCredit !== undefined && amount.value > customerInfo.value?.betCredit) {
                setExchangeTransferValidStatus(ExchangeTransferValidStatus.InsufficientInAccount);
                return false;
            }
            if (customerInfo.value?.hasBettingBudget && amount.value > customerInfo.value?.bettingBudget) {
                setExchangeTransferValidStatus(ExchangeTransferValidStatus.ExceedBettingBudget);
                return false;
            }
        } else if (exchangeAction === ExchangeAction.TransferOut) {
            if (customerInfo.value?.exchangeBalance !== undefined && amount.value > customerInfo.value?.exchangeBalance) {
                setExchangeTransferValidStatus(ExchangeTransferValidStatus.InsufficientInWallet);
                return false;
            }
        }
        setExchangeTransferValidStatus(ExchangeTransferValidStatus.Valid);
        return true;
    }

    return {
        exchangeTransferState,
        amount,
        setAmount,
        transferIn,
        transferOut,
        exchangeTransferValidStatus,
        setExchangeTransferValidStatus,
        setExchangeTransferState,
        transferValidation,
        isTransferring,
    };
}
