import {
    EnumEventDateFilterType,
    EnumOddsCategoryType,
    EnumPresetFilterType,
} from '@/core/oddsApi/oddsApiType';
import { EuroDate, EventDateType, MarketPage } from '@/interface/enum';

function getEarlyMarketEventDateFilter(eventDate: EventDateType): EnumEventDateFilterType {
    return getEventDateFilter(eventDate, EnumEventDateFilterType.EarlyMarket);
}

function getEventDateFilterWithDefaultAll(eventDate: EventDateType): EnumEventDateFilterType {
    return getEventDateFilter(eventDate, EnumEventDateFilterType.All);
}

function getEventDateFilter(eventDate: EventDateType, defaultAndAll: EnumEventDateFilterType): EnumEventDateFilterType {
    switch (eventDate) {
        case EventDateType.Today: return EnumEventDateFilterType.Today;
        case EventDateType.Plus1: return EnumEventDateFilterType.Plus1;
        case EventDateType.Plus2: return EnumEventDateFilterType.Plus2;
        case EventDateType.Plus3: return EnumEventDateFilterType.Plus3;
        case EventDateType.Plus4: return EnumEventDateFilterType.Plus4;
        case EventDateType.Plus5: return EnumEventDateFilterType.Plus5;
        case EventDateType.Plus6Over: return EnumEventDateFilterType.Plus6Over;
        case EventDateType.All:
        default:
            return defaultAndAll;
    }
}

type PresetFilterInput = { presetFilter: EnumPresetFilterType, date: EnumEventDateFilterType }

export function getEventPresetFilterInputType(
    marketPage: MarketPage,
    eventDate: EventDateType = EventDateType.All,
): PresetFilterInput {
    const mapping: Partial<Record<MarketPage, PresetFilterInput>> = {
        [MarketPage.Live]: { presetFilter: EnumPresetFilterType.Live, date: EnumEventDateFilterType.All },
        [MarketPage.Today]: { presetFilter: EnumPresetFilterType.All, date: EnumEventDateFilterType.Today },
        [MarketPage.All]: { presetFilter: EnumPresetFilterType.All, date: EnumEventDateFilterType.All },
        [MarketPage.EarlyMarket]: { presetFilter: EnumPresetFilterType.NonLive, date: getEarlyMarketEventDateFilter(eventDate) },
        [MarketPage.MixParlay]: { presetFilter: EnumPresetFilterType.MixParlay, date: getEventDateFilterWithDefaultAll(eventDate) },
        [MarketPage.Outright]: { presetFilter: EnumPresetFilterType.Outright, date: EnumEventDateFilterType.All },
        [MarketPage.MyFavorites]: { presetFilter: EnumPresetFilterType.All, date: getEventDateFilterWithDefaultAll(eventDate) },
    };

    const result = mapping[marketPage];
    if (!result) throw Error(`MarketPage Event PresetFilter not found: ${MarketPage[marketPage]}`);

    return result;
}

export function getOddsPresetFilterInputType(isLive: boolean): EnumPresetFilterType.Live | EnumPresetFilterType.NonLive {
    return isLive
        ? EnumPresetFilterType.Live
        : EnumPresetFilterType.NonLive;
}

export function getOddsCategory(marketPage: MarketPage): EnumOddsCategoryType {
    if (marketPage === MarketPage.MixParlay) return EnumOddsCategoryType.MixParlay;
    return EnumOddsCategoryType.All;
}

export function getMarketPageFromPresetAndDate(presetFilter: EnumPresetFilterType, date: EnumEventDateFilterType): { marketPage: MarketPage, date: EventDateType } | null {
    switch (presetFilter) {
        case EnumPresetFilterType.Live: {
            if (date === EnumEventDateFilterType.All) {
                return { marketPage: MarketPage.Live, date: EventDateType.All };
            }
            return null;
        }
        case EnumPresetFilterType.NonLive:
            switch (date) {
                case EnumEventDateFilterType.Today:
                    return { marketPage: MarketPage.Today, date: EventDateType.All };
                case EnumEventDateFilterType.EarlyMarket:
                    return { marketPage: MarketPage.EarlyMarket, date: EventDateType.All };
                case EnumEventDateFilterType.Plus1:
                    return { marketPage: MarketPage.EarlyMarket, date: EventDateType.Plus1 };
                case EnumEventDateFilterType.Plus2:
                    return { marketPage: MarketPage.EarlyMarket, date: EventDateType.Plus2 };
                case EnumEventDateFilterType.Plus3:
                    return { marketPage: MarketPage.EarlyMarket, date: EventDateType.Plus3 };
                case EnumEventDateFilterType.Plus4:
                    return { marketPage: MarketPage.EarlyMarket, date: EventDateType.Plus4 };
                case EnumEventDateFilterType.Plus5:
                    return { marketPage: MarketPage.EarlyMarket, date: EventDateType.Plus5 };
                case EnumEventDateFilterType.Plus6Over:
                    return { marketPage: MarketPage.EarlyMarket, date: EventDateType.Plus6Over };

                default: return null;
            }
        case EnumPresetFilterType.MixParlay:
            switch (date) {
                case EnumEventDateFilterType.All:
                    return { marketPage: MarketPage.MixParlay, date: EventDateType.All };
                case EnumEventDateFilterType.Today:
                    return { marketPage: MarketPage.MixParlay, date: EventDateType.Today };
                case EnumEventDateFilterType.Plus1:
                    return { marketPage: MarketPage.MixParlay, date: EventDateType.Plus1 };
                case EnumEventDateFilterType.Plus2:
                    return { marketPage: MarketPage.MixParlay, date: EventDateType.Plus2 };
                case EnumEventDateFilterType.Plus3:
                    return { marketPage: MarketPage.MixParlay, date: EventDateType.Plus3 };
                case EnumEventDateFilterType.Plus4:
                    return { marketPage: MarketPage.MixParlay, date: EventDateType.Plus4 };
                case EnumEventDateFilterType.Plus5:
                    return { marketPage: MarketPage.MixParlay, date: EventDateType.Plus5 };
                case EnumEventDateFilterType.Plus6Over:
                    return { marketPage: MarketPage.MixParlay, date: EventDateType.Plus6Over };

                default: return null;
            }
        case EnumPresetFilterType.Outright: {
            if (date === EnumEventDateFilterType.All) {
                return { marketPage: MarketPage.Outright, date: EventDateType.All };
            }
            return null;
        }
        case EnumPresetFilterType.All:
            switch (date) {
                case EnumEventDateFilterType.All:
                    return { marketPage: MarketPage.All, date: EventDateType.All };
                case EnumEventDateFilterType.Today:
                    return { marketPage: MarketPage.Today, date: EventDateType.Today };
                default: return null;
            }

        default: return null;
    }
}

export function getFilterFromEuroDate(date: EuroDate, presetFilter: EnumPresetFilterType | undefined = undefined): PresetFilterInput {
    switch (date) {
        case EuroDate.Today: return { presetFilter: presetFilter ?? EnumPresetFilterType.All, date: EnumEventDateFilterType.TodayExpanded };
        case EuroDate.Tomorrow: return { presetFilter: presetFilter ?? EnumPresetFilterType.NonLive, date: EnumEventDateFilterType.Plus1 };
        case EuroDate.Future: return { presetFilter: presetFilter ?? EnumPresetFilterType.NonLive, date: EnumEventDateFilterType.Plus2Over };

        default: return getFilterFromEuroDate(EuroDate.Today);
    }
}
