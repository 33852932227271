<template>
    <div class="videoContainer gLive">
        <div v-if="errorMsg" class="videoContainer_message">
            {{ errorMsg }}
        </div>
        <iframe
            v-else
            title="GLive"
            v-bind:src="iframeUrl"
            allow="fullscreen"
            height="100%"
            width="100%"
        />
    </div>
</template>

<script lang="ts" setup>
    import type { PropType } from 'vue';
    import { ref } from 'vue';
    import { Api } from '@/core/lib/api';
    import type { IGLive } from '@/interface/IEvent';
    import { LiveStreamResource } from '@/interface/pollux';

    const props = defineProps({
        gLive: {
            type: Object as PropType<IGLive>,
            required: true,
        },
    });
    const iframeUrl = ref('');
    const errorMsg = ref('');

    Api.getLiveStream(props.gLive.eventId, 0, LiveStreamResource.GLive)
        .then(({ data }) => {
            const { errorCode, errorMessage, url } = data;
            if (errorCode === 0 && url) {
                iframeUrl.value = `${url}&compact=1`;
            } else if (errorMessage) {
                errorMsg.value = errorMessage;
            }
        })
        .catch((err) => {
            errorMsg.value = err.toString();
        });

</script>

<style lang="scss" scoped>
    @import "@/components/liveStream/gLiveStream/GLiveStream";
</style>
