import type { Directive, DirectiveBinding } from 'vue';

function bindAttribute(el: HTMLElement, binding: DirectiveBinding<string>) {
    el.dataset[binding.arg ?? 'debug'] = binding.value;
}

/**
 * This directive will be removed in production builds. See `vite.config.ts`'s `nodeTransforms` for more details.
 */
export const DebugDirective: Directive<HTMLElement, string> = {
    created(el, binding) {
        bindAttribute(el, binding);
    },
    updated(el, binding) {
        bindAttribute(el, binding);
    },
};
