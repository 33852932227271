import type { Ref, WritableComputedRef } from 'vue';
import { ref, watch } from 'vue';
import { createI18n } from 'vue-i18n';
import en from '@/assets/i18n/en.json';
import { useCookie } from '@/composable/useCookie';
import { getHost } from '@/core/lib/url';
import { EnumLanguage, SiteStyle } from '@/interface/enum';

type LanguageFile = {
    name: EnumLanguage;
    file: string;
    title: string;
}

export const languageFileMap: Array<LanguageFile> = [
    { name: EnumLanguage.EN, file: 'en', title: 'English' },
    { name: EnumLanguage.ZH_CN, file: 'zh-cn', title: '简体中文' },
    { name: EnumLanguage.ID_ID, file: 'id-id', title: 'Indonesia' },
    { name: EnumLanguage.VI_VN, file: 'vi-vn', title: 'Tiếng Việt' },
    { name: EnumLanguage.TH_TH, file: 'th-th', title: 'ภาษาไทย' },
    { name: EnumLanguage.JA_JP, file: 'ja-jp', title: '日本語' },
    { name: EnumLanguage.KO_KR, file: 'ko-kr', title: '한국어' },
    { name: EnumLanguage.MY_MM, file: 'my-mm', title: 'Burmese' },
    { name: EnumLanguage.PT_BR, file: 'pt-br', title: 'Português' },
    { name: EnumLanguage.KM_KH, file: 'km-kh', title: 'Khmer' },
    { name: EnumLanguage.BN_BD, file: 'bn-bd', title: 'Bengali' },
];

const defaultLanguageFile = languageFileMap[0];

export const i18nInstance = createI18n<false>({
    legacy: false,
    locale: defaultLanguageFile.name,
    fallbackLocale: defaultLanguageFile.name,
    messages: {
        [EnumLanguage.EN]: en,
    },
    silentTranslationWarn: __ENV_PROD__,
    warnHtmlMessage: false,
});
type Modify<T, R> = Omit<T, keyof R> & R;
// eslint-disable-next-line @typescript-eslint/ban-types
type NamedValue<T = {}> = T & Record<string, unknown>;

export const i18n = i18nInstance.global as Modify<typeof i18nInstance.global, {
    t(key: TranslateKeys): string;
    t(key: TranslateKeys, fallback: string): string;
    t(key: TranslateKeys, named: NamedValue): string;
    t(key: TranslateKeys, named: NamedValue, plural: number): string;
    t(key: TranslateKeys, list: unknown[]): string;

    locale: WritableComputedRef<EnumLanguage>;
    platformLanguageCookie: Ref<EnumLanguage>;
}>;

const siteStyle = ref<SiteStyle>(SiteStyle.Unknown);

export const setI18nSiteStyle = (style: SiteStyle) => {
    siteStyle.value = style;
};

const originalT = i18n.t;
const translate = (lang: EnumLanguage, key: TranslateKeys, ...args: any[]) => {
    const messages = i18n.messages.value as Record<EnumLanguage, Record<TranslateKeys, TranslateValue>>;
    const value = messages[lang][key];
    const _siteStyle = siteStyle.value.toLowerCase();
    if (typeof value === 'object') {
        if (_siteStyle in value) {
            // @ts-expect-error to decorate i18n.t()
            return originalT(`${key}.${_siteStyle}`, ...args);
        }

        return null;
    }
    // @ts-expect-error to decorate i18n.t()
    return originalT(key, ...args);
};

i18n.t = (key: TranslateKeys, ...args: any[]) => translate(i18n.locale.value, key, ...args) ?? translate(EnumLanguage.EN, key, ...args) ?? '';

const [platformLanguageCookie, setPlatformLanguageCookie] = useCookie('lang', defaultLanguageFile.name, {
    domain: `.${getHost()}`,
    encode: lang => EnumLanguage.toPlatform(lang),
    decode: input => EnumLanguage.fromPlatform(input),
});

i18n.platformLanguageCookie = platformLanguageCookie;

const [, setPolluxLanguageCookie] = useCookie('LanguageType', defaultLanguageFile.name, {
    domain: `.${getHost()}`,
    encode: lang => EnumLanguage.toPollux(lang),
    decode: input => EnumLanguage.fromPollux(input),
});

watch(platformLanguageCookie, (newLang) => {
    setPolluxLanguageCookie(newLang);
    setI18nLanguage(newLang);
}, { immediate: true });

export async function setI18nLanguage(language: EnumLanguage) {
    if (i18n.locale.value === language) {
        return;
    }

    if (platformLanguageCookie.value !== language) {
        setPlatformLanguageCookie(language);
    }

    const languageToLoad = languageFileMap.find(lang => lang.name === language);
    if (languageToLoad) {
        await importLanguage(languageToLoad);
        applyI18nLanguage(languageToLoad.name);
        setPolluxLanguageCookie(language);
    }
}

async function importLanguage(language: LanguageFile) {
    const { default: messages } = await import(`../../assets/i18n/${language.file}.json`);
    i18n.setLocaleMessage(language.name, messages);
}

function applyI18nLanguage(lang: EnumLanguage) {
    i18n.locale.value = lang;
    const langTag = lang.split('_')[0].toLowerCase();

    document.documentElement.setAttribute('lang', langTag);
}

export function getLanguageTag(locale?: EnumLanguage) {
    const lang = locale ?? i18n.locale.value;
    return lang.replace('_', '-').toLowerCase();
}

export function getLanguageFile(locale: EnumLanguage) {
    return languageFileMap.find(lang => lang.name === locale) ?? defaultLanguageFile;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type TranslateKeys = keyof typeof en | (string & {});

export type TranslateValue = string | {
    asia: string;
    euro: string;
};

export type TranslateMessageType = {
    [key in TranslateKeys]: TranslateValue;
};
