<template>
    <VTableRow>
        <VTableHeadline v-bind:width="getColumnWidth(ResultsBlockType.Time, sportType)" />
        <VTableHeadline
            v-bind:colspan="columnCount - 1"
            align="left"
        >
            {{ leagueName }}
        </VTableHeadline>
    </VTableRow>
    <MatchResult
        v-for="(result, index) in results"
        v-bind:key="result.matchId"
        v-bind:index="index"
        v-bind:result="result"
    />
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import type { PropType } from 'vue';
    import VTableHeadline from '@/components/common/table/VTableHeadline.vue';
    import VTableRow from '@/components/common/table/VTableRow.vue';
    import MatchResult from '@/components/results/MatchResult.vue';
    import { getColumnWidth } from '@/components/results/resultColumnHelper';
    import type { IResult } from '@/interface/IResult';
    import { ResultsBlockType } from '@/interface/enum';
    import { useResultsStore } from '@/store/resultsStore';

    defineProps({
        results: {
            type: Array as PropType<IResult[]>,
            required: true,
        },
        leagueName: {
            type: String,
            required: true,
        },
        columnCount: {
            type: Number,
            required: true,
        },
    });

    const { sportType } = storeToRefs(useResultsStore());
</script>
