<template>
    <div class="errorPage">
        <div class="errorPage_content">
            <img v-bind:src="notAvailableImage" alt="notAvailable">
            <strong>{{ errorMessage.title }}</strong>
            <span>{{ errorMessage.message }}</span>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { toRefs } from 'vue';
    import type { IErrorMessage } from '@/interface/IErrorMessage';

    const props = defineProps<{
        errorMessage: IErrorMessage;
    }>();

    const { errorMessage } = toRefs(props);
    const notAvailableImage = new URL('../../assets/images/notAvailable.png', import.meta.url).href;
</script>

<style lang="scss" scoped>
    @import "@/components/common/ErrorPage";
</style>
