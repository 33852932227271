import throttle from 'lodash/throttle';

type IdleStatus = 'idle' | 'active';
const eventList = ['mousemove', 'keydown', 'scroll', 'touchstart', 'touchmove', 'click'];

export class IdleManager {
    public status: IdleStatus = 'active';

    private intervalId: number;
    private lastActive = Date.now();
    private idleCallbacks: Set<() => void> = new Set();
    private activeCallbacks: Set<() => void> = new Set();
    private destroyCallbacks: Set<() => void> = new Set();

    constructor(private timeout: number) {
        const _onEvent = throttle(() => {
            this.lastActive = Date.now();
        }, 1000);

        eventList.forEach(event => window.addEventListener(event, _onEvent, { passive: true }));
        this.intervalId = window.setInterval(() => this.onTick(), 1000);

        this.onDestroy(() => {
            eventList.forEach(event => window.removeEventListener(event, _onEvent));
            window.clearInterval(this.intervalId);
        });
    }

    onTick() {
        const idledTime = Date.now() - this.lastActive;
        const isIdle = idledTime > this.timeout;

        if (isIdle && this.status === 'active') this.idle();
        if (!isIdle && this.status === 'idle') this.active();
    }

    onActive(callback: () => void) {
        this.activeCallbacks.add(callback);
    }

    onIdle(callback: () => void) {
        this.idleCallbacks.add(callback);
    }

    onDestroy(callback: () => void) {
        this.destroyCallbacks.add(callback);
    }

    active() {
        this.status = 'active';
        this.activeCallbacks.forEach(callback => callback());
    }

    idle() {
        this.status = 'idle';
        this.idleCallbacks.forEach(callback => callback());
    }

    destroy() {
        window.clearInterval(this.intervalId);

        this.idleCallbacks.clear();
        this.activeCallbacks.clear();

        this.destroyCallbacks.forEach(callback => callback());
        this.destroyCallbacks.clear();
    }
}
