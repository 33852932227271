import { get } from '@vueuse/core';
import { isEqual } from 'lodash';
import { computed, ref } from 'vue';
import type { MaybeRef } from 'vue';
import { deepCompareComputed } from '@/composable/deepCompareComputed';
import type { BetBuilderMatchFragment, Maybe } from '@/core/betBuilder/betBuilderType';
import { useBetBuilderMatchQuery, useBetBuilderMatchSubscription } from '@/core/betBuilder/betBuilderType';
import { deepFreeze } from '@/core/lib/utils';
import { formatBetBuilderMatch } from '@/core/oddsApi/helpers/formatBetBuilder';
import type { IBetBuilderMatch } from '@/interface/betBuilder';

export function useBetBuilderMatch(matchId: MaybeRef<number>, isEnabled = ref(true)) {
    const betBuilderMatch = ref<IBetBuilderMatch | null>();

    const variables = computed(() => ({
        matchId: get(matchId),
    }));

    const { onResult, loaded, loading, refetch } = useBetBuilderMatchQuery(
        variables,
        deepCompareComputed(() => ({
            enabled: isEnabled.value,
        })),
    );

    const { onSubscription } = useBetBuilderMatchSubscription(
        variables,
        deepCompareComputed(() => ({
            enabled: isEnabled.value,
        })),
    );

    const onUpdate = (result?: Maybe<BetBuilderMatchFragment>): void => {
        if (!result) {
            betBuilderMatch.value = null;
            return;
        }

        const newMatch = deepFreeze(formatBetBuilderMatch(result));
        if (!isEqual(betBuilderMatch.value, newMatch)) {
            betBuilderMatch.value = newMatch;
        }
    };
    onResult(onUpdate);
    onSubscription(onUpdate);

    return {
        betBuilderMatch,
        refetch,
        loaded,
        loading,
    };
}
