<template>
    <div class="block">
        <div class="header">
            <SvgIcon name="icon-system-stopwatch" size="xl" /><strong>{{ i18n.t('live') }}</strong>
        </div>
        <SportsNavBar
            showCount
            showSportLiveCount
            v-bind:gtmLabelPrefix="'InPlay'"
        />
        <OddsDisplayBlock
            class="noRadius"
            v-bind:groups="groups"
            v-bind:loading="!loaded"
            v-bind:groupViewAll="groupViewAll"
        />
        <div class="footer">
            <strong
                v-gtm:@click
                v-gtm:label="'InPlay_ViewAll_' + sportType"
                v-gtm:type="'MatchCard'"
                class="detail"
                v-on:click="goToInPlay(sportType)"
            >
                {{ i18n.t('market_page_view_all_in_play', { sport: i18n.t(`sport_type_name_${sportType}`) }) }}
                <span v-if="events.length" class="number">{{ events.length }}</span>
                <SvgIcon name="icon-system-arrow" size="xs" />
            </strong>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import orderBy from 'lodash/orderBy';
    import { computed, ref } from 'vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import { EuroDisplayContext } from '@/components/euroDisplay/EuroDisplayContext';
    import OddsDisplayBlock from '@/components/euroDisplay/oddsDisplay/OddsDisplayBlock.vue';
    import SportsNavBar from '@/components/navbarBlock/euro/SportsNavBar.vue';
    import { useEuroRouter } from '@/composable/useEuroRouter';
    import { useSportsOrder } from '@/composable/useSportsOrder';
    import { groupByWithOrder } from '@/core/lib/array';
    import { i18n } from '@/core/lib/i18n';
    import { useEvents } from '@/core/oddsApi/composable/useEvents.euro';
    import { EnumEventDateFilterType, EnumOddsCategoryType, EnumPresetFilterType } from '@/core/oddsApi/oddsApiType';
    import type { IEventEuro } from '@/interface/IEvent';
    import { EuroDate } from '@/interface/enum';

    const { defaultSportType } = useSportsOrder();
    const { sportType } = EuroDisplayContext.provide(
        ref(defaultSportType.value),
        ref(false),
        ref(EuroDate.Unknown),
    );

    const { goToInPlay, getLeagueRoute } = useEuroRouter();
    const groupViewAll = (events: IEventEuro[]) => getLeagueRoute(sportType.value, events[0].league);

    const { events, loaded } = useEvents({
        sportType,
        presetFilter: { presetFilter: EnumPresetFilterType.Live, date: EnumEventDateFilterType.All },
        oddsCategory: EnumOddsCategoryType.All,
    });

    const groups = computed(() => {
        const sortedEvents = orderBy(events.value, e => e.eventCode);
        const groups = groupByWithOrder(sortedEvents, e => e.league.id);
        return groups
            .slice(0, 3)
            .map((group) => {
                const events = orderBy(group, [e => e.isLive], ['desc']);
                return {
                    id: events[0].id,
                    events,
                    title: events[0].league.name,
                    info: events[0].league.info ?? undefined,
                };
            });
    });
</script>

<style lang="scss" scoped>
@import "@/components/euroDisplay/home/HomeBlock";
</style>
