<template>
    <PopupModal
        v-if="isShowPopup"
        v-bind:title="i18n.t('exchange_wallet')"
        v-bind:gtmPage="isAsiaSite ? 'K_Exchange' : 'G_Exchange'"
        gtmType="WalletPage"
        v-on:close="closePopup"
    >
        <template #header>
            <SboIcon name="system:transfer" size="20" />
        </template>
        <template #content>
            <div class="balance">
                <span>{{ i18n.t('exchange_transfer_balance') }}</span>
                <span>{{ sboCurrency }}</span>
                <span>{{ toSboCurrencyFormat(balance, sboCurrency) }}</span>
                <VButton
                    v-gtm:label="'Refresh'"
                    v-gtm:@click
                    padding="0"
                    v-on:click="fetchCustomerInfo"
                >
                    <SvgIcon
                        name="icon-system-refresh"
                        size="lg"
                        v-bind:rotating="isCustomerInfoLoading"
                    />
                </VButton>
            </div>
            <SectionTabs v-bind:tabs="exchangeTabs" v-on:changeTab="handleTabChange" />
            <div class="sectionContent">
                <div v-if="exchangeTransferState === ExchangeTransferState.BeforeTransfer">
                    <div class="titleField">
                        {{ sectionTitle }}
                    </div>
                    <div class="titleField titleField-deco">
                        {{ i18n.t('transfer_amount') }}
                    </div>
                    <AmountInput
                        v-bind:modelValue="amount"
                        v-bind:errorMsg="statusMessage"
                        v-bind:precision="2"
                        v-on:update:modelValue="updateAmount"
                        v-on:resetInput="resetInput"
                    />
                    <div class="row">
                        <I18nT tag="span" keypath="exchange_transfer_view_history">
                            <template #statement>
                                <button
                                    v-gtm:label="'Statement'"
                                    v-gtm:@click
                                    type="button"
                                    class="textLink textLink-underlined"
                                    v-on:click="popupStatement"
                                >
                                    {{ i18n.t("statement") }}
                                </button>
                            </template>
                        </I18nT>
                    </div>
                </div>

                <div v-else class="infoMsg">
                    <SboIcon
                        v-if="transferStateInfo"
                        v-bind:name="transferStateInfo.icon.iconName"
                        size="60"
                        v-bind:class="['infoMsgIcon', transferStateInfo.icon.iconClass]"
                    />
                    <div class="titleField titleField-lg">
                        {{ transferStateInfo?.title }}
                    </div>
                    <div class="row">
                        <span>
                            {{ transferStateInfo?.subTitle }}
                        </span>
                    </div>
                </div>

                <div class="buttonGroup">
                    <template v-if="transferStateInfo?.mainBtn">
                        <VButton
                            v-gtm:label="transferStateInfo.mainBtn.gtmLabel"
                            v-gtm:@click
                            v-bind:disabled="transferStateInfo.mainBtn.disable"
                            color="primary"
                            textColor="white"
                            hoverTextColor="white"
                            v-on:click="transferStateInfo.mainBtn.btnAction"
                        >
                            {{ transferStateInfo.mainBtn.btnText }}
                        </VButton>
                    </template>
                    <div v-if="transferStateInfo?.subBtn" class="row">
                        <button
                            v-gtm:label="transferStateInfo.subBtn.gtmLabel"
                            v-gtm:@click
                            type="button"
                            class="textLink textLink-underlined"
                            v-on:click="transferStateInfo.subBtn.btnAction"
                        >
                            {{ transferStateInfo.subBtn.btnText }}
                        </button>
                    </div>
                </div>
            </div>
        </template>
    </PopupModal>
</template>

<script lang="ts" setup>
    import type { IconName } from '@sbo/icons-vue';
    import { toSboCurrencyFormat } from '@sports-utils/money';
    import { storeToRefs } from 'pinia';
    import { computed, ref, toRefs } from 'vue';
    import { useTransfer } from './useTransfer';
    import AmountInput from '@/components/common/AmountInput.vue';
    import SboIcon from '@/components/common/SboIcon.vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import VButton from '@/components/common/button/VButton.vue';
    import PopupModal from '@/components/exchange/PopupModal.vue';
    import SectionTabs from '@/components/exchange/SectionTabs.vue';
    import { usePlatform } from '@/composable/usePlatform';
    import { i18n } from '@/core/lib/i18n';
    import type { ITabItem } from '@/interface/ITabItem';
    import { ExchangeAction, ExchangeTransferState, ExchangeTransferValidStatus } from '@/interface/enum';
    import { useCustomerStore } from '@/store/customerStore';
    import { useExchangeCustomerStore } from '@/store/exchangeCustomerStore';

    const props = defineProps<{
        isShowPopup: boolean;
    }>();

    const emit = defineEmits<(e: 'close') => void>();

    const { sboCurrency, isAsiaSite, brandName } = storeToRefs(useCustomerStore());
    const { isShowPopup } = toRefs(props);
    const {
        exchangeTransferState,
        exchangeTransferValidStatus,
        amount,
        setAmount,
        setExchangeTransferValidStatus,
        transferIn,
        transferOut,
        setExchangeTransferState,
        isTransferring,
    } = useTransfer();

    const { isCustomerInfoLoading, customerInfo } = storeToRefs(useExchangeCustomerStore());
    const { fetchCustomerInfo } = useExchangeCustomerStore();
    fetchCustomerInfo();

    const exchangeTabs = computed<ITabItem[]>(() => [
        { title: i18n.t('transfer_in_tab'), value: ExchangeAction.TransferIn, gtmLabel: 'TopUpTab' },
        { title: i18n.t('transfer_out_tab'), value: ExchangeAction.TransferOut, gtmLabel: 'TransferTab' },
    ]);

    const activeTab = ref(ExchangeAction.TransferIn);
    const sectionTitle = computed(() => (
        activeTab.value === ExchangeAction.TransferIn
            ? i18n.t('transfer_in_title')
            : i18n.t('transfer_out_title', { brand: brandName.value })
    ));

    const isDisableContinueBtn = computed(() => {
        if (isTransferring.value) {
            return true;
        }
        if (exchangeTransferValidStatus.value !== ExchangeTransferValidStatus.Valid) {
            return true;
        }
        if (exchangeTransferState.value === ExchangeTransferState.BeforeTransfer) {
            return !amount.value || amount.value === 0;
        }
        return false;
    });

    const statusMessageMapping = computed<Record<ExchangeTransferValidStatus, string>>(() => ({
        [ExchangeTransferValidStatus.Valid]: '',
        [ExchangeTransferValidStatus.AccountProblem]: i18n.t('transfer_input_error_account_closed_or_suspended'),
        [ExchangeTransferValidStatus.ExceedBettingBudget]: i18n.t('transfer_input_exceed_betting_limit'),
        [ExchangeTransferValidStatus.InsufficientInAccount]: i18n.t('transfer_input_error_insufficient_funds_in_account', { brand: brandName.value }),
        [ExchangeTransferValidStatus.InsufficientInWallet]: i18n.t('transfer_input_error_insufficient_funds_in_wallet'),
    }));

    const statusMessage = computed(() => statusMessageMapping.value[exchangeTransferValidStatus.value]);

    const { openLiveChat, openStatement } = usePlatform();

    async function transferAction() {
        activeTab.value === ExchangeAction.TransferIn ? await transferIn() : await transferOut();
    }

    const { formatMoney } = useCustomerStore();

    const formattedAmount = computed(() => formatMoney(Math.floor(amount.value)));

    const transferMessage = computed(() => new Map<ExchangeTransferState, TransferMessage>([
        [ExchangeTransferState.BeforeTransfer, {
            icon: {
                iconName: '' as IconName,
                iconClass: '',
            },
            title: '',
            subTitle: '',
            mainBtn: {
                btnText: i18n.t('continue'),
                btnAction: transferAction,
                gtmLabel: 'Continue',
                disable: isDisableContinueBtn.value,
            },
            subBtn: {
                btnText: i18n.t('transfer_maybe_later'),
                btnAction: closePopup,
                gtmLabel: 'Later',
            },
        }],
        [ExchangeTransferState.TransferInSuccess, {
            icon: {
                iconName: 'system:checkCircle',
                iconClass: '_success',
            },
            title: i18n.t('transfer_in_success_title'),
            subTitle: i18n.t('transfer_in_success_subtitle', { amount: `${sboCurrency.value} ${formattedAmount.value}` }),
            mainBtn: {
                btnText: i18n.t('transfer_btn_start_betting'),
                btnAction: closePopup,
                gtmLabel: 'Start',
            },
            subBtn: {
                btnText: i18n.t('transfer_view_statement'),
                btnAction: popupStatement,
                gtmLabel: 'Statement',
            },
        }],
        [ExchangeTransferState.TransferInThirdPartyError, {
            icon: {
                iconName: 'system:warningCircle',
                iconClass: '_warning',
            },
            title: i18n.t('something_went_wrong'),
            subTitle: i18n.t('transfer_in_third_party_error'),
            mainBtn: {
                btnText: i18n.t('transfer_btn_back_to_exchange'),
                btnAction: closePopup,
            },
            subBtn: null,
        }],
        [ExchangeTransferState.TransferInError, {
            icon: {
                iconName: 'system:warningCircle',
                iconClass: '_warning',
            },
            title: i18n.t('something_went_wrong'),
            subTitle: i18n.t('transfer_in_out_system_error'),
            mainBtn: {
                btnText: i18n.t('transfer_btn_start_live_chat'),
                btnAction: openLiveChat,
                gtmLabel: 'LiveChat',
            },
            subBtn: null,
        }],
        [ExchangeTransferState.TransferOutSuccess, {
            icon: {
                iconName: 'system:checkCircle',
                iconClass: '_success',
            },
            title: i18n.t('exchange_transfer_out_success_title', { brand: brandName.value }),
            subTitle: i18n.t('exchange_transfer_out_success_subtitle', { amount: `${sboCurrency.value} ${formattedAmount.value}`, brand: brandName.value }),
            mainBtn: {
                btnText: i18n.t('transfer_btn_back_to_exchange'),
                btnAction: closePopup,
            },
            subBtn: null,
        }],
        [ExchangeTransferState.TransferOutThirdPartyError, {
            icon: {
                iconName: 'system:warningCircle',
                iconClass: '_warning',
            },
            title: i18n.t('something_went_wrong'),
            subTitle: i18n.t('transfer_out_third_party_error'),
            mainBtn: {
                btnText: i18n.t('transfer_btn_back_to_exchange'),
                btnAction: closePopup,
            },
            subBtn: null,
        }],
        [ExchangeTransferState.TransferOutError, {
            icon: {
                iconName: 'system:warningCircle',
                iconClass: '_warning',
            },
            title: i18n.t('something_went_wrong'),
            subTitle: i18n.t('transfer_in_out_system_error'),
            mainBtn: {
                btnText: i18n.t('transfer_btn_start_live_chat'),
                btnAction: openLiveChat,
                gtmLabel: 'LiveChat',
            },
            subBtn: null,
        }],
    ]));

    const transferStateInfo = computed (() => transferMessage.value.get(exchangeTransferState.value));

    const balance = computed(() => customerInfo.value?.exchangeBalance ?? 0);

    function popupStatement() {
        closePopup();
        openStatement();
    }

    function closePopup() {
        emit('close');
    }

    function handleTabChange(newTab: ExchangeAction) {
        activeTab.value = newTab;
        if (exchangeTransferState.value !== ExchangeTransferState.BeforeTransfer) {
            setExchangeTransferState(ExchangeTransferState.BeforeTransfer);
        }
        resetInput();
    }

    function resetInput() {
        setExchangeTransferValidStatus(ExchangeTransferValidStatus.Valid);
        setAmount(0);
    }

    function updateAmount(amount: number) {
        setExchangeTransferValidStatus(ExchangeTransferValidStatus.Valid);
        setAmount(amount);
    }

    interface Icon {
        iconName: IconName;
        iconClass: string;
    }

    interface TransferMessage {
        icon: Icon;
        title: string;
        subTitle: string;
        mainBtn: ButtonProperty | null;
        subBtn: ButtonProperty | null;
    }

    interface ButtonProperty {
        btnText: string;
        btnAction: () => void | Promise<void>;
        gtmLabel?: string;
        disable?: boolean;
    }

</script>

<style lang="scss" scoped>
    @import "@/components/exchange/Transfer";
</style>
