<template>
    <div class="singleBetType" v-bind:class="[styleWidth, { live: props.event.isLive }]">
        <strong class="singleBetType_title">
            {{ title }}
        </strong>
        <div
            v-for="(row, rowIdx) in selectionRows"
            v-bind:key="rowIdx"
            class="betBuilderBetType_content"
        >
            <template v-for="(selectionGroup, idx) in row">
                <div
                    v-if="selectionGroup"
                    v-bind:key="selectionGroup.selection.id"
                    v-tooltip:top="marketRule.tooltipFormatter(selectionGroup)"
                    class="betBuilderBetType_content_col"
                >
                    <div class="oddsItem">
                        <Selection
                            v-bind:matchId="event.id"
                            v-bind:market="selectionGroup.market"
                            v-bind:selection="selectionGroup.selection"
                            orientation="vertical"
                            align="center"
                            class="oddsPrice"
                        />
                    </div>
                </div>
                <div
                    v-else
                    v-bind:key="`i${idx}`"
                    class="betBuilderBetType_content_col"
                />
            </template>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { computed, toRefs } from 'vue';
    import { getMarketRule } from '../betBuilderMarketRules';
    import Selection from '@/components/odds/Selection.vue';
    import type { IEvent } from '@/interface/IEvent';
    import type { IBetBuilderMarket } from '@/interface/betBuilder';

    const props = defineProps<{
        event: IEvent;
        markets: IBetBuilderMarket[];
    }>();

    const { event } = toRefs(props);

    const market = computed(() => props.markets[0]);
    const marketRule = computed(() => getMarketRule(market.value));

    const title = computed(() => market.value.marketType.name);

    const selectionGroups = computed(() => props.markets.map(market => market.selections.map(selection => ({
        market,
        selection,
    }))));

    const selectionRows = computed(() => marketRule.value.selectionGroupFormatter(selectionGroups.value));

    const styleWidth = computed(() => marketRule.value.widthFormatter(selectionGroups.value));
</script>

<style lang="scss" scoped>
    @import "@/components/oddsDisplay/moreMarket/marketType/SingleBetType";
</style>
