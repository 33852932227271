import { EnumMarketType } from '@/core/oddsApi/oddsApiType';
import type { IToggleResponse } from '@/interface/IToggle';
import { BetTypeGroup } from '@/interface/enum';
import {
    FH_1X2, FH_Handicap, FH_OddEven, FH_OverUnder, FakeMarketGroup, FakeOdds, FakeOddsEventResult, Handicap, MoneyLine, OddEven, OverUnder, _1X2,
} from '@/mock/mock-function';

export const fakeToggle = (): IToggleResponse => ({
    isESportsImInSportEnabled: 'y',
    isGLiveStreamEnabled: 'y',
    gLiveStreamRestrictions: '*:*:*:*:*:*',
    isAvApiLiveStreamEnabled: 'y',
    liveStreamRefreshSeconds: '30',
    excludeSportList: '25',
    defaultOpenMoreMarketInLiveLeagueIds: '3,6',
    defaultOpenMoreMarketInLiveBySportsIds: '12',
    isInAvApiWhiteList: 'y',
    supportFailedBetReasonSports: '4,9,14,17',
    liveScoreRefreshSeconds: '30',
    miniMyBetRefreshSeconds: '120',
    miniMyBetWaitingRefreshSeconds: '5',
    myBetRefreshSeconds: '120',
    myBetWaitingRefreshSeconds: '5',
    multipleSportsTournamentNames: 'SEA Games Vietnam 2021',
    efootballLeagueIds: '65167,66624,76444,96813,25118,122525,135151',
    isCasinoWidgetEnabled: 'n',
    isMiniGameWidgetEnabled: 'y',
    isVirtualFootballEnabled: 'y',
    isVirtualBasketballEnabled: 'y',
    isVoucherEnabled: 'y',
    cashOutValueRefreshSeconds: '5',
    isCashOutEnabled: 'y',
    isMyFavoritesEnabled: 'y',
    liveTicketAutoRefreshSeconds: '3',
    nonLiveTicketAutoRefreshSeconds: '5',
    isSendBehaviorEnabled: 'y',
    behaviorMinutes: '{"0":0.5,"1":0.1}',
    isScoreAlertEnabled: 'y',
    miniMyBetMaxDisplayCount: '15',
    isShowTvChannelsTooltip: 'y',
    liveRefreshDuration: '20',
    nonLiveRefreshDuration: '60',
    oddsApiUrl: '',
    oddsApiWsUrl: 'fakeApiUrl',
    isBetBuilderEnabled: 'n',
    betBuilderOddsApiUrl: '',
    betBuilderOddsWsUrl: 'fakeApiUrl',
    betBuilderMinLegCount: '2',
    betBuilderMinLegSettings: '[]',
    placeBetDelayTime: '0',
    liveScoreProviderConfig: '{"1":[0],"2":[1],"4":[2],"5":[2],"8":[2],"9":[2],"10":[2],"14":[3],"15":[2]}',
    setGameScoreSportList: '5,8,10,15',
    periodSportList: '4',
    inningSportList: '9',
    sportsCupTournamentNames: 'fakeTournamentNames',
    sportsCup2TournamentNames: 'fakeTournamentNames',
    multipleSportsHotLinkExpiredDate: '2024-12-31T23:59:59',
    sportsCupHotLinkExpiredDate: '2024-12-31T23:59:59',
    sportsCup2HotLinkExpiredDate: '2024-12-31T23:59:59',
    isEFootballZoneEnabled: 'y',
    isUseNewPlaceBetApi: 'y',
    mpTwoSubBetLeagueIds: '123,456',
    isTwoMpSubBetEnabled: 'y',
    isJackpotEnabled: 'y',
    multipleSportsZoneSupportSports: '1',
    b2bJackpotCurrency: 'TB',
    b2cJackpotCurrency: 'TB',
    toggleAutoRefreshSeconds: '60',
    isLiveStreamBanner1Enabled: 'y',
    isLiveStreamBanner2Enabled: 'y',
    liveStreamBannerEnableMatchIds: '123,456',
    isBetBuilderTutorialEnabled: 'y',
    isBetBuilderResultEnabled: 'y',
    defaultSportType: '1',
    isFairExchangeEnabled: 'y',
});

export const mockOdds = [
    FakeOdds(
        EnumMarketType.Handicap,
        Handicap(0.92, 0.99),
    ),
    FakeOdds(
        EnumMarketType.OverUnder,
        OverUnder(0.9, 0.87),
    ),
    FakeOdds(
        EnumMarketType.MoneyLine,
        MoneyLine(1, 2),
    ),
    FakeOdds(
        EnumMarketType.FH_Handicap,
        FH_Handicap(0.88, 1),
    ),
    FakeOdds(
        EnumMarketType.FH_OverUnder,
        FH_OverUnder(0.88, 0.98),
    ),
    FakeOdds(
        EnumMarketType.OddEven,
        OddEven(-0.96, 0.86),
    ),
    FakeOdds(
        EnumMarketType.FH_OddEven,
        FH_OddEven(-0.96, 0.86),
    ),
    FakeOdds(
        EnumMarketType.OddEven,
        OddEven(-0.96, 0.86),
    ),
    FakeOdds(
        EnumMarketType.OddEven,
        OddEven(-0.96, 0.86),
        FakeOddsEventResult(FakeMarketGroup(129, '第一节')),
    ),
    FakeOdds(
        EnumMarketType.OddEven,
        OddEven(-0.96, 0.86),
        FakeOddsEventResult(FakeMarketGroup(747, 'Home Team Total Points', {
            homeTemplate: '_{home}_ - _{homeext}_',
            awayTemplate: '_{home}_ - _{awayext}_',
            isYesNo: true,
        })),
    ),
    FakeOdds(
        EnumMarketType.Handicap,
        Handicap(0.99, 0.85),
        FakeOddsEventResult(FakeMarketGroup(126, 'Total Corners')),
    ),
    FakeOdds(
        EnumMarketType.OverUnder,
        OverUnder(0.92, -0.97),
        FakeOddsEventResult(FakeMarketGroup(126, 'Total Corners')),
    ),
    FakeOdds(
        EnumMarketType.OverUnder,
        OverUnder(0.82, -0.92),
        FakeOddsEventResult(FakeMarketGroup(126, 'Total Corners')),
    ),
    FakeOdds(
        EnumMarketType.OddEven,
        OddEven(0.96, 0.9),
        FakeOddsEventResult(FakeMarketGroup(126, 'Total Corners')),
    ),
    FakeOdds(
        EnumMarketType.FH_Handicap,
        FH_Handicap(-0.96, 0.8),
        FakeOddsEventResult(FakeMarketGroup(126, 'Total Corners')),
    ),
    FakeOdds(
        EnumMarketType.FH_OverUnder,
        FH_OverUnder(0.92, 0.9),
        FakeOddsEventResult(FakeMarketGroup(126, 'Total Corners')),
    ),

    FakeOdds(
        EnumMarketType.MoneyLine,
        MoneyLine(2.17, 1.65),
        FakeOddsEventResult(FakeMarketGroup(129, '1st Quarter')),
    ),
    FakeOdds(
        EnumMarketType.Handicap,
        Handicap(0.91, 0.85),
        FakeOddsEventResult(FakeMarketGroup(129, '1st Quarter')),
    ),
    FakeOdds(
        EnumMarketType.OverUnder,
        OverUnder(0.80, 0.96),
        FakeOddsEventResult(FakeMarketGroup(129, '1st Quarter')),
    ),
    FakeOdds(
        EnumMarketType.OddEven,
        OddEven(0.94, 0.94),
        FakeOddsEventResult(FakeMarketGroup(129, '1st Quarter')),
    ),

    FakeOdds(
        EnumMarketType.OverUnder,
        OverUnder(2.17, 1.65),
        FakeOddsEventResult(FakeMarketGroup(130, '2nd Quarter')),
    ),
    FakeOdds(
        EnumMarketType.OverUnder,
        OverUnder(0.91, -0.85),
        FakeOddsEventResult(FakeMarketGroup(130, '2nd Quarter')),
    ),
    FakeOdds(
        EnumMarketType.OverUnder,
        OverUnder(0.80, 0.96),
        FakeOddsEventResult(FakeMarketGroup(130, '2nd Quarter')),
    ),

    FakeOdds(
        EnumMarketType.MoneyLine,
        MoneyLine(2.17, 1.65),
        FakeOddsEventResult(FakeMarketGroup(131, '3rd Quarter')),
    ),
    FakeOdds(
        EnumMarketType.Handicap,
        Handicap(2.17, 1.65),
        FakeOddsEventResult(FakeMarketGroup(131, '3rd Quarter')),
    ),
    FakeOdds(
        EnumMarketType.OverUnder,
        OverUnder(0.91, -0.85),
        FakeOddsEventResult(FakeMarketGroup(131, '3rd Quarter')),
    ),
    FakeOdds(
        EnumMarketType._1X2,
        _1X2(),
        FakeOddsEventResult(FakeMarketGroup(131, '3rd Quarter')),
    ),
    FakeOdds(
        EnumMarketType.OddEven,
        OddEven(0.80, 0.96),
        FakeOddsEventResult(FakeMarketGroup(131, '3rd Quarter')),
    ),
    FakeOdds(
        EnumMarketType.FH_Handicap,
        FH_Handicap(2.17, 1.65),
        FakeOddsEventResult(FakeMarketGroup(131, '3rd Quarter')),
    ),
    FakeOdds(
        EnumMarketType.FH_OverUnder,
        FH_OverUnder(0.91, -0.85),
        FakeOddsEventResult(FakeMarketGroup(131, '3rd Quarter')),
    ),
    FakeOdds(
        EnumMarketType.FH_OddEven,
        FH_OddEven(0.80, 0.96),
        FakeOddsEventResult(FakeMarketGroup(131, '3rd Quarter')),
    ),
    FakeOdds(
        EnumMarketType.FH_1X2,
        FH_1X2(0.80, 0.96, 0.87),
        FakeOddsEventResult(FakeMarketGroup(131, '3rd Quarter')),
    ),
    FakeOdds(
        EnumMarketType.FH_1X2,
        FH_1X2(0.80, 0.96, 0.87),
        FakeOddsEventResult(FakeMarketGroup(BetTypeGroup.Soccer_TotalCorner, 'Total Corner')),
    ),
];

export const mockEvents = [
    {
        id: 2223665,
        eventCode: 'e000117101404',
        eventDate: '2021-12-21T00:00:00',
        hasStatistics: true,
        liveCourtMatchId: 0,
        showTime: '12/21/2021 22:30',
        showTimeType: 1,
        tvChannels: [],
        marketTypeCount: [
            '0:1:1',
            '0:2:1',
            '0:3:1',
            '0:7:1',
            '0:9:1',
            '0:11:1',
            '0:12:1',
            '129:2:1',
            '747:2:1',
        ],
        isLive: true,
        realKickoffTime: '2021-12-21T19:00:12.0030000',
        mainMarketEventResult: {
            liveAwayScore: 0,
            liveHandicapType: 'Unknown',
            nonLiveHandicapType: 'Away',
            liveHomeScore: 1,
            isMixParlay: true,
            extraInfo: null,
        },
        awayTeam: {
            teamName: 'My Basket Ball Base',
        },
        homeTeam: {
            teamName: 'Lemvig Basket(Pls DOnt Use)',
        },
        tournament: {
            id: 13311,
            tournamentName: 'Denmark Basketball League',
            tournamentInfo: '',
            tournamentSize: 'Small',
            sport: {
                sportType: 'Basketball',
            },
        },
        stream: null,
        nonLiveOutrightIds: [],
    },
    {
        id: 2223666,
        eventCode: 'e000117101404',
        eventDate: '2022-03-15T00:00:00',
        hasStatistics: true,
        liveCourtMatchId: 0,
        showTime: '03/15/2022 22:00',
        showTimeType: 8,
        tvChannels: [],
        marketTypeCount: [
            '0:1:1',
            '0:2:1',
            '0:3:1',
            '0:7:1',
            '0:9:1',
            '0:11:1',
            '0:12:1',
            '129:2:1',
            '747:2:1',
        ],
        isLive: false,
        realKickoffTime: '2022-03-16T23:59:12.0030000',
        mainMarketEventResult: {
            liveAwayScore: 0,
            liveHandicapType: 'Unknown',
            nonLiveHandicapType: 'Away',
            liveHomeScore: 0,
            isMixParlay: true,
            extraInfo: null,
        },
        awayTeam: {
            teamName: 'My Basket Ball Base',
        },
        homeTeam: {
            teamName: 'Lemvig Basket(Pls DOnt Use)',
        },
        tournament: {
            id: 13311,
            tournamentName: 'Denmark Basketball League',
            tournamentInfo: '',
            tournamentSize: 'Small',
            sport: {
                sportType: 'Basketball',
            },
        },
        stream: null,
        nonLiveOutrightIds: [],
    },
];
