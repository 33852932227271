<template>
    <div class="ticket notranslate">
        <span
            v-gtm:@click
            v-gtm:label="'CloseTicket'"
            class="close"
            v-on:click="cancelTicket(ticketKey)"
        >
            <SvgIcon name="icon-system-close" />
        </span>
        <div class="topInfo">
            <div class="marketOption topInfoItem">
                <div class="optionDisplay">
                    <span v-if="isDisplayScore" class="optionOddsGroup">
                        {{ `(${liveScoreDisplay})` }}
                    </span>
                    {{ optionDisplay }}
                    <span v-if="isShowPoint">
                        <del v-if="isPointChange" class="previousPoint">
                            {{ oldPointDisplay }}
                        </del>
                        <span
                            v-bind:class="{ change: isPointChange }"
                        >{{ pointDisplay }}</span>
                    </span>
                </div>
                <div
                    class="betSlipOdds"
                    v-bind:class="{
                        rise: isPriceRise,
                        drop: isPriceDrop,
                    }"
                >
                    <span class="price">{{ priceDisplay }}</span>
                    <SvgIcon
                        v-show="isPriceChange"
                        class="arrow"
                        name="icon-system-arrow-down"
                        v-bind:rotated="isPriceRise"
                    />
                </div>
            </div>

            <div class="marketType topInfoItem">
                {{ marketGroupName }}
            </div>
            <div
                v-if="!isOutright"
                class="teamName topInfoItem"
            >
                {{ ticket.event.homeTeam.name }}
                {{ i18n.t('vs') }}
                {{ ticket.event.awayTeam.name }}
            </div>
            <div
                v-if="isOutright"
                class="teamName topInfoItem"
            >
                {{ `${ticket.event.league.name}` }}
            </div>

            <div
                v-if="correctScoreExcludingScores"
                class="detailText"
            >
                {{ i18n.t('odds_option_cs_excluding') }}
                {{ correctScoreExcludingScores }}
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { computed, toRefs, watch } from 'vue';
    import { BetSlipContextEuro } from '@/components/betSlip/betSlipContext';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import { useEventEmitter } from '@/composable/useEventEmitter';
    import { usePriceChangeStatusEuro } from '@/composable/usePriceChangeStatusEuro';
    import { useTicketDisplay } from '@/composable/useTicketDisplay';
    import { getTicketKey } from '@/core/lib/betSlipHelper.euro';
    import { i18n } from '@/core/lib/i18n';
    import { isMainMarket } from '@/core/lib/oddsHelper';
    import type { ITicketEuro } from '@/interface/IBetSlip';
    import { MarketType, PriceChangeStatus } from '@/interface/enum';

    const props = defineProps<{
        ticket: ITicketEuro;
        stakeDisplay: string;
    }>();
    const { ticket, stakeDisplay } = toRefs(props);
    const { cancelTicket, isAutoAcceptChange, subscribeTicket } = BetSlipContextEuro.inject();
    const isOutright = computed(() => ticket.value.odds.marketType === MarketType.OutRight);
    const ticketKey = computed(() => getTicketKey(ticket.value.odds, ticket.value.priceOption));

    const {
        marketGroupName,
        optionDisplay,

        isShowPoint,
        pointDisplay,
        isPointChange,
        oldPointDisplay,

        price,
        priceDisplay,

        liveScoreDisplay, correctScoreExcludingScores,
    } = useTicketDisplay(ticket);
    const isDisplayScore = computed(() => isMainMarket(ticket.value.odds) && liveScoreDisplay.value);
    const hasStake = computed(() => stakeDisplay.value !== '');

    const enablePriceChangeReset = computed(() => isAutoAcceptChange.value || !hasStake.value);
    const { isPriceChange, priceChangeStatus } = usePriceChangeStatusEuro(price, enablePriceChangeReset);
    const isPriceRise = computed(() => isPriceChange.value && priceChangeStatus.value === PriceChangeStatus.Rise);
    const isPriceDrop = computed(() => isPriceChange.value && priceChangeStatus.value === PriceChangeStatus.Drop);

    const eventEmitter = useEventEmitter();
    watch([isPriceChange, hasStake], ([newIsPriceChange], [oldIsPriceChange]) => {
        const isAcceptPriceChange = oldIsPriceChange === true && newIsPriceChange === false;
        if (hasStake.value) {
            if (isPriceChange.value) {
                eventEmitter.emit('ticket:price:change');
            } else if (isAcceptPriceChange) {
                eventEmitter.emit('ticket:accept:price:change');
            }
        }
    });

    eventEmitter.on('ticket:accept:price:change', () => {
        priceChangeStatus.value = PriceChangeStatus.Remain;
    });

    subscribeTicket(ticket);
</script>

<style lang="scss" scoped>
@import "@/components/betSlip/Ticket.Euro";
</style>
