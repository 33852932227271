import '@/publicPath';
import '@/polyfill';
import { setupApp } from '@/core/lib/startup';
import { getCasaApiUrl, getOddsApiUrl, getPolluxUrl } from '@/core/lib/url';
import { fakeToggle } from '@/mock/mock-data';
import Index from '@/views/Index.vue';
// eslint-disable-next-line import/no-unresolved
import 'virtual:svg-icons-register';

if (__MOCK_POLLUX__ || __MOCK_HEADER__ || __MOCK_ODDS_API__) {
    const { createWorker } = await import('./mock/handlers');
    const worker = createWorker();

    if (__MOCK_POLLUX__) {
        const { mockPollux } = await import('./mock/pollux-api');
        mockPollux(worker);
    }
    if (__MOCK_HEADER__) {
        const { mockCasa } = await import('./mock/casa-api');
        mockCasa(worker);
    }
    if (__MOCK_ODDS_API__) {
        const { mockOddsApi } = await import('./mock/odds-api');
        mockOddsApi(worker);
    }

    await worker.start({
        onUnhandledRequest(req) {
            const isPolluxRequestUnhandled = __MOCK_POLLUX__ && req.url.href.startsWith(getPolluxUrl());
            const isCasaApiRequestUnhandled = __MOCK_HEADER__ && req.url.href.startsWith(getCasaApiUrl());

            const oddsApiUrl = fakeToggle().oddsApiUrl;
            const isOddsApiRequestUnhandled = __MOCK_ODDS_API__ && req.url.href.startsWith(getOddsApiUrl(oddsApiUrl));

            if (isPolluxRequestUnhandled || isCasaApiRequestUnhandled || isOddsApiRequestUnhandled) {
                console.warn(`[MSW] Warning: captured a query without a matching request handler\n${req.url.href}`);
            }
        },
    });
}

setupApp(Index, 'sports');
