import { sleep } from '@sports-utils/timer';
import { tryOnScopeDispose, useThrottleFn, useTimeoutFn } from '@vueuse/core';
import type { CancelTokenSource } from 'axios';
import axios from 'axios';
import { xorBy } from 'lodash';
import { storeToRefs } from 'pinia';
import type { Ref } from 'vue';
import { computed, watch } from 'vue';
import type { IAsiaBetSlipContext } from '@/components/betSlip/betSlipContext/BaseBetSlipContext';
import { useBaseBetSlipContext } from '@/components/betSlip/betSlipContext/BaseBetSlipContext';
import type {
    PlaceBetBuilderBetErrorRules,
    PlaceBetErrorHandler,
} from '@/components/betSlip/betSlipContext/usePlaceBetErrorHandler';
import { usePlaceBetErrorHandler } from '@/components/betSlip/betSlipContext/usePlaceBetErrorHandler';
import { useBetBuilderInvalidMessageDisplay } from '@/composable/useBetBuilderInvalidMessageDisplay';
import { usePlatform } from '@/composable/usePlatform';
import { Api } from '@/core/lib/api';
import { i18n } from '@/core/lib/i18n';
import { getMaxPayout, getPlaceBetErrorMessage } from '@/core/lib/ticket';
import { useBetBuilderMatch } from '@/core/oddsApi/composable/useBetBuilderMatch';
import { useEventSubscription } from '@/core/oddsApi/composable/useEventSubscription';
import { EnumOddsCategoryType } from '@/core/oddsApi/oddsApiType';
import type { IBetBuilderBetSlip } from '@/interface/betBuilder';
import { BetBuilderSelectionStatus, BetBuilderTicketStatus, PlaceOrderStatus, PriceStyle, SiteStyle } from '@/interface/enum';
import type { BetBuilderTicketRequest } from '@/interface/pollux';
import { useBetSlipStore } from '@/store/betSlipStore';
import { useCustomerStore } from '@/store/customerStore';
import { useLayoutStore } from '@/store/layoutStore';
import { useToggleStore } from '@/store/toggleStore';

export const createBetBuilderBetSlipContext = (betBuilderBetSlip: Ref<IBetBuilderBetSlip>): IAsiaBetSlipContext => {
    const {
        updateBetBuilderBetSlip,
        removeBetBuilderBetSlip,
        removeBetBuilderTicket,
    } = useBetSlipStore();
    const betSlipContext = useBaseBetSlipContext(betBuilderBetSlip);

    const {
        stakeInput,
        totalOddsPrice,
        setBetSlipMessage,
        setStakeMessage,
        toggleIsPlaceBetInProgress,
        cancelTicketMillisecond,
        isPlaceBetEnabled: _isPlaceBetEnabled,
        emitPlaceBetSuccess,
        autoRefresh,
    } = betSlipContext;
    const { setStake } = stakeInput;

    const tickets = computed(() => betBuilderBetSlip.value.tickets);
    const { isValidSelectionCount } = useBetBuilderInvalidMessageDisplay(tickets);
    const { formatMoneyWithDecimal } = useCustomerStore();
    const maxPayout = computed(() => {
        const payout = getMaxPayout(stakeInput.stake.value, totalOddsPrice.value, PriceStyle.Euro);
        return formatMoneyWithDecimal(payout);
    });

    function cancelTicket(key?: string | undefined) {
        if (!key) return;
        removeBetBuilderTicket(key);
    }

    let cancelToken: CancelTokenSource;

    const betBuilderStatusHandlerMap: Partial<Record<BetBuilderTicketStatus, () => { isContinue: boolean}>> = {
        [BetBuilderTicketStatus.MaxLegCountExceeded]: () => {
            setBetSlipMessage(i18n.t('betSlip_errorMessage_maxLegCountExceeded'));
            return { isContinue: true };
        },
        [BetBuilderTicketStatus.Unavailable]: () => {
            setBetSlipMessage(i18n.t('betSlip_errorMessage_unavailable'));
            return { isContinue: true };
        },
        [BetBuilderTicketStatus.Unknown]: () => {
            setBetSlipMessage(i18n.t('bet_slip_error_message_general_failure'));
            return { isContinue: true };
        },
    };

    const openTicket = async (isAutoOpen: boolean) => {
        try {
            if (cancelToken) cancelToken.cancel('invalid previous openTicket');
            cancelToken = axios.CancelToken.source();

            updateBetBuilderBetSlip((betSlip) => {
                betSlip.updatingStatus = isAutoOpen ? 'auto' : 'manual';
                return betSlip;
            });

            const hash = getTicketsHash(betBuilderBetSlip);

            const ticketRequest: BetBuilderTicketRequest = {
                isLive: tickets.value[0].match.isLive,
                selectionIds: tickets.value.map(ticket => ticket.selection.id),
            };

            const ticketResponse = await Api.openBetBuilderTicket(ticketRequest, cancelToken.token);

            if (hash !== getTicketsHash(betBuilderBetSlip)) return; // tickets not match between request and response

            const handler = betBuilderStatusHandlerMap[ticketResponse.status];
            if (handler && !handler().isContinue) {
                return;
            }

            if (!ticketResponse.selectedSelections || ticketResponse.selectedSelections.length === 0) {
                setBetSlipMessage(i18n.t('betSlip_errorMessage_unavailable'));
                useTimeoutFn(() => removeBetBuilderBetSlip(), cancelTicketMillisecond);
                return;
            }

            const availableResponseSelections = ticketResponse.selectedSelections.filter(ticket => ticket.status === BetBuilderSelectionStatus.Running);
            const availableTickets = betBuilderBetSlip.value.tickets.filter(ticket => availableResponseSelections.some(selection => selection.id === ticket.selection.id));
            if (availableTickets.length === 0) {
                setBetSlipMessage(i18n.t('betSlip_errorMessage_unavailable'));
                useTimeoutFn(() => removeBetBuilderBetSlip(), cancelTicketMillisecond);
                return;
            }

            updateBetBuilderBetSlip((betSlip) => {
                betSlip.price = ticketResponse.price;
                betSlip.uid = ticketResponse.uid ?? '';
                betSlip.minBet = ticketResponse.minBet;
                betSlip.maxBet = ticketResponse.maxBet;
                betSlip.balance = ticketResponse.balance;
                betSlip.markets = ticketResponse.markets;
                betSlip.tickets = availableTickets;
                return betSlip;
            });
        } catch (error) {
            console.error(error);
            if (axios.isCancel(error)) {
                // do nothing
            } else {
                setBetSlipMessage(i18n.t('bet_slip_error_message_general_failure'));
                useTimeoutFn(() => removeBetBuilderBetSlip(), 3000);
            }
        } finally {
            updateBetBuilderBetSlip((betSlip) => {
                betSlip.updatingStatus = 'none';
                return betSlip;
            });
        }
    };
    const openTicketThrottled = useThrottleFn(openTicket, 1000, true, false);

    autoRefresh(() => openTicketThrottled(true));

    const ticketContextChangeHandler: PlaceBetErrorHandler = (_placeOrderStatus: PlaceOrderStatus, _extraInfo: string) => {
        openTicketThrottled(true);
    };

    const invalidEventOrPriceHandler: PlaceBetErrorHandler = (placeOrderStatus: PlaceOrderStatus, _extraInfo: string) => {
        setBetSlipMessage(getPlaceBetErrorMessage(placeOrderStatus));
        useTimeoutFn(() => removeBetBuilderBetSlip(), cancelTicketMillisecond);
    };

    const insufficientSelectionHandler: PlaceBetErrorHandler = (_placeOrderStatus: PlaceOrderStatus, _extraInfo: string, leagueId?: number) => {
        const { getBetBuilderMinSelectionCount } = useToggleStore();
        if (leagueId) {
            setBetSlipMessage(i18n.t('bet_builder_insufficient_selections', { n: getBetBuilderMinSelectionCount(leagueId) }));
        } else {
            setBetSlipMessage(i18n.t('bet_builder_insufficient_selections'));
        }
    };

    const { placeBetErrorRuleMap, defaultErrorHandler, errorMessageHandler } = usePlaceBetErrorHandler(betSlipContext);

    const placeBetBuilderErrorRuleMap: Partial<Record<PlaceBetBuilderBetErrorRules, PlaceBetErrorHandler>> = {
        ...placeBetErrorRuleMap,
        [PlaceOrderStatus.RetryOpenTicket]: ticketContextChangeHandler,
        [PlaceOrderStatus.PriceChange]: ticketContextChangeHandler,
        [PlaceOrderStatus.ScoreChanged]: ticketContextChangeHandler,
        [PlaceOrderStatus.PriceSuspend]: invalidEventOrPriceHandler,
        [PlaceOrderStatus.InvalidPrice]: invalidEventOrPriceHandler,
        [PlaceOrderStatus.EventClosed]: invalidEventOrPriceHandler,
        [PlaceOrderStatus.InvalidRefNo]: errorMessageHandler,
        [PlaceOrderStatus.BetBuilderMaxPayoutExceed]: errorMessageHandler,
        [PlaceOrderStatus.BetBuilderMaxAccumulatedStakeExceed]: errorMessageHandler,
        [PlaceOrderStatus.LessSelection]: insufficientSelectionHandler,
        [PlaceOrderStatus.ExistBetWithSameRefNo]: errorMessageHandler,
    };

    const { refreshBalanceAfterAction } = usePlatform();

    const { singleLastStake, isShowLastStake } = storeToRefs(useBetSlipStore());
    const { setSingleLastStake } = useBetSlipStore();
    const { placeBetDelayTime } = storeToRefs(useToggleStore(SiteStyle.Asia));

    if (isShowLastStake.value) {
        setStake(singleLastStake.value);
    }

    const placeBet = async () => {
        toggleIsPlaceBetInProgress(true);
        try {
            const [response] = await Promise.all([
                Api.placeBetBuilder(betBuilderBetSlip.value, stakeInput.stake.value),
                sleep(placeBetDelayTime.value * 1000),
            ]);

            if (response.isPlaceBetSuccess) {
                const { toggleBetSlipExpanded } = useLayoutStore();
                toggleBetSlipExpanded(false);
                removeBetBuilderBetSlip();
                emitPlaceBetSuccess();
                if (isShowLastStake.value) {
                    setSingleLastStake(stakeInput.stake.value);
                }
                refreshBalanceAfterAction();
                return;
            }
            const handler = placeBetBuilderErrorRuleMap[response.placeOrderStatus as PlaceBetBuilderBetErrorRules] ?? defaultErrorHandler;
            handler(response.placeOrderStatus, '', betBuilderBetSlip.value.tickets[0].match.league.id);
        } catch (error) {
            console.error(error);
            errorMessageHandler(PlaceOrderStatus.GeneralFailure, '');
        } finally {
            toggleIsPlaceBetInProgress(false);
        }
    };

    const isPlaceBetEnabled = computed(() => _isPlaceBetEnabled.value && isValidSelectionCount.value);

    const matchId = betBuilderBetSlip.value.tickets[0].match.id;

    const { betBuilderMatch } = useBetBuilderMatch(matchId);
    watch(betBuilderMatch, (match) => {
        if (!match) {
            Api.clearTicket();
            setBetSlipMessage(i18n.t('betSlip_errorMessage_unavailable'));
            useTimeoutFn(() => removeBetBuilderBetSlip(), cancelTicketMillisecond);
            return;
        }

        const ticketSelections = betBuilderBetSlip.value.tickets.map(ticket => ticket.selection);
        const marketSelections = match?.markets.flatMap(market => market.selections);

        const isTicketSelectionChanged = ticketSelections.some((selection) => {
            const subscribedSelection = marketSelections.find(x => x.id === selection.id);
            const selectionClosed = !subscribedSelection;
            const statusChanged = subscribedSelection && subscribedSelection.status !== selection.status;
            return selectionClosed || statusChanged;
        });

        if (isTicketSelectionChanged) {
            openTicketThrottled(true);
        }
    });

    const { onSubscription } = useEventSubscription(matchId, EnumOddsCategoryType.All);
    onSubscription((event) => {
        if (!event) {
            Api.clearTicket();
            setBetSlipMessage(i18n.t('betSlip_errorMessage_unavailable'));
            useTimeoutFn(() => removeBetBuilderBetSlip(), cancelTicketMillisecond);
        }
    });

    watch(betBuilderBetSlip, (newValue, oldValue) => {
        if (!newValue || newValue?.tickets.length === 0) {
            Api.clearTicket();
        } else {
            const ticketDifferences = xorBy(newValue.tickets, oldValue?.tickets ?? [], x => x.key);
            if (ticketDifferences.length) {
                setStakeMessage('');
                updateBetBuilderBetSlip((betSlip) => {
                    betSlip.uid = '';
                    betSlip.minBet = 0;
                    betSlip.maxBet = 0;

                    return betSlip;
                });

                openTicketThrottled(false);
            }
        }
    }, { immediate: true, deep: true });

    tryOnScopeDispose(() => {
        Api.clearTicket();
    });

    const isVoucherAvailable = computed (() => false);
    const isVoucherValidForTicket = computed(() => false);

    return {
        ...betSlipContext,
        maxPayout,
        openTicket,
        cancelTicket,
        placeBet,
        subscribeOdds: () => {},
        updateWhenLangChanged: () => {},
        isPlaceBetEnabled,
        isVoucherAvailable,
        isVoucherValidForTicket,
    };
};

function getTicketsHash(betSlip: Ref<IBetBuilderBetSlip>) {
    return betSlip.value.tickets.map(x => x.key).join();
}
