<template>
    <div class="videoContainer twitch">
        <iframe
            v-if="isHttps"
            title="Twitch"
            v-bind:src="url"
            allowfullscreen="true"
            height="100%"
            width="100%"
        />
        <div v-else class="videoMessage twitch">
            <p class="videoMessage_title">
                {{ i18n.t('looking_for_live_streaming') }}
            </p>
            <VButton
                margin
                outline
                color="primary"
                hoverOutlineColor="primary"
                v-on:click="openTwitch"
            >
                {{ i18n.t('watch_here') }}
            </VButton>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { computed } from 'vue';
    import VButton from '@/components/common/button/VButton.vue';
    import { i18n } from '@/core/lib/i18n';

    const props = defineProps({
        videoUrl: {
            type: String,
            required: true,
        },
    });

    const parentUrl = window.location.hostname;
    const isHttps = window.location.protocol === 'https:';
    const url = computed(() => `${props.videoUrl}&parent=${parentUrl}&autoplay=true`);

    const twitchWindow = {
        name: 'TwitchStream',
        config: 'scrollbars=no,resizable=no,width=830,height=352',
    };

    const openTwitch = () => {
        window.open(url.value, twitchWindow.name, twitchWindow.config);
    };
</script>

<style lang="scss" scoped>
    @import "@/components/liveStream/twitchLiveStream/TwitchLiveStream";
</style>
