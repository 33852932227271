import type { Ref } from 'vue';
import { computed, nextTick, ref } from 'vue';
import { useState } from '@/composable/useState';
import { assignArray } from '@/core/lib/array';
import { i18n } from '@/core/lib/i18n';
import { deepFreeze } from '@/core/lib/utils';
import { formatOutrightOdds } from '@/core/oddsApi/helpers/formatOutrightOdds';
import { useOutrightsQuery } from '@/core/oddsApi/oddsApiType';
import type { IOutrightOdds } from '@/interface/IOutrightOdds';
import { EnumLanguage, SportType } from '@/interface/enum';

export function useTicketOutrightInfo({
    sportType,
    leagueId,
    oddsId,
    isLive,
    isActive = ref(true),
}: {
    sportType: SportType;
    leagueId: number;
    oddsId: number;
    isLive: boolean;
    isActive?: Ref<boolean>;
}) {
    const variables = computed(() => ({
        query: {
            sport: SportType.toOddsApi(sportType),
            tournamentId: leagueId,
            isLive,
            lang: EnumLanguage.toOddsApi(i18n.locale.value),
        },
    }));

    const [isEnabled, setIsEnabled] = useState(true);
    const refetch = async () => {
        if (isEnabled.value) {
            setIsEnabled(false);
            nextTick(() => setIsEnabled(true));
        }
    };
    const { loaded, loading, onResult } = useOutrightsQuery(
        variables,
        computed(() => ({
            enabled: isActive.value && isEnabled.value,
        })),
    );

    const outrightOdds = ref<IOutrightOdds[]>([]);

    onResult((result) => {
        const newOutrightOdds = (result ?? [])
            .filter(odds => odds.id === oddsId)
            .map(odds => deepFreeze(formatOutrightOdds(odds)));

        assignArray(outrightOdds.value, newOutrightOdds, x => x.id);
    });

    return {
        loaded,
        loading,
        outrightOdds,
        refetch,
    };
}
