<template>
    <div
        class="outrightMatch"
        v-bind:class="{
            live: isLive,
            loading: !isOddsLoaded,
        }"
        v-bind:style="{ 'min-height': `${outrightMatchPlaceHolderHeight}px` }"
    >
        <div
            v-for="(oddsItem, index) in leftColumnOdds"
            v-bind:key="oddsItem.id"
            class="outrightMatch_row"
        >
            <div class="outrightMatch_row_item outrightMatchOdds">
                <div class="outrightMatchOdds_name">
                    {{ oddsItem.teamName }}
                </div>
                <div class="outrightMatchOdds_odds">
                    <OutrightOdds
                        v-bind:league="league"
                        v-bind:oddsInfo="oddsItem"
                        align="right"
                    />
                </div>
            </div>
            <div class="outrightMatch_row_item outrightMatchOdds">
                <template v-if="rightColumnOdds[index]">
                    <div class="outrightMatchOdds_name">
                        {{ rightColumnOdds[index].teamName }}
                    </div>
                    <div class="outrightMatchOdds_odds">
                        <OutrightOdds
                            v-bind:league="league"
                            v-bind:oddsInfo="rightColumnOdds[index]"
                            align="right"
                        />
                    </div>
                </template>
                <template v-else>
                    <div class="outrightMatchOdds_name" />
                    <div class="outrightMatchOdds_odds" />
                </template>
            </div>
        </div>
        <div v-if="!isOddsLoaded" class="loadingOverlay">
            <StaticSpinner />
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { useThrottleFn } from '@vueuse/core';
    import orderBy from 'lodash/orderBy';
    import type { PropType } from 'vue';
    import { computed, toRefs, watch } from 'vue';
    import StaticSpinner from '@/components/common/StaticSpinner.vue';
    import OutrightOdds from '@/components/odds/OutrightOdds.vue';
    import { getOutrightMatchPlaceHolderHeight } from '@/components/oddsDisplay/oddsTableCalculation';
    import { OutrightOddsDisplayContext } from '@/components/oddsDisplay/outright/OutrightOddsDisplayContext';
    import { useEventEmitter } from '@/composable/useEventEmitter';
    import { useOutrightOdds } from '@/core/oddsApi/composable/useOutrightOdds';
    import type { IOutrightLeague } from '@/interface/IOutrightLeague';

    const props = defineProps({
        league: {
            type: Object as PropType<IOutrightLeague>,
            required: true,
        },
        isLive: {
            type: Boolean,
            required: true,
        },
    });

    const { league, isLive } = toRefs(props);
    const { sportType, oddsCache } = OutrightOddsDisplayContext.inject();

    const { outrightOdds, refetch, loaded } = useOutrightOdds(sportType.value, league.value.id, isLive.value);

    /** Put latest odds into odds cache  */
    /** So when user scroll away and back, we can use the cached data to render first */
    const OddsCacheKey = `${league.value.id}`;
    watch(outrightOdds, value => oddsCache.set(OddsCacheKey, value));

    const isOddsLoaded = computed(() => oddsCache.has(OddsCacheKey) || loaded.value);

    /** Automatically fallback to use cached odds */
    const allOddsList = computed(() => [
        ...(loaded.value
            ? outrightOdds.value
            : oddsCache.get(OddsCacheKey) ?? []),
    ]);

    const sortedOutrightOdds = computed(() => orderBy(allOddsList.value, [x => x.price, x => x.teamName.toUpperCase()]));
    const leftColumnOdds = computed(() => sortedOutrightOdds.value.slice(0, Math.ceil(sortedOutrightOdds.value.length / 2)));
    const rightColumnOdds = computed(() => sortedOutrightOdds.value.slice(Math.ceil(sortedOutrightOdds.value.length / 2)));

    const throttledRefetchOdds = useThrottleFn(refetch, 5000, false, true);

    const eventEmitter = useEventEmitter();
    eventEmitter.on('oddsDisplay:refreshOdds', (leagueId) => {
        if (leagueId === null || leagueId === league.value.id) {
            throttledRefetchOdds();
        }
    });

    const outrightMatchPlaceHolderHeight = computed(() => getOutrightMatchPlaceHolderHeight(league.value));
</script>

<style lang="scss" scoped>
    @import "@/components/oddsDisplay/outright/OutrightMatch";
</style>
