<!-- eslint-disable vue/valid-template-root : prevent vue warning of empty template in console -->
<template />

<script lang="ts" setup>
    import { watch } from 'vue';
    import { useLiveScore } from '@/core/oddsApi/composable/useLiveScore';
    import { useScoreAlertStore } from '@/store/scoreAlertStore';

    const props = defineProps ({
        eventId: {
            type: Number,
            required: true,
        },
    });

    const { liveScore } = useLiveScore(props.eventId);
    const { playSound } = useScoreAlertStore();

    watch(liveScore, (newVal, oldVal) => {
        if (!oldVal) return;

        const isScoreChange = newVal?.sbo?.score.home !== oldVal?.sbo?.score.home || newVal?.sbo?.score.away !== oldVal?.sbo?.score.away;
        if (isScoreChange) {
            playSound();
        }
    });
</script>
