<template>
    <div
        class="noData"
        v-bind:class="{ borderRadius }"
    >
        {{ text }}
    </div>
</template>

<script lang="ts" setup>
    import { computed } from 'vue';
    import { i18n } from '@/core/lib/i18n';
    import { NoDataType } from '@/interface/enum';

    const props = withDefaults(defineProps<{
        dataType: NoDataType;
        borderRadius?: boolean;
    }>(), {
        borderRadius: false,
    });

    const text = computed(() => {
        switch (props.dataType) {
            case NoDataType.Events:
                return i18n.t('no_events_available_for_market');
            case NoDataType.Event:
                return i18n.t('event_has_been_closed');
            case NoDataType.Odds:
                return i18n.t('market_is_unavailable_at_this_moment');
            default:
                return '';
        }
    });
</script>

<style lang="scss" scoped>
.noData {
    display: flex;
    height: 144px;
    flex-direction: column;
    flex-shrink: 0;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    padding: var(--spacers-xxl) 0;
    background-color: var(--indigo-100);
}

.borderRadius {
    border-radius: var(--borderRadius-md);
}
</style>
