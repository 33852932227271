<template>
    <svg
        class="svgIcon"
        v-bind:class="classes"
        v-bind:style="style"
    >
        <use v-bind:href="`#${name}`" />
    </svg>
</template>

<script lang="ts" setup>
    /* eslint-disable vue/require-default-prop */
    import type { CSSProperties, PropType } from 'vue';
    import { computed } from 'vue';

    const props = defineProps({
        name: {
            type: String,
            required: true,
        },
        // can be size: sm, md, lg, ...
        // or size in pixels: 16, 24, 32, ...
        size: {
            type: [Number, String],
            default: () => 'default',
        },
        // can be color: white, red, ...
        color: {
            type: String,
            default: () => '',
        },
        rotated: {
            type: Boolean,
            default: () => false,
        },
        rotating: {
            type: Boolean,
            default: () => false,
        },
        left: {
            type: Boolean,
            default: () => false,
        },
        right: {
            type: Boolean,
            default: () => false,
        },
        style: {
            type: Object as PropType<CSSProperties>,
            default: () => ({}),
        },
    });

    const classes = computed(() => {
        const result = [];

        if (props.size && Number.isNaN(Number(props.size))) result.push(`svgIcon-${props.size}`);

        if (props.color) result.push(`svgIcon-${props.color}`);

        if (props.rotated && !props.rotating) result.push('rotated');

        if (props.rotating) result.push('rotating');

        if (props.left) result.push('svgIcon-left');
        else if (props.right) result.push('svgIcon-right');

        return result;
    });

    const style = computed<CSSProperties>(() => {
        if (Number.isNaN(Number(props.size))) {
            return props.style;
        }

        return {
            width: `${props.size}px`,
            height: `${props.size}px`,
            minWidth: `${props.size}px`,
            minHeight: `${props.size}px`,
            ...props.style,
        };
    });
</script>

<style lang="scss" scoped>
    @import "@/components/common/SvgIcon";
</style>
