<template>
    <button
        ref="elRef"
        type="button"
        class="btn btn-color"
        v-bind:class="classes"
        v-bind:style="style"
        v-on:click="!disabled && emit('click')"
    >
        <span class="btn-content">
            <slot name="icon" />
            <slot />
        </span>
    </button>
</template>

<script lang="ts" setup>
    import type { PropType } from 'vue';
    import { computed, ref } from 'vue';
    import type { CSSPropertiesWithVars } from '@/core/lib/style';
    import { parsePadding } from '@/core/lib/style';
    import type { IColors, IRootObject } from '@/interface/sassVariables';

    const props = defineProps({
        active: {
            type: Boolean,
            default: false,
        },
        activeUnderline: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        flat: {
            type: Boolean,
            default: false,
        },
        tile: {
            type: Boolean,
            default: false,
        },
        rounded: {
            type: Boolean,
            default: false,
        },
        outline: {
            type: Boolean,
            default: false,
        },
        block: {
            type: Boolean,
            default: false,
        },
        padding: {
            type: [String, Number],
            default: '',
        },
        color: {
            type: String as PropType<keyof IColors | keyof IRootObject>,
            default: () => '',
        },
        textColor: {
            type: String as PropType<keyof IColors | keyof IRootObject>,
            default: () => '',
        },
        activeColor: {
            type: String as PropType<keyof IColors | keyof IRootObject>,
            default: () => '',
        },
        activeTextColor: {
            type: String as PropType<keyof IColors | keyof IRootObject>,
            default: () => '',
        },
        outlineColor: {
            type: String as PropType<keyof IColors | keyof IRootObject>,
            default: () => '',
        },
        hoverColor: {
            type: String as PropType<keyof IColors | keyof IRootObject>,
            default: () => '',
        },
        hoverTextColor: {
            type: String as PropType<keyof IColors | keyof IRootObject>,
            default: () => '',
        },
        hoverOutlineColor: {
            type: String as PropType<keyof IColors | keyof IRootObject>,
            default: () => '',
        },
    });

    const elRef = ref<HTMLElement | null>(null);

    defineExpose({ elementRef: elRef });

    const emit = defineEmits(['click']);

    const classes = computed(() => {
        const list = [];
        if (props.tile) list.push('btn-tile');
        if (props.outline) list.push('btn-outline');
        if (props.rounded) list.push('btn-rounded');
        if (props.block) list.push('btn-block');
        if (props.disabled) list.push('disabled');
        if (!props.outline && !props.disabled) list.push('hover_overlay');
        if (props.activeUnderline) list.push('activeUnderline');

        return list;
    });

    const color = computed(() => {
        if (props.disabled) return 'btnDisabledTextColor';
        if (props.flat && props.active) {
            return (props.activeTextColor) ? props.activeTextColor : 'white';
        }

        if (props.textColor) return props.textColor;

        return 'current';
    });

    const backgroundColor = computed(() => {
        if (props.disabled) {
            return (props.outline || props.flat) ? 'transparent' : 'btnDisabledBgColor';
        }

        if (props.flat) {
            if (!props.active) {
                return 'transparent';
            }
            return (props.activeColor) ? props.activeColor : props.color;
        }

        if (props.outline) return 'transparent';
        if (props.color) return props.color;
        return 'transparent';
    });

    const outlineColor = computed(() => {
        if (props.disabled) return 'btnDisabledOutlineColor';
        if (props.outlineColor) return props.outlineColor;

        return color.value;
    });

    const hoverBackgroundColor = computed(() => {
        if (props.hoverColor) return props.hoverColor;
        if (props.outline) return props.color;

        return backgroundColor.value;
    });

    const hoverTextColor = computed(() => {
        if (props.hoverTextColor) return props.hoverTextColor;
        if (props.hoverColor) return 'white';
        if (props.outline) return 'white';

        return 'current';
    });

    const hoverOutlineColor = computed(() => {
        if (props.hoverOutlineColor) return props.hoverOutlineColor;

        return hoverTextColor.value;
    });

    const style = computed(() => {
        const result: CSSPropertiesWithVars = {};

        const padding = parsePadding(props.padding);
        if (padding) result.padding = padding;

        result['--v-button-color'] = `var(--${color.value})`;
        result['--v-button-hover-color'] = `var(--${hoverTextColor.value})`;
        result['--v-button-background-color'] = `var(--${backgroundColor.value})`;
        result['--v-button-hover-background-color'] = `var(--${hoverBackgroundColor.value})`;
        result['--v-button-outline-color'] = `var(--${outlineColor.value})`;
        result['--v-button-hover-outline-color'] = `var(--${hoverOutlineColor.value})`;
        result['--v-button-active-color'] = 'var(--blue-600)';

        return result;
    });
</script>

<style lang="scss" scoped>
    .btn {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: map-get($spacers, md);
        border: 0;
        background-color: transparent;
        border-radius: var(--borderRadius-md);
        color: currentColor;
        cursor: pointer;
        font-weight: $fontWeight-bold;
        line-height: 1;
        outline: 0;
        text-decoration: none;
        transition: $transition-hover;
        user-select: none;

        &:focus {
            outline: 0;
        }

        &:not(.disabled):hover {
            background-color: var(--v-button-hover-background-color);
            color: var(--v-button-hover-color);
        }

        &-content {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            text-align: center;
        }

        &:disabled,
        &.disabled {
            cursor: not-allowed !important;
        }

        &-block {
            flex: 1 1 auto;
        }

        &-tile {
            border-radius: 0;
        }

        &-outline {
            border: 1px solid;
            background-color: transparent;
            color: currentColor;
        }

        &-rounded {
            border-radius: 50%;
        }
    }

    .hover_overlay {
        @include hover-overlay;
    }

    .btn-color {
        align-items: center;
        border-color: var(--v-button-outline-color);
        background-color: var(--v-button-background-color);
        color: var(--v-button-color);
    }

    .activeUnderline {
        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: var(--blue-600);
            content: "";
        }
    }
</style>
