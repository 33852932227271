import type { MaybeRef } from '@vueuse/core';
import { get } from '@vueuse/core';
import type { Ref } from 'vue';
import { computed, ref } from 'vue';

export function useCollapse<K extends string | number>(list: MaybeRef<K[]>) {
    const allIds = computed(() => get(list));
    const _collapsedIds = ref([]) as Ref<K[]>;

    const isCollapsedAll = computed<boolean>({
        get() {
            if (_collapsedIds.value.length === 0) return false;
            return _collapsedIds.value.length >= allIds.value.length
                && allIds.value.every(id => _collapsedIds.value.includes(id));
        },
        set(isCollapsed) {
            _collapsedIds.value = isCollapsed ? allIds.value : [];
        },
    });

    const setCollapsedItems = (cb: (item: K) => boolean) => {
        _collapsedIds.value = allIds.value.filter(cb);
    };

    const isItemCollapsed = (id: K) => _collapsedIds.value.includes(id);
    const setIsItemCollapsed = (id: K, isCollapsed: boolean) => {
        if (isCollapsed) {
            _collapsedIds.value.push(id);
        } else {
            _collapsedIds.value = _collapsedIds.value.filter(i => i !== id);
        }
    };

    const isItemCollapsedRef = (id: K) => computed<boolean>({
        get() {
            return isItemCollapsed(id);
        },
        set(isCollapsed) {
            setIsItemCollapsed(id, isCollapsed);
        },
    });

    return {
        isCollapsedAll,
        setCollapsedItems,
        /**
         * getter function for isItemCollapsed
         */
        isItemCollapsed,
        /**
         * setter function for isItemCollapsed
         */
        setIsItemCollapsed,
        /**
         * computed ref containing the state of whether an item is collapsed.
         * Use this if you want to use v-model convention.
         */
        isItemCollapsedRef,
    };
}
