import { storeToRefs } from 'pinia';
import type { Ref } from 'vue';
import { computed } from 'vue';
import type { IEventLike } from '@/interface/IEventLike';
import { SportType } from '@/interface/enum';
import { useToggleStore } from '@/store/toggleStore';

export function useLiveScoreRestriction(event: Ref<IEventLike | null>) {
    const { liveScoreProviderConfig } = storeToRefs(useToggleStore());

    const supportedSports = Object.keys(liveScoreProviderConfig.value).map(x => parseInt(x) as SportType);
    const sportType = computed(() => event.value?.sportType);
    const isSupportedSports = computed(() => sportType.value && supportedSports.includes(sportType.value));
    const isSupported = computed(() => {
        if (!event.value) return false;
        if (!event.value.isLive) return false;

        // Soccer use the score from event result, not from live score query
        if (sportType.value === SportType.Soccer) return false;

        if (!isSupportedSports.value) return false;

        return true;
    });

    return {
        isSupported,
    };
}
