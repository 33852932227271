<template>
    <tr
        class="vTableRow"
        v-bind:class="classes"
        v-bind:style="style"
    >
        <slot />
    </tr>
</template>

<script lang="ts" setup>
    import type { PropType } from 'vue';
    import { computed } from 'vue';
    import type { CSSPropertiesWithVars } from '@/core/lib/style';
    import type { IColors, IRootObject } from '@/interface/sassVariables';

    const props = defineProps({
        bgColor: {
            type: String as PropType<keyof IColors | keyof IRootObject>,
            default: () => '',
        },
    });

    const backgroundColor = computed(() => {
        if (props.bgColor) return props.bgColor;
        return 'transparent';
    });

    const classes = computed(() => {
        const list = [];
        if (props.bgColor) list.push('vTableRow-bgColor');
        return list;
    });

    const style = computed(() => {
        const result: CSSPropertiesWithVars = {};
        result['--v-table-row-background-color'] = `var(--${backgroundColor.value})`;
        return result;
    });
</script>

<style lang="scss" scoped>
    .vTableRow.vTableRow-bgColor {
        background-color: var(--v-table-row-background-color);
    }
</style>
