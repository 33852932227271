<template>
    <div
        class="spinner"
        v-bind:style="innerStyle"
    >
        <div class="dot dot-1" v-bind:style="dotStyle" />
        <div class="dot dot-2" v-bind:style="dotStyle" />
        <div class="dot dot-3" v-bind:style="dotStyle" />
    </div>
</template>

<script lang="ts" setup>
    import type { CSSProperties } from 'vue';
    import { computed } from 'vue';

    const props = withDefaults(defineProps<{
        color?: string;
        margin?: string;
    }>(), {
        color: '',
        margin: '16px auto',
    });

    const dotStyle = props.color && `background-color: ${props.color};`;

    const innerStyle = computed(() => {
        const result: CSSProperties = {};

        if (props.margin) result.margin = props.margin;

        return result;
    });
</script>

<style lang="scss" scoped>
    @import "@/components/common/StaticSpinner";
</style>
