import { getVirtualSportsUrl as _getVirtualSportsUrl } from '@/core/lib/url';
import { SportType, VirtualSportsType } from '@/interface/enum';
import { useCustomerStore } from '@/store/customerStore';

export const useUrl = () => {
    function getVirtualSportsUrl(sportType: SportType): string {
        const { priceStyle } = useCustomerStore();
        const url = _getVirtualSportsUrl();
        const sportTypeMapping: Partial<Record<SportType, VirtualSportsType>> = {
            [SportType.VirtualFootball]: VirtualSportsType.Football,
            [SportType.VirtualBasketball]: VirtualSportsType.Basketball,
        };
        return `${url}?sportType=${sportTypeMapping[sportType]}&gameFrom=10231&oddsStyle=${priceStyle}&displayMode=1`;
    }

    return {
        getVirtualSportsUrl,
    };
};
