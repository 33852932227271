import type { Fn, MaybeRef } from '@vueuse/core';
import { get, useIntervalFn } from '@vueuse/core';
import type { Ref } from 'vue';
import { computed, watch } from 'vue';
import { useState } from '@/composable/useState';

interface IntervalOptions {
    immediate?: boolean;
    immediateCallback?: boolean;
    isEnabled?: Ref<boolean>;
}

export function useInterval(
    callback: Fn,
    durationSeconds: MaybeRef<number>,
    options: IntervalOptions = {},
) {
    const isEnabled = computed(() => options?.isEnabled?.value ?? true);
    const durationRef = computed(() => get(durationSeconds));
    const [countdown, setCountdown] = useState(durationRef.value);

    const { resume, pause, isActive } = useIntervalFn(
        () => {
            setCountdown(countdown.value - 1);
        },
        1000,
        {
            immediate: options.immediate,
            immediateCallback: options.immediateCallback,
        },
    );

    const resetCountdown = () => {
        setCountdown(durationRef.value);
    };

    watch(isEnabled, (enabled) => {
        if (enabled) {
            resume();
            resetCountdown();
        } else {
            pause();
        }
    }, { immediate: true });

    watch(countdown, (sec) => {
        if (sec <= 0) {
            callback();
            resetCountdown();
        }
    });

    return {
        isActive,
        countdown,
    };
}
