<template>
    <div class="oddsArea outrightOdds notranslate" v-on:click="handleClick">
        <div
            v-bind:class="['odds', {
                live: oddsInfo.isLive,
                'odds-rise': isOddsRise,
                'odds-drop': isOddsDrop,
                'odds-picked': isSelected,
                'odds-negative': isNegative,
            }]"
        >
            <div
                class="oddsValue"
                v-bind:style="{ textAlign: align }"
            >
                {{ priceDisplay }}
            </div>
            <div class="oddsStates" v-bind:class="oddsChangeStates">
                <div v-if="oddsChangeStates" class="triangle" />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import type { PropType } from 'vue';
    import { computed, toRefs } from 'vue';
    import { useBetPage } from '@/composable/useBetPage';
    import { usePriceChangeStatus } from '@/composable/usePriceChangeStatus';
    import { formatMinDecimalPlace } from '@/core/lib/numberFormat';
    import type { IOutrightLeague } from '@/interface/IOutrightLeague';
    import type { IOutrightOdds } from '@/interface/IOutrightOdds';
    import { PriceChangeStatus, TabName } from '@/interface/enum';
    import { useBetSlipStore } from '@/store/betSlipStore';
    import { useLayoutStore } from '@/store/layoutStore';

    const props = defineProps({
        league: {
            type: Object as PropType<IOutrightLeague>,
            required: true,
        },
        oddsInfo: {
            type: Object as PropType<IOutrightOdds>,
            required: true,
        },
        align: {
            type: String as PropType<'left' | 'center' | 'right'>,
            default: 'center',
        },
    });

    const { league, oddsInfo } = toRefs(props);
    const { singleTicket } = storeToRefs(useBetSlipStore());
    const oddsPrice = computed(() => oddsInfo.value.price);
    const isNegative = computed(() => oddsPrice.value < 0);
    const isSelected = computed(() => !!singleTicket.value
        && singleTicket.value.odds.id === oddsInfo.value.id);

    const { priceChangeStatus } = usePriceChangeStatus(oddsPrice);
    const isOddsRise = computed(() => priceChangeStatus.value === PriceChangeStatus.Rise);
    const isOddsDrop = computed(() => priceChangeStatus.value === PriceChangeStatus.Drop);
    const oddsChangeStates = computed(() => {
        if (isOddsRise.value) return 'oddsStates-rise';
        if (isOddsDrop.value) return 'oddsStates-drop';
        return '';
    });

    const { isLayoutExpanded } = storeToRefs(useLayoutStore());
    const { toggleIsSportListExpanded, toggleBetSlipExpanded, toggleMyBetsExpanded, setBetSlipActiveTab } = useLayoutStore();
    const { addToOutrightBetSlip, removeSingleBetSlip } = useBetSlipStore();

    function handleClick() {
        const { betPage } = useBetPage();

        if (isSelected.value) {
            removeSingleBetSlip();
        } else {
            addToOutrightBetSlip(league.value, oddsInfo.value, betPage.value);
        }

        setBetSlipActiveTab(TabName.Single);
        toggleIsSportListExpanded(false);
        toggleBetSlipExpanded(true);
        if (!isLayoutExpanded.value) toggleMyBetsExpanded(false);
    }
    const priceDisplay = computed(() => formatMinDecimalPlace(props.oddsInfo.price, 2));
</script>

<style lang="scss" scoped>
    @import "@/components/odds/Odds";
</style>
