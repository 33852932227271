<template>
    <Tabs
        v-gtm:type="'HotLinks'"
        class="wrapper"
        v-bind:enableCollapse="false"
    >
        <Tab
            name="sports"
            v-bind:label="i18n.t('hot_link')"
            icon="icon-system-fire"
        />
        <template #sports>
            <SportList
                v-bind:items="hotLinks"
            />
        </template>
    </Tabs>
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import SportList from '@/components/sportList/SportList.Euro.vue';
    import Tab from '@/components/tabs/Tab.vue';
    import Tabs from '@/components/tabs/Tabs.vue';
    import { i18n } from '@/core/lib/i18n';
    import { useEuroSideBarStore } from '@/store/sideBarStore.Euro';

    const { hotLinks } = storeToRefs(useEuroSideBarStore());
</script>

<style lang="scss" scoped>
    @import "@/components/sportList/HotLinkBlock.Euro";
</style>
