<template>
    <div class="iframe-container" v-bind:class="{ embed: isEmbedOnMainPage }">
        <div v-if="errorMsg" class="error">
            {{ errorMsg }}
        </div>
        <iframe
            v-else
            title="BetConstruct"
            v-bind:src="iframeUrl"
            allow="fullscreen"
            height="100%"
            width="100%"
        />
    </div>
</template>

<script lang="ts" setup>
    import type { PropType } from 'vue';
    import { onMounted, ref } from 'vue';
    import { Api } from '@/core/lib/api';
    import type { IBetConstruct } from '@/interface/IEvent';
    import { LiveStreamResource } from '@/interface/pollux';

    const props = defineProps({
        betConstruct: {
            type: Object as PropType<IBetConstruct>,
            required: true,
        },
        isEmbedOnMainPage: {
            type: Boolean,
            default: false,
        },
    });

    const iframeUrl = ref('');
    const errorMsg = ref('');

    onMounted(async () => {
        const { data: { url, errorCode, errorMessage } } = await Api.getLiveStream(props.betConstruct.eventId, 0, LiveStreamResource.BetConstruct);
        if (errorCode === 0 && url) {
            iframeUrl.value = url;
        } else if (errorMessage) {
            errorMsg.value = errorMessage;
        }
    });
</script>

<style lang="scss" scoped>
    @import "@/components/liveStream/betConstructStream/BetConstructStream";
</style>
