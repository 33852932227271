import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { SboCurrency } from '@/interface/enum';
import { useCustomerStore } from '@/store/customerStore';

export function useStakeDisplay(stake: number) {
    const { formatMoney } = useCustomerStore();
    const stakeDisplay = formatMoney(stake);

    return {
        stakeDisplay,
    };
}

export function useStakeConfig() {
    const { defaultMoneyConfig, sboCurrency } = storeToRefs(useCustomerStore());
    const formatStakeConfig = computed(() => ({
        ...defaultMoneyConfig.value,
        precision: 0,
        allowBlank: true,
        disableNegative: true,
    }));

    const hasMultiplier = computed(() => [SboCurrency.VND].includes(sboCurrency.value));
    const multiplier = computed(() => (hasMultiplier.value ? 1000 : 1));

    const stakeInputMaxLength = computed(() => {
        const limit = 15 - (hasMultiplier.value ? 3 : 0);
        const separatorCount = Math.floor(limit / 3) - 1;
        return limit + separatorCount;
    });

    return {
        formatStakeConfig,

        hasMultiplier,
        multiplier,
        stakeInputMaxLength,
    };
}
