<template>
    <div ref="tutorialAnchor">
        <OddsDisplay
            v-if="isShowLive"
            v-resize="triggerRedraw"
            v-bind:isLive="true"
            v-bind:isSticky="true"
        />
        <OddsDisplay
            v-if="isShowNonLive"
            v-bind:isLive="false"
            v-bind:isSticky="true"
            v-bind:redrawSignal="redrawSignal"
        />
    </div>
    <SelectLeague
        v-if="isShowSelectLeague"
        v-bind:leagueFilters="selectLeagueList"
        v-bind:loaded="loaded"
        v-on:close="setIsShowSelectLeague(false)"
    />
</template>

<script lang="ts" setup>
    import { uniqBy } from 'lodash';
    import { storeToRefs } from 'pinia';
    import { computed, ref, watch } from 'vue';
    import OddsDisplay from '@/components/oddsDisplay/OddsDisplay.vue';
    import { OddsDisplayBlockContext } from '@/components/oddsDisplay/OddsDisplayBlockContext';
    import { useSelectLeague } from '@/components/oddsDisplay/useSelectLeague';
    import SelectLeague from '@/components/selectLeague/SelectLeague.vue';
    import type { ILeagueFilter } from '@/interface/ILeagueFilter';
    import { MarketPage } from '@/interface/enum';
    import { useAsiaSportMarketInfoStore } from '@/store/asiaSportMarketInfoStore';
    import { useBetBuilderTutorialStore } from '@/store/betBuilderTutorialStore';

    const { marketPage, loaded, events, displayEvents } = OddsDisplayBlockContext.provide();
    const { navbarMarketList, eventDate } = storeToRefs(useAsiaSportMarketInfoStore());

    const selectLeagueList = computed((): ILeagueFilter[] => {
        const allLeagues = events.value.map(event => ({
            id: event.league.id,
            name: event.league.name,
            size: event.league.size,
        }));
        return uniqBy(allLeagues, x => x.id);
    });
    const { isShowSelectLeague, setIsShowSelectLeague } = useSelectLeague(selectLeagueList);

    const isMarketPageWithLive = computed(() => [MarketPage.Today, MarketPage.MixParlay, MarketPage.MyFavorites, MarketPage.All].includes(marketPage));
    const isShowLive = computed(() => {
        if (marketPage === MarketPage.Live) return true;

        if (isMarketPageWithLive.value) {
            if (loaded.value) return displayEvents.value.some(x => x.isLive);
            return navbarMarketList.value.some(x => x.marketPage === marketPage && x.date === eventDate.value && x.liveEventCount > 0);
        }

        return false;
    });
    const isShowNonLive = marketPage !== MarketPage.Live;

    const redrawSignal = ref(0);
    const triggerRedraw = () => redrawSignal.value++;

    watch(isShowLive, triggerRedraw);

    const { tutorialAnchor } = storeToRefs(useBetBuilderTutorialStore());
</script>
