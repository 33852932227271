<!-- eslint-disable vuejs-accessibility/mouse-events-have-key-events -->
<template>
    <div
        class="dropDown"
        v-on:mouseenter="setIsOpen(true)"
        v-on:mouseleave="setIsOpen(false)"
    >
        <span class="dropDown_title">
            <span v-if="selectedItem">{{ selectedItem.name }}</span>
        </span>

        <div
            v-show="isOpen"
            ref="dropDownRef"
            class="dropDown_list"
        >
            <dl>
                <dd
                    v-for="item in list"
                    v-bind:key="item.value"
                    v-gtm:label="item.value"
                    v-gtm:@click
                    class="dropDown_list_item"
                    v-on:click="update(item)"
                >
                    {{ item.name }}
                </dd>
            </dl>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import type { PropType } from 'vue';
    import { computed, nextTick, ref, watch } from 'vue';
    import { useState } from '@/composable/useState';
    import type { IDropDownConfig } from '@/interface/IDropDownConfig';
    import type { SportType } from '@/interface/enum';

    const props = defineProps({
        value: {
            type: [Number, String] as PropType<SportType>,
            required: true,
        },
        list: {
            type: Array as PropType<IDropDownConfig<any>[]>,
            required: true,
        },
    });
    const emit = defineEmits(['update']);

    const [isOpen, setIsOpen] = useState(false);
    const selectedItem = computed(() => props.list.find(item => props.value === item.value));
    const dropDownRef = ref<HTMLElement | null>(null);

    const update = (item: IDropDownConfig<any>) => {
        emit('update', item.value);
        setIsOpen(false);
    };

    watch(isOpen, () => {
        nextTick(() => {
            if (isOpen.value === true && dropDownRef.value !== null) {
                const targetRect = dropDownRef.value.getBoundingClientRect();
                const windowHeight = window.innerHeight;
                const padding = 8;
                const dropDownHeight = windowHeight - targetRect.top - padding;
                if (targetRect.height > dropDownHeight) {
                    dropDownRef.value.style.height = `${dropDownHeight}px`;
                    dropDownRef.value.style.overflowY = 'scroll';
                }
            }
        });
    });

</script>

<style lang="scss" scoped>
    @import "@/components/liveStream/DropDown";
</style>
