<template>
    <VButton
        flat
        color="showMoreBtnColor"
        activeColor="showMoreBtnActiveColor"
        hoverColor="showMoreBtnHoverBgColor"
        v-bind:active="isOpenMore"
        v-on:click="emit('click')"
    >
        <template #icon>
            <SvgIcon
                v-bind:left="isMatchResult ? false : true"
                size="14"
                v-bind:name="isOpenMore ? 'icon-system-minus' : 'icon-system-plus'"
            />
        </template>
        <template v-if="!isMatchResult">
            {{ count }}
        </template>
    </VButton>
</template>

<script lang="ts" setup>
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import VButton from '@/components/common/button/VButton.vue';

    defineProps({
        isOpenMore: {
            type: Boolean,
            required: true,
        },
        count: {
            type: Number,
            default: 0,
        },
        isMatchResult: {
            type: Boolean,
            default: false,
        },
    });

    const emit = defineEmits(['click']);
</script>
