import { storeToRefs } from 'pinia';
import { computed, watchEffect } from 'vue';
import { i18n } from '@/core/lib/i18n';
import { PageType } from '@/interface/enum';
import { useCustomerStore } from '@/store/customerStore';

export function useTitle(pageType: PageType) {
    const { brandName } = storeToRefs(useCustomerStore());

    const titleName = computed(() => {
        const pageTitleMapping: Partial<Record<PageType, string>> = {
            [PageType.Home]: '',
            [PageType.Mybets]: i18n.t('tab_title_mybets'),
            [PageType.Results]: i18n.t('results'),
            [PageType.LiveStream]: i18n.t('live_stream'),
        };

        const homeTitleName = `${brandName.value} ${i18n.t('main_header_sports')}`;
        return pageTitleMapping[pageType]
            ? `${homeTitleName} | ${pageTitleMapping[pageType]}`
            : homeTitleName;
    });

    watchEffect(() => {
        document.title = titleName.value;
    });
}
