<template>
    <GroupTitle
        v-model:isExpanded="isExpanded"
        v-gtm:type="'MatchCard'"
        v-bind:viewAll="getRegionRoute(sportType, region)"
        v-bind:expandedGtmLabel="isExpanded ? 'Region_Collapse' : 'Region_Expand'"
        v-bind:viewAllGtmLabel="'Region_ViewAll'"
    >
        <template #title>
            <strong class="nameItem">{{ region.name }}</strong>
        </template>
    </GroupTitle>
    <div v-if="isExpanded" class="content">
        <div
            v-for="league in region.leagues"
            v-bind:key="league.id"
            v-tooltip="league.info"
            class="item notranslate"
            v-on:click="goToLeague(league)"
        >
            <strong>
                {{ league.name }}
                <SvgIcon
                    v-if="league.info"
                    class="icon"
                    name="icon-system-cycle-info"
                />
            </strong>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { ref } from 'vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import GroupTitle from '@/components/common/groupTitle/GroupTitle.vue';
    import { EuroDisplayContext } from '@/components/euroDisplay/EuroDisplayContext';
    import { useEuroRouter } from '@/composable/useEuroRouter';
    import type { ILeague } from '@/interface/ILeague';

    export interface ILeaguesByRegion {
        id: number;
        name: string;
        leagues: ILeague[];
    }

    const props = defineProps<{
        region: ILeaguesByRegion;
        initialIsExpanded: boolean;
    }>();

    const isExpanded = ref(props.initialIsExpanded);

    const { sportType } = EuroDisplayContext.inject();

    const { goToLeague: _goToLeague, getRegionRoute } = useEuroRouter();
    const goToLeague = (league: ILeague) => _goToLeague(sportType.value, league);
</script>

<style lang="scss" scoped>
@import "@/components/euroDisplay/RegionCompetitionsDisplay";
</style>
