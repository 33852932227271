import { sleep } from '@sports-utils/timer';
import { useBroadcastChannel, whenever } from '@vueuse/core';
import { cloneDeep } from 'lodash';
import { storeToRefs } from 'pinia';
import type { Ref } from 'vue';
import { computed, watch } from 'vue';
import { useAutoResetState } from '@/composable/useAutoResetState';
import { useBetSlipMessage } from '@/composable/useBetSlipMessage';
import { useEventEmitter } from '@/composable/useEventEmitter';
import { usePlatform } from '@/composable/usePlatform';
import { createReceipt, useReceiptCollection } from '@/composable/useReceiptCollection';
import { useState } from '@/composable/useState';
import { Api } from '@/core/lib/api';
import { getDefaultBetSlip, getTicketKey } from '@/core/lib/betSlipHelper.euro';
import { i18n } from '@/core/lib/i18n';
import { convertToEuroPrice, is5050Market } from '@/core/lib/oddsHelper';
import { getActualStake, getPlacedErrorMessage, getTotalOddsPrice } from '@/core/lib/ticket';
import { isVoucherValidForOdds } from '@/core/lib/voucherHelper';
import type { IBetSlipEuro } from '@/interface/IBetSlip';
import { BetSlipType } from '@/interface/IBetSlip';
import type { IBetSlipStoreEuro } from '@/interface/IBetSlipStoreEuro';
import type { IEventEuro } from '@/interface/IEvent';
import type { IOdds, IPrice } from '@/interface/IOdds';
import { BetSlipsEuroStatus } from '@/interface/IPlaceBetButton';
import type { BetSlipBroadcastMessage } from '@/interface/broadcastchannel';
import type { EuroBetPageType } from '@/interface/enum';
import {
    BetSlipMessagePriority,
    BroadcastChannelName,
    MarketType,
    PlaceOrderStatus,
    PriceStyle,
    SiteStyle,
    SportType,
} from '@/interface/enum';
import type { OrderResponse } from '@/interface/pollux';
import type { ISingleBetSlipEuro } from '@/store/betSlipStore.euro';
import { useCustomerStore } from '@/store/customerStore';
import { useToggleStore } from '@/store/toggleStore';

const SINGLE_MAX_BET_COUNT = 20;

export const useSingleBetSlips = (singleBetSlips: Ref<ISingleBetSlipEuro[]>, balance: Ref<number | null>): IBetSlipStoreEuro => {
    const [isPlacingBet, setIsPlacingBet] = useState(false);

    const { refreshBalanceAfterAction } = usePlatform();
    const { receipts, resetReceipts, setReceiptSuccessMessage, setReceiptWarningMessage } = useReceiptCollection();

    const { isAcceptAnyOdds, priceStyle } = storeToRefs(useCustomerStore());
    const isAutoAcceptChange = computed(() => isAcceptAnyOdds.value || singleBetSlips.value.every(x => x.stake === 0));

    const { message, setMessage, resetMessage, startAutoResetMessage } = useBetSlipMessage();
    const [placedErrorMessages, setPlacedErrorMessages] = useAutoResetState<string[]>([], 5000);

    whenever(() => singleBetSlips.value.length === 0, () => {
        startAutoResetMessage();
    });

    const addBet = (
        event: IEventEuro,
        odds: IOdds,
        priceOption: IPrice,
        betPage: EuroBetPageType,
        type: BetSlipType.Single | BetSlipType.Outright = BetSlipType.Single,
    ) => {
        const isOutright = odds.marketType === MarketType.OutRight;
        if (isOutright) type = BetSlipType.Outright;

        /**
         * Check if the bet count exceeded the limit.
         */
        if (singleBetSlips.value.length >= SINGLE_MAX_BET_COUNT) {
            setMessage(i18n.t('bet_slip_error_message_max_selections_exceeded'), BetSlipMessagePriority.MaxSelectionsExceeded);
            startAutoResetMessage();
            return;
        }

        /**
         * Check if the bet is already existed.
         * Normally, this should not happen. Just in case.
         */
        const betSlipKey = getTicketKey(odds, priceOption);
        const isExisted = singleBetSlips.value.some(x => x.key === betSlipKey);
        if (isExisted) return;

        /**
         * Clear all receipts when a new bet is added
         */
        resetReceipts();

        /**
         * Clear all error message when a new bet is added
         */
        resetMessage();
        singleBetSlips.value.push({
            ...getDefaultBetSlip(type),
            type,
            key: betSlipKey,
            tickets: [{
                key: betSlipKey,
                betPage,
                priceStyle: PriceStyle.Unknown,
                event: cloneDeep(event),
                odds: cloneDeep(odds),
                priceOption: cloneDeep(priceOption),
                liveHomeScore: 0,
                liveAwayScore: 0,
            }],
            vouchers: [],
            stake: 0,
        });
    };

    const removeBet = (key: string) => {
        const index = singleBetSlips.value.findIndex(x => x.key === key);
        if (index >= 0) {
            Api.clearSingleTicket(singleBetSlips.value[index].uid);
            singleBetSlips.value.splice(index, 1);
        }
    };
    const updateBet = (key: string, updateFn: (betSlip: IBetSlipEuro) => void) => {
        const single = singleBetSlips.value.find(single => single.key === key);

        if (single) {
            updateFn(single);
        }
    };
    const removeAll = () => {
        Api.clearTicket();
        singleBetSlips.value = [];
    };

    const actualStake = (betSlip: IBetSlipEuro) => getActualStake(betSlip.stake, getTotalOddsPrice(betSlip.tickets));
    const { placeBetDelayTime } = storeToRefs(useToggleStore(SiteStyle.Euro));

    function getRelatedBetSlip(response: OrderResponse) {
        return singleBetSlips.value.find(betSlip => betSlip.tickets[0].odds.id === response.oddsId && (!response.option || betSlip.tickets[0].priceOption.option === response.option));
    }

    const placeBet = async () => {
        const totalRealStake = singleBetSlips.value
            .filter(betSlip => !betSlip.voucherId)
            .reduce((acc, curr) => acc + actualStake(curr), 0);

        if (balance.value !== null && totalRealStake > balance.value) {
            setMessage(i18n.t('bet_slip_error_message_not_enough_balance'));
            return;
        }

        try {
            setIsPlacingBet(true);

            // bets without stake or voucher will be ignored
            // voucher bet will have stake = voucher amount
            const betsWithStake = singleBetSlips.value.filter(x => x.stake);
            const singleBets = betsWithStake.filter(x => x.type === BetSlipType.Single);
            const outrightBets = betsWithStake.filter(x => x.type === BetSlipType.Outright);

            const requests = [
                ...(singleBets.length ? [Api.placeEuroAllSingleBets(singleBets)] : []),
                ...(outrightBets.length ? [Api.placeEuroOutrightBets(outrightBets)] : []),
            ];
            if (requests.length === 0) return; // no bet to place

            const responses = (await Promise.all(requests));
            await sleep(placeBetDelayTime.value * 1000);

            const orderResponses = responses.flat().map(x => x.data).flat();
            if (orderResponses.some(x => x.isPlaceBetSuccess)) {
                refreshBalanceAfterAction();

                const successBetSlips = orderResponses
                    .filter(x => x.isPlaceBetSuccess)
                    .map((response) => {
                        const betSlip = getRelatedBetSlip(response);

                        if (betSlip && ((priceStyle.value === PriceStyle.Euro || betSlip.tickets[0].event.sportType === SportType.Cricket) && is5050Market(betSlip.tickets[0].odds.marketType))) {
                            response.price = convertToEuroPrice(response.price, PriceStyle.HK);
                        }

                        return { betSlip: betSlip!, response };
                    });

                emitPlaceBetSuccess(successBetSlips.map(r => r.betSlip));
                receipts.value.list = successBetSlips.map((successBetSlips) => {
                    /**
                     * Update the price of the bet slip with the response price
                     *
                     * This is to ensure that the receipt will display the correct price
                     * when "Accept Any Odds" is enabled.
                     */
                    successBetSlips.betSlip.tickets[0].priceOption.price = successBetSlips.response.price;
                    return createReceipt(successBetSlips.betSlip, successBetSlips.response);
                });

                const failedBetsCount = orderResponses.length - successBetSlips.length;
                if (failedBetsCount > 0) {
                    setReceiptWarningMessage(failedBetsCount);
                } else {
                    setReceiptSuccessMessage();
                }

                removeAll();
            } else {
                orderResponses.forEach((response) => {
                    const betSlip = getRelatedBetSlip(response);

                    if (betSlip) {
                        const status = PlaceOrderStatus.fromPollux(response.placeOrderStatus);
                        const message = getPlacedErrorMessage(status, response.extraInfo);
                        betSlip.placedOrderError = {
                            status,
                            message,
                        };
                        if (!placedErrorMessages.value.find(m => m === message)) {
                            setPlacedErrorMessages([...placedErrorMessages.value, message]);
                        }
                    }
                });
            }
        } catch (error) {
            console.error('Failed to place bet', error);
            const message = getPlacedErrorMessage(PlaceOrderStatus.GeneralFailure, '');
            setPlacedErrorMessages([message]);
        } finally {
            setIsPlacingBet(false);
        }
    };
    const { post } = useBroadcastChannel<BetSlipBroadcastMessage, BetSlipBroadcastMessage>({ name: BroadcastChannelName.BetSlip });

    function emitPlaceBetSuccess(betSlips: ISingleBetSlipEuro[]) {
        const isLive = betSlips
            .filter(x => x.stake)
            .some(x => x.tickets[0].odds.isLive);
        post({ name: 'placeBetSuccess', isLive });
    }

    const [hasPriceChange, setHasPriceChange] = useState(false);
    const eventEmitter = useEventEmitter();
    eventEmitter.on('ticket:price:change', () => {
        setHasPriceChange(true);
    });
    eventEmitter.on('ticket:accept:price:change', () => {
        setHasPriceChange(false);
    });

    const betSlipStatusList = computed(() => {
        const statusList: BetSlipsEuroStatus[] = [];

        if (isPlacingBet.value) {
            statusList.push(BetSlipsEuroStatus.Loading);
        }
        // Not all tickets are opened
        if (singleBetSlips.value.some(x => x.uid === '')) {
            statusList.push(BetSlipsEuroStatus.Unavailable);
        }
        if (singleBetSlips.value.every(x => x.stake === 0 && !x.voucherId)) {
            statusList.push(BetSlipsEuroStatus.Unavailable);
        }
        if (singleBetSlips.value.filter(x => !x.voucherId && x.stake > 0).find(x => x.stake < x.minBet)) {
            statusList.push(BetSlipsEuroStatus.LessThanMinBet);
        }
        if (singleBetSlips.value.filter(x => !x.voucherId).find(x => x.stake > x.maxBet)) {
            statusList.push(BetSlipsEuroStatus.GreaterThanMaxBet);
        }

        const tickets = singleBetSlips.value.filter(x => x.voucherId).flatMap(x => x.tickets);
        if (tickets.some(ticket => !isVoucherValidForOdds(ticket.priceOption.price, ticket.priceStyle))) {
            statusList.push(BetSlipsEuroStatus.Unavailable);
        }

        if (!isAutoAcceptChange.value && hasPriceChange.value) {
            statusList.push(BetSlipsEuroStatus.PriceChange);
        }

        if (singleBetSlips.value.length === 0 || statusList.length === 0) {
            statusList.push(BetSlipsEuroStatus.Valid);
        }

        return statusList;
    });

    const placeBetStatus = computed(() => Math.max(...betSlipStatusList.value));

    watch(betSlipStatusList, () => {
        const isInvalidStake = BetSlipsEuroStatus.isInvalidStake(betSlipStatusList.value);
        const isPriceChange = betSlipStatusList.value.includes(BetSlipsEuroStatus.PriceChange) && !isAutoAcceptChange.value;

        if (isInvalidStake && isPriceChange) {
            setMessage(i18n.t('bet_slip_error_message_stake_update_stake_and_accept_price_change'), BetSlipMessagePriority.OddsChange);
        } else if (isInvalidStake) {
            setMessage(i18n.t('bet_slip_error_message_stake_update_stake'), BetSlipMessagePriority.InvalidStake);
        } else if (isPriceChange) {
            setMessage(i18n.t('bet_slip_error_message_stake_accept_price_change'), BetSlipMessagePriority.OddsChange);
        } else if (betSlipStatusList.value.includes(BetSlipsEuroStatus.Valid)) {
            resetMessage();
        } else {
            resetMessage();
        }
    });

    return {
        betSlipStatusList,
        placeBetStatus,

        receipts,
        resetReceipts,

        actualStake,

        isAutoAcceptChange,
        isPlacingBet,
        setIsPlacingBet,

        betSlipMessage: message,
        setBetSlipMessage: setMessage,
        placedErrorMessages,

        addBet,
        removeBet,
        updateBet,
        removeAll,
        placeBet,
    };
};
