/* eslint-disable max-classes-per-file */
import { storeToRefs } from 'pinia';
import { watch } from 'vue';
import { useRouter } from 'vue-router';
import { useCasinoCta } from '@/composable/useCasinoCta';
import { useEuroRouter } from '@/composable/useEuroRouter';
import { useState } from '@/composable/useState';
import { Api } from '@/core/lib/api';
import { CasinoApi } from '@/core/lib/casinoApi';
import { openInJoiGame, openInJoiPromotionPage } from '@/core/lib/gameHelper';
import { i18n } from '@/core/lib/i18n';
import { getCasinoSubDomainUrl, getUrl } from '@/core/lib/url';
import type { IBannerInfo } from '@/interface/IBannerInfo';
import { AsiaRoutePage, BannerAction, BannerPosition, CasinoAutoJoinType, CasinoGameFrom, MarketPage, SportType } from '@/interface/enum';
import type { BannerInfo } from '@/interface/pollux';
import { useAsiaSportMarketInfoStore } from '@/store/asiaSportMarketInfoStore';
import { useCustomerStore } from '@/store/customerStore';

type SportTypeKey = Exclude<keyof typeof SportType, 'fromOddsApi' | 'toOddsApi' | 'fromPollux' | 'toPollux' | 'fromRoute' | 'toRoute'>;

function convertToBanner(bannerInfo: BannerInfo): IBannerInfo {
    return {
        cdn: bannerInfo.cdn,
        chinaCdn: bannerInfo.chinaCdn,
        action: BannerAction.fromPollux(bannerInfo.action),
        actionDetail: bannerInfo.actionDetail,
        id: bannerInfo.id,
        trackingCode: bannerInfo.trackingCode ?? null,
        remark: bannerInfo.remark,
        gtm: getBannerGtm(bannerInfo),
    };
}

function getBannerGtm(banner: BannerInfo) {
    if (banner) {
        const bannerUrlName = banner.cdn?.split('/')
            .pop() ?? '';
        return bannerUrlName;
    }
    return '';
}

function redirectUrl(bannerInfo: IBannerInfo) {
    if (!bannerInfo.actionDetail) {
        throw new Error('banner redirectUrl need actionDetail');
    }

    const url = bannerInfo.actionDetail;
    window.location.href = getUrl(url);
}

function openWindow(bannerInfo: IBannerInfo) {
    const { actionDetail } = bannerInfo;
    if (!actionDetail) {
        throw new Error('banner openWindow need actionDetail');
    }

    const [url, name, windowFeatures] = actionDetail.split(';');
    window.open(getUrl(url), name, windowFeatures);
}

function getCasinoGameFrom(position: BannerPosition) {
    const casinoGameFromMapping: Partial<Record<BannerPosition, CasinoGameFrom>> = {
        [BannerPosition.AsiaTop]: CasinoGameFrom.KratosTop,
        [BannerPosition.AsiaSide]: CasinoGameFrom.KratosSide,
        [BannerPosition.AsiaPopup]: CasinoGameFrom.KratosCover,
        [BannerPosition.EuroTop]: CasinoGameFrom.KratosTop,
        [BannerPosition.EuroPopup]: CasinoGameFrom.KratosCover,
    };
    return casinoGameFromMapping[position]!;
}

async function openCasinoWindow(bannerInfo: IBannerInfo, position: BannerPosition) {
    const { actionDetail } = bannerInfo;
    if (!actionDetail) {
        throw new Error('banner openCasinoWindow need actionDetail');
    }

    const casinoCta = useCasinoCta(actionDetail);
    const needsOpenGame = bannerInfo.action === BannerAction.CasinoInGame;
    if (casinoCta.isCasino && needsOpenGame) {
        casinoCta.autoJoinType = CasinoAutoJoinType.GoodRoadFirstLow;
    }
    if (casinoCta.isPromotion) {
        await CasinoApi.enrollCasino(bannerInfo.remark?.promotionCode ?? '');
    }

    const desktopPlatform = 1;
    const { data } = await CasinoApi.getCasinoPopUpMessage(casinoCta.productType, desktopPlatform);
    if (data.isPopupMessage) {
        // eslint-disable-next-line no-restricted-globals, no-alert
        if (!confirm(`${data.popupMessage}`)) {
            return;
        }
    }

    window.open(
        `${getCasinoSubDomainUrl()}/api/Game/launchCasino?productType=${casinoCta.productType}&gameFrom=${(getCasinoGameFrom(position))}&autoJoinType=${casinoCta.autoJoinType}`,
        'Casino',
        'scrollbars=no,resizable=yes,width=1200,height=770',
    );
}

const injoiGameFromMapping: Partial<Record<BannerPosition, number>> = {
    [BannerPosition.AsiaTop]: 10202,
    [BannerPosition.EuroTop]: 10202,
    [BannerPosition.AsiaSide]: 10206,
};

const injoiPromotionGameFromMapping: Partial<Record<BannerPosition, number>> = {
    [BannerPosition.AsiaTop]: 10235,
    [BannerPosition.EuroTop]: 10235,
    [BannerPosition.AsiaSide]: 10236,
};

function inJoiOpenGame(bannerInfo: IBannerInfo, position: BannerPosition) {
    const gameFrom = injoiGameFromMapping[position] ?? 0;
    if (bannerInfo.actionDetail) {
        openInJoiGame(bannerInfo.actionDetail, gameFrom);
    }
}

function inJoiOpenPromotionPage(bannerInfo: IBannerInfo, position: BannerPosition) {
    const gameFrom = injoiPromotionGameFromMapping[position] ?? 0;
    if (bannerInfo.actionDetail) {
        openInJoiPromotionPage(bannerInfo.actionDetail, gameFrom);
    }
}

function notImplement(bannerInfo: IBannerInfo) {
    console.error(`BannerAction ${BannerAction[bannerInfo.action]} is not supported`);
}

function useBaseBannerAction(position: BannerPosition) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [banners, setBanners] = useState<IBannerInfo[]>([]);
    const router = useRouter();

    function changeRoute(bannerInfo: IBannerInfo) {
        if (!bannerInfo.actionDetail) {
            throw new Error('banner RouteTo need actionDetail');
        }

        router.push(bannerInfo.actionDetail);
    }

    async function getBannerFromUmai(position: BannerPosition) {
        const { brandType } = storeToRefs(useCustomerStore());

        try {
            const { data } = await Api.getBannerFromUmai(position, brandType.value);

            if (data.length === 0) return;
            setBanners(data.map(convertToBanner).filter(x => x.cdn));
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoaded(true);
        }
    }

    watch(i18n.locale, () => {
        getBannerFromUmai(position);
    });

    getBannerFromUmai(position);

    return {
        isLoaded,
        banners,
        changeRoute,
        redirectUrl,
        openWindow,
        openCasinoWindow,
        inJoiOpenGame,
        inJoiOpenPromotionPage,
        notImplement,
    };
}

type BannerPositionAsia = BannerPosition.AsiaTop
| BannerPosition.AsiaPopup
| BannerPosition.AsiaSide
| BannerPosition.AsiaMyBetTop;

type BannerPositionEuro = BannerPosition.EuroMyBetTop
| BannerPosition.EuroPopup
| BannerPosition.EuroTop;

export function useBannerAsia(position: BannerPositionAsia) {
    const {
        isLoaded,
        banners,
        changeRoute,
        redirectUrl,
        openWindow,
        openCasinoWindow,
        inJoiOpenGame,
        inJoiOpenPromotionPage,
        notImplement,
    } = useBaseBannerAction(position);

    const router = useRouter();

    const { setSportType } = useAsiaSportMarketInfoStore();

    async function changeSport(bannerInfo: IBannerInfo) {
        const sportType = SportType[bannerInfo.actionDetail as unknown as SportTypeKey];
        if (!sportType) {
            throw new Error(`actionDetail "${bannerInfo.actionDetail}" is not valid sport`);
        }

        await router.push(AsiaRoutePage.Home);
        setSportType(sportType);
    }

    async function changeSportMarketPage(bannerInfo: IBannerInfo) {
        if (!bannerInfo.actionDetail) {
            throw new Error('banner ToSportMarketPage need actionDetail');
        }

        const [sportType, marketPage] = bannerInfo.actionDetail.split(';');

        await router.push(AsiaRoutePage.Home);
        setSportType(
            SportType[sportType as unknown as SportTypeKey],
            MarketPage[marketPage as unknown as keyof typeof MarketPage],
        );
    }

    function executeBannerAction(bannerInfo: IBannerInfo | null) {
        if (!bannerInfo) return;

        switch (bannerInfo.action) {
            case BannerAction.RouteTo:
                changeRoute(bannerInfo);
                break;
            case BannerAction.ToSport:
                changeSport(bannerInfo);
                break;
            case BannerAction.ToSportMarketPage:
                changeSportMarketPage(bannerInfo);
                break;
            case BannerAction.RedirectTo:
                redirectUrl(bannerInfo);
                break;
            case BannerAction.OpenWindow:
                openWindow(bannerInfo);
                break;
            case BannerAction.CasinoInGame:
            case BannerAction.CasinoToGameLobby:
            case BannerAction.CasinoDealerPage:
                openCasinoWindow(bannerInfo, position);
                break;
            case BannerAction.InJoiOpenGame:
                inJoiOpenGame(bannerInfo, position);
                break;
            case BannerAction.InJoiEventPromotion:
                inJoiOpenPromotionPage(bannerInfo, position);
                break;
            case BannerAction.NoAction:
                break;
            default:
                notImplement(bannerInfo);
        }
    }

    return {
        isLoaded,
        banners,
        executeBannerAction,
    };
}

export function useBannerEuro(position: BannerPositionEuro) {
    const {
        isLoaded,
        banners,
        changeRoute,
        redirectUrl,
        openWindow,
        openCasinoWindow,
        inJoiOpenGame,
        inJoiOpenPromotionPage,
        notImplement,
    } = useBaseBannerAction(position);

    const { goToSportPage } = useEuroRouter();
    function changeSport(bannerInfo: IBannerInfo) {
        const sportType = SportType[bannerInfo.actionDetail as unknown as SportTypeKey];
        if (!sportType) {
            throw new Error(`actionDetail "${bannerInfo.actionDetail}" is not valid sport`);
        }

        goToSportPage(sportType);
    }

    function executeBannerAction(bannerInfo: IBannerInfo | null) {
        if (!bannerInfo) return;

        switch (bannerInfo.action) {
            case BannerAction.RouteTo:
                changeRoute(bannerInfo);
                break;
            case BannerAction.ToSport:
                changeSport(bannerInfo);
                break;
            case BannerAction.RedirectTo:
                redirectUrl(bannerInfo);
                break;
            case BannerAction.OpenWindow:
                openWindow(bannerInfo);
                break;
            case BannerAction.CasinoInGame:
            case BannerAction.CasinoToGameLobby:
            case BannerAction.CasinoDealerPage:
                openCasinoWindow(bannerInfo, position);
                break;
            case BannerAction.InJoiOpenGame:
                inJoiOpenGame(bannerInfo, position);
                break;
            case BannerAction.InJoiEventPromotion:
                inJoiOpenPromotionPage(bannerInfo, position);
                break;
            case BannerAction.NoAction:
                break;
            default:
                notImplement(bannerInfo);
        }
    }

    return {
        isLoaded,
        banners,
        executeBannerAction,
    };
}
