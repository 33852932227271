<template>
    <div v-if="isShowResult" class="result">
        <strong>{{ i18n.t('score') }}: </strong>
        <template v-if="isNextGoal">
            <span>
                {{ bet.fullTimeScore.home }}:{{ bet.fullTimeScore.away }}
            </span>
        </template>
        <template v-else>
            <span v-if="hasHTScore" class="halfTimeScore">
                {{ i18n.t('half_time_short') }} {{ bet.halfTimeScore.home }}:{{ bet.halfTimeScore.away }}
            </span>
            <span v-if="hasFTScore" class="finalScore">
                {{ i18n.t('full_time_short') }} {{ bet.fullTimeScore.home }}:{{ bet.fullTimeScore.away }}
            </span>
        </template>
    </div>
</template>

<script lang="ts" setup>
    import { computed, toRefs } from 'vue';
    import { i18n } from '@/core/lib/i18n';
    import { nextGoalMarketGroups } from '@/core/lib/oddsHelper';
    import type { IBaseBet } from '@/interface/IBet';
    import type { Score } from '@/interface/ILiveScore';
    import { SportType } from '@/interface/enum';

    const props = defineProps<{
        bet: IBaseBet;
    }>();

    const { bet } = toRefs(props);

    const isNextGoal = computed(() => nextGoalMarketGroups.includes(bet.value.betTypeGroup.id));
    const hasHTScore = computed(() => hasScore(bet.value.halfTimeScore));
    const hasFTScore = computed(() => hasScore(bet.value.fullTimeScore));
    const isShowResult = computed(() => bet.value.isSettled
        && bet.value.sportType === SportType.Soccer
        && (hasHTScore.value
            || hasFTScore.value));

    function hasScore(score: Score) {
        return score.home !== -1 && score.away !== -1;
    }

</script>

<style lang="scss" scoped>
    @import "@/components/myBets/Result";
</style>
