<template>
    <Tabs
        v-model:isExpand="isBetSlipExpanded"
        v-gtm:type="'BetSlip'"
        class="component"
    >
        <Tab
            v-gtm:label="isBetSlipExpanded ? 'Collapse' : 'Expand'"
            v-gtm:@click
            name="betSlip"
            icon="icon-system-ticket"
            v-bind:label="i18n.t('block_header_bet_slip')"
            class="notranslate"
        />

        <template #betSlip>
            <SubTabs
                v-model="betSlipTab"
                style="height: 100%;"
            >
                <SubTab
                    v-bind:name="TabName.Single"
                    v-bind:label="i18n.t('single')"
                    showIndicator
                    class="notranslate"
                />
                <SubTab
                    v-bind:name="TabName.Parlay"
                    v-bind:label="i18n.t('parlay')"
                    showIndicator
                    class="notranslate"
                />
                <template #Single>
                    <SingleBlockEuro />
                </template>
                <template #Parlay>
                    <ParlayBlockEuro />
                </template>
            </SubTabs>
        </template>
    </Tabs>
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import { ref } from 'vue';
    import ParlayBlockEuro from '@/components/betSlip/ParlayBlockEuro.vue';
    import SingleBlockEuro from '@/components/betSlip/SingleBlock.Euro.vue';
    import SubTab from '@/components/subTabs/SubTab.vue';
    import SubTabs from '@/components/subTabs/SubTabs.vue';
    import Tab from '@/components/tabs/Tab.vue';
    import Tabs from '@/components/tabs/Tabs.vue';
    import { i18n } from '@/core/lib/i18n';
    import { TabName } from '@/interface/enum';
    import { useEuroBetSlipStore } from '@/store/betSlipStore.euro';

    const isBetSlipExpanded = ref(true);
    const { betSlipTab } = storeToRefs(useEuroBetSlipStore());
</script>
