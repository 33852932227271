import { useEventListener } from '@vueuse/core';
import type { CSSProperties } from 'vue';
import { computed, onMounted, ref } from 'vue';
import { useToggle } from '@/composable/useToggle';

export function useLiveStreamExpand() {
    const [isExpand, _toggleIsExpand] = useToggle(true);

    const windowWidth = ref(window.innerWidth);
    const windowHeight = ref(window.innerHeight);
    useEventListener('resize', () => {
        windowWidth.value = window.innerWidth;
        windowHeight.value = window.innerHeight;
    }, { passive: true });

    const rightPanelWidth = ref(366);
    const leftPanelWidth = computed(() => (isExpand.value
        ? windowWidth.value - rightPanelWidth.value
        : windowWidth.value));

    const rightPanelEl = ref<HTMLElement | null>(null);

    const toggleExpand = async () => {
        _toggleIsExpand();

        // we will only compare the width of the window here
        // so full-width window and full-screen window won't be resized
        const isFullWidth = window.screen.width === window.innerWidth || window.screen.width === window.outerWidth;

        if (!isFullWidth) {
            window.resizeTo(
                window.outerWidth + (isExpand.value ? rightPanelWidth.value : -rightPanelWidth.value),
                window.outerHeight,
            );
        }
    };

    const onResizeRightPanel = () => {
        if (!rightPanelEl.value) return;

        const rect = rightPanelEl.value.getBoundingClientRect();
        rightPanelWidth.value = rect.width;
    };

    onMounted(onResizeRightPanel);

    const leftPanelStyle = computed<CSSProperties>(() => ({
        minWidth: `${leftPanelWidth.value}px`,
        maxHeight: `${windowHeight.value}px`,
    }));

    const rightPanelStyle = computed<CSSProperties>(() => ({
        height: `${windowHeight.value}px`,
    }));

    return {
        isExpand,
        toggleExpand,
        leftPanelStyle,
        rightPanelStyle,
        rightPanelEl,
        onResizeRightPanel,
    };
}
