import type { OutrightTournamentTypeEuroFragment, OutrightTournamentTypeFragment } from '@/core/oddsApi/oddsApiType';
import { EnumTournamentSizeType } from '@/core/oddsApi/oddsApiType';
import type { IOutrightLeague, IOutrightLeagueEuro } from '@/interface/IOutrightLeague';
import type { SportType } from '@/interface/enum';
import { LeagueSize } from '@/interface/enum';

export function formatOutrightLeague(
    item: OutrightTournamentTypeFragment,
    sportType: SportType,
    isLive: boolean,
): IOutrightLeague {
    const { tournament, eventDate, oddsCount } = item;
    return {
        id: tournament?.id ?? 0,
        name: tournament?.tournamentName ?? '',
        info: tournament?.tournamentInfo ?? '',
        leagueCode: tournament?.tournamentCode ?? '',
        size: LeagueSize.fromOddsApi(tournament?.tournamentSize ?? EnumTournamentSizeType.Small),
        eventDate,
        sportType,
        isLive,
        oddsCount,
    };
}

export function formatOutrightLeagueEuro(
    item: OutrightTournamentTypeEuroFragment,
    sportType: SportType,
): IOutrightLeagueEuro {
    const { tournament, eventDate, oddsCount, isLive } = item;
    return {
        id: tournament?.id ?? 0,
        name: tournament?.tournamentName ?? '',
        info: tournament?.tournamentInfo ?? '',
        leagueCode: tournament?.tournamentCode ?? '',
        size: LeagueSize.fromOddsApi(tournament?.tournamentSize ?? EnumTournamentSizeType.Small),
        eventDate,
        sportType,
        isLive,
        oddsCount,
    };
}
