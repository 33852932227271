<template>
    <div
        v-gtm:label="isSelected ? 'BbOdds_Unclick' : 'BbOdds'"
        v-gtm:@click
        v-debug:selectionId="selection.id"
        class="selection"
        v-bind:class="{
            _oddsChange: isSelectionAvailable && !isLoading && isShowPrice && (isPriceRise || isPriceDrop),
            _disabled: isDisabled,
            _suspended: isSuspend,
            _picked: isSelectionAvailable && isSelected,
            _onlyTag: isOnlyShowTag,
            _vertical: isVertical,
        }"
        v-on:click="handleClick"
    >
        <Skeleton
            v-if="!isSelected && isLoading"
        />
        <SvgIcon
            v-else-if="isSuspend"
            v-bind:size="isVertical ? 'md' : 'sm'"
            name="icon-system-odds-lock"
        />
        <template v-else>
            <div class="oddsTag">
                {{ displayTag }}
                {{ displayPoint }}
            </div>
            <div v-if="isShowPrice" class="oddsArea">
                <div class="odds">
                    <div
                        class="oddsValue"
                        v-bind:style="{ textAlign: align }"
                    >
                        {{ priceDisplay }}
                    </div>
                </div>
                <div class="oddsStates" v-bind:class="priceChangeStates">
                    <div v-show="priceChangeStates" class="triangle" />
                </div>
            </div>
        </template>
    </div>
</template>

<script lang="ts" setup>
    import { toRefs } from '@vueuse/core';
    import { storeToRefs } from 'pinia';
    import type { PropType } from 'vue';
    import { computed } from 'vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import Skeleton from '@/components/common/skeleton/Skeleton.vue';
    import { OddsDisplayContext } from '@/components/oddsDisplay/OddsDisplayContext';
    import { getMarketRule } from '@/components/oddsDisplay/moreMarket/betBuilderMarketRules';
    import { useBetPage } from '@/composable/useBetPage';
    import { usePriceChangeStatus } from '@/composable/usePriceChangeStatus';
    import { formatMinDecimalPlace } from '@/core/lib/numberFormat';
    import { formatPoint } from '@/core/lib/oddsHelper';
    import type { IBetBuilderMarket, IBetBuilderTicket, ISelection } from '@/interface/betBuilder';
    import { BetBuilderSelectionStatus, PriceChangeStatus, TabName } from '@/interface/enum';
    import { useBetSlipStore } from '@/store/betSlipStore';
    import { useLayoutStore } from '@/store/layoutStore';

    const props = defineProps({
        matchId: {
            type: Number,
            required: true,
        },
        market: {
            type: Object as PropType<IBetBuilderMarket>,
            required: true,
        },
        selection: {
            type: Object as PropType<ISelection>,
            required: true,
        },
        align: {
            type: String as PropType<'left' | 'center' | 'right'>,
            default: 'right',
        },
        orientation: {
            type: String as PropType<'vertical' | 'horizontal'>,
            default: 'horizontal',
        },
    });

    const { matchId, market, selection } = toRefs(props);
    const { betBuilderBetSlip } = storeToRefs(useBetSlipStore());
    const { addToBetBuilderBetSlip, removeBetBuilderTicket } = useBetSlipStore();

    const isMatchInBetSlip = computed(() => betBuilderBetSlip.value?.tickets.some(ticket => ticket.match.id === matchId.value) ?? false);

    const selectionTicket = computed<IBetBuilderTicket | null>(() => {
        if (!betBuilderBetSlip.value || !isMatchInBetSlip.value) return null;

        return betBuilderBetSlip.value.tickets.find(betSlipSelection => betSlipSelection.selection.id === selection.value.id) ?? null;
    });
    const isSelected = computed(() => !!selectionTicket.value);

    const isLoading = computed(() => isMatchInBetSlip.value && betBuilderBetSlip.value?.updatingStatus === 'manual');

    const availableSelections = computed(() => betBuilderBetSlip.value?.markets
        .find(m => m.matchMarketId === market.value.matchMarketId)?.selections
        .find(s => s.id === selection.value.id && s.isCompatible && s.status === BetBuilderSelectionStatus.Running));

    const isDisabled = computed(() => {
        if (isSelected.value) return false;

        if (selection.value.status === BetBuilderSelectionStatus.Incompatible) return true;

        if (!isMatchInBetSlip.value) return false;

        return !availableSelections.value;
    });

    const isSuspend = computed(() => {
        if (isDisabled.value) return false;
        return selection.value.status === BetBuilderSelectionStatus.Suspended;
    });

    const isSelectionAvailable = computed(() => !isSuspend.value && !isDisabled.value);

    const marketRule = computed(() => getMarketRule(market.value));
    const minDecimalPlace = computed(() => marketRule.value.minDecimalPlace);
    const price = computed<number | null>(() => {
        const value = availableSelections.value?.price?.value ?? null;
        if (value === 0) return null;
        return value;
    });
    const priceDisplay = computed(() => formatMinDecimalPlace(price.value ?? 0, minDecimalPlace.value));
    const isShowPrice = computed(() => !isDisabled.value && !isSuspend.value && !isSelected.value && price.value !== null);

    const { priceChangeStatus } = usePriceChangeStatus(price);
    const isPriceRise = computed(() => priceChangeStatus.value === PriceChangeStatus.Rise);
    const isPriceDrop = computed(() => priceChangeStatus.value === PriceChangeStatus.Drop);
    const priceChangeStates = computed(() => {
        if (isSuspend.value) return '';
        if (isPriceRise.value) return 'oddsStates-rise';
        if (isPriceDrop.value) return 'oddsStates-drop';
        return '';
    });

    const isVertical = computed(() => props.orientation === 'vertical');
    const displayPoint = computed(() => {
        if (!isVertical.value) return '';
        if (market.value.point !== 0) return formatPoint(market.value.point);

        return '';
    });

    const shortTag = computed(() => marketRule.value.tagFormatter({
        market: market.value,
        selection: selection.value,
    }));
    const longTag = computed(() => marketRule.value.optionFormatter({
        market: market.value,
        selection: selection.value,
    }));
    const displayTag = computed(() => {
        const short = shortTag.value;
        const long = longTag.value;

        if (isDisabled.value || isSelected.value || isVertical.value) {
            return displayPoint.value ? short : long;
        }

        return price.value !== null ? short : long;
    });
    const isOnlyShowTag = computed(() => isSelected.value || isDisabled.value || !price.value);

    const { isLayoutExpanded } = storeToRefs(useLayoutStore());
    const { toggleIsSportListExpanded, toggleBetSlipExpanded, toggleMyBetsExpanded, setBetSlipActiveTab } = useLayoutStore();

    const { eventCache } = OddsDisplayContext.inject();
    const { betPage } = useBetPage();

    function handleClick() {
        if (!isSelectionAvailable.value) return;
        if (isLoading.value) return;

        const event = eventCache.value.get(matchId.value)!;
        setBetSlipActiveTab(TabName.Single);
        if (selectionTicket.value) {
            removeBetBuilderTicket(selectionTicket.value?.key);
            return;
        }

        addToBetBuilderBetSlip(event, market.value, selection.value, betPage.value);
        toggleIsSportListExpanded(false);
        toggleBetSlipExpanded(true);
        if (!isLayoutExpanded.value) toggleMyBetsExpanded(false);
    }
</script>

<style lang="scss" scoped>
    @import "@/components/odds/Odds";
</style>
