import type { Ref } from 'vue';
import { computed } from 'vue';
import { deepCompareComputed } from '@/composable/deepCompareComputed';
import { useBetBuilderMatchExistsQuery } from '@/core/betBuilder/betBuilderType';

export function useBetBuilderExists(matchId: number, isEnabled: Ref<boolean>) {
    const { result } = useBetBuilderMatchExistsQuery(
        { matchId },
        deepCompareComputed(() => ({
            pollInterval: 30000,
            enabled: isEnabled.value,
        })),
    );
    const isBetBuilderMatchExist = computed(() => {
        if (!isEnabled.value) return false;

        return result.value;
    });

    return {
        isBetBuilderMatchExist,
    };
}
