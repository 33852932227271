import { tryOnScopeDispose } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import type { Ref } from 'vue';
import { computed, ref, watch } from 'vue';
import { useState } from '@/composable/useState';
import { PriceChangeStatus } from '@/interface/enum';
import { useCustomerStore } from '@/store/customerStore';

function convertToHKOdds(price: number) {
    if (price < 0) {
        return -1 / price;
    }
    return price;
}

export const usePriceChangeStatusEuro = (price: Ref<number>, enableReset: Ref<boolean>) => {
    const { priceStyle } = storeToRefs(useCustomerStore());
    const prevPriceStyle = ref(priceStyle.value);

    const [priceChangeStatus, setPriceChangeStatus] = useState(PriceChangeStatus.Remain);

    watch(price, (newVal, oldVal) => {
        // Do not show price change status when price style changed
        if (prevPriceStyle.value !== priceStyle.value) {
            prevPriceStyle.value = priceStyle.value;
            return;
        }

        const oldPrice = convertToHKOdds(oldVal);
        const newPrice = convertToHKOdds(newVal);

        if (oldPrice < newPrice) {
            setPriceChangeStatus(PriceChangeStatus.Rise);
        } else if (oldPrice > newPrice) {
            setPriceChangeStatus(PriceChangeStatus.Drop);
        }
    });

    const isPriceChange = computed(() => priceChangeStatus.value !== PriceChangeStatus.Remain);

    let timeoutId: number;
    watch(isPriceChange, (newVal) => {
        window.clearTimeout(timeoutId);

        if (newVal) {
            timeoutId = window.setTimeout(() => {
                if (enableReset.value) {
                    setPriceChangeStatus(PriceChangeStatus.Remain);
                }
            }, 3000);
        }
    });

    tryOnScopeDispose(() => {
        window.clearTimeout(timeoutId);
    });

    return {
        priceChangeStatus,
        isPriceChange,
    };
};
