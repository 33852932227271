<template>
    <svg
        class="jersey"
        v-bind:class="{ 'none-color': jersey.type <= 0 }"
        width="43"
        height="32"
        viewBox="0 0 43 32"
    >
        <template v-if="jersey.type > 0 && jersey.type <= 4">
            <path v-bind:fill="jerseyColor.body" d="M29 0h-14.8l-14 10.1 4.6 6.3 6.7-4.8v20.4h20.2v-20.4l6.5 4.8 4.6-6.3z" />
            <path v-bind:fill="jerseyColor.leftSleeve" d="M0.2 10.1l4.6 6.3 6.6-4.8v-9.6l-11.2 8.1z" />
            <path v-bind:fill="jerseyColor.rightSleeve" d="M31.6 2v9.6l6.6 4.8 4.6-6.3-11.2-8.1z" />
        </template>
        <template v-if="jersey.type > 4">
            <path v-bind:fill="jerseyColor.body" d="M30.7 8.8v-8.8h-18.3v8.8c0 1.2-1.7 2.3-2.7 2.7v20.5h23.6v-20.5c-1-0.4-2.6-1.4-2.6-2.7z" />
        </template>
        <template v-if="jersey.type === 2">
            <path v-bind:fill="jerseyColor.stripe" d="M21.5 0h-7.3l-2.7 2v30h10z" />
        </template>
        <template v-if="jersey.type === 3">
            <path v-bind:fill="jerseyColor.stripe" d="M21.6 3.9c-0.9 0-1.8-0.1-2.6-0.4v28.5h5.1v-28.4c-0.8 0.2-1.6 0.3-2.5 0.3z" />
            <path v-bind:fill="jerseyColor.stripe" d="M11.4 2v30h2.6v-31.9z" />
            <path v-bind:fill="jerseyColor.stripe" d="M29.1 0.1v31.9h2.5v-30c0 0-2.2-1.6-2.5-1.9z" />
            <path v-bind:fill="jerseyColor.stripe" d="M27.7 0c-0.8 1.9-3.4 3.3-6.3 3.3s-5.6-1.4-6.3-3.3h-0.6c0.8 2.3 3.7 3.9 7 3.9s6.1-1.6 7-3.9h-0.8z" />
        </template>
        <template v-if="jersey.type === 4">
            <path v-bind:fill="jerseyColor.stripe" d="M11.4 20.4h20.2v4.1h-20.2v-4.1z" />
            <path v-bind:fill="jerseyColor.stripe" d="M11.4 12.7h20.2v4.1h-20.2v-4.1z" />
        </template>
        <template v-if="jersey.type === 6">
            <path v-bind:fill="jerseyColor.stripe" d="M9.7 11.5v20.5h2.8v-22.1c-0.7 0.7-1.9 1.3-2.8 1.6z" />
            <path v-bind:fill="jerseyColor.stripe" d="M33.3 11.5v20.5h-2.8v-22.1c0.7 0.7 1.9 1.3 2.8 1.6z" />
        </template>
        <template v-if="jersey.type === 7">
            <path v-bind:fill="jerseyColor.stripe" d="M21.6 3.9c-0.9 0-1.8-0.1-2.6-0.4v28.5h5.1v-28.4c-0.8 0.2-1.6 0.3-2.5 0.3z" />
            <path v-bind:fill="jerseyColor.stripe" d="M9.7 11.5v20.5h2.8v-22.1c-0.7 0.7-1.9 1.3-2.8 1.6z" />
            <path v-bind:fill="jerseyColor.stripe" d="M33.3 11.5v20.5h-2.8v-22.1c0.7 0.7 1.9 1.3 2.8 1.6z" />
            <path v-bind:fill="jerseyColor.stripe" d="M27.7 0c-0.8 1.9-3.4 3.3-6.3 3.3s-5.6-1.4-6.3-3.3h-0.6c0.8 2.3 3.7 3.9 7 3.9s6.1-1.6 7-3.9h-0.8z" />
        </template>
        <template v-if="jersey.type === 8">
            <path v-bind:fill="jerseyColor.stripe" d="M9.7 13.3h23.6v3h-23.6v-3z" />
        </template>
        <template v-if="jersey.type === 9">
            <path v-bind:fill="jerseyColor.stripe" d="M9.7 13.3h23.6v3h-23.6v-3z" />
            <path v-bind:fill="jerseyColor.stripe" d="M9.7 20.9h23.6v3h-23.6v-3z" />
        </template>
        <path fill="#c9c7c8" d="M21.5 2.8c2.7 0 5.1-1.2 5.8-2.8h-11.7c0.7 1.6 3.1 2.8 5.9 2.8v0z" />

        <path
            v-if="jersey.type <= 4"
            fill="#2d4694"
            d="M28.7 0.5l13.4 9.7-4 5.5-6.5-4.7v20.5h-20.2v-20.5l-6.5 4.7-4-5.5 13.4-9.7h14.4zM28.9 0h-14.8l-13.9 10.1 4.6 6.3 6.1-4.5v20h21.2v-20l6.1 4.5 4.6-6.3-13.9-10.1z"
        />
        <path
            v-if="jersey.type > 4"
            fill="#2d4694"
            d="M30.2 0.5v8.3c0 1.4 1.3 2.4 2.6 3v19.7h-22.6v-19.7c1.1-0.5 2.7-1.6 2.7-3v-8.3h17.3zM30.7 0h-18.3v8.8c0 1.2-1.7 2.3-2.7 2.7v20.5h23.6v-20.5c-1-0.4-2.6-1.4-2.6-2.7v-8.8z"
        />
    </svg>
</template>

<script lang="ts" setup>
    import type { PropType } from 'vue';
    import { computed } from 'vue';
    import type { IJersey } from '@/interface/IJersey';

    const props = defineProps({
        jersey: {
            type: Object as PropType<IJersey>,
            default: () => ({
                type: 0,
            } as IJersey),
        },
    });

    const jerseyColor = computed(() => {
        const defaultColor = '#C3CCD8';
        const { jersey } = props;
        return {
            leftSleeve: jersey.leftSleeve ?? defaultColor,
            rightSleeve: jersey.rightSleeve ?? defaultColor,
            body: jersey.body ?? defaultColor,
            stripe: jersey.stripe ?? defaultColor,
        };
    });
</script>

<style scoped>
.jersey {
    transform: scale(0.8);
}

.none-color {
    opacity: 0.3;
}
</style>
