/* eslint-disable no-nested-ternary */
import { tryOnUnmounted, whenever } from '@vueuse/core';
import type { CookieAttributes } from 'js-cookie';
import Cookie from 'js-cookie';
import type { Ref } from 'vue';
import { reactive, ref, watch } from 'vue';
import type { Serializer } from '@/composable/useStorage';
import { getDefaultSerializer } from '@/composable/useStorage';

type CookieOptions<T> = Serializer<T> & Omit<CookieAttributes, 'expires'> & {
    expires?: number | Date | (() => Date);
}

function tryParseFn<T>(decode: (input: string) => T) {
    return (str: string | null, defaultValue: T): T => {
        try {
            if (str === '' || str === null || str === undefined) {
                return defaultValue;
            }

            return decode(str);
        } catch (err) {
            console.error('useCookie parse failed with value:', str, err);
            return defaultValue;
        }
    };
}
let _id = 1;
const intervalId = ref(0);

const cookieSubscriber = reactive(new Map<number, [string, Ref<string>]>());
if (!__ENV_TEST__) {
    whenever(() => cookieSubscriber.size > 0 && intervalId.value === 0, () => {
        intervalId.value = window.setInterval(() => updateRawValue(), 250);
    });

    whenever(() => cookieSubscriber.size === 0 && intervalId.value !== 0, () => {
        window.clearInterval(intervalId.value);
        intervalId.value = 0;
    });
}

let prevCookie = '';
function updateRawValue() {
    if (window.document.cookie === prevCookie) return;
    prevCookie = window.document.cookie;

    cookieSubscriber.forEach(([key, rawRef]) => {
        const value = Cookie.get(key) ?? '';
        if (value !== rawRef.value) {
            rawRef.value = value;
        }
    });
}

/**
* @description Reactive cookie which will bi-directionally sync with the browser's cookie, cross-tab supported.
* @param key - cookie's key
* @param defaultValue - default value when cookie is not found or parse failed
* @param options.encode - Function to encode the cookie value. If omitted, will auto pick the best encoder.
* @param options.decode - Function to decode the cookie value. If omitted, will auto pick the best decoder.
* @param options.expires - Define when the cookie will be removed. Value can be a number of million seconds from now (e.g. 60*1000), a JavaScript Date object, or a function that returns cookie's expiration date. If omitted, the cookie becomes a session cookie.
* @param options.path - Define the path where the cookie is available. Defaults to '/'
* @param options.domain - Define the domain where the cookie is available. Defaults to the domain of the page where the cookie was created.
* @param options.secure - If true, the cookie will only be available through a secured connection. Defaults to false.
* @param options.sameSite - If true, the cookie will be available for cross-origin requests. If false, the cookie will be available for the current origin only. If omitted, the cookie is available for cross-origin requests.
* @returns [valueRef, setValue]
 */
export function useCookie<T>(key: string, defaultValue: T, options?: CookieOptions<T>): [Ref<T>, (newVal: T) => void] {
    const defaultSerializer = getDefaultSerializer(defaultValue);
    const decode = options?.decode ?? defaultSerializer.decode;
    const encode = options?.encode ?? defaultSerializer.encode;

    const id = _id++;
    const rawRef = ref(Cookie.get(key) ?? '');
    cookieSubscriber.set(id, [key, rawRef]);
    tryOnUnmounted(() => cookieSubscriber.delete(id));

    const tryParse = tryParseFn(decode);
    const value = ref(tryParse(rawRef.value, defaultValue));
    watch(rawRef, (newVal, oldVal) => {
        if (newVal !== oldVal) {
            value.value = tryParse(newVal, defaultValue);
        }
    }, { flush: 'sync' });

    const setCookie = (newValue: T) => {
        const encoded = encode(newValue);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { expires, decode: _1, encode: _2, ...cookieOptions } = options ?? {};
        const expiresValue = expires instanceof Function
            ? expires()
            : typeof expires === 'number'
                ? new Date(Date.now() + expires)
                : expires;
        if (encoded !== Cookie.get(key)) {
            Cookie.set(key, encoded, {
                ...cookieOptions,
                expires: expiresValue,
            });
            updateRawValue();
        }
    };

    const setValue = (newValue: T) => {
        setCookie(newValue);
    };

    return [value, setValue];
}
