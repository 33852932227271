<template>
    <div class="marketGroupWrapper">
        <MatchLoading v-if="!loaded" />
        <NoData
            v-else-if="!markets.length"
            v-bind:dataType="NoDataType.Odds"
            borderRadius
        />
        <template v-else>
            <MatchDetailMarket
                v-for="market in markets"
                v-bind:key="market.key"
                v-bind:market="market"
                v-bind:event="event"
            />
        </template>
    </div>
</template>

<script setup lang="ts">
    import { groupBy, orderBy, toPairs } from 'lodash';
    import { storeToRefs } from 'pinia';
    import { computed } from 'vue';
    import { EuroDisplayContext } from '@/components/euroDisplay/EuroDisplayContext';
    import MatchLoading from '@/components/euroDisplay/MatchLoading.vue';
    import NoData from '@/components/euroDisplay/NoData.vue';
    import { mainMarketOrder, nbtMarketOrder } from '@/components/euroDisplay/marketOrder';
    import MatchDetailMarket from '@/components/euroDisplay/oddsDisplay/MatchDetailMarket.vue';
    import { isMainMarket } from '@/core/lib/oddsHelper';
    import { useEventOddsEuro } from '@/core/oddsApi/composable/useEventOdds.euro';
    import { EnumOddsMarketFilterType } from '@/core/oddsApi/oddsApiType';
    import type { IEventEuro } from '@/interface/IEvent';
    import { NoDataType } from '@/interface/enum';
    import { useCustomerStore } from '@/store/customerStore';

    const props = defineProps<{
        event: IEventEuro;
    }>();

    const { priceStyle } = storeToRefs(useCustomerStore());
    const { isMultiples } = EuroDisplayContext.inject();

    const { oddsList, loaded } = useEventOddsEuro({
        eventId: props.event.id,
        isLive: props.event.isLive,
        isMixParlay: isMultiples.value,
        marketFilter: EnumOddsMarketFilterType.All,
        priceStyle,
    });

    const markets = computed(() => {
        const sorted = orderBy(
            oddsList.value,
            [
                o => o.eventResult.marketGroup.displayPriority,
                o => (isMainMarket(o.eventResult.marketGroup.id)
                    ? mainMarketOrder.indexOf(o.marketType)
                    : nbtMarketOrder.indexOf(o.marketType)),
                o => o.sortOrder,
            ],
            ['asc', 'asc', 'desc'],
        );

        const grouped = groupBy(sorted, o => `${o.eventResult.marketGroup.id}-${o.marketType}`);

        return toPairs(grouped)
            .map(([key, oddsList]) => ({
                key,
                marketGroup: oddsList[0].eventResult.marketGroup,
                marketType: oddsList[0].marketType,
                odds: oddsList,
            }));
    });
</script>

<style lang="scss" scoped>
@import "@/components/euroDisplay/MatchDetailOddsDisplay";
</style>
