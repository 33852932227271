<template>
    <SvgIcon
        v-if="sboCurrencyIcon"
        v-bind:size="size"
        v-bind:name="`icon-currency-${sboCurrencyIcon}`"
    />
    <template v-else>
        {{ sboCurrency }}
    </template>
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import { computed } from 'vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import { SboCurrency } from '@/interface/enum';
    import { useCustomerStore } from '@/store/customerStore';

    defineProps({
        // can be size: sm, md, lg, ...
        // or size in pixels: 16, 24, 32, ...
        size: {
            type: [Number, String],
            default: () => 'default',
        },
    });

    const { sboCurrency } = storeToRefs(useCustomerStore());

    const sboCurrencyIcon = computed(() => {
        switch (sboCurrency.value) {
            case SboCurrency.AUD:
            case SboCurrency.BND:
            case SboCurrency.CAD:
            case SboCurrency.HKD:
            case SboCurrency.HK$:
            case SboCurrency.NZD:
            case SboCurrency.SD$:
            case SboCurrency.USD:
            case SboCurrency.US$:
            case SboCurrency.ZWD:
                return '1';
            case SboCurrency.CNY:
            case SboCurrency.YEN:
            case SboCurrency.RMB:
                return '2';
            case SboCurrency.PP:
                return '3';
            case SboCurrency.INR:
                return '4';
            case SboCurrency.BDT:
                return '5';
            case SboCurrency.EURO:
                return '6';
            case SboCurrency.GBP:
                return '7';
            case SboCurrency.KRW:
                return '8';
            case SboCurrency.LKR:
                return '9';
            case SboCurrency.PHP:
                return '10';
            case SboCurrency.TB:
                return '11';
            case SboCurrency.VND:
                return '12';
            case SboCurrency.MMK:
                return '13';
            case SboCurrency.ZAR:
                return '14';
            case SboCurrency.NOK:
            case SboCurrency.SEK:
                return '15';
            case SboCurrency.CHF:
                return '16';
            case SboCurrency.MYR:
            case SboCurrency.RM:
                return '17';
            case SboCurrency.NIO:
                return '18';
            case SboCurrency.USDT:
                return '19';
            default:
                return '';
        }
    });
</script>
