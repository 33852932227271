import { useThrottleFn } from '@vueuse/core';
import cloneDeep from 'lodash/fp/cloneDeep';
import flow from 'lodash/fp/flow';
import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useLiveStream } from '@/composable/useLiveStream';
import { useState } from '@/composable/useState';
import { Api } from '@/core/lib/api';
import { useStreamEvents } from '@/core/oddsApi/composable/useStreamEvents';
import type { IStreamEvent } from '@/interface/IStream';
import { SportType } from '@/interface/enum';

export const useLiveStreamStore = defineStore('LiveStream', () => {
    const { streamEvents, loaded, refetchStreamEvents } = useStreamEvents();

    const hasStreamPipe = (streams: IStreamEvent[]) => streams.filter((stream) => {
        const { isLiveStreamAvailable } = useLiveStream(ref(stream));
        return isLiveStreamAvailable.value;
    });

    const processFlow = (streams: IStreamEvent[]) => flow(
        cloneDeep,
        hasStreamPipe,
    )(streams);

    const allStreamEvents = computed<IStreamEvent[]>(
        () => processFlow(streamEvents.value),
    );

    const [selectedEventId, setSelectedEventId] = useState(0);
    const selectedStreamEvent = ref<IStreamEvent | null>(null);
    watch([selectedEventId, allStreamEvents], () => {
        const newVal = allStreamEvents.value.find(event => event.eventId === selectedEventId.value);

        // prevent video disappear right after event been closed
        if (newVal) {
            selectedStreamEvent.value = newVal;
        }
    });

    const [myEventIds, setMyEventIds] = useState<number[]>([]);
    const refreshMyEventIds = async () => {
        try {
            const { data } = await Api.getMyEventIds();
            setMyEventIds(data);
        } catch (error) {
            console.error(error);
        }
    };

    const refreshMyEventIdsThrottled = useThrottleFn(refreshMyEventIds, 5000, false, true);
    const refetchStreamEventsThrottled = useThrottleFn(refetchStreamEvents, 5000, false, true);

    refreshMyEventIds();

    const [selectedSport, setSelectSport] = useState(SportType.Unknown);
    const filteredStreamEvents = computed(() => {
        if (selectedSport.value === SportType.Unknown) return allStreamEvents.value;
        return allStreamEvents.value.filter(event => event.sportType === selectedSport.value);
    });

    const filteredMyStreamEvents = computed(() => filteredStreamEvents.value.filter(event => myEventIds.value.includes(event.eventId)));
    return {
        allStreamEvents,
        selectedStreamEvent,
        loaded,
        selectedEventId,
        selectedSport,
        filteredStreamEvents,
        filteredMyStreamEvents,
        refreshMyEventIds,
        refreshMyEventIdsThrottled,
        setSelectSport,
        setSelectedEventId,
        refetchStreamEvents,
        refetchStreamEventsThrottled,
    };
});
