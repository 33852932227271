<template>
    <Tabs
        v-gtm:type="'Information'"
        class="wrapper"
        v-bind:enableCollapse="false"
    >
        <Tab
            name="extraLinks"
            v-bind:label="i18n.t('information')"
            icon="icon-system-open-link"
        />
        <template #extraLinks>
            <SportList
                v-bind:items="extraList"
            />
        </template>
    </Tabs>
</template>

<script lang="ts" setup>
    import { computed } from 'vue';
    import SportList from '@/components/sportList/SportList.Euro.vue';
    import Tab from '@/components/tabs/Tab.vue';
    import Tabs from '@/components/tabs/Tabs.vue';
    import { i18n } from '@/core/lib/i18n';
    import type { ISportListItem } from '@/interface/ISport';

    const extraList = computed((): ISportListItem[] => [
        {
            name: i18n.t('results'),
            icon: 'icon-system-result-outline',
            count: 0,
            liveCount: 0,
            nonLiveCount: 0,
            todayExpandedCount: 0,
            isShowCount: false,
            isHot: false,
            isLive: false,
            isActive: false,
            onClick: () => {
                window.open('/results', 'Results', 'width=830,height=600');
            },
            gtmLabel: 'Results',
        },
    ]);

</script>

<style lang="scss" scoped>
    @import "@/components/sportList/SportListBlock.Euro";
</style>
