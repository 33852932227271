import type { Directive, DirectiveBinding } from 'vue';

export type SizeInfo = {
    width: number;
    height: number;
};

type ResizeHandler = (size: SizeInfo) => void;
interface ResizeDirectiveBinding extends Omit<DirectiveBinding, 'modifiers'> {
    value: ResizeHandler;
}

type ResizeElement = HTMLElement & { __ResizeHandler: ResizeHandler[] };

const observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
    if (!entries.length) return;

    entries.forEach((entry) => {
        const element = entry.target as ResizeElement;
        if (!element.__ResizeHandler) return;
        const size = { width: entry.contentRect.width, height: entry.contentRect.height };
        element.__ResizeHandler.forEach(cb => cb(size));
    });
});

function addObserver(el: ResizeElement, handler: ResizeHandler) {
    if (!el.__ResizeHandler) el.__ResizeHandler = [];
    el.__ResizeHandler.push(handler);
    observer.observe(el);
}

function removeObserver(el: ResizeElement, handler: ResizeHandler) {
    if (!el.__ResizeHandler || el.__ResizeHandler.length === 0) return;
    el.__ResizeHandler = el.__ResizeHandler.filter(cb => cb !== handler);
    if (el.__ResizeHandler.length === 0) observer.unobserve(el);
}

function checkBindingFunction(value: unknown) {
    if (typeof value !== 'function') throw new Error('v-resize must be provided a function');
}

export const ResizeDirective: Directive = {
    beforeMount: (el: ResizeElement, binding: ResizeDirectiveBinding) => {
        checkBindingFunction(binding.value);
        addObserver(el, binding.value);
    },
    updated: (el: ResizeElement, binding: ResizeDirectiveBinding) => {
        if (binding.oldValue === binding.value) return;

        removeObserver(el, binding.oldValue);
        checkBindingFunction(binding.value);
        addObserver(el, binding.value);
    },
    beforeUnmount: (el: ResizeElement, binding: ResizeDirectiveBinding) => {
        removeObserver(el, binding.value);
    },
};
