<template>
    <StatusTag
        v-bind:text="i18n.t(`bet_status_${status}`)"
        v-bind:level="betStatusStyle"
        size="sm"
    />
</template>

<script lang="ts" setup>
    import type { PropType } from 'vue';
    import { computed } from 'vue';
    import StatusTag from '@/components/common/statusTag/StatusTag.vue';
    import { invalidMainBetStatusList } from '@/components/myBets/betHelper';
    import { i18n } from '@/core/lib/i18n';
    import { MainBetStatus } from '@/interface/enum';

    const props = defineProps({
        status: {
            type: Number as PropType<MainBetStatus>,
            required: true,
        },
    });

    const betStatusStyle = computed(() => {
        if (invalidMainBetStatusList.includes(props.status)) return 'danger';
        if (props.status === MainBetStatus.Waiting) return 'warning';
        return 'info';
    });
</script>
