<template>
    <GroupTitle
        v-bind:isExpanded="isExpanded"
        v-bind:viewAll="viewAll ? viewAll(events) : undefined"
        v-bind:expandedGtmLabel="GetCollapseGtmLabelName(isExpanded)"
        v-bind:class="{
            cricket: isCricket,
        }"
        v-on:update:isExpanded="emit('update:isExpanded', $event)"
    >
        <template #title>
            <strong class="nameItem">{{ title }}</strong>
            <SvgIcon
                v-if="info"
                v-tooltip="info"
                name="icon-system-cycle-info"
                right
            />
        </template>
        <template #content>
            <div class="back">
                <div class="marketHeader">
                    <div
                        v-for="header in market.headers"
                        v-bind:key="header"
                        class="marketHeaderCol"
                    >
                        <strong>{{ header }}</strong>
                    </div>
                </div>
            </div>
        </template>
    </GroupTitle>
    <template v-if="isExpanded">
        <Match
            v-for="event in events"
            v-bind:key="event.id"
            v-bind:event="event"
        />
    </template>
</template>

<script setup lang="ts">
    import { toRefs } from 'vue';
    import type { RouteLocationNamedRaw } from 'vue-router';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import GroupTitle from '@/components/common/groupTitle/GroupTitle.vue';
    import { EuroDisplayContext } from '@/components/euroDisplay/EuroDisplayContext';
    import Match from '@/components/euroDisplay/Match.vue';
    import { useEuroRoute } from '@/composable/useEuroRoute';
    import type { IEventEuro } from '@/interface/IEvent';
    import { SportType } from '@/interface/enum';

    const props = defineProps<{
        id: number;
        title: string;
        info?: string;
        events: IEventEuro[];
        isExpanded: boolean;
        viewAll?: (events: IEventEuro[]) => RouteLocationNamedRaw;
    }>();

    const emit = defineEmits<{
        'update:isExpanded': any;
    }>();
    const { leagueId } = useEuroRoute();

    function GetCollapseGtmLabelName(isExpanded: boolean) {
        if (leagueId.value !== 0) {
            return null;
        }
        return isExpanded ? 'CollapseLeague' : 'ExpandLeague';
    }
    const { isExpanded } = toRefs(props);
    const { market } = EuroDisplayContext.inject();

    const isCricket = props.events.some(event => event.sportType === SportType.Cricket);
</script>

<style lang="scss" scoped>
@import "@/components/euroDisplay/GroupedMatchesDisplay";
</style>
