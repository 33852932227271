<template>
    <div
        ref="sportBody"
        v-gtm:page="'G_Lobby'"
        v-gtm:identity="PlayerType[playerType]"
        v-gtm:license="LicenseType[licenseType]"
        v-gtm:currency="sboCurrency"
        v-gtm:uuid="trackingId"
        class="sportBody"
        v-bind:class="[locale, siteStyle.toLowerCase()]"
    >
        <div
            v-if="isCasaHeaderEnabled"
            id="shared-component"
            v-resize="handleHeaderResize"
            data-product="Sports"
        >
            <!-- will be replaced by real header when casa is mounted -->
            <div class="placeholderHeader" />
        </div>
        <FakeHeader v-else />

        <div class="container">
            <div class="columns">
                <div class="column column-left">
                    <LeftColumn />
                </div>
                <div class="column column-center">
                    <RouterView />
                </div>
                <div ref="rightColumnContainerRef" class="column column-right">
                    <RightColumn />
                </div>
            </div>
        </div>

        <div v-if="isCasaHeaderEnabled" id="shared-footer" />

        <div id="games-end-match" />
        <PopupBlock />
        <JackpotWinnerPopup />
    </div>
</template>

<script lang="ts" setup>
    import { loadScript } from '@sports-utils/dom';
    import { useCssVar, useEventListener } from '@vueuse/core';
    import { storeToRefs } from 'pinia';
    import { nextTick, onMounted, ref } from 'vue';
    import PopupBlock from '@/components/PopupBlock.vue';
    import FakeHeader from '@/components/header/FakeHeader.vue';
    import JackpotWinnerPopup from '@/components/jackpot/JackpotWinnerPopup.vue';
    import LeftColumn from '@/components/layout/LeftColumn.Euro.vue';
    import RightColumn from '@/components/layout/RightColumn.Euro.vue';
    import { useBackToClassicDesktop } from '@/composable/useBackToClassicDesktop';
    import { useEventEmitter } from '@/composable/useEventEmitter';
    import { useMyBets } from '@/composable/useMyBets';
    import { usePlatform } from '@/composable/usePlatform';
    import { useTitle } from '@/composable/useTitle';
    import { i18n } from '@/core/lib/i18n';
    import { getCasaUrl } from '@/core/lib/url';
    import type { SizeInfo } from '@/directives/resize';
    import { LicenseType, PageType, PlayerType } from '@/interface/enum';
    import { useCustomerStore } from '@/store/customerStore';

    document.body.classList.add('sports-background');

    const { locale } = i18n;
    const { playerType, licenseType, sboCurrency, trackingId, siteStyle } = storeToRefs(useCustomerStore());
    const isCasaHeaderEnabled = __ENABLE_CASA_HEADER__;
    if (isCasaHeaderEnabled) {
        // TODO: we should use vite-html-plugin to inject the script
        // for performance reason
        loadScript(`${getCasaUrl()}/shared-component.js`);
    }

    const sportBody = ref<HTMLElement | null>(null);
    const accountHeaderSize = useCssVar('--size-account-header', sportBody, { initialValue: '70px' });
    const handleHeaderResize = ({ height }: SizeInfo) => {
        accountHeaderSize.value = `${height >= 70 ? 70 : 35}px`;
    };

    useTitle(PageType.Home);

    const { onOpenMyBets, safariBackPageCallBack, onBackToClassicDesktop } = usePlatform();
    safariBackPageCallBack();

    const { openMyBets } = useMyBets();
    onOpenMyBets(openMyBets);

    const { backToClassicDesktop } = useBackToClassicDesktop();
    onBackToClassicDesktop(backToClassicDesktop);

    const cleanup = useEventListener(window, 'click', () => {
        cleanup();
    });
    const eventEmitter = useEventEmitter();
    const rightColumnContainerRef = ref<HTMLElement | null>(null);

    async function scrollToBetSlipBottom() {
        nextTick(async () => {
            const rightColumnContainer = rightColumnContainerRef.value;

            if (rightColumnContainer) {
                const betSlipElement = rightColumnContainer.querySelector('.betSlipContainer');
                const betSlipHeight = betSlipElement ? betSlipElement.scrollHeight : 0;
                const messageHeight = 50;
                const headerHeight = 70;

                const scrollTo = betSlipHeight + headerHeight + messageHeight - window.innerHeight;
                rightColumnContainer.scroll({ top: scrollTo, behavior: 'smooth' });
            }
        });
    }

    eventEmitter.on('betSlip:addToSingleBetSlip', scrollToBetSlipBottom);
    eventEmitter.on('betSlip:addToMultipleBetSlip', scrollToBetSlipBottom);
    onMounted(scrollToBetSlipBottom);
</script>

<style lang="scss" scoped>
    @import "@/scss/euro/layout";
</style>
