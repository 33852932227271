import { until } from '@vueuse/core';
import { defineStore, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useLocalStorage } from '@/composable/useLocalStorage';
import { useState } from '@/composable/useState';
import { isMobile } from '@/core/lib/utils';
import { BetBuilderTutorialState, RoutePage, SiteStyle } from '@/interface/enum';
import { useAsiaSportMarketInfoStore } from '@/store/asiaSportMarketInfoStore';
import { useCustomerStore } from '@/store/customerStore';
import { useToggleStore } from '@/store/toggleStore';

const allTutorialState = Object.values(BetBuilderTutorialState).filter(value => typeof value === 'number') as BetBuilderTutorialState[];
const tutorialStateList = isMobile()
    ? allTutorialState.filter(x => x <= BetBuilderTutorialState.Step1)
    : allTutorialState;

export const useBetBuilderTutorialStore = defineStore('BetBuilderTutorial', () => {
    const [tutorialState, setTutorialState] = useState(BetBuilderTutorialState.None);
    const tutorialAnchor = ref<HTMLElement | null>(null);

    const { hashedAccountId } = storeToRefs(useCustomerStore());
    const [isShown, setIsShown] = useLocalStorage<boolean>(`tutorial:bb:${hashedAccountId.value}`, false);

    const { isBetBuilderEnabled, isBetBuilderTutorialEnabled } = storeToRefs(useToggleStore(SiteStyle.Asia));
    const { isPromotionZone } = storeToRefs(useAsiaSportMarketInfoStore());
    const isShowing = computed(() => tutorialState.value !== BetBuilderTutorialState.None);
    const route = useRoute();
    const isExchangePage = computed(() => route.name === RoutePage.Exchange);

    function open() {
        if (isShown.value) return;
        if (!isBetBuilderEnabled.value) return;
        if (!isBetBuilderTutorialEnabled.value) return;
        if (isPromotionZone.value) return;
        if (isExchangePage.value) return;

        setTutorialState(BetBuilderTutorialState.Step1);
    }

    function close() {
        setIsShown(true);
        setTutorialState(BetBuilderTutorialState.None);
    }

    function show() {
        return new Promise<void>((resolve) => {
            open();
            until(isShowing).toBe(false).then(() => {
                resolve();
            });
        });
    }

    const currentStep = computed(() => Number(tutorialState.value));

    const totalStep = computed(() => tutorialStateList.filter(x => x !== BetBuilderTutorialState.None).length);

    const hasPrev = computed(() => tutorialState.value > BetBuilderTutorialState.Step1);

    const hasNext = computed(() => tutorialState.value < totalStep.value);

    function next() {
        if (hasNext.value) {
            setTutorialState(tutorialState.value + 1);
        }
    }

    function prev() {
        if (hasPrev.value) {
            setTutorialState(tutorialState.value - 1);
        }
    }

    return {
        show,
        open,
        close,
        next,
        prev,
        hasNext,
        hasPrev,
        tutorialState,
        currentStep,
        totalStep,
        isShowing,
        tutorialAnchor,
    };
});
