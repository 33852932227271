import type { Fn } from '@vueuse/core';
import { tryOnUnmounted, useIntervalFn } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import type { Ref } from 'vue';
import { computed, watch } from 'vue';
import type { IBetSlip, IBetSlipEuro } from '@/interface/IBetSlip';
import { BetSlipType } from '@/interface/IBetSlip';
import type { IBetBuilderBetSlip } from '@/interface/betBuilder';
import { useToggleStore } from '@/store/toggleStore';

export const useAutoRefreshBetSlip = (betSlip: Ref<IBetSlip | IBetSlipEuro | IBetBuilderBetSlip>) => {
    const { liveTicketAutoRefreshSeconds, nonLiveTicketAutoRefreshSeconds } = storeToRefs(useToggleStore());

    const autoRefreshSeconds = computed(() => {
        if (betSlip.value.type === BetSlipType.BetBuilder) {
            return 15;
        }

        const isLive = betSlip.value.tickets.some(x => x.odds.isLive);
        if (betSlip.value.type === BetSlipType.Outright) {
            return isLive
                ? 6
                : 15;
        }
        return isLive
            ? liveTicketAutoRefreshSeconds.value
            : nonLiveTicketAutoRefreshSeconds.value;
    });

    let stopAutoRefreshFn: Fn | undefined;
    function stopAutoRefresh() {
        if (stopAutoRefreshFn) {
            stopAutoRefreshFn();
        }
    }

    function startAutoRefresh(openTicket: () => void) {
        const { pause } = useIntervalFn(() => {
            openTicket();
        }, autoRefreshSeconds.value * 1000);
        stopAutoRefreshFn = pause;
    }

    function autoRefresh(openTicket: () => void) {
        watch([autoRefreshSeconds, () => betSlip.value.uid], () => {
            stopAutoRefresh();
            if (autoRefreshSeconds.value !== 0 && betSlip.value.uid) {
                startAutoRefresh(openTicket);
            }
        }, { immediate: true });
    }

    tryOnUnmounted(() => {
        stopAutoRefresh();
    });

    return {
        autoRefresh,
    };
};
