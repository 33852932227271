import { sleep } from '@sports-utils/timer';
import { useThrottleFn, useTimeoutFn, whenever } from '@vueuse/core';
import type { CancelTokenSource } from 'axios';
import axios from 'axios';
import { cloneDeep, xorBy } from 'lodash';
import { storeToRefs } from 'pinia';
import type { Ref } from 'vue';
import { computed, ref, watch } from 'vue';
import type { IAsiaBetSlipContext } from '@/components/betSlip/betSlipContext/BaseBetSlipContext';
import { useBaseBetSlipContext } from '@/components/betSlip/betSlipContext/BaseBetSlipContext';
import { usePlaceBetErrorHandler } from '@/components/betSlip/betSlipContext/usePlaceBetErrorHandler';
import type { PlaceBetErrorHandler, PlaceParlayBetErrorRules } from '@/components/betSlip/betSlipContext/usePlaceBetErrorHandler';
import { oncePromise } from '@/composable/once';
import { useMpInvalidMessageDisplay } from '@/composable/useMpInvalidMessageDisplay';
import { usePlatform } from '@/composable/usePlatform';
import { useState } from '@/composable/useState';
import { Api } from '@/core/lib/api';
import { i18n } from '@/core/lib/i18n';
import { isMainMarket } from '@/core/lib/oddsHelper';
import { getMaxPayout, getOpenTicketErrorMessage, getPlaceBetErrorMessage } from '@/core/lib/ticket';
import { useOddsSubscriptionCallback } from '@/core/oddsApi/composable/useOddsSubscriptionCallback';
import { useTicketEvent } from '@/core/oddsApi/composable/useTicketEvent';
import { useTicketOdds } from '@/core/oddsApi/composable/useTicketOdds';
import { getOddsPresetFilterInputType } from '@/core/oddsApi/helpers';
import { EnumOddsCategoryType } from '@/core/oddsApi/oddsApiType';
import type { IBetSlip, ITicket } from '@/interface/IBetSlip';
import type { IEvent } from '@/interface/IEvent';
import type { IOdds } from '@/interface/IOdds';
import { BetSlipMessagePriority, OpenTicketStatus, PlaceOrderStatus, PriceStyle, SiteStyle } from '@/interface/enum';
import { useBetSlipStore } from '@/store/betSlipStore';
import { useCustomerStore } from '@/store/customerStore';
import { useLayoutStore } from '@/store/layoutStore';
import { useToggleStore } from '@/store/toggleStore';

export const createParlayBetSlipContext = (parlayBetSlip: Ref<IBetSlip>): IAsiaBetSlipContext => {
    const {
        updateParlayBetSlip,
        removeParlayTicket,
        removeParlayBetSlip,
        setParlayLastStake,
    } = useBetSlipStore();

    const betSlipContext = useBaseBetSlipContext(parlayBetSlip);

    const {
        stakeInput,
        totalOddsPrice,
        setBetSlipMessage,
        setStakeMessage,
        toggleIsPlaceBetInProgress,
        cancelTicketMillisecond,
        isPlaceBetEnabled: _isPlaceBetEnabled,
        emitPlaceBetSuccess,
        autoRefresh,
    } = betSlipContext;
    const { setStake } = stakeInput;

    const tickets = computed(() => parlayBetSlip.value.tickets);
    const { isValidSubBetCount } = useMpInvalidMessageDisplay(tickets, ref(true));

    const { formatMoneyWithDecimal } = useCustomerStore();
    const maxPayout = computed(() => {
        // Parlay will always be Euro price style
        const payout = getMaxPayout(stakeInput.stake.value, totalOddsPrice.value, PriceStyle.Euro);
        return formatMoneyWithDecimal(payout);
    });

    const [isMinBetLessThanMaxBet, setIsMinBetLessThanMaxBet] = useState(true);

    function cancelTicket(key?: string | undefined) {
        if (!key) return;
        removeParlayTicket(key);
    }

    let cancelToken: CancelTokenSource;
    const openTicket = async () => {
        try {
            if (!isValidSubBetCount.value) return;

            if (cancelToken) cancelToken.cancel('invalid previous openTicket');
            cancelToken = axios.CancelToken.source();

            const hash = getTicketsHash(parlayBetSlip);

            const { data: ticketResponse } = await Api.openMixParlayTicket(parlayBetSlip.value.tickets, cancelToken.token);

            if (hash !== getTicketsHash(parlayBetSlip)) return; // tickets not match between request and response

            updateParlayBetSlip((betSlip) => {
                betSlip.tickets.forEach((ticket) => {
                    if (!ticketResponse.oddsInfo) return;

                    const ticketKey = ticket.key;
                    const oddsData = ticketResponse.oddsInfo[ticketKey];

                    if (!oddsData) {
                        setBetSlipMessage(i18n.t('bet_slip_error_message_odds_closed'));
                        useTimeoutFn(() => cancelTicket(ticketKey), cancelTicketMillisecond);
                        return;
                    }

                    if (oddsData.errorCode) {
                        setBetSlipMessage(getOpenTicketErrorMessage(oddsData.errorCode));

                        const cancelTicketStatus = [
                            OpenTicketStatus.EventClosed,
                            OpenTicketStatus.EventCompleted,
                            OpenTicketStatus.EventInternal,
                            OpenTicketStatus.OddsClosed,
                            OpenTicketStatus.OddsCompleted,
                            OpenTicketStatus.OddsInternal,
                        ];

                        if (cancelTicketStatus.includes(oddsData.errorCode)) {
                            useTimeoutFn(() => cancelTicket(ticketKey), cancelTicketMillisecond);
                        }

                        return;
                    }

                    ticket.odds.betCondition = oddsData.betCondition ?? '';
                    ticket.odds.point = oddsData.point;
                    ticket.priceStyle = PriceStyle.fromPollux(oddsData.priceStyle);
                    ticket.priceOption.price = oddsData.price;

                    if (isMainMarket(ticket.odds)) {
                        ticket.event.homeTeam.liveScore = oddsData.liveHomeScore;
                        ticket.event.awayTeam.liveScore = oddsData.liveAwayScore;
                    }

                    const eventResult = ticket.event.eventResults.find(eventResult => eventResult.id === ticket.odds.eventResult.id);
                    if (eventResult) {
                        eventResult.homeTeam.liveScore = oddsData.liveHomeScore;
                        eventResult.awayTeam.liveScore = oddsData.liveAwayScore;
                    }
                });

                betSlip.uid = ticketResponse.mpUid ?? '';
                betSlip.minBet = ticketResponse.minBet;
                betSlip.maxBet = ticketResponse.maxBet;
                betSlip.balance = ticketResponse.balance;

                if (ticketResponse.errorCode === OpenTicketStatus.MaxOddsExceed || ticketResponse.maxBet < ticketResponse.minBet) {
                    setIsMinBetLessThanMaxBet(false);
                    setStakeMessage(i18n.t('bet_slip_error_message_over_max_odds'));
                } else {
                    setIsMinBetLessThanMaxBet(true);
                }

                return betSlip;
            });
        } catch (error) {
            if (axios.isCancel(error)) {
                // do nothing
            } else {
                console.error(error);
                setBetSlipMessage(i18n.t('bet_slip_error_message_general_failure'));
                useTimeoutFn(() => removeParlayBetSlip(), 3000);
            }
        }
    };
    const openTicketThrottled = useThrottleFn(openTicket, 1000, true, false);

    const ticketContextChangeHandler: PlaceBetErrorHandler = (_placeOrderStatus: PlaceOrderStatus, _extraInfo: string) => {
        openTicketThrottled();
    };

    const invalidEventOrPriceHandler: PlaceBetErrorHandler = (placeOrderStatus: PlaceOrderStatus, _extraInfo: string) => {
        setBetSlipMessage(getPlaceBetErrorMessage(placeOrderStatus));
        useTimeoutFn(() => removeParlayBetSlip(), cancelTicketMillisecond);
    };

    const { placeBetErrorRuleMap, defaultErrorHandler, errorMessageHandler } = usePlaceBetErrorHandler(betSlipContext);

    const placeParlayBetErrorRuleMap: Partial<Record<PlaceParlayBetErrorRules, PlaceBetErrorHandler>> = {
        ...placeBetErrorRuleMap,
        [PlaceOrderStatus.RetryOpenTicket]: ticketContextChangeHandler,
        [PlaceOrderStatus.PriceChange]: ticketContextChangeHandler,
        [PlaceOrderStatus.HdpPointChange]: ticketContextChangeHandler,
        [PlaceOrderStatus.ScoreChanged]: ticketContextChangeHandler,
        [PlaceOrderStatus.PriceSuspend]: invalidEventOrPriceHandler,
        [PlaceOrderStatus.InvalidPrice]: invalidEventOrPriceHandler,
        [PlaceOrderStatus.EventClosed]: invalidEventOrPriceHandler,
        [PlaceOrderStatus.InvalidRefNo]: errorMessageHandler,
        [PlaceOrderStatus.ExistBetOnSameEvent]: errorMessageHandler,
        [PlaceOrderStatus.LessEvent]: errorMessageHandler,
        [PlaceOrderStatus.MPMaxPayoutExceed]: errorMessageHandler,
        [PlaceOrderStatus.MPMaxAccumulatedStakeForSubEventExceed]: errorMessageHandler,
        [PlaceOrderStatus.NotValidMixParlayCombination]: errorMessageHandler,
    };

    const { refreshBalanceAfterAction } = usePlatform();

    const { parlayLastStake, isShowLastStake } = storeToRefs(useBetSlipStore());
    const { placeBetDelayTime } = storeToRefs(useToggleStore(SiteStyle.Asia));

    if (isShowLastStake.value) {
        setStake(parlayLastStake.value);
    }

    const placeBet = async () => {
        toggleIsPlaceBetInProgress(true);

        try {
            const [{ data: response }] = await Promise.all([
                Api.placeAsiaMixParlayBet(parlayBetSlip.value, stakeInput.stake.value),
                sleep(placeBetDelayTime.value * 1000),
            ]);

            if (response.isPlaceBetSuccess) {
                const { toggleBetSlipExpanded } = useLayoutStore();
                toggleBetSlipExpanded(false);
                removeParlayBetSlip();
                emitPlaceBetSuccess();
                if (isShowLastStake.value) {
                    setParlayLastStake(stakeInput.stake.value);
                }
                refreshBalanceAfterAction();
                toggleIsPlaceBetInProgress(false);
                return;
            }
            const placeOrderStatus = PlaceOrderStatus.fromPollux(response.placeOrderStatus);
            const handler = placeParlayBetErrorRuleMap[placeOrderStatus as PlaceParlayBetErrorRules] ?? defaultErrorHandler;
            handler(placeOrderStatus, response.extraInfo);
        } catch (error) {
            console.error(error);
            errorMessageHandler(PlaceOrderStatus.GeneralFailure, '');
        }
        toggleIsPlaceBetInProgress(false);
    };

    const isPlaceBetEnabled = computed(() => _isPlaceBetEnabled.value && isValidSubBetCount.value && isMinBetLessThanMaxBet.value);

    function subscribeOdds(ticket: Ref<ITicket>) {
        // query odds
        const { priceStyle } = storeToRefs(useCustomerStore());
        const {
            loaded,
            oddsList,
        } = useTicketOdds({
            eventId: ticket.value.event.id,
            oddsId: ticket.value.odds.id,
            presetFilter: getOddsPresetFilterInputType(ticket.value.odds.isLive),
            oddsCategory: EnumOddsCategoryType.MixParlay,
            priceStyle,
            isActive: !isValidSubBetCount.value,
        });

        Promise.all([oncePromise(loaded, val => val === true)]).then(() => {
            const odds = oddsList.value.find(odds => odds.id === ticket.value.odds.id);
            if (odds) {
                updateOdds(odds);
            } else {
                handleOddsClosed(ticket.value.odds.id);
            }
        });

        // subscribe odds
        const shouldSubscribe = computed(() => isValidSubBetCount.value || loaded.value);
        const {
            onUpdated,
            onDeleted,
        } = useOddsSubscriptionCallback({
            eventId: ticket.value.event.id,
            oddsId: ticket.value.odds.id,
            presetFilter: getOddsPresetFilterInputType(ticket.value.odds.isLive),
            oddsCategory: EnumOddsCategoryType.MixParlay,
            priceStyle,
            isActive: shouldSubscribe,
        });

        onUpdated((result) => {
            handleOddsChanged(result);
        });

        onDeleted(() => {
            handleOddsClosed(ticket.value.odds.id);
        });
    }

    function updateWhenLangChanged(ticket: Ref<ITicket>) {
        const { loaded, event } = useTicketEvent(ticket.value.event.id, EnumOddsCategoryType.MixParlay);
        whenever(loaded, () => {
            if (event.value) {
                updateEvent(event.value);
            }
        });
    }

    function handleOddsChanged(odds: IOdds) {
        if (isValidSubBetCount.value) {
            openTicketThrottled();
        } else {
            updateOdds(odds);
        }
    }

    function handleOddsClosed(oddsId: number) {
        setBetSlipMessage(i18n.t('bet_slip_error_message_odds_closed'), BetSlipMessagePriority.SubscriptionOddsClose);

        const ticket = parlayBetSlip.value.tickets.find(x => x.odds.id === oddsId);
        if (ticket) {
            useTimeoutFn(() => cancelTicket(ticket?.key), cancelTicketMillisecond);
        }
    }

    function updateEvent(event: IEvent) {
        const ticket = parlayBetSlip.value.tickets.find(ticket => ticket.event.id === event.id);
        if (ticket) {
            ticket.event = cloneDeep(event);
        }
    }

    function updateOdds(odds: IOdds) {
        const ticket = parlayBetSlip.value.tickets.find(ticket => ticket.odds.id === odds.id);
        if (!ticket) return;
        ticket.odds = cloneDeep(odds);

        const priceOption = odds.prices.find(p => p.option === ticket.priceOption.option);
        if (!priceOption) return;
        ticket.priceOption = cloneDeep(priceOption);
    }

    watch(parlayBetSlip, (newValue, oldValue) => {
        if (newValue.tickets.length === 0) {
            Api.clearTicket();
        } else {
            const ticketDifferences = xorBy(newValue.tickets, oldValue?.tickets ?? [], x => x.key);
            if (ticketDifferences.length) {
                setStakeMessage('');
                updateParlayBetSlip((betSlip) => {
                    betSlip.uid = '';
                    betSlip.minBet = 0;
                    betSlip.maxBet = 0;

                    return betSlip;
                });

                if (isValidSubBetCount.value) {
                    openTicketThrottled();
                }
            }
        }
    }, { immediate: true, deep: true });

    autoRefresh(openTicketThrottled);

    const isVoucherAvailable = computed (() => false);
    const isVoucherValidForTicket = computed(() => false);

    return {
        ...betSlipContext,
        maxPayout,
        openTicket,
        cancelTicket,
        placeBet,
        subscribeOdds,
        updateWhenLangChanged,
        isPlaceBetEnabled,
        isVoucherAvailable,
        isVoucherValidForTicket,
    };
};

function getTicketsHash(betSlip: Ref<IBetSlip>) {
    return betSlip.value.tickets.map(x => x.key).join();
}
