import { storeToRefs } from 'pinia';
import { type Ref, computed } from 'vue';
import { getMatchLiveTimeDisplay } from '@/components/oddsDisplay/matchDisplay/useMatchDisplay';
import { computedInterval } from '@/composable/computedInterval';
import { i18n } from '@/core/lib/i18n';
import type { IEventLike } from '@/interface/IEventLike';
import type {
    IBetConstructLiveScore,
    IBetRadarLiveScore,
    ILiveScoreDisplay,
    IProviderLiveScore,
    Score,
} from '@/interface/ILiveScore';
import { LiveScoreProvider, MatchStatus, SiteStyle, SportType } from '@/interface/enum';
import { useToggleStore } from '@/store/toggleStore';

type LiveScoreHandler = (event: IEventLike, liveScore: IProviderLiveScore, siteStyle: SiteStyle) => ILiveScoreDisplay | null

const liveScoreHandlerMapping: Partial<Record<LiveScoreProvider, LiveScoreHandler>> = {
    [LiveScoreProvider.Sdt]: getSdtLiveScoreDisplay,
    [LiveScoreProvider.BetConstruct]: getBetConstructLiveScoreDisplay,
    [LiveScoreProvider.BetRadar]: getBetRadarLiveScoreDisplay,
    [LiveScoreProvider.Cricket]: getCricketLiveScoreDisplay,
};

export function useLiveScoreDisplay(
    event: Ref<IEventLike | null>,
    providerLiveScore: Ref<IProviderLiveScore | undefined>,
    sboLiveScore: Ref<Score | null>,
    siteStyle: SiteStyle,
) {
    const { liveScoreProviderConfig } = storeToRefs(useToggleStore());
    const supportedProviders = computed(() => (event.value ? liveScoreProviderConfig.value[event.value.sportType] ?? [] : []));

    const sboScore = computedInterval(() => getSboLiveScoreDisplay(event.value, sboLiveScore.value), 20 * 1000);

    const displayScore = computed(() => supportedProviders.value.map((provider: LiveScoreProvider) => {
        if (!event.value) return null;

        if (provider === LiveScoreProvider.Sbo) return sboScore.value;

        if (!providerLiveScore.value) return null;

        const handler = liveScoreHandlerMapping[provider];
        return handler ? handler(event.value, providerLiveScore.value, siteStyle) : null;
    }).find(x => x !== null) ?? null);

    return {
        displayScore,
    };
}

function getSboLiveScoreDisplay(event: IEventLike | null, liveScore: Score | null): ILiveScoreDisplay | null {
    if (!event) return null;
    return {
        score: liveScore
            ? { home: liveScore.home, away: liveScore.away }
            : undefined,
        periodText: getMatchLiveTimeDisplay(event),
    };
}

function getCricketLiveScoreDisplay(_event: IEventLike, liveScore: IProviderLiveScore | undefined, _siteStyle: SiteStyle): ILiveScoreDisplay | null {
    if (!liveScore?.cricket) return null;
    return {
        score: {
            home: `${liveScore.cricket.score.home}/${liveScore.cricket.homeDismissals}`,
            away: `${liveScore.cricket.score.away}/${liveScore.cricket.awayDismissals}`,
        },
        cricketDisplayText: `${i18n.t('cricket_live_score_inn')}: ${liveScore.cricket.innings} | ${i18n.t('cricket_live_score_over')}: ${liveScore.cricket.over}.${liveScore.cricket.delivery}`,
    };
}

function getSdtLiveScoreDisplay(event: IEventLike, liveScore: IProviderLiveScore | undefined, siteStyle: SiteStyle): ILiveScoreDisplay | null {
    if (!liveScore?.sdt?.status) return null;

    const { sdt } = liveScore;
    return {
        score: {
            home: sdt.score.home,
            away: sdt.score.away,
        },
        periodText: getSdtLiveScoreInfo(event, sdt.status, siteStyle),
    };
}

function getBetConstructLiveScoreDisplay(event: IEventLike, liveScore: IProviderLiveScore, siteStyle: SiteStyle): ILiveScoreDisplay | null {
    if (!liveScore.betConstruct) return null;
    return getThirdPartyLiveScoreDisplay(event, liveScore.betConstruct, siteStyle);
}

function getBetRadarLiveScoreDisplay(event: IEventLike, liveScore: IProviderLiveScore, siteStyle: SiteStyle): ILiveScoreDisplay | null {
    if (!liveScore.betRadar) return null;
    return getThirdPartyLiveScoreDisplay(event, liveScore.betRadar, siteStyle);
}

const ncaaPeriodDisplayMapping = computed<Record<number, string>>(() => ({
    1: i18n.t('live_score_first_half'),
    2: i18n.t('live_score_first_half'),
    3: i18n.t('live_score_second_half'),
    4: i18n.t('live_score_second_half'),
    5: i18n.t('live_score_second_half'),
    6: i18n.t('live_score_second_half'),
    7: i18n.t('live_score_second_half'),
    8: i18n.t('live_score_second_half'),
    9: i18n.t('live_score_second_half'),
    10: i18n.t('live_score_over_time'),
    11: i18n.t('live_score_over_time'),
}));

const basketballPeriodDisplayMapping = computed<Record<number, string>>(() => ({
    1: i18n.t('live_score_quarter', { n: 1 }),
    2: i18n.t('live_score_quarter', { n: 1 }),
    3: i18n.t('live_score_quarter', { n: 2 }),
    4: i18n.t('live_score_quarter', { n: 2 }),
    5: i18n.t('live_score_quarter', { n: 3 }),
    6: i18n.t('live_score_quarter', { n: 3 }),
    7: i18n.t('live_score_quarter', { n: 4 }),
    8: i18n.t('live_score_quarter', { n: 4 }),
    9: i18n.t('live_score_quarter', { n: 4 }),
    10: '--',
    11: i18n.t('live_score_over_time'),
    12: i18n.t('live_score_over_time'),
    13: '--',
    14: '--',
    15: '--',
    16: '--',
    17: '--',
}));

function getThirdPartyLiveScoreDisplay(event: IEventLike, providerLiveScore: IBetConstructLiveScore | IBetRadarLiveScore, siteStyle: SiteStyle): ILiveScoreDisplay | null {
    if (providerLiveScore.matchStatus === MatchStatus.NotStarted) return null;

    const {
        periodSportList,
        inningSportList,
        setGameScoreSportList,
    } = storeToRefs(useToggleStore());

    const isPeriodSport = periodSportList.value.includes(event.sportType);
    const isInningSport = inningSportList.value.includes(event.sportType);
    const isSetGameScoreSport = setGameScoreSportList.value.includes(event.sportType);

    if (event.sportType === SportType.Basketball) {
        if (isInvalid(providerLiveScore.inPlayPeriod)) return null;

        return {
            score: {
                home: providerLiveScore.totalScore.home,
                away: providerLiveScore.totalScore.away,
            },
            periodText: getBasketballLiveScoreInfo(event.league.name, providerLiveScore.inPlayPeriod),
        };
    }

    if (isSetGameScoreSport) {
        return {
            score: {
                home: providerLiveScore.gameScore.home,
                away: providerLiveScore.gameScore.away,
            },
            setDisplayText: getSetDisplayText(providerLiveScore.setScore, siteStyle),
            setScore: {
                home: providerLiveScore.setScore.home,
                away: providerLiveScore.setScore.away,
            },
        };
    }

    if (isPeriodSport) {
        if (isInvalid(providerLiveScore.inPlayPeriod)) return null;

        return {
            score: {
                home: providerLiveScore.totalScore.home,
                away: providerLiveScore.totalScore.away,
            },
            periodText: getInPlayPeriodInfo(providerLiveScore.inPlayPeriod),
        };
    }

    if (isInningSport) {
        if (isInvalid(providerLiveScore.inPlayInning)) return null;

        return {
            score: {
                home: providerLiveScore.totalScore.home,
                away: providerLiveScore.totalScore.away,
            },
            periodText: getInPlayInningInfo(providerLiveScore.inPlayInning),
        };
    }

    return {
        score: {
            home: providerLiveScore.totalScore.home,
            away: providerLiveScore.totalScore.away,
        },
        periodText: isPenalty(providerLiveScore.inPlayPeriod) ? 'PEN' : getMatchLiveTimeDisplay(event),
    };
}

function getSdtLiveScoreInfo(event: IEventLike, status: number, _siteStyle: SiteStyle): string {
    if (isNcaaLeague(event.league.name) && event.sportType === SportType.Basketball) {
        return ncaaPeriodDisplayMapping.value[status];
    }

    return basketballPeriodDisplayMapping.value[status];
}

function getInPlayPeriodInfo(inPlayPeriod: string) {
    if (isOverTime(inPlayPeriod)) {
        return i18n.t('live_score_over_time');
    }

    if (isPenalty(inPlayPeriod)) {
        return 'PEN';
    }

    return i18n.t('live_score_period', { n: inPlayPeriod });
}

function getInPlayInningInfo(inPlayInning: string) {
    if (isOverTime(inPlayInning)) {
        return i18n.t('live_score_extra');
    }

    return i18n.t('live_score_inning', { n: inPlayInning });
}

function getBasketballLiveScoreInfo(leagueName: string, inPlayPeriod: string) {
    if (isOverTime(inPlayPeriod)) {
        return i18n.t('live_score_over_time');
    }

    if (isNcaaLeague(leagueName)) {
        return inPlayPeriod === '1' ? i18n.t('live_score_first_half') : i18n.t('live_score_second_half');
    }

    return i18n.t('live_score_quarter', { n: inPlayPeriod });
}

function isOverTime(inPlayInfo: string) {
    return inPlayInfo === 'OT';
}

function isPenalty(inPlayInfo: string) {
    return inPlayInfo === 'PEN';
}

function isInvalid(inPlayInfo: string) {
    return !inPlayInfo || inPlayInfo === '0';
}

function isNcaaLeague(leagueName: string) {
    return leagueName.startsWith('NCAA');
}

function getSetDisplayText(setScore: Score, siteStyle: SiteStyle) {
    if (siteStyle === SiteStyle.Asia) {
        return i18n.t('live_score_set', {
            homeScore: setScore.home,
            awayScore: setScore.away,
        });
    }
    return i18n.t('live_score_set_count', {
        count: setScore.home + setScore.away + 1,
    });
}
