import { EnumOddsStatus } from '@/core/oddsApi/oddsApiType';
import type { IBetSlipEuro } from '@/interface/IBetSlip';
import { BetSlipType } from '@/interface/IBetSlip';
import type { IEventEuro, ITeamEuro } from '@/interface/IEvent';
import type { IJersey } from '@/interface/IJersey';
import type { IOdds, IPrice } from '@/interface/IOdds';
import type { IOutrightLeagueEuro } from '@/interface/IOutrightLeague';
import type { IOutrightOdds } from '@/interface/IOutrightOdds';
import { HandicapType, MarketType, OddsOption, OpenTicketStatus, PlaceOrderStatus } from '@/interface/enum';
import type { IMixParlayBetSlipEuro } from '@/store/betSlipStore.euro';

export const getTicketKey = (odds: IOdds | IOutrightOdds, priceOption: IPrice) => `${odds.id}-${priceOption.option}`;

export const getDefaultBetSlip = <Type extends BetSlipType>(type: Type): IBetSlipEuro<Type> => ({
    type,
    uid: '',
    key: '',
    minBet: 0,
    maxBet: 0,
    tickets: [],
    stake: 0,
    vouchers: [],
    placedOrderError: {
        status: PlaceOrderStatus.Unknown,
        message: '',
    },
    openTicketError: OpenTicketStatus.Success,
});

export function getDefaultParlayBetSlip(): IMixParlayBetSlipEuro {
    return {
        ...getDefaultBetSlip(BetSlipType.Parlay),
        type: BetSlipType.Parlay,
        key: 'Mix parlay',
        tickets: [],
        stake: 0,
    };
}
const emptyJersey: IJersey = { type: 0, body: null, leftSleeve: null, rightSleeve: null, stripe: null };

const emptyTeam: ITeamEuro = { name: '', redCard: 0, liveScore: 0, jersey: emptyJersey };

export function outrightLeagueToEvent(league: IOutrightLeagueEuro, odds: IOutrightOdds): IEventEuro {
    return {
        id: league.id,
        isLive: league.isLive,
        sportType: league.sportType,
        eventCode: '',
        hasStatistics: false,
        liveCourtMatchId: 0,
        showTime: new Date(),
        showTimeType: 0,
        marketTypeCount: {},
        kickOffTime: new Date(),
        extraInfo: null,
        awayTeam: { ...emptyTeam, name: odds.teamName },
        homeTeam: { ...emptyTeam, name: odds.teamName },
        league: {
            id: league.id,
            name: league.name,
            size: league.size,
            info: league.info,
            region: {
                id: 0,
                name: '',
            },
        },
        stream: null,
    };
}

export function outrightOddsToOdds(league: IOutrightLeagueEuro, odds: IOutrightOdds): IOdds {
    return {
        id: odds.id,
        isLive: odds.isLive,
        sportType: league.sportType,
        status: EnumOddsStatus.Running,
        marketType: MarketType.OutRight,

        hasMixParlay: false,
        point: 0,
        handicapType: HandicapType.Unknown,
        betCondition: '',
        sortOrder: 0,
        prices: [],
        eventResult: {
            id: 0,
            eventId: 0,
            handicapType: HandicapType.Unknown,
            marketGroup: {
                id: 0,
                name: '',
                awayExtension: '',
                awayTemplate: '',
                displayPriority: 0,
                homeExtension: '',
                homeTemplate: '',
                isSingle: false,
                isYesNo: false,
            },
            liveHomeScore: 0,
            liveAwayScore: 0,
        },
    };
}

export function outrightOddsToPrice(odds: IOutrightOdds): IPrice {
    return {
        price: odds.price,
        option: OddsOption.OR,
    };
}
