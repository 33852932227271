import { ColumnGroup, ResultsBlockType, ScoringType, SportType } from '@/interface/enum';

const columnGroupCountMapping: Partial<Record<ColumnGroup, number>> = {
    [ColumnGroup.G5]: 5,
    [ColumnGroup.G7]: 7,
    [ColumnGroup.P3]: 3,
};

const defaultColumnGroupCount = 1;

export const getColumnGroupCount = (column: ColumnGroup) => columnGroupCountMapping[column] ?? defaultColumnGroupCount;

const sportColumnGroupsMapping: Partial<Record<SportType, ColumnGroup[]>> = {
    [SportType.Badminton]: [ColumnGroup.G5, ColumnGroup.TotalPointsWon, ColumnGroup.TotalSetsWon],
    [SportType.MotorSport]: [ColumnGroup.Winner],
    [SportType.Tennis]: [ColumnGroup.G5, ColumnGroup.TotalGameWon, ColumnGroup.TotalSetsWon],
    [SportType.Volleyball]: [ColumnGroup.G5, ColumnGroup.TotalPointsWon, ColumnGroup.TotalSetsWon],
    [SportType.BeachVolleyball]: [ColumnGroup.G5, ColumnGroup.TotalPointsWon, ColumnGroup.TotalSetsWon],
    [SportType.Golf]: [ColumnGroup.FinalScore],
    [SportType.Boxing]: [ColumnGroup.Winner, ColumnGroup.Round],
    [SportType.Cricket]: [ColumnGroup.Result],
    [SportType.TableTennis]: [ColumnGroup.G7, ColumnGroup.TotalPointsWon, ColumnGroup.TotalSetsWon],
    [SportType.Cycling]: [ColumnGroup.Winner],
    [SportType.Athletics]: [ColumnGroup.Winner],
    [SportType.BeachSoccer]: [ColumnGroup.P3, ColumnGroup.FinalScore],
    [SportType.Darts]: [ColumnGroup.FinalScore],
    [SportType.WinterSports]: [ColumnGroup.Winner],
    [SportType.Squash]: [ColumnGroup.G5, ColumnGroup.TotalPointsWon, ColumnGroup.TotalGameWon],
    [SportType.MixedMartialArts]: [ColumnGroup.Winner, ColumnGroup.Round],
    [SportType.MuayThai]: [ColumnGroup.Winner, ColumnGroup.Round],
};

export const scoringTypeColumnGroupsMapping: Record<ScoringType, ColumnGroup[]> = {
    [ScoringType.Unknown]: [],
    [ScoringType.Football]: [ColumnGroup.FirstHalfScore, ColumnGroup.FullTimeScore],
    [ScoringType.Golf]: [ColumnGroup.FinalScore],
    [ScoringType.Badminton]: [ColumnGroup.G5, ColumnGroup.TotalGameWon, ColumnGroup.TotalSetsWon],
    [ScoringType.BeachSoccer]: [ColumnGroup.P3, ColumnGroup.FinalScore],
    [ScoringType.TableTennis]: [ColumnGroup.G7, ColumnGroup.TotalPointsWon, ColumnGroup.TotalSetsWon],
    [ScoringType.BasketBall]: [ColumnGroup.FirstHalfScore, ColumnGroup.FullTimeScore],
    [ScoringType.Tennis]: [ColumnGroup.G5, ColumnGroup.TotalGameWon, ColumnGroup.TotalSetsWon],
    [ScoringType.MotorSport]: [ColumnGroup.Winner],
    [ScoringType.WinterSport]: [ColumnGroup.Winner],
    [ScoringType.Cricket]: [ColumnGroup.Result],
};

const defaultColumnGroups = [ColumnGroup.FirstHalfScore, ColumnGroup.FullTimeScore];

export const getColumnGroupsBySport = (sport: SportType) => {
    const groups = sportColumnGroupsMapping[sport] ?? defaultColumnGroups;
    return groups.map(group => ({
        group,
        count: getColumnGroupCount(group),
    }));
};

export const getColumnGroupsByScoringType = (scoringType: ScoringType) => {
    const groups = scoringTypeColumnGroupsMapping[scoringType];
    return groups.map(group => ({
        group,
        count: getColumnGroupCount(group),
    }));
};

const columnWidthMapping: Record<ColumnGroup | ResultsBlockType, number> = {
    [ColumnGroup.FirstHalfScore]: 138,
    [ColumnGroup.FullTimeScore]: 138,
    [ColumnGroup.FinalScore]: 230,
    [ColumnGroup.TotalPointsWon]: 100,
    [ColumnGroup.TotalSetsWon]: 100,
    [ColumnGroup.TotalGameWon]: 100,
    [ColumnGroup.Winner]: 230,
    [ColumnGroup.Round]: 70,
    [ColumnGroup.Result]: 180,
    [ColumnGroup.G5]: 46,
    [ColumnGroup.G7]: 46,
    [ColumnGroup.P3]: 46,
    [ResultsBlockType.Time]: 142,
    [ResultsBlockType.More]: 70,
    [ResultsBlockType.NextGoal]: 68,
};

export const getColumnWidth = (key: ColumnGroup | ResultsBlockType, sport: SportType) => {
    if (key === ResultsBlockType.More && sport === SportType.Cricket) {
        return 80;
    }
    return columnWidthMapping[key];
};
