<template>
    <Message
        v-bind:message="i18n.t(receipts.message?.key ?? '', { count: receipts.message?.failedBetCount })"
        v-bind:status="receipts.message?.status"
        position="top"
        iconSize="lg"
        v-bind:show="true"
    />
    <div class="single">
        <div class="subHeader">
            <div class="action" v-on:click="reuseSelections">
                {{ i18n.t('mini_my_bet_reuse_selections') }}
            </div>
        </div>
    </div>
    <Receipt
        v-for="receipt in receipts.list"
        v-bind:key="receipt.transId"
        v-bind:receipt="receipt"
        hideTicketPrice
        hideDetail
    >
        <template #price>
            <span class="price">{{ getMixParlayFolds(receipt.tickets.length) }} @ {{ formatReceiptPrice(receipt.displayPrice) }}</span>
        </template>
    </Receipt>
    <div class="placeBetBlock">
        <div class="detailText_item">
            <span>{{ i18n.t('stake') }}</span>
            <span class="amount">
                <CurrencyIcon />
                {{ totalStake }}
            </span>
        </div>
        <div class="detailText_item">
            <strong>{{ i18n.t('bet_slip_potential_returns') }}</strong>
            <strong class="amount">
                <CurrencyIcon />
                {{ totalReturn }}
            </strong>
        </div>
        <VButton
            block
            class="notranslate"
            color="brandColor-800"
            textColor="white"
            hoverTextColor="white"
            v-on:click="resetReceipts"
        >
            {{ i18n.t('bet_receipt_continue') }}
        </VButton>
        <VButton
            block
            outline
            class="notranslate"
            outlineColor="brandColor-800"
            hoverOutlineColor="brandColor-800"
            hoverTextColor="brandColor-800"
            v-on:click="openMyBets"
        >
            {{ i18n.t('bet_receipt_check_mybets') }}
        </VButton>
    </div>
</template>

<script lang="ts" setup>
    import { format } from '@sports-utils/money';
    import { storeToRefs } from 'pinia';
    import { computed, onUnmounted } from 'vue';
    import Message from '@/components/betSlip/Message.vue';
    import CurrencyIcon from '@/components/common/CurrencyIcon.vue';
    import VButton from '@/components/common/button/VButton.vue';
    import Receipt from '@/components/receipt/Receipt.vue';
    import { useMyBets } from '@/composable/useMyBets';
    import { i18n } from '@/core/lib/i18n';
    import { sum } from '@/core/lib/math';
    import { formatMinDecimalPlace } from '@/core/lib/numberFormat';
    import { BetSlipType } from '@/interface/IBetSlip';
    import { useEuroBetSlipStore } from '@/store/betSlipStore.euro';
    import { useCustomerStore } from '@/store/customerStore';

    const { openMyBets } = useMyBets();
    const { useBetSlipsStoreByType } = useEuroBetSlipStore();
    const { addBet, receipts, resetReceipts } = useBetSlipsStoreByType(BetSlipType.Parlay);

    const reuseSelections = () => {
        receipts.value.list.forEach((r) => {
            r.tickets.forEach((ticket) => {
                addBet(ticket.event, ticket.odds, ticket.priceOption, ticket.betPage);
            });
        });
    };

    const { defaultMoneyConfig } = storeToRefs(useCustomerStore());
    const formattedSum = (list: number[]) => format(sum(...list), defaultMoneyConfig.value);
    const formatReceiptPrice = (value: number) => formatMinDecimalPlace(value, 2);

    const totalStake = computed(() => formattedSum(receipts.value.list.map(r => r.stake)));
    const totalReturn = computed(() => formattedSum(receipts.value.list.map(r => r.maxPayout)));

    const foldsMap: Record<number, string> = {
        1: 'betSlip_comboParlay_option_1',
        2: 'betSlip_comboParlay_option_2',
        3: 'betSlip_comboParlay_option_3',
    };

    const getMixParlayFolds = (ticketCount: number) => {
        const foldTranslationKey = foldsMap[ticketCount];
        return foldTranslationKey
            ? i18n.t(foldTranslationKey)
            : i18n.t('betSlip_comboParlay_option_n', { n: ticketCount });
    };

    onUnmounted(() => {
        resetReceipts();
    });
</script>

<style scoped lang="scss">
    @import "@/components/betSlip/BetSlipBlock.Euro";

    .price {
        margin-bottom: var(--spacers-lg);
        font-size: 14px;
        font-weight: 700;
    }
</style>
