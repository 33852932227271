<template>
    <OutrightOddsDisplay
        v-if="isShowLive"
        v-bind:isLive="true"
        v-bind:isSticky="isSticky"
    />
    <OutrightOddsDisplay
        v-bind:isLive="false"
        v-bind:isSticky="isSticky"
    />
    <SelectLeague
        v-if="isShowSelectLeague"
        v-bind:leagueFilters="selectLeagueList"
        v-bind:loaded="loaded"
        v-on:close="setIsShowSelectLeague(false)"
    />
</template>

<script lang="ts" setup>
    import uniqBy from 'lodash/uniqBy';
    import { computed } from 'vue';
    import OutrightOddsDisplay from '@/components/oddsDisplay/outright/OutrightOddsDisplay.vue';
    import { OutrightOddsDisplayBlockContext } from '@/components/oddsDisplay/outright/OutrightOddsDisplayBlockContext';
    import { useSelectLeague } from '@/components/oddsDisplay/useSelectLeague';
    import SelectLeague from '@/components/selectLeague/SelectLeague.vue';
    import type { ILeagueFilter } from '@/interface/ILeagueFilter';

    const { displayLiveLeagues, sortedAllLeagues, loaded } = OutrightOddsDisplayBlockContext.provide();

    const isShowLive = computed(() => displayLiveLeagues.value.length > 0);

    const selectLeagueList = computed((): ILeagueFilter[] => {
        const allLeagues = sortedAllLeagues.value.map(league => ({
            id: league.id,
            name: league.name,
            size: league.size,
        }));
        return uniqBy(allLeagues, x => x.id);
    });
    const { isShowSelectLeague, setIsShowSelectLeague } = useSelectLeague(selectLeagueList);

    const isSticky = true;
</script>
