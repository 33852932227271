import { useWindowSize } from '@vueuse/core';
import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useState } from '@/composable/useState';
import { useToggle } from '@/composable/useToggle';
import { BreakPoint, TabName } from '@/interface/enum';

export const useLayoutStore = defineStore('Layout', () => {
    const { width: windowWidth, height: windowHeight } = useWindowSize();
    const layoutSize = computed(() => {
        if (windowWidth.value > BreakPoint.XXL) return BreakPoint.XXL;
        if (windowWidth.value > BreakPoint.XL) return BreakPoint.XL;
        return BreakPoint.LG;
    });

    const [isLayoutExpanded, _toggleLayoutExpanded] = useToggle(true);
    const [isSportListExpanded, toggleIsSportListExpanded] = useToggle(true);
    const [isBetSlipExpanded, toggleBetSlipExpanded] = useToggle(false);
    const [isMyBetsExpanded, toggleMyBetsExpanded] = useToggle(true);
    const [betSlipActiveTab, setBetSlipActiveTab] = useState(TabName.Single);

    const toggleLayoutExpanded = (value?: boolean | undefined) => {
        if (isLayoutExpanded.value) {
            toggleBetSlipExpanded(false);
            toggleMyBetsExpanded(false);
        } else {
            toggleBetSlipExpanded(true);
            toggleMyBetsExpanded(true);
        }
        _toggleLayoutExpanded(value);
    };

    return {
        layoutSize,
        windowWidth,
        windowHeight,
        isLayoutExpanded,
        toggleLayoutExpanded,
        isSportListExpanded,
        toggleIsSportListExpanded,
        isBetSlipExpanded,
        toggleBetSlipExpanded,
        isMyBetsExpanded,
        toggleMyBetsExpanded,
        betSlipActiveTab,
        setBetSlipActiveTab,
    };
});
